import Cookies from "js-cookie";
import { REGEX } from "../constants/regex";

// maintains a caret position when manually trying to change input value on change
export const maintainCaretPosition = (event) => {
  const caret = event.target.selectionStart;
  const element = event.target;
  window.requestAnimationFrame(() => {
    element.selectionStart = caret;
    element.selectionEnd = caret;
  });
};

export const setCookie = (name, value) => {
  Cookies.set(name, value, {
    expires: Number(process.env.REACT_APP_COOKIE_EXPIRY), //cookie expires in days.
    domain: process.env.REACT_APP_DOMAIN, //current app domain eg: '.spectio.app'.
    secure: true, // indicating if the cookie transmission requires a secure protocol (https).
    sameSite: "none", //allowing to control whether the browser is sending a cookie along with cross-site requests.
    // path: "/",
  });
};

export const getSubdomain = (url) => {
  let domain = url;
  if (url.includes("://")) {
    domain = url.split("://")[1];
  }
  domain = domain.split("/")[0];
  const parts = domain.split(".");
  return parts.length > 2 ? parts[0] : "";
};

const firstLetters = (name) => {
  name = name.replace(/^\s+/, "");

  name = name.replace(/\s+/g, " ");

  const words = name.split(" ");

  let firstLetters = "";

  for (let i = 0; i < Math.min(words.length, 2); i++) {
    firstLetters += words[i][0];
  }

  return firstLetters.toUpperCase();
};

export const stringAvatar = (name) => {
  return {
    children: `${firstLetters(name.length > 1 ? name : "User")}`,
  };
};

export const isEmail = (string) => {
  return REGEX.EMAIL.test(string.toLowerCase());
};

export const convertArrayToString = (arr) => {
  let result = "";
  const limit = 10;
  arr.slice(0, limit).forEach((str, index) => {
    if (index > 0) {
      result += ", ";
    }
    result += str;
  });
  if (arr.length > limit) {
    result += ", etc...";
  }
  return result;
};
