import React, { useEffect, useRef, useState } from "react";
import BaseModal from "../../common/BaseModal";
import { InputField } from "../../common/InputField";
import CustomSelectField from "../../common/CustomSelectField";
import { useDispatch, useSelector } from "react-redux";
import {
  getSpecDataScopesAsync,
  unsetSpecDataScopeModal,
} from "../../store/slices/dataScopes";
import { updateSpecification } from "../../services";
import { useParams } from "react-router-dom";
import { HTTP_OK } from "../../constants/statusCodes";
import { defaultMessage } from "../../constants/messages";
import { toaster } from "../../common/Toaster";
import _ from "lodash";
import { Field, Form, Formik } from "formik";
import {
  specDataScopeSelectValidationSchema,
  specDataScopeTextValidationSchema,
} from "../../utils/validationSchemas";

const SetSpecDataScopeModal = () => {
  const { specId } = useParams();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const { isSetSpecDSModalOpen, specDSData } = useSelector(
    (state) => state?.dataScopes
  );

  const [selectOptions, setSelectOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const isTextField = _.isEmpty(specDSData?.values);

  const textInitialValue = {
    textValue: specDSData?.originalObject?.value
      ? specDSData?.originalObject?.value
      : "",
  };

  const selectInitialValue = {
    selectValue: specDSData?.originalObject?.value
      ? {
          value: specDSData?.originalObject?.value,
          label: specDSData?.originalObject?.value,
        }
      : null,
  };

  const handleChange = (field, value) => {
    field.onChange({
      target: {
        name: field.name,
        value: value,
      },
    });
  };

  const handleClose = () => {
    dispatch(unsetSpecDataScopeModal());
  };

  const onSubmit = async (values, { resetForm, validateForm }) => {
    try {
      setIsLoading(true);
      const response = await updateSpecification({
        id: specId,
        payload: {
          specification: {
            data_scope_specifications_attributes: {
              id: specDSData?.id,
              value: isTextField
                ? values?.textValue
                : values?.selectValue?.value,
            },
          },
        },
      });

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }
      setIsLoading(false);
      resetForm();
      validateForm();
      handleClose();
      dispatch(getSpecDataScopesAsync({ specId }));
      toaster({
        type: "success",
        message: "Data scope value updated successfully.",
      });
    } catch (error) {
      setIsLoading(false);
      toaster({
        type: "error",
        message: " Error while updating data scope value!",
      });
    }
  };
  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    if (isSetSpecDSModalOpen && specDSData) {
      if (!isTextField) {
        const options = specDSData.values.split(/,\s*/).map((value, index) => {
          return { value: value, label: value };
        });
        setSelectOptions(options);
      }
    }
  }, [specDSData]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={isTextField ? textInitialValue : selectInitialValue}
      onSubmit={onSubmit}
      validateOnBlur={true}
      validateOnMount={true}
      validateOnChange={true}
      validationSchema={
        isTextField
          ? specDataScopeTextValidationSchema
          : specDataScopeSelectValidationSchema
      }
      enableReinitialize={true}>
      {({ touched, errors, values, isValid, resetForm, validateForm }) => (
        <Form>
          <BaseModal
            title='Set Data Scope Value'
            isOpen={isSetSpecDSModalOpen}
            handleClose={() => {
              resetForm();
              validateForm();
              handleClose();
            }}
            handleSubmit={handleSubmitClick}
            isLoading={isLoading}
            submitDisable={errors && Object.keys(errors)?.length !== 0}>
            {isTextField ? (
              <Field name='textValue'>
                {({ field, form }) => (
                  <InputField
                    name='textValue'
                    isLabel={true}
                    label={"Value"}
                    placeholder='Enter data scope value'
                    value={field.value}
                    onChange={({ currentTarget: input }) =>
                      handleChange(field, input.value)
                    }
                    onBlur={() => {
                      form.setTouched({
                        ...form.touched,
                        textValue: true,
                      });
                    }}
                    error={
                      form.touched.textValue && Boolean(form.errors.textValue)
                    }
                    helperText={form.touched.textValue && form.errors.textValue}
                  />
                )}
              </Field>
            ) : (
              <Field name='selectValue'>
                {({ field, form }) => (
                  <CustomSelectField
                    isLabel={true}
                    label={"Value"}
                    isMulti={false}
                    isCreatable={false}
                    placeholder='Select data scope value'
                    options={selectOptions}
                    async={false}
                    value={field.value}
                    onChange={(newValue) => handleChange(field, newValue)}
                    onBlur={() => {
                      form.setTouched({
                        ...form.touched,
                        selectValue: true,
                      });
                    }}
                    error={
                      form.touched.selectValue &&
                      Boolean(form.errors.selectValue)
                    }
                    helperText={
                      form.touched.selectValue && form.errors.selectValue
                    }
                  />
                )}
              </Field>
            )}
          </BaseModal>
        </Form>
      )}
    </Formik>
  );
};

export default SetSpecDataScopeModal;
