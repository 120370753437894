import React from "react";
import BusinessRequirementsForm from "../../components/BusinessRequirementsForm";
import { Stack } from "@mui/material";
import DataScopeTable from "../../components/DataScopeTable";
import { ROUTE_PATH } from "../../router/routes";
import AcceptanceCriteriaForm from "../../components/AcceptanceCriteriaForm";
import RequesterInformationForm from "../../components/RequesterInformationForm";
import RequestInformationPreview from "../../components/RequesterInformationForm/Preview";
import UserStoryPreview from "../../components/BusinessRequirementsForm/UserStoryPreview";
import KPIAndBI from "../../components/KPIAndBI";

const BusinessRequirements = () => {
  // Example condition based on location pathname
  const isPreview = location.pathname.includes(ROUTE_PATH.SPEC_PREVIEW);

  return (
    <Stack
      sx={{
        height: 1,
        width: 1,
        overflowY: "auto",
      }}>
      <Stack px={3} py={2} pb={4} rowGap={5}>
        {isPreview ? <UserStoryPreview /> : <BusinessRequirementsForm />}
        <AcceptanceCriteriaForm isPreview={isPreview} />
        <KPIAndBI isPreview={isPreview} />
        {isPreview ? (
          <RequestInformationPreview />
        ) : (
          <RequesterInformationForm />
        )}
        <DataScopeTable isPreview={isPreview} />
      </Stack>
    </Stack>
  );
};

export default BusinessRequirements;
