import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // FUNCTIONS STATES
  labelId: null,
  labelDetails: null,
};

const copyLabelSlice = createSlice({
  name: "copyLabel",
  initialState: initialState,

  reducers: {
    setCopyLabelData: (state, action) => {
      state.labelId = action?.payload?.id;
      state.labelDetails = action?.payload?.labelDetails;
    },
    resetCopyLabelData: (state, action) => initialState,
  },
});

export const { setCopyLabelData, resetCopyLabelData } = copyLabelSlice.actions;
export default copyLabelSlice.reducer;
