import React, { useRef, useState, useEffect } from "react";
import _ from "lodash";
import {
  Stack,
  Avatar,
  Button,
  Divider,
  Tooltip,
  Typography,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import {
  askToInvite,
  getSpecAccesses,
  updateShareList,
  getAutocompleteUsers,
} from "../../services";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { components } from "react-select";
import RoleDropdown from "../RoleDropdown";
import { useParams } from "react-router-dom";
import { toaster } from "../../common/Toaster";
import BaseModal from "../../common/BaseModal";
import { ShareOutlinedIcon } from "../../assets";
import { LinkRounded } from "@mui/icons-material";
import { PERMISSIONS, ROLES } from "../../constants";
import { HTTP_OK } from "../../constants/statusCodes";
import LoadingButton from "../../common/LoadingButton";
import { defaultMessage } from "../../constants/messages";
import { specSecondary } from "../../constants/theme/colors";
import CustomIconButton from "../../common/CustomIconButton";
import { isEmail, stringAvatar } from "../../utils/commonUtility";
import PaginatedSelectField from "../../common/PaginatedSelectField";
import {
  setShareModalClose,
  setShareModalOpen,
} from "../../store/slices/userSpec";

const ShareModal = () => {
  const scrollableBlockRef = useRef();
  const dispatch = useDispatch();

  const { specId } = useParams();
  const { specData } = useSelector((state) => state.spec);
  const { user } = useSelector((state) => state?.userAuth);
  const { isShareModalOpen, shareModalData } = useSelector(
    (state) => state.spec
  );

  // const [open, setOpen] = useState(false);
  const [specAccesses, setSpecAccesses] = useState([]);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [oldSpecAccesses, setOldSpecAccesses] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [requestLoading, setRequestLoading] = useState(false);
  const [specAccessLoading, setSpecAccessLoading] = useState(false);
  const [currentRole, setCurrentRole] = useState(PERMISSIONS.EDITOR);

  //--- PERMISSIONS---//
  const canInviteNewUser = user?.role === ROLES.ADMIN;
  const canShare =
    user?.superAdmin ||
    user?.role === PERMISSIONS.ADMIN ||
    Boolean(
      specAccesses?.find(
        (access) =>
          access?.user?.id === user?.id &&
          [PERMISSIONS.EDITOR, PERMISSIONS.ADMIN].includes(access.permission)
      )
    );
  const isAddOrRequest =
    !canInviteNewUser &&
    selectedOption?.__isNew__ &&
    currentRole === PERMISSIONS.EDITOR;

  // CHECK OWNER
  const currentSpecAccesses = specData?.specAccesses
    ? specData?.specAccesses
    : [];

  const owner =
    currentSpecAccesses?.find((user) => user?.permission === "owner")?.user ||
    null;
  // ----------------//

  const openShareModal = () => {
    // setOpen(true);
    dispatch(setShareModalOpen());
  };

  const closeShareModal = () => {
    setSelectedOption(null);
    setCurrentRole(null);
    setIsValidEmail(true);
    // setOpen(false);
    dispatch(setShareModalClose());
  };

  const loadUsers = async (searchQuery = "", loadedOptions, { page }) => {
    let userOptions = [];
    try {
      const response = await getAutocompleteUsers({
        query: searchQuery,
        allUsers: true,
      });

      if (response.status === HTTP_OK) {
        // Filter users based on access array
        let filteredUsers = response?.data?.users.filter(
          (user) =>
            !specAccesses.some(
              (accessObj) => accessObj.user.email === user?.email
            )
        );

        const modifiedResults = filteredUsers.map((user) => ({
          value: user?.id,
          label:
            user?.firstName && user?.lastName
              ? `${user?.firstName} ${user?.lastName}`
              : user?.email,
          user: user,
        }));
        userOptions = modifiedResults;
      }
      return {
        hasMore: false,
        options: userOptions,
      };
    } catch (error) {
      toaster({
        type: "error",
        message: "Error while fetching user details",
      });
      return {
        options: userOptions,
      };
    }
  };

  // Function to handle option selection
  const handleSelectChange = (selectedOption) => {
    if (!selectedOption?.__isNew__) {
      setIsValidEmail(true);
    }
    setSelectedOption(selectedOption);
  };

  const handleOnCreateOption = (val) => {
    const email = val?.trim();
    const newOption = {
      value: email,
      label: email,
      user: {
        email: email,
      },
      __isNew__: true,
    };
    setIsValidEmail(isEmail(email));
    handleSelectChange(newOption);
  };

  const handleRoleChange = (role) => {
    setCurrentRole(role);
  };

  const handleIndividualRoleChange = (role, id) => {
    const index = specAccesses.findIndex((obj) => obj.id === id);
    if (index !== -1) {
      const newArray = [...specAccesses];
      newArray[index] = { ...newArray[index], permission: role };

      if (!newArray[index]?.__isNew__ || !newArray[index]?.__isExisting__) {
        const oldPermission = oldSpecAccesses[index]?.permission; // Fetching old permission from oldArrayOfObjects
        if (oldPermission !== role) {
          newArray[index].__isUpdated__ = true;
        } else {
          newArray[index].__isUpdated__ = false;
        }
      }

      setSpecAccesses(newArray);
    }
  };

  const handleAdd = () => {
    if (selectedOption?.__isNew__) {
      const newUserAccess = {
        id: uuidv4(),
        permission: currentRole || PERMISSIONS.EDITOR,
        __isNew__: true,
        user: {
          id: null,
          email: selectedOption?.value,
          firstName: "",
          lastName: "",
        },
      };
      setSpecAccesses([...specAccesses, newUserAccess]);
    } else {
      setSpecAccesses([
        ...specAccesses,
        {
          id: selectedOption?.user?.id,
          permission: currentRole || PERMISSIONS.EDITOR,
          user: selectedOption?.user,
          __isExisting__: true,
        },
      ]);
    }

    setSelectedOption(null);
    setCurrentRole(PERMISSIONS?.EDITOR);

    setTimeout(() => {
      scrollToBottom();
    }, [100]);
  };

  const scrollToBottom = () => {
    if (scrollableBlockRef?.current) {
      scrollableBlockRef.current.scrollTo({
        top: scrollableBlockRef?.current?.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const Option = (props) => {
    return (
      <>
        <components.Option {...props}>
          <Stack>
            <Typography
              variant='spec_body'
              sx={{
                wordBreak: "break-word",
              }}>
              {props?.label}
            </Typography>
            {props?.data?.user?.firstName &&
              props?.data?.user?.lastName &&
              props?.data?.user?.email && (
                <Typography
                  variant='spec_caption'
                  color={"gray.main"}
                  sx={{
                    wordBreak: "break-word",
                  }}>{`(${props?.data?.user?.email})`}</Typography>
              )}
          </Stack>
        </components.Option>
      </>
    );
  };

  const handleCopyLink = () => {
    const hostname = window.location.hostname;
    const dotsCount = hostname.split(".").length - 1;
    const addSubdomain =
      dotsCount === 1 && Cookies.get("subdomain")
        ? `${Cookies.get("subdomain")}.`
        : "";
    const path = `/specs/spec/${specId}`;
    navigator.clipboard.writeText(
      `https://${addSubdomain}${window.location.hostname}${path}`
    );
    setOpenTooltip(true);
    setTimeout(() => {
      setOpenTooltip(false);
    }, 2000);
  };

  const handleAskToInvite = async () => {
    const payload = {
      email: selectedOption?.user?.email,
    };
    setRequestLoading(true);
    try {
      const response = await askToInvite(payload);
      setRequestLoading(false);

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }

      toaster({
        type: "success",
        message:
          response?.data?.toaster?.data?.message ??
          "Admin will be notified of your request",
      });

      setSelectedOption(null);
      setCurrentRole(PERMISSIONS.EDITOR);
      // setOpen(false);
      dispatch(setShareModalClose());
    } catch (error) {
      setRequestLoading(false);
      toaster({
        type: "error",
        message: "Error while sending invite request!",
      });
    }
  };

  const handleSubmit = async () => {
    const updatedAddAccesses = specAccesses.filter((obj) => {
      return obj.__isUpdated__ || obj.__isNew__ || obj.__isExisting__;
    });

    const updatedShareAccesses = updatedAddAccesses?.map((newAccess) => {
      // { user_id: <id>, permission: 'collaborator', spec_access_id: spec_access_id }, # change permission of existing user access
      // { user_id: null, permission: 'editor', email: 'test@example.com', spec_access_id: null }, # add new user
      // { user_id: null, permission: 'viewer', email: new_user_2.email, spec_access_id: null }, # add user with existing email
      // { user_id: <id>, permission: 'viewer', email: new_user.email, spec_access_id: null } # add user with existing email in the same company
      if (newAccess?.__isNew__) {
        return {
          user_id: null,
          permission: newAccess?.permission,
          email: newAccess?.user?.email,
          spec_access_id: null,
        };
      }

      if (newAccess?.__isExisting__) {
        return {
          user_id: newAccess?.user?.id,
          permission: newAccess?.permission,
          email: newAccess?.user?.email,
          spec_access_id: null,
        };
      }

      if (newAccess?.__isUpdated__) {
        return {
          user_id: newAccess?.user?.id,
          permission: newAccess?.permission,
          spec_access_id: newAccess?.id,
        };
      }

      return newAccess;
    });

    if (updatedShareAccesses?.length) {
      setSubmitLoading(true);
      try {
        const response = await updateShareList(specId, {
          spec_accesses: updatedShareAccesses,
        });
        setSubmitLoading(false);
        toaster({
          type: "success",
          message:
            response?.data?.toaster?.data?.message ??
            "Access was successfully updated!",
        });
        dispatch(setShareModalClose());
        // setOpen(false);
      } catch (error) {
        setSubmitLoading(false);
        toaster({
          type: "error",
          message: "Error while updating specification access!",
        });
      }
    }
  };

  const loadSpecAccesses = async () => {
    setSpecAccessLoading(true);
    try {
      const response = await getSpecAccesses(specId);
      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }

      const allSpecAccesses = response?.data?.specAccesses?.map((access) => ({
        ...access,
        __isUpdated__: false,
      }));

      setOldSpecAccesses(allSpecAccesses ?? []);
      setSpecAccesses(allSpecAccesses ?? []);
      setSpecAccessLoading(false);
    } catch (error) {
      setSpecAccessLoading(false);
      toaster({
        type: "error",
        message: "Error while fetching spec accesses!",
      });
    }
  };
  useEffect(() => {
    if (isShareModalOpen) {
      loadSpecAccesses();
      if (shareModalData) {
        handleOnCreateOption(shareModalData);
      }
    }
  }, [isShareModalOpen]);

  useEffect(() => {
    loadSpecAccesses();
  }, []);

  return (
    <>
      {canShare && (
        <CustomIconButton
          tooltipTitle='Share'
          Icon={ShareOutlinedIcon}
          onClick={openShareModal}
        />
      )}

      {isShareModalOpen && (
        <BaseModal
          dialogWidth={550}
          title='Share Specification'
          isOpen={isShareModalOpen}
          handleClose={closeShareModal}
          dialogAction={
            <>
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={1}
                columnGap={2}
                sx={{
                  flexDirection: { xs: "column", sm: "row" },
                  rowGap: { xs: 2, sm: 0 },
                }}>
                <Tooltip
                  title='Link Copied to Clipboard!'
                  placement='top'
                  open={openTooltip}>
                  <Button
                    variant={"outlined"}
                    color={"secondary"}
                    startIcon={<LinkRounded />}
                    onClick={handleCopyLink}
                    sx={{ width: "fit-content", alignSelf: "flex-end" }}>
                    Copy Link
                  </Button>
                </Tooltip>
                <Stack
                  flexDirection={"row"}
                  columnGap={2}
                  alignSelf={"flex-end"}>
                  <Button
                    variant={"outlined"}
                    color={"gray"}
                    onClick={closeShareModal}
                    disabled={submitLoading}>
                    Cancel
                  </Button>
                  <LoadingButton
                    onClick={handleSubmit}
                    variant='contained'
                    disabled={_.isEqual(oldSpecAccesses, specAccesses)}
                    loading={submitLoading}>
                    Share
                  </LoadingButton>
                </Stack>
              </Stack>
            </>
          }>
          <Stack rowGap={2}>
            {specAccessLoading ? (
              <Stack direction={"row"} justifyContent={"center"} mt={2}>
                <CircularProgress size={45} />
              </Stack>
            ) : (
              <>
                <Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    columnGap={2}
                    sx={{
                      flexDirection: { xs: "column", sm: "row" },
                      rowGap: 1,
                    }}>
                    <PaginatedSelectField
                      disabled={requestLoading}
                      name='addPeople'
                      label='Add People'
                      placeholder='Select your coworkers from the list'
                      isLabel={true}
                      loadOptions={loadUsers}
                      value={selectedOption}
                      onChange={handleSelectChange}
                      isCreatable={true}
                      handleOnCreateOption={handleOnCreateOption}
                      Components={{ Option: Option }}
                      error={!isValidEmail}
                      defaultOptions={false}
                      allowCreateWhileLoading={true}
                    />
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      columnGap={2}
                      sx={{ alignSelf: "flex-end", pt: { xs: 0, sm: "29px" } }}>
                      <RoleDropdown
                        role={currentRole}
                        onRoleChange={handleRoleChange}
                      />

                      <LoadingButton
                        variant='contained'
                        loading={requestLoading}
                        disabled={!isValidEmail || !selectedOption}
                        onClick={
                          isAddOrRequest ? handleAskToInvite : handleAdd
                        }>
                        {isAddOrRequest ? "Request" : "Add"}
                      </LoadingButton>
                    </Stack>
                  </Stack>
                  <Stack>
                    <FormHelperText error={!isValidEmail} sx={{ m: 0 }}>
                      <Typography
                        sx={(theme) => ({
                          margin: 0,
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          color: !isValidEmail
                            ? theme.palette.error.main
                            : theme.palette.gray.main,
                        })}
                        variant='spec_caption'>
                        {!isValidEmail
                          ? "Please enter valid email."
                          : selectedOption?.__isNew__
                          ? currentRole === PERMISSIONS.EDITOR &&
                            !canInviteNewUser
                            ? "This editor is not an approved user. The account admin will receive a request to add this user as an editor."
                            : "Invite new user to the spec via email"
                          : ""}
                      </Typography>
                    </FormHelperText>
                  </Stack>
                </Stack>

                <Stack rowGap={1.5}>
                  <Typography variant='spec_body' sx={{ fontWeight: 500 }}>
                    People With Access
                  </Typography>
                  <Divider />
                  <Stack
                    ref={scrollableBlockRef}
                    rowGap={1.5}
                    sx={{
                      overflowY: "auto",
                      maxHeight: "200px",
                      "::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                        px: 1,
                      },
                    }}>
                    {specAccesses?.length > 0 && !specAccessLoading ? (
                      specAccesses?.map((access, i) => {
                        return (
                          <Stack width={1} rowGap={1.5} key={i}>
                            <Stack
                              key={i}
                              direction={"row"}
                              justifyContent={"space-between"}
                              sx={{
                                flexDirection: { xs: "column", sm: "row" },
                                rowGap: { xs: 2, sm: 0 },
                              }}>
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                columnGap={2}>
                                <Avatar
                                  sx={(theme) => ({
                                    bgcolor: specSecondary[50],
                                    color: theme.palette.secondary.main,
                                    height: "35px",
                                    width: "35px",
                                  })}>
                                  {access?.user?.firstName &&
                                    access?.user?.lastName && (
                                      <Typography
                                        variant='spec_body'
                                        color={"secondary.main"}>
                                        {
                                          stringAvatar(
                                            `${access?.user?.firstName} ${access?.user?.lastName}`
                                          )?.children
                                        }
                                      </Typography>
                                    )}
                                </Avatar>
                                <Stack>
                                  <Typography
                                    variant='spec_body'
                                    sx={{
                                      wordBreak: "break-word",
                                    }}>
                                    {access?.user?.firstName &&
                                    access?.user?.lastName
                                      ? `${access?.user?.firstName} ${access?.user?.lastName}`
                                      : `${access?.user?.email}`}
                                  </Typography>
                                  {access?.user?.firstName &&
                                    access?.user?.lastName && (
                                      <Typography
                                        variant='spec_caption'
                                        color={"gray.main"}
                                        sx={{
                                          wordBreak: "break-word",
                                        }}>
                                        {`${access?.user?.email}`}
                                      </Typography>
                                    )}
                                  {access?.user?.invitationStatus ===
                                  "invited" ? (
                                    <Typography
                                      variant='spec_caption'
                                      color={"secondary.light"}>
                                      {`[Invitation Sent]`}
                                    </Typography>
                                  ) : (
                                    access?.__isNew__ && (
                                      <Typography
                                        variant='spec_caption'
                                        color={"warning.main"}>
                                        {`[Invitation Not Sent]`}
                                      </Typography>
                                    )
                                  )}
                                </Stack>
                              </Stack>

                              <RoleDropdown
                                roleSx={{
                                  alignSelf: "flex-end",
                                }}
                                allowDelete={true}
                                role={access?.permission}
                                onRoleChange={(role) =>
                                  handleIndividualRoleChange(role, access?.id)
                                }
                                setSpecAccesses={setSpecAccesses}
                                specAccesses={specAccesses}
                                oldSpecAccesses={oldSpecAccesses}
                                setOldSpecAccesses={setOldSpecAccesses}
                                access={access}
                                isOwner={owner?.id === access?.user?.id}
                                disableRoleChange={
                                  owner?.id === access?.user?.id ||
                                  !(canShare && user?.id !== access?.user?.id)
                                }
                              />
                            </Stack>
                            <Divider />
                          </Stack>
                        );
                      })
                    ) : (
                      <Typography variant='spec_body'>
                        Currently there are no people with spec access
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </>
            )}
          </Stack>
        </BaseModal>
      )}
    </>
  );
};

export default ShareModal;
