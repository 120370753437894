import { ColumnIcon } from "../assets/images/default-chart/ColumnIcon";
import { MultipleLineIcon } from "../assets/images/default-chart/MultipleLineIcon";
import { PieIcon } from "../assets/images/default-chart/PieIcon";
import { ScatterIcon } from "../assets/images/default-chart/ScatterIcon";
import { SingleLineIcon } from "../assets/images/default-chart/SingleLineIcon";
import { Stacked100AreaIcon } from "../assets/images/default-chart/Stacked100AreaIcon";
import { Stacked100ColumnIcon } from "../assets/images/default-chart/Stacked100ColumnIcon";
import { StackedAreaIcon } from "../assets/images/default-chart/StackedAreaIcon";
import { StackedColumnIcon } from "../assets/images/default-chart/StackedColumnIcon";
import { BarIcon } from "../assets/images/default-chart/BarIcon";
import { BoxIcon } from "../assets/images/default-chart/BoxIcon";
import { CircularIcon } from "../assets/images/default-chart/CircularIcon";
import { MOCK_DATA_LENGTH } from "../constants";
import _ from "lodash";
import {
  transformDataFor100PerStackedColumnChart,
  transformDataForBarChart,
  transformDataForBoxPlotChart,
  transformDataForColumnChart,
  transformDataForMultilineChart,
  transformDataForSingleLineChart,
  transformDataForStackedColumnChart,
} from "./transformChartDataUtils";
import {
  transformDataToBoxPlotFormat,
  transformDataToCircularAreaChartFormat,
  transformDataToMultiColumnChartFormat,
  transformDataToPieChart,
  transformDataToScatterChartFormat,
} from "./utils-chart-data";
import BarChart from "../components/Charts/BarChart";
import ColumnChart from "../components/Charts/ColumnChart";
import PieChart from "../components/Charts/PieChart";
import ScatterChart from "../components/Charts/ScatterChart";
import BoxPlot from "../components/Charts/BoxPlot";
import LineChart from "../components/Charts/LineChart";
import StackedColumnChart from "../components/Charts/StackedColumnChart";
import StackedColumn100Chart from "../components/Charts/StackedColumn100Chart";
import MultiLineChart from "../components/Charts/MultilineChart";
import { calculateStandardDeviation, findMedian } from "./common-utils";

const BAR_CHART = "bar";
const BOX_PLOT = "box-plot";
const COLUMN_CHART = "column";
const COLUMN_CHART_TIME = "column-time";
const PIE_CHART = "pie";
const SCATTER_CHART = "scatter";
const SCATTER_CHART_SECOND = "scatter-second";
const STACKED_100_COLUMN_CHART = "stacked-100-column";
const STACKED_COLUMN_CHART = "stacked-column";
const CIRCULAR_AREA_CHART = "circular-area";
const SINGLE_LINE_CHART = "single-line";
const MULTIPLE_LINE_CHART = "multiple-line";
const STACKED_100_AREA_CHART = "stacked-100-area";
const STACKED_AREA_CHART = "stacked-area";

export const getDefaultCharts = (id) => {
  switch (id) {
    case BOX_PLOT:
      return <BoxIcon />;
    case SINGLE_LINE_CHART:
      return <SingleLineIcon />;
    case BAR_CHART:
      return <BarIcon />;
    case COLUMN_CHART:
      return <ColumnIcon />;
    case COLUMN_CHART_TIME:
      return null;
    case MULTIPLE_LINE_CHART:
      return <MultipleLineIcon />;
    case CIRCULAR_AREA_CHART:
      return <CircularIcon />;
    case PIE_CHART:
      return <PieIcon />;
    case SCATTER_CHART:
      return <ScatterIcon />;
    case SCATTER_CHART_SECOND:
      return null;
    case STACKED_100_COLUMN_CHART:
      return <Stacked100ColumnIcon />;
    case STACKED_COLUMN_CHART:
      return <StackedColumnIcon />;
    case STACKED_100_AREA_CHART:
      return <Stacked100AreaIcon />;
    case STACKED_AREA_CHART:
      return <StackedAreaIcon />;
    default:
      return null;
  }
};

export const getSymbol = (str) => {
  const currencySymbols = ["$", "€", "£", "¥", "₹", "%"];
  const cleanedStr = str?.trim();

  for (const symbol of currencySymbols) {
    if (cleanedStr?.startsWith(symbol) || cleanedStr?.endsWith(symbol)) {
      return symbol;
    }
  }

  return "";
};

export const getCharts = (
  id,
  data = [],
  xTitle,
  yTitle,
  timeTitle,
  metric,
  multipleSeries,
  filterSettings,
  isInverted
) => {
  switch (id) {
    case BAR_CHART:
      return (
        <BarChart
          chartData={data}
          xTitle={xTitle}
          yTitle={yTitle}
          filterSettings={filterSettings}
          metric={metric}
        />
      );
    case COLUMN_CHART:
      return (
        <ColumnChart
          chartData={data}
          xTitle={xTitle}
          yTitle={yTitle}
          metric={metric}
        />
      );
    case PIE_CHART:
      return (
        <PieChart
          chartData={data}
          xTitle={xTitle}
          yTitle={yTitle}
          metric={metric}
        />
      );
    case SCATTER_CHART:
      return (
        <ScatterChart
          chartData={data}
          xTitle={xTitle}
          yTitle={yTitle}
          metric={metric}
        />
      );
    case BOX_PLOT:
      return (
        <BoxPlot
          chartData={data}
          xTitle={xTitle}
          yTitle={yTitle}
          metric={metric}
        />
      );
    case SINGLE_LINE_CHART:
      return (
        <LineChart
          chartData={data}
          xTitle={xTitle}
          yTitle={yTitle}
          metric={metric}
        />
      );
    case STACKED_COLUMN_CHART:
      return (
        <StackedColumnChart
          chartData={data}
          xTitle={xTitle}
          yTitle={yTitle}
          isMultipleSeries={multipleSeries && multipleSeries.length > 0}
          metric={metric}
          isInverted={isInverted}
        />
      );
    case STACKED_100_COLUMN_CHART:
      return (
        <StackedColumn100Chart
          chartData={data}
          xTitle={xTitle}
          yTitle={yTitle}
          metric={metric}
          isMultipleSeries={multipleSeries && multipleSeries.length > 0}
          isInverted={isInverted}
        />
      );
    case MULTIPLE_LINE_CHART:
      return (
        <MultiLineChart
          chartData={data}
          xTitle={xTitle}
          yTitle={yTitle}
          metric={metric}
          isMultipleSeries={multipleSeries && multipleSeries.length > 0}
        />
      );

    default:
      return null;
  }
};

export const convertToNumber = (str) => {
  const cleanedStr = str?.replace(/[^0-9.-]/g, "");
  return +parseFloat(cleanedStr).toFixed(2);
};

export const genDataForChart = (
  type,
  prevData,
  x,
  y,
  time,
  metric,
  topN,
  multipleSeries,
  chart
) => {
  if (
    (prevData?.rows?.length == 0 && !prevData) ||
    (!prevData && prevData?.rows && !prevData?.rows[0][y])
  ) {
    return null;
  } else {
    switch (type) {
      case COLUMN_CHART:
        return transformDataForColumnChart(prevData, x, y, metric, topN);
      // return transformDataToColumnChart(prevData, x, y, metric);
      case SINGLE_LINE_CHART:
        return transformDataForSingleLineChart(prevData, x, y, metric);
      // return transformDataToLineChartFormat(prevData, time, y, metric);
      case MULTIPLE_LINE_CHART:
        return transformDataForMultilineChart(
          prevData,
          x,
          null,
          y,
          metric,
          multipleSeries
        );
      // return transformDataToMultiLineChartFormat(prevData, time, x, y, metric);
      case SCATTER_CHART:
      case SCATTER_CHART_SECOND:
        return transformDataToScatterChartFormat(prevData, x, y, metric);
      case BAR_CHART:
        return transformDataForBarChart(prevData, x, y, metric, topN);
      // return transformDataToBarChart(prevData, x, y, metric);
      case PIE_CHART:
        return transformDataToPieChart(prevData, x, y, metric);
      case CIRCULAR_AREA_CHART:
        return transformDataToCircularAreaChartFormat(
          prevData,
          time,
          y,
          metric
        );
      case COLUMN_CHART_TIME:
      case STACKED_COLUMN_CHART:
        return transformDataForStackedColumnChart(
          prevData,
          x,
          null,
          y,
          metric,
          multipleSeries
        );
      case STACKED_100_COLUMN_CHART:
        return transformDataFor100PerStackedColumnChart(
          prevData,
          x,
          null,
          y,
          metric,
          multipleSeries
        );
      case STACKED_AREA_CHART:
      case STACKED_100_AREA_CHART:
        return transformDataToMultiColumnChartFormat(
          prevData,
          time,
          x,
          y,
          metric
        );
      case BOX_PLOT:
        return transformDataForBoxPlotChart(
          prevData,
          x,
          y,
          metric,
          multipleSeries,
          chart?.params?.xAxisLabel || "",
          chart?.params?.yAxisLabel || ""
        );
      default:
        return null;
    }
  }
};

export const getCalculatedValueForStatistics = (data, x, metric = "total") => {
  const rows = data?.rows ?? [];
  const symbol = getSymbol(rows?.[0]?.[x] ?? "");

  const values = rows?.map((row) => {
    return convertToNumber(row?.[x]);
  });

  const total = _.sum(values);
  const average = _.mean(values);
  const count = values?.length;
  const median = findMedian(values);
  const minimum = Math.min(...values);
  const maximum = Math.max(...values);
  const percentile75th = () => {
    if (values.length === 0) return 0;
    const sum = values.reduce((a, b) => a + b, 0);
    const percentile75th = (sum * 0.75) / 100;
    return percentile75th;
  };
  const percentile25th = () => {
    if (values.length === 0) return 0;
    const sum = values.reduce((a, b) => a + b, 0);
    const percentile25th = (sum * 0.25) / 100;
    return percentile25th;
  };
  const standardDeviation = calculateStandardDeviation(values);

  const dataForStatistics = {
    total: total,
    average: average,
    count,
    median: median,
    minimum: minimum,
    maximum: maximum,
    percentile75th: percentile75th(),
    percentile25th: percentile25th(),
    standardDeviation: standardDeviation,
  };

  let result = 0;
  let metricResult = dataForStatistics[metric];
  result = Number(metricResult?.toFixed(2));
  const value = result.toLocaleString("en-US");

  const displayValue = isFinite(result)
    ? symbol === "%"
      ? `${value}%`
      : `${metric === "count" ? "" : symbol}${value}`
    : 0;

  return displayValue;
};

export const getFilterOptions = (data, x) => {
  const rows = data?.rows ?? [];

  const values = rows?.map((row) => {
    return row?.[x];
  });

  return values || [];
};

export const getRangeFilterOptions = (data, x) => {
  const rows = data?.rows ?? [];

  const values = rows?.map((row) => {
    return row?.[x];
  });

  return [...new Set(values)] || [];
};
