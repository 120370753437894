import _ from "lodash";
import { format } from "date-fns";

export const getBillingRows = (invoices) => {
  return invoices?.map((invoice) => {
    return {
      id: invoice?.id,
      invoiceNumber: invoice?.invoiceNumber ? invoice?.invoiceNumber : "-",
      stripePaymentUrl: invoice?.stripePaymentUrl,
      stripeInvoiceUrl: invoice?.stripeInvoiceUrl,
      isTrialPeriod: invoice?.isTrialPeriod,
      paid: invoice?.paid,
      seats: invoice?.seats,
      totalCost: `$${invoice?.totalCost}`,
      startDate: invoice?.startDate
        ? format(new Date(invoice?.startDate), `MMMM dd, yyyy 'at' h:m a`)
        : "-",
      endDate: invoice?.endDate
        ? format(new Date(invoice?.endDate), `MMMM dd, yyyy 'at' h:m a`)
        : "-",
      firstName: invoice?.firstName ? invoice?.firstName : "-",
      lastName: invoice?.lastName ? invoice?.lastName : "-",
      email: invoice?.email ? invoice?.email : "-",
    };
  });
};
