import { Box, styled as MuiStyled, alpha } from "@mui/material";
import { AgGridReact } from "ag-grid-react";

const borderColor = `color-mix(in srgb, transparent, var(
    --ag-foreground-color) 15%)`;

export const AgGridContainer = MuiStyled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  "&.ag-theme-quartz": {
    "--ag-foreground-color": theme.palette.gray.textGray1,
    "--ag-background-color": theme.palette.secondary.white,
    "--ag-header-foreground-color": theme.palette.secondary.black,
    "--ag-header-background-color": theme.palette.gray.headerBackground,
    // "--ag-range-selection-border-color": "transparent",

    "--ag-font-size": "14px",
    "--ag-font-family": "Poppins !important",

    ".ag-checkbox-input-wrapper.ag-checked::after": {
      color: theme.palette.primary.main,
    },
  },
  "--ag-row-border-color": theme.palette.gray.borderGray,
  ".ag-cell, .ag-header-cell": {
    borderRight: `1px solid ${theme.palette.gray.borderGray}`,
  },
  ".ag-row .ag-cell:last-child, .ag-header-row .ag-header-cell:last-child": {
    borderRight: `1px solid ${borderColor}`,
  },
  // FOR AUTO HEIGHT
  ".ag-layout-auto-height .ag-cell:last-child:not(.ag-cell-last-left-pinned), .ag-header-row .ag-header-cell:last-child":
    {
      borderRight: "none",
    },
  // FOR AUTO HEIGHT
  ".ag-layout-auto-height .ag-row-last": {
    borderBottom: "none",
  },

  ".ag-row .ag-cell": {
    display: "flex",
    alignItems: "center",
  },
  ".ag-paging-panel": {
    display: "flex",
    justifyContent: "center",
  },
  ".ag-paging-row-summary-panel": {
    display: "none",
  },

  ".ag-center-cols-clipper, .ag-center-cols-container, .ag-center-cols-viewport":
    {
      minHeight: "0 !important",
    },
}));

export const AgGrid = MuiStyled(AgGridReact)(({ theme }) => ({
  "--ag-header-column-resize-handle-display": "none",
  ".ag-header-cell-resize:hover": {
    "--ag-header-column-resize-handle-display": "block",
    "--ag-header-column-resize-handle-height": "100%",
    "--ag-header-column-resize-handle-width": "5px",
    "--ag-header-column-resize-handle-color": alpha(
      theme.palette.primary.main,
      0.3
    ),
  },
  ".ag-root-wrapper": {
    borderRadius: "0px",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "none",
    borderTop: "none",
  },

  ".ag-overlay": {
    pointerEvents: "all !important",
    height: `calc(100% - 42px)`,
    top: "42px",
  },
}));
