import React, { useState } from "react";
import { Box, Divider } from "@mui/material";
import { CustomTabPanel, StyledTab, StyledTabs, a11yProps } from "./style";
import MockDatasetTab from "../../components/MockDatasetTab";

const tabs = [
  {
    label: "Mock Dataset",
    path: "mockdataset",
    component: <MockDatasetTab />,
  },
  {
    label: "Mappings",
    path: "mappings",
    component: <Box>MappingsTab</Box>,
  },
  {
    label: "Data Scope",
    path: "datascope",
    component: <Box>DataScopeTab</Box>,
  },
];

const MockDataset = () => {
  const [currentTab, setCurrentTab] = useState("mockdataset");

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
      }}>
      <StyledTabs
        orientation={"horizontal"}
        value={currentTab}
        onChange={handleChange}
        indicatorColor='primary'
        aria-label='secondary tabs example'>
        {tabs?.map((tab) => (
          <StyledTab
            key={tab?.path}
            value={tab?.path}
            label={tab?.label}
            {...a11yProps(tab?.path)}
          />
        ))}
      </StyledTabs>
      <Divider
        sx={(theme) => ({
          bgcolor: `1px solid ${theme.palette.gray.borderGray}`,
        })}
      />
      {tabs?.map((tab) => (
        <CustomTabPanel key={tab?.path} value={currentTab} index={tab?.path}>
          {tab?.component}
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default MockDataset;
