import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Root from "./container/Root";
import Rollbar from "rollbar";
import { ErrorBoundary, Provider } from "@rollbar/react";

const rollbarConfig = new Rollbar({
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
  captureUncaught: true,
  captureUnhandledRejections: true,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider config={rollbarConfig}>
    <ErrorBoundary>
      <Root />
    </ErrorBoundary>
  </Provider>
);
