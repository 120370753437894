import { StyledStatusChip } from "./style";
import MenuItem from "../../common/MenuItem";
import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { ExpandMoreRoundedIcon } from "../../assets";
import CustomPopover from "../../common/CustomPopover";
import { specSecondary } from "../../constants/theme/colors";

const CriteriaStatus = ({ handleApprovalStatusChange, approvalStatus }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [currentSpecStatus, setCurrentSpecStatus] = useState(approvalStatus);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateStatus = (status) => {
    setCurrentSpecStatus(status);
    handleClose();
    handleApprovalStatusChange(status);
  };

  const approvalStatuses = [
    {
      name: "Approved",
      status: true,
    },
    {
      name: "Not Approved",
      status: false,
    },
  ];

  return (
    <>
      <StyledStatusChip
        sx={{
          minWidth: "145px !important",
          dispaly: "flex",
          justifyContent: "space-between",
        }}
        // CHIP LABEL
        label={
          <Typography
            variant='spec_body'
            sx={(theme) => ({
              color: theme.palette.info.main,
            })}>
            {currentSpecStatus ? "Approved" : "Not Approved"}
          </Typography>
        }
        // CHIP ARROW ICON
        deleteIcon={
          <ExpandMoreRoundedIcon
            sx={(theme) => ({
              color: `${theme.palette.info.main} !important`,
            })}
          />
        }
        // NEEDED TO DISPLAY ARROW ICON
        onDelete={(e) => handleClick(e)}
        onClick={(e) => handleClick(e)}
      />
      <CustomPopover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <Stack rowGap={1}>
          {approvalStatuses?.map((status, index) => (
            <MenuItem
              key={index}
              itemText={status?.name}
              onClick={() => handleUpdateStatus(status.status)}
              itemProps={{
                sx: {
                  height: "12px",
                  backgroundColor:
                    status.status === currentSpecStatus
                      ? specSecondary[50]
                      : "",
                  ":hover": {
                    backgroundColor: specSecondary[50],
                  },
                },
              }}
            />
          ))}
        </Stack>
      </CustomPopover>
    </>
  );
};

export default CriteriaStatus;
