import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

const ScatterChart = ({ chartData, xTitle, yTitle }) => {
  const chartRef = useRef(null);
  const containerRef = useRef(null);
  const r = useRef((Math.random() + 1).toString(36).substring(7));
  const reduxState = useSelector((state) => state?.spec);

  const drawScatterChart = () => {
    if (!window.google) return; // Make sure google is loaded
    const createData = chartData[0].data.map((d) => [
      d.x,
      d.y,
      createCustomHTMLTooltip(d.x, d.y),
    ]);

    function drawChart() {
      const data = window.google.visualization.arrayToDataTable([
        [
          chartData[0].data[0].xName,
          chartData[0].data[0].yName,
          { type: "string", role: "tooltip", p: { html: true } },
        ],
        ...createData,
      ]);
      const options = {
        title: "",
        hAxis: {
          // minValue: findMinimumValue(createData),
          // viewWindow: {
          //   min: findMinimumValue(createData),
          // },
          title: chartData[0].data[0].xName,
          textStyle: { fontSize: 12, fontName: "Poppins" },
          titleTextStyle: {
            italic: false,
            fontSize: 12,
            fontName: "Poppins-Bold",
          },
        },
        vAxis: {
          title: chartData[0].data[0].yName,
          format: "short",
          textStyle: { fontSize: 12, fontName: "Poppins" },
          titleTextStyle: {
            italic: false,
            fontSize: 12,
            fontName: "Poppins-Bold",
          },
        },
        tooltip: { isHtml: true },
        colors: ["#F1E15B"],
        legend: { position: "none" },
        height: containerRef.current?.clientHeight,
        chartArea: {
          height: containerRef.current?.clientHeight - 80,
          top: "2%",
        },
      };

      const chart = new window.google.visualization.ScatterChart(
        chartRef.current
      );
      chart.draw(data, options);
    }
    if (!reduxState?.isSpecVisualDatasetLoading)
      window.google.charts.setOnLoadCallback(drawChart);
  };

  useEffect(() => {
    const renderChart = () => {
      setTimeout(() => {
        drawScatterChart();
      }, 150);
    };

    renderChart();
  }, [
    containerRef.current?.clientHeight,
    containerRef.current?.clientWidth,
    reduxState?.isSpecVisualDatasetLoading,
    chartData,
  ]);

  function findMinimumValue(dataList) {
    let minValue = Number.MAX_VALUE; // Initialize minValue with a large number

    // Iterate through each sublist in the dataList
    for (const sublist of dataList) {
      // Check if the first element of the sublist is less than the current minValue
      if (sublist[0] < minValue) {
        minValue = sublist[0]; // Update minValue if a smaller value is found
      }
    }

    return minValue;
  }

  function createCustomHTMLTooltip(key, value) {
    const fixedValue1 = Number(key?.toFixed(2));
    const newValue1 = fixedValue1?.toLocaleString("en-US");

    const fixedValue2 = Number(value?.toFixed(2));
    const newValue2 = fixedValue2?.toLocaleString("en-US");

    return (
      '<div style="padding:5px;">' +
      `<strong>${chartData[0].data[0].xName}: </strong>` +
      newValue1 +
      "<br/>" +
      `<strong>${chartData[0].data[0].yName}: </strong>` +
      newValue2 +
      "</div>"
    );
  }

  return (
    <div
      ref={containerRef}
      id={r.current}
      style={{ height: "100%" }}
      className='cancelDraggableElement'>
      <div ref={chartRef} id={`${r.current}_div`}></div>
    </div>
  );
};

export default ScatterChart;
