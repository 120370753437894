import { Typography, alpha, useTheme } from "@mui/material";
import React from "react";
import { GoogleLoginButton } from "react-social-login-buttons";
import { baseApi } from "../../utils/urls";

const GoogleButton = () => {
  const theme = useTheme();
  const handleGoogleLogin = () => {
    window.location.href = `${baseApi}/auth/google_oauth2`;
  };
  return (
    <GoogleLoginButton
      onClick={handleGoogleLogin}
      style={{
        borderRadius: theme.spacing(1),
        boxShadow: theme?.palette?.shadows?.shadow1,
      }}
      activeStyle={{ background: alpha(theme?.palette?.gray?.lighter, 0.2) }}>
      <Typography variant='spec_body'>Login with Google</Typography>
    </GoogleLoginButton>
  );
};

export default GoogleButton;
