import { useDispatch, useSelector } from "react-redux";
import { updateCell } from "../../services";
import { useParams } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css";
import { toaster } from "../../common/Toaster";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { HTTP_OK } from "../../constants/statusCodes";
// import CustomCellTextEditor from "./CustomCellTextEditor";
import { defaultMessage } from "../../constants/messages";
// import { AgGridComponent, AgGridContainer } from "./style";

// import OverlayNoRowsComponent from "./OverlayNoRowsComponent";
// import LoadingOverlayComponent from "./LoadingOverlayComponent";
import React, { useEffect, useMemo, useRef, useState } from "react";
// import CustomMockdatasetHeader from "./CustomMockdatasetHeader/CustomMockdatasetHeader";
import { CURRENCY, NUMBER, PERCENT } from "../../constants";
import mockDataset, {
  updateSpecTab,
  updateSpecTabCell,
} from "../../store/slices/mockDataset";
import CustomCellTextEditor from "../MockDatasetTable/CustomCellTextEditor";
import OverlayNoRowsComponent from "../MockDatasetTable/OverlayNoRowsComponent";
import LoadingOverlayComponent from "../MockDatasetTable/LoadingOverlayComponent";
import { AgGridComponent, AgGridContainer } from "../MockDatasetTable/style";
import CustomMockdatasetHeader from "../MockDatasetTable/CustomMockdatasetHeader/CustomMockdatasetHeader";

const MockDatasetPreview = ({ mockDataset }) => {
  const gridRef = useRef(null);
  const [rowData, setRowData] = useState([]);

  // Column Definitions: Defines & controls grid columns.
  const [colDefs, setColDefs] = useState([]);

  // Custom Header
  const components = useMemo(() => {
    return {
      agColumnHeader: (params) => (
        <CustomMockdatasetHeader {...params} isView={true} />
      ),
    };
  }, []);

  const handleOnCellEditingStopped = async (params) => {};

  useEffect(() => {
    const table = mockDataset?.table;

    const modifiedColumn = !table
      ? []
      : table?.columns?.map((column) => {
          return {
            field: column?.columnId,
            editable: false,
            headerName: column?.name,
            labelId: column?.businessTermId,
            type: "text",
            labelType: column?.type,
            status: column?.state,
            // cellEditor: CustomCellTextEditor,
            // cellEditorPopup: true,
            width: 150,
            minWidth: 150,
            cellClass: [NUMBER.type, PERCENT.type, CURRENCY.type].includes(
              column?.type
            )
              ? "ag-right-aligned-cell"
              : "",
          };
        });

    setColDefs([
      {
        field: "index",
        editable: false,
        headerName: "Index",
        type: "text",
        labelType: "systemDefault",
        valueGetter: "node.rowIndex + 1",
        width: 150,
        resizable: false,
        lockPosition: "left",
      },
      ...modifiedColumn,
    ]);

    const mutableRows = table?.rows?.map((item) => {
      return { ...item };
    });

    setRowData(mutableRows);
  }, [mockDataset]);

  return (
    <AgGridContainer className={"ag-theme-quartz"}>
      <AgGridComponent
        ref={gridRef}
        rowData={rowData}
        columnDefs={colDefs}
        components={components}
        onCellEditingStopped={handleOnCellEditingStopped}
        noRowsOverlayComponent={OverlayNoRowsComponent}
        loadingOverlayComponent={LoadingOverlayComponent}
        reactiveCustomComponents
      />
    </AgGridContainer>
  );
};

export default MockDatasetPreview;
