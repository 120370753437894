import {
  getStatusesAsync,
  resetStatusAddEditModal,
} from "../../../store/slices/statuses";
import { Field, Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { toaster } from "../../../common/Toaster";
import BaseModal from "../../../common/BaseModal";
import { useDispatch, useSelector } from "react-redux";
import { InputField } from "../../../common/InputField";
import { addStatuses, updateStatus } from "../../../services";
import { statusNameValidationSchema } from "../../../utils/validationSchemas";

const StatusAddEditModal = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const { isStatusAddEditOpen, statusAddEditData, statusAddEditType } =
    useSelector((state) => state.statuses);

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = (resetForm, validateForm) => {
    dispatch(resetStatusAddEditModal());
    resetForm();
    validateForm();
  };

  const initialValues = {
    status: statusAddEditData?.name ? statusAddEditData.name : "",
  };

  const handleChange = (field, value) => {
    field.onChange({
      target: {
        name: field.name,
        value: value,
      },
    });
  };

  const onSubmit = (values, { resetForm, validateForm }) => {
    if (statusAddEditType === "ADD") {
      setIsLoading(true);
      addStatuses({
        payload: {
          name: values.status,
        },
      })
        .then((response) => {
          setIsLoading(false);
          dispatch(getStatusesAsync());
          toaster({
            type: "success",
            message:
              response?.data?.toaster?.data?.message ||
              "Status was successfully created.",
          });
          handleClose(resetForm, validateForm);
        })
        .catch((e) => {
          setIsLoading(false);
          toaster({
            type: "error",
            message: e,
          });
        });
    } else {
      setIsLoading(true);
      updateStatus({
        statusId: statusAddEditData?.id,
        payload: {
          name: values.status,
        },
      })
        .then((response) => {
          dispatch(getStatusesAsync());
          setIsLoading(false);
          handleClose(resetForm, validateForm);

          toaster({
            type: "success",
            message:
              response?.data?.toaster?.data?.message ||
              "Status was successfully updated.",
          });
        })
        .catch((e) => {
          setIsLoading(false);
          toaster({ type: "error", message: e });
        });
    }
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={statusNameValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      initialTouched={{ title: true }}
      validateOnMount={true}
      enableReinitialize={true}
      onSubmit={onSubmit}>
      {({ touched, errors, values, isValid, resetForm, validateForm }) => (
        <BaseModal
          title={statusAddEditType === "ADD" ? "Add Status" : "Status Edit"}
          isOpen={isStatusAddEditOpen}
          handleClose={() => {
            handleClose(resetForm, validateForm);
          }}
          confirmTitle={statusAddEditType === "ADD" ? "Submit" : "Update"}
          handleSubmit={handleSubmitClick}
          isLoading={isLoading}
          submitDisable={errors && Object.keys(errors)?.length !== 0}>
          <Form>
            <Field name='status'>
              {({ field, form }) => (
                <InputField
                  name='status'
                  placeholder='Enter status name here'
                  isLabel={true}
                  label='Status Name'
                  variant={"outlined"}
                  value={field.value}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmitClick();
                    }
                  }}
                  onChange={({ currentTarget: input }) =>
                    handleChange(field, input.value)
                  }
                  onBlur={() => {
                    form.setTouched({
                      ...form.touched,
                      status: true,
                    });
                  }}
                  error={form.touched.status && Boolean(form.errors.status)}
                  helperText={form.touched.status && form.errors.status}
                />
              )}
            </Field>
          </Form>
        </BaseModal>
      )}
    </Formik>
  );
};

export default StatusAddEditModal;
