import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MockDatasetContextMenu from "../MockDatasetContextMenu";
import { STATE_BUSINESS_TERMS_LABEL, TYPE_LABEL } from "../../../constants";
import {
  ArrowDownwardRounded,
  ArrowUpwardRounded,
  Sync,
  UnfoldMore,
} from "@mui/icons-material";
import { resetTerm } from "../../../services";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toaster } from "../../../common/Toaster";
import { replaceTable } from "../../../store/slices/mockDataset";

const CustomMockdatasetHeader = (props) => {
  const { specId } = useParams();
  const { currentSelectedTab } = useSelector((state) => state.mockDataset);

  const dispatch = useDispatch();

  const [ascSort, setAscSort] = useState(false);
  const [descSort, setDescSort] = useState(false);
  const [noSort, setNoSort] = useState(true);

  const onSortChanged = () => {
    setAscSort(props.column.isSortAscending());
    setDescSort(props.column.isSortDescending());
    setNoSort(
      !props.column.isSortAscending() && !props.column.isSortDescending()
    );
  };

  const onSortRequested = (order, event) => {
    props.setSort(order, event.shiftKey);
  };

  useEffect(() => {
    props.column.addEventListener("sortChanged", onSortChanged);
    onSortChanged();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let sort = null;
  if (props.enableSorting) {
    sort = (
      <>
        {ascSort && (
          <IconButton
            sx={(theme) => ({
              p: 0,
              color: theme.palette.gray.iconColor,
            })}
            onClick={(event) => onSortRequested("desc", event)}
            onTouchEnd={(event) => onSortRequested("desc", event)}>
            <ArrowDownwardRounded fontSize='small' />
          </IconButton>
        )}
        {descSort && (
          <IconButton
            sx={(theme) => ({
              p: 0,
              color: theme.palette.gray.iconColor,
            })}
            onClick={(event) => onSortRequested("", event)}
            onTouchEnd={(event) => onSortRequested("", event)}>
            <ArrowUpwardRounded fontSize='small' />
          </IconButton>
        )}
        {noSort && (
          <IconButton
            sx={(theme) => ({
              p: 0,
              color: theme.palette.gray.iconColor,
            })}
            onClick={(event) => onSortRequested("asc", event)}
            onTouchEnd={(event) => onSortRequested("asc", event)}>
            <UnfoldMore
              fontSize='small'
              onClick={(event) => onSortRequested("asc", event)}
              onTouchEnd={(event) => onSortRequested("asc", event)}
            />
          </IconButton>
        )}
      </>
    );
  }

  const handleSyncClick = async () => {
    try {
      props?.api?.showLoadingOverlay();
      const response = await resetTerm({
        specId: specId,
        tabId: currentSelectedTab,
        payload: {
          dataset_column_id: props?.column?.colDef?.field,
        },
      });

      props?.api?.hideOverlay();
      if (response?.status !== 200) {
        throw new Error("Error while syncing column data!");
      }

      const newTable = response?.data?.specMockdataTab?.table;

      dispatch(
        replaceTable({
          tabId: currentSelectedTab,
          newTable: newTable,
        })
      );
    } catch (error) {
      props?.api?.hideOverlay();
      toaster({ type: "error", message: "Error while syncing column data!" });
    }
  };

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      width={1}>
      <Stack
        sx={{
          overflowX: "auto",
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}>
        <Stack flexDirection={"row"} alignItems={"center"} columnGap={0.5}>
          <Typography variant='spec_caption' color={"gray.main"}>
            {TYPE_LABEL[props?.column?.colDef?.labelType]}
          </Typography>
          <Typography variant='spec_caption' color={"gray.main"}>
            {props?.column?.colDef?.status &&
              `[${STATE_BUSINESS_TERMS_LABEL[props?.column?.colDef?.status]}]`}
          </Typography>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} columnGap={1}>
          <Typography variant='spec_body' sx={{ fontWeight: 600 }}>
            {" "}
            {props?.column?.colDef?.headerName}
          </Typography>
        </Stack>
      </Stack>
      {!props?.isView && (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ cursor: "pointer" }}>
          {props?.column?.colDef?.headerComponentParams?.outdated && (
            <Tooltip
              title={<Typography variant='spec_tooltip'>Sync Data</Typography>}
              arrow>
              <IconButton
                sx={(theme) => ({
                  p: 0,
                  color: theme.palette.gray.iconColor,
                })}
                onClick={handleSyncClick}
                onTouchStart={handleSyncClick}>
                <Sync fontSize='small' />
              </IconButton>
            </Tooltip>
          )}
          {props.enableSorting && sort}
          <MockDatasetContextMenu props={props} />
        </Stack>
      )}
    </Stack>
  );
};

export default CustomMockdatasetHeader;
