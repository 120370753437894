import {
  Backdrop,
  Box,
  CircularProgress,
  // InputLabel,
  // MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import _, { debounce, isEqual } from "lodash";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import VisualMockupTopBar from "../../components/VisualMockupTopBar";
import "./visual.style.css";
import { specPrimaryGray } from "../../constants/theme/colors";
import { VisualCard } from "../../components/VisualCard";
import { copyPasteVisual, updateSpecification } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import {
  getSpecVisualizationAsync,
  resetSpecVisualization,
  setCopiedVisualization,
  setIsVisualConfigOpen,
  updateAutoSaved,
  updateSelectedChart,
  updateSpecVisualization,
} from "../../store/slices/userSpec";
import { FILTER, SEARCH, STATISTIC, TITLE } from "../../constants";
import { useParams } from "react-router-dom";
import { TitleBlock } from "../../components/TitleCard";
import StatBlock from "../../components/StatsCard/index.";
import { FilterBlock } from "../../components/FilterBlock";
import {
  genDataForChart,
  getCalculatedValueForStatistics,
  getFilterOptions,
  getRangeFilterOptions,
} from "../../utils/chartUtilities";
import VisualConfigDrawer from "../../components/VisualConfigDrawer";
import {
  getDefaultDataChartsAsync,
  getSelectedMockDatasetTabAsync,
  getSpecMockdatasetTabsAsync,
  updateCurrenSelectedTab,
  updateSpecMockdataTabs,
} from "../../store/slices/mockDataset";
import {
  BOX_PLOT,
  MULTIPLE_LINE_CHART,
  SCATTER_CHART,
  STACKED_100_COLUMN_CHART,
  STACKED_COLUMN_CHART,
  // charTypes,
} from "../../components/SelectVisuals/utils";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getMappingColumnsAsync } from "../../store/slices/businessTerms";
import { ROUTE_PATH } from "../../router/routes";
import NoRowsOverlayCustomComponent from "../../common/NoRowsOverlayCustomComponent";
// import BaseModal from "../../common/BaseModal";
// import { MuiSelectField } from "../../common/style";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
function VisualMockup({ editable = true }) {
  const isMobileView = useMediaQuery("(max-width: 600px)");

  // IF IS NON EDITABLE OR PREVIEW
  const isPreview =
    location.pathname.includes(ROUTE_PATH.SPEC_PREVIEW) || false;

  let { specId } = useParams();
  const bottomRef = useRef(null);
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state?.spec);
  const mockDatasetReduxState = useSelector((state) => state?.mockDataset);

  const [charts, setCharts] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [statEditIndex, setStatEditIndex] = useState(null);
  const [filterEditIndex, setFilterEditIndex] = useState(null);
  // const [needUpdate, setNeedUpdate] = useState(false);
  const [statistic, setStatistic] = useState([]);
  const [layout, setLayout] = useState({
    currentBreakpoint: "lg",
    compactType: "vertical",
    mounted: false,
    layouts: [],
  });
  const { selectedChart } = useSelector((state) => state?.spec);
  const [filterSettings, setFilterSettings] = useState([]);
  const [title, setTitle] = useState({});
  const [specData, setSpecData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [label, setLabel] = useState("");
  // const [description, setDescription] = useState("");
  // const [annotation, setAnnotation] = useState("");
  const [params, setParams] = useState({});
  const [isPasteLoading, setIsPasteLoading] = useState(false);
  // const [isSwitchVisualOpen, setIsSwitchVisualOpen] = useState(false);
  // const [switchVisualData, setSwitchVisualData] = useState(null);
  // const [chartSelectedForSwitch, setChartSelectedForSwitch] = useState(null);

  // const chartTypesArray = charTypes
  //   ?.map((chart) => ({
  //     type: chart?.type,
  //     label: chart?.label,
  //   }))
  //   ?.filter((chart) => chart?.type !== switchVisualData?.type);

  useEffect(() => {
    if (selectedChart?.type === "Statistic") {
      // setLabel(selectedChart?.label || "");
      setParams(selectedChart?.params || {});
    } else {
      setLabel(selectedChart?.label || "");
      setParams(selectedChart?.params || {});
    }
  }, [selectedChart]);

  useEffect(() => {
    dispatch(getSpecVisualizationAsync({ specId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  // useEffect(() => {
  //   fetchDataset();
  // }, []);

  useEffect(() => {
    if (
      reduxState?.specVisualization?.length > 0 &&
      reduxState?.specVisualization
    ) {
      setCharts(
        reduxState?.specVisualization?.filter(
          (item) =>
            ![SEARCH, TITLE, TITLE.toLowerCase(), FILTER, STATISTIC].includes(
              item.type
            )
        ) || []
      );
      setFilterSettings(
        reduxState?.specVisualization?.filter((item) => item.type === FILTER) ||
          []
      );

      setTitle(
        reduxState?.specVisualization?.find((item) => item.type === TITLE) || {}
      );
      setStatistic(
        reduxState?.specVisualization?.filter(
          (item) => item.type === STATISTIC
        ) || []
      );
    }
  }, [reduxState?.specVisualization]);

  const fetchDataset = async () => {
    // dispatch(getSpecDataSetAsync({ specId }));
    dispatch(getSpecMockdatasetTabsAsync(specId));
  };

  useEffect(() => {
    setSpecData(mockDatasetReduxState?.specMockdataTabs || []);
    setFilteredData(mockDatasetReduxState?.specMockdataTabs || []);
  }, [mockDatasetReduxState?.specMockdataTabs]);

  useEffect(() => {
    const tempLayoutData =
      charts?.map((chart) => chart?.params?.dimension || {}) || [];
    const tempStatsData = statistic?.map(
      (stats) => stats?.params?.dimension || {}
    );
    const tempFilterData = filterSettings?.map(
      (filter) => filter?.params?.dimension || {}
    );
    tempLayoutData.push(...tempStatsData);
    tempLayoutData.push(...tempFilterData);
    if (title?.type) tempLayoutData.push(title.params?.dimension);
    setLayout({
      ...layout,
      layouts: tempLayoutData,
      mounted: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charts, statistic, title, filterSettings]);

  const onChartMoveCard = (layout, oldItem, newItem) => {
    // This lodash function checks if their are any changes in Item then only it goes for the layout update for specification
    if (isEqual(oldItem, newItem)) return;

    const titleElements = {
      ...title,
      params: {
        ...title.params,
        dimension: layout.find((l) => l.i === title?.params?.dimension?.i),
      },
    };

    const newChartElements = charts.map((chart) => {
      return {
        ...chart,
        params: {
          ...chart.params,
          dimension: layout.find((l) => l.i === chart?.params?.dimension?.i),
        },
      };
    });
    const newStatsElements = statistic.map((stats) => {
      return {
        ...stats,
        params: {
          ...stats.params,
          dimension: layout.find((l) => l.i === stats?.params?.dimension?.i),
        },
      };
    });
    const filterElements = filterSettings.map((item) => {
      return {
        ...item,
        params: {
          ...item.params,
          dimension: layout.find((l) => l.i === item?.params?.dimension?.i),
        },
      };
    });

    let junkFreePayload = removeJunk(
      newChartElements,
      newStatsElements,
      filterElements,
      titleElements
    );
    callUpdateVisual(junkFreePayload);
    setFilterSettings(filterElements);
    setTitle(titleElements);
    setCharts(newChartElements);
    setStatistic(newStatsElements);
  };

  const onStatChange = (props) => {
    if (statEditIndex !== null) {
      const newStats = [...statistic];

      const mockDatasetId = props?.params.table;

      const mockDataset = specData?.filter(
        (data) => data?.id === mockDatasetId
      )?.[0];

      const value = getCalculatedValueForStatistics(
        mockDataset?.table,
        props?.params.x,
        props?.params.metric
      );

      newStats[statEditIndex] = {
        ...newStats[statEditIndex],
        params: { ...props.params, value: value },
      };
      setStatistic(newStats);
      let payload = [...charts, ...newStats, ...filterSettings];
      if (title.params?.dimension !== undefined) {
        payload.push(title);
      }
      dispatch(updateSpecVisualization(payload));
      callUpdateVisual(payload);
    }
  };

  const onChangeFilterConfig = (props) => {
    if (filterEditIndex !== null) {
      if (selectedChart?.params === props.params) return;

      const newFilters = [...filterSettings];

      const mockDatasetId = props?.params.table;

      const mockDataset = specData?.filter(
        (data) => data?.id === mockDatasetId
      )?.[0];

      const options = getFilterOptions(mockDataset?.table, props?.params.x);

      const rangeOptions = getRangeFilterOptions(
        mockDataset?.table,
        props?.params?.y
      );

      const filterObj = newFilters[filterEditIndex];
      const isYChange = filterObj?.params?.y !== props?.params?.y;

      if (isYChange) {
        dispatch(
          updateSelectedChart({
            ...selectedChart,
            params: {
              ...selectedChart?.params,
              ...props.params,
              to: isYChange
                ? rangeOptions?.length
                  ? rangeOptions?.[rangeOptions?.length - 1]
                  : ""
                : rangeOptions.includes(props?.params?.to)
                ? props?.params?.to
                : "",
              from: isYChange
                ? rangeOptions?.length
                  ? rangeOptions?.[0]
                  : ""
                : rangeOptions.includes(props?.params?.from)
                ? props?.params?.from
                : "",
            },
          })
        );
      }

      newFilters[filterEditIndex] = {
        ...newFilters[filterEditIndex],
        params: {
          ...props.params,
          activeOptions: props?.params?.activeOptions?.filter((option) =>
            options?.includes(option)
          ),
          options: options,
          rangeOptions: rangeOptions,
          to: isYChange
            ? rangeOptions?.length
              ? rangeOptions?.[rangeOptions?.length - 1]
              : ""
            : rangeOptions.includes(props?.params?.to)
            ? props?.params?.to
            : "",
          from: isYChange
            ? rangeOptions?.length
              ? rangeOptions?.[0]
              : ""
            : rangeOptions.includes(props?.params?.from)
            ? props?.params?.from
            : "",
        },
      };
      setFilterSettings(newFilters);
      let payload = [...charts, ...statistic, ...newFilters];
      if (title.params?.dimension !== undefined) {
        payload.push(title);
      }
      dispatch(updateSpecVisualization(payload));
      callUpdateVisual(payload);
    }
  };

  const onChangeCharts = (props) => {
    if (editIndex !== null) {
      const newCharts = [...charts];
      newCharts[editIndex] = {
        ...newCharts[editIndex],
        // chartData: getChartData(newCharts[editIndex]),
        ...props,
      };

      newCharts[editIndex].params.chartData =
        [BOX_PLOT].includes(newCharts?.[editIndex]?.params?.id) &&
        newCharts?.[editIndex]?.params?.multipleSeries?.length > 0
          ? getChartData(newCharts[editIndex], editIndex)
          : [
              STACKED_COLUMN_CHART,
              STACKED_100_COLUMN_CHART,
              MULTIPLE_LINE_CHART,
            ].includes(newCharts?.[editIndex]?.params?.id)
          ? newCharts?.[editIndex]?.params?.x &&
            newCharts?.[editIndex]?.params?.multipleSeries?.length > 0
            ? getChartData(newCharts[editIndex], editIndex)
            : null
          : newCharts?.[editIndex]?.params?.x &&
            newCharts?.[editIndex]?.params?.y
          ? getChartData(newCharts[editIndex], editIndex)
          : null;

      setCharts(newCharts);
      let payload = [...newCharts, ...statistic, ...filterSettings];
      if (title.params?.dimension !== undefined) {
        payload.push(title);
      }
      dispatch(updateSpecVisualization(payload));
      callUpdateVisual(payload);
    }
  };

  const getChartData = (chart) => {
    const mockDatasetId = chart?.mockDataId;

    const mockDataset = specData?.filter(
      (data) => data?.id === mockDatasetId
    )?.[0];

    const xColumn = mockDataset?.table.columns.find(
      (item) => item.columnId === chart.params.x
    )?.columnId;
    const yColumn = mockDataset?.table.columns.find(
      (item) => item.columnId === chart.params.y
    )?.columnId;
    const timeColumn = mockDataset?.table.columns.find(
      (item) => item.columnId === chart.params.time
    )?.columnId;

    if (
      xColumn === chart?.params?.x &&
      yColumn === chart?.params?.y &&
      timeColumn === chart?.params?.time &&
      mockDataset?.table
    ) {
      return genDataForChart(
        chart.params?.id,
        mockDataset?.table,
        xColumn,
        yColumn,
        null,
        chart.params?.metric,
        chart.params?.topN || 0,
        chart.params?.multipleSeries,
        chart
      );
    }
  };

  // eslint-disable-next-line
  const callUpdateVisual = useCallback(
    debounce((chartsProp) => {
      if (isPreview) return;

      updateSpecification({
        payload: {
          specification: {
            spec_visualization_attributes: {
              visualization_data: chartsProp,
            },
          },
        },
        id: specId,
      }).then(() => {
        dispatch(updateAutoSaved(true));
        // fetchDataset();
        // dispatch(getSpecVisualizationAsync({ specId }));
        // dispatch(getSpecMockdatasetTabsAsync(specId));
      });
    }, 500),
    []
  );

  const onChartResizeCard = (layout, oldItem, newItem) => {
    // This lodash function checks if their are any changes in Item then only it goes for the layout update for specification
    if (isEqual(oldItem, newItem)) return;

    const titleElements = {
      ...title,
      params: {
        ...title.params,
        dimension: layout.find((l) => l.i === title?.params?.dimension?.i),
      },
    };

    const newChartElements = charts.map((chart) => {
      const newChart = {
        ...chart,
        params: {
          ...chart.params,
          dimension: layout.find((l) => l.i === chart?.params?.dimension?.i),
        },
      };

      if (chart?.mockDataId === selectedChart?.mockDataId) {
        dispatch(updateSelectedChart(newChart));
      }

      return newChart;
    });
    const newStatsElements = statistic.map((stats) => {
      return {
        ...stats,
        params: {
          ...stats.params,
          dimension: layout.find((l) => l.i === stats?.params?.dimension?.i),
        },
      };
    });
    const filterElement = filterSettings.map((filter) => {
      return {
        ...filter,
        params: {
          ...filter.params,
          dimension: layout.find((l) => l.i === filter?.params?.dimension?.i),
        },
      };
    });
    let junkFreePayload = removeJunk(
      newChartElements,
      newStatsElements,
      filterElement,
      titleElements
    );

    dispatch(updateSpecVisualization(junkFreePayload));

    callUpdateVisual(junkFreePayload);
    setTitle(titleElements);
    setFilterSettings(filterElement);
    setCharts(newChartElements);
    setStatistic(newStatsElements);
  };

  const handleChartsAdded = (chart, response) => {
    fetchDataset();
    let chartData = [];
    let multipleSeries = [];
    if (Object.keys(response?.coordinates).length > 2) {
      if (chart.params?.id === SCATTER_CHART) {
        chartData = genDataForChart(
          chart.params?.id,
          response?.specMockdataTab?.table,
          response?.coordinates?.y1?.columnId,
          response?.coordinates?.y2?.columnId,
          null,
          "total",
          0,
          [],
          chart
        );
      } else {
        multipleSeries = Object.keys(response?.coordinates)
          .filter((key) => key.startsWith("y"))
          .map((key) => ({
            yTitle: response?.coordinates[key].name,
            y: response?.coordinates[key].columnId,
          }));

        chartData = genDataForChart(
          chart.params?.id,
          response?.specMockdataTab?.table,
          response?.coordinates?.x?.columnId,
          null,
          null,
          "total",
          0,
          multipleSeries,
          chart
        );
      }
    } else {
      chartData = genDataForChart(
        chart.params?.id,
        response?.specMockdataTab?.table,
        response?.coordinates?.x?.columnId,
        response?.coordinates?.y?.columnId,
        null,
        "total",
        0,
        [],
        chart
      );
    }
    let newChart = { ...chart };
    newChart.params.multipleSeries = multipleSeries;

    newChart.params.chartData = { ...chartData };
    let tempCharts = [...charts];
    tempCharts.push(newChart);
    setCharts(tempCharts);

    setTimeout(() => {
      bottomRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });

      setEditIndex(tempCharts?.length - 1);
      setFilterEditIndex(null);
      setStatEditIndex(null);
      dispatch(
        getSelectedMockDatasetTabAsync({
          specId: specId,
          tabId: newChart?.mockDataId,
        })
      );
      dispatch(setIsVisualConfigOpen({ open: true, chart: newChart }));
    }, [800]);

    let payload = [...tempCharts, ...statistic, ...filterSettings];
    if (title.params?.dimension !== undefined) {
      payload.push(title);
    }
    let junkFreePayload = [];
    payload.map((item) => {
      if (item.params?.dimension !== undefined) {
        junkFreePayload.push(item);
      }
    });

    dispatch(updateSpecVisualization(junkFreePayload));

    callUpdateVisual(junkFreePayload);
  };

  const handleEditChart = (index) => {
    if (index === editIndex) {
      dispatch(setIsVisualConfigOpen({ open: false }));
      setEditIndex(null);
    } else {
      setEditIndex(index);
    }
    setStatEditIndex(null);
    setFilterEditIndex(null);
  };

  const handleEditStat = (index) => {
    if (index === statEditIndex) {
      setStatEditIndex(null);
      dispatch(setIsVisualConfigOpen({ open: false }));
    } else {
      setStatEditIndex(index);
    }

    setEditIndex(null);
    setFilterEditIndex(null);
  };

  const handleFilterEditIndex = (index) => {
    if (index === filterEditIndex) {
      setFilterEditIndex(null);
      dispatch(setIsVisualConfigOpen({ open: false }));
    } else {
      setFilterEditIndex(index);
    }

    setEditIndex(null);
    setStatEditIndex(null);
  };

  const moveVisual = useCallback((dragIndex, hoverIndex) => {
    setCharts((prevItems) => {
      const updatedElements = [...prevItems];
      const [draggedElement] = updatedElements.splice(dragIndex, 1);
      updatedElements.splice(hoverIndex, 0, draggedElement);
      return updatedElements;
    });
  }, []);

  const handleEditTitle = (props) => {
    setTitle({ ...title, ...props });
    let junkFreePayload = removeJunk(charts, statistic, filterSettings, {
      ...title,
      ...props,
    });
    callUpdateVisual(junkFreePayload);
  };

  const handleAddTitle = () => {
    setTitle({
      type: TITLE,
      text: "",
      params: {
        x: 0,
        y: 0,
        dimension: {
          i: Math.random().toString(16).slice(2),
          x: 0,
          y: 0,
          h: 1,
          w: 12,
          minW: 6,
        },
      },
    });

    setTimeout(() => {
      const element = document.getElementById("title-block");
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }, [500]);

    // setNeedUpdate(true);
  };

  const handleAddFilter = () => {
    setEditIndex(null);
    setStatEditIndex(null);
    setFilterEditIndex(null);
    dispatch(
      setIsVisualConfigOpen({
        open: false,
        chart: null,
      })
    );
    dispatch(
      getDefaultDataChartsAsync({
        specId: specId,
        payload: {
          chart_type: "filter",
        },
      })
    )
      .unwrap()
      .then(async (response) => {
        dispatch(updateCurrenSelectedTab(response?.specMockdataTab?.id));
        fetchDataset();

        let tempHeight = 0;
        let y = 0;
        y = charts?.reduce((value, chart) => {
          if (chart?.params?.dimension?.y >= value) {
            tempHeight = chart?.params.dimension.h;
            return chart?.params.dimension.y;
          }
          return value;
        }, 0);

        const rangeOptions = getRangeFilterOptions(
          response?.specMockdataTab?.table,
          response.coordinates?.y?.columnId
        );

        const newFilter = {
          type: FILTER,
          mockDataId: response?.specMockdataTab?.id,
          params: {
            title: "Filter",
            filterByTerm: "",
            table: response?.specMockdataTab?.id,
            x: response.coordinates?.x?.columnId,
            y: response.coordinates?.y?.columnId,
            value: 0,
            isRange: false,
            to: rangeOptions?.length
              ? rangeOptions?.[rangeOptions?.length - 1]
              : "",
            from: rangeOptions?.length ? rangeOptions?.[0] : "",
            searchTermID: "0",
            activeOptions: [],
            options: getFilterOptions(
              response?.specMockdataTab?.table,
              response.coordinates?.x?.columnId
            ),
            rangeOptions: rangeOptions,
            dimension: {
              i: Math.random().toString(16).slice(2),
              x: 0,
              y: y + tempHeight,
              h: 1,
              // w: 6,
              // minW: 5,
              w: 4,
              minW: 3,
              // maxW: 0,
              // minH: 0,
              // maxH: 0,
            },
          },
        };

        let filterElement = [...filterSettings, newFilter];

        setFilterSettings([...filterElement]);

        setTimeout(() => {
          bottomRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });

          //
          setEditIndex(null);
          setFilterEditIndex(filterElement?.length - 1);
          setStatEditIndex(null);
          dispatch(
            getSelectedMockDatasetTabAsync({
              specId: specId,
              tabId: newFilter?.mockDataId,
            })
          );
          dispatch(setIsVisualConfigOpen({ open: true, chart: newFilter }));
          //
        }, [500]);

        let junkFreePayload = removeJunk(
          charts,
          statistic,
          filterElement,
          title
        );
        callUpdateVisual(junkFreePayload);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const onChangeFilter = (data, i) => {
    let filterElement = filterSettings.map((filter) => {
      if (i === filter?.params?.dimension?.i) {
        const newFilter = {
          ...filter,
          ...data,
          params: {
            ...filter.params,
            ...data.params,
          },
        };
        return newFilter;
      }
      return filter;
    });

    if (
      selectedChart?.type === "Filter" &&
      selectedChart?.params?.dimension?.i === i
    ) {
      dispatch(
        updateSelectedChart({
          ...selectedChart,
          params: { ...selectedChart?.params, ...data.params },
        })
      );
    }

    setFilterSettings(filterElement);
    let junkFreePayload = removeJunk(charts, statistic, filterElement, title);
    dispatch(updateSpecVisualization(junkFreePayload));
    callUpdateVisual(junkFreePayload);
  };

  const handleDeleteFilter = (mockDataId, index) => {
    setFilterEditIndex(null);
    dispatch(
      setIsVisualConfigOpen({
        open: false,
        chart: null,
      })
    );

    const newSpecData = [...specData];
    const filteredArray = newSpecData?.filter(
      (item) => item?.id !== mockDataId
    );
    dispatch(updateSpecMockdataTabs(filteredArray));

    const newFilterSettings = [...filterSettings];
    newFilterSettings.splice(index, 1);
    setFilterSettings(newFilterSettings);

    let junkFreePayload = removeJunk(
      charts,
      statistic,
      newFilterSettings,
      title
    );
    dispatch(updateSpecVisualization(junkFreePayload));
    callUpdateVisual(junkFreePayload);
  };

  const handleDeleteChart = (index, mockDataId) => {
    setEditIndex(null);
    dispatch(
      setIsVisualConfigOpen({
        open: false,
        chart: null,
      })
    );

    const newSpecData = [...specData];
    // newSpecData.splice(index, 1);
    const filteredArray = newSpecData?.filter(
      (item) => item?.id !== mockDataId
    );
    dispatch(updateSpecMockdataTabs(filteredArray));

    const newChartElements = [...charts];
    newChartElements.splice(index, 1);
    setCharts(newChartElements);
    let junkFreePayload = removeJunk(
      newChartElements,
      statistic,
      filterSettings,
      title
    );

    dispatch(updateSpecVisualization(junkFreePayload));

    callUpdateVisual(junkFreePayload);
  };
  const handleDeleteStatistic = (mockId, index) => {
    setStatEditIndex(null);
    dispatch(
      setIsVisualConfigOpen({
        open: false,
        chart: null,
      })
    );

    const newSpecData = [...specData];
    const filteredArray = newSpecData?.filter((item) => item?.id !== mockId);

    dispatch(updateSpecMockdataTabs(filteredArray));

    const newStatistic = [...statistic];
    newStatistic.splice(index, 1);
    setStatistic(newStatistic);

    let junkFreePayload = removeJunk(
      charts,
      newStatistic,
      filterSettings,
      title
    );

    dispatch(updateSpecVisualization(junkFreePayload));

    callUpdateVisual(junkFreePayload);
  };

  const handleDeleteTitle = () => {
    setTitle({});
    let junkFreePayload = removeJunk(charts, statistic, filterSettings, {});
    callUpdateVisual(junkFreePayload);
  };

  const handleAddStatistic = async () => {
    setEditIndex(null);
    setStatEditIndex(null);
    setFilterEditIndex(null);
    dispatch(
      setIsVisualConfigOpen({
        open: false,
        chart: null,
      })
    );
    dispatch(
      getDefaultDataChartsAsync({
        specId: specId,
        payload: {
          chart_type: "statistics",
        },
      })
    )
      .unwrap()
      .then(async (response) => {
        dispatch(updateCurrenSelectedTab(response?.specMockdataTab?.id));
        fetchDataset();

        let tempHeight = 0;
        let y = 0;

        y = charts?.reduce((value, chart) => {
          if (chart?.params?.dimension?.y >= value) {
            tempHeight = chart?.params.dimension.h;
            return chart?.params.dimension.y;
          }
          return value;
        }, 0);

        const newStat = {
          type: STATISTIC,
          mockDataId: response?.specMockdataTab?.id,
          params: {
            title: "Statistic",
            table: response?.specMockdataTab?.id,
            x: response.coordinates?.x?.columnId,
            value: getCalculatedValueForStatistics(
              response?.specMockdataTab?.table,
              response.coordinates?.x?.columnId,
              "total"
            ),
            metric: "total",
            y: 0,
            func: { id: 0, label: "" },
            dimension: {
              i: Math.random().toString(16).slice(2),
              x: 0,
              y: y + tempHeight,
              h: 1,
              w: 3,
              minW: 3,
            },
          },
        };

        let statisticElement = [...statistic, newStat];

        setStatistic(statisticElement);

        setTimeout(() => {
          bottomRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });

          //
          setEditIndex(null);
          setFilterEditIndex(null);
          setStatEditIndex(statisticElement?.length - 1);
          dispatch(
            getSelectedMockDatasetTabAsync({
              specId: specId,
              tabId: newStat?.mockDataId,
            })
          );
          dispatch(setIsVisualConfigOpen({ open: true, chart: newStat }));
          //
        }, [500]);

        let junkFreePayload = removeJunk(
          charts,
          statisticElement,
          filterSettings,
          title
        );
        callUpdateVisual(junkFreePayload);
      })
      .catch((error) => {
        console.log("Error", error);
      });

    // let tempHeight = 0;
    // let y = 0;

    // y = charts?.reduce((value, chart) => {
    //   if (chart?.params?.dimension?.y >= value) {
    //     tempHeight = chart?.params.dimension.h;
    //     return chart?.params.dimension.y;
    //   }
    //   return value;
    // }, 0);

    // let statisticElement = [
    //   ...statistic,
    //   {
    //     type: STATISTIC,
    //     params: {
    //       title: "Statistic",
    //       table: 0,
    //       value: 0,
    //       metric: { id: 0, label: "" },
    //       y: 0,
    //       func: { id: 0, label: "" },
    //       dimension: {
    //         i: Math.random().toString(16).slice(2),
    //         x: 0,
    //         y: y + tempHeight,
    //         h: 1,
    //         w: 3,
    //         minW: 3,
    //       },
    //     },
    //   },
    // ];

    // setStatistic(statisticElement);

    // setTimeout(() => {
    //   bottomRef.current?.scrollIntoView({
    //     behavior: "smooth",
    //     block: "nearest",
    //   });
    // }, [500]);

    // let junkFreePayload = removeJunk(
    //   charts,
    //   statisticElement,
    //   filterSettings,
    //   title
    // );
    // callUpdateVisual(junkFreePayload);
  };

  const handlePaste = (mockDataId, type) => {
    setIsPasteLoading(true);
    copyPasteVisual({ specId: specId, tabId: mockDataId })
      .then((response) => {
        fetchDataset();
        setIsPasteLoading(false);
        const data = response?.data?.specVisualization;
        const id = response?.data?.specMockdataTabId;
        data.forEach((item) => {
          if (item.mockDataId === id) {
            item.params.dimension.y = Infinity;
          }
        });

        dispatch(setCopiedVisualization(null));
        dispatch(updateSpecVisualization(data));

        const totalCharts =
          data?.filter(
            (item) =>
              ![SEARCH, TITLE, TITLE.toLowerCase(), FILTER, STATISTIC].includes(
                item.type
              )
          ) || [];

        const totalFilter = data?.filter((item) => item.type === FILTER) || [];

        const totalStat = data?.filter((item) => item.type === STATISTIC) || [];

        setTimeout(() => {
          bottomRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
          if (type === "filter") {
            setFilterEditIndex(totalFilter?.length - 1);
            setStatEditIndex(null);
            setEditIndex(null);
          } else if (type === "statistic") {
            setStatEditIndex(totalStat?.length - 1);
            setFilterEditIndex(null);
            setEditIndex(null);
          } else {
            setEditIndex(totalCharts?.length - 1);
            setStatEditIndex(null);
            setFilterEditIndex(null);
          }
          dispatch(
            getSelectedMockDatasetTabAsync({
              specId: specId,
              tabId: id,
            })
          );
          dispatch(
            setIsVisualConfigOpen({
              open: true,
              chart: data?.[data?.length - 1],
            })
          );
        }, [800]);
      })
      .catch((error) => {
        setIsPasteLoading(false);
        console.log("Error", error);
      });
  };

  const updateSelectedDimension = (params, dimensionsArray) => {
    const dimensionId = params?.dimension?.i;
    const matchingDimension = dimensionsArray.find(
      (dimension) => dimension.i === dimensionId
    );

    if (matchingDimension) {
      return { ...params, dimension: matchingDimension };
    }

    return params;
  };

  // const onClickSwitchVisual = (chart) => {
  //   console.log("chart", chart);
  //   setIsSwitchVisualOpen(!isSwitchVisualOpen);
  //   setSwitchVisualData(chart);
  // };

  useEffect(() => {
    const updatedParams = updateSelectedDimension(params, layout?.layouts);

    if (selectedChart?.type === "Statistic") {
      onStatChange({ params: { ...updatedParams } });
    } else if (selectedChart?.type === "Filter") {
      onChangeFilterConfig({ params: { ...updatedParams } });
    } else {
      onChangeCharts({
        label,
        params: { ...updatedParams },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label, params, specData]);

  useEffect(() => {
    return () => {
      dispatch(
        setIsVisualConfigOpen({
          open: false,
          chart: null,
        })
      );
      dispatch(resetSpecVisualization());
    };
  }, [dispatch]);

  // FOR EDIT MODAL - CUSTOM FIELDS
  useEffect(() => {
    dispatch(getMappingColumnsAsync());
  }, [dispatch]);

  return (
    // <DndProvider backend={HTML5Backend}>
    <Stack sx={{ height: 1, width: 1 }} className='hello-main-stack'>
      {reduxState?.isVisualisationloading ? (
        <Stack
          sx={{
            height: 1,
            width: 1,
            justifyContent: "center",
            alignItems: "center",
          }}>
          <CircularProgress size={50} />
        </Stack>
      ) : (
        <>
          {!isPreview && (
            <VisualMockupTopBar
              addCharts={(chart, response) => {
                handleChartsAdded(chart, response);
              }}
              charts={charts}
              setEditIndex={setEditIndex}
              addTitle={handleAddTitle}
              title={title}
              addStatistic={handleAddStatistic}
              addFilters={handleAddFilter}
              // addChartData={(data)=>handleAddChartData(data)}
            />
          )}
          <Stack
            sx={{ width: 1, height: isPreview ? 1 : `calc(100% - 55px)` }}
            direction={"row"}>
            <Box
              sx={{
                width: 1,
                height: 1,
                backgroundColor: specPrimaryGray["backgroundGray"],
                overflowY: "auto",
              }}>
              {!isPreview ? (
                isMobileView ? (
                  <Box
                    sx={{
                      height: 1,
                      width: 1,
                      overflowY: "auto",
                    }}>
                    <Stack
                      height={1}
                      width={1}
                      rowGap={2}
                      alignContent={"center"}
                      pt={2}>
                      {title?.type && (
                        <Box
                          sx={{ height: "150px", width: "100%" }}
                          id='title-block'>
                          <TitleBlock
                            isPreview={isPreview}
                            element={title}
                            handleDelete={handleDeleteTitle}
                            onChange={handleEditTitle}
                          />
                        </Box>
                      )}

                      {statistic?.map((item, index) => (
                        <Box
                          sx={{ height: "250px", width: "99%" }}
                          key={item?.params?.dimension?.i || index}>
                          <StatBlock
                            isPreview={isPreview}
                            key={item?.params?.dimension?.i || index}
                            item={item}
                            statistic={statistic}
                            setStatistic={setStatistic}
                            index={index}
                            charts={charts}
                            mockDataset={specData}
                            data={filteredData}
                            handleEdit={handleEditStat}
                            statEditIndex={statEditIndex}
                            handleDeleteStatistic={handleDeleteStatistic}
                            setOpenSideBar={() => {
                              dispatch(
                                getSelectedMockDatasetTabAsync({
                                  specId: specId,
                                  tabId: item?.mockDataId,
                                })
                              );
                              dispatch(
                                setIsVisualConfigOpen({
                                  open: true,
                                  chart: item,
                                })
                              );
                            }}
                            handlePaste={handlePaste}
                          />
                        </Box>
                      ))}

                      {filterSettings?.map((item, index) => (
                        <Box
                          sx={{ width: "99%" }}
                          key={item?.params?.dimension?.i || index}>
                          <FilterBlock
                            isPreview={isPreview}
                            index={index}
                            key={item?.params?.dimension?.i || index}
                            filterSettings={filterSettings}
                            setFilterSettings={setFilterSettings}
                            element={item}
                            handleDelete={handleDeleteFilter}
                            onChange={onChangeFilter}
                            mockDataset={specData}
                            handleEdit={handleFilterEditIndex}
                            filterEditIndex={filterEditIndex}
                            setOpenSideBar={() => {
                              dispatch(
                                getSelectedMockDatasetTabAsync({
                                  specId: specId,
                                  tabId: item?.mockDataId,
                                })
                              );
                              dispatch(
                                setIsVisualConfigOpen({
                                  open: true,
                                  chart: item,
                                })
                              );
                            }}
                            handlePaste={handlePaste}
                          />
                        </Box>
                      ))}

                      {charts?.map((chart, index) => (
                        <Box
                          sx={{ height: "400px", width: "100%" }}
                          key={chart?.params?.dimension?.i || index}>
                          <VisualCard
                            isPreview={isPreview}
                            key={chart?.params?.dimension?.i || index}
                            chart={chart}
                            index={index}
                            label={label}
                            mockDataset={filteredData[index]}
                            editIndex={editIndex}
                            handleEdit={handleEditChart}
                            handleDelete={handleDeleteChart}
                            onChangeVisual={onChangeCharts}
                            moveItem={moveVisual}
                            filterSettings={filterSettings}
                            setOpenSideBar={() => {
                              dispatch(
                                getSelectedMockDatasetTabAsync({
                                  specId: specId,
                                  tabId: chart?.mockDataId,
                                })
                              );
                              dispatch(
                                setIsVisualConfigOpen({ open: true, chart })
                              );
                            }}
                          />
                        </Box>
                      ))}
                      <div ref={bottomRef} />
                    </Stack>
                  </Box>
                ) : (
                  <>
                    {/* <Box sx={{ padding: "30px" }}></Box> */}
                    {!statistic?.length > 0 &&
                      !filterSettings?.length &&
                      !charts?.length &&
                      !title?.type && (
                        <Box
                          sx={{
                            height: `calc(100% - 55px)`,
                            width: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          <Stack
                            justifyContent={"center"}
                            alignItems={"center"}
                            rowGap={1}>
                            <Typography variant='spec_bold_title'>
                              Visualization
                            </Typography>
                            <Typography
                              variant='spec_bold_subtitle'
                              sx={{ textTransform: "none" }}>
                              Create your prototype by first adding visuals
                            </Typography>
                            <VisualMockupTopBar
                              addCharts={(chart, response) => {
                                handleChartsAdded(chart, response);
                              }}
                              charts={charts}
                              setEditIndex={setEditIndex}
                              addTitle={handleAddTitle}
                              title={title}
                              addStatistic={handleAddStatistic}
                              addFilters={handleAddFilter}
                              // addChartData={(data)=>handleAddChartData(data)}
                              isBar={false}
                            />
                          </Stack>
                        </Box>
                      )}
                    <ResponsiveReactGridLayout
                      layouts={{
                        lg: layout.layouts,
                      }}
                      className='layout'
                      onDragStop={onChartMoveCard}
                      onResizeStop={onChartResizeCard}
                      measureBeforeMount={false}
                      useCSSTransforms={layout.mounted}
                      compactType={layout.compactType}
                      preventCollision={!layout.compactType}
                      margin={[10, 10]}
                      breakpoints={{ lg: 100 }}
                      isResizable={true}
                      style={{ padding: "10px 0px" }}
                      isDraggable={true}
                      // draggableHandle={".handleDraggableElement"}
                      draggableCancel='.cancelDraggableElement' //This class can be added to any element who you don't want to have Draggable functionality
                      cols={{ lg: 12 }}>
                      {charts.map((chart, index) => (
                        <div key={chart?.params?.dimension?.i || index}>
                          <VisualCard
                            isPreview={isPreview}
                            chart={chart}
                            index={index}
                            label={label}
                            mockDataset={filteredData[index]}
                            editIndex={editIndex}
                            handleEdit={handleEditChart}
                            handleDelete={handleDeleteChart}
                            onChangeVisual={onChangeCharts}
                            moveItem={moveVisual}
                            filterSettings={filterSettings}
                            setOpenSideBar={() => {
                              dispatch(
                                getSelectedMockDatasetTabAsync({
                                  specId: specId,
                                  tabId: chart?.mockDataId,
                                })
                              );
                              dispatch(
                                setIsVisualConfigOpen({ open: true, chart })
                              );
                            }}
                            handlePaste={handlePaste}
                          />
                        </div>
                      ))}
                      {title?.type && (
                        <div
                          key={title?.params?.dimension?.i || 0}
                          id='title-block'>
                          <TitleBlock
                            isPreview={isPreview}
                            element={title}
                            handleDelete={handleDeleteTitle}
                            onChange={handleEditTitle}
                          />
                        </div>
                      )}
                      {statistic?.map((item, index) => (
                        <div key={item?.params?.dimension?.i || 0}>
                          <StatBlock
                            isPreview={isPreview}
                            item={item}
                            statistic={statistic}
                            setStatistic={setStatistic}
                            index={index}
                            charts={charts}
                            mockDataset={specData}
                            data={filteredData}
                            setNeedUpdate={(newStatistics) => {
                              let junkFreePayload = removeJunk(
                                charts,
                                newStatistics,
                                filterSettings,
                                title
                              );
                              callUpdateVisual(junkFreePayload);
                            }}
                            handleEdit={handleEditStat}
                            statEditIndex={statEditIndex}
                            setOpenSideBar={() => {
                              dispatch(
                                getSelectedMockDatasetTabAsync({
                                  specId: specId,
                                  tabId: item?.mockDataId,
                                })
                              );
                              dispatch(
                                setIsVisualConfigOpen({
                                  open: true,
                                  chart: item,
                                })
                              );
                            }}
                            handleDeleteStatistic={handleDeleteStatistic}
                            handlePaste={handlePaste}
                          />
                        </div>
                      ))}
                      {filterSettings?.map((item, index) => (
                        <Box key={item?.params?.dimension?.i || 0}>
                          <FilterBlock
                            isPreview={isPreview}
                            index={index}
                            filterSettings={filterSettings}
                            setFilterSettings={setFilterSettings}
                            element={item}
                            handleDelete={handleDeleteFilter}
                            onChange={onChangeFilter}
                            mockDataset={specData}
                            handleEdit={handleFilterEditIndex}
                            filterEditIndex={filterEditIndex}
                            setOpenSideBar={() => {
                              dispatch(
                                getSelectedMockDatasetTabAsync({
                                  specId: specId,
                                  tabId: item?.mockDataId,
                                })
                              );
                              dispatch(
                                setIsVisualConfigOpen({
                                  open: true,
                                  chart: item,
                                })
                              );
                            }}
                            handlePaste={handlePaste}
                          />
                        </Box>
                      ))}
                    </ResponsiveReactGridLayout>{" "}
                    <div ref={bottomRef} />
                  </>
                )
              ) : isMobileView ? (
                <Box
                  sx={{
                    height: 1,
                    width: 1,
                    overflowY: "auto",
                  }}>
                  <Stack
                    height={1}
                    width={1}
                    rowGap={2}
                    alignContent={"center"}
                    pt={2}>
                    {title?.type && (
                      <Box
                        sx={{ height: "150px", width: "100%" }}
                        id='title-block'>
                        <TitleBlock
                          isPreview={isPreview}
                          element={title}
                          handleDelete={handleDeleteTitle}
                          onChange={handleEditTitle}
                        />
                      </Box>
                    )}

                    {statistic?.map((item, index) => (
                      <Box
                        sx={{ height: "250px", width: "99%" }}
                        key={item?.params?.dimension?.i || index}>
                        <StatBlock
                          isPreview={isPreview}
                          key={item?.params?.dimension?.i || index}
                          item={item}
                          statistic={statistic}
                          setStatistic={setStatistic}
                          index={index}
                          charts={charts}
                          mockDataset={specData}
                          data={filteredData}
                          handleEdit={handleEditStat}
                          statEditIndex={statEditIndex}
                          handleDeleteStatistic={handleDeleteStatistic}
                          setOpenSideBar={() => {
                            dispatch(
                              getSelectedMockDatasetTabAsync({
                                specId: specId,
                                tabId: item?.mockDataId,
                              })
                            );
                            dispatch(
                              setIsVisualConfigOpen({
                                open: true,
                                chart: item,
                              })
                            );
                          }}
                          handlePaste={handlePaste}
                        />
                      </Box>
                    ))}

                    {filterSettings?.map((item, index) => (
                      <Box
                        sx={{ width: "99%" }}
                        key={item?.params?.dimension?.i || index}>
                        <FilterBlock
                          isPreview={isPreview}
                          index={index}
                          key={item?.params?.dimension?.i || index}
                          filterSettings={filterSettings}
                          setFilterSettings={setFilterSettings}
                          element={item}
                          handleDelete={handleDeleteFilter}
                          onChange={onChangeFilter}
                          mockDataset={specData}
                          handleEdit={handleFilterEditIndex}
                          filterEditIndex={filterEditIndex}
                          setOpenSideBar={() => {
                            dispatch(
                              getSelectedMockDatasetTabAsync({
                                specId: specId,
                                tabId: item?.mockDataId,
                              })
                            );
                            dispatch(
                              setIsVisualConfigOpen({
                                open: true,
                                chart: item,
                              })
                            );
                          }}
                          handlePaste={handlePaste}
                        />
                      </Box>
                    ))}

                    {charts?.map((chart, index) => (
                      <Box
                        sx={{ height: "400px", width: "100%" }}
                        key={chart?.params?.dimension?.i || index}>
                        <VisualCard
                          isPreview={isPreview}
                          key={chart?.params?.dimension?.i || index}
                          chart={chart}
                          index={index}
                          label={label}
                          mockDataset={filteredData[index]}
                          editIndex={editIndex}
                          handleEdit={handleEditChart}
                          handleDelete={handleDeleteChart}
                          onChangeVisual={onChangeCharts}
                          moveItem={moveVisual}
                          filterSettings={filterSettings}
                          setOpenSideBar={() => {
                            dispatch(
                              getSelectedMockDatasetTabAsync({
                                specId: specId,
                                tabId: chart?.mockDataId,
                              })
                            );
                            dispatch(
                              setIsVisualConfigOpen({ open: true, chart })
                            );
                          }}
                        />
                      </Box>
                    ))}
                    <div ref={bottomRef} />
                  </Stack>
                </Box>
              ) : (
                <>
                  {!statistic?.length > 0 &&
                    !filterSettings?.length &&
                    !charts?.length &&
                    !title?.type && (
                      <Box
                        sx={{
                          height: `calc(100% - 55px)`,
                          width: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <Stack
                          justifyContent={"center"}
                          alignItems={"center"}
                          rowGap={1}
                          sx={{
                            width: "80%",
                          }}>
                          <NoRowsOverlayCustomComponent
                            noRowsMessageFunc={() =>
                              ` No visual mocks have been added. If you are an editor, click into “edit mode” to make changes.`
                            }
                          />
                        </Stack>
                      </Box>
                    )}
                  <ResponsiveReactGridLayout
                    layouts={{
                      lg: layout.layouts,
                    }}
                    className='layout'
                    onDragStop={onChartMoveCard}
                    onResizeStop={onChartResizeCard}
                    measureBeforeMount={false}
                    useCSSTransforms={layout.mounted}
                    compactType={layout.compactType}
                    preventCollision={!layout.compactType}
                    margin={[10, 10]}
                    breakpoints={{ lg: 100 }}
                    isResizable={false}
                    style={{ padding: "10px 0px" }}
                    isDraggable={false}
                    // draggableHandle={".handleDraggableElement"}
                    draggableCancel='.cancelDraggableElement' //This class can be added to any element who you don't want to have Draggable functionality
                    cols={{ lg: 12 }}>
                    {charts.map((chart, index) => (
                      <div key={chart?.params?.dimension?.i || index}>
                        <VisualCard
                          isPreview={isPreview}
                          chart={chart}
                          index={index}
                          label={label}
                          mockDataset={filteredData[index]}
                          editIndex={editIndex}
                          handleEdit={handleEditChart}
                          handleDelete={handleDeleteChart}
                          onChangeVisual={onChangeCharts}
                          moveItem={moveVisual}
                          filterSettings={filterSettings}
                          setOpenSideBar={() => {
                            dispatch(
                              getSelectedMockDatasetTabAsync({
                                specId: specId,
                                tabId: chart?.mockDataId,
                              })
                            );
                            dispatch(
                              setIsVisualConfigOpen({ open: true, chart })
                            );
                          }}
                        />
                      </div>
                    ))}
                    {title?.type && !_.isEmpty(title?.text) && (
                      <div key={title?.params?.dimension?.i || 0}>
                        <TitleBlock
                          isPreview={isPreview}
                          element={title}
                          handleDelete={handleDeleteTitle}
                          onChange={handleEditTitle}
                        />
                      </div>
                    )}
                    {statistic?.map((item, index) => (
                      <div key={item?.params?.dimension?.i || 0}>
                        <StatBlock
                          isPreview={isPreview}
                          item={item}
                          statistic={statistic}
                          setStatistic={setStatistic}
                          index={index}
                          charts={charts}
                          mockDataset={specData}
                          data={filteredData}
                          handleEdit={handleEditStat}
                          statEditIndex={statEditIndex}
                          handleDeleteStatistic={handleDeleteStatistic}
                          setOpenSideBar={() => {
                            dispatch(
                              getSelectedMockDatasetTabAsync({
                                specId: specId,
                                tabId: item?.mockDataId,
                              })
                            );
                            dispatch(
                              setIsVisualConfigOpen({ open: true, chart: item })
                            );
                          }}
                          handlePaste={handlePaste}
                        />
                      </div>
                    ))}
                    {filterSettings?.map((item, index) => (
                      <Box key={item?.params?.dimension?.i || 0}>
                        <FilterBlock
                          index={index}
                          isPreview={isPreview}
                          filterSettings={filterSettings}
                          setFilterSettings={setFilterSettings}
                          element={item}
                          handleDelete={handleDeleteFilter}
                          onChange={onChangeFilter}
                          mockDataset={specData}
                          handleEdit={handleFilterEditIndex}
                          filterEditIndex={filterEditIndex}
                          setOpenSideBar={() => {
                            dispatch(
                              getSelectedMockDatasetTabAsync({
                                specId: specId,
                                tabId: item?.mockDataId,
                              })
                            );
                            dispatch(
                              setIsVisualConfigOpen({ open: true, chart: item })
                            );
                          }}
                          handlePaste={handlePaste}
                        />
                      </Box>
                    ))}
                  </ResponsiveReactGridLayout>{" "}
                </>
              )}
              {/* <div ref={bottomRef} /> */}
            </Box>
            <VisualConfigDrawer
              isPreview={isPreview}
              setParams={setParams}
              mockDataset={specData}
              chart={selectedChart}
              params={params}
              label={label}
              setLabel={setLabel}
              onClose={() => {
                setEditIndex(null);
                setStatEditIndex(null);
                handleFilterEditIndex(null);
              }}
              // onClickSwitchVisual={onClickSwitchVisual}
            />
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isPasteLoading}
              onClick={() => {}}>
              <CircularProgress color='primary' />
            </Backdrop>
          </Stack>
          {/* <BaseModal
            isOpen={isSwitchVisualOpen}
            handleClose={() => {
              setIsSwitchVisualOpen(false);
              setSwitchVisualData(null);
              setChartSelectedForSwitch(null);
            }}
            title='Switch Visual'
            confirmTitle='Switch'
            submitDisable={!chartSelectedForSwitch}>
            <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
              <InputLabel>
                <Typography variant='spec_inputLabel'>
                  Select visual to switch with
                </Typography>
              </InputLabel>
              <MuiSelectField
                id='chartType'
                placeholder='Select Aggregation'
                value={chartSelectedForSwitch || 0}
                onChange={(e) => {
                  setChartSelectedForSwitch(e.target.value);
                }}
                sx={{
                  width: "100%",
                  "#table": { display: "flex" },
                  ":has(div)": { color: "#8c8c8c", alignItems: "end" },
                }}>
                <MenuItem value={0} disabled>
                  <Typography variant='spec_body'>Select Visual</Typography>
                </MenuItem>
                {chartTypesArray.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.type}
                    sx={{ display: "flex", alignItems: "end" }}>
                    <Typography mr={1} color='text.primary' variant='spec_body'>
                      {item.label}
                    </Typography>
                  </MenuItem>
                ))}
              </MuiSelectField>
              {chartSelectedForSwitch && (
                <Typography
                  variant='spec_body'
                  color={"error"}
                  sx={{ pt: 2, fontWeight: 500 }}>
                  Are you sure you want to switch the current visual with the
                  newly selected visual?
                </Typography>
              )}
            </Stack>
          </BaseModal> */}
        </>
      )}
    </Stack>
    // </DndProvider>
  );
}

export default VisualMockup;
function removeJunk(
  newChartElements,
  newStatsElements,
  filterElement,
  titleElements
) {
  let payload = [];
  if (titleElements.params?.dimension !== undefined) {
    payload.push(titleElements);
  }
  payload = [
    ...payload,
    ...newChartElements,
    ...newStatsElements,
    ...filterElement,
  ];
  let junkFreePayload = [];
  payload.map((item) => {
    if (item.params?.dimension !== undefined) {
      junkFreePayload.push(item);
    }
  });
  return junkFreePayload;
}
