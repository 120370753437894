import React from "react";
import { FullLogo } from "../../assets";
import { Outlet } from "react-router-dom";
import { LogoHolder, MainArea, PageWrapper } from "./style";

const OnboardingLayout = ({ children }) => {
  return (
    <PageWrapper>
      <LogoHolder>
        <FullLogo />
      </LogoHolder>
      <MainArea>
        {children}
        <Outlet />
      </MainArea>
    </PageWrapper>
  );
};

export default OnboardingLayout;
