import { styled as MuiStyled, Stack, alpha } from "@mui/material";

export const StyledMenuItem = MuiStyled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  columnGap: theme.spacing(1.5),
  borderRadius: theme.spacing(1),
  cursor: "pointer",
  padding: "12px",
  ":hover": {
    backgroundColor: alpha(theme.palette.primary.light, 0.3),
  },
}));
