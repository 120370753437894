export const mentionStyle = (theme, isView) => ({
  fontFamily: `'Poppins', sans-serif`,
  position: "relative",
  zIndex: 1,
  lineHeight: "normal",
  color: theme.palette.info.main,
  textShadow: `1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
        -1px -1px 1px white`, //required for mention highlight
  pointerEvents: "none",
});
