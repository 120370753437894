import { Tooltip } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";

export const ErrorTooltip = ({ className, ...rest }) => {
  return (
    <Tooltip
      {...rest}
      arrow
      classes={{ popper: className }}
      componentsProps={{
        popper: {
          sx: {
            [`& .${tooltipClasses.arrow}`]: {
              color: (theme) => theme.palette.error.main,
            },
            [`& .${tooltipClasses.tooltip}`]: {
              backgroundColor: (theme) => theme.palette.error.main,
            },
          },
        },
      }}
    />
  );
};
