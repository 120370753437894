import { HTTP_OK } from "../../constants/statusCodes";
import { defaultMessage } from "../../constants/messages";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createNewTab,
  getDefaultDataCharts,
  getSelectedMockDatasetTab,
  getSpecMockDatasetTabs,
} from "../../services";
import { defaultExceptionHandler } from "../../utils/defaultExceptionHandler";

const initialState = {
  // SPEC MOCKDATASET ALL TABS STATES
  specMockdataTabs: [],
  isSpecMockdataTabsLoading: false,
  isCreateNewTabLoading: false,
  currentSelectedTab: null,
  addEditLabelOpen: false,
  addEditLabelType: "ADD",
  labelId: null,
};

export const getSpecMockdatasetTabsAsync = createAsyncThunk(
  "mockdataset/getSpecMockdatasetTabsAsync",
  async (payload) => {
    try {
      const response = await getSpecMockDatasetTabs(payload); // Here payload is ':specId'

      if (response?.status !== HTTP_OK) {
        throw new Error("Something went wrong!");
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

export const getSelectedMockDatasetTabAsync = createAsyncThunk(
  "mockdataset/getSelectedMockDatasetTabAsync",
  async (payload) => {
    try {
      const response = await getSelectedMockDatasetTab(payload); // Here payload is ':specId'

      if (response?.status !== HTTP_OK) {
        throw new Error("Something went wrong!");
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

export const getDefaultDataChartsAsync = createAsyncThunk(
  "mockdataset/getDefaultDataChartsAsync",
  async (params) => {
    try {
      const response = await getDefaultDataCharts(
        params.specId,
        params.payload
      );

      if (response?.status !== HTTP_OK) {
        throw new Error("Something went wrong!");
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

// CREATE NEW TAB
export const createNewTabAsync = createAsyncThunk(
  "mockdataset/createNewTabAsync",
  async (payload) => {
    try {
      const response = await createNewTab(payload);
      /* 
        Here payload is:
        {
             specId,
             spec_mockdata_tab: 
             {
                name: "Name of tab", row_limit: 'row limit', table: {columns: [], rows: []}
             }
         }
         */

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

const mockDatasetSlice = createSlice({
  name: "mockdataset",
  initialState: initialState,

  reducers: {
    updateTabName: (state, action) => {
      const { id, name } = action.payload;

      const tabIndex = state.specMockdataTabs.findIndex((tab) => tab.id === id);
      if (tabIndex !== -1) {
        state.specMockdataTabs[tabIndex].name = name;
      }
    },
    updateCurrenSelectedTab: (state, action) => {
      state.currentSelectedTab = action.payload;
    },
    deleteTabById: (state, action) => {
      const idToDelete = action.payload;
      state.specMockdataTabs = state.specMockdataTabs.filter(
        (tab) => tab.id !== idToDelete
      );
    },
    replaceTable: (state, action) => {
      const { tabId, newTable } = action.payload;
      const tabIndex = state.specMockdataTabs.findIndex(
        (tab) => tab.id === tabId
      );

      if (tabIndex !== -1) {
        state.specMockdataTabs[tabIndex].table = newTable;
      }
    },
    updateSpecTab: (state, action) => {
      const { tabId, newTab } = action.payload;
      const tabIndex = state.specMockdataTabs.findIndex(
        (tab) => tab.id === tabId
      );
      if (tabIndex !== -1) {
        state.specMockdataTabs[tabIndex] = newTab;
      }
    },

    updateSpecTabCell: (state, action) => {
      const { tabId, payload } = action.payload;
      const tabIndex = state.specMockdataTabs.findIndex(
        (tab) => tab.id === tabId
      );
      if (tabIndex !== -1) {
        state.specMockdataTabs[tabIndex].table.rows[payload?.row_index][
          payload?.dataset_column_id
        ] = payload?.value;
      }
    },

    setAddEditLabel: (state, action) => {
      state.addEditLabelOpen = action.payload?.addEditLabelOpen;
      state.addEditLabelType = action.payload?.addEditLabelType;
      state.labelId = action.payload?.labelId;
    },

    unsetAddEditLabel: (state, action) => {
      state.addEditLabelOpen = false;
      state.addEditLabelType = "ADD";
      state.labelId = null;
    },

    updateSpecMockdataTabs: (state, action) => {
      state.specMockdataTabs = action.payload;
    },
  },

  extraReducers: (builder) => {
    // GET SPEC MOCKDATASET TABS BUILDER FUNCTIONS
    builder.addCase(getSpecMockdatasetTabsAsync.pending, (state, action) => {
      state.isSpecMockdataTabsLoading = true;
    });
    builder.addCase(getSpecMockdatasetTabsAsync.fulfilled, (state, action) => {
      state.isSpecMockdataTabsLoading = false;
      state.specMockdataTabs = action?.payload?.specMockdataTabs;

      if (action?.payload?.specMockdataTabs?.length) {
        // state.currentSelectedTab = action?.payload?.specMockdataTabs?.[0]?.id;
      }
    });
    builder.addCase(getSpecMockdatasetTabsAsync.rejected, (state) => {
      state.isSpecMockdataTabsLoading = false;
    });

    // GET SELECTED SPEC MOCKDATASET TABS BUILDER FUNCTIONS
    builder.addCase(getSelectedMockDatasetTabAsync.pending, (state, action) => {
      state.isSpecMockdataTabsLoading = true;
    });
    builder.addCase(
      getSelectedMockDatasetTabAsync.fulfilled,
      (state, action) => {
        state.isSpecMockdataTabsLoading = false;

        const tabId = action?.payload?.specMockdataTab?.id;

        const existingIndex = state.specMockdataTabs.findIndex(
          (tab) => tab.id === tabId
        );

        if (existingIndex !== -1) {
          state.specMockdataTabs[existingIndex] =
            action?.payload?.specMockdataTab;
        } else {
          state.specMockdataTabs.push(action?.payload?.specMockdataTab);
        }

        if (action?.payload?.specMockdataTab?.id) {
          state.currentSelectedTab = action?.payload?.specMockdataTab?.id;
        }
      }
    );
    builder.addCase(getSelectedMockDatasetTabAsync.rejected, (state) => {
      state.isSpecMockdataTabsLoading = false;
    });

    // CREATE A NEW MOCKDATASET TAB BUILDER FUNCTIONS
    builder.addCase(createNewTabAsync.pending, (state, action) => {
      state.isCreateNewTabLoading = true;
    });
    builder.addCase(createNewTabAsync.fulfilled, (state, action) => {
      state.isCreateNewTabLoading = false;
      state.specMockdataTabs = [
        ...state.specMockdataTabs,
        action?.payload?.specMockdataTab,
      ];
      state.currentSelectedTab = action?.payload?.specMockdataTab?.id;
    });
    builder.addCase(createNewTabAsync.rejected, (state) => {
      state.isCreateNewTabLoading = false;
    });
  },
});

export const {
  replaceTable,
  updateTabName,
  deleteTabById,
  updateSpecTab,
  updateCurrenSelectedTab,
  setAddEditLabel,
  unsetAddEditLabel,
  updateSpecTabCell,
  updateSpecMockdataTabs,
} = mockDatasetSlice.actions;
export default mockDatasetSlice.reducer;
