import React, { useEffect, useRef, useState } from "react";
import { Button, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { PersonAddAlt1Icon } from "../../assets";
import BaseModal from "../../common/BaseModal";
import { InputField } from "../../common/InputField";
import { Field, Form, Formik } from "formik";
import { InviteEditorValidationSchema } from "../../utils/validationSchemas";
import { useDispatch, useSelector } from "react-redux";
import { ROLES } from "../../constants";
import axiosInstance from "../../axios";
import { inviteUserUrl } from "../../utils/urls";
import CustomIconButton from "../../common/CustomIconButton";
import { HTTP_CREATED, HTTP_OK } from "../../constants/statusCodes";
import { toaster } from "../../common/Toaster";
import { useLocation, useSearchParams } from "react-router-dom";
import { ROUTE_PATH } from "../../router/routes";
import { getUsersAsync } from "../../store/slices/userManagement";

const InviteButton = () => {
  const formRef = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const newUser = searchParams?.get("new_user");

  const { user, currentCompany } = useSelector((state) => state.userAuth);
  const companyDomain = user?.email?.split("@")[1];

  const currentPath = location.pathname;

  const [open, setOpen] = useState(false);
  const [isInviteEditorLoading, setInviteEditorLoading] = useState(false);
  const [domainWarning, setDomainWarning] = useState(false);

  const userMgt = currentPath.includes(ROUTE_PATH.USER_MGT);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const initialValues = {
    email: "",
  };

  const handleChange = (field, value) => {
    if (value.includes("@") && !value.includes("@" + companyDomain)) {
      setDomainWarning(true);
    } else {
      setDomainWarning(false);
    }

    field.onChange({
      target: {
        name: field.name,
        value: value,
      },
    });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const onSubmit = (values, { resetForm, validateForm }) => {
    const companyId = currentCompany?.id;

    const payload = {
      email: values.email,
      role_name: ROLES.EDITOR,
      company_id: companyId, //current admin id
      owner: false, //always false
    };
    setInviteEditorLoading(true);

    axiosInstance
      .post(inviteUserUrl, payload)
      .then((response) => {
        if (response?.status === HTTP_OK || response?.status === HTTP_CREATED) {
          toaster({
            type: "success",
            message: response?.data?.toaster?.data?.message,
          });
          setInviteEditorLoading(false);
          resetForm();
          validateForm();
          handleClose();
          if (userMgt) {
            dispatch(getUsersAsync());
          }
        }
      })
      .catch((error) => {
        setInviteEditorLoading(false);
        toaster({
          type: "success",
          message: error?.response?.data?.toaster?.data?.message,
        });
      });
  };

  useEffect(() => {
    if (newUser) {
      handleOpen();
      handleChange(formRef.current.getFieldProps("email"), newUser);
    }
  }, [userMgt]);

  const theme = useTheme();
  const isLgDown = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      {isLgDown ? (
        <CustomIconButton
          tooltipTitle='Invite Editors'
          Icon={PersonAddAlt1Icon}
          onClick={handleOpen}
        />
      ) : (
        <Button
          sx={{ height: "30px" }}
          startIcon={<PersonAddAlt1Icon />}
          onClick={handleOpen}>
          Invite Editors
        </Button>
      )}

      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={true}
        validateOnMount={true}
        validateOnChange={true}
        validationSchema={InviteEditorValidationSchema}>
        {({ touched, errors, values, isValid, resetForm, validateForm }) => (
          <Form>
            <BaseModal
              isOpen={open}
              handleClose={() => {
                resetForm({ values: initialValues });
                setTimeout(() => {
                  validateForm();
                  handleClose();
                }, 0);
              }}
              confirmTitle={"Send Invite"}
              title={"Invite Editors"}
              handleSubmit={handleSubmitClick}
              submitDisable={errors && Object.keys(errors)?.length !== 0}
              isLoading={isInviteEditorLoading}>
              <Field name='email'>
                {({ field, form }) => (
                  <InputField
                    name='email'
                    placeholder='Enter email address'
                    isLabel={true}
                    label='Email'
                    variant={"outlined"}
                    value={field.value}
                    onChange={({ currentTarget: input }) =>
                      handleChange(field, input.value)
                    }
                    onBlur={() => {
                      form.setTouched({
                        ...form.touched,
                        email: true,
                      });
                    }}
                    error={form.touched.email && Boolean(form.errors.email)}
                    helperText={
                      form.touched.email
                        ? form.errors.email
                        : domainWarning
                        ? `The following guest is from outside your organization: ${companyDomain}. Are you sure you would like to invite them?`
                        : ""
                    }
                  />
                )}
              </Field>
            </BaseModal>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default InviteButton;
