import React, { useRef, useState } from "react";
import { CreateNewPassCard } from "./style";
import { Field, Form, Formik } from "formik";
import { Stack, Typography } from "@mui/material";
import { InputField } from "../../common/InputField";
import LoadingButton from "../../common/LoadingButton";
import { createNewPasswordValidationSchema } from "../../utils/validationSchemas";
import { useNavigate, useParams } from "react-router-dom";
import { createNewPasswordRequest } from "../../services";
import { toaster } from "../../common/Toaster";
import { HTTP_OK } from "../../constants/statusCodes";
import { defaultMessage } from "../../constants/messages";
import { ROUTE_PATH } from "../../router/routes";

const CreateNewPassword = () => {
  const { token } = useParams();
  const formRef = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const handleSubmitClick = () => {
    formRef.current.submitForm();
  };

  const handleChange = (field, value) => {
    field.onChange({
      target: {
        name: field.name,
        value: value,
      },
    });
  };
  const onSubmit = async (values) => {
    setLoading(true);
    createNewPasswordRequest({
      payload: {
        password: values.password,
        password_confirmation: values.confirmPassword,
        token: token,
      },
    })
      .then((response) => {
        setLoading(false);
        if (response?.status !== HTTP_OK) {
          throw new Error(defaultMessage);
        }
        navigate(ROUTE_PATH.LOGIN);
        toaster({
          type: "success",
          message: response?.data?.toaster?.data?.message,
        });
      })
      .catch((error) => {
        setLoading(false);
        toaster({ type: "error", message: error });
      });
  };

  return (
    <Stack
      sx={{
        height: 1,
        width: 1,
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={createNewPasswordValidationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        validateOnMount={true}
        onSubmit={onSubmit}>
        {({ touched, errors, values, isValid }) => (
          <Form>
            <CreateNewPassCard
              sx={{
                width: { xs: "auto", md: "425px" },
              }}>
              <Stack rowGap={3}>
                <Stack>
                  <Typography variant='spec_h3' sx={{ fontSize: "20.8px" }}>
                    Reset Password
                  </Typography>
                  <Typography
                    variant='spec_h3'
                    sx={{ fontWeight: 400 }}
                    color={"gray.main"}>
                    Please choose your new password
                  </Typography>
                </Stack>
                <Stack rowGap={2}>
                  <Field name='password'>
                    {({ field, form }) => (
                      <InputField
                        value={field.value}
                        type='password'
                        name='password'
                        placeholder='Enter your password'
                        isLabel={true}
                        label='Password'
                        // onKeyDown={(e) => {
                        //   if (e.key === "Enter") {
                        //     handleSubmitClick();
                        //   }
                        // }}
                        onChange={({ currentTarget: input }) =>
                          handleChange(field, input.value)
                        }
                        onBlur={() => {
                          form.setTouched({
                            ...form.touched,
                            password: true,
                          });
                        }}
                        error={
                          form.touched.password && Boolean(form.errors.password)
                        }
                        helperText={
                          form.touched.password && form.errors.password
                        }
                      />
                    )}
                  </Field>
                  <Field name='confirmPassword'>
                    {({ field, form }) => (
                      <InputField
                        value={field.value}
                        type='password'
                        name='confirmPassword'
                        placeholder='Re-enter your password'
                        isLabel={true}
                        label='Confirm Password'
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSubmitClick();
                          }
                        }}
                        onChange={({ currentTarget: input }) =>
                          handleChange(field, input.value)
                        }
                        onBlur={() => {
                          form.setTouched({
                            ...form.touched,
                            confirmPassword: true,
                          });
                        }}
                        error={
                          form.touched.confirmPassword &&
                          Boolean(form.errors.confirmPassword)
                        }
                        helperText={
                          form.touched.confirmPassword &&
                          form.errors.confirmPassword
                        }
                      />
                    )}
                  </Field>
                </Stack>
                <Stack rowGap={2}>
                  <Typography
                    variant='spec_caption'
                    color={"gray.main"}
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                    }}>
                    The Password must be 8-15 characters long and must contain 1
                    alphabet, 1 digit, 1 special character, 1 uppercase and 1
                    lowercase. No spaces allowed.
                  </Typography>
                  <LoadingButton
                    loading={loading}
                    onClick={handleSubmitClick}
                    variant={"contained"}
                    color='primary'
                    disabled={errors && Object.keys(errors)?.length !== 0}>
                    Reset Password
                  </LoadingButton>
                </Stack>
              </Stack>
            </CreateNewPassCard>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};

export default CreateNewPassword;
