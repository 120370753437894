import React from "react";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { OptionLabel } from "../../common/style";
import { getSpecPreviewRoute } from "../../router/routes";

// import { ROUTE_VIEW_SPEC } from "../../constants/routes";

const TemplateCard = ({ template }) => {
  const navigate = useNavigate();
  return (
    <>
      <Card
        elevation={0}
        sx={(theme) => ({
          borderRadius: "8px",
          minWidth: { xs: "90%", sm: "80%", md: "300px" },
          width: { xs: "90%", sm: "80%", md: "300px" },
          height: "262px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          boxShadow: theme.palette.shadows.shadow1,
          p: 1,
        })}>
        <CardContent sx={{ p: 1 }}>
          <Stack
            sx={{
              display: "flex",
              alignItems: "flex-start",
              rowGap: 1,
            }}>
            <Typography variant='spec_h2'>{template?.title}</Typography>
            <Stack
              flexDirection={"row"}
              justifyContent={"space-between"}
              width={1}
              columnGap={1}>
              <Stack
                sx={{
                  width: 1,
                }}>
                <Typography
                  variant='spec_body'
                  sx={(theme) => ({
                    color: theme.palette.grey[500],
                  })}>
                  Function
                </Typography>
                <OptionLabel
                  variant='spec_body'
                  label={template?.specFunction ? template?.specFunction : "-"}
                />
              </Stack>
              <Stack
                sx={{
                  justifyContent: "left",
                  width: 1,
                }}>
                <Typography
                  variant='spec_body'
                  sx={(theme) => ({
                    color: theme.palette.grey[500],
                  })}>
                  Industry
                </Typography>
                <OptionLabel
                  variant='spec_body'
                  label={
                    template?.specIndustry?.title
                      ? template?.specIndustry?.title
                      : "-"
                  }
                />
              </Stack>
            </Stack>
            <Stack>
              <Typography
                variant='spec_body'
                sx={(theme) => ({
                  color: theme.palette.grey[500],
                })}>
                User Story
              </Typography>
              <Typography>
                {" "}
                <OptionLabel
                  variant='spec_body'
                  label={
                    template.businessNeed?.persona &&
                    template.businessNeed?.action &&
                    template.businessNeed?.outcome
                      ? `As a ${template.businessNeed.persona}, I want to ${template.businessNeed.action} so that ${template.businessNeed.outcome}`
                      : "-"
                  }
                  size='110'
                />
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}>
          <Button
            size='small'
            variant='contained'
            onClick={() =>
              navigate(getSpecPreviewRoute(template?.id), {
                state: { fromTemplate: true },
              })
            }>
            <Box
              component='span'
              sx={{
                fontSize: "14px",
                lineHeight: "22px",
              }}>
              Preview
            </Box>
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default TemplateCard;
