import ActionCell from "./ActionCell";

const commonCellStyle = { wordBreak: "normal", lineHeight: "normal" };
export const getStatusesSettingsColumns = () => {
  return [
    {
      field: "action",
      editable: false,
      headerName: "Actions",
      resizable: false,
      cellDataType: "text",
      width: 80,
      sortable: false,
      cellRenderer: ActionCell,
    },
    {
      field: "name",
      editable: false,
      headerName: "Status Name",
      resizable: false,
      unSortIcon: true,
      cellDataType: "text",
      flex: 1,
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
    },
  ];
};
