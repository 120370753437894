//TODO refactor this component. It is too big and has too many dependencies
export const BarIcon = () => {
  return (
    <svg
      width='100%'
      height='100%'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 60 60'
      fill='none'
      // style={{ height: '100%', width: '100%' }}
      xmlns='http://www.w3.org/2000/svg'>
      <rect
        width='3.85714'
        height='16.1379'
        rx='0.5'
        transform='matrix(0 -1 -1 0 28.1377 48.0005)'
        fill='#808080'
      />
      <rect
        width='3.85714'
        height='36'
        rx='0.5'
        transform='matrix(0 -1 -1 0 48 41.572)'
        fill='#808080'
      />
      <rect
        width='3.85714'
        height='27.931'
        rx='0.5'
        transform='matrix(0 -1 -1 0 39.9312 35.1433)'
        fill='#808080'
      />
      <rect
        width='3.85714'
        height='19.8621'
        rx='0.5'
        transform='matrix(0 -1 -1 0 31.8623 28.7148)'
        fill='#808080'
      />
      <rect
        width='3.85714'
        height='27.931'
        rx='0.5'
        transform='matrix(0 -1 -1 0 39.9312 22.2861)'
        fill='#808080'
      />
      <rect
        width='3.85714'
        height='19.8621'
        rx='0.5'
        transform='matrix(0 -1 -1 0 31.8623 15.8577)'
        fill='#808080'
      />
    </svg>
  );
};
