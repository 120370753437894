import { PURGE } from "redux-persist";
import axiosInstance from "../../axios";
import { checkTokenUrl } from "../../utils/urls";
import { HTTP_OK } from "../../constants/statusCodes";
import { defaultMessage } from "../../constants/messages";
import { getCurrentCompany } from "../../pages/Login/loginUtil";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultExceptionHandler } from "../../utils/defaultExceptionHandler";
import { setCookie } from "../../utils/commonUtility";

const initialState = {
  user: null,
  isLoggedIn: false,
  userLoading: false,
  currentCompany: null,
  activeCompanies: [],
};

//checks the token returned after authentication & return user details along with active companies & permissions associated with each company
export const checkToken = createAsyncThunk("userAuth/checkToken", async () => {
  try {
    const response = await axiosInstance.post(checkTokenUrl);

    if (response?.status !== HTTP_OK) {
      throw new Error(defaultMessage);
    }

    const currentURL = window.location.href;
    const currentCompany = getCurrentCompany(response.data?.user, currentURL);
    response.data.currentCompany = currentCompany;
    setCookie("subdomain", currentCompany?.subdomain);
    return response?.data;
  } catch (error) {
    throw defaultExceptionHandler(error);
  }
});

const userAuthSlice = createSlice({
  name: "userAuth",
  initialState: initialState,

  reducers: {
    setUserAndCompanies: (state, action) => {
      state.user = action.payload.user;
      state.activeCompanies = action.payload.user?.activeCompanies;
      state.currentCompany = action.payload?.currentCompany;
      state.isLoggedIn = true;
    },
    updateAuthUser: (state, action) => {
      state.user = action.payload.user;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action?.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
    builder.addCase(checkToken.pending, (state, action) => {
      state.userLoading = true;
    });
    builder.addCase(checkToken.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.activeCompanies = action.payload.user?.activeCompanies;
      state.currentCompany = action.payload?.currentCompany;
      state.isLoggedIn = true;
      state.userLoading = false;
    });
    builder.addCase(checkToken.rejected, (state) => {
      state.isLoggedIn = false;
      state.userLoading = false;
    });
  },
});

export const { setUserAndCompanies, setIsLoggedIn, updateAuthUser } =
  userAuthSlice.actions;
export default userAuthSlice.reducer;
