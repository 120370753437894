import { CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import AgGridComponent from "../../common/AgGridComponent";
import CustomHeaderCell from "../../common/CustomHeaderCell";
import { useDispatch, useSelector } from "react-redux";
import { getSpecDataScopesAsync } from "../../store/slices/dataScopes";
import { useParams } from "react-router-dom";
import { getSpecDataScopeRows } from "./helperFunctions";
import { getSpecDataScopeColumns } from "./columns";
import SetSpecDataScopeModal from "../SetSpecDataScopeModal";
import InfoPopup from "../../common/InfoPopup";
import MainCard from "../MainCard";

const DataScopeTable = ({ isPreview }) => {
  const theme = useTheme();

  const cardBorderSX = {
    border: `1px solid #ffffff`,
    borderColor: "#dddddd",
    boxShadow: theme.palette.shadows.shadow1,
    ":hover": {
      boxShadow: theme.palette.shadows.shadow1,
    },
  };
  const dividerSX = { borderColor: "#dddddd" };

  const { specId } = useParams();
  const dispatch = useDispatch();
  const { specDataScopes, isSetSpecDSModalOpen } = useSelector(
    (state) => state.dataScopes
  );

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [rowData, setRowData] = useState([]);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([]);
  // CUSTOM HEADER
  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeaderCell,
    };
  }, []);

  useEffect(() => {
    dispatch(getSpecDataScopesAsync({ specId }))
      .unwrap()
      .then(() => {
        setIsInitialLoading(false);
      });
  }, []);

  useEffect(() => {
    setColDefs(getSpecDataScopeColumns(isPreview));
    if (specDataScopes?.length) {
      setRowData(getSpecDataScopeRows(specDataScopes, isPreview));
    }
  }, [specDataScopes]);

  return (
    <>
      {rowData?.length > 0 ? (
        <Stack rowGap={4}>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography variant='spec_h3'>Data Scope</Typography>
            {!isPreview && <InfoPopup tab={2} settingPage={true} />}
          </Stack>

          <Stack
            ml={3}
            sx={(theme) => ({
              // height: "300px",
              border: `1px solid ${theme.palette.gray.borderGray}`,
              // isPreview
              //   ? "none"
              //   : `1px solid ${theme.palette.gray.borderGray}`,
              borderRadius: "8px",
              overflow: "hidden",
            })}>
            {isInitialLoading ? (
              <Stack
                height={1}
                width={1}
                justifyContent={"center"}
                alignItems={"center"}>
                <CircularProgress size={40} />
              </Stack>
            ) : (
              //  isPreview ? (
              // <MainCard
              //   content={false}
              //   title='Data Scope'
              //   sx={{
              //     ...cardBorderSX,
              //   }}
              //   dividerSX={dividerSX}
              //   titleTypographyProps={{ variant: "spec_h2" }}
              //   isHeaderPaddingRequired={false}>
              //   <AgGridComponent
              //     data={rowData}
              //     columnDefs={colDefs}
              //     components={components}
              //     autoSizeStrategy={"fitGridWidth"}
              //     pagination={false}
              //     domLayout={"autoHeight"}
              //   />
              // </MainCard>
              // ) :
              <AgGridComponent
                data={rowData}
                columnDefs={colDefs}
                components={components}
                autoSizeStrategy={"fitGridWidth"}
                pagination={false}
                domLayout={"autoHeight"}
              />
            )}
          </Stack>
        </Stack>
      ) : (
        <></>
      )}

      {!isPreview && isSetSpecDSModalOpen && <SetSpecDataScopeModal />}
    </>
  );
};

export default DataScopeTable;
