import _ from "lodash";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { Box, Stack, Typography } from "@mui/material";
import { InputField } from "../../common/InputField";
import { updateSpecification } from "../../services";
import { ErrorTooltip } from "../../common/ErrorTooltip";
import React, { useEffect, useRef, useState } from "react";
import CustomIconButton from "../../common/CustomIconButton";
import TooltipTypography from "../../common/TooltipTypography";
import { BorderColorOutlinedIcon, InfoIcon } from "../../assets";
import { TitleValidationSchema } from "../../utils/validationSchemas";
import { Check } from "@mui/icons-material";

const SpecTitle = () => {
  const { specId } = useParams();
  const formRef = useRef(null);
  const { specData } = useSelector((state) => state?.spec);

  const [inEditMode, setInEditMode] = useState(false);

  const initialValues = {
    title: specData?.title ? specData?.title : "",
  };

  const handleChange = (field, value) => {
    field.onChange({
      target: {
        name: field.name,
        value: value,
      },
    });
  };

  const onSubmit = (values) => {
    setInEditMode(false);

    if (_.isEqual(initialValues, values)) return;

    const payload = {
      specification: {
        title: values?.title,
      },
    };
    updateSpecification({
      payload: payload,
      id: specId,
    }).then((response) => {
      // DO SOMETHING ON TITLE UPDATE SUCCESS - MOST PROBABLY UPDATE SAVED STATUS
    });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    if (
      specData?.title?.trim() === "New Specification" ||
      specData?.title?.trim()?.endsWith("Copy")
    ) {
      setInEditMode(true);
    }
  }, [specData?.title]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={TitleValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      initialTouched={{ title: true }}
      validateOnMount={true}
      enableReinitialize={true}
      onSubmit={onSubmit}>
      {({ touched, errors, values, isValid }) => (
        <Form>
          {inEditMode ? (
            <Box sx={{ width: { xs: "150px", sm: "350px" } }}>
              <Field name='title'>
                {({ field, form }) => (
                  <InputField
                    fullWidth={true}
                    name='title'
                    placeholder='Enter spec title here'
                    isLabel={false}
                    variant={"outlined"}
                    value={field.value}
                    sx={{
                      ml: 1,
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmitClick();
                      }
                    }}
                    onChange={({ currentTarget: input }) =>
                      handleChange(field, input.value)
                    }
                    onBlur={() => {
                      form.setTouched({
                        ...form.touched,
                        title: true,
                      });
                    }}
                    error={form.touched.title && Boolean(form.errors.title)}
                    startAdornment={
                      form.touched.title &&
                      Boolean(form.errors.title) && (
                        <ErrorTooltip
                          title={
                            <Typography
                              sx={{ textTransform: "none" }}
                              variant='spec_h3'
                              color={"secondary.white"}>
                              {form.errors.title}
                            </Typography>
                          }
                          arrow>
                          <InfoIcon color='error' />
                        </ErrorTooltip>
                      )
                    }
                    endAdornment={
                      !(form.touched.title && Boolean(form.errors.title)) && (
                        <Check
                          color='success'
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleSubmitClick()}
                        />
                      )
                    }
                  />
                )}
              </Field>
            </Box>
          ) : (
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              columnGap={"12px"}
              ml={3}>
              <TooltipTypography
                label={values?.title}
                variant='spec_h2'
                displaySx={{
                  whiteSpace: "nowrap",
                }}
              />
              <CustomIconButton
                size={"small"}
                IconProps={{ fontSize: "small" }}
                Icon={BorderColorOutlinedIcon}
                onClick={() => setInEditMode(true)}
              />
            </Stack>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SpecTitle;
