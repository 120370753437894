import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../axios";
import { specificationsUrl } from "../../utils/urls";
import { defaultMessage } from "../../constants/messages";
import { defaultExceptionHandler } from "../../utils/defaultExceptionHandler";
import { HTTP_OK } from "../../constants/statusCodes";
import { PURGE } from "redux-persist";
import { getSpecIndexPreference } from "../../services";

export const getSpecsList = createAsyncThunk(
  "specListing/getSpecsList",
  async (payload) => {
    try {
      const response = await axiosInstance.get(
        `${specificationsUrl}?similar_to=${
          payload?.similarTo ?? ""
        }&business_term_ids=${payload?.businessTermIds ?? []}&data_scope=${
          payload?.dataScope ?? ""
        }&due_date_from=${payload?.dueDateFrom ?? ""}&due_date_to=${
          payload?.dueDateTo ?? ""
        }`,
        {
          params: {
            spec_status_ids: payload?.specStatusIds ?? [],
            priority_ids: payload?.priorityIds ?? [],
            function_ids: payload?.functionIds ?? [],
            requester_ids: payload?.requesterIds ?? [],
            author_ids: payload?.authorIds ?? [],
            collaborator_ids: payload?.collaboratorIds ?? [],
            urgency: payload?.urgency ?? [],
          },
        }
      );

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }

      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

export const getSpecIndexPreferenceAsync = createAsyncThunk(
  "specListing/getSpecIndexPreferenceAsync",
  async () => {
    try {
      const response = await getSpecIndexPreference();

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }

      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

const initialState = {
  specs: [],
  isSpecsLoading: false,
  specIndexPrefrences: null,
  isSpecIndexPrefrencesLoading: false,
};

const specListing = createSlice({
  name: "specListing",
  initialState: initialState,
  reducers: {
    updateSpecIndePrefrencesState: (state, action) => {
      state.specIndexPrefrences = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
    builder.addCase(getSpecsList.pending, (state, action) => {
      state.isSpecsLoading = true;
    });
    builder.addCase(getSpecsList.fulfilled, (state, action) => {
      state.isSpecsLoading = false;
      state.specs = action.payload.specifications;
    });
    builder.addCase(getSpecsList.rejected, (state) => {
      state.isSpecsLoading = false;
    });
    // SPEC INDEX PREFRENCES
    builder.addCase(getSpecIndexPreferenceAsync.pending, (state, action) => {
      state.isSpecIndexPrefrencesLoading = true;
    });
    builder.addCase(getSpecIndexPreferenceAsync.fulfilled, (state, action) => {
      state.isSpecIndexPrefrencesLoading = false;
      state.specIndexPrefrences =
        action.payload.specIndexPreference?.preferences;
    });
    builder.addCase(getSpecIndexPreferenceAsync.rejected, (state) => {
      state.isSpecIndexPrefrencesLoading = false;
    });
  },
});

export const { updateSpecIndePrefrencesState } = specListing.actions;
export default specListing.reducer;
