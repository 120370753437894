import BarIcon from "../../assets/icons/charts/barChart.svg";
import BoxPlotIcon from "../../assets/icons/charts/boxplot.svg";
import ColumnIcon from "../../assets/icons/charts/columnChart.svg";
import MultipleLineIcon from "../../assets/icons/charts/multipleLineChart.svg";
import PieIcon from "../../assets/icons/charts/pieChart.svg";
import ScatterIcon from "../../assets/icons/charts/scatterChart.svg";
import SingleLineIcon from "../../assets/icons/charts/singleLineChart.svg";
import Stacked100ColumnIcon from "../../assets/icons/charts/stacked100PerColumnChart.svg";
import StackedColumnIcon from "../../assets/icons/charts/stackedColumnChart.svg";

import {
  BOOLEAN,
  CURRENCY,
  DATE,
  DAY,
  GEOGRAPHY,
  MONTH,
  NUMBER,
  PERCENT,
  TEXT,
  TIME,
  WEEK,
  YEAR,
} from "../../constants";

export const GROUP = {
  comparison: "Comparison",
  relationship: "Relationship",
  distribution: "Distribution",
  composition: "Composition",
};

export const BAR_CHART = "bar";
export const BOX_PLOT = "box-plot";
export const COLUMN_CHART = "column";
export const COLUMN_CHART_TIME = "column-time";
export const PIE_CHART = "pie";
export const SCATTER_CHART = "scatter";
export const SCATTER_CHART_SECOND = "scatter-second";
export const STACKED_100_COLUMN_CHART = "stacked-100-column";
export const STACKED_COLUMN_CHART = "stacked-column";
export const CIRCULAR_AREA_CHART = "circular-area";
export const SINGLE_LINE_CHART = "single-line";
export const MULTIPLE_LINE_CHART = "multiple-line";
export const STACKED_100_AREA_CHART = "stacked-100-area";
export const STACKED_AREA_CHART = "stacked-area";

export const charTypes = [
  {
    id: BAR_CHART,
    type: BAR_CHART,
    label: "Bar Chart",
    categories: "Many Items",
    period: "Among items",
    group: [GROUP.comparison],
    xAxisType: [TEXT.type, GEOGRAPHY.type, BOOLEAN.type, DATE.type],
    yAxisType: [NUMBER.type, PERCENT.type, CURRENCY.type],
    xLabel: "X-Axis",
    yLabel: "Y-Axis",
    placeholderX: "Select a Metric",
    placeholderY: "Select a Category Dimension",
    metricAxis: ["X-Axis"],
    icon: <img src={BarIcon} alt='bar-chart' />,
  },
  {
    id: COLUMN_CHART,
    type: COLUMN_CHART,
    label: "Column Chart",
    categories: "Few Items",
    period: "Among items",
    group: [GROUP.comparison],
    xAxisType: [
      TEXT.type,
      GEOGRAPHY.type,
      BOOLEAN.type,
      TIME.type,
      DATE.type,
      DAY.type,
      WEEK.type,
      MONTH.type,
      YEAR.type,
    ],
    yAxisType: [NUMBER.type, PERCENT.type, CURRENCY.type],
    xLabel: "X-Axis",
    yLabel: "Y-Axis",
    placeholderX: "Select a Dimension",
    placeholderY: "Select a Metric",
    metricAxis: ["Y-Axis"],
    icon: <img src={ColumnIcon} alt='column-chart' />,
  },
  {
    id: BOX_PLOT,
    type: BOX_PLOT,
    label: "Boxplot",
    categories: "Shape of Data",
    period: "Two Variables",
    group: [GROUP.distribution],
    xAxisType: [TEXT.type, GEOGRAPHY.type, BOOLEAN.type],
    yAxisType: [NUMBER.type, PERCENT.type, CURRENCY.type],
    xLabel: "X-Axis",
    yLabel: "Y-Axis",
    placeholderY: "Select a Category Dimension",
    placeholderX: "Select a Metric",
    metricAxis: ["X-Axis"],
    icon: (
      <div style={{ transform: "rotate(90deg)" }}>
        <img src={BoxPlotIcon} alt='box-plot-chart' />
      </div>
    ),
  },
  // {
  //   id: COLUMN_CHART_TIME,
  //   type: COLUMN_CHART,
  //   label: 'Column Chart',
  //   categories: 'Few Periods - Single or Few Categories',
  //   period: 'Over Time',
  //   group: [GROUP.comparison],
  //   xAxisType: [TEXT.type, GEOGRAPHY.type, BOOLEAN.type],
  //   yAxisType: [NUMBER.type, PERCENT.type, CURRENCY.type],
  //   timeLabel: 'X-Axis',
  //   xLabel: 'Categories',
  //   yLabel: 'Y-Axis',
  //   placeholderTime: 'Select a Time Dimension',
  //   placeholderX: 'Select a Categories Dimension',
  //   placeholderY: 'Select a Metric',
  //   metricAxis: ['Y-Axis'],
  //   icon: <ColumnIcon />,
  // },
  {
    id: SINGLE_LINE_CHART,
    type: SINGLE_LINE_CHART,
    label: "Single Line Chart",
    categories: "Many Periods - Non-Cyclical Data",
    period: "Over Time",
    group: [GROUP.comparison],
    xAxisType: [
      TIME.type,
      DATE.type,
      DAY.type,
      WEEK.type,
      MONTH.type,
      YEAR.type,
    ],
    yAxisType: [NUMBER.type, PERCENT.type, CURRENCY.type],
    timeLabel: "X-Axis",
    yLabel: "Y-Axis",
    placeholderTime: "Select a Time Dimension",
    placeholderY: "Select a Metric",
    metricAxis: ["Y-Axis"],
    icon: <img src={SingleLineIcon} alt='line-chart' />,
  },
  {
    id: MULTIPLE_LINE_CHART,
    type: MULTIPLE_LINE_CHART,
    label: "Multiple Line Chart",
    categories: "Many Periods - Many Categories",
    period: "Over Time",
    group: [GROUP.comparison],
    xAxisType: [TEXT.type, GEOGRAPHY.type, BOOLEAN.type],
    yAxisType: [NUMBER.type, PERCENT.type, CURRENCY.type],
    timeLabel: "X-Axis",
    xLabel: "Overlay Category",
    yLabel: "Y-Axis",
    placeholderTime: "Select a Time Dimension",
    placeholderX: "Select a Category Dimension",
    placeholderY: "Select a Metric",
    metricAxis: ["Y-Axis"],
    icon: <img src={MultipleLineIcon} alt='multiline-chart' />,
  },
  // {
  //   id: CIRCULAR_AREA_CHART,
  //   type: CIRCULAR_AREA_CHART,
  //   label: 'Circular Area Chart',
  //   categories: 'Many Periods - Cyclical Data',
  //   period: 'Over Time',
  //   group: [GROUP.comparison],
  //   xAxisType: [DATE.type],
  //   yAxisType: [NUMBER.type, PERCENT.type, CURRENCY.type],
  //   timeLabel: 'Radius',
  //   yLabel: 'Circumference',
  //   placeholderTime: 'Select a Time Dimension',
  //   placeholderY: 'Select a Metric',
  //   metricAxis: ['Y-Axis'],
  //   icon: <CircularIcon />,
  // },
  {
    id: SCATTER_CHART,
    type: SCATTER_CHART,
    label: "Scatter Chart",
    categories: "Correlation Between Variables",
    period: "Two Variables",
    group: [GROUP.relationship],
    xAxisType: [NUMBER.type, PERCENT.type, CURRENCY.type],
    yAxisType: [NUMBER.type, PERCENT.type, CURRENCY.type],
    xLabel: "X-Axis",
    yLabel: "Y-Axis",
    placeholderX: "Select a Metric",
    placeholderY: "Select a Metric",
    metricAxis: ["X-Axis", "Y-Axis"],
    icon: <img src={ScatterIcon} alt='scatter-chart' />,
  },
  // {
  //   id: SCATTER_CHART_SECOND,
  //   type: SCATTER_CHART,
  //   label: 'Scatter Chart',
  //   categories: 'Association Between Variables',
  //   period: 'Two Variables',
  //   group: [GROUP.distribution],
  //   xAxisType: [NUMBER.type, PERCENT.type, CURRENCY.type],
  //   yAxisType: [NUMBER.type, PERCENT.type, CURRENCY.type],
  //   xLabel: 'X-Axis',
  //   yLabel: 'Y-Axis',
  //   placeholderX: 'Select a Metric',
  //   placeholderY: 'Select a Metric',
  //   metricAxis: ['X-Axis', 'Y-Axis'],
  //   icon: <ScatterIcon />,
  // },

  {
    id: STACKED_100_COLUMN_CHART,
    type: STACKED_100_COLUMN_CHART,
    label: "Stacked 100% Column Chart",
    categories: "Few Periods - Only Relative Differences Matter",
    period: "Over time",
    group: [GROUP.composition],
    xAxisType: [TEXT.type, GEOGRAPHY.type, BOOLEAN.type],
    yAxisType: [CURRENCY.type, NUMBER.type],
    timeLabel: "X-Axis",
    xLabel: "Overlay Category",
    yLabel: "Y-Axis",
    placeholderTime: "Select a Dimension",
    placeholderX: "Select a Category Dimension",
    placeholderY: "Select a Metric",
    metricAxis: ["Y-Axis"],
    icon: <img src={Stacked100ColumnIcon} alt='stacke-100-chart' />,
  },
  {
    id: STACKED_COLUMN_CHART,
    type: STACKED_COLUMN_CHART,
    label: "Stacked Column Chart",
    categories: "Few Periods - Relative & Absolute Differences Matter",
    period: "Over time",
    group: [GROUP.composition],
    xAxisType: [TEXT.type, GEOGRAPHY.type, BOOLEAN.type],
    yAxisType: [NUMBER.type, CURRENCY.type],
    timeLabel: "X-Axis",
    xLabel: "Overlay Categories",
    yLabel: "Y-Axis",
    placeholderTime: "Select a Dimension",
    placeholderX: "Select a Category Dimension",
    placeholderY: "Select a Metric",
    metricAxis: ["Y-Axis"],
    icon: <img src={StackedColumnIcon} alt='stacked-col-chart' />,
  },

  // {
  //   id: STACKED_AREA_CHART,
  //   type: STACKED_AREA_CHART,
  //   label: 'Stacked Area Chart',
  //   categories: 'Many Periods - Relative & Absolute Differences Matter',
  //   period: 'Over time',
  //   group: [GROUP.composition],
  //   xAxisType: [TEXT.type, GEOGRAPHY.type, BOOLEAN.type],
  //   yAxisType: [NUMBER.type, PERCENT.type, CURRENCY.type],
  //   timeLabel: 'X-Axis',
  //   xLabel: 'Categories',
  //   yLabel: 'Y-Axis',
  //   placeholderTime: 'Select a Time Dimension',
  //   placeholderX: 'Select a Category Dimension',
  //   placeholderY: 'Select a Metric',
  //   metricAxis: ['Y-Axis'],
  //   icon: <StackedAreaIcon />,
  // },
  {
    id: PIE_CHART,
    type: PIE_CHART,
    label: "Pie Chart",
    categories: "Simple Share of Total",
    period: "Static",
    group: [GROUP.composition],
    xAxisType: [TEXT.type, GEOGRAPHY.type, BOOLEAN.type],
    yAxisType: [NUMBER.type, CURRENCY.type],
    xLabel: "Legend",
    yLabel: "Slices",
    placeholderX: "Select a Category Dimension",
    placeholderY: "Select a Metric",
    metricAxis: [],
    icon: <img src={PieIcon} alt='pie-chart' />,
  },
];

export const chartColors = [
  "#F1E15B",
  "#E8C1A0",
  "#F47560",
  "#E8A838",
  "#61CDBB",
  "#97E3D5",
  "#6BAED6",
  "#2171B5",
  "#7DAE6F",
  "#44683B",
  "#FFCB85",
  "#FF9F1C",
  "#CDB4DB",
  "#967AA1",
  "#F9D3C8",
  "#B56576",
  "#586BA4",
  "#4A7ECA",
  "#FFDC7D",
  "#FFB703",
  "#D1CFE2",
  "#93A8AC",
  "#BDE0FE",
  "#9ECAE1",
  "#4292C6",
  "#5E7FA0",
  "#74C476",
  "#5E9051",
  "#E74D23",
  "#415A77",
  "#238B45",
  "#C8B6FF",
  "#E7C6FF",
  "#2A9D8F",
  "#2C6B60",
  "#F38202",
  "#41AB5D",
  "#ECBB5B",
  "#38BCA4",
  "#F19074",
  "#59CD90",
  "#006D2C",
  "#A1D99B",
  "#FDCA40",
  "#FFC8DD",
  "#B780E0",
  "#A53212",
  "#C7E9C0",
  "#E6B89C",
  "#F6DFB6",
  "#C06D55",
  "#D0F1EB",
  "#E5F5E0",
];

// for customer request we hide initial chart data
export const initBarChatData = [
  {
    id: "AD",
    "hot dog": 151,
    burger: 151,
    sandwich: 8,
    kebab: 185,
    fries: 36,
    donut: 26,
  },
  {
    id: "AM",
    "hot dog": 55,
    burger: 7,
    sandwich: 96,
    kebab: 41,
    fries: 50,
    donut: 20,
  },
];

export const initLineChart = [
  {
    id: "vv",
    data: [
      {
        x: "January",
        y: 2260,
      },
      {
        x: "February",
        y: 2492,
      },
      {
        x: "March",
        y: 1976,
      },
      {
        x: "April",
        y: 2092,
      },
      {
        x: "May",
        y: 1634,
      },
      {
        x: "June",
        y: 2196,
      },
      {
        x: "July",
        y: 1769,
      },
      {
        x: "August",
        y: 1881,
      },
      {
        x: "September",
        y: 2107,
      },
      {
        x: "October",
        y: 1480,
      },
      {
        x: "November",
        y: 2271,
      },
      {
        x: "December",
        y: 1960,
      },
    ],
  },
];

export const initMultipleLineChart = [
  {
    id: "japan",
    color: "hsl(171, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 126,
      },
      {
        x: "helicopter",
        y: 52,
      },
      {
        x: "boat",
        y: 235,
      },
      {
        x: "train",
        y: 265,
      },
      {
        x: "subway",
        y: 177,
      },
      {
        x: "bus",
        y: 3,
      },
      {
        x: "car",
        y: 48,
      },
      {
        x: "moto",
        y: 42,
      },
      {
        x: "bicycle",
        y: 117,
      },
      {
        x: "horse",
        y: 85,
      },
      {
        x: "skateboard",
        y: 291,
      },
      {
        x: "others",
        y: 276,
      },
    ],
  },
  {
    id: "france",
    color: "hsl(311, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 121,
      },
      {
        x: "helicopter",
        y: 149,
      },
      {
        x: "boat",
        y: 294,
      },
      {
        x: "train",
        y: 55,
      },
      {
        x: "subway",
        y: 84,
      },
      {
        x: "bus",
        y: 185,
      },
      {
        x: "car",
        y: 172,
      },
      {
        x: "moto",
        y: 214,
      },
      {
        x: "bicycle",
        y: 12,
      },
      {
        x: "horse",
        y: 30,
      },
      {
        x: "skateboard",
        y: 71,
      },
      {
        x: "others",
        y: 65,
      },
    ],
  },
  {
    id: "us",
    color: "hsl(334, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 241,
      },
      {
        x: "helicopter",
        y: 278,
      },
      {
        x: "boat",
        y: 97,
      },
      {
        x: "train",
        y: 8,
      },
      {
        x: "subway",
        y: 283,
      },
      {
        x: "bus",
        y: 236,
      },
      {
        x: "car",
        y: 224,
      },
      {
        x: "moto",
        y: 220,
      },
      {
        x: "bicycle",
        y: 85,
      },
      {
        x: "horse",
        y: 151,
      },
      {
        x: "skateboard",
        y: 291,
      },
      {
        x: "others",
        y: 44,
      },
    ],
  },
  {
    id: "germany",
    color: "hsl(356, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 129,
      },
      {
        x: "helicopter",
        y: 262,
      },
      {
        x: "boat",
        y: 170,
      },
      {
        x: "train",
        y: 72,
      },
      {
        x: "subway",
        y: 258,
      },
      {
        x: "bus",
        y: 137,
      },
      {
        x: "car",
        y: 234,
      },
      {
        x: "moto",
        y: 243,
      },
      {
        x: "bicycle",
        y: 253,
      },
      {
        x: "horse",
        y: 260,
      },
      {
        x: "skateboard",
        y: 113,
      },
      {
        x: "others",
        y: 74,
      },
    ],
  },
  {
    id: "norway",
    color: "hsl(271, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 196,
      },
      {
        x: "helicopter",
        y: 261,
      },
      {
        x: "boat",
        y: 116,
      },
      {
        x: "train",
        y: 284,
      },
      {
        x: "subway",
        y: 199,
      },
      {
        x: "bus",
        y: 224,
      },
      {
        x: "car",
        y: 55,
      },
      {
        x: "moto",
        y: 143,
      },
      {
        x: "bicycle",
        y: 66,
      },
      {
        x: "horse",
        y: 97,
      },
      {
        x: "skateboard",
        y: 225,
      },
      {
        x: "others",
        y: 221,
      },
    ],
  },
];

export const initCircularAreaChart = [
  {
    id: "fruity",
    chardonay: 70,
    carmenere: 113,
    syrah: 94,
  },
  {
    id: "bitter",
    chardonay: 57,
    carmenere: 105,
    syrah: 86,
  },
  {
    id: "heavy",
    chardonay: 25,
    carmenere: 55,
    syrah: 85,
  },
  {
    id: "strong",
    chardonay: 75,
    carmenere: 88,
    syrah: 87,
  },
  {
    id: "sunny",
    chardonay: 118,
    carmenere: 53,
    syrah: 64,
  },
];

export const initPieChart = [
  {
    id: "lisp",
    label: "lisp",
    value: 5,
    color: "hsl(277, 70%, 50%)",
  },
  {
    id: "javascript",
    label: "javascript",
    value: 298,
    color: "hsl(353, 70%, 50%)",
  },
  {
    id: "c",
    label: "c",
    value: 512,
    color: "hsl(287, 70%, 50%)",
  },
  {
    id: "haskell",
    label: "haskell",
    value: 328,
    color: "hsl(7, 70%, 50%)",
  },
  {
    id: "erlang",
    label: "erlang",
    value: 97,
    color: "hsl(302, 70%, 50%)",
  },
];

export const initScatterChart = [
  {
    id: "group A",
    data: [
      {
        x: 8,
        y: 97,
      },
      {
        x: 66,
        y: 27,
      },
      {
        x: 80,
        y: 37,
      },
      {
        x: 13,
        y: 89,
      },
      {
        x: 12,
        y: 109,
      },
      {
        x: 1,
        y: 90,
      },
      {
        x: 59,
        y: 57,
      },
      {
        x: 72,
        y: 47,
      },
      {
        x: 20,
        y: 99,
      },
      {
        x: 57,
        y: 120,
      },
      {
        x: 68,
        y: 89,
      },
      {
        x: 70,
        y: 49,
      },
      {
        x: 39,
        y: 65,
      },
      {
        x: 24,
        y: 36,
      },
      {
        x: 35,
        y: 0,
      },
      {
        x: 44,
        y: 69,
      },
      {
        x: 38,
        y: 119,
      },
      {
        x: 52,
        y: 52,
      },
      {
        x: 4,
        y: 28,
      },
      {
        x: 62,
        y: 109,
      },
      {
        x: 99,
        y: 45,
      },
      {
        x: 55,
        y: 7,
      },
      {
        x: 70,
        y: 88,
      },
      {
        x: 81,
        y: 63,
      },
      {
        x: 100,
        y: 102,
      },
      {
        x: 87,
        y: 110,
      },
      {
        x: 48,
        y: 47,
      },
      {
        x: 88,
        y: 33,
      },
      {
        x: 88,
        y: 13,
      },
      {
        x: 84,
        y: 83,
      },
      {
        x: 61,
        y: 47,
      },
      {
        x: 6,
        y: 111,
      },
      {
        x: 90,
        y: 102,
      },
      {
        x: 45,
        y: 112,
      },
      {
        x: 23,
        y: 7,
      },
      {
        x: 28,
        y: 38,
      },
      {
        x: 33,
        y: 45,
      },
      {
        x: 0,
        y: 58,
      },
      {
        x: 72,
        y: 56,
      },
      {
        x: 11,
        y: 95,
      },
      {
        x: 60,
        y: 36,
      },
      {
        x: 17,
        y: 37,
      },
      {
        x: 23,
        y: 17,
      },
      {
        x: 32,
        y: 116,
      },
      {
        x: 100,
        y: 2,
      },
      {
        x: 51,
        y: 76,
      },
      {
        x: 59,
        y: 110,
      },
      {
        x: 59,
        y: 44,
      },
      {
        x: 91,
        y: 77,
      },
      {
        x: 77,
        y: 84,
      },
    ],
  },
  {
    id: "group B",
    data: [
      {
        x: 71,
        y: 53,
      },
      {
        x: 65,
        y: 45,
      },
      {
        x: 17,
        y: 105,
      },
      {
        x: 26,
        y: 95,
      },
      {
        x: 42,
        y: 4,
      },
      {
        x: 62,
        y: 104,
      },
      {
        x: 50,
        y: 12,
      },
      {
        x: 35,
        y: 6,
      },
      {
        x: 48,
        y: 31,
      },
      {
        x: 6,
        y: 29,
      },
      {
        x: 43,
        y: 78,
      },
      {
        x: 39,
        y: 101,
      },
      {
        x: 23,
        y: 112,
      },
      {
        x: 99,
        y: 16,
      },
      {
        x: 29,
        y: 82,
      },
      {
        x: 72,
        y: 3,
      },
      {
        x: 74,
        y: 39,
      },
      {
        x: 52,
        y: 106,
      },
      {
        x: 83,
        y: 106,
      },
      {
        x: 5,
        y: 17,
      },
      {
        x: 39,
        y: 19,
      },
      {
        x: 6,
        y: 106,
      },
      {
        x: 98,
        y: 15,
      },
      {
        x: 74,
        y: 9,
      },
      {
        x: 62,
        y: 28,
      },
      {
        x: 58,
        y: 0,
      },
      {
        x: 18,
        y: 38,
      },
      {
        x: 64,
        y: 88,
      },
      {
        x: 71,
        y: 37,
      },
      {
        x: 67,
        y: 11,
      },
      {
        x: 43,
        y: 54,
      },
      {
        x: 90,
        y: 58,
      },
      {
        x: 34,
        y: 75,
      },
      {
        x: 60,
        y: 9,
      },
      {
        x: 56,
        y: 39,
      },
      {
        x: 80,
        y: 70,
      },
      {
        x: 44,
        y: 23,
      },
      {
        x: 83,
        y: 1,
      },
      {
        x: 88,
        y: 37,
      },
      {
        x: 41,
        y: 107,
      },
      {
        x: 8,
        y: 58,
      },
      {
        x: 30,
        y: 4,
      },
      {
        x: 36,
        y: 7,
      },
      {
        x: 41,
        y: 21,
      },
      {
        x: 15,
        y: 87,
      },
      {
        x: 8,
        y: 97,
      },
      {
        x: 15,
        y: 85,
      },
      {
        x: 46,
        y: 34,
      },
      {
        x: 63,
        y: 79,
      },
      {
        x: 64,
        y: 76,
      },
    ],
  },
  {
    id: "group C",
    data: [
      {
        x: 50,
        y: 58,
      },
      {
        x: 57,
        y: 41,
      },
      {
        x: 79,
        y: 85,
      },
      {
        x: 93,
        y: 49,
      },
      {
        x: 41,
        y: 69,
      },
      {
        x: 21,
        y: 1,
      },
      {
        x: 6,
        y: 49,
      },
      {
        x: 5,
        y: 54,
      },
      {
        x: 81,
        y: 102,
      },
      {
        x: 75,
        y: 111,
      },
      {
        x: 95,
        y: 6,
      },
      {
        x: 9,
        y: 6,
      },
      {
        x: 41,
        y: 66,
      },
      {
        x: 91,
        y: 1,
      },
      {
        x: 6,
        y: 21,
      },
      {
        x: 69,
        y: 91,
      },
      {
        x: 48,
        y: 101,
      },
      {
        x: 17,
        y: 58,
      },
      {
        x: 47,
        y: 115,
      },
      {
        x: 18,
        y: 3,
      },
      {
        x: 69,
        y: 37,
      },
      {
        x: 91,
        y: 89,
      },
      {
        x: 18,
        y: 19,
      },
      {
        x: 33,
        y: 73,
      },
      {
        x: 99,
        y: 108,
      },
      {
        x: 37,
        y: 6,
      },
      {
        x: 66,
        y: 3,
      },
      {
        x: 25,
        y: 116,
      },
      {
        x: 1,
        y: 46,
      },
      {
        x: 52,
        y: 104,
      },
      {
        x: 64,
        y: 20,
      },
      {
        x: 73,
        y: 15,
      },
      {
        x: 52,
        y: 115,
      },
      {
        x: 39,
        y: 30,
      },
      {
        x: 71,
        y: 48,
      },
      {
        x: 59,
        y: 34,
      },
      {
        x: 19,
        y: 94,
      },
      {
        x: 100,
        y: 43,
      },
      {
        x: 86,
        y: 65,
      },
      {
        x: 45,
        y: 77,
      },
      {
        x: 21,
        y: 62,
      },
      {
        x: 84,
        y: 22,
      },
      {
        x: 28,
        y: 13,
      },
      {
        x: 76,
        y: 74,
      },
      {
        x: 51,
        y: 107,
      },
      {
        x: 44,
        y: 13,
      },
      {
        x: 52,
        y: 34,
      },
      {
        x: 28,
        y: 108,
      },
      {
        x: 72,
        y: 115,
      },
      {
        x: 84,
        y: 86,
      },
    ],
  },
  {
    id: "group D",
    data: [
      {
        x: 79,
        y: 57,
      },
      {
        x: 8,
        y: 62,
      },
      {
        x: 8,
        y: 105,
      },
      {
        x: 81,
        y: 27,
      },
      {
        x: 85,
        y: 65,
      },
      {
        x: 13,
        y: 89,
      },
      {
        x: 64,
        y: 15,
      },
      {
        x: 89,
        y: 17,
      },
      {
        x: 67,
        y: 91,
      },
      {
        x: 58,
        y: 38,
      },
      {
        x: 25,
        y: 69,
      },
      {
        x: 4,
        y: 72,
      },
      {
        x: 95,
        y: 68,
      },
      {
        x: 4,
        y: 51,
      },
      {
        x: 74,
        y: 28,
      },
      {
        x: 88,
        y: 78,
      },
      {
        x: 54,
        y: 54,
      },
      {
        x: 56,
        y: 55,
      },
      {
        x: 49,
        y: 85,
      },
      {
        x: 53,
        y: 77,
      },
      {
        x: 27,
        y: 1,
      },
      {
        x: 51,
        y: 36,
      },
      {
        x: 28,
        y: 93,
      },
      {
        x: 9,
        y: 17,
      },
      {
        x: 96,
        y: 10,
      },
      {
        x: 16,
        y: 99,
      },
      {
        x: 59,
        y: 43,
      },
      {
        x: 68,
        y: 84,
      },
      {
        x: 16,
        y: 24,
      },
      {
        x: 19,
        y: 72,
      },
      {
        x: 49,
        y: 92,
      },
      {
        x: 83,
        y: 6,
      },
      {
        x: 99,
        y: 118,
      },
      {
        x: 47,
        y: 73,
      },
      {
        x: 48,
        y: 100,
      },
      {
        x: 70,
        y: 7,
      },
      {
        x: 60,
        y: 61,
      },
      {
        x: 59,
        y: 45,
      },
      {
        x: 49,
        y: 110,
      },
      {
        x: 99,
        y: 111,
      },
      {
        x: 2,
        y: 49,
      },
      {
        x: 17,
        y: 82,
      },
      {
        x: 90,
        y: 20,
      },
      {
        x: 74,
        y: 45,
      },
      {
        x: 39,
        y: 42,
      },
      {
        x: 36,
        y: 99,
      },
      {
        x: 70,
        y: 18,
      },
      {
        x: 9,
        y: 95,
      },
      {
        x: 65,
        y: 16,
      },
      {
        x: 64,
        y: 41,
      },
    ],
  },
  {
    id: "group E",
    data: [
      {
        x: 0,
        y: 12,
      },
      {
        x: 50,
        y: 14,
      },
      {
        x: 11,
        y: 72,
      },
      {
        x: 69,
        y: 6,
      },
      {
        x: 55,
        y: 50,
      },
      {
        x: 83,
        y: 14,
      },
      {
        x: 49,
        y: 62,
      },
      {
        x: 38,
        y: 19,
      },
      {
        x: 15,
        y: 81,
      },
      {
        x: 13,
        y: 68,
      },
      {
        x: 0,
        y: 13,
      },
      {
        x: 1,
        y: 11,
      },
      {
        x: 74,
        y: 19,
      },
      {
        x: 93,
        y: 37,
      },
      {
        x: 80,
        y: 90,
      },
      {
        x: 33,
        y: 33,
      },
      {
        x: 46,
        y: 48,
      },
      {
        x: 74,
        y: 54,
      },
      {
        x: 44,
        y: 87,
      },
      {
        x: 73,
        y: 36,
      },
      {
        x: 90,
        y: 8,
      },
      {
        x: 65,
        y: 114,
      },
      {
        x: 43,
        y: 30,
      },
      {
        x: 82,
        y: 50,
      },
      {
        x: 46,
        y: 50,
      },
      {
        x: 21,
        y: 15,
      },
      {
        x: 88,
        y: 33,
      },
      {
        x: 69,
        y: 62,
      },
      {
        x: 81,
        y: 66,
      },
      {
        x: 78,
        y: 113,
      },
      {
        x: 63,
        y: 76,
      },
      {
        x: 19,
        y: 13,
      },
      {
        x: 50,
        y: 89,
      },
      {
        x: 51,
        y: 98,
      },
      {
        x: 30,
        y: 53,
      },
      {
        x: 69,
        y: 56,
      },
      {
        x: 36,
        y: 37,
      },
      {
        x: 37,
        y: 73,
      },
      {
        x: 59,
        y: 22,
      },
      {
        x: 86,
        y: 77,
      },
      {
        x: 13,
        y: 38,
      },
      {
        x: 88,
        y: 73,
      },
      {
        x: 22,
        y: 36,
      },
      {
        x: 55,
        y: 61,
      },
      {
        x: 71,
        y: 112,
      },
      {
        x: 88,
        y: 96,
      },
      {
        x: 19,
        y: 76,
      },
      {
        x: 59,
        y: 52,
      },
      {
        x: 20,
        y: 0,
      },
      {
        x: 87,
        y: 34,
      },
    ],
  },
];

export const initStackedArea = [
  {
    Raoul: 87,
    Josiane: 100,
    Marcel: 99,
    René: 150,
    Paul: 40,
    Jacques: 178,
  },
  {
    Raoul: 169,
    Josiane: 155,
    Marcel: 62,
    René: 198,
    Paul: 198,
    Jacques: 189,
  },
  {
    Raoul: 38,
    Josiane: 87,
    Marcel: 81,
    René: 200,
    Paul: 182,
    Jacques: 47,
  },
  {
    Raoul: 193,
    Josiane: 181,
    Marcel: 59,
    René: 168,
    Paul: 51,
    Jacques: 16,
  },
  {
    Raoul: 178,
    Josiane: 188,
    Marcel: 68,
    René: 164,
    Paul: 55,
    Jacques: 92,
  },
  {
    Raoul: 13,
    Josiane: 58,
    Marcel: 91,
    René: 164,
    Paul: 191,
    Jacques: 55,
  },
  {
    Raoul: 117,
    Josiane: 173,
    Marcel: 41,
    René: 33,
    Paul: 157,
    Jacques: 84,
  },
  {
    Raoul: 18,
    Josiane: 80,
    Marcel: 77,
    René: 197,
    Paul: 50,
    Jacques: 55,
  },
  {
    Raoul: 33,
    Josiane: 150,
    Marcel: 193,
    René: 143,
    Paul: 49,
    Jacques: 69,
  },
];

// group: 'male';
// mu: 208.3921568627451;
// n: 51;
// sd: 144.9575218374504;
// value: 208;
export const initDataBoxPlot = [
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 3.8613857861265495,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.8626298631875535,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 3.757166188698398,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 3.968257796590731,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.275075220263445,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.68163440776123,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 6.114788649829774,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 6.684142269062024,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 7.080479141606347,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.555231869650467,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.063217642591983,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.746016721278398,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.462330407361161,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.984414041439892,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.174496925086866,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.09832096213883,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 3.402117944076082,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.291844784703943,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.279723657455303,
  },
  {
    group: "Alpha",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.230665922293689,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 4.039002809414884,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 7.778938578387885,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.77127456199084,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.244571188217545,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.62321751334823,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.888681066129606,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 7.088780462863155,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.007077275598357,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 4.79385319114183,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.80273985428291,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 4.1933058185561265,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.922203634539543,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.9436286691363325,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.05726346145135,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.977577300698051,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.0247302373190275,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 4.58182306503221,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 4.213939646361557,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.20495549910238,
  },
  {
    group: "Alpha",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.740477761677768,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 5.634244167125812,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 7.532238280873577,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 7.7256371187435855,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 8.086284860074807,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 8.127463894222016,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 8.421486772998357,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 7.196114462971556,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 9.698911931049535,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 7.329487587976054,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 6.433456669926427,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 7.170826224025697,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 9.269579535751248,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 8.787009945821502,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 9.449697816043468,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 8.235294382932588,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 6.400641677830888,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 10.469273580067576,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 5.286908116153404,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 7.8463365114766335,
  },
  {
    group: "Beta",
    subgroup: "A",
    mu: 8,
    sd: 1.4,
    n: 20,
    value: 5.2745851140686515,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 7.4977085685159315,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 7.683958829793765,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 11.023256517854385,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 5.590681023458085,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 7.708062298140789,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 5.9211957679116685,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 5.93637068418631,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 8.60586871919853,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 7.830880515953709,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 8.062212628621564,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 7.77882230960091,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 6.651940324300811,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 7.563612335344456,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 6.729889606179708,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 7.627037300965292,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 10.636221710284982,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 8.00160732763178,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 6.767567832786225,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 4.204879695764999,
  },
  {
    group: "Beta",
    subgroup: "B",
    mu: 7.5,
    sd: 1.4,
    n: 20,
    value: 8.38797511324165,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.32631040203578,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.395621529630744,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.5939616584664655,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 6.393369761652314,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.743345351344835,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.853947602200906,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.699233652432554,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.531304698812346,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 3.703832158943884,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.428312860946555,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 3.741528970862081,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.816396375127205,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.963906415919259,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.900359690793494,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.849488785134842,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.818165048579361,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.145124417495636,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.519725375788099,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.043122566344385,
  },
  {
    group: "Gamma",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.446585254565331,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 6.472058719321844,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 3.9192200499827807,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 6.9302217344821555,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 7.513499963953956,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 8.354636609028887,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 8.223957202544177,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 6.110986737304115,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 6.949371615716232,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 5.098411883837809,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 5.850773901141903,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 7.201714348220652,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 6.841567958846573,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 8.252042125170734,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 7.492456985440667,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 6.123621673728006,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 7.602185650457578,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 9.133496933559245,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 7.069507521239091,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 2.1651857870752,
  },
  {
    group: "Gamma",
    subgroup: "B",
    mu: 7.2,
    sd: 1.8,
    n: 20,
    value: 8.403784888741091,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.143163887153457,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.0668612815728205,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.063303320150706,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.931986272908218,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.632223699179897,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.662898606876833,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.0748493402928005,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.1359204880805125,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 2.5110088930040946,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.489725307250387,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.863881011287802,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 6.130769662878551,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.5071419903656125,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 4.9512707059153565,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.525663297403301,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.534196330891073,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 3.6977748288431194,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 6.171146445162481,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 5.093096022422683,
  },
  {
    group: "Delta",
    subgroup: "A",
    mu: 5,
    sd: 1,
    n: 20,
    value: 3.891238186572842,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.736628514681068,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.596644738198347,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.607548684295921,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 4.741112122519024,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 3.582459912379889,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.419822210646068,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.4266963467114255,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.312525430003119,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 7.619378975759884,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.4448471217787136,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.457024704913706,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 4.856770900893329,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 7.722850271056715,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.136425245544306,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.720537125266203,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 6.885671892005769,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 8.423097828055592,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.803930912146005,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 5.83234149558829,
  },
  {
    group: "Delta",
    subgroup: "B",
    mu: 6,
    sd: 1,
    n: 20,
    value: 7.323096881854109,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 3.5791895695213274,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 5.707007077096508,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 5.332345234009086,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 3.843491297094303,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 3.108550368195419,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 4.951672050414705,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 6.022427907571821,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 3.5692940517071934,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 6.407338642791429,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 6.150827237084705,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 4.6806465281901,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 5.881650452993304,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 4.4488413377836356,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 4.108499456174947,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 4.612269785798156,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 4.684161488664652,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 4.766966709062743,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 4.347791821468444,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 4.580363662567861,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    mu: 5,
    sd: 1.4,
    n: 20,
    value: 6.421178438509193,
  },
];

export const STAT_FUNCTIONS = {
  total: "total",
  count: "count",
  average: "average",
  median: "median",
};

export const STAT_FUNCTIONS_LABEL = {
  [STAT_FUNCTIONS.total]: "Total",
  [STAT_FUNCTIONS.count]: "Count",
  [STAT_FUNCTIONS.average]: "Average",
  [STAT_FUNCTIONS.median]: "Median",
};

export const STAT_FUNCTIONS_ARR = [
  {
    id: STAT_FUNCTIONS.total,
    label: STAT_FUNCTIONS_LABEL[STAT_FUNCTIONS.total],
  },
  {
    id: STAT_FUNCTIONS.count,
    label: STAT_FUNCTIONS_LABEL[STAT_FUNCTIONS.count],
  },
  {
    id: STAT_FUNCTIONS.average,
    label: STAT_FUNCTIONS_LABEL[STAT_FUNCTIONS.average],
  },
  {
    id: STAT_FUNCTIONS.median,
    label: STAT_FUNCTIONS_LABEL[STAT_FUNCTIONS.median],
  },
];

export const STAT_METRIC_ARR = [
  { id: "total", label: "Total" },
  { id: "average", label: "Average" },
  { id: "minimum", label: "Minimum" },
  { id: "maximum", label: "Maximum" },
  { id: "median", label: "Median" },
  { id: "count", label: "Count" },
  { id: "percentile75th", label: "75th Percentile" },
  { id: "percentile25th", label: "25th Percentile" },
  { id: "standardDeviation", label: "Standard Deviation" },
];

export const TOP_N_ARR = [
  { id: 0, label: "All" },
  { id: 5, label: "Top 5" },
  { id: 10, label: "Top 10" },
  { id: 15, label: "Top 15" },
  { id: 20, label: "Top 20" },
  { id: 25, label: "Top 25" },
];
