import {
  getFunctionsAsync,
  resetFunctionAddEditModal,
} from "../../../store/slices/functions";
import { Field, Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { toaster } from "../../../common/Toaster";
import BaseModal from "../../../common/BaseModal";
import { useDispatch, useSelector } from "react-redux";
import { InputField } from "../../../common/InputField";
import { addFunction, updateFunction, updateStatus } from "../../../services";
import { functionNameValidationSchema } from "../../../utils/validationSchemas";

const FunctionAddEditModal = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const { isFunctionAddEditOpen, functionAddEditData, functionAddEditType } =
    useSelector((state) => state.functions);

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = (resetForm, validateForm) => {
    dispatch(resetFunctionAddEditModal());
    resetForm();
    validateForm();
  };

  const initialValues = {
    function: functionAddEditData?.tag ? functionAddEditData.tag : "",
  };

  const handleChange = (field, value) => {
    field.onChange({
      target: {
        name: field.name,
        value: value,
      },
    });
  };

  const onSubmit = (values, { resetForm, validateForm }) => {
    if (functionAddEditType === "ADD") {
      setIsLoading(true);
      addFunction({
        payload: {
          tag: values.function,
        },
      })
        .then((response) => {
          setIsLoading(false);
          dispatch(getFunctionsAsync());
          toaster({
            type: "success",
            message:
              response?.data?.toaster?.data?.message ||
              "Function was successfully created.",
          });
          handleClose(resetForm, validateForm);
        })
        .catch((e) => {
          setIsLoading(false);
          toaster({
            type: "error",
            message: e,
          });
        });
    } else {
      setIsLoading(true);
      updateFunction({
        functionId: functionAddEditData?.id,
        payload: {
          tag: values.function,
        },
      })
        .then((response) => {
          dispatch(getFunctionsAsync());
          setIsLoading(false);
          handleClose(resetForm, validateForm);

          toaster({
            type: "success",
            message:
              response?.data?.toaster?.data?.message ||
              "Function Name was successfully updated.",
          });
        })
        .catch((e) => {
          setIsLoading(false);
          toaster({ type: "error", message: e });
        });
    }
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={functionNameValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      initialTouched={{ title: true }}
      validateOnMount={true}
      enableReinitialize={true}
      onSubmit={onSubmit}>
      {({ touched, errors, values, isValid, resetForm, validateForm }) => (
        <BaseModal
          title={
            functionAddEditType === "ADD" ? "Add Function" : "Function Edit"
          }
          isOpen={isFunctionAddEditOpen}
          handleClose={() => {
            handleClose(resetForm, validateForm);
          }}
          confirmTitle={functionAddEditType === "ADD" ? "Submit" : "Update"}
          handleSubmit={handleSubmitClick}
          isLoading={isLoading}
          submitDisable={errors && Object.keys(errors)?.length !== 0}>
          <Form>
            <Field name='function'>
              {({ field, form }) => (
                <InputField
                  name='function'
                  placeholder='Enter function name here'
                  isLabel={true}
                  label='Function Name'
                  variant={"outlined"}
                  value={field.value}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmitClick();
                    }
                  }}
                  onChange={({ currentTarget: input }) =>
                    handleChange(field, input.value)
                  }
                  onBlur={() => {
                    form.setTouched({
                      ...form.touched,
                      function: true,
                    });
                  }}
                  error={form.touched.function && Boolean(form.errors.function)}
                  helperText={form.touched.function && form.errors.function}
                />
              )}
            </Field>
          </Form>
        </BaseModal>
      )}
    </Formik>
  );
};

export default FunctionAddEditModal;
