import { Close } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import "./Toaster.css";

export const toaster = ({
  type = "success",
  title = "",
  message = "",
  position = "top-center",
  autoClose = 5000,
}) => {
  const CustomToast = ({ closeToast, toastProps }) => {
    return (
      <Stack
        spacing={1}
        sx={{}}
        role='alert'
        aria-live='assertive'
        aria-atomic='true'>
        <Alert
          severity={type}
          variant='filled'
          action={
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={() => closeToast()}>
              <Close fontSize='inherit' />
            </IconButton>
          }>
          {title && (
            <AlertTitle variant='spec_h3' color='inherit'>
              {title}
            </AlertTitle>
          )}
          <Typography variant='spec_body' color='inherit'>
            {message}
          </Typography>
        </Alert>
      </Stack>
    );
  };

  return toast(<CustomToast />, {
    position: position,
    autoClose: autoClose,
  });
};
