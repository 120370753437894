import _ from "lodash";

export const getFunctionsSettingsRows = (statuses) => {
  return statuses?.map((status) => {
    return {
      id: status?.id,
      tag: status?.tag,
    };
  });
};
