import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { NoDataFound } from "../../assets";
import LoadingButton from "../../common/LoadingButton";
import { AddRounded } from "@mui/icons-material";

const NoRowsOverlayCustomComponentBussinessTerms = (props) => {
  return (
    <Stack>
      <Box>
        {props?.isEmpty ? (
          <>
            <LoadingButton
              startIcon={<AddRounded />}
              sx={{ height: "30px", mb: 2 }}
              onClick={props?.handleClick}>
              Add New Business Term
            </LoadingButton>
          </>
        ) : (
          <NoDataFound
            style={{
              height: "220px",
              width: "220px",
            }}
          />
        )}
      </Box>
      <Typography variant='spec_body' sx={{ px: 4 }}>
        {props?.noRowsMessageFunc()}
      </Typography>
    </Stack>
  );
};

export default NoRowsOverlayCustomComponentBussinessTerms;
