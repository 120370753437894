import { ChipRole } from "../../common/ChipRole/ChipRole";
import { ChipStatus } from "../../common/ChipStatus/ChipStatus";
import AvatarWithLabelCell from "../SpecsListing/AvatarWithLabelCell";
import ActionCell from "./ActionCell";

const commonCellStyle = { wordBreak: "break-word", lineHeight: "normal" };
export const getUserColumns = () => {
  return [
    {
      field: "Actions",
      editable: false,
      headerName: "Actions",
      resizable: false,
      pinned: "left",
      cellDataType: "text",
      width: 90,
      sortable: false,
      cellStyle: { commonCellStyle },
      cellRenderer: ActionCell,
    },
    {
      field: "member",
      editable: false,
      headerName: "Member",
      resizable: false,
      cellDataType: "text",
      minWidth: 260,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
      cellRenderer: (props) => (
        <AvatarWithLabelCell {...props} allowEmptyAvatar={true} />
      ),
      pinned: "left",
    },
    {
      field: "email",
      editable: false,
      headerName: "Email",
      resizable: false,
      cellDataType: "text",
      minWidth: 260,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
    },
    {
      field: "role",
      editable: false,
      headerName: "Role",
      resizable: false,
      cellDataType: "text",
      minWidth: 160,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
      cellRenderer: (props) => (
        <ChipRole
          role={props?.value}
          sx={{
            fontWeight: 400,
          }}
        />
      ),
    },
    {
      field: "status",
      editable: false,
      headerName: "Status",
      resizable: false,
      cellDataType: "text",
      minWidth: 160,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
      cellRenderer: (props) => <ChipStatus status={props?.value} />,
    },
    {
      field: "position",
      editable: false,
      headerName: "Position",
      resizable: false,
      cellDataType: "text",
      minWidth: 260,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
    },
    {
      field: "department",
      editable: false,
      headerName: "Department",
      resizable: false,
      cellDataType: "text",
      minWidth: 260,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
    },
    {
      field: "company",
      editable: false,
      headerName: "Company",
      resizable: false,
      cellDataType: "text",
      minWidth: 260,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
    },
    {
      field: "specificationCount",
      editable: false,
      headerName: "Specs Associated",
      resizable: false,
      cellDataType: "text",
      minWidth: 180,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
    },
    {
      field: "dateJoined",
      editable: false,
      headerName: "Date Joined",
      resizable: false,
      cellDataType: "text",
      minWidth: 180,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
    },
  ];
};
