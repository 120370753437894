import React from "react";

import { useTheme } from "@mui/material";
import { Mention, MentionsInput } from "react-mentions";

import { mentionStyle } from "./mentionStyle";
import { defaultStyle } from "./defaultStyle";

const PromptTagField = ({
  value = "",
  handleChange = () => {},
  placeholder = "",
  padding,
  minHeight,
  maxHeight,
  data = [],
  isView = false,
  singleLine = true,
  trigger = "#",
}) => {
  const theme = useTheme();
  return (
    <MentionsInput
      className='hello'
      value={value}
      onChange={handleChange}
      singleLine={singleLine}
      placeholder={placeholder}
      disabled={isView}
      style={defaultStyle(theme, isView, minHeight, maxHeight)}
      forceSuggestionsAboveCursor={true}>
      <Mention
        className='hello1'
        trigger={trigger}
        markup='@[__display__](__id__)'
        displayTransform={(id, display) => `${display}`}
        data={data}
        style={mentionStyle(theme)}
      />
    </MentionsInput>
  );
};

export default PromptTagField;
