import { AppBar, styled as MuiStyled, Toolbar } from "@mui/material";

export const StyledAppBar = MuiStyled((props) => <AppBar {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.secondary.white,
    boxShadow: "none",
    minHeight: `45px !important`,
    borderBottom: `1px solid ${theme.palette.gray.borderGray}`,
    paddingTop: 0,
    paddingBottom: 0,
  })
);

export const StyledToolbar = MuiStyled((props) => <Toolbar {...props} />)(
  ({ theme }) => ({
    minHeight: `45px !important`,
    paddingTop: 0,
    paddingBottom: 0,

    [theme.breakpoints.down("sm")]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
  })
);
