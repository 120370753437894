export const getSpecKpiRows = (kpis, isPreview) => {
  const rowData = kpis?.map((kpi) => {
    return {
      id: kpi?.id,
      name: kpi?.name,
      targetValue: kpi?.targetValue,
      description: kpi?.description,
      originalObject: kpi,
    };
  });

  return rowData;
};
