import _, { debounce } from "lodash";
import "./VisualRequirements.css";
import { updateTab } from "../../services";
import { useParams } from "react-router-dom";
import { toaster } from "../../common/Toaster";
import { HTTP_OK } from "../../constants/statusCodes";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Stack } from "@mui/material";
import { defaultMessage } from "../../constants/messages";
import React, { useCallback, useEffect, useRef, useState } from "react";
import QuillRTEComponent from "../../common/QuillRTEComponent";
import { updateSpecTab } from "../../store/slices/mockDataset";

const VisualRequirements = ({ isPreview }) => {
  const dispatch = useDispatch();
  const { specId } = useParams();
  const { currentSelectedTab, specMockdataTabs, isSpecMockdataTabsLoading } =
    useSelector((state) => state.mockDataset);

  const [originalValue, setOriginalValue] = useState("");
  const [value, setValue] = useState("");
  // const valueRef = useRef();
  const originalValueRef = useRef();

  useEffect(() => {
    const requirements = specMockdataTabs?.find(
      (tab) => tab?.id === currentSelectedTab
    )?.requirements;
    setOriginalValue(requirements);
    setValue(requirements);
  }, [currentSelectedTab]);

  // useEffect(() => {
  //   valueRef.current = value;
  // }, [value]);

  useEffect(() => {
    originalValueRef.current = originalValue;
  }, [originalValueRef]);

  // useEffect(() => {
  //   return () => {

  //     };

  //     if (!_.isEqual(originalValueRef, valueRef)) {
  //       saveRequirements();
  //     }
  //   };
  // }, []);

  const updateLink = useCallback(
    debounce((value) => {
      const saveRequirements = async () => {
        const payload = {
          spec_mockdata_tab: {
            requirements: value,
          },
        };

        try {
          const response = await updateTab({
            specId: specId,
            tabId: currentSelectedTab,
            payload: payload,
          });

          if (response?.status !== HTTP_OK) {
            throw new Error(defaultMessage);
          }

          dispatch(
            updateSpecTab({
              tabId: currentSelectedTab,
              newTab: response?.data?.specMockdataTab,
            })
          );
        } catch (error) {
          toaster({
            type: "error",
            message: "Error while saving visual requirements!",
          });
        }
      };

      if (!_.isEqual(originalValueRef, value)) {
        saveRequirements();
      }
    }, 1000),
    []
  );

  useEffect(() => {
    if (isSpecMockdataTabsLoading) {
      setValue("");
    }
  }, [isSpecMockdataTabsLoading]);

  return (
    <Stack sx={{ height: 1 }} className='visual-requirements'>
      {isSpecMockdataTabsLoading ? (
        <Stack
          sx={{ height: 1 }}
          justifyContent={"center"}
          alignItems={"center"}
          g>
          <CircularProgress size={45} />
        </Stack>
      ) : (
        <QuillRTEComponent
          value={value}
          isPreview={isPreview}
          setValue={(value) => {
            setValue(value);
            updateLink(value);
          }}
          placeholder={
            isPreview
              ? "No Requirements Found."
              : "Add context and other requirements for the visual here."
          }
        />
      )}
    </Stack>
  );
};

export default VisualRequirements;
