import { Button, Stack } from "@mui/material";
import { styled as MuiStyled } from "@mui/material/styles";

export const StyledCompanyToggle = MuiStyled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  height: "45px",
  ":hover": {
    backgroundColor: "transparent",
  },
}));

export const MenuItemTextContainer = MuiStyled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  columnGap: theme.spacing(0.5),
  alignItems: "center",
  color: theme.palette.gray.lighter,
}));
