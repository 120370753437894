import _, { debounce } from "lodash";
import "./BusinessRequirementsForm.css";
import { useParams } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { updateSpecification } from "../../services";
import { InputField } from "../../common/InputField";
import { useDispatch, useSelector } from "react-redux";
import { BorderColorOutlined } from "@mui/icons-material";
import QuillRTEComponent from "../../common/QuillRTEComponent";
import React, { useCallback, useEffect, useState } from "react";
import UserStorySkeleton from "../../common/Skeletons/UserStorySkeleton";
import {
  getSpecDataForBusinessRequirementsAsync,
  updateAutoSaved,
} from "../../store/slices/userSpec";
import InfoTooltip from "../../common/InfoTooltip";

const BusinessRequirementsForm = () => {
  const dispatch = useDispatch();

  const { specId } = useParams();
  const { specData, isSpecLoadingForBusinessRequirements } = useSelector(
    (state) => state?.spec
  );

  const [additionalInfo, setAdditionalInfo] = useState("");
  const [persona, setPersona] = useState("");
  const [action, setAction] = useState("");
  const [outcome, setOutcome] = useState("");

  useEffect(() => {
    if (specData?.otherBusinessConsiderations) {
      setAdditionalInfo(specData?.otherBusinessConsiderations);
    }

    if (specData?.businessNeed) {
      setPersona(specData?.businessNeed?.persona);
      setAction(specData?.businessNeed?.action);
      setOutcome(specData?.businessNeed?.outcome);
    }
  }, [specData]);

  useEffect(() => {
    return () => dispatch(getSpecDataForBusinessRequirementsAsync({ specId }));
  }, []);

  const updateBusinessRequirement = useCallback(
    debounce(({ persona, action, outcome, additionalInfo }) => {
      updateSpecification({
        payload: {
          specification: {
            business_need_attributes: {
              action: action,
              outcome: outcome,
              persona: persona,
            },
            other_business_considerations: additionalInfo,
          },
        },
        id: specId,
      }).then((response) => {
        // DO NOTHING AS OF NOW AS ITS A AUTO SAVE FUNCTIONALITY
        dispatch(updateAutoSaved(true));
      });
    }, 2000),
    []
  );

  useEffect(() => {
    if (
      specData &&
      !isSpecLoadingForBusinessRequirements &&
      (persona?.length ||
        action?.length ||
        outcome?.length ||
        additionalInfo?.length) &&
      (persona !== specData.businessNeed?.persona ||
        action !== specData.businessNeed?.action ||
        outcome !== specData.businessNeed?.outcome ||
        additionalInfo !== specData.otherBusinessConsiderations)
    ) {
      updateBusinessRequirement({ persona, action, outcome, additionalInfo });
    }
  }, [persona, action, outcome, additionalInfo]);

  const EditIcon = () => {
    return (
      <BorderColorOutlined
        fontSize='small'
        sx={(theme) => ({
          color: theme.palette.gray.iconColor,
        })}
      />
    );
  };

  return (
    <Stack rowGap={4}>
      <Stack direction={"row"} columnGap={1} sx={{ alignItems: "center" }}>
        <Typography variant='spec_h3'>User Story </Typography>
        <InfoTooltip
          infoText='Explanation of the data feature focusing on how it will provide value to the end user'
          placement='right'
          isInfoIcon={true}
        />
      </Stack>

      {isSpecLoadingForBusinessRequirements ? (
        <UserStorySkeleton />
      ) : (
        <Stack
          pl={3}
          columnGap={2}
          sx={{
            flexDirection: { xs: "column" },
            rowGap: 2,
            height: "max-content",
          }}>
          <Stack
            rowGap={2}
            sx={{
              width: { xs: 1 },
            }}
            width={"50%"}>
            <InputField
              value={persona}
              onChange={(e) => {
                setPersona(e.target.value);
              }}
              isLabel={true}
              label='As a/an:'
              placeholder='Enter a role or title'
              endAdornment={<EditIcon />}
            />
            <InputField
              value={action}
              onChange={(e) => {
                setAction(e.target.value);
              }}
              isLabel={true}
              label='I want to:'
              placeholder='Enter the analysis to be done with the data'
              endAdornment={<EditIcon />}
            />
            <InputField
              value={outcome}
              onChange={(e) => {
                setOutcome(e.target.value);
              }}
              isLabel={true}
              label='so that:'
              placeholder='Enter the target business outcome'
              endAdornment={<EditIcon />}
            />
          </Stack>
          <Stack
            sx={{
              mt: { xs: 1 },
              width: { xs: 1 },
              pb: { xs: "66px", sm: "42px" },
            }}
            rowGap={0.7}
            className='business-requirements'>
            <Typography variant='spec_inputLabel'>
              Additional Information:
            </Typography>
            <QuillRTEComponent
              value={additionalInfo}
              setValue={setAdditionalInfo}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default BusinessRequirementsForm;
