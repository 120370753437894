import React, { useEffect, useRef } from "react";
import { chartColors } from "../../SelectVisuals/utils";
import { useSelector } from "react-redux";

function PieChart({ chartData, xTitle, yTitle }) {
  const chartRef = useRef(null);
  const containerRef = useRef(null);
  const r = useRef((Math.random() + 1).toString(36).substring(7));
  const reduxState = useSelector((state) => state?.spec);

  useEffect(() => {
    const renderChart = () => {
      setTimeout(() => {
        drawPieChart();
      }, 150);
    };

    renderChart();
  }, [
    containerRef.current?.clientHeight,
    containerRef.current?.clientWidth,
    reduxState?.isSpecVisualDatasetLoading,
    chartData,
  ]);

  const drawPieChart = () => {
    if (!window.google) return; // Make sure google is loaded
    let data = Object.values(chartData);
    const createData = data?.map((d) => [d.id, d.value]);

    function drawChart() {
      const data = window.google.visualization.arrayToDataTable([
        [xTitle, yTitle],
        ...createData,
      ]);

      const options = {
        title: "",
        pieHole: 0.4,
        colors: [...chartColors],
        height: containerRef.current?.clientHeight,
        chartArea: {
          width: "100%",
          height: containerRef.current?.clientHeight - 80,
          top: "2%",
          bottom: "12%",
        },
        tooltip: { isHtml: true },
      };

      const chart = new window.google.visualization.PieChart(chartRef.current);
      chart.draw(data, options);
    }
    if (!reduxState?.isSpecVisualDatasetLoading)
      window.google.charts.setOnLoadCallback(drawChart);
  };

  return (
    <div
      ref={containerRef}
      id={r.current}
      style={{ height: "100%", padding: 0, margin: 0 }}
      className='cancelDraggableElement'>
      <div
        ref={chartRef}
        id={`${r.current}_div`}
        style={{
          padding: 0,
          margin: 0,
        }}></div>
    </div>
  );
}

export default PieChart;
