import {
  AddRounded,
  ClearRounded,
  FileDownloadOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Stack,
  ToggleButton,
  Typography,
  useTheme,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { SecondaryAppBar } from "../../common/commonStyle";
import { InputField } from "../../common/InputField";
import LoadingButton from "../../common/LoadingButton";
import {
  getBusinessTerms,
  getMappingColumnsAsync,
  setCurrentTermTab,
  setTermModal,
} from "../../store/slices/businessTerms";
import CustomOverlayLoadingComponent from "../../common/CustomOverlayLoadingComponent";
import NoRowsOverlayCustomComponent from "../../common/NoRowsOverlayCustomComponent";
import AgGridComponent from "../../common/AgGridComponent";
import CustomHeaderCell from "../../common/CustomHeaderCell";
import _ from "lodash";
import { TabGroup } from "../../common/style";
import { ROLES, STATE_BUSINESS_TERMS, TYPE_LABEL } from "../../constants";
import { getMappingsColumns } from "./columns";
import { getRowData } from "./helperFunctions";
import {
  resetTableFilters,
  tableFilterInitialState,
} from "../../store/slices/tableFilters";
import AddEditBusinessTerm from "../../components/AddEditBusinessTerm";
import csvDownload from "json-to-csv-export";
import InfoPopup from "../../common/InfoPopup";
import NoRowsOverlayCustomComponentBussinessTerms from "./NoRowsOverlayCustomComponentBusinessTerms";

const MappingsLibrary = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { user } = useSelector((state) => state.userAuth);
  const {
    mappingColumns,
    businessTerms,
    currentTermTab,
    isBuisnessTermLoading,
  } = useSelector((state) => state.businessTerms);
  const { filters } = useSelector((state) => state.tableFilter);
  const isFilterApplied = !_.isEqual(tableFilterInitialState?.filters, filters);

  const [searchText, setSearchText] = useState("");

  const [colDef, setColDef] = useState([]);
  const [isGridReady, setIsGridReady] = useState(false);
  const [businessTermsRowsForAgGrid, setBusinessTermsRowsForAgGrid] = useState(
    []
  );
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const onSearchFilterChanged = useCallback(() => {
    const displayedRows = ref.current.api.getDisplayedRowAtIndex(0);
    if (!displayedRows) {
      showNoRowsOverlay();
    } else {
      hideOverlay(); // Hide any existing overlay
    }
  }, []);

  // SHOW OVERLAY
  const showLoading = useCallback(() => {
    return ref?.current?.api?.showLoadingOverlay();
  }, []);

  // SHOW NO ROWS OVERLAY
  const showNoRowsOverlay = useCallback(() => {
    return ref.current.api.showNoRowsOverlay();
  }, []);

  // HIDE OVERLAY
  const hideOverlay = useCallback(() => {
    return ref?.current?.api?.hideOverlay();
  }, []);

  const onGridReady = useCallback(() => {
    setIsGridReady(true);
  }, []);

  // CUSTOM HEADER
  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeaderCell,
    };
  }, []);

  // SHOW OR HIDE LOADING OVERLAY
  useEffect(() => {
    if (ref && ref?.current && isGridReady) {
      if (isBuisnessTermLoading) {
        showLoading();
      } else {
        hideOverlay();
      }
    }
  }, [ref, isBuisnessTermLoading, isGridReady]);

  const onFilterTextBoxChanged = useCallback(
    (e) => {
      setSearchText(e.target.value);
      ref.current.api.setGridOption("quickFilterText", e.target.value);

      // Call onSearchFilterChanged to trigger check for empty rows
      onSearchFilterChanged();
    },
    [onSearchFilterChanged]
  );

  useEffect(() => {
    setSearchText("");
    ref?.current?.api?.setGridOption("quickFilterText", "");
    dispatch(resetTableFilters());
  }, [currentTermTab]);

  useEffect(() => {
    dispatch(getMappingColumnsAsync());
    dispatch(
      getBusinessTerms({
        status: currentTermTab,
      })
    )
      .unwrap()
      .then(() => {
        setIsInitialLoading(false);
      });

    return () => dispatch(resetTableFilters());
  }, []);

  // useEffect(() => {
  //   dispatch(
  //     getBusinessTerms({
  //       status: currentTermTab,
  //     })
  //   );
  // }, [currentTermTab]);

  useEffect(() => {
    dispatch(
      getBusinessTerms({
        status: currentTermTab,
        dataType: filters?.["dataType"]
          ? filters?.["dataType"]?.map((selected) => selected?.value)
          : [],
        proposedBy: filters?.["proposedBy"]
          ? filters?.["proposedBy"]?.map((selected) => selected?.value)
          : [],
        businessTermCategory: filters?.["businessTermCategory"]
          ? filters?.["businessTermCategory"]?.map(
              (selected) => selected?.value
            )
          : [],
      })
    );
  }, [filters, currentTermTab]);

  useEffect(() => {
    if (mappingColumns) {
      const canEdit = user?.role === ROLES.ADMIN || user?.superAdmin;
      const columns = getMappingsColumns(
        mappingColumns,
        currentTermTab,
        canEdit
      );
      setColDef(columns);
    }
  }, [mappingColumns, currentTermTab]);

  useEffect(() => {
    const newRows = getRowData(businessTerms);

    setBusinessTermsRowsForAgGrid(newRows);
  }, [businessTerms]);

  // Map fields to rows
  const mappedRows = businessTermsRowsForAgGrid?.map((row) => {
    const mappedRow = {};
    const csvColumns = colDef?.filter((col) => col?.field !== "action");

    csvColumns?.forEach((col) => {
      mappedRow[_?.capitalize(col?.headerName)] = row?.[col?.field]
        ? row?.[col?.field]
        : "-";
    });
    return mappedRow;
  });

  return (
    <>
      <Stack sx={{ width: 1, height: 1 }}>
        <SecondaryAppBar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            overflowY: "hidden",
            overflowX: "auto",
            scrollbarWidth: "none",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}>
          <Stack direction={"row"} alignItems={"center"} columnGap={1}>
            <Stack alignItems={"center"}>
              <TabGroup
                sx={{ ml: 1, flex: 10 }}
                size='small'
                value={currentTermTab}
                exclusive
                onChange={(e, tab) => {
                  dispatch(setCurrentTermTab(tab));
                }}
                aria-label='text alignment'>
                <ToggleButton
                  value={STATE_BUSINESS_TERMS.EXISTS}
                  aria-label='Taxonomy'>
                  <Typography variant='spec_body'>Approved</Typography>
                </ToggleButton>
                <ToggleButton
                  value={STATE_BUSINESS_TERMS.PROPOSED}
                  aria-label='List'>
                  <Typography variant='spec_body'>Proposed</Typography>
                </ToggleButton>
                <ToggleButton
                  value={STATE_BUSINESS_TERMS.DECLINED}
                  aria-label='Taxonomy'>
                  <Typography variant='spec_body'>Declined</Typography>
                </ToggleButton>
              </TabGroup>
            </Stack>
            <Stack sx={{ width: "350px" }}>
              <InputField
                className={"searchInput"}
                variant={"outlined"}
                value={searchText}
                onChange={onFilterTextBoxChanged}
                placeholder='Search for specs by title or user story'
                startAdornment={
                  <SearchOutlined
                    fontSize='small'
                    sx={(theme) => ({
                      color: theme.palette.gray.iconColor,
                    })}
                  />
                }
              />
            </Stack>
          </Stack>
          <Stack direction='row' columnGap={1} alignItems={"center"}>
            <InfoPopup tab={1} />
            {isFilterApplied && (
              <Button
                variant='outlined'
                color='error'
                sx={{ height: "30px" }}
                onClick={() => dispatch(resetTableFilters())}
                startIcon={<ClearRounded />}>
                Clear All Filter
              </Button>
            )}
            {mappedRows?.length > 0 && (
              <Button
                startIcon={<FileDownloadOutlined />}
                sx={{ height: "30px" }}
                onClick={() => {
                  csvDownload({
                    data: mappedRows,
                    filename:
                      currentTermTab === STATE_BUSINESS_TERMS.EXISTS
                        ? "ApprovedBusinessTerms"
                        : currentTermTab === STATE_BUSINESS_TERMS.PROPOSED
                        ? "ProposedBusinessTerms"
                        : "DeclinedBusinessTerms",
                    delimiter: ",",
                  });
                }}>
                Export To CSV
              </Button>
            )}

            {currentTermTab === STATE_BUSINESS_TERMS.EXISTS && (
              <LoadingButton
                startIcon={<AddRounded />}
                sx={{ height: "30px" }}
                onClick={() => {
                  dispatch(
                    setTermModal({
                      isTermModalOpen: true,
                      termModalData: null,
                      termModalType: "ADD",
                    })
                  );
                }}>
                Add New Business Term
              </LoadingButton>
            )}
          </Stack>
        </SecondaryAppBar>
        {isInitialLoading ? (
          <Stack
            justifyContent='center'
            alignItems='center'
            sx={{
              height: 1,
              width: 1,
            }}>
            <CircularProgress size={60} />
          </Stack>
        ) : (
          <AgGridComponent
            ref={ref}
            columnDefs={colDef}
            data={businessTermsRowsForAgGrid}
            components={components}
            loadingOverlayComponent={CustomOverlayLoadingComponent}
            noRowsOverlayComponent={
              currentTermTab === STATE_BUSINESS_TERMS.EXISTS
                ? NoRowsOverlayCustomComponentBussinessTerms
                : NoRowsOverlayCustomComponent
            }
            noRowsOverlayComponentParamsObj={{
              isEmpty: !(isFilterApplied || searchText?.length),
              handleClick: () => {
                dispatch(
                  setTermModal({
                    isTermModalOpen: true,
                    termModalData: null,
                    termModalType: "ADD",
                  })
                );
              },
            }}
            rowModelType='clientSide'
            onGridReady={onGridReady}
            paginationPageSize={25}
            noRowsMessage={
              currentTermTab === STATE_BUSINESS_TERMS.EXISTS
                ? isFilterApplied || searchText?.length
                  ? "No approved term found related to search term or filter applied."
                  : `To create a business term, add the necessary term details and map them to the available attributes. These terms will then be available for use in specifications. Click on "Add New Business Term" to get started.`
                : currentTermTab === STATE_BUSINESS_TERMS.PROPOSED
                ? isFilterApplied || searchText?.length
                  ? "No proposed term found related to search term or filter applied."
                  : "Propose terms and their mappings. These terms will need to be approved by your admin before they can be available for use in specs."
                : isFilterApplied || searchText?.length
                ? "No declined term found related to search term or filter applied."
                : "No declined terms available."
            }
          />
        )}
      </Stack>
      <AddEditBusinessTerm />
    </>
  );
};

export default MappingsLibrary;
