import React, { useEffect, useRef } from "react";
import "./style.css";
import { chartColors } from "../../SelectVisuals/utils";
import { useSelector } from "react-redux";

const BarChart = ({ chartData, xTitle, yTitle, filterSettings, metric }) => {
  const chartRef = useRef(null);
  const containerRef = useRef(null);
  const reduxState = useSelector((state) => state?.spec);

  const drawBarChart = () => {
    if (!window.google) return; // Make sure google is loaded
    const createData = chartData[0].dataForChart.map((d) => [
      d.id,
      d.value,
      createCustomHTMLTooltip(d.id, d.value, chartData[0].symbol),
    ]);
    function drawBarColors() {
      const data = window.google.visualization.arrayToDataTable([
        [
          xTitle,
          yTitle,
          { type: "string", role: "tooltip", p: { html: true } },
        ],
        ...createData,
      ]);

      const options = {
        title: "",
        tooltip: { isHtml: true },
        height: containerRef.current?.clientHeight,
        chartArea: {
          width: "70%",
          height: containerRef.current?.clientHeight - 80,
          top: "2%",
        },
        colors: [...chartColors],
        legend: { position: "none" },
        bars: "horizontal",
        hAxis: {
          format: "short",
          title:
            chartData[0]?.symbol &&
            (chartData[0]?.symbol === "$" || chartData[0]?.symbol === "%")
              ? `${chartData[0].yaxisname} (${metric} in ${chartData[0].symbol})`
              : `${chartData[0].yaxisname} (${chartData[0]?.metric})`,
          textStyle: { fontSize: 12, fontName: "Poppins" },
          titleTextStyle: {
            italic: false,
            fontSize: 12,
            fontName: "Poppins-Bold",
          },
        },
        vAxis: {
          title: chartData[0]?.timeName,
          format: "short",
          textStyle: { fontSize: 12, fontName: "Poppins" },
          titleTextStyle: {
            italic: false,
            fontSize: 12,
            fontName: "Poppins-Bold",
          },
          maxTextLines: 1, // maximum number of lines to wrap to
          maxAlternation: 1, // maximum layers of labels (setting this higher than 1 allows labels to stack over/under each other)
          // minTextSpacing: 1, // minimum space in pixels between adjacent labels
        },
      };

      const chart = new window.google.visualization.BarChart(chartRef.current);
      chart.draw(data, options);
    }
    if (!reduxState?.isSpecVisualDatasetLoading)
      window.google.charts.setOnLoadCallback(drawBarColors);
  };

  useEffect(() => {
    const renderChart = () => {
      setTimeout(() => {
        drawBarChart();
      }, 150);
    };

    renderChart();
  }, [
    chartData,
    containerRef.current?.clientHeight,
    containerRef.current?.clientWidth,
  ]);

  function createCustomHTMLTooltip(quarter, value, symbol) {
    const fixedValue = Number(value?.toFixed(2));
    const newValue = fixedValue?.toLocaleString("en-US");

    return (
      '<div style="padding:5px;">' +
      `<strong>${chartData[0].timeName}: </strong>` +
      quarter +
      "<br/>" +
      `<strong>${chartData[0].yaxisname}: </strong>` +
      `${
        symbol === "$"
          ? "$" + newValue
          : symbol === "%"
          ? newValue + "%"
          : newValue
      }` +
      "</div>"
    );
  }

  // useEffect(() => {
  //   console.log("onClientHeight Change");
  //   const reRenderChart = () => {
  //     setTimeout(() => {
  //       drawBarChart();
  //     }, 0);
  //   };

  //   reRenderChart();
  // }, [containerRef.current?.clientHeight, containerRef.current?.clientWidth]);

  return (
    <div
      ref={containerRef}
      style={{ height: "100%" }}
      className='cancelDraggableElement'>
      <div ref={chartRef} style={{ height: "100%" }}></div>
    </div>
  );
};

export default BarChart;
