import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const QuillRTEComponent = ({
  value,
  setValue,
  placeholder = "Type someting here...",
  isPreview = false,
}) => {
  return (
    <ReactQuill
      theme='snow'
      value={value}
      onChange={setValue}
      readOnly={isPreview}
      modules={{
        clipboard: {
          matchVisual: false,
        },
        toolbar: isPreview
          ? null
          : [
              // [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ["bold", "italic", "underline", "strike", "blockquote"],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              ["link", "image"],
              ["clean"],
            ],
      }}
      formats={[
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
      ]}
      className='quill-editor'
      placeholder={placeholder}
    />
  );
};

export default QuillRTEComponent;
