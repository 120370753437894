import Cookies from "js-cookie";
import { persistor } from "../../store";
import { getUserColumns } from "./columns";
import { toaster } from "../../common/Toaster";
import storage from "redux-persist/lib/storage";
import { getUserRows } from "./helperFunctions";
import { ROUTE_PATH } from "../../router/routes";
import { deactivateCompany } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import AgGridComponent from "../../common/AgGridComponent";
import CustomHeaderCell from "../../common/CustomHeaderCell";
import { getUsersAsync } from "../../store/slices/userManagement";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { Stack, Typography, CircularProgress } from "@mui/material";
import DeleteConfirmationModal from "../../common/DeleteConfirmationModal";
import NoRowsOverlayCustomComponent from "../../common/NoRowsOverlayCustomComponent";
import CustomOverlayLoadingComponent from "../../common/CustomOverlayLoadingComponent";

const UserManagement = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userAuth);
  const { users } = useSelector((state) => state.userManagement);
  const { company } = user;
  const [seats, setSeats] = useState(company?.currentPlan?.seats || 0);

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeaderCell,
    };
  }, []);

  useEffect(() => {
    dispatch(getUsersAsync())
      .unwrap()
      .then(() => {
        setIsInitialLoading(false);
      });
  }, []);

  useEffect(() => {
    setColDefs(getUserColumns());
    if (users?.length) {
      setRowData(getUserRows(users));
    }
  }, [users]);

  return (
    <>
      <Stack
        sx={{
          height: 1,
          width: 1,
        }}>
        <Stack
          sx={{
            height: "42px",
            px: { xs: 1, md: 2 },
          }}
          pl={"42px"}
          columnGap={2}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}>
          <Typography variant='spec_h2' sx={{ whiteSpace: "nowrap" }}>
            User Management
          </Typography>

          <Stack
            direction={"row"}
            columnGap={1}
            alignItems={"center"}
            sx={{
              whiteSpace: "nowrap",
              alignItems: "flex-end",
              overflowX: "scroll",
              scrollbarWidth: "none",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}>
            {company?.currentPlan?.planName?.toLowerCase() === "trial" ? (
              <Typography variant='spec_caption' component='span'>
                Unlimited editor seats available in your free trial
              </Typography>
            ) : (
              <>
                <Typography
                  variant='spec_caption'
                  sx={{ whiteSpace: "nowrap" }}>
                  {company?.currentPlan?.availableSeats} / {seats} editor{" "}
                  {seats === 1 ? "seat" : "seats"} available in your plan
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
        <Stack
          sx={(theme) => ({
            height: 1,
            borderTop: `1px solid ${theme.palette.gray.borderGray}`,
          })}>
          {isInitialLoading ? (
            <Stack
              height={1}
              width={1}
              justifyContent={"center"}
              alignItems={"center"}>
              <CircularProgress size={40} />
            </Stack>
          ) : (
            <AgGridComponent
              ref={ref}
              data={rowData}
              columnDefs={colDefs}
              components={components}
              loadingOverlayComponent={CustomOverlayLoadingComponent}
              noRowsOverlayComponent={NoRowsOverlayCustomComponent}
              noRowsMessage='No Users found.'
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default UserManagement;
