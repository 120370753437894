import { Box, styled as muiStyled } from "@mui/material";

export const PaletteCube = muiStyled(Box)(({ theme, backcolor, ismain }) => ({
  height: theme.spacing(5),
  width: theme.spacing(5),
  borderRadius: ismain ? theme.spacing(3) : theme.spacing(1),
  backgroundColor: backcolor,
  cursor: "pointer",
  transition: `transform .2s`,
  ":hover": {
    transform: `scale(1.1)`,
  },
}));
