import { Box, Button, IconButton, Stack, Tooltip } from "@mui/material";
import React, { useState } from "react";
import {
  BuildCircleOutlined,
  FilterListRounded,
  InsightsRounded,
  NumbersOutlined,
} from "@mui/icons-material";
import TextFieldsRoundedIcon from "@mui/icons-material/TextFieldsRounded";
import {
  specGray,
  specPrimaryGray,
  themePalette,
} from "../../constants/theme/colors";
import SelectVisuals from "../SelectVisuals";
import { useDispatch } from "react-redux";
import { setIsVisualConfigOpen } from "../../store/slices/userSpec";
import {
  getDefaultDataChartsAsync,
  updateCurrenSelectedTab,
} from "../../store/slices/mockDataset";
import { useParams } from "react-router-dom";
import { dummyApiCall } from "./dummyJson";

function VisualMockupTopBar({
  addCharts,
  charts,
  addTitle,
  title,
  addStatistic,
  addFilters,
  addChartData,
  setEditIndex,
  isBar = true,
}) {
  const { specId } = useParams();
  const dispatch = useDispatch();
  const [selectVisualDialog, setSelectVisualDialog] = useState(false);
  const [selectedChart, setSelectedChart] = useState(null);

  const handleChartChange = async (chart) => {
    setEditIndex(null);
    dispatch(
      setIsVisualConfigOpen({
        open: false,
        chart: null,
      })
    );
    dispatch(
      getDefaultDataChartsAsync({
        specId: specId,
        payload: {
          chart_type: chart?.type,
        },
      })
    )
      .unwrap()
      .then(async (response) => {
        setSelectedChart(chart);
        let tempHeight = 0;
        let y = 0;
        let w = 0;

        y = charts?.reduce((value, chart) => {
          if (chart?.params?.dimension?.y > value) {
            if (chart?.params.dimension.w < 12) {
              w = chart?.params.dimension.w;
            }
            tempHeight = chart?.params.dimension.h;
            return chart?.params.dimension.y;
          }

          if (chart?.params?.dimension?.y === value) {
            w += chart?.params.dimension.w;
          }

          return value;
        }, 0);
        dispatch(updateCurrenSelectedTab(response?.specMockdataTab?.id));
        await addCharts(
          {
            type: chart?.type,
            label: chart?.label,
            mockDataId: response?.specMockdataTab?.id,
            description:
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            subtitle: "Sample Analysis",
            params: {
              table: response?.specMockdataTab?.id,
              x: response.coordinates?.x?.columnId,
              y: response.coordinates?.y?.columnId,
              xTitle: response.coordinates?.x?.name,
              yTitle: response.coordinates?.y?.name,
              id: chart.id,
              metric: "total",
              isInverted: false,
              dimension: {
                i: Math.random().toString(16).slice(2),
                x: w > 8 ? 0 : w,
                y: w > 8 ? tempHeight + y : y,
                h: 2,
                w: 4,
                minW: 4,
                maxW: 12,
                minH: 2,
                // maxH: 0,
              },
            },
          },
          response
        );
      });
    // setSelectedChart(chart);
    // let tempHeight = 0;
    // let y = 0;
    // let w = 0;

    // y = charts?.reduce((value, chart) => {
    //   if (chart?.params?.dimension?.y > value) {
    //     if (chart?.params.dimension.w < 12) {
    //       w = chart?.params.dimension.w;
    //     }
    //     tempHeight = chart?.params.dimension.h;
    //     return chart?.params.dimension.y;
    //   }

    //   if (chart?.params?.dimension?.y === value) {
    //     w += chart?.params.dimension.w;
    //   }

    //   return value;
    // }, 0);
    // await addCharts(
    //   {
    //     type: chart?.type,
    //     label: chart?.label,
    //     mock_data_id: dummyApiCall?.specMockdataTab?.id,
    //     description:
    //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //     subtitle: "Sample Analysis",
    //     params: {
    //       table: 0,
    //       x: 0,
    //       y: 0,
    //       xTitle: dummyApiCall.coordinates?.x?.name,
    //       yTitle: dummyApiCall.coordinates?.y?.name,
    //       id: chart.id,
    //       metric: "",
    //       dimension: {
    //         i: Math.random().toString(16).slice(2),
    //         x: w > 8 ? 0 : w,
    //         y: w > 8 ? tempHeight + y : y,
    //         h: 2,
    //         w: 4,
    //         minW: 4,
    //         maxW: 12,
    //         minH: 2,
    //         // maxH: 0,
    //       },
    //     },
    //   },
    //   dummyApiCall
    // );
  };

  return (
    <>
      {!isBar ? (
        <Button
          variant='outlined'
          onClick={() => {
            setSelectedChart(null);
            setSelectVisualDialog(true);
          }}
          sx={(theme) => ({
            backgroundColor: themePalette.palette.secondary.white,
            width: "fit-content",
          })}
          startIcon={<InsightsRounded fontSize='small' />}>
          Add Visuals
        </Button>
      ) : (
        <Box
          sx={{
            width: 1,
            backgroundColor: themePalette.palette.secondary.white,
            py: 1,
            borderTop: `1px solid ${specPrimaryGray["borderGray"]}`,
            borderBottom: `1px solid ${specPrimaryGray["borderGray"]}`,
          }}>
          <Box
            sx={{
              width: 1,
              display: "flex",
              overflowX: "auto",
              scrollbarWidth: "none",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}>
            {!title?.type && (
              <Box sx={{ mx: 1 }}>
                <Button
                  variant='outlined'
                  onClick={() => {
                    addTitle();
                  }}
                  startIcon={<TextFieldsRoundedIcon fontSize='small' />}>
                  Add Report Title
                </Button>
              </Box>
            )}
            <Box sx={{ mx: 1 }}>
              <Button
                variant='outlined'
                onClick={() => {
                  setSelectedChart(null);
                  setSelectVisualDialog(true);
                }}
                startIcon={<InsightsRounded fontSize='small' />}>
                Add Visuals
              </Button>
            </Box>
            <Box sx={{ mx: 1 }}>
              <Button
                variant='outlined'
                onClick={() => addFilters()}
                startIcon={<FilterListRounded fontSize='small' />}>
                Add Filter
              </Button>
            </Box>
            <Box sx={{ mx: 1 }}>
              <Button
                variant='outlined'
                onClick={() => addStatistic()}
                startIcon={<NumbersOutlined fontSize='small' />}>
                Add Statistic
              </Button>
            </Box>
            {/* <Stack sx={{ mx: 1, justifyContent: "center" }}>
          <Tooltip title="Open visual config drawer" arrow>
            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                dispatch(setIsVisualConfigOpen({ open: true }));
              }}
            >
              <BuildCircleOutlined fontSize="large" color="info" />
            </IconButton>
          </Tooltip>
        </Stack> */}
          </Box>
        </Box>
      )}

      <SelectVisuals
        open={selectVisualDialog}
        handleClose={() => setSelectVisualDialog(false)}
        selectedChart={selectedChart}
        setSelectedChart={(chart) => handleChartChange(chart)}
      />
    </>
  );
}

export default VisualMockupTopBar;
