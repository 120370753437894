import _ from "lodash";

export const getPrioritiesSettingsRows = (priorities) => {
  return priorities?.map((priority) => {
    return {
      id: priority?.id,
      name: priority?.name,
    };
  });
};
