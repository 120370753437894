import { styled as MuiStyled, Popover } from '@mui/material';

export const StyledUserMenu = MuiStyled(props => (
  <Popover
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPopover-paper': {
    maxHeight: '260px',
    width: '221px',
    borderRadius: '4px',
    marginTop: theme.spacing(1),
    boxShadow: theme.shadows[10],
    padding: theme.spacing(1),
  },
}));
