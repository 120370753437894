import { styled as MuiStyled, Stack } from "@mui/material";

export const Error404Container = MuiStyled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));
