import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PageWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.white,
  height: "100vh",
  overflowY: "auto",
}));

export const LogoHolder = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginLeft: theme.spacing(3),
}));

export const MainArea = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "calc(100vh - 56px)",
}));
