import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

function LineChart({ chartData, xTitle, yTitle }) {
  const chartRef = useRef(null);
  const containerRef = useRef(null);
  const reduxState = useSelector((state) => state?.spec);

  const drawLineChart = () => {
    if (!window.google) return; // Make sure google is loaded
    const createData = chartData[0].data.map((d) => [
      d.x,
      d.y,
      createCustomHTMLTooltip(d.x, d.y, chartData[0].symbol),
    ]);

    function drawBasic() {
      const data = new window.google.visualization.DataTable();
      data.addColumn("string", chartData[0]?.xName);
      data.addColumn("number", yTitle);
      data.addColumn({ type: "string", role: "tooltip", p: { html: true } });

      data.addRows(createData);

      const options = {
        hAxis: {
          title: chartData[0]?.xName,
          textStyle: { fontSize: 12, fontName: "Poppins" },
          titleTextStyle: {
            italic: false,
            fontSize: 12,
            fontName: "Poppins-Bold",
            top: 10,
          },
        },
        pointSize: 6,
        vAxis: {
          title:
            chartData[0]?.symbol &&
            (chartData[0]?.symbol === "$" || chartData[0]?.symbol === "%")
              ? `${chartData[0].yName} (${chartData[0]?.metric} in ${chartData[0].symbol})`
              : `${chartData[0].yName} (${chartData[0]?.metric})`,
          format: "short",
          textStyle: { fontSize: 12, fontName: "Poppins" },
          titleTextStyle: {
            italic: false,
            fontSize: 12,
            fontName: "Poppins-Bold",
          },
          minValue: getMinValue(chartData[0].data),
        },
        tooltip: { isHtml: true },
        colors: ["#F1E15B"],
        legend: { position: "none" },
        height: containerRef.current?.clientHeight,
        chartArea: {
          height: containerRef.current?.clientHeight - 80,
          top: "2%",
        },
      };

      const chart = new window.google.visualization.LineChart(chartRef.current);

      chart.draw(data, options);
    }

    if (!reduxState?.isSpecVisualDatasetLoading)
      window.google.charts.setOnLoadCallback(drawBasic);
  };

  useEffect(() => {
    const renderChart = () => {
      setTimeout(() => {
        drawLineChart();
      }, 150);
    };

    renderChart();
  }, [
    containerRef.current?.clientHeight,
    containerRef.current?.clientWidth,
    reduxState?.isSpecVisualDatasetLoading,
    chartData,
  ]);

  const getMinValue = (data) => {
    return Math.min(...data.map((d) => d.y));
  };

  function createCustomHTMLTooltip(quarter, value, symbol) {
    const fixedValue = Number(value?.toFixed(2));
    const newValue = fixedValue?.toLocaleString("en-US");

    return (
      '<div style="padding:5px;">' +
      `<strong>${chartData[0].xName}: </strong>` +
      quarter +
      "<br/>" +
      `<strong>${yTitle}: </strong>` +
      `${
        symbol === "$"
          ? "$" + newValue
          : symbol === "%"
          ? newValue + "%"
          : newValue
      }` +
      "</div>"
    );
  }

  return (
    <div
      ref={containerRef}
      style={{ height: "100%" }}
      className='cancelDraggableElement'>
      <div ref={chartRef}></div>
    </div>
  );
}

export default LineChart;
