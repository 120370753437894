import {
  BorderColorOutlined,
  DeleteOutline,
  MoreHorizRounded,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import { SquareIconButton } from "../../common/commonStyle";
import CustomPopover from "../../common/CustomPopover";
import MenuItem from "../../common/MenuItem";
import { useDispatch } from "react-redux";
import { setIsKpiBiModalOpen, updateKpiBi } from "../../store/slices/userSpec";
import DeleteConfirmationModal from "../../common/DeleteConfirmationModal";
import { deleteSpecKpi } from "../../services";
import { useParams } from "react-router-dom";
import { HTTP_OK } from "../../constants/statusCodes";
import { toaster } from "../../common/Toaster";

const ActionCell = (props) => {
  const dispatch = useDispatch();
  const { specId } = useParams();

  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
    handleClose();
  };

  const handleCloseDelete = () => {
    setDeleteOpen(false);
  };

  const handleDelete = () => {
    setDeleteLoading(true);
    deleteSpecKpi({ id: props?.data?.id, specId: specId })
      .then((response) => {
        setDeleteLoading(false);
        if (response.status !== HTTP_OK) {
          throw new Error("Error while deleting KPI");
        }

        toaster({ type: "success", message: "KPI deleted successfully" });

        dispatch(updateKpiBi(response?.data));

        handleCloseDelete();
      })
      .catch((error) => {
        setDeleteLoading(false);
        toaster({ type: "error", message: "Error while deleting KPI" });
      });
  };

  return (
    <>
      <Stack
        width={1}
        height={1}
        justifyContent={"center"}
        alignItems={"center"}>
        <SquareIconButton onClick={handleClick}>
          <MoreHorizRounded />
        </SquareIconButton>
      </Stack>
      <CustomPopover open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem
          Icon={<BorderColorOutlined fontSize='small' />}
          itemText={"Edit"}
          itemProps={{ height: "15px" }}
          onClick={() => {
            handleClose();
            dispatch(
              setIsKpiBiModalOpen({
                kpiBiModalObj: {
                  type: "EDIT",
                  data: props?.data,
                },
              })
            );
          }}
        />
        <MenuItem
          Icon={<DeleteOutline fontSize='small' />}
          itemText={"Delete"}
          itemProps={{ height: "15px" }}
          onClick={handleDeleteOpen}
        />
      </CustomPopover>
      <DeleteConfirmationModal
        message={"Are you sure you want to delete this KPI?"}
        isOpen={deleteOpen}
        handleClose={handleCloseDelete}
        handleDelete={handleDelete}
        loading={deleteLoading}
      />
    </>
  );
};

export default ActionCell;
