import _ from "lodash";

export const getStatusesSettingsRows = (statuses) => {
  return statuses?.map((status) => {
    return {
      id: status?.id,
      name: status?.name,
      tag: status?.tag,
    };
  });
};
