import { getUser } from "../../services";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toaster } from "../../common/Toaster";
import UserEditForm from "../Profile/UserEditForm";
import { Box, CircularProgress } from "@mui/material";
import { HTTP_OK } from "../../constants/statusCodes";
import { defaultMessage } from "../../constants/messages";

const UserEdit = () => {
  const { userId } = useParams();
  const [isLoaded, handleLoaded] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (!isLoaded) {
      getUser({ userId: userId })
        .then((r) => {
          handleLoaded(true);

          if (r?.status !== HTTP_OK) {
            throw new Error(defaultMessage);
          }

          const user = r.data.user;

          const formattedUserData = {
            id: user?.id,
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            department: user?.department,
            companyName: user?.company?.name || "",
            position: user?.jobTitle,
            role: user?.role || "",
            superAdmin: user?.superAdmin,
          };

          setUserData(formattedUserData);
        })
        .catch((e) => {
          toaster({ type: "error", message: e });
        });
    }
  }, [isLoaded, userId]);

  return isLoaded ? (
    <Box
      sx={{
        height: 1,
        width: 1,
      }}>
      <UserEditForm userData={userData} />
    </Box>
  ) : (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      sx={{ height: 1, width: 1 }}>
      <CircularProgress color='primary' size={30} />
    </Box>
  );
};

export default UserEdit;
