import { useEffect, useState, useRef } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  COMMENT_STATUS,
  COMMENT_STATUS_LABEL,
  PERMISSIONS,
  ROLES,
} from "../../constants";
import { checkAvailable } from "./utils";
import { CommentCard } from "./CommentCard/CommentCard";
import PromptTagField from "../../common/PromptTagField";
import {
  addComment,
  getAutocompleteUsersComments,
  getBottomComments,
  updComment,
} from "../../services";
import { toaster } from "../../common/Toaster";
import { HTTP_OK } from "../../constants/statusCodes";
import { defaultMessage } from "../../constants/messages";
import { CommentsTextBox } from "./style";
import LoadingButton from "../../common/LoadingButton";

export const Comments = ({ type = "at_the_bottom" }) => {
  const scrollableBlockRef = useRef(null);
  const { user } = useSelector((state) => state.userAuth);
  const { specId } = useParams();
  const { specData } = useSelector((state) => state.spec);
  const [searchParams, setSearchParams] = useSearchParams();
  const commentId = searchParams.get("commentId");
  const message = searchParams.get("message");

  const [filter, setFilter] = useState("All");
  const [commentBody, setCommentBody] = useState("");
  const [plainCommentBody, setPlainCommentBody] = useState("");
  const [mentionedUsers, setMentionedUsers] = useState([]);
  const [comments, setComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addCommentLoading, setAddCommentLoading] = useState(false);

  // Check if user can interact with comments
  const canInteract = checkAvailable(user, specData);

  const handleChangeFilter = (event) => setFilter(event.target.value);

  const handleClear = () => {
    setCommentBody("");
    setPlainCommentBody("");
    setMentionedUsers([]);
  };

  const handleCommentChange = (event, newValue, plainText, mentions) => {
    const uniqueMentions = mentions.filter(
      (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
    );
    setCommentBody(newValue);
    setPlainCommentBody(plainText);
    setMentionedUsers(uniqueMentions);
  };

  const handleAddComment = () => {
    // creating new comment on first level

    const payload = {
      comment: {
        body: commentBody,
        plain_body: plainCommentBody,
        mentions: mentionedUsers,
      },
    };
    if (commentBody?.length) {
      setAddCommentLoading(true);
      addComment({ specId: specId, payload: payload })
        .then((r) => {
          setAddCommentLoading(false);
          if (r?.status !== HTTP_OK) {
            throw new Error(defaultMessage);
          }
          setComments([...comments, r.data.comment]);
          handleClear();
          setTimeout(() => {
            scrollToBottom();
          }, [100]);
        })
        .catch((e) => {
          setAddCommentLoading(false);
          toaster({ type: "error", message: e });
        });
    }
  };

  const onReply = ({ comment, commentId }) => {
    // creating new comment on second level (child comment)
    setComments((prevData) => {
      return prevData.map((item) => {
        if (item.id === commentId) {
          return {
            ...item,
            children: [...item.children, comment],
          };
        }
        return item;
      });
    });
  };

  const onUpdate = (id, status) => {
    const payload = { comment: { status } };
    // update comments status
    updComment({ commentId: id, payload: payload, specId: specId }).then(
      (r) => {
        // set updated comment to local state
        const updatedComments = comments.map((comment) =>
          comment.id === id ? r.data.comment : comment
        );
        setComments(updatedComments);
      }
    );
  };

  const onUpdateComment = ({ comment, isParent, parentId }) => {
    if (comment?.id) {
      if (isParent) {
        setComments((prevArray) =>
          prevArray.map((obj) => (obj.id === comment?.id ? comment : obj))
        );
      } else {
        setComments((prevComments) => {
          return prevComments.map((obj) => {
            if (obj.id === parentId) {
              return {
                ...obj,
                children: obj.children.map((child) => {
                  if (child.id === comment?.id) {
                    return comment;
                  }
                  return child;
                }),
              };
            }
            return obj;
          });
        });
      }
    }
  };

  const handleUpdateCommentsOnDelete = ({ id, isParent, parentId }) => {
    if (id) {
      if (isParent) {
        setComments((prevArray) => prevArray.filter((obj) => obj.id !== id));
      } else {
        setComments((prevComments) => {
          return prevComments.map((comment) => {
            if (comment.id === parentId) {
              return {
                ...comment,
                children: comment.children.filter((child) => child.id !== id),
              };
            }
            return comment;
          });
        });
      }
    }
  };

  const fetchUsers = (query, callback) => {
    getAutocompleteUsersComments({ query: query, specId: specId })
      .then((res) => {
        // Transform the users to what react-mentions expects
        return res?.data?.users?.map((user) => ({
          display: `${user?.firstName} ${user?.lastName}`,
          id: user?.id,
        }));
      })
      .then(callback);
  };

  const scrollToBottom = () => {
    if (scrollableBlockRef?.current) {
      scrollableBlockRef.current.scrollTo({
        top: scrollableBlockRef?.current?.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    getBottomComments({ comment_type: type, specId: specId })
      .then((r) => {
        setLoading(false);
        if (r?.status !== HTTP_OK) {
          throw new Error(defaultMessage);
        }
        setComments(r.data.comments);
      })
      .catch((e) => {
        setLoading(false);
        toaster({ type: "error", message: e });
      });
    handleClear();
  }, [type, specId]);

  useEffect(() => {
    if (commentId && !loading) {
      let element = document.getElementById(commentId);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          inline: "start",
          block: "start",
        });

        if (message === "comment_resolved") {
          toaster({ type: "success", message: "Comment Resolved." });
        }

        searchParams.delete("commentId");
        searchParams.delete("message");
        setSearchParams(searchParams);
      }
    }
  }, [commentId, loading]);

  return (
    <Box
      sx={{
        height: "calc(100vh - 312px)",
      }}>
      {!loading ? (
        comments.length === 0 ? (
          <Typography variant='spec_body'>No comments yet!</Typography>
        ) : (
          <>
            <Stack
              spacing={2}
              justifyContent='space-between'
              direction='row'
              alignItems={"center"}
              mb={2}>
              <Stack spacing={2} direction='row' alignItems={"center"}>
                <Typography variant='spec_body'>
                  {
                    comments.filter(
                      (item) => item.status === COMMENT_STATUS.OPEN
                    ).length
                  }{" "}
                  Open
                </Typography>
                <Divider orientation='vertical' flexItem />
                <Typography variant='spec_body' sx={{ color: "#8C8C8C" }}>
                  {
                    comments.filter(
                      (item) => item.status === COMMENT_STATUS.RESOLVED
                    ).length
                  }{" "}
                  Resolved
                </Typography>
              </Stack>
              <FormControl
                sx={{ m: 1, minWidth: 50, justifySelf: "flex-end" }}
                size='small'>
                <Select
                  value={filter}
                  onChange={handleChangeFilter}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ div: { padding: "1px 10px" } }}
                  renderValue={() => (
                    <Typography variant='spec_body'>
                      {filter === "All" ? "All" : COMMENT_STATUS_LABEL[filter]}
                    </Typography>
                  )}>
                  <MenuItem value='All'>All</MenuItem>
                  <MenuItem value={COMMENT_STATUS.OPEN}>
                    {COMMENT_STATUS_LABEL[COMMENT_STATUS.OPEN]}
                  </MenuItem>
                  <MenuItem value={COMMENT_STATUS.RESOLVED}>
                    {COMMENT_STATUS_LABEL[COMMENT_STATUS.RESOLVED]}
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack
              ref={scrollableBlockRef}
              spacing={2}
              direction='column'
              alignItems='center'
              sx={{
                overflowY: "auto",
                height: `calc(100vh - 358px)`,
                width: "100%",
                mb: 1,
              }}>
              {comments
                .filter((item) => filter === item.status || filter === "All")
                .map((comment) => (
                  <CommentCard
                    id={comment?.id}
                    key={comment?.id}
                    comment={comment}
                    activeId={activeComment}
                    onActive={setActiveComment}
                    onReply={onReply}
                    onUpdate={onUpdate}
                    onUpdateComment={onUpdateComment}
                    handleUpdateCommentsOnDelete={handleUpdateCommentsOnDelete}
                  />
                ))}
            </Stack>
          </>
        )
      ) : (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={{ minHeight: "300px" }}>
          <CircularProgress />
        </Box>
      )}
      {canInteract && (
        <Box
          display='flex'
          position='fixed'
          bottom={0}
          width='350px'
          justifyContent='center'>
          <Stack
            direction='row'
            spacing={2}
            alignItems='flex-start'
            sx={{ maxWidth: "800px", width: "100%" }}>
            <Box sx={{ width: "100%" }}>
              <CommentsTextBox>
                <PromptTagField
                  value={commentBody}
                  handleChange={handleCommentChange}
                  placeholder='Add your comment here.  You can also tag users by prepending @ in front of their name.'
                  data={fetchUsers}
                  singleLine={false}
                  trigger='@'
                />
              </CommentsTextBox>
              <Stack
                mt={1}
                mb={1}
                spacing={1}
                direction='row'
                justifyContent='flex-end'
                alignItems='baseline'>
                <Button
                  sx={{ height: "32px" }}
                  variant='outlined'
                  color='gray'
                  onClick={handleClear}
                  disabled={addCommentLoading}>
                  Cancel
                </Button>
                <LoadingButton
                  sx={{ height: "32px" }}
                  variant='contained'
                  color='primary'
                  onClick={handleAddComment}
                  loading={addCommentLoading}
                  disabled={addCommentLoading}>
                  Add
                </LoadingButton>
              </Stack>
            </Box>
          </Stack>
        </Box>
      )}
    </Box>
  );
};
