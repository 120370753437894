import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { NoDataFound } from "../../assets";
import LoadingButton from "../../common/LoadingButton";
import { AddRounded } from "@mui/icons-material";

const NoRowsOverlayCustomComponentSpecListing = (props) => {
  return (
    <Stack>
      <Box>
        {props?.isSpecListingAndEmpty ? (
          <>
            {props?.canAddSpec && (
              <LoadingButton
                startIcon={<AddRounded />}
                sx={{ height: "30px", mb: 2 }}
                loading={props?.creatSpecLoading}
                onClick={() => props?.handleCreateSpec()}>
                Add New Spec
              </LoadingButton>
            )}
          </>
        ) : (
          <NoDataFound
            style={{
              height: "220px",
              width: "220px",
            }}
          />
        )}
      </Box>
      <Typography variant='spec_body'>{props?.noRowsMessageFunc()}</Typography>
    </Stack>
  );
};

export default NoRowsOverlayCustomComponentSpecListing;
