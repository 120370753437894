import { Button } from "@mui/material";
import { styled as MuiStyled } from "@mui/material/styles";

export const StyledProfileMenuButton = MuiStyled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  columnGap: theme.spacing(1),
  height: "45px",
  ":hover": {
    backgroundColor: "transparent",
  },
}));
