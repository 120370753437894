import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ToastContainer } from "react-toastify";
import { Suspense, useEffect } from "react";
import { CircularProgress, ThemeProvider } from "@mui/material";
import theme from "../constants/theme";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { persistor, store } from "../store";
import { PersistGate } from "redux-persist/integration/react";

const Root = () => {
  useEffect(() => {
    const gtagId = process.env.GTAG_NAME;
    if (gtagId) {
      const script1 = document.createElement("script");
      script1.async = true;
      script1.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
      document.head.appendChild(script1);

      const script2 = document.createElement("script");
      script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${gtagId}');
    `;
      document.head.appendChild(script2);
    }
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={<CircularProgress />} persistor={persistor}>
            <ToastContainer
              hideProgressBar={true}
              newestOnTop={false}
              limit={1}
              pauseOnHover
            />
            <Suspense fallback={<CircularProgress />}>
              <App />
            </Suspense>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default Root;
