import React, { useEffect, useState } from "react";
import BaseModal from "../../../../common/BaseModal";
import { toaster } from "../../../../common/Toaster";
import { HTTP_OK } from "../../../../constants/statusCodes";
import { v4 as uuidv4 } from "uuid";
import {
  addLabel,
  autoCompleteSuggestions,
  generateData,
  getLabel,
  updateLabel,
  updateTab,
} from "../../../../services";
import PaginatedSelectField from "../../../../common/PaginatedSelectField";
import { useDispatch, useSelector } from "react-redux";
import {
  replaceTable,
  unsetAddEditLabel,
} from "../../../../store/slices/mockDataset";
import { defaultMessage } from "../../../../constants/messages";
import { tooltipClasses } from "@mui/material/Tooltip";
import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { components } from "react-select";
import {
  DATA_TYPES,
  STATE_BUSINESS_TERMS,
  TYPE_LABEL,
} from "../../../../constants";
import { PromptTextBox } from "./style";
import LoadingButton from "../../../../common/LoadingButton";
import { useParams } from "react-router-dom";
import PromptTagField from "../../../../common/PromptTagField";
import { InputField } from "../../../../common/InputField";
import CustomSelectField from "../../../../common/CustomSelectField";
import { HelpOutlineRounded } from "@mui/icons-material";
import { convertArrayToString } from "../../../../utils/commonUtility";
import { type } from "@testing-library/user-event/dist/type";
import DataSampleComponent from "../../../../common/DataSampleComponent";
import _ from "lodash";
import TermDynamicFields from "../../../AddEditBusinessTerm/TermDynamicFields";
const AddEditLabel = (props) => {
  const dispatch = useDispatch();
  const { specId } = useParams();

  const {
    addEditLabelOpen,
    addEditLabelType,
    labelId,
    currentSelectedTab,
    specMockdataTabs,
  } = useSelector((state) => state.mockDataset);

  const { mappingColumns } = useSelector((state) => state?.businessTerms);

  const [labelData, setLabelData] = useState(null);

  const [selectedOption, setSelectedOption] = useState(null);
  const [getLabelLoading, setGetLabelLoading] = useState(false);

  const [labelName, setLabelName] = useState("");
  const [description, setDescription] = useState("");
  const [dataType, setDataType] = useState({ value: "text", label: "Text" });
  const [dataset, setDataset] = useState([]);
  const [genDataLoading, setGenDataLoading] = useState(false);
  const [addEditLoading, setAddEditLoading] = useState(false);
  const [promptBody, setPromptBody] = useState("");
  const [plainPrompt, setPlainPrompt] = useState("");
  const [mentionedLabels, setMentionedLabels] = useState([]);

  // State to store the selected values for each custom mapping column
  const [formData, setFormData] = useState({});

  const handleClose = () => {
    setSelectedOption(null);
    setLabelData(null);
    setLabelName("");
    setDataType({ value: "text", label: "Text" });
    setDataset([]);
    setPromptBody("");
    setPlainPrompt("");
    setMentionedLabels([]);
    setFormData({});

    dispatch(
      unsetAddEditLabel({
        addEditLabelOpen: false,
        addEditLabelType: "ADD",
        labelId: null,
      })
    );
  };

  const handlePromptChange = (event, newValue, plainText, mentions) => {
    const uniqueMentions = mentions.filter(
      (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
    );
    setPromptBody(newValue);
    setPlainPrompt(plainText);
    setMentionedLabels(uniqueMentions);
  };

  const loadApprovedLabels = async (
    searchQuery = "",
    loadedOptions,
    { page }
  ) => {
    let labelOptions = [];
    try {
      const response = await autoCompleteSuggestions({
        query: searchQuery,
        specId: specId,
        state: `${STATE_BUSINESS_TERMS.EXISTS},${STATE_BUSINESS_TERMS.PROPOSED}`,
      });

      if (response.status === HTTP_OK) {
        const modifiedResults = response?.data?.businessTerms?.map((label) => ({
          value: label.id,
          label: label?.name,
          labelData: label,
        }));

        const table = specMockdataTabs?.find(
          (tab) => tab?.id === currentSelectedTab
        )?.table;

        // Array of columnIds
        const columnIds = table?.columns?.map((column) => column.columnId);

        const filteredResults = modifiedResults?.filter(
          (option) =>
            option?.labelData?.datasetColumnId &&
            !columnIds.includes(option?.labelData?.datasetColumnId)
        );

        const groupedOptions = [
          {
            label: "Approved Terms",
            options: filteredResults?.filter(
              (result) => result?.labelData?.state === "exists"
            ),
          },
          {
            label: "Proposed Terms",
            options: filteredResults?.filter(
              (result) => result?.labelData?.state === "proposed"
            ),
          },
        ];

        labelOptions = groupedOptions;
      }
      return {
        hasMore: false,
        options: labelOptions,
      };
    } catch (error) {
      toaster({
        type: "error",
        message: "Error while fetching approved labels",
      });
      return {
        options: labelOptions,
      };
    }
  };

  useEffect(() => {
    const getLabelData = async () => {
      setGetLabelLoading(true);
      try {
        const response = await getLabel({ labelId: labelId });

        setGetLabelLoading(false);

        if (response?.status !== HTTP_OK) {
          throw new Error(defaultMessage);
        }

        if (
          response?.data?.businessTerm?.state === "exists" &&
          addEditLabelType === "EDIT"
        ) {
          setSelectedOption({
            value: response?.data?.businessTerm?.id,
            label: response?.data?.businessTerm?.name,
            labelData: response?.data?.businessTerm,
          });
        }

        setLabelData(response?.data?.businessTerm);
      } catch (error) {
        setGetLabelLoading(false);
        toaster({
          type: "error",
          message: "Error while fetching label details!",
        });
      }
    };

    if (labelId && addEditLabelType === "EDIT") {
      getLabelData();
    }
  }, [labelId]);

  useEffect(() => {
    if (addEditLabelType === "EDIT") {
      setLabelName(labelData?.name);
      setDescription(labelData?.description);
      setDataType(
        DATA_TYPES?.filter((type) => type?.value === labelData?.dataType)?.[0]
      );
      setLabelName(labelData?.name);

      setPromptBody(labelData?.prompt ?? "");
      setPlainPrompt(labelData?.plainPrompt ?? "");
      setMentionedLabels(
        _.isEmpty(labelData?.mentions) ? [] : labelData?.mentions
      );
      setDataset(_.isEmpty(labelData?.dataset) ? [] : labelData?.dataset);
    }
  }, [labelData]);

  // Function to handle option selection
  const handleSelectChange = (newSelectedOption) => {
    setSelectedOption(newSelectedOption);
  };

  const Option = (props) => {
    return (
      <>
        <components.Option {...props}>
          <Stack direction={"row"} alignItems={"center"} columnGap={1}>
            <Typography
              variant='spec_body'
              sx={{
                wordBreak: "break-word",
              }}>
              {props?.label}
            </Typography>
            {props?.data?.labelData?.dataType && (
              <Typography
                variant='spec_caption'
                color={"gray.main"}
                sx={{
                  wordBreak: "break-word",
                }}>{`(${
                TYPE_LABEL[props?.data?.labelData?.dataType]
              })`}</Typography>
            )}
          </Stack>
        </components.Option>
      </>
    );
  };

  // const DataSampleComponent = ({ data }) => {
  //   return (
  //     data?.length > 0 && (
  //       <Stack rowGap={0.4}>
  //         <Typography
  //           variant='spec_inputLabel'
  //           sx={{
  //             color: "secondary.black",
  //           }}>
  //           Data Sample:
  //         </Typography>
  //         <Stack
  //           direction={"row"}
  //           flexWrap={"wrap"}
  //           sx={{ whiteSpace: "pre-line" }}>
  //           <Typography variant='spec_body' color={"gray.main"}>
  //             {convertArrayToString(data)}
  //           </Typography>
  //         </Stack>
  //       </Stack>
  //     )
  //   );
  // };

  const fetchLabels = (query, callback) => {
    autoCompleteSuggestions({ query: query, tabId: currentSelectedTab })
      .then((res) => {
        // Transform the users to what react-mentions expects
        return res?.data?.businessTerms?.map((term) => ({
          display: `${term.name}`,
          id: term?.id,
        }));
      })
      .then(callback);
  };

  const handleGenarateData = async () => {
    const payload = {
      data_type: dataType?.value,
      primary_input: labelName,
      secondary_input: plainPrompt,
      mentions: mentionedLabels,
      prompt: promptBody,
    };

    setGenDataLoading(true);

    try {
      const response = await generateData({ payload });

      setGenDataLoading(false);
      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }

      setDataset(response?.data?.generatedData);
    } catch (error) {
      setGenDataLoading(false);
      toaster({
        type: "error",
        message: "Error while generating mock dataset!",
      });
    }
  };

  const dataTypeOptions = DATA_TYPES.filter(
    (type) => type?.value !== "systemDefault"
  );

  const handleOnCreateOption = (val) => {
    const newLabelName = val?.trim();
    if (!newLabelName > 0) {
      return;
    }
    const newOption = {
      value: newLabelName,
      label: newLabelName,
      labelData: {
        name: newLabelName,
      },
      __isNew__: true,
    };
    handleSelectChange(newOption);
    setLabelName(newLabelName);
  };

  const isLabelValid = labelName?.trim()?.length > 0;

  const getUpdatedColumnsPayload = () => {
    const columntoReplace = labelData?.datasetColumnId;

    const table = specMockdataTabs?.find(
      (tab) => tab?.id === currentSelectedTab
    )?.table;

    // Array of columnIds
    const columnIds = table?.columns.map((column) => column.columnId);

    // Updating a columnId based on its id
    const updatedColumnIds = columnIds.map((columnId) =>
      columnId === columntoReplace
        ? { columnId: selectedOption?.labelData?.datasetColumnId }
        : { columnId: columnId }
    );

    return {
      spec_mockdata_tab: {
        table: { columns: updatedColumnIds },
      },
    };
  };

  const onSubmit = async () => {
    const addUpdateProposedCommonPayload = {
      business_term: {
        name: labelName?.trim(),
        data_type: dataType?.value,
        formula: "",
        description: description,
        proposedForId: specId,
        state: STATE_BUSINESS_TERMS?.PROPOSED,
        datasetColumnId: uuidv4(),
        dataset: dataset,
        prompt: promptBody ?? "",
        plain_prompt: plainPrompt ?? "",
        mentions: mentionedLabels,
        sample: dataset?.length ? dataset?.[0] : "",
        business_term_fields_attributes: Object.values(formData),
      },
    };

    const payload =
      addEditLabelType === "EDIT"
        ? labelData?.state === "proposed" ||
          labelData?.state === "default" ||
          labelData?.state === "declined"
          ? {
              business_term_id: labelData?.id,
              ...addUpdateProposedCommonPayload,
              // If the label is declined, then update the state to declined only
              ...(labelData?.state === "declined" && {
                business_term: {
                  ...addUpdateProposedCommonPayload?.business_term,
                  state: STATE_BUSINESS_TERMS.DECLINED,
                },
              }),
            }
          : getUpdatedColumnsPayload()
        : selectedOption?.__isNew__ && addEditLabelType === "ADD"
        ? {
            ...addUpdateProposedCommonPayload,
          }
        : {
            business_term_id: selectedOption?.value,
          };

    const addEditFunction =
      addEditLabelType === "EDIT"
        ? labelData?.state === "proposed" ||
          labelData?.state === "default" ||
          labelData?.state === "declined"
          ? updateLabel //Update proposed label
          : updateTab //Update existing label
        : selectedOption?.__isNew__ && addEditLabelType === "ADD"
        ? addLabel //Propose new label
        : addLabel; // Add new label

    const toastMessages =
      addEditLabelType === "EDIT"
        ? {
            success: "Label updated successfully.",
            error: "Error while adding Label!",
          }
        : {
            success: "Label added successfully.",
            error: "Error while adding Label!",
          };

    try {
      setAddEditLoading(true);
      const response = await addEditFunction({
        specId: specId,
        tabId: currentSelectedTab,
        payload: payload,
      });
      setAddEditLoading(false);

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }

      dispatch(
        replaceTable({
          tabId: currentSelectedTab,
          newTable: response?.data?.specMockdataTab?.table,
        })
      );

      handleClose();

      toaster({
        type: "success",
        message: toastMessages?.success,
      });
    } catch (error) {
      setAddEditLoading(false);

      toaster({
        type: "error",
        message: toastMessages?.error,
      });
    }
  };

  return (
    <BaseModal
      dialogWidth={"562px"}
      isOpen={addEditLabelOpen}
      handleClose={handleClose}
      title={
        addEditLabelType === "ADD" ? "Add Business Term" : "Edit Business Term"
      }
      submitDisable={getLabelLoading}
      isLoading={addEditLoading}
      handleSubmit={onSubmit}
      confirmTitle={
        addEditLabelType === "EDIT"
          ? labelData?.state === "proposed" ||
            labelData?.state === "default" ||
            labelData?.state === "declined"
            ? "Update" //Update proposed label
            : "Update" //Update existing label
          : selectedOption?.__isNew__ && addEditLabelType === "ADD"
          ? "Propose" //Propose new label
          : "Add Existing" // Add new label
      }>
      {getLabelLoading ? (
        <Box
          sx={{
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <CircularProgress size={45} />
        </Box>
      ) : (
        <Stack>
          {(selectedOption?.__isNew__ && addEditLabelType === "ADD") ||
          (addEditLabelType === "EDIT" &&
            (labelData?.state === "proposed" ||
              labelData?.state === "default" ||
              labelData?.state === "declined")) ? (
            <Stack rowGap={2}>
              <Stack>
                <InputField
                  value={labelName}
                  isLabel={true}
                  onChange={(e) => {
                    setLabelName(e.target.value);
                  }}
                  label='Term Name'
                  placeholder='Enter term name'
                  error={!isLabelValid}
                  helperText={
                    !isLabelValid ? "Term name is a required field." : ""
                  }
                />
              </Stack>
              <Stack>
                <InputField
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  isLabel={true}
                  label='Description'
                  placeholder='Enter description'
                  multiline={true}
                  rows={2}
                  className={"inputHeightAuto"}
                />
              </Stack>
              <Stack>
                <CustomSelectField
                  value={dataType}
                  isLabel={true}
                  label='Data Type'
                  options={dataTypeOptions}
                  menuPlacement={"top"}
                  placeholder='Select data type'
                  onChange={(selectedOption) => setDataType(selectedOption)}
                  isMulti={false}
                />
              </Stack>

              <Stack rowGap={0.4}>
                <Stack direction={"column"}>
                  <Typography
                    variant='spec_inputLabel'
                    sx={{
                      color: "secondary.black",
                    }}>
                    Mock Dataset (Optional)
                  </Typography>

                  <Stack py={0.5} pl={0.5}>
                    <Typography variant='spec_caption' color={"gray.main"}>
                      1. Enter an AI prompt that will be used to generate the
                      mock data you expect to see.
                    </Typography>
                    <Typography variant='spec_caption' color={"gray.main"}>
                      2. To create a derived label, press #. Click = to enter a
                      calculation.
                    </Typography>
                    <Typography variant='spec_caption' color={"gray.main"}>
                      3. If the prompt for the Mock Dataset is left empty, a
                      column will be generated with empty cells.
                    </Typography>
                  </Stack>
                  {/* <Typography
                    variant='spec_caption'
                    color={"gray.main"}
                    sx={{ mt: 0.5 }}>
                    1. Enter an AI prompt that will be used to generate the mock
                    data you expect to see. <br />
                    2. To create a derived label, press #. Click = to enter a
                    calculation. <br />
                    3. If the prompt for the Mock Dataset is left empty, a
                    column will be generated with empty cells.
                  </Typography> */}

                  {/* <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses?.arrow}`]: {
                            color: (theme) => theme.palette.primary?.main,
                          },
                          [`& .${tooltipClasses?.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.primary?.main,
                            boxShadow: (theme) =>
                              theme?.palette?.shadows?.shadow1,
                            textAlign: "center",
                          },
                        },
                      },
                    }}
                    title={
                     
                    }
                    // title='Enter an AI prompt that will be used to generate the mock data you expect to see.'
                    placement='top'
                    arrow>
                    <HelpOutlineRounded
                      fontSize='small'
                      sx={(theme) => ({
                        color: theme.palette.info.main,
                        cursor: "pointer",
                      })}
                    />
                  </Tooltip> */}
                </Stack>

                <Stack
                  direction={"row"}
                  // columnGap={1}
                  width={1}
                  justifyContent={"space-between"}>
                  <PromptTextBox
                    sx={{
                      width: "75%",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <PromptTagField
                      value={promptBody}
                      handleChange={handlePromptChange}
                      placeholder='Enter a prompt to generate mock data.'
                      data={fetchLabels}
                    />
                  </PromptTextBox>
                  <Box sx={{ width: "20%" }}>
                    <LoadingButton
                      loading={genDataLoading}
                      onClick={handleGenarateData}
                      onMouseDown={(event) => event.preventDefault()}
                      variant='contained'
                      sx={{ width: "100px" }}
                      edge='end'
                      color='primary'>
                      Generate
                    </LoadingButton>
                  </Box>
                </Stack>
              </Stack>

              <DataSampleComponent data={dataset} />

              <Stack rowGap={2}>
                <Typography variant='spec_h3'>Custom Columns</Typography>
                <Stack
                  rowGap={1}
                  columnGap={1}
                  direction={"row"}
                  flexWrap={"wrap"}>
                  <TermDynamicFields
                    term={labelData}
                    mappingColumns={mappingColumns}
                    formData={formData}
                    setFormData={setFormData}
                  />
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Stack rowGap={1}>
              {addEditLabelOpen && (
                <PaginatedSelectField
                  isLabel={true}
                  label='Column Name'
                  placeholder='Select or type colum name'
                  value={selectedOption}
                  onChange={handleSelectChange}
                  isCreatable={addEditLabelType === "ADD" ? true : false}
                  loadOptions={addEditLabelOpen ? loadApprovedLabels : []}
                  defaultOptions={false}
                  debounceTimeout={600}
                  Components={{ Option: Option }}
                  isClearable={false}
                  handleOnCreateOption={handleOnCreateOption}
                  allowCreateWhileLoading={true}
                />
              )}
              {selectedOption && selectedOption?.labelData && (
                <Stack rowGap={1}>
                  <Stack direction={"row"}>
                    <Typography
                      variant='spec_inputLabel'
                      color={"secondary.black"}>
                      Data Type:
                    </Typography>
                    <Typography variant='spec_body'>
                      {TYPE_LABEL?.[selectedOption?.labelData?.dataType]}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"}>
                    <Typography
                      variant='spec_inputLabel'
                      color={"secondary.black"}>
                      Description:
                    </Typography>
                    <Typography variant='spec_body'>
                      {selectedOption?.labelData?.description}
                    </Typography>
                  </Stack>
                  <Stack direction={"row"}>
                    <Typography
                      variant='spec_inputLabel'
                      color={"secondary.black"}>
                      Category:
                    </Typography>
                    <Typography variant='spec_body'>
                      {selectedOption?.labelData?.businessTermCategory?.name}
                    </Typography>
                  </Stack>
                  <DataSampleComponent
                    data={selectedOption?.labelData?.dataset}
                  />
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      )}
    </BaseModal>
  );
};

export default AddEditLabel;
