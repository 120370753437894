import React, { useState, useEffect } from "react";
import Select from "react-select";
import { InputField } from "../../common/InputField";
import CustomSelectField from "../../common/CustomSelectField";
import { Box } from "@mui/material";
import _ from "lodash";

const TermDynamicFields = ({ mappingColumns, term, formData, setFormData }) => {
  // Set initial form data based on term object

  // Filter out only custom columns
  const customColumns = mappingColumns?.filter(
    (column) => column?.kind === "custom"
  );

  // Function to get custom field ID
  const getCustomFieldId = (columnId) => {
    const customField = term?.businessTermFields.find(
      (customColumn) => customColumn.mappingColumnId === columnId
    );
    return customField ? customField.id : null;
  };

  useEffect(() => {
    if (term) {
      const initialFormData = {};
      term.businessTermFields.forEach((field) => {
        initialFormData[field.mappingColumnId] = {
          id: field?.id,
          value: field.value ?? "",
          mapping_column_id: field.mappingColumnId,
          mapping_column_value_id: field.mappingColumnValueId || null,
        };
      });
      setFormData(initialFormData);
    } else {
      // If term doesn't exist, set default values
      const initialFormData = {};
      customColumns?.forEach((column) => {
        if (column.selectField) {
          initialFormData[column.id] = {
            id: null,
            value: "",
            mapping_column_id: column.id,
            mapping_column_value_id: null,
            // mappingColumnValue: "",
          };
        } else {
          initialFormData[column.id] = {
            id: null,
            value: "",
            mapping_column_id: column.id,
            mapping_column_value_id: null,
            // mappingColumnValue: "",
          };
        }
      });
      setFormData(initialFormData);
    }
  }, [term, mappingColumns]);

  //   const handleChange = (id, value) => {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       [id]: value,
  //     }));
  //   };

  const handleChange = (id, value, isSelectField) => {
    const customFieldId = getCustomFieldId(id);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: {
        id: customFieldId, // Use existing custom field ID if available, otherwise null
        value: isSelectField || _.isObject(value) ? "" : value,
        mapping_column_id: id,
        mapping_column_value_id: value.value ? value.value : null,
      },
    }));
  };

  // Get option object based on stored value
  const getOptionObject = (columnId, value) => {
    const column = mappingColumns.find((column) => column.id === columnId);

    if (column) {
      const options = column.values.map((val) => ({
        value: val.id,
        label: val.name,
      }));
      return (
        options.find(
          (option) => option.value === value?.mapping_column_value_id
        ) || null
      );
    }
    return null;
  };

  return (
    <>
      {customColumns?.map((column) => (
        <Box key={column.id} sx={{ width: "49%" }}>
          {column?.selectField ? (
            <CustomSelectField
              value={getOptionObject(column.id, formData[column.id])}
              onChange={(selectedOption) =>
                handleChange(
                  column.id,
                  selectedOption ? selectedOption : null,
                  column?.selectField
                )
              }
              options={column.values.map((val) => ({
                value: val.id,
                label: val.name,
              }))}
              isLabel={true}
              label={column?.name}
              isMulti={false}
              placeholder='Select custom column value'
            />
          ) : (
            <InputField
              isLabel={true}
              label={column?.name}
              value={formData[column.id]?.value || ""}
              onChange={(e) =>
                handleChange(column.id, e.target.value, column?.selectField)
              }
              placeholder='Add text to custom column'
            />
          )}
        </Box>
      ))}
    </>
  );
};

export default TermDynamicFields;
