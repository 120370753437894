import { Stack, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { LoginCard, LoginContainer, LoginContent } from "./style";
import { InputField } from "../../common/InputField";
import { Field, Form, Formik } from "formik";
import { LoginValidationSchema } from "../../utils/validationSchemas";
import GoogleButton from "./GoogleLoginButton";
import MicrosoftButton from "./MicrosoftLoginButton";
import LoadingButton from "../../common/LoadingButton";
import { Link } from "react-router-dom";
import { ROUTE_PATH } from "../../router/routes";
import { authenticationUrl } from "../../utils/urls";
import axios from "axios";
import { HTTP_CREATED } from "../../constants/statusCodes";
import { setCookie } from "../../utils/commonUtility";
import { setUserAndCompanies } from "../../store/slices/userAuth";
import { useDispatch } from "react-redux";
import { toaster } from "../../common/Toaster";
import { getCurrentCompany } from "./loginUtil";
import { defaultMessage } from "../../constants/messages";

const Login = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  // const location = useLocation();
  const [loginLoading, setLoginLoading] = useState(false);
  // const [socialLoginLoading, setSocialLoginLoading] = useState(false);
  const initialValues = {
    email: "",
    password: "",
  };
  const handleChange = (field, value) => {
    field.onChange({
      target: {
        name: field.name,
        value: value,
      },
    });
  };
  const onSubmit = (values) => {
    const payload = {
      email: values.email.toLowerCase(),
      password: values.password,
    };
    setLoginLoading(true);
    axios
      .post(authenticationUrl, payload)
      .then((response) => {
        setLoginLoading(false);
        if (response.status !== HTTP_CREATED) {
          throw new Error(defaultMessage);
        }

        toaster({
          type: "success",
          message: response?.data?.toaster?.data?.message,
        });

        const currentURL = window.location.href;
        const currentCompany = getCurrentCompany(
          response.data?.user,
          currentURL
        );
        response.data.currentCompany = currentCompany;

        setCookie("token", response?.data?.token);
        setCookie("subdomain", currentCompany?.subdomain);
        dispatch(setUserAndCompanies(response?.data));
      })
      .catch((error) => {
        setLoginLoading(false);
        toaster({
          type: "error",
          message: error?.response?.data?.toaster.data?.message,
        });
      });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  // useEffect(() => {
  //   if (location.pathname.includes(ROUTE_PATH.OAUTHCALLBACK)) {
  //     setSocialLoginLoading(true);
  //     console.log("pathname", location.pathname);
  //     console.log("location", window.location.href);
  //     const urlParams = new URLSearchParams(window.location.search);
  //     console.log("urlParams", urlParams);
  //     const token = urlParams.get("token");
  //     console.log("token", token);
  //     const requestConfig = {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     };
  //     axios
  //       .post(checkOAuthTokenUrl, {}, requestConfig)
  //       .then((response) => {
  //         setSocialLoginLoading(false);
  //         if (response.status !== HTTP_CREATED) {
  //           throw new Error(defaultMessage);
  //         }
  //         toaster({
  //           type: "success",
  //           message: response?.data?.toaster?.data?.message,
  //         });
  //         const currentURL = window.location.href;
  //         const currentCompany = getCurrentCompany(
  //           response.data?.user,
  //           currentURL
  //         );
  //         response.data.currentCompany = currentCompany;

  //         setCookie("token", token);
  //         setCookie("subdomain", currentCompany?.subdomain);
  //         dispatch(setUserAndCompanies(response?.data));
  //       })
  //       .catch((error) => {
  //         setSocialLoginLoading(false);
  //         toaster({
  //           type: "error",
  //           message: error?.response?.data?.toaster.data?.message,
  //         });
  //       });
  //   }
  //   // eslint-disable-next-line
  // }, []);

  return (
    <LoginContainer>
      {/* {Cookies.get("token") && Cookies.get("subdomain") ? (
        <Stack
          sx={{
            height: 1,
            width: 1,
            justifyContent: "center",
            alignItems: "center",
          }}>
          <CircularProgress size={60} />
        </Stack>
      ) : ( */}
      <LoginCard>
        <LoginContent>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={LoginValidationSchema}
            validateOnChange={true}
            validateOnBlur={true}
            validateOnMount={true}
            onSubmit={onSubmit}>
            {({ touched, errors, values, isValid }) => (
              <Form>
                <Typography variant='spec_h1'>Login</Typography>
                <Stack sx={{ my: "24px" }} rowGap={2}>
                  <Field name='email'>
                    {({ field, form }) => (
                      <InputField
                        name='email'
                        placeholder='Enter email address'
                        isLabel={true}
                        label='Email'
                        variant={"outlined"}
                        value={field.value}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSubmitClick();
                          }
                        }}
                        onChange={({ currentTarget: input }) =>
                          handleChange(field, input.value)
                        }
                        onBlur={() => {
                          form.setTouched({
                            ...form.touched,
                            email: true,
                          });
                        }}
                        error={form.touched.email && Boolean(form.errors.email)}
                        helperText={form.touched.email && form.errors.email}
                      />
                    )}
                  </Field>
                  <Field name='password'>
                    {({ field, form }) => (
                      <InputField
                        type='password'
                        name='password'
                        placeholder='Enter your password'
                        isLabel={true}
                        label='Password'
                        variant='outlined'
                        value={field.value}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSubmitClick();
                          }
                        }}
                        onChange={({ currentTarget: input }) =>
                          handleChange(field, input.value)
                        }
                        onBlur={() => {
                          form.setTouched({
                            ...form.touched,
                            password: true,
                          });
                        }}
                        error={
                          form.touched.password && Boolean(form.errors.password)
                        }
                        helperText={
                          form.touched.password && form.errors.password
                        }
                      />
                    )}
                  </Field>
                </Stack>
                <LoadingButton
                  variant='contained'
                  fullWidth
                  sx={{ mt: 1 }}
                  loading={loginLoading}
                  disabled={loginLoading}
                  type='submit'>
                  Login
                </LoadingButton>
                <Stack rowGap={2} alignItems={"center"} width={1} mt={2}>
                  <Typography
                    variant='spec_body'
                    component={Link}
                    to={ROUTE_PATH.FORGOT_PASSWORD}
                    sx={(theme) => ({
                      cursor: "pointer",
                      ":hover": {
                        color: theme.palette.info.main,
                      },
                    })}>
                    Forgot Password?
                  </Typography>
                  <Typography variant='spec_body'>
                    SSO available for those with a Spectio account
                  </Typography>
                </Stack>
                <Stack
                  sx={(theme) => ({
                    mt: 2,
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                  })}>
                  <GoogleButton />
                  <MicrosoftButton />
                </Stack>
              </Form>
            )}
          </Formik>
        </LoginContent>
      </LoginCard>
      {/* )} */}
    </LoginContainer>
  );
};

export default Login;
