import React from "react";
import { Skeleton, Stack } from "@mui/material";

const IndustriesMenuSkeleton = () => {
  return (
    <Stack>
      <Skeleton height={40} />
      <Skeleton height={40} />
      <Skeleton height={40} />
    </Stack>
  );
};

export default IndustriesMenuSkeleton;
