import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { ROUTE_PATH } from "../../router/routes";
import { Error404Image } from "../../assets";
import { Error404Container } from "./style";

// ==============================|| ERROR 404 - MAIN ||============================== //

const Error404 = () => {
  return (
    <>
      <Error404Container>
        <Box
          component={"img"}
          src={Error404Image}
          alt='404 Error'
          sx={{
            height: { xs: 100, sm: 300 },
            width: { xs: 200, sm: 590 },
          }}
        />
        <Stack alignItems={"center"} rowGap={3} px={2}>
          <Typography variant='spec_h1'>Page Not Found</Typography>
          <Typography variant='spec_body' textAlign={"center"}>
            The page you are looking was moved, removed, renamed, or might never
            exist!
          </Typography>
          <Button component={Link} to={ROUTE_PATH.SPECS} variant='contained'>
            Back To Home
          </Button>
        </Stack>
      </Error404Container>
    </>
  );
};

export default Error404;
