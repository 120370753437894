import React from "react";

import { Tooltip, Typography } from "@mui/material";

const TooltipTypography = ({
  label = "",
  size = "25",
  variant = "spec_body",
  tooltipVariant = "spec_tooltip",
  displaySx,
}) => {
  return label?.length >= size ? (
    <Tooltip
      title={
        <Typography
          variant={tooltipVariant}
          sx={{
            backgroundColor: "transparent",
            wordBreak: "break-word",
          }}>
          {label}
        </Typography>
      }
      arrow>
      <Typography
        variant={variant}
        color='secondary.black'
        sx={{
          wordBreak: "break-word",
          ...displaySx,
        }}>
        {label?.slice(0, size)}...
      </Typography>
    </Tooltip>
  ) : (
    <Typography
      variant={variant}
      color='secondary.black'
      sx={{
        wordBreak: "break-word",
        ...displaySx,
      }}>
      {label}
    </Typography>
  );
};

export default TooltipTypography;
