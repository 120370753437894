function isNumber(value) {
  return new RegExp("^(?=.*[0-9]).+$").test(value);
}

function isLowercaseChar(value) {
  return new RegExp("^(?=.*[a-z]).+$").test(value);
}

function isUppercaseChar(value) {
  return new RegExp("^(?=.*[A-Z]).+$").test(value);
}

function isSpecialChar(value) {
  return new RegExp(/^(?=.*[!@#$%^&*():;+=|'"/\\]).+$/).test(value);
}

function minLength(value) {
  return value.length > 7;
}

function isValidEmail(email) {
  var pattern = /^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
}

export function isValidURL(url) {
  const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;
  return urlPattern.test(url);
}

export {
  isNumber,
  isLowercaseChar,
  isUppercaseChar,
  isSpecialChar,
  minLength,
  isValidEmail,
};
