import { Field, Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { kpiAndBIValidationSchema } from "../../utils/validationSchemas";
import { InputField } from "../../common/InputField";
import { Stack } from "@mui/material";
import BaseModal from "../../common/BaseModal";
import { useDispatch, useSelector } from "react-redux";
import { resetKpiBiModal, updateKpiBi } from "../../store/slices/userSpec";
import { addSpecKpis, updateSpeckpi } from "../../services";
import { useParams } from "react-router-dom";
import { toaster } from "../../common/Toaster";
import _ from "lodash";
import { HTTP_CREATED, HTTP_OK } from "../../constants/statusCodes";
import { defaultMessage } from "../../constants/messages";

const KPIAndBIAddEditModal = () => {
  const formRef = useRef();
  const { specId } = useParams();
  const dispatch = useDispatch();
  const { isKpiBiModalOpen, kpiBiModalObj } = useSelector(
    (state) => state.spec
  );

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    kpi: kpiBiModalObj?.data?.name || "",
    targetValue: kpiBiModalObj?.data?.targetValue || "",
    businessImpact: kpiBiModalObj?.data?.description || "",
  };

  const onSubmit = (values) => {
    const payload = {
      name: values.kpi,
      target_value: values.targetValue,
      description: values.businessImpact,
    };

    if (kpiBiModalObj?.type === "ADD") {
      // dispatch(updateKpiBi(payload));
      setIsLoading(true);
      addSpecKpis({ specId, payload })
        .then((response) => {
          setIsLoading(false);

          if (
            !(response.status === HTTP_CREATED || response.status === HTTP_OK)
          ) {
            throw new Error(defaultMessage);
          }

          dispatch(updateKpiBi(response?.data));

          toaster({
            type: "success",
            message: "KPI and Business Impact added successfully",
          });
          handleClose();
        })
        .catch((error) => {
          setIsLoading(false);
          toaster({
            type: "error",
            message: "Failed to add KPI and Business Impact",
          });
        });
    }
    if (kpiBiModalObj?.type === "EDIT") {
      setIsLoading(true);
      updateSpeckpi({
        id: kpiBiModalObj?.data?.id,
        payload,
        specId,
      })
        .then((response) => {
          setIsLoading(false);

          if (
            !(response.status === HTTP_CREATED || response.status === HTTP_OK)
          ) {
            throw new Error(defaultMessage);
          }

          dispatch(updateKpiBi(response?.data));

          toaster({
            type: "success",
            message: "KPI and Business Impact updated successfully",
          });
          handleClose();
        })
        .catch((error) => {
          setIsLoading(false);
          toaster({
            type: "error",
            message: "Failed to update KPI and Business Impact",
          });
        });
    }
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleChange = (field, value) => {
    field.onChange({
      target: {
        name: field.name,
        value: value,
      },
    });
  };

  const handleClose = () => {
    dispatch(resetKpiBiModal());
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={kpiAndBIValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
      onSubmit={onSubmit}
      enableReinitialize={true}>
      {({ touched, errors, values, isValid }) => (
        <Form>
          <BaseModal
            dialogWidth={"530px"}
            isOpen={isKpiBiModalOpen}
            handleClose={() => {
              handleClose();
            }}
            title={
              kpiBiModalObj?.type === "EDIT"
                ? "Edit KPI and Business Impact"
                : "Add KPI and Business Impact"
            }
            handleSubmit={handleSubmitClick}
            isLoading={isLoading}
            confirmTitle={kpiBiModalObj?.type === "EDIT" ? "Update" : "Add"}
            submitDisable={
              _.isEqual(initialValues, values) ||
              (errors && Object.keys(errors)?.length !== 0)
            }>
            <Stack rowGap={2}>
              <Field name='kpi'>
                {({ field, form }) => (
                  <InputField
                    name='kpi'
                    placeholder='Add a KPI'
                    isLabel={true}
                    label='KPI'
                    variant={"outlined"}
                    value={field.value}
                    onChange={({ currentTarget: input }) =>
                      handleChange(field, input.value)
                    }
                    onBlur={() => {
                      form.setTouched({
                        ...form.touched,
                        kpi: true,
                      });
                    }}
                    error={form.touched.kpi && Boolean(form.errors.kpi)}
                    helperText={form.touched.kpi && form.errors.kpi}
                  />
                )}
              </Field>
              <Field name='targetValue'>
                {({ field, form }) => (
                  <InputField
                    name='targetValue'
                    placeholder='Enter a numeric value'
                    isLabel={true}
                    label='Target Value'
                    variant={"outlined"}
                    value={field.value}
                    onChange={({ currentTarget: input }) =>
                      handleChange(field, input.value)
                    }
                    onBlur={() => {
                      form.setTouched({
                        ...form.touched,
                        targetValue: true,
                      });
                    }}
                    error={
                      form.touched.targetValue &&
                      Boolean(form.errors.targetValue)
                    }
                    helperText={
                      form.touched.targetValue && form.errors.targetValue
                    }
                  />
                )}
              </Field>
              <Field name='businessImpact'>
                {({ field, form }) => (
                  <InputField
                    name='businessImpact'
                    placeholder='Describe the business impact of enabling this KPI'
                    isLabel={true}
                    label='Business Impact'
                    variant={"outlined"}
                    className={"inputHeightAuto"}
                    multiline={true}
                    rows={4}
                    value={field.value}
                    onChange={({ currentTarget: input }) =>
                      handleChange(field, input.value)
                    }
                    onBlur={() => {
                      form.setTouched({
                        ...form.touched,
                        businessImpact: true,
                      });
                    }}
                    error={
                      form.touched.businessImpact &&
                      Boolean(form.errors.businessImpact)
                    }
                    helperText={
                      form.touched.businessImpact && form.errors.businessImpact
                    }
                  />
                )}
              </Field>
            </Stack>
          </BaseModal>
        </Form>
      )}
    </Formik>
  );
};

export default KPIAndBIAddEditModal;
