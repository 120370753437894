import React, { useEffect, useMemo, useState } from "react";
import {
  BorderColorOutlined,
  CloseOutlined,
  CommentOutlined,
  ContentCopyOutlined,
  HistoryOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import SpecTitle from "../SpecTitle";
import { StyledAppBar, StyledToolbar } from "./style";
import CustomIconButton from "../../common/CustomIconButton";
import StatusChip from "../StatusChip";
import Author from "../Author";
import FunctionDropdown from "../FunctionDropdown";
import IndustryDropdown from "../IndustryDropdown";
import { useSelector } from "react-redux";
import SpecMoreMenu from "../SpecMoreMenu";
import ShareModal from "../ShareModal";
import SideDrawer from "../SideDrawer";
import HistoryDrawer from "../History/HistoryDrawer";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ROUTE_PATH } from "../../router/routes";
import TooltipTypography from "../../common/TooltipTypography";
import StatusCell from "../../pages/SpecsListing/StatusCell";
import { PERMISSIONS, ROLES } from "../../constants";
import { createSpecCopy } from "../../services";
import { HTTP_OK } from "../../constants/statusCodes";
import { defaultMessage } from "../../constants/messages";
import { toaster } from "../../common/Toaster";
import LoadingButton from "../../common/LoadingButton";
import CommentsDrawer from "../Comments/CommentsDrawer";
import PriorityCell from "../../pages/SpecsListing/PriorityCell";

const SpecPreviewAppBar = () => {
  const navigate = useNavigate();
  const { specId } = useParams();
  const { state } = useLocation();
  const { user } = useSelector((state) => state.userAuth);
  const { specData } = useSelector((state) => state.spec);

  const [historyDrawerAnchor, setHistoryDrawerAnchor] = useState(null);
  const [commentsDrawerAnchor, setCommentsDrawerAnchor] = useState(null);
  const [isMakeCopyLoading, setIsMakeCopyLoading] = useState(false);

  const isAdmin = user?.superAdmin || user?.role === PERMISSIONS.ADMIN;
  const activeCompany = user?.company?.status === "active";
  const fromTemplate = state?.fromTemplate;

  const [searchParams] = useSearchParams();
  const commentId = searchParams.get("commentId");

  useEffect(() => {
    if (commentId) {
      setCommentsDrawerAnchor(true);
    }
  }, [commentId]);

  const canEditSpec = useMemo(
    () =>
      isAdmin ||
      Boolean(
        specData.specAccesses?.find(
          (access) =>
            access.user?.id === user.id &&
            [PERMISSIONS.EDITOR, PERMISSIONS.ADMIN].includes(access.permission)
        )
      ),
    [specData, user]
  );

  const hasAccess = useMemo(
    () =>
      !specData.publishedAsTemplate &&
      (isAdmin ||
        Boolean(
          specData.specAccesses?.find((access) => access?.user?.id === user?.id)
        )),
    [specData, user]
  );

  const canDeleteSpec = useMemo(
    () =>
      isAdmin ||
      Boolean(
        specData.specAccesses?.find(
          (access) =>
            access.user?.id === user?.id &&
            [PERMISSIONS.ADMIN].includes(access.permission)
        )
      ),
    [specData, user]
  );

  const handleMakeSpecCopy = async () => {
    setIsMakeCopyLoading(true);
    try {
      const response = await createSpecCopy({
        payload: { template_id: specData?.id },
      });

      setIsMakeCopyLoading(false);
      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }
      const id = response?.data?.specification?.id;
      const newUrl = `${window.location.origin}/specs/spec/${id}/`;
      toaster({
        type: "success",
        message: response?.data?.toaster?.data?.message,
      });
      if (id) {
        window.open(newUrl, "_blank");
      }
    } catch (error) {
      setIsMakeCopyLoading(false);
      toaster({ type: "error", message: defaultMessage });
    }
  };

  return (
    <>
      <StyledAppBar>
        <StyledToolbar>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={1}
            columnGap={2}
            sx={{
              position: "relative",
              overflowX: { xs: "scroll", sm: "scroll" },
              scrollbarWidth: "none",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}>
            <Stack ml={3}>
              <TooltipTypography
                label={specData?.title}
                variant='spec_h2'
                displaySx={{
                  whiteSpace: "nowrap",
                }}
              />
            </Stack>

            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                columnGap: 2,
                minWidth: "100px",
                width: 1,
                overflowX: "scroll",
                scrollbarWidth: "none",
                "::-webkit-scrollbar": {
                  display: "none",
                },
              }}>
              {/* <StatusChip /> */}
              <StatusCell
                value={specData?.specStatus?.name || "Status not defined"}
              />
              <PriorityCell
                value={specData?.priority?.name || "Priority not defined"}
              />
              <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                <Typography variant='spec_caption' color={"gray.main"}>
                  Function:
                </Typography>

                <Stack direction={"row"} alignItems={"center"}>
                  <TooltipTypography
                    label={
                      specData?.specFunctionId ? specData?.specFunction : "-"
                    }
                    size='25'
                    variant='spec_caption'
                    displaySx={{ whiteSpace: "nowrap" }}
                  />
                </Stack>
              </Stack>
              {user?.superAdmin && (
                <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                  <Typography variant='spec_caption' color={"gray.main"}>
                    Industry:
                  </Typography>

                  <Stack direction={"row"} alignItems={"center"}>
                    <TooltipTypography
                      label={
                        specData?.specIndustry?.id
                          ? specData?.specIndustry?.title
                          : "-"
                      }
                      size='25'
                      variant='spec_caption'
                      displaySx={{ whiteSpace: "nowrap" }}
                    />
                  </Stack>
                </Stack>
              )}
              <Author />
            </Stack>

            <Stack flexDirection={"row"} columnGap={1} alignItems={"center"}>
              {!fromTemplate && hasAccess && (
                <>
                  <CustomIconButton
                    tooltipTitle='Comments'
                    Icon={CommentOutlined}
                    onClick={() => setCommentsDrawerAnchor(true)}
                  />
                  <CustomIconButton
                    tooltipTitle='History'
                    Icon={HistoryOutlined}
                    onClick={() => setHistoryDrawerAnchor(true)}
                  />
                  {activeCompany && canEditSpec && (
                    <CustomIconButton
                      onClick={() => navigate(`/specs/spec/${specId}`)}
                      tooltipTitle='Edit'
                      Icon={BorderColorOutlined}
                    />
                  )}
                </>
              )}

              {hasAccess && <ShareModal />}

              {[ROLES.EDITOR, ROLES.ADMIN].includes(user?.role) && (
                <LoadingButton
                  onClick={handleMakeSpecCopy}
                  variant='contained'
                  sx={{
                    height: "30px",
                  }}
                  startIcon={<ContentCopyOutlined />}
                  loading={isMakeCopyLoading}>
                  Make a Copy
                </LoadingButton>
              )}

              {canDeleteSpec && <SpecMoreMenu isPreview={true} />}
            </Stack>
          </Stack>
        </StyledToolbar>
      </StyledAppBar>
      <HistoryDrawer
        open={historyDrawerAnchor}
        onClose={() => setHistoryDrawerAnchor(false)}
      />
      <CommentsDrawer
        open={commentsDrawerAnchor}
        onClose={() => setCommentsDrawerAnchor(false)}
      />
    </>
  );
};

export default SpecPreviewAppBar;
