import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import MainAppBar from "../../components/MainAppBar";
import { useDispatch, useSelector } from "react-redux";
import { AppLayoutWrapper, MainContent } from "./style";
import { checkToken } from "../../store/slices/userAuth";
import LayoutLoader from "../../components/LayoutLoader";
import { persistor } from "../../store";
import storage from "redux-persist/lib/storage";
import { ROUTE_PATH } from "../../router/routes";
import { getAnnouncementsAsync } from "../../store/slices/announcements";

const AppLayout = ({ children }) => {
  const { userLoading } = useSelector((state) => state.userAuth);
  const { announcements } = useSelector((state) => state.announcements);

  const token = Cookies.get("token");
  const subdomain = Cookies.get("subdomain");
  const dispatch = useDispatch();

  useEffect(() => {
    if (token && subdomain) {
      dispatch(checkToken()); //validate & get user details
      dispatch(getAnnouncementsAsync());
    } else {
      persistor.purge();
      storage.removeItem("persist:root");
      localStorage.clear();
      window.location.pathname = ROUTE_PATH.LOGIN;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return userLoading ? (
    <LayoutLoader /> //this show's up on initial get user request
  ) : (
    <AppLayoutWrapper>
      <MainAppBar />
      <MainContent
        sx={{
          pt: announcements?.length > 0 ? "85px" : "45px",
          height:
            announcements?.length > 0
              ? "calc(100vh - 85px)"
              : "calc(100vh - 45px)",
        }}>
        {children}
        <Outlet />
      </MainContent>
    </AppLayoutWrapper>
  );
};

export default AppLayout;
