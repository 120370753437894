import { Chip, alpha } from "@mui/material";
import PropTypes from "prop-types";
import { ROLES } from "../../constants";
import { specPrimary } from "../../constants/theme/colors";

export const ChipRole = ({ role, isSuperAdmin, sx }) => {
  return isSuperAdmin ? (
    <Chip
      size='small'
      variant='filled'
      label='Super admin'
      sx={(theme) => ({
        borderRadius: "5px",
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
        color: theme.palette.primary.main,
        fontFamily: "Poppins",
        fontWeight: 400,
        ...sx,
      })}
    />
  ) : (
    <>
      {role === "owner" && (
        <Chip
          size='small'
          variant='filled'
          label={"Owner"}
          sx={(theme) => ({
            borderRadius: "5px",
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
            color: theme.palette.primary.main,
            fontFamily: "Poppins",
            fontWeight: 500,
            ...sx,
          })}
        />
      )}
      {role === ROLES.ADMIN && (
        <Chip
          size='small'
          variant='filled'
          label={ROLES.ADMIN_LABEL}
          sx={(theme) => ({
            borderRadius: "5px",
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
            color: theme.palette.primary.main,
            fontFamily: "Poppins",
            fontWeight: 500,
            ...sx,
          })}
        />
      )}
      {role === ROLES.EDITOR && (
        <Chip
          size='small'
          variant='filled'
          label={ROLES.EDITOR_LABEL}
          sx={(theme) => ({
            borderRadius: "5px",
            backgroundColor: alpha(theme.palette.info.main, 0.1),
            color: theme.palette.info.main,
            fontFamily: "Poppins",
            fontWeight: 500,
            ...sx,
          })}
        />
      )}
      {role === ROLES.COLLABORATOR && (
        <Chip
          size='small'
          variant='filled'
          label={ROLES.COLLABORATOR_LABEL}
          sx={(theme) => ({
            borderRadius: "5px",
            backgroundColor: alpha(theme.palette.success.main, 0.1),
            color: theme.palette.success.main,
            fontFamily: "Poppins",
            fontWeight: 500,
            ...sx,
          })}
        />
      )}
      {role === ROLES.VIEWER && (
        <Chip
          size='small'
          variant='filled'
          label={ROLES.VIEWER_LABEL}
          sx={{
            ...sx,
          }}
        />
      )}
    </>
  );
};

ChipRole.propTypes = {
  role: PropTypes.string,
  isSuperAdmin: PropTypes.bool,
  others: PropTypes.any,
};
