import { alpha, createTheme } from "@mui/material";

export const specPrimary = {
  50: "#fefde7",
  100: "#fdf8c3",
  200: "#fcf49b",
  300: "#faf073",
  400: "#f8eb53",
  500: "#f5e631",
  600: "#f7d62f",
  700: "#f6bf26",
  800: "#f4a71d",
  900: "#f17e0a",
};

export const specSecondary = {
  50: "#e2f1ff",
  100: "#badcff",
  200: "#8ec7ff",
  300: "#5fb1ff",
  400: "#3ba0ff",
  500: "#188fff",
  600: "#1e81f0",
  700: "#206fdc",
  800: "#205dca",
  900: "#1f3eaa",
};

export const specGray = {
  50: "#fafafa",
  100: "#f5f5f5",
  200: "#eeeeee",
  300: "#e0e0e0",
  400: "#bdbdbd",
  500: "#9e9e9e",
  600: "#757575",
  700: "#616161",
  800: "#424242",
  900: "#212121",
};

export const specPrimaryGray = {
  buttonGray: "#666666",
  borderGray: "#dddddd",
  backgroundGray: "#f7f8fa",
  textGray1: "#262626",
};

export const specError = {
  50: "#fdecef",
  100: "#fbd1d5",
  200: "#eaa0a1",
  300: "#df7d7d",
  400: "#e9625d",
  500: "#ed5546",
  600: "#df4d45",
  700: "#cd443e",
  800: "#bf3e38",
  900: "#b1342c",
};

export const specInfo = {
  50: "#e1f5fe",
  100: "#b3e5fc",
  200: "#81d4fa",
  300: "#4fc3f7",
  400: "#2ab7f6",
  500: "#04aaf4",
  600: "#049ce5",
  700: "#0289d1",
  800: "#0278bd",
  900: "#01589b",
};

export const specSuccess = {
  50: "#ecf8e6",
  100: "#d0eec0",
  200: "#b0e297",
  300: "#8dd66b",
  400: "#71cd47",
  500: "#53c41a",
  600: "#42b411",
  700: "#26a001",
  800: "#008c00",
  900: "#006a00",
};

export const themePalette = createTheme({
  palette: {
    type: "light",
    primary: {
      light: specPrimary[600],
      main: specPrimary[700],
      dark: specPrimary[800],
    },
    secondary: {
      main: specSecondary[500],
      black: "#000000",
      white: "#ffffff",
      textColor: "#262626",
    },
    gray: {
      lighter: specGray[300],
      light: specGray[400],
      main: specGray[500],
      dark: specGray[600],
      buttonGray: specPrimaryGray["buttonGray"],
      backgroundGray: specPrimaryGray["backgroundGray"],
      borderGray: specPrimaryGray["borderGray"],
      textGray1: specPrimaryGray["textGray1"],
      iconColor: specGray[800],
      headerBackground: specGray[50],
    },
    error: {
      light: specError[300],
      main: specError[400],
      dark: specError[500],
    },
    warning: {
      light: "#ff9800",
      main: "#ed6c02",
      dark: "#e65100",
    },
    info: {
      light: specSecondary[400],
      main: specSecondary[500],
      dark: specSecondary[600],
    },
    success: {
      light: specSuccess[500],
      main: specSuccess[800],
      dark: specSuccess[900],
    },
    shadows: {
      shadow1: `0px 2px 8px ${alpha(specGray[900], 0.15)}`,
    },
  },
});
