import { CircularProgress, Stack } from "@mui/material";
import React from "react";

const CustomOverlayLoadingComponent = (props) => {
  return (
    <Stack>
      <CircularProgress size={60} />
    </Stack>
  );
};

export default CustomOverlayLoadingComponent;
