import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  getIndustriesAsync,
  setIndustryAddEditModal,
} from "../../../store/slices/industries";
import { useDispatch, useSelector } from "react-redux";
import { getIndustriesSettingsColumns } from "./columns";
import { InputField } from "../../../common/InputField";
import { getIndustriesSettingsRows } from "./helperFunctions";
import AgGridComponent from "../../../common/AgGridComponent";
import { Button, CircularProgress, Stack } from "@mui/material";
import CustomHeaderCell from "../../../common/CustomHeaderCell";
import { AddOutlined, SearchOutlined } from "@mui/icons-material";
import NoRowsOverlayCustomComponent from "../../../common/NoRowsOverlayCustomComponent";
import CustomOverlayLoadingComponent from "../../../common/CustomOverlayLoadingComponent";
import IndustryAddEditModal from "../../../components/DataSettingsAddEditModals/IndustryAddEditModal";

const FunctionsSettings = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();

  const { specIndustries } = useSelector((state) => state.industries);

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeaderCell,
    };
  }, []);

  const goToFirstPage = useCallback(() => {
    ref.current.api.paginationGoToFirstPage();
  }, []);

  const showLoading = useCallback(() => {
    return ref?.current?.api?.showLoadingOverlay();
  }, []);

  const showNoRowsOverlay = useCallback(() => {
    return ref.current.api.showNoRowsOverlay();
  }, []);

  const hideOverlay = useCallback(() => {
    return ref?.current?.api?.hideOverlay();
  }, []);

  const onSearchFilterChanged = useCallback(() => {
    const displayedRows = ref.current.api.getDisplayedRowAtIndex(0);
    if (!displayedRows) {
      showNoRowsOverlay();
    } else {
      hideOverlay();
    }
  }, []);

  const onFilterTextBoxChanged = useCallback(
    (e) => {
      setSearchText(e.target.value);
      ref.current.api.setGridOption("quickFilterText", e.target.value);
      onSearchFilterChanged();
    },
    [onSearchFilterChanged]
  );

  useEffect(() => {
    dispatch(getIndustriesAsync())
      .unwrap()
      .then(() => {
        setIsInitialLoading(false);
      });
  }, []);

  useEffect(() => {
    setColDefs(getIndustriesSettingsColumns());

    setRowData(
      getIndustriesSettingsRows(
        specIndustries?.length > 0 ? specIndustries : []
      )
    );
  }, [specIndustries]);

  return (
    <>
      <Stack sx={{ height: 1 }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            height: "45px",
            pr: 2,
            overflowY: "hidden",
            overflowX: "auto",
            scrollbarWidth: "none",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}>
          <InputField
            isLabel={false}
            value={searchText}
            onChange={onFilterTextBoxChanged}
            className={"searchInput"}
            placeholder='Search for industries by name'
            sx={{ width: "300px", height: "30px", mt: "-7px" }}
            startAdornment={<SearchOutlined fontSize='small' />}
          />
          <Button
            variant='contained'
            color='primary'
            sx={{ height: "30px" }}
            startIcon={<AddOutlined />}
            onClick={() => {
              dispatch(
                setIndustryAddEditModal({
                  industryAddEditType: "ADD",
                })
              );
            }}>
            Add Industry
          </Button>
        </Stack>
        <Stack
          sx={(theme) => ({
            height: 1,
            borderTop: `1px solid ${theme.palette.gray.borderGray}`,
          })}>
          {isInitialLoading ? (
            <Stack
              height={1}
              width={1}
              justifyContent={"center"}
              alignItems={"center"}>
              <CircularProgress size={40} />
            </Stack>
          ) : (
            <AgGridComponent
              ref={ref}
              data={rowData}
              columnDefs={colDefs}
              components={components}
              loadingOverlayComponent={CustomOverlayLoadingComponent}
              noRowsOverlayComponent={NoRowsOverlayCustomComponent}
              noRowsMessage='No industries found. Create requirements for industries.'
            />
          )}
        </Stack>
      </Stack>
      <IndustryAddEditModal />
    </>
  );
};

export default FunctionsSettings;
