export const MultipleLineIcon = () => {
  return (
    <svg
      width='100%'
      height='100%'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 60 61'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g id='chart icon / multiple line chart '>
        <g id='Group 1'>
          <path
            id='Vector 2'
            d='M14.25 36.3014L21.2538 23.5143L30.9592 38.1779L40.8757 19.9016L47.9999 13.9299'
            stroke='#808080'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector 3'
            d='M14.25 44.1443H17.4642L30.8525 29.5403L40.7119 33.2157L47.6785 30.0013'
            stroke='#808080'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <circle
            id='Ellipse 1'
            cx='21.0001'
            cy='23.5728'
            r='1.28571'
            fill='#808080'
            stroke='#808080'
            strokeWidth='1.5'
          />
          <ellipse
            id='Ellipse 2'
            cx='31.2427'
            cy='38.3585'
            rx='1.28572'
            ry='1.28571'
            fill='#808080'
            stroke='#808080'
            strokeWidth='1.5'
          />
          <ellipse
            id='Ellipse 3'
            cx='40.9283'
            cy='33.2159'
            rx='1.28572'
            ry='1.28571'
            fill='#808080'
            stroke='#808080'
            strokeWidth='1.5'
          />
          <ellipse
            id='Ellipse 4'
            cx='40.9283'
            cy='19.7159'
            rx='1.28572'
            ry='1.28571'
            fill='#808080'
            stroke='#808080'
            strokeWidth='1.5'
          />
          <ellipse
            id='Ellipse 5'
            cx='17.7857'
            cy='44.1444'
            rx='1.28571'
            ry='1.28572'
            fill='#808080'
            stroke='#808080'
            strokeWidth='1.5'
          />
          <circle
            id='Ellipse 6'
            cx='30.6431'
            cy='29.3585'
            r='1.28571'
            fill='#808080'
            stroke='#808080'
            strokeWidth='1.5'
          />
          <path id='Vector 1' d='M12 12.0015V48.0015H48' stroke='#808080' strokeDasharray='2 2' />
        </g>
      </g>
    </svg>
  );
};
