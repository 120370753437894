import ActionCell from "./ActionCell";

const commonCellStyle = {
  overflowWrap: "anywhere",
  whiteSpace: "normal",
  lineHeight: "normal",
  paddingTop: "8px",
  paddingBottom: "8px",
};
export const getSpecKpiColumns = (isPreview) => {
  const cols = [
    {
      field: "action",
      editable: false,
      headerName: "Actions",
      resizable: false,
      cellDataType: "text",
      width: 90,
      maxWidth: 90,
      sortable: false,
      cellRenderer: ActionCell,
      pinned: "left",
      suppressMovable: true,
    },
    {
      field: "name",
      editable: false,
      headerName: "KPI",
      resizable: false,
      unSortIcon: true,
      cellDataType: "text",
      pinned: "left",
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
      suppressMovable: true,
    },

    {
      field: "targetValue",
      editable: false,
      headerName: "Target Value",
      resizable: false,
      unSortIcon: true,
      cellDataType: "text",
      flex: 1,
      minWidth: 200,
      maxWidth: 300,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
      suppressMovable: true,
    },
    {
      field: "description",
      editable: false,
      headerName: "Business Impact",
      resizable: false,
      unSortIcon: true,
      cellDataType: "text",
      minWidth: 200,
      flex: 1,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
      suppressMovable: true,
    },
  ];

  const filteredCols = cols.filter(
    (col) => !(col.field === "action" && isPreview)
  );

  return filteredCols;
};
