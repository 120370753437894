import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { NoDataFound } from "../../assets";

const NoRowsOverlayCustomComponent = (props) => {
  return (
    <Stack justifyContent={"center"}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <NoDataFound
          style={{
            height: "220px",
            width: "220px",
          }}
        />
      </Box>
      <Typography variant='spec_body' sx={{ mt: 1 }}>
        {props?.noRowsMessageFunc()}
      </Typography>
    </Stack>
  );
};

export default NoRowsOverlayCustomComponent;
