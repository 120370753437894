import { PURGE } from "redux-persist";
import { HTTP_OK } from "../../constants/statusCodes";
import { STATE_BUSINESS_TERMS } from "../../constants";
import { defaultMessage } from "../../constants/messages";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getBusinessTerm,
  getMappingColumns,
  getSpecBusinessTerm,
} from "../../services";
import { defaultExceptionHandler } from "../../utils/defaultExceptionHandler";

export const getBusinessTerms = createAsyncThunk(
  "businessTerms/getBusinessTerms",
  async (payload) => {
    try {
      const response = await getBusinessTerm({ payload: payload });

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }

      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

export const getSpecBusinessTermsAsync = createAsyncThunk(
  "businessTerms/getSpecBusinessTermsAsync",
  async (payload) => {
    try {
      const response = await getSpecBusinessTerm({ specId: payload?.specId });

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }

      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

export const getMappingColumnsAsync = createAsyncThunk(
  "businessTerms/getMappingColumnsAsync",
  async () => {
    try {
      const response = await getMappingColumns();

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }

      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

const initialState = {
  businessTerms: [],
  isBuisnessTermLoading: false,
  mappingColumns: null,
  isMappingColumnsLoading: false,
  currentTermTab: STATE_BUSINESS_TERMS.EXISTS,
  termModal: {
    isTermModalOpen: false,
    termModalData: null,
    termModalType: "ADD",
  },
};

const businessTerms = createSlice({
  name: "businessTerms",
  initialState: initialState,
  reducers: {
    resetBusinessTerms: (state) => {
      state.businessTerms = [];
    },
    setCurrentTermTab: (state, action) => {
      state.currentTermTab = action.payload;
    },
    setTermModal: (state, action) => {
      state.termModal.isTermModalOpen = action.payload.isTermModalOpen;
      state.termModal.termModalData = action?.payload?.termModalData
        ? action?.payload?.termModalData
        : null;
      state.termModal.termModalType = action?.payload?.termModalType
        ? action?.payload?.termModalType
        : "ADD";
    },
    unsetTermModal: (state, action) => {
      state.termModal = {
        isTermModalOpen: false,
        termModalData: null,
        termModalType: "ADD",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
    builder.addCase(getBusinessTerms.pending, (state, action) => {
      state.isBuisnessTermLoading = true;
    });
    builder.addCase(getBusinessTerms.fulfilled, (state, action) => {
      state.isBuisnessTermLoading = false;
      state.businessTerms = action.payload.businessTerms;
    });
    builder.addCase(getBusinessTerms.rejected, (state) => {
      state.isBuisnessTermLoading = false;
    });
    // SPEC BUSINESS TERMS
    builder.addCase(getSpecBusinessTermsAsync.pending, (state, action) => {
      state.isBuisnessTermLoading = true;
    });
    builder.addCase(getSpecBusinessTermsAsync.fulfilled, (state, action) => {
      state.isBuisnessTermLoading = false;
      state.businessTerms = action.payload.businessTerms;
    });
    builder.addCase(getSpecBusinessTermsAsync.rejected, (state) => {
      state.isBuisnessTermLoading = false;
    });
    // SPEC INDEX PREFRENCES
    builder.addCase(getMappingColumnsAsync.pending, (state, action) => {
      state.isSpecIndexPrefrencesLoading = true;
    });
    builder.addCase(getMappingColumnsAsync.fulfilled, (state, action) => {
      state.isSpecIndexPrefrencesLoading = false;
      state.mappingColumns = action.payload.mappingColumns;
    });
    builder.addCase(getMappingColumnsAsync.rejected, (state) => {
      state.isSpecIndexPrefrencesLoading = false;
    });
  },
});

export const {
  setCurrentTermTab,
  setTermModal,
  unsetTermModal,
  resetBusinessTerms,
} = businessTerms.actions;
export default businessTerms.reducer;
