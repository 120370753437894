import axios from "axios";
import { HTTP_FORBIDDEN, HTTP_UNAUTHORIZED } from "./constants/statusCodes";
import storage from "redux-persist/lib/storage";

import Cookies from "js-cookie";
import { toaster } from "./common/Toaster";
import { getSubdomain } from "./utils/commonUtility";

let persistor;

export const createInstanceAndInjectStore = (_store, _dispatch, _persistor) => {
  persistor = _persistor;
};

axios.defaults.headers.post["Content-Type"] = "application/json";
const axiosInstance = axios.create();
axiosInstance.all = axios.all;
axiosInstance.spread = axios.spread;

axiosInstance.interceptors.request.use((request) => {
  const accessToken = Cookies.get("token");

  const currentURL = window.location.href;

  const urlWithSubdomain = getSubdomain(currentURL);

  const subdomain = Cookies.get("subdomain");
  request.params = {
    ...request.params,
    subdomain: urlWithSubdomain ? urlWithSubdomain : subdomain,
  };
  request.headers["Authorization"] = `Bearer ${accessToken}`;
  return request;
});

const redirectToLogout = () => {
  Cookies.remove("token", { domain: `${process.env.REACT_APP_DOMAIN}` });
  Cookies.remove("subdomain", { domain: `${process.env.REACT_APP_DOMAIN}` });
  persistor.purge();
  storage.removeItem("persist:root");
  localStorage.clear();
  // window.location.pathname = ROUTE_PATH.LOGIN;
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    return new Promise(async (resolve, reject) => {
      if (error?.response?.status === HTTP_UNAUTHORIZED) {
        redirectToLogout();
      } else if (error?.response?.status === HTTP_FORBIDDEN) {
        toaster({
          type: "error",
          message:
            error?.response?.data?.toaster?.data?.message ||
            "Something went wrong!",
        });
      } else {
        return reject(error);
      }
    });
  }
);

export default axiosInstance;
