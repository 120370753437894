import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Stack,
  IconButton,
  Typography,
  InputLabel,
} from "@mui/material";
import BaseModal from "../../../common/BaseModal";
import { useDispatch, useSelector } from "react-redux";
import { InputField } from "../../../common/InputField";
import {
  getMappingColumnsAsync,
  resetMappingColumnsAddEditModal,
} from "../../../store/slices/mappingSettings";
import { AddOutlined, DeleteOutline } from "@mui/icons-material";
import { toaster } from "../../../common/Toaster";
import {
  createMappingsColumn,
  editMappingsColumn,
  editMappingsDefaultColumn,
} from "../../../services";
import { HTTP_OK } from "../../../constants/statusCodes";
import { defaultMessage } from "../../../constants/messages";

const MappingsAddEditModal = () => {
  const dispatch = useDispatch();
  const {
    isMappingColumnsAddEditOpen,
    mappingColumnsAddEditData,
    mappingColumnsAddEditType,
  } = useSelector((state) => state.mappingSettings);

  const [name, setName] = useState("");
  const [values, setValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isMappingColumnsAddEditOpen && mappingColumnsAddEditData) {
      setName(
        mappingColumnsAddEditData?.title ? mappingColumnsAddEditData?.title : ""
      );
      setValues(
        mappingColumnsAddEditData?.valuesArray
          ? [...mappingColumnsAddEditData.valuesArray]
          : []
      );
    }
  }, [mappingColumnsAddEditData]);

  const handleClose = () => {
    dispatch(resetMappingColumnsAddEditModal());
    setName("");
    setValues([]);
  };

  //  =--=--=-=--=--=--=- DYNAMIC FIELD LOGIC -=--=--=--=--=--=--=--=

  const addField = () => {
    setValues([
      ...values,
      { id: Date.now(), name: "", _destroy: false, __isNew__: true },
    ]);
  };

  const removeField = (id, field) => {
    if (field?.__isNew__) {
      setValues(values.filter((field) => field.id !== id));
    } else {
      setValues(
        values.map((field) =>
          field?.id === id ? { ...field, _destroy: true } : field
        )
      );
    }
  };

  const handleChange = (id, value) => {
    setValues(
      values.map((field) =>
        field.id === id ? { ...field, name: value } : field
      )
    );
  };

  const isValid = name?.trim().length > 0;

  const handleSubmit = () => {
    const valuePayload = values
      .filter((value) => value.name.length)
      ?.map((value) => {
        if (value?.__isNew__) {
          return {
            name: value?.name,
            _destroy: value?._destroy,
          };
        }

        return value;
      });

    if (mappingColumnsAddEditType === "ADD") {
      setIsLoading(true);
      createMappingsColumn({
        payload: {
          name: name,
          mapping_column_values_attributes: valuePayload,
        },
      })
        .then((response) => {
          setIsLoading(false);
          if (response?.status !== HTTP_OK) {
            throw new Error(defaultMessage);
          }
          dispatch(getMappingColumnsAsync());
          handleClose();
          toaster({
            type: "success",
            message: "Column was created successfully.",
          });
        })
        .catch((e) => {
          setIsLoading(false);
          toaster({
            type: "error",
            message: e,
          });
        });
    }

    if (
      mappingColumnsAddEditType === "EDIT" &&
      mappingColumnsAddEditData?.originalKey !== "businessTermCategory"
    ) {
      setIsLoading(true);
      editMappingsColumn({
        payload: {
          name: name,
          mapping_column_values_attributes: valuePayload,
        },
        id: mappingColumnsAddEditData?.id,
      })
        .then((response) => {
          setIsLoading(false);
          if (response?.status !== HTTP_OK) {
            throw new Error(defaultMessage);
          }
          dispatch(getMappingColumnsAsync());
          handleClose();
          toaster({
            type: "success",
            message: "Column was updated successfully.",
          });
        })
        .catch((e) => {
          setIsLoading(false);
          toaster({
            type: "error",
            message: e,
          });
        });
    }

    if (
      mappingColumnsAddEditType === "EDIT" &&
      mappingColumnsAddEditData?.originalKey === "businessTermCategory"
    ) {
      setIsLoading(true);
      editMappingsDefaultColumn({
        payload: {
          business_term_categories: {
            business_term_categories_attributes: valuePayload,
          },
        },
      })
        .then((response) => {
          setIsLoading(false);
          if (response?.status !== HTTP_OK) {
            throw new Error(defaultMessage);
          }
          dispatch(getMappingColumnsAsync());
          handleClose();
          toaster({
            type: "success",
            message: "Column was updated successfully.",
          });
        })
        .catch((e) => {
          setIsLoading(false);
          toaster({
            type: "error",
            message: e,
          });
        });
    }
  };

  return (
    <BaseModal
      title={
        mappingColumnsAddEditType === "ADD"
          ? "Add Mapping Column"
          : "Mapping Column Edit"
      }
      isOpen={isMappingColumnsAddEditOpen}
      handleClose={handleClose}
      confirmTitle={mappingColumnsAddEditType === "ADD" ? "Submit" : "Update"}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      submitDisable={!isValid}>
      <Stack rowGap={2}>
        {mappingColumnsAddEditData?.originalKey !== "businessTermCategory" && (
          <InputField
            isLabel={true}
            label='Name'
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            placeholder='Enter column name'
          />
        )}
        <Stack rowGap={2}>
          <Stack
            sx={{
              width: 1,
              rowGap: 2,
            }}>
            <InputLabel
              htmlFor={name}
              sx={{
                mb: 0.65,
                ".MuiFormLabel-asterisk": {
                  color: "secondary.error",
                },
                textAlign: "left",
              }}>
              <Typography
                variant='spec_inputLabel'
                sx={{
                  color: "secondary.black",
                }}>
                Values
              </Typography>
            </InputLabel>
            {[...values]
              ?.filter((field) => !field?._destroy)
              ?.map((value, i) => {
                return (
                  <DynamicField
                    index={i}
                    value={value?.name}
                    handleChange={handleChange}
                    id={value?.id}
                    key={value?.id}
                    removeField={removeField}
                    field={value}
                  />
                );
              })}
          </Stack>

          <Stack
            width={1}
            sx={{
              mt: !values?.length ? 2 : 0,
              display: "flex",
              flexDirection: "row",
              justifyContent: !values?.length ? "center" : "left",
            }}>
            <Button
              variant={"outlined"}
              color='secondary'
              startIcon={<AddOutlined />}
              onClick={addField}>
              Add More
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </BaseModal>
  );
};

function DynamicField({ index, value, handleChange, id, removeField, field }) {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      columnGap={2}
      sx={{
        width: 1,
      }}>
      <Typography variant='spec_body' sx={{ width: "10px" }}>
        {index + 1}.
      </Typography>
      <InputField
        fullWidth={true}
        value={value}
        onChange={(e) => {
          handleChange(id, e?.target?.value);
        }}
        placeholder='Enter Value'
        endAdornment={
          <DeleteOutline
            fontSize='small'
            onClick={() => removeField(id, field)}
            sx={(theme) => ({
              cursor: "pointer",
              color: theme.palette.gray.iconColor,
            })}
          />
        }
      />
    </Stack>
  );
}

export default MappingsAddEditModal;
