import _ from "lodash";
import React from "react";
import { stringAvatar } from "../../utils/commonUtility";
import { specSecondary } from "../../constants/theme/colors";
import { Avatar, AvatarGroup, Typography } from "@mui/material";

const MultipleAvatarGroup = (props) => {
  return !_.isEmpty(props?.value) ? (
    <AvatarGroup
      max={5}
      slotProps={{
        additionalAvatar: {
          sx: {
            bgcolor: specSecondary[50],
            height: "35px",
            width: "35px",
            color: "info.main",
            fontSize: "14px",
            fontWeight: 400,
          },
        },
      }}>
      {props?.value?.map((item, i) => {
        return (
          <Avatar
            key={i}
            sx={(theme) => ({
              bgcolor: specSecondary[50],
              height: "35px",
              width: "35px",
            })}>
            <Typography variant='spec_body' color={"info.main"}>
              {stringAvatar(item?.username)?.children}
            </Typography>
          </Avatar>
        );
      })}
    </AvatarGroup>
  ) : (
    <Typography variant='spec_body'> - </Typography>
  );
};

export default MultipleAvatarGroup;
