import {
  FullLogo,
  RoundLogo,
  ExpandCircleDownOutlinedIcon,
} from "../../assets";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import MenuItem from "../../common/MenuItem";
import CustomPopover from "../../common/CustomPopover";
import {
  Box,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MenuItemTextContainer, StyledCompanyToggle } from "./style";
import { setCookie } from "../../utils/commonUtility";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../router/routes";
import { persistor } from "../../store";
import storage from "redux-persist/lib/storage";

const CompanyToggleButton = () => {
  const navigate = useNavigate();
  const { user, currentCompany, activeCompanies } = useSelector(
    (state) => state.userAuth
  );
  const canToggleCompanies = user && user?.activeCompanies?.length > 1;
  const isCompanyOwner = currentCompany && currentCompany?.companyOwner;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //MAKES THE CURSOR WAIT AND POINTER BASED ON LOADING
  const cursorWait = () => (document.body.style.cursor = "wait");
  const cursorDefault = () => (document.body.style.cursor = "default");

  const changeSubdomain = (subdomain) => {
    handleClose();
    cursorWait();
    const url = new URL(window.location.href);
    const parts = url?.hostname?.split(".");

    // If there's already a subdomain, replace it
    if (parts?.length > 2) {
      parts[0] = subdomain;
    } else {
      // If not, insert the subdomain
      parts?.unshift(subdomain);
    }

    url.hostname = parts?.join(".");

    setCookie("subdomain", subdomain);
    cursorDefault();
    window.location.replace(`${url.protocol + "//" + url.hostname}/specs`);
  };

  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  return canToggleCompanies ? (
    <>
      <StyledCompanyToggle
        onClick={handleClick}
        endIcon={<ExpandCircleDownOutlinedIcon />}>
        <Box
          component={"img"}
          src={RoundLogo}
          sx={{
            height: { xs: "25px", sm: "25px" },
            width: { xs: "25px", sm: "25px" },
            mr: 1,
          }}
        />
        <Stack
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "flex-start", sm: "center" },
          }}>
          <Typography variant={isSmDown ? "spec_h3" : "spec_companytitle"}>
            {currentCompany?.name}
          </Typography>
          <Typography
            variant='spec_caption'
            ml={{ xs: 0, sm: 0.5 }}
            sx={{
              fontSize: { xs: "10px", sm: "12px" },
            }}>
            ({isCompanyOwner ? "Owner" : currentCompany?.role})
          </Typography>
        </Stack>
      </StyledCompanyToggle>
      <CustomPopover anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Stack rowGap={1}>
          {activeCompanies?.map((company, index) => (
            <MenuItem
              key={index}
              onClick={() => changeSubdomain(company.subdomain)}
              Icon={
                <Box
                  component={"img"}
                  src={RoundLogo}
                  sx={{ height: "25px", width: "25px" }}
                />
              }
              customItemText={
                <MenuItemTextContainer>
                  <Tooltip title={company?.name} arrow>
                    <Typography
                      variant='spec_h2'
                      sx={{ wordBreak: "break-word" }}>
                      {company?.name.length > 20
                        ? `${company?.name.slice(0, 20)}...`
                        : company?.name}
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    title={company?.companyOwner ? "owner" : company.role}
                    arrow>
                    <Typography
                      variant='spec_caption'
                      color='gray.main'
                      sx={{ wordBreak: "break-word" }}>
                      {`(${company?.companyOwner ? "owner" : company.role})`}
                    </Typography>
                  </Tooltip>
                </MenuItemTextContainer>
              }
            />
          ))}
        </Stack>
      </CustomPopover>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
      }}
      onClick={() => navigate(ROUTE_PATH.SPECS)}>
      <FullLogo />
    </Box>
  );
};

export default CompanyToggleButton;
