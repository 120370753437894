import { TableChartOutlined } from "@mui/icons-material";
import { Button, Checkbox, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomPopover from "../../common/CustomPopover";
import MenuItem from "../../common/MenuItem";
import LoadingButton from "../../common/LoadingButton";
import { updateSpecIndexPreference } from "../../services";
import { HTTP_OK } from "../../constants/statusCodes";
import { defaultMessage } from "../../constants/messages";
import { toaster } from "../../common/Toaster";
import { updateSpecIndePrefrencesState } from "../../store/slices/specListing";

const SpecColumnFilter = ({ columns }) => {
  const dispatch = useDispatch();
  const { specIndexPrefrences } = useSelector((state) => state.specListing);
  const [anchorEl, setAnchorEl] = useState(null);
  const [localSpecIndexPrefrences, setLocalSpecIndexPrefrences] =
    useState(specIndexPrefrences);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const handleClick = (e) => {
    setLocalSpecIndexPrefrences(specIndexPrefrences);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleFilterChange = (name, checked) => {
    setLocalSpecIndexPrefrences((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSave = async () => {
    setIsSaveLoading(true);
    const modifiedPrefrences = {
      status: localSpecIndexPrefrences.status,
      priority: localSpecIndexPrefrences.priority,
      function: localSpecIndexPrefrences.function,
      requester: localSpecIndexPrefrences.requester,
      author: localSpecIndexPrefrences.author,
      created_at: localSpecIndexPrefrences.createdAt, // Renamed keys
      updated_at: localSpecIndexPrefrences.updatedAt, // Renamed keys
      collaborators: localSpecIndexPrefrences.collaborators,
      similar_specs: localSpecIndexPrefrences.similarSpecs,
      urgency: localSpecIndexPrefrences.urgency,
      due_date: localSpecIndexPrefrences.dueDate, // Renamed keys
      comments: localSpecIndexPrefrences.comments,
      labels: localSpecIndexPrefrences.labels,
      visuals: localSpecIndexPrefrences.visuals,
    };

    try {
      const response = await updateSpecIndexPreference({
        payload: {
          preferences: modifiedPrefrences,
        },
      });

      setIsSaveLoading(false);

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }

      dispatch(
        updateSpecIndePrefrencesState(
          response?.data?.specIndexPreference?.preferences
        )
      );
      handleClose();

      toaster({
        type: "success",
        message: "Specs column prefrences updated.",
      });
    } catch (error) {
      setIsSaveLoading(false);
      toaster({
        type: "error",
        message: "Error while saving spec column prefrences!",
      });
    }
  };

  return (
    <>
      <IconButton
        size='small'
        sx={(theme) => ({
          borderRadius: "7px",
          height: "30px",
          backgroundColor: theme.palette.gray.backgroundGray,
        })}
        onClick={handleClick}>
        <TableChartOutlined />
      </IconButton>

      <CustomPopover
        open={open}
        anchorEl={anchorEl}
        onClose={isSaveLoading ? () => {} : handleClose}>
        <Stack sx={{ pb: 1 }}>
          <Typography variant='spec_h3'>Customize Columns</Typography>
        </Stack>
        <Stack sx={{ height: "200px", overflowY: "auto" }}>
          {columns?.map((column, i) => {
            if (column.field !== "specification") {
              return (
                <MenuItem
                  key={i}
                  Icon={
                    <Checkbox
                      sx={{ p: 0 }}
                      color='primary'
                      name={column?.field}
                      value={column?.field}
                      checked={localSpecIndexPrefrences[column?.field]}
                    />
                  }
                  itemText={column?.headerName}
                  itemProps={{ height: "15px" }}
                  onClick={() =>
                    handleFilterChange(
                      column?.field,
                      !localSpecIndexPrefrences[column?.field]
                    )
                  }
                />
              );
            }
          })}
        </Stack>
        <Stack direction={"row"} columnGap={1} mt={1.5}>
          <Button
            fullWidth
            variant='outlined'
            color='gray'
            disabled={isSaveLoading}
            onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton fullWidth onClick={handleSave} loading={isSaveLoading}>
            Save
          </LoadingButton>
        </Stack>
      </CustomPopover>
    </>
  );
};

export default SpecColumnFilter;
