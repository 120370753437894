import _ from "lodash";

import { MOCK_DATA_LENGTH } from "../constants";

import { convertToNumber, getSymbol } from "./utils-chart-data";

// Custom function to calculate median
const calculateMedian = (arr) => {
  const sorted = _.sortBy(arr);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  } else {
    return sorted[middle];
  }
};

//PREPARING DATA FOR - STACKED COLUMN CHART
export const transformDataForStackedColumnChart = (
  data,
  xaxis,
  overlay,
  yaxis,
  metric = "total",
  multipleSeries
) => {
  if (!multipleSeries || multipleSeries?.length === 0) {
    if (data) {
      const { columns, rows } = data;

      const overlayName = columns?.find(
        (item) => item?.columnId === overlay
      )?.name;
      const timeName = columns?.find((item) => item?.columnId === xaxis)?.name;
      const preparedData = rows?.slice(0, MOCK_DATA_LENGTH).map((item) => {
        return {
          Xaxis: item[xaxis],
          Overlay: item[overlay],
          Yaxis: convertToNumber(item[yaxis] || ""),
        };
      });

      const symbol = getSymbol(rows[0][yaxis]);

      const groupedData = _(preparedData)
        ?.groupBy("Xaxis")
        ?.map((group) => ({
          Xaxis: group[0]?.Xaxis,
          Overlay: "Grouped",
          Yaxis: _.sumBy(group, "Yaxis"),
        }))
        .value();

      const aggregatedData = _.chain(preparedData)
        ?.groupBy("Xaxis")
        ?.mapValues((values) =>
          _.chain(values)
            ?.groupBy("Overlay")
            ?.mapValues((group) => {
              const total = _.sumBy(group, "Yaxis");
              const count = group?.length;
              const average = total / count;

              const sortedValues = _.sortBy(group, "Yaxis");
              const median =
                count % 2 === 0
                  ? (sortedValues[count / 2 - 1].Yaxis +
                      sortedValues[count / 2].Yaxis) /
                    2
                  : sortedValues[(count - 1) / 2].Yaxis;

              return { total, count, average, median };
            })
            .value()
        )
        .value();

      const overlayValues = _.chain(aggregatedData)
        ?.flatMap((values) => Object?.keys(values))
        ?.uniq()
        ?.value();

      return [
        {
          dataForChart:
            Object?.keys(aggregatedData)?.map((key) => {
              const dynamicEntry = {
                id: key,
              };

              overlayValues?.forEach((overlay) => {
                dynamicEntry[overlay] =
                  aggregatedData?.[key]?.[overlay]?.[
                    _.isEmpty(metric) ? "total" : metric
                  ] ?? 0;
              });

              return dynamicEntry;
            }) || {},

          timeName,
          overlayName,
          symbol: symbol,
          aggregatedData,
          overlayValues,
          metric,
        },
      ];
    }
  } else {
    if (data) {
      const { columns, rows } = data;
      let yAxisKeys = [];
      const overlayName = columns?.find(
        (item) => item?.columnId === overlay
      )?.name;
      multipleSeries?.map((axis) => {
        yAxisKeys.push(
          columns?.find((item) => item?.columnId === axis?.y)?.name
        );
      });
      const timeName = columns?.find((item) => item?.columnId === xaxis)?.name;
      const preparedData = rows?.slice(0, MOCK_DATA_LENGTH).map((item) => {
        let yAxisValue;
        if (Array.isArray(multipleSeries)) {
          yAxisValue = multipleSeries.map((axis) => {
            const axisItem = item[axis.y];
            const key = axis.yTitle;

            return axisItem
              ? {
                  data: { [key]: convertToNumber(axisItem || "") },
                  symbol: getSymbol(axisItem),
                }
              : null;
          });
        } else {
          yAxisValue = convertToNumber(item[yaxis.id] || "");
        }
        return {
          xaxis: item[xaxis],
          yaxis: yAxisValue,
        };
      });

      const symbol = getSymbol(rows[0][yaxis]);

      return [
        {
          dataForChart: preparedData || [],
          symbol: symbol,
          metric,
          yaxis: yAxisKeys,
          xaxis: timeName,
        },
      ];
    }
  }
};

//PREPARING DATA FOR - 100% STACKED COLUMN CHART
export const transformDataFor100PerStackedColumnChart = (
  data,
  xaxis,
  overlay,
  yaxis,
  metric = "total",
  multipleSeries
) => {
  if (!multipleSeries || multipleSeries?.length === 0) {
    if (data) {
      const { columns, rows } = data;
      const overlayName = columns?.find(
        (item) => item?.columnId === overlay
      )?.name;
      const timeName = columns?.find((item) => item?.columnId === xaxis)?.name;
      const preparedData = rows?.slice(0, MOCK_DATA_LENGTH).map((item) => {
        return {
          Xaxis: item[xaxis],
          Overlay: item[overlay],
          Yaxis: convertToNumber(item[yaxis] || ""),
        };
      });

      const symbol = getSymbol(rows[0][yaxis]);

      const aggregatedData = _.chain(preparedData)
        .groupBy("Xaxis")
        .mapValues((values) =>
          _.chain(values)
            .groupBy("Overlay")
            .mapValues((group) => {
              const total = _.sumBy(group, "Yaxis");
              return { total };
            })
            .value()
        )
        .mapValues((overlayTotals, key) => {
          const totalSum = _.sumBy(_.values(overlayTotals), "total");
          const percentages = _.mapValues(overlayTotals, (overlay) => ({
            total: overlay.total,
            percentage: (overlay.total / totalSum) * 100,
          }));
          return { id: key, ...percentages };
        })
        .value();

      const overlayValues = _.chain(aggregatedData)
        ?.flatMap((values) => Object?.keys(values))
        ?.uniq()
        ?.filter((key) => key !== "id") // Filter out 'id'
        ?.value();

      return [
        {
          dataForChart:
            Object?.keys(aggregatedData)?.map((key) => {
              const dynamicEntry = {
                id: key,
              };

              overlayValues?.forEach((overlay) => {
                dynamicEntry[overlay] =
                  aggregatedData?.[key]?.[overlay]?.percentage ?? 0;
              });
              return dynamicEntry;
            }) || {},
          timeName,
          overlayName,
          symbol: symbol,
          aggregatedData,
          overlayValues,
          metric,
        },
      ];
    }
  } else {
    if (data) {
      const { columns, rows } = data;
      let yAxisKeys = [];
      // multipleSeries?.map((axis) => {
      //   yAxisKeys.push(axis.yTitle);
      // });
      multipleSeries?.map((axis) => {
        yAxisKeys.push(
          columns?.find((item) => item?.columnId === axis?.y)?.name
        );
      });
      const timeName = columns?.find((item) => item?.columnId === xaxis)?.name;
      const preparedData = rows?.slice(0, MOCK_DATA_LENGTH).map((item) => {
        let yAxisValue;
        if (Array.isArray(multipleSeries)) {
          yAxisValue = multipleSeries.map((axis) => {
            const axisItem = item[axis.y];
            const key = axis.yTitle;

            return axisItem
              ? {
                  data: { [key]: convertToNumber(axisItem || "") },
                  symbol: getSymbol(axisItem),
                }
              : null;
          });
        } else {
          yAxisValue = convertToNumber(item[yaxis.id] || "");
        }
        return {
          xaxis: item[xaxis],
          yaxis: yAxisValue,
        };
      });

      const symbol = getSymbol(rows[0][yaxis]);

      return [
        {
          dataForChart: preparedData || [],
          symbol: symbol,
          metric,
          yaxis: yAxisKeys,
          xaxis: timeName,
        },
      ];
    }
  }
};

// PREPARING DATA FOR - SINGLE LINE CHART
export const transformDataForSingleLineChart = (data, xaxis, yaxis, metric) => {
  const { columns, rows } = data;
  const timeName = columns.find((item) => item.columnId === xaxis)?.name;
  const columnName = columns.find((item) => item.columnId === yaxis)?.name;

  const symbol = getSymbol(rows[0]?.[yaxis] || "");
  const preparedData = rows.slice(0, MOCK_DATA_LENGTH).map((item) => {
    return {
      xAxis: item?.[xaxis],
      yAxis: convertToNumber(item?.[yaxis] || ""),
    };
  });

  const groupedData = _.groupBy(preparedData, "xAxis");

  const aggregatedData = _.mapValues(groupedData, (group) => {
    const count = group?.length;
    const total = _.sumBy(group, "yAxis");
    const values = _.map(group, "yAxis")?.sort((a, b) => a - b);
    const middle = Math?.floor(values?.length / 2);
    const median =
      values?.length % 2 === 0
        ? (values?.[middle - 1] + values?.[middle]) / 2
        : values?.[middle];

    return {
      count,
      total,
      average: total / count,
      median,
    };
  });

  const dataForLineChart = Object.keys(aggregatedData).map((xAxis) => ({
    x: xAxis,
    y: aggregatedData?.[xAxis]?.[_.isEmpty(metric) ? "total" : metric] || 0, // Use the data you want to display on the y-axis
  }));

  return [
    {
      id: columnName,
      xName: timeName,
      yName: columnName,
      data: dataForLineChart,
      symbol: symbol,
      metric: _.isEmpty(metric) ? "total" : metric,
      aggregatedData: aggregatedData,
    },
  ];
};

// PREPARING DATA FOR - MULTILINE LINE CHART
export const transformDataForMultilineChart = (
  data,
  xaxis,
  overlay,
  yaxis,
  metric = "total",
  multipleSeries
) => {
  if (multipleSeries === undefined || multipleSeries?.length === 0) {
    const { columns, rows } = data;
    const xAxisName =
      columns?.find((item) => item?.columnId === xaxis)?.name || "";
    const yAxisName =
      columns?.find((item) => item?.columnId === yaxis)?.name || "";
    const overlayName =
      columns?.find((item) => item?.columnId === overlay)?.name || "";

    const preparedData = rows.slice(0, MOCK_DATA_LENGTH).map((item) => {
      return {
        xaxis: item[xaxis],
        overlay: item[overlay],
        yaxis: convertToNumber(item[yaxis] || ""),
      };
    });
    const symbol = getSymbol(rows[0]?.[yaxis] || "");

    const groupedByOverlay = _.groupBy(preparedData, "overlay");

    const aggregatedData = _.map(groupedByOverlay, (overlayData, overlay) => {
      const groupedEntries = _.groupBy(overlayData, "xaxis");
      const aggregatedEntries = _.map(groupedEntries, (entries, xaxis) => {
        const total = _.sumBy(entries, "yaxis");
        const count = entries.length;
        const average = _.meanBy(entries, "yaxis");

        const xaxisSortedValues = _.sortBy(entries.map((entry) => entry.yaxis));
        const xaxisMiddleIndex = Math.floor(xaxisSortedValues.length / 2);
        const median =
          xaxisSortedValues.length % 2 === 0
            ? (xaxisSortedValues[xaxisMiddleIndex - 1] +
                xaxisSortedValues[xaxisMiddleIndex]) /
              2
            : xaxisSortedValues[xaxisMiddleIndex];

        const metricValues = {
          total: total,
          count: count,
          average: average,
          median: median,
        };

        const y = metricValues[metric] || total;

        return {
          x: xaxis,
          y,
          total,
          count,
          average,
          median,
        };
      });

      return {
        id: overlay,
        data: aggregatedEntries,
      };
    });

    return {
      xAxisName,
      yAxisName,
      overlayName,
      metric,
      symbol,
      data: aggregatedData,
    };
  } else {
    if (data) {
      const { columns, rows } = data;
      let yAxisKeys = [];
      // multipleSeries?.map((axis) => {
      //   yAxisKeys.push(axis.yTitle);
      // });
      multipleSeries?.map((axis) => {
        yAxisKeys?.push(
          columns?.find((item) => item?.columnId === axis?.y)?.name
        );
      });
      const timeName = columns?.find((item) => item?.columnId === xaxis)?.name;
      const preparedData = rows?.slice(0, MOCK_DATA_LENGTH).map((item) => {
        let yAxisValue;
        if (Array.isArray(multipleSeries)) {
          yAxisValue = multipleSeries.map((axis) => {
            const axisItem = item[axis.y];
            const key = axis.yTitle;

            return axisItem
              ? {
                  data: { [key]: convertToNumber(axisItem || "") },
                  symbol: getSymbol(axisItem),
                }
              : null;
          });
        } else {
          yAxisValue = convertToNumber(item[yaxis.id] || "");
        }
        return {
          xaxis: item[xaxis],
          yaxis: yAxisValue,
        };
      });

      const symbol = getSymbol(rows[0][yaxis]);

      return [
        {
          dataForChart: preparedData || [],
          symbol: symbol,
          metric,
          yaxis: yAxisKeys,
          xaxis: timeName,
        },
      ];
    }
  }
};

// PREPARING DATA FOR - COLUMN CHART
export const transformDataForColumnChart = (
  data,
  xaxis,
  yaxis,
  metric = "total",
  topN
) => {
  const { columns, rows } = data;
  const timeName = columns.find((item) => item.columnId === xaxis)?.name;
  const yAxis = columns.find((item) => item.columnId === yaxis)?.name;
  const symbol = getSymbol(rows[0][yaxis]);

  const preparedData = rows.slice(0, MOCK_DATA_LENGTH).map((item) => {
    return { id: item?.[xaxis], value: convertToNumber(item?.[yaxis] || "") };
  });
  const calculateMedian = (arr) => {
    const sortedArr = _.sortBy(arr);
    const mid = Math.floor(sortedArr.length / 2);

    if (sortedArr.length % 2 === 0) {
      return (sortedArr[mid - 1] + sortedArr[mid]) / 2;
    } else {
      return sortedArr[mid];
    }
  };

  const groupedData = _.groupBy(preparedData, "id");
  const formattedData = Object.keys(groupedData).map((month) => {
    const values = groupedData[month].map((item) => item.value);
    const count = values.length;
    const total = _.sum(values);
    const average = total / count;
    const median = calculateMedian(values);

    const metricValues = {
      total: total,
      count: count,
      average: average,
      median: median,
    };

    const value = metricValues[metric] || total;

    return {
      id: month,
      value: value,
    };
  });

  const getTopNItemsWithTies = (data, n, key) => {
    if (n === 0) {
      return data;
    }

    if (n > data?.length) {
      return data;
    }

    const sortedData = data.slice().sort((a, b) => b[key] - a[key]);
    const topNValue = sortedData[n - 1][key];
    return sortedData.filter((item) => item[key] >= topNValue);
  };

  return [
    {
      dataForChart: topN
        ? getTopNItemsWithTies(formattedData, topN, "value")
        : formattedData,
      xasix: timeName,
      yaxis: yAxis,
      symbol,
      metric: _.isEmpty(metric) ? "total" : metric,
    },
  ];
};

// PREPARING DATA FOR - BAR CHART
export const transformDataForBarChart = (
  data,
  xaxis,
  yaxis,
  metric = "total",
  topN = 0
) => {
  const { columns, rows } = data;

  const timeName = columns.find((item) => item.columnId === xaxis)?.name;
  const yaxisname = columns.find((item) => item.columnId === yaxis)?.name;
  const symbol = getSymbol(rows[0][yaxis]);

  const preparedData = rows.slice(0, MOCK_DATA_LENGTH).map((item) => {
    return { id: item?.[xaxis], value: convertToNumber(item?.[yaxis] || "") };
  });
  const calculateMedian = (arr) => {
    const sortedArr = _.sortBy(arr);
    const mid = Math.floor(sortedArr.length / 2);

    if (sortedArr.length % 2 === 0) {
      return (sortedArr[mid - 1] + sortedArr[mid]) / 2;
    } else {
      return sortedArr[mid];
    }
  };

  const groupedData = _.groupBy(preparedData, "id");
  const formattedData = Object.keys(groupedData).map((month) => {
    const values = groupedData[month].map((item) => item.value);
    const count = values.length;
    const total = _.sum(values);
    const average = total / count;
    const median = calculateMedian(values);

    const metricValues = {
      total: total,
      count: count,
      average: average,
      median: median,
    };

    const value = metricValues[metric] || total;

    return {
      id: month,
      value: value,
    };
  });

  const getTopNItemsWithTies = (data, n, key) => {
    if (n === 0) {
      return data;
    }
    if (n > data?.length) {
      return data;
    }

    const sortedData = data.slice().sort((a, b) => b[key] - a[key]);
    const topNValue = sortedData[n - 1][key];
    return sortedData.filter((item) => item[key] >= topNValue);
  };

  return [
    {
      dataForChart: topN
        ? getTopNItemsWithTies(formattedData, topN, "value")
        : formattedData,
      timeName,
      symbol,
      metric: _.isEmpty(metric) ? "total" : metric,
      yaxisname,
    },
  ];
};

function getValues(multipleSeries, rows, columns) {
  const result = multipleSeries.map((series) => {
    let yKey = series.y;
    let values = [columns?.find((item) => item?.columnId === yKey)?.name];

    rows.forEach((row) => {
      values.push(convertToNumber(row[yKey] || "")); // Assuming values are integers
    });

    return {
      values: values,
    };
  });

  return result;
}

//PREPARING DATA FOR - BOX PLOT CHART
export const transformDataForBoxPlotChart = (
  data,
  xaxis,
  yaxis,
  metric = "total",
  multipleSeries,
  xAxisLabel,
  yAxisLabel
) => {
  if (data) {
    const { columns, rows } = data;

    let yAxisKeys = [];
    multipleSeries?.map((axis) => {
      yAxisKeys.push(columns?.find((item) => item?.columnId === axis?.y)?.name);
    });

    const timeName = columns?.find((item) => item?.columnId === xaxis)?.name;

    const preparedData = getValues(multipleSeries, rows, columns);

    return [
      {
        dataForChart: [...preparedData] || [],
        symbol: "",
        metric,
        yaxis: yAxisKeys,
        xaxis: timeName,
        xAxisLabel,
        yAxisLabel,
      },
    ];
  }
};
