import * as yup from "yup";
import { REGEX } from "../constants/regex";
import {
  confirmPassValidationMsg,
  defaultTitleErrorMsg,
  emailValidationMsg,
  passwordValidationMsg,
  requiredValidationMsg,
  titleEmptyErrorMsg,
  trimValidationMsg,
} from "../constants/messages";

// LOGIN VALIDATION SCHEMA
export const LoginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .matches(REGEX.EMAIL, emailValidationMsg)
    .required(requiredValidationMsg("Email")),
  password: yup.string().required(requiredValidationMsg("Password")),
});

// FORGET PASSWORD VALIDATION SCHEMA
export const ForgetPasswordValidationSchema = yup.object().shape({
  email: yup
    .string()
    .matches(REGEX.EMAIL, emailValidationMsg)
    .required(requiredValidationMsg("Email")),
});

// INVITE EDITOR SCHEMA FOR OWNERS
export const InviteEditorValidationSchema = yup.object().shape({
  email: yup
    .string()
    .matches(REGEX.EMAIL, emailValidationMsg)
    .required(requiredValidationMsg("Email")),
});

export const TitleValidationSchema = yup.object().shape({
  title: yup
    .string()
    .trim(trimValidationMsg("Title"))
    .strict()
    .test("not-new-specification", defaultTitleErrorMsg, function (value) {
      if (value === "New Specification" || value.endsWith("Copy")) {
        return false;
      }
      return true;
    })
    .required(titleEmptyErrorMsg),
});

export const tabRenameValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim(trimValidationMsg("Tab name"))
    .strict()
    .required(requiredValidationMsg("Tab name")),
});

export const configureRowValidationSchema = yup.object().shape({
  noOfRows: yup
    .number()
    .integer("Input must be an integer.")
    .typeError("Input must be a valid number.")
    .min(1, "minimum number of row can be 1")
    .max(30, "maximum number of row can be 30")
    .required(requiredValidationMsg("Number of rows")),
});

export const cellEditValidationSchema = yup.object().shape({
  cellValue: yup
    .string()
    .trim(trimValidationMsg("Cell value"))
    .strict()
    .required("Cell value is required."),
});

export const specDataScopeTextValidationSchema = yup.object().shape({
  textValue: yup
    .string()
    .trim(trimValidationMsg("Value"))
    .strict()
    .required(requiredValidationMsg("Value")),
});

export const specDataScopeSelectValidationSchema = yup.object().shape({
  selectValue: yup.object().required(requiredValidationMsg("Value")),
});

export const statusNameValidationSchema = yup.object().shape({
  status: yup
    .string()
    .trim(trimValidationMsg("Status Name"))
    .strict()
    .required(requiredValidationMsg("Status Name")),
});

export const priorityNameValidationSchema = yup.object().shape({
  priority: yup
    .string()
    .trim(trimValidationMsg("Priority Name"))
    .strict()
    .required(requiredValidationMsg("Priority Name")),
});

export const functionNameValidationSchema = yup.object().shape({
  function: yup
    .string()
    .trim(trimValidationMsg("Function Name"))
    .strict()
    .required(requiredValidationMsg("Function Name")),
});

export const industryNameValidationSchema = yup.object().shape({
  industry: yup
    .string()
    .trim(trimValidationMsg("Industry Name"))
    .strict()
    .required(requiredValidationMsg("Industry Name")),
});

export const dataScopeValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim(trimValidationMsg("Data scope name"))
    .strict()
    .required(requiredValidationMsg("Data scope name")),
  category: yup
    .object()
    .nullable(true)
    .required(requiredValidationMsg("Category")),
  required: yup
    .object()
    .nullable(true)
    .required(requiredValidationMsg("Required")),
  optionValues: yup.string().trim(trimValidationMsg("Values")).strict(),
  description: yup.string().trim(trimValidationMsg("Description")).strict(),
});

export const editProfileValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim(trimValidationMsg("First name"))
    .strict()
    .required(requiredValidationMsg("First name")),
  lastName: yup
    .string()
    .trim(trimValidationMsg("Last name"))
    .strict()
    .required(requiredValidationMsg("Last name")),
  companyName: yup
    .string()
    .trim(trimValidationMsg("Company name"))
    .strict()
    .required(requiredValidationMsg("Company name")),
  position: yup
    .string()
    .trim(trimValidationMsg("Position"))
    .strict()
    .required(requiredValidationMsg("Position")),
  department: yup
    .string()
    .trim(trimValidationMsg("Department"))
    .strict()
    .required(requiredValidationMsg("Department")),
  email: yup
    .string()
    .matches(REGEX.EMAIL, emailValidationMsg)
    .required(requiredValidationMsg("Email")),
  role: yup.object().nullable(true).required(requiredValidationMsg("Role")),
});

export const registrationValidationSchema1 = yup.object().shape({
  firstName: yup
    .string()
    .trim(trimValidationMsg("First name"))
    .strict()
    .required(requiredValidationMsg("First name")),
  lastName: yup
    .string()
    .trim(trimValidationMsg("Last name"))
    .strict()
    .required(requiredValidationMsg("Last name")),
  companyName: yup
    .string()
    .trim(trimValidationMsg("Company name"))
    .strict()
    .required(requiredValidationMsg("Company name")),
  subdomain: yup
    .string()
    .trim(trimValidationMsg("Subdomain"))
    .strict()
    .required(requiredValidationMsg("Subdomain")),
  jobTitle: yup
    .string()
    .trim(trimValidationMsg("Job title"))
    .strict()
    .required(requiredValidationMsg("Job title")),
  department: yup
    .string()
    .trim(trimValidationMsg("Department"))
    .strict()
    .required(requiredValidationMsg("Department")),
  email: yup
    .string()
    .matches(REGEX.EMAIL, emailValidationMsg)
    .required(requiredValidationMsg("Email")),
  password: yup
    .string()
    .matches(REGEX.PASSWORD, passwordValidationMsg)
    .required(requiredValidationMsg("Password")),
  confirmPassword: yup
    .string()
    .required(requiredValidationMsg("Confirm password"))
    .oneOf([yup.ref("password")], confirmPassValidationMsg),
});

export const registrationValidationSchema2 = yup.object().shape({
  firstName: yup
    .string()
    .trim(trimValidationMsg("First name"))
    .strict()
    .required(requiredValidationMsg("First name")),
  lastName: yup
    .string()
    .trim(trimValidationMsg("Last name"))
    .strict()
    .required(requiredValidationMsg("Last name")),
  companyName: yup
    .string()
    .trim(trimValidationMsg("Company name"))
    .strict()
    .required(requiredValidationMsg("Company name")),
  subdomain: yup
    .string()
    .trim(trimValidationMsg("Subdomain"))
    .strict()
    .required(requiredValidationMsg("Subdomain")),
  jobTitle: yup
    .string()
    .trim(trimValidationMsg("Job title"))
    .strict()
    .required(requiredValidationMsg("Job title")),
  department: yup
    .string()
    .trim(trimValidationMsg("Department"))
    .strict()
    .required(requiredValidationMsg("Department")),
  email: yup
    .string()
    .matches(REGEX.EMAIL, emailValidationMsg)
    .required(requiredValidationMsg("Email")),
});

export const createNewPasswordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .matches(REGEX.PASSWORD, passwordValidationMsg)
    .required(requiredValidationMsg("Password")),
  confirmPassword: yup
    .string()
    .required(requiredValidationMsg("Confirm password"))
    .oneOf([yup.ref("password")], confirmPassValidationMsg),
});

export const kpiAndBIValidationSchema = yup.object().shape({
  kpi: yup
    .string()
    .trim(trimValidationMsg("KPI"))
    .strict()
    .required(requiredValidationMsg("KPI")),
  targetValue: yup
    .string()
    .trim(trimValidationMsg("Target Value"))
    .strict()
    .matches(
      /^\$?(?:\d{1,3}(?:,\d{3})+|\d+)(?:\.\d{2})?$/,
      "Please enter a valid value."
    )
    .required(requiredValidationMsg("Target Value")),
  businessImpact: yup
    .string()
    .trim(trimValidationMsg("Business Impact"))
    .strict()
    .required(requiredValidationMsg("Business Impact")),
});
