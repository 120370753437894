import { Skeleton, Stack } from "@mui/material";
import React from "react";

const UserStorySkeleton = () => {
  return (
    <Stack direction={"row"} columnGap={1} width={1}>
      <Stack sx={{ width: "49%" }} rowGap={2}>
        <Skeleton variant='rounded' height={50} />
        <Skeleton variant='rounded' height={50} />
        <Skeleton variant='rounded' height={50} />
      </Stack>
      <Stack sx={{ width: "49%" }}>
        <Skeleton variant='rounded' height={200} />
      </Stack>
    </Stack>
  );
};

export default UserStorySkeleton;
