export const CircularIcon = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 60 61'
      preserveAspectRatio='xMidYMid meet'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g id='chart icon / circular area chart'>
        <g id='Group 2'>
          <path id='Vector 4' d='M30 6.00244V54.0024' stroke='#808080' strokeDasharray='2 2' />
          <path id='Vector 5' d='M6 30.0024L54 30.0024' stroke='#808080' strokeDasharray='2 2' />
          <path
            id='Vector 6'
            d='M13.2002 13.2024L47.1413 47.1435'
            stroke='#808080'
            strokeDasharray='2 2'
          />
          <path
            id='Vector 7'
            d='M47.3999 13.2024L13.4588 47.1435'
            stroke='#808080'
            strokeDasharray='2 2'
          />
          <path
            id='Vector 8'
            d='M47.247 30.0127L40.8569 19.8926C40.8195 19.8332 40.7699 19.7824 40.7115 19.7435L30.2387 12.7616C30.0909 12.6631 29.902 12.6503 29.7423 12.7281L18.4524 18.2284C18.3536 18.2765 18.2734 18.3558 18.2243 18.454L12.3926 30.102C12.3429 30.2013 12.3276 30.3143 12.3492 30.4231L15.1884 44.7392C15.2474 45.0369 15.556 45.2138 15.8427 45.1143L29.8903 40.2406C29.9625 40.2156 30.0396 40.2074 30.1155 40.2168L41.1616 41.581C41.369 41.6066 41.5706 41.5004 41.6668 41.3148L47.2681 30.5097C47.3499 30.3521 47.3418 30.1629 47.247 30.0127Z'
            fill='#808080'
          />
          <path
            id='Vector 9'
            d='M41.8765 29.4603L38.2987 22.5436C38.2593 22.4675 38.201 22.4027 38.1294 22.3556L29.9374 16.9663C29.784 16.8654 29.5876 16.8566 29.4259 16.9435L21.4526 21.2276C21.3619 21.2763 21.2885 21.3519 21.2423 21.4439L16.8872 30.1281C16.831 30.2403 16.819 30.3694 16.8535 30.49L19.6522 40.256C19.7352 40.5458 20.0539 40.6968 20.3308 40.5775L29.5546 36.601C29.642 36.5633 29.7383 36.5514 29.8322 36.5665L37.1404 37.7466C37.3481 37.7802 37.5545 37.6801 37.6569 37.4963L41.8692 29.9333C41.9508 29.7869 41.9535 29.6092 41.8765 29.4603Z'
            fill='#808080'
          />
          <path
            id='Vector 10'
            d='M36.8272 29.1002L33.7457 25.9816C33.7143 25.9498 33.6787 25.9223 33.6399 25.8999L30.0532 23.831C29.9134 23.7503 29.7432 23.7421 29.5962 23.809L26.1502 25.3779C26.0788 25.4105 26.0161 25.4593 25.967 25.5206L23.0071 29.2184C22.8815 29.3753 22.8621 29.5923 22.9579 29.7691L25.1436 33.8013C25.2668 34.0285 25.5427 34.1245 25.7802 34.0226L29.6971 32.3429C29.7842 32.3056 29.88 32.2937 29.9735 32.3088L33.1256 32.8151C33.2728 32.8387 33.4229 32.7954 33.5349 32.697L36.8015 29.8273C37.0176 29.6375 37.0293 29.3049 36.8272 29.1002Z'
            fill='#808080'
          />
        </g>
      </g>
    </svg>
  );
};
