export const requiredValidationMsg = (fieldName) => {
  return `${fieldName} is a required field`;
};
export const emailValidationMsg = "Please enter a valid email address";
export const passwordValidationMsg =
  "The Password must be 8-15 characters long and must contain 1 alphabet, 1 digit, 1 special character, 1 uppercase and 1 lowercase. No spaces allowed.";
export const defaultMessage = "Something went wrong!";
export const ErrorLogoutMessage = "Error while logging out!";
export const LogoutConfirmationMessage = "Are You sure you want to logout?";
export const titleEmptyErrorMsg = "Spec title cannot be left empty.";
export const defaultTitleErrorMsg =
  "Change the spec title from the default to a descriptive one.";
export const trimValidationMsg = (fieldName) => {
  return `${fieldName} cannot include leading and trailing spaces`;
};
export const tabRenameSuccessMsg = "Tab renamed successfully!";
export const tabRenameErrorMsg = "Error while renaming tab!";
export const confirmPassValidationMsg = "Passwords do not match";
