import { DAY, MONTH, WEEK, YEAR } from "../constants";

const { parse, format, getHours, getMonth } = require("date-fns");

// ====================================================================================================
// utils for charts data transformation and generation
// ====================================================================================================

// Helper function to get the time of day label
function getTimeOfDayLabel(hour) {
  if (hour >= 0 && hour < 4) {
    return "00:00 - 03:59";
  } else if (hour >= 4 && hour < 8) {
    return "04:00 - 07:59";
  } else if (hour >= 8 && hour < 12) {
    return "08:00 - 11:59";
  } else if (hour >= 12 && hour < 16) {
    return "12:00 - 15:59";
  } else if (hour >= 16 && hour < 20) {
    return "16:00 - 19:59";
  } else {
    return "20:00 - 23:59";
  }
}

function getWeekLabel(weekNumber) {
  if (weekNumber >= 0 && weekNumber < 11) {
    return "Weeks 1-10";
  } else if (weekNumber >= 11 && weekNumber < 21) {
    return "Weeks 11-20";
  } else if (weekNumber >= 21 && weekNumber < 31) {
    return "Weeks 21-30";
  } else if (weekNumber >= 31 && weekNumber < 41) {
    return "Weeks 31-40";
  } else if (weekNumber >= 41 && weekNumber < 53) {
    return "Weeks 41-52";
  } else {
    return "Weeks 53+";
  }
}

function getMonthLabel(month) {
  const monthLabels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return monthLabels[month];
}
// ====================================================================================================
// functions for time series charts
// ====================================================================================================
export function simpleGroupByXandTime(data, type) {
  const groupedData = {};

  const variant = [...new Set(data.map((item) => item.time))].sort(
    (a, b) => a - b
  );

  data.forEach((item) => {
    const x = item.x;
    const date = item.time; // Parse the date string

    if (!groupedData[x]) {
      groupedData[x] = {};
      if (type === DAY.type) {
        groupedData[x] = {
          Sunday: 0,
          Monday: 0,
          Tuesday: 0,
          Wednesday: 0,
          Thursday: 0,
          Friday: 0,
          Saturday: 0,
        };
      } else if (type === MONTH.type) {
        groupedData[x] = {
          January: 0,
          February: 0,
          March: 0,
          April: 0,
          May: 0,
          June: 0,
          July: 0,
          August: 0,
          September: 0,
          October: 0,
          November: 0,
          December: 0,
        };
      } else if (type === YEAR.type) {
        const yearsObject = {};
        for (const year of variant) {
          yearsObject[year] = 0;
        }
        groupedData[x] = yearsObject;
      }
    }

    if (!groupedData[x][date]) {
      groupedData[x][date] = 0;
    }

    groupedData[x][date] += item.value;
  });

  return groupedData;
}

export function groupDataByXAndWeek(data) {
  const groupedData = {};

  data.forEach((item) => {
    const x = item.x;
    const weekNumber = parseInt(item.time.split(" ")[1]);

    const weekLabel = getWeekLabel(weekNumber);

    if (!groupedData[x]) {
      groupedData[x] = {
        "Weeks 1-10": 0,
        "Weeks 11-20": 0,
        "Weeks 21-30": 0,
        "Weeks 31-40": 0,
        "Weeks 41-52": 0,
      };
    }

    if (!groupedData[x][weekLabel]) {
      groupedData[x][weekLabel] = 0;
    }

    groupedData[x][weekLabel] += item.value;
  });

  return groupedData;
}

export function groupDataByXAndDayTime(data) {
  const groupedData = {};

  data.forEach((item) => {
    const x = item.x;
    // Parse the date string into a Date object using date-fns
    const date = parse(item.time, "HH:mm", new Date());
    // Get the hour from the Date object
    const hour = getHours(date);

    const timeOfDayLabel = getTimeOfDayLabel(hour);

    if (!groupedData[x]) {
      groupedData[x] = {
        "00:00 - 03:59": 0,
        "04:00 - 07:59": 0,
        "08:00 - 11:59": 0,
        "12:00 - 15:59": 0,
        "16:00 - 19:59": 0,
        "20:00 - 23:59": 0,
      };
    }

    if (!groupedData[x][timeOfDayLabel]) {
      groupedData[x][timeOfDayLabel] = 0;
    }

    groupedData[x][timeOfDayLabel] += item.value;
  });

  return groupedData;
}

export function groupDataByXAndMonth(data) {
  const groupedData = {};

  data.forEach((item) => {
    const x = item.x;
    const date = parse(item.time, "MM/dd/yyyy", new Date());
    const month = getMonth(date);

    const monthLabel = getMonthLabel(month);

    if (!groupedData[x]) {
      groupedData[x] = {
        January: 0,
        February: 0,
        March: 0,
        April: 0,
        May: 0,
        June: 0,
        July: 0,
        August: 0,
        September: 0,
        October: 0,
        November: 0,
        December: 0,
      };
    }

    if (!groupedData[x][monthLabel]) {
      groupedData[x][monthLabel] = 0;
    }

    groupedData[x][monthLabel] += item.value;
  });

  return groupedData;
}
// ====================================================================================================

export function simpleGroupByTime(data, type) {
  let groupedData = {};
  if (type === DAY.type) {
    groupedData = {
      Sunday: 0,
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
    };
  } else if (type === MONTH.type) {
    groupedData = {
      January: 0,
      February: 0,
      March: 0,
      April: 0,
      May: 0,
      June: 0,
      July: 0,
      August: 0,
      September: 0,
      October: 0,
      November: 0,
      December: 0,
    };
  } else if (type === YEAR.type) {
    const yearsObject = {};
    for (const year of [...new Set(data.map((item) => item.time))].sort(
      (a, b) => a - b
    )) {
      yearsObject[year] = 0;
    }
    groupedData = yearsObject;
  }

  data.forEach((item) => {
    const date = item.time;

    groupedData[date] += item.value;
  });

  return groupedData;
}

export function groupDataByWeek(data) {
  const groupedData = {
    "Weeks 1-10": 0,
    "Weeks 11-20": 0,
    "Weeks 21-30": 0,
    "Weeks 31-40": 0,
    "Weeks 41-52": 0,
  };

  data.forEach((item) => {
    const weekNumber = parseInt(item.time.split(" ")[1]);

    const weekLabel = getWeekLabel(weekNumber);

    groupedData[weekLabel] += item.value;
  });

  return groupedData;
}

export function groupDataByDayTime(data) {
  const groupedData = {
    "00:00 - 03:59": 0,
    "04:00 - 07:59": 0,
    "08:00 - 11:59": 0,
    "12:00 - 15:59": 0,
    "16:00 - 19:59": 0,
    "20:00 - 23:59": 0,
  };

  data.forEach((item) => {
    // Parse the date string into a Date object using date-fns
    const date = parse(item.time, "HH:mm", new Date());
    // Get the hour from the Date object
    const hour = getHours(date);

    const timeOfDayLabel = getTimeOfDayLabel(hour);

    groupedData[timeOfDayLabel] += item.value;
  });

  return groupedData;
}

export function groupDataByMonth(data) {
  const groupedData = {
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  };

  data?.forEach((item) => {
    const date = new Date(item.time); // Parse the date string to a Date object
    const monthYear = format(date, "MMMM"); // Format the date as 'YYYY-MM'

    groupedData[monthYear] += item.value;
  });

  return groupedData;
}

// ====================================================================================================

export function simpleGroupByTimeAndX(data, type, categories) {
  let groupedData = {};
  const xKeys = {};
  for (const key of [...new Set(data.map((item) => item.x))].sort(
    (a, b) => a - b
  )) {
    xKeys[key] = 0;
  }

  if (type === DAY.type) {
    groupedData = {
      Sunday: { ...xKeys },
      Monday: { ...xKeys },
      Tuesday: { ...xKeys },
      Wednesday: { ...xKeys },
      Thursday: { ...xKeys },
      Friday: { ...xKeys },
      Saturday: { ...xKeys },
    };
  } else if (type === MONTH.type) {
    groupedData = {
      January: { ...xKeys },
      February: { ...xKeys },
      March: { ...xKeys },
      April: { ...xKeys },
      May: { ...xKeys },
      June: { ...xKeys },
      July: { ...xKeys },
      August: { ...xKeys },
      September: { ...xKeys },
      October: { ...xKeys },
      November: { ...xKeys },
      December: { ...xKeys },
    };
  } else if (type === YEAR.type) {
    const yearsObject = {};
    for (const year of [...new Set(data.map((item) => item.time))].sort(
      (a, b) => a - b
    )) {
      yearsObject[year] = { ...xKeys };
    }
    groupedData = yearsObject;
  }

  data.forEach((item) => {
    const x = item.x;
    const date = item.time;

    if (!groupedData[date][x]) {
      groupedData[date][x] = 0;
    }

    groupedData[date][x] += item.value;
    groupedData[date][x] = +groupedData[date][x].toFixed(2);
  });

  return groupedData;
}

export function groupDataByWeekAndX(data) {
  const xKeys = {};
  for (const key of [...new Set(data.map((item) => item.x))].sort(
    (a, b) => a - b
  )) {
    xKeys[key] = 0;
  }
  const groupedData = {
    "Weeks 1-10": { ...xKeys },
    "Weeks 11-20": { ...xKeys },
    "Weeks 21-30": { ...xKeys },
    "Weeks 31-40": { ...xKeys },
    "Weeks 41-52": { ...xKeys },
  };

  data.forEach((item) => {
    const x = item.x;
    const weekNumber = parseInt(item.time.split(" ")[1]);

    const weekLabel = getWeekLabel(weekNumber);

    if (!groupedData[weekLabel][x]) {
      groupedData[weekLabel][x] = 0;
    }

    groupedData[weekLabel][x] += item.value;
    groupedData[weekLabel][x] = +groupedData[weekLabel][x].toFixed(2);
  });

  return groupedData;
}

export function groupDataByDayTimeAndX(data) {
  const xKeys = {};
  for (const key of [...new Set(data.map((item) => item.x))].sort(
    (a, b) => a - b
  )) {
    xKeys[key] = 0;
  }
  const groupedData = {
    "00:00 - 03:59": { ...xKeys },
    "04:00 - 07:59": { ...xKeys },
    "08:00 - 11:59": { ...xKeys },
    "12:00 - 15:59": { ...xKeys },
    "16:00 - 19:59": { ...xKeys },
    "20:00 - 23:59": { ...xKeys },
  };

  data.forEach((item) => {
    const x = item.x;
    // Parse the date string into a Date object using date-fns
    const date = parse(item.time, "HH:mm", new Date());
    // Get the hour from the Date object
    const hour = getHours(date);

    const timeOfDayLabel = getTimeOfDayLabel(hour);

    groupedData[timeOfDayLabel][x] += item.value;
    groupedData[timeOfDayLabel][x] = +groupedData[timeOfDayLabel][x].toFixed(2);
  });
  return groupedData;
}

export function groupDataByMonthAndX(data) {
  const xKeys = {};
  for (const key of [...new Set(data.map((item) => item.x))].sort(
    (a, b) => a - b
  )) {
    xKeys[key] = 0;
  }

  const groupedData = {
    January: { ...xKeys },
    February: { ...xKeys },
    March: { ...xKeys },
    April: { ...xKeys },
    May: { ...xKeys },
    June: { ...xKeys },
    July: { ...xKeys },
    August: { ...xKeys },
    September: { ...xKeys },
    October: { ...xKeys },
    November: { ...xKeys },
    December: { ...xKeys },
  };

  data.forEach((item) => {
    const x = item.x;
    const date = parse(item.time, "MM/dd/yyyy", new Date());
    const month = getMonth(date);

    const monthLabel = getMonthLabel(month);

    if (!groupedData[monthLabel][x]) {
      groupedData[monthLabel][x] = 0;
    }

    groupedData[monthLabel][x] += item.value;
    groupedData[monthLabel][x] = +groupedData[monthLabel][x].toFixed(2);
  });

  return groupedData;
}
