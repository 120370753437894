import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_OK } from "../../constants/statusCodes";
import { defaultMessage } from "../../constants/messages";
import { defaultExceptionHandler } from "../../utils/defaultExceptionHandler";
import { getDataScopes, getspecDataScopes } from "../../services";
import { PURGE } from "redux-persist";

const initialState = {
  // SPEC DATASCOPE STATES
  specDataScopes: null,
  specDataScopeLoading: false,
  isSetSpecDSModalOpen: false,
  specDSData: null,

  isDataScopeAddEditOpen: false,
  dataScopeAddEditData: null,
  dataScopeAddEditType: "ADD",
};

export const getSpecDataScopesAsync = createAsyncThunk(
  "dataScope/getSpecDataScopesAsync",
  async (payload) => {
    try {
      const response = await getspecDataScopes({ specId: payload?.specId });

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

export const getDataScopesAsync = createAsyncThunk(
  "dataScope/getDataScopesAsync",
  async (payload) => {
    try {
      const response = await getDataScopes();

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

const dataScopeSlice = createSlice({
  name: "dataScope",
  initialState: initialState,

  reducers: {
    resetSpecDataScopes: (state, action) => {
      state.specDataScopes = null;
      state.specDataScopeLoading = false;
    },
    setSpecDataScopeModal: (state, action) => {
      state.isSetSpecDSModalOpen = action.payload.isSetSpecDSModalOpen;
      state.specDSData = action.payload.specDSData;
    },
    unsetSpecDataScopeModal: (state, action) => {
      state.isSetSpecDSModalOpen = false;
      state.specDSData = null;
    },

    setDataScopeAddEditModal: (state, action) => {
      state.dataScopeAddEditData = action?.payload?.dataScopeAddEditData
        ? action?.payload?.dataScopeAddEditData
        : null;
      state.isDataScopeAddEditOpen = true;
      state.dataScopeAddEditType = action?.payload?.dataScopeAddEditType
        ? action.payload.dataScopeAddEditType
        : "ADD";
    },
    resetDataScopeAddEditModal: (state, action) => {
      state.dataScopeAddEditData = null;
      state.isDataScopeAddEditOpen = false;
      state.dataScopeAddEditType = "ADD";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
    builder.addCase(getSpecDataScopesAsync.pending, (state, action) => {
      state.specDataScopeLoading = true;
    });
    builder.addCase(getSpecDataScopesAsync.fulfilled, (state, action) => {
      state.specDataScopes = action.payload.dataScopes;
      state.specDataScopeLoading = false;
    });
    builder.addCase(getSpecDataScopesAsync.rejected, (state) => {
      state.specDataScopeLoading = false;
    });
    //
    builder.addCase(getDataScopesAsync.pending, (state, action) => {
      state.specDataScopeLoading = true;
    });
    builder.addCase(getDataScopesAsync.fulfilled, (state, action) => {
      state.specDataScopes = action.payload.dataScopes;
      state.specDataScopeLoading = false;
    });
    builder.addCase(getDataScopesAsync.rejected, (state) => {
      state.specDataScopeLoading = false;
    });
  },
});

export const {
  resetSpecDataScopes,
  setSpecDataScopeModal,
  unsetSpecDataScopeModal,
  setDataScopeAddEditModal,
  resetDataScopeAddEditModal,
} = dataScopeSlice.actions;
export default dataScopeSlice.reducer;
