import _ from "lodash";

export const getIndustriesSettingsRows = (industries) => {
  return industries?.map((industry) => {
    return {
      id: industry?.id,
      title: industry?.title,
    };
  });
};
