import { getIndustries } from "../../services";
import { HTTP_OK } from "../../constants/statusCodes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultExceptionHandler } from "../../utils/defaultExceptionHandler";

const initialState = {
  // INDUSTRIES STATES
  specIndustries: [],
  isIndustriesLoading: false,
  isIndustryAddEditOpen: false,
  industryAddEditData: null,
  industryAddEditType: "ADD",
};

export const getIndustriesAsync = createAsyncThunk(
  "industries/getIndustriesAsync",
  async (payload) => {
    try {
      const response = await getIndustries();

      if (response?.status !== HTTP_OK) {
        throw new Error("Something went wrong!");
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

const industriesSlice = createSlice({
  name: "industries",
  initialState: initialState,

  reducers: {
    setIndustryAddEditModal: (state, action) => {
      state.industryAddEditData = action?.payload?.industryAddEditData
        ? action?.payload?.industryAddEditData
        : null;
      state.isIndustryAddEditOpen = true;
      state.industryAddEditType = action?.payload?.industryAddEditType
        ? action.payload.industryAddEditType
        : "ADD";
    },
    resetIndustryAddEditModal: (state, action) => {
      state.industryAddEditData = null;
      state.isIndustryAddEditOpen = false;
      state.industryAddEditType = "ADD";
    },
  },

  extraReducers: (builder) => {
    // GET INDUSTRIES BUILDER FUNCTIONS
    builder.addCase(getIndustriesAsync.pending, (state, action) => {
      state.isIndustriesLoading = true;
    });
    builder.addCase(getIndustriesAsync.fulfilled, (state, action) => {
      state.isIndustriesLoading = false;
      state.specIndustries = action?.payload?.specIndustries;
    });
    builder.addCase(getIndustriesAsync.rejected, (state) => {
      state.isIndustriesLoading = false;
    });
  },
});

export const { setIndustryAddEditModal, resetIndustryAddEditModal } =
  industriesSlice.actions;
export default industriesSlice.reducer;
