export const Stacked100ColumnIcon = () => {
  return (
    <svg
      width='100%'
      height='100%'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 60 61'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g id='chart icon / stacked 100% column chart'>
        <g id='Group 10'>
          <g id='Group 7'>
            <rect
              id='Rectangle 14'
              x='22.0503'
              y='15.8933'
              width='5.85'
              height='14.784'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 17'
              x='22.0503'
              y='13.2053'
              width='5.85'
              height='6'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 15'
              x='22.0503'
              y='30.6772'
              width='5.85'
              height='9.856'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 16'
              x='22.0503'
              y='40.5332'
              width='5.85'
              height='6.272'
              rx='0.5'
              fill='#808080'
            />
          </g>
          <g id='Group 6'>
            <rect
              id='Rectangle 14_2'
              x='40.9502'
              y='17.2173'
              width='5.85'
              height='8.52537'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 18'
              x='40.9502'
              y='13.2053'
              width='5.85'
              height='6'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 15_2'
              x='40.9502'
              y='25.7427'
              width='5.85'
              height='8.52537'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 17_2'
              x='40.9502'
              y='33.0681'
              width='5.85'
              height='8.4'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 16_2'
              x='40.9502'
              y='40.7874'
              width='5.85'
              height='6.01791'
              rx='0.5'
              fill='#808080'
            />
          </g>
          <g id='Group 8'>
            <rect
              id='Rectangle 14_3'
              x='31.5'
              y='17.1582'
              width='5.85'
              height='11.2'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 18_2'
              x='31.5'
              y='13.2053'
              width='5.85'
              height='6'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 17_3'
              x='31.5'
              y='33.668'
              width='5.85'
              height='7.2'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 19'
              x='31.5'
              y='24.4053'
              width='5.85'
              height='9.88235'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 16_3'
              x='31.5'
              y='40.7876'
              width='5.85'
              height='6.01791'
              rx='0.5'
              fill='#808080'
            />
          </g>
          <g id='Group 9'>
            <rect
              id='Rectangle 14_4'
              x='12.6001'
              y='17.1582'
              width='5.85'
              height='11.2'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 18_3'
              x='12.6001'
              y='13.2053'
              width='5.85'
              height='6'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 17_4'
              x='12.6001'
              y='33.6682'
              width='5.85'
              height='7.2'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 19_2'
              x='12.6001'
              y='24.4053'
              width='5.85'
              height='9.88235'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 16_4'
              x='12.6001'
              y='40.7876'
              width='5.85'
              height='6.01791'
              rx='0.5'
              fill='#808080'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
