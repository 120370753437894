import _ from "lodash";

export const getMappingsSettingsRows = (mappingColumns) => {
  return mappingColumns?.map((column) => {
    return {
      id: column?.id,
      title: column?.name,
      values:
        column?.kind === "system" &&
        column?.originalKey !== "businessTermCategory"
          ? "User Input"
          : column?.values?.map((value) => value?.name).join(", "),
      options: column?.kind === "system" ? "Default" : "Custom",
      kind: column?.kind,
      originalKey: column?.originalKey,
      valuesArray: column?.values,
    };
  });
};
