import { Grid, Stack, Typography } from "@mui/material";
import React from "react";

// ======
import Effort from "../../assets/icons/use-case/effort.svg";
import Function from "../../assets/icons/use-case/function.svg";
import Job from "../../assets/icons/use-case/job.svg";
import NeededBy from "../../assets/icons/use-case/neededBy.svg";
import NeededFor from "../../assets/icons/use-case/neededFor.svg";
import Requester from "../../assets/icons/use-case/requester.svg";
import Status from "../../assets/icons/use-case/status.svg";
import Urgency from "../../assets/icons/use-case/urgency.svg";
import { useSelector } from "react-redux";
import { InfoItem } from "./style";
import { LEVELS as LEVEL, STATUS_DOC_LABEL } from "../../constants";
import { convertDateToCustomFormat } from "../../utils/convertData";
// ======

const showLevel = (level) => {
  switch (level) {
    case LEVEL.LOW:
      return LEVEL.LOW_LABEL;
    case LEVEL.MEDIUM:
      return LEVEL.MEDIUM_LABEL;
    case LEVEL.HIGH:
      return LEVEL.HIGH_LABEL;
    default:
      return "-";
  }
};
const RequestInformationPreview = () => {
  const { specData } = useSelector((state) => state?.spec);

  return (
    <Stack rowGap={4}>
      <Typography variant='spec_h3'>Requester Information</Typography>
      <Stack pl={3}>
        <Grid container spacing={2} columns={16}>
          {specData.specRequest?.requester?.firstName &&
            specData.specRequest?.requester?.lastName && (
              <Grid item xs={16} md={8} lg={4}>
                <InfoItem>
                  <img src={Requester} alt='icon' />
                  <Stack direction='column' rowGap={0.5}>
                    <Typography
                      variant='spec_body'
                      style={{ color: "#8C8C8C" }}>
                      Requester
                    </Typography>
                    <Typography variant='spec_h3'>{`${
                      specData.specRequest?.requester?.firstName || ""
                    } ${
                      specData.specRequest?.requester?.lastName || "-"
                    }`}</Typography>
                  </Stack>
                </InfoItem>
              </Grid>
            )}
          {specData.specRequest?.requester?.jobTitle && (
            <Grid item xs={16} md={8} lg={4}>
              <InfoItem>
                <img src={Job} alt='icon' />
                <Stack direction='column'>
                  <Typography variant='spec_body' style={{ color: "#8C8C8C" }}>
                    Job Title:
                  </Typography>
                  <Typography variant='spec_h3'>
                    {specData.specRequest?.requester?.jobTitle || "-"}
                  </Typography>
                </Stack>
              </InfoItem>
            </Grid>
          )}
          {specData.status && (
            <Grid item xs={16} md={8} lg={4}>
              <InfoItem>
                <img src={Status} alt='icon' />
                <Stack direction='column'>
                  <Typography variant='spec_body' style={{ color: "#8C8C8C" }}>
                    Status:
                  </Typography>
                  <Typography variant='spec_h3'>
                    {STATUS_DOC_LABEL[specData.status]}
                  </Typography>
                </Stack>
              </InfoItem>
            </Grid>
          )}
          {specData.specFunction && (
            <Grid item xs={16} md={8} lg={4}>
              <InfoItem>
                <img src={Function} alt='icon' />
                <Stack direction='column'>
                  <Typography variant='spec_body' style={{ color: "#8C8C8C" }}>
                    Function:
                  </Typography>
                  <Typography variant='spec_h3'>
                    {specData.specFunction || "-"}{" "}
                  </Typography>
                </Stack>
              </InfoItem>
            </Grid>
          )}

          {specData.specRequest?.neededFor && (
            <Grid item xs={16} md={8} lg={4}>
              <InfoItem>
                <img src={NeededFor} alt='icon' />
                <Stack direction='column'>
                  <Typography variant='spec_body' style={{ color: "#8C8C8C" }}>
                    Purpose:
                  </Typography>
                  <Typography variant='spec_h3'>
                    {specData.specRequest?.neededFor || "-"}
                  </Typography>
                </Stack>
              </InfoItem>
            </Grid>
          )}
          {specData.specRequest?.neededBy && (
            <Grid item xs={16} md={8} lg={4}>
              <InfoItem>
                <img src={NeededBy} alt='icon' />
                <Stack direction='column'>
                  <Typography variant='spec_body' style={{ color: "#8C8C8C" }}>
                    Due Date:
                  </Typography>
                  <Typography variant='spec_h3'>
                    {specData.specRequest?.neededBy
                      ? convertDateToCustomFormat(
                          specData.specRequest?.neededBy
                        )
                      : "-"}
                  </Typography>
                </Stack>
              </InfoItem>
            </Grid>
          )}

          {specData.specRequest?.urgency && (
            <Grid item xs={16} md={8} lg={4}>
              <InfoItem>
                <img src={Urgency} alt='icon' />
                <Stack direction='column'>
                  <Typography variant='spec_body' style={{ color: "#8C8C8C" }}>
                    Urgency:
                  </Typography>
                  <Typography variant='spec_h3'>
                    {showLevel(specData.specRequest?.urgency)}
                  </Typography>
                </Stack>
              </InfoItem>
            </Grid>
          )}
          {specData.specRequest?.effort && (
            <Grid item xs={16} md={8} lg={4}>
              <InfoItem>
                <img src={Effort} alt='icon' />
                <Stack direction='column'>
                  <Typography variant='spec_body' style={{ color: "#8C8C8C" }}>
                    Effort:
                  </Typography>
                  <Typography variant='spec_h3'>
                    {showLevel(specData.specRequest?.effort)}
                  </Typography>
                </Stack>
              </InfoItem>
            </Grid>
          )}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default RequestInformationPreview;
