import React, { useEffect, useState } from "react";
import { getTemplates } from "../../services";
import { useTheme } from "@emotion/react";
import {
  Box,
  CircularProgress,
  Grid,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import TemplateCard from "../../components/TemplateCard";
import { specPrimaryGray } from "../../constants/theme/colors";
import { SearchOutlined } from "@mui/icons-material";
import styled from "@emotion/styled";
import { FiltersChips } from "../../components/Filters/chips";
import { useDispatch, useSelector } from "react-redux";
import { getIndustriesAsync } from "../../store/slices/industries";
import { getFunctionsAsync } from "../../store/slices/functions";
import _ from "lodash";
import { Filter } from "../../components/Filters";
import { InputField } from "../../common/InputField";
import NoRowsOverlayCustomComponent from "../../common/NoRowsOverlayCustomComponent";

const Bar = styled("div")(() => ({
  padding: "3px 24px 3px 4px",
  backgroundColor: "#FFF",
  border: "1px solid #ffffff",
  borderBottomColor: "#dddddd",
  borderTopColor: "#dddddd",
  overflowY: "hidden",
  overflowX: "auto",
  scrollbarWidth: "none",
  "::-webkit-scrollbar": {
    display: "none",
  },
}));

const recordsPerPage = 20;

const initialState = {
  function: { options: [], active: new Map() },
  industry: { options: [], active: new Map() },
};

const Templates = () => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [pageLoader, setPageLoader] = useState(false);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [templateList, setTemplateList] = useState([]);
  const [filters, setFilters] = useState(initialState);
  // const filters = Object.entries(filtersData);
  const startIndex = (page - 1) * recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const isFilterApplied = Object.entries(filters).some(([, filter]) =>
    Boolean(filter.active.size)
  );

  useEffect(() => {
    dispatch(getIndustriesAsync());
    dispatch(getFunctionsAsync({ admin: true }));
  }, []);

  useEffect(() => {
    initalizeFilters();
  }, [reduxState?.functions, reduxState?.industries]);

  useEffect(() => {
    if (searchString) {
      const filteredData = templateList.filter(
        (template) =>
          template.specFunction
            ?.toLowerCase()
            .includes(searchString.toLowerCase()) ||
          template.specIndustry?.title
            ?.toLowerCase()
            .includes(searchString.toLowerCase()) ||
          template.title?.toLowerCase().includes(searchString.toLowerCase()) ||
          template.businessNeed?.persona
            ?.toLowerCase()
            .includes(searchString.toLowerCase()) ||
          template.businessNeed?.action
            ?.toLowerCase()
            .includes(searchString.toLowerCase()) ||
          template.businessNeed?.outcome
            ?.toLowerCase()
            .includes(searchString.toLowerCase())
      );
      setFilteredTemplates(filteredData);
      setPage(1);
    } else {
      setFilteredTemplates(templateList);
    }
  }, [searchString, templateList]);

  const displayedRecords = filteredTemplates.slice(startIndex, endIndex);
  const pageCount = Math.ceil(filteredTemplates?.length / recordsPerPage);

  useEffect(() => {
    setPageLoader(true);

    const getKeys = (filter) => Array.from(filter?.active?.keys());

    getTemplates({
      industryIds: getKeys(filters.industry),
      functionIds: getKeys(filters.function),
    })
      .then((response) => {
        setPageLoader(false);

        setTemplateList(response?.data?.templates);
      })
      .catch((error) => {
        setPageLoader(false);
      });
  }, [filters]);

  const onChangeFilters = (type, option) => {
    let appliedFilters = { ...filters };
    if (appliedFilters[type].active.has(option.id)) {
      appliedFilters[type].active.delete(option.id);
    } else {
      appliedFilters[type].active.set(option.id, option.label);
    }
    setFilters(appliedFilters);
  };

  const initalizeFilters = () => {
    let industries = reduxState?.industries?.specIndustries ?? [];
    let functions = reduxState?.functions?.specFunctions ?? [];
    const industriesOptions = industries?.map((option) => ({
      id: option.id,
      label: option.title,
    }));

    const functionOptions = functions?.map((option) => ({
      id: option.id,
      label: option.tag,
    }));
    setFilters({
      function: { options: functionOptions, active: new Map() },
      industry: { options: industriesOptions, active: new Map() },
    });
  };
  const isTemplates = displayedRecords?.length > 0;

  return (
    <Box
      sx={{
        height: "calc(100vh - 45px)",
        backgroundColor: specPrimaryGray["backgroundGray"],
        width: "100%",
      }}>
      <Bar>
        <Stack
          direction='row'
          spacing={2}
          justifyContent='space-between'
          alignItems='center'>
          <Stack sx={{ width: 300 }}>
            <InputField
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              className='searchInput'
              sx={{ width: { xs: 200, sm: 300 } }}
              placeholder='Search for specs by title'
              startAdornment={
                <SearchOutlined fontSize='small' style={{ color: "#424242" }} />
              }
            />
          </Stack>
          <Box display='flex' alignItems='center'>
            <Box sx={{ mr: 1 }}>
              <FiltersChips
                filters={Object.entries(filters)}
                clearAllFilters={() => initalizeFilters()}
              />
            </Box>
            <Stack
              direction='row'
              mr={0}
              gap={2}
              alignItems='center'
              flexWrap='nowrap'>
              {/* {Object.entries(filters).map(([type, filter]) => (
                <CustomSelectField
                  placeholder={_.capitalize(type)}
                  key={type}
                  value={filter.active}
                  options={filter.options}
                  onChange={(option) => onChangeFilters(type, option)}
                />
              ))} */}
              {Object.entries(filters).map(([type, filter]) => (
                <Filter
                  key={type}
                  title={type}
                  activeOptions={filter.active}
                  options={filter.options}
                  onOptionClick={(option) => onChangeFilters(type, option)}
                />
              ))}
            </Stack>
          </Box>
        </Stack>
      </Bar>
      {!pageLoader ? (
        <>
          <Stack
            sx={{
              pt: isTemplates ? 3 : "unset",
              px: isTemplates ? 3.5 : "unset",
              pb: isTemplates ? 3 : "unset",
              justifyContent: { xs: "center", sm: "center", md: "flex-start" },
              display: "flex",
              flexDirection: "row",
              height: isTemplates ? `calc(100vh - 137px)` : 1,
              flexWrap: "wrap",
              rowGap: 2,
              columnGap: 3,
              overflowY: "auto",
            }}>
            {isTemplates ? (
              displayedRecords.map((template) => (
                <TemplateCard key={template?.id} template={template} />
              ))
            ) : (
              <Stack
                sx={{
                  height: 1,
                  width: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <NoRowsOverlayCustomComponent
                  noRowsMessageFunc={() => {
                    if (searchString || isFilterApplied) {
                      return "No Templates found for the provided filter or search term.";
                    } else {
                      return "There are no Templates available at this time.";
                    }
                  }}
                />
              </Stack>
            )}
          </Stack>
          {isTemplates && pageCount > 1 && (
            <Grid
              container
              alignItems='center'
              justifyContent='center'
              sx={{ width: "auto" }}>
              <Grid item>
                <Pagination
                  count={pageCount}
                  page={page}
                  // onChange={handleChangePage}
                  color='primary'
                  variant='combined'
                  showFirstButton
                  showLastButton
                />
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <Stack
          sx={{
            height: 1,
            justifyContent: "center",
            alignItems: "center",
          }}>
          <CircularProgress
            color='primary'
            size={50}
            sx={{
              display: "flex",
            }}
          />
        </Stack>
      )}
    </Box>
  );
};

export default Templates;
