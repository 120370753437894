export const ScatterIcon = () => {
  return (
    <svg
      width='100%'
      height='100%'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 60 61'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx='19.65' cy='23.9949' r='2.7' fill='#808080' />
      <circle cx='31.35' cy='34.345' r='2.7' fill='#808080' />
      <circle cx='34.05' cy='26.6951' r='2.7' fill='#808080' />
      <ellipse cx='42.1499' cy='25.345' rx='2.7' ry='2.7' fill='#808080' />
      <ellipse cx='43.5' cy='17.2449' rx='2.7' ry='2.7' fill='#808080' />
      <circle cx='34.05' cy='19.9451' r='2.7' fill='#808080' />
      <circle cx='23.7' cy='34.345' r='2.7' fill='#808080' />
      <ellipse cx='19.65' cy='41.095' rx='2.7' ry='2.7' fill='#808080' />
      <circle cx='27.3' cy='26.6951' r='2.7' fill='#808080' />
      <path
        d='M12 12.2949V47.7949C12 48.0711 12.2239 48.2949 12.5 48.2949H48'
        stroke='#808080'
        strokeDasharray='2 2'
      />
    </svg>
  );
};
