export const ROUTE_PATH = {
  LOGIN: "/auth/login",
  OAUTHCALLBACK: "/auth/oauth_callback",
  FORGOT_PASSWORD: "/auth/forgot-password",
  THEMEDEMO: "/theme-demo",
  SPECS: "/specs",
  SPEC: `/specs/spec/:specId/`,
  BUSINESS_REQUIREMETS: `business-requirements`,
  MAPPINGS_LIBRARY: "/business-terms",
  TEMPLATES: "/templates",
  ACCOUNT: "/account",
  USER_MGT: "/manage/user-management",
  EDIT_USER: "/manage/user-management/:userId/edit",
  BILLING: "/account/billing",
  DATA_SETTINGS: "/data-settings",
  MOCKDATASET: "mockdataset",
  TASKS: "tasks",
  VISUAL_MOCKUP: "visual-mockup",
  SPEC_MAPPINGS: "spec-mappings",
  DATA_SCOPE: "/data-settings/data-scope",
  MAPPINGS: "/data-settings/business-terms",
  FUNCTIONS: "/data-settings/functions",
  STATUSES: "/data-settings/statuses",
  PRIORITY: "/data-settings/priority",
  INDUSTIRES: "data-settings/industries",
  // SPEC_PREVIEW: "view",
  MY_PROFILE: "/account/my-profile",
  CHANGE_PASSWORD: "/account/change-password",
  EDIT_PROFILE: "/account/my-profile/edit-profile",
  ACCEPT_INVITATION: "/auth/accept-invitation/:token",
  CREATE_NEW_PASSWORD: "/auth/create-new-password/:token",
  ZENDESK: "/access/zendesk",
  FEEDBACK: "/access/feedback",
  SPEC_PREVIEW: "/spec-preview",
  SPEC_PREVIEW_ID: "/specs/spec-preview/:specId",
};

export const getSpecPreviewRoute = (specId) => {
  return `/specs/spec-preview/${specId}/`;
};
