import React, { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  matchPath,
  useLocation,
} from "react-router-dom";
import OnboardingLayout from "../layouts/OnboardingLayout";
import { ROUTE_PATH } from "./routes";
import Login from "../pages/Login";
import ThemeDemo from "../constants/theme/ThemeDemo";
import ForgotPassword from "../pages/ForgotPassword";
import AppLayout from "../layouts/AppLayout";
import MappingsLibrary from "../pages/MappingsLibrary";
import Templates from "../pages/Templates";
import SpecsListing from "../pages/SpecsListing";
import VisualMockup from "../pages/VisualMockup";
import Profile from "../pages/Profile";
import UserManagement from "../pages/UserManagement";
import Billing from "../pages/Billing";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn } from "../store/slices/userAuth";
import { persistor } from "../store";
import storage from "redux-persist/lib/storage";
import SpecLayout from "../layouts/SpecLayout";
import BusinessRequirements from "../pages/BusinessRequirements";
import Error404 from "../pages/Error404";
import MockDataset from "../pages/MockDataset";
import DataSettingsLayout from "../layouts/DataSettingsLayout";
import MappingsSettings from "../pages/DataSettings/MappingsSettings";
import FunctionsSettings from "../pages/DataSettings/FunctionsSettings";
import StatusesSettings from "../pages/DataSettings/StatusesSettings";
import IndustriesSettings from "../pages/DataSettings/IndustriesSettings";
import SpecPreview from "../pages/SpecPreview";
import DataScopeSettings from "../pages/DataSettings/DataScopeSettings";
import AccountLayout from "../layouts/AccountLayout";
import { ChangePassword } from "../components/ChangePassword";
import { ProfileEdit } from "../pages/Profile/ProfileEdit";
import UserEdit from "../pages/UserManagement/UserEdit";
import AcceptInvitation from "../pages/AcceptInvitation";
import SpecMappings from "../pages/SpecMappings";
import SocialAuth from "../pages/SocialAuth";
import CreateNewPassword from "../pages/CreateNewPassword";
import ZendeskJwt from "../components/ZendeskJwt";
import PrioritiesSettings from "../pages/DataSettings/PrioritiesSettings";
import SpecPreviewLayout from "../layouts/SpecPreviewLayout";

const AppRouter = () => {
  const dispatch = useDispatch();
  const { isLoggedIn = false } = useSelector((state) => state.userAuth);
  const location = useLocation();
  const userAuthState = useSelector((state) => state.userAuth);

  // // Example condition based on location pathname
  // const isSpecPreviewRoute = location.pathname.includes(
  //   ROUTE_PATH.SPEC_PREVIEW
  // );

  const isAcceptInvitation = matchPath(
    { path: ROUTE_PATH.ACCEPT_INVITATION, exact: true },
    location.pathname
  );

  const isOAuthVerification = matchPath(
    { path: ROUTE_PATH.OAUTHCALLBACK, exact: true },
    location.pathname
  );

  useEffect(() => {
    if (Cookies.get("token") && Cookies.get("subdomain")) {
      dispatch(setIsLoggedIn(true));
    } else {
      dispatch(setIsLoggedIn(false));
      persistor.purge();
      storage.removeItem("persist:root");
      localStorage.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoggedIn && !isAcceptInvitation && !isOAuthVerification ? (
        <Routes>
          <Route element={<AppLayout />}>
            <Route path={ROUTE_PATH.ZENDESK} exact element={<ZendeskJwt />} />
            <Route
              path={ROUTE_PATH.FEEDBACK}
              exact
              element={<ZendeskJwt isFeedback={true} />}
            />
            <Route path={ROUTE_PATH.THEMEDEMO} exact element={<ThemeDemo />} />
            <Route path={ROUTE_PATH.SPECS} exact element={<SpecsListing />} />
            {/* ThemeDemo consists of all reusable components and their variants along with theme palette & typography */}
            <Route path={ROUTE_PATH.THEMEDEMO} exact element={<ThemeDemo />} />
            {/* <Route path={ROUTE_PATH.SPECS} element={<SpecsListing />} /> */}
            {/* Add routes in array which should point to 404 Page */}
            {["/specs/spec/"].map((path, i) => (
              <Route path={path} element={<Error404 />} key={i} />
            ))}
            <Route path={ROUTE_PATH.ACCOUNT} element={<AccountLayout />}>
              <Route path={ROUTE_PATH.MY_PROFILE} exact element={<Profile />} />
              <Route
                path={ROUTE_PATH.EDIT_PROFILE}
                exact
                element={<ProfileEdit />}
              />
              <Route path={ROUTE_PATH.BILLING} exact element={<Billing />} />
              <Route
                path={ROUTE_PATH.CHANGE_PASSWORD}
                exact
                element={<ChangePassword />}
              />
            </Route>

            <Route
              path={ROUTE_PATH.USER_MGT}
              exact
              element={<UserManagement />}
            />

            <Route path={ROUTE_PATH.EDIT_USER} exact element={<UserEdit />} />

            <Route
              path={ROUTE_PATH.DATA_SETTINGS}
              element={<DataSettingsLayout />}>
              <Route
                path={ROUTE_PATH.DATA_SCOPE}
                exact
                element={<DataScopeSettings />}
              />
              <Route
                path={ROUTE_PATH.MAPPINGS}
                exact
                element={<MappingsSettings />}
              />
              <Route
                path={ROUTE_PATH.FUNCTIONS}
                exact
                element={<FunctionsSettings />}
              />
              <Route
                path={ROUTE_PATH.STATUSES}
                exact
                element={<StatusesSettings />}
              />
              <Route
                path={ROUTE_PATH.PRIORITY}
                exact
                element={<PrioritiesSettings />}
              />
              <Route
                path={ROUTE_PATH.INDUSTIRES}
                exact
                element={<IndustriesSettings />}
              />
            </Route>
            <Route path={ROUTE_PATH.SPEC} element={<SpecLayout />}>
              <Route
                path={""}
                element={
                  <Navigate to={ROUTE_PATH.BUSINESS_REQUIREMETS} replace />
                }
              />
              <Route
                path={ROUTE_PATH.BUSINESS_REQUIREMETS}
                exact
                element={<BusinessRequirements />}
              />
              {/* MOCKDATASETS NOT USED ANYMORE */}
              <Route
                path={ROUTE_PATH.MOCKDATASET}
                exact
                element={<MockDataset />}
              />
              <Route
                path={ROUTE_PATH.VISUAL_MOCKUP}
                exact
                element={<VisualMockup />}
              />
              <Route
                path={ROUTE_PATH.SPEC_MAPPINGS}
                exact
                element={<SpecMappings />}
              />
              {/* <Route
                path={ROUTE_PATH.SPEC_PREVIEW}
                exact
                element={<SpecPreview />}
              /> */}
            </Route>

            {/* =========== SPEC PREVIEW ========= */}
            <Route
              path={ROUTE_PATH.SPEC_PREVIEW_ID}
              element={<SpecPreviewLayout />}>
              <Route
                path={""}
                element={
                  <Navigate to={ROUTE_PATH.BUSINESS_REQUIREMETS} replace />
                }
              />
              <Route
                path={ROUTE_PATH.BUSINESS_REQUIREMETS}
                exact
                element={<BusinessRequirements />}
              />
              <Route
                path={ROUTE_PATH.MOCKDATASET}
                exact
                element={<MockDataset />}
              />
              <Route
                path={ROUTE_PATH.VISUAL_MOCKUP}
                exact
                element={<VisualMockup />}
              />
              <Route
                path={ROUTE_PATH.SPEC_MAPPINGS}
                exact
                element={<SpecMappings />}
              />
              {/* <Route
                path={ROUTE_PATH.SPEC_PREVIEW}
                exact
                element={<SpecPreview />}
              /> */}
            </Route>
            {/* ================================== */}

            <Route
              path={ROUTE_PATH.MAPPINGS_LIBRARY}
              exact
              element={<MappingsLibrary />}
            />
            <Route path={ROUTE_PATH.TEMPLATES} exact element={<Templates />} />

            {/* <Route
              path={ROUTE_PATH.DATA_SETTINGS}
              exact
              element={<DataSettings />}
            /> */}
          </Route>
          <Route
            path='*'
            exact
            element={<Navigate replace to={ROUTE_PATH.SPECS} />}
          />
        </Routes>
      ) : (
        <Routes>
          <Route element={<OnboardingLayout />}>
            <Route path={ROUTE_PATH.LOGIN} exact element={<Login />} />
            <Route
              path={ROUTE_PATH.FORGOT_PASSWORD}
              exact
              element={<ForgotPassword />}
            />
            <Route
              path={ROUTE_PATH.CREATE_NEW_PASSWORD}
              exact
              element={<CreateNewPassword />}
            />
            <Route
              path={ROUTE_PATH.ACCEPT_INVITATION}
              exact
              element={<AcceptInvitation />}
            />
            <Route
              path={ROUTE_PATH.OAUTHCALLBACK}
              exact
              element={<SocialAuth />}
            />
          </Route>

          <Route
            path='*'
            exact
            element={<Navigate replace to={ROUTE_PATH.LOGIN} />}
          />
          {/* <Route path='' element={<Navigate replace to={ROUTE_PATH.LOGIN} />} /> */}
        </Routes>
      )}
    </>
  );
};

export default AppRouter;
