import { MoreHorizRounded } from "@mui/icons-material";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import { SquareIconButton } from "../../common/commonStyle";
import CustomPopover from "../../common/CustomPopover";
import MenuItem from "../../common/MenuItem";
import { setTermModal } from "../../store/slices/businessTerms";
import { useDispatch } from "react-redux";

const ActionCell = (props) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack alignItems='center' justifyContent={"center"} width={1}>
        <SquareIconButton onClick={handleClick}>
          <MoreHorizRounded
            sx={(theme) => ({ color: theme.palette.gray.iconColor })}
          />
        </SquareIconButton>
      </Stack>
      <CustomPopover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        <MenuItem
          itemText={"Edit"}
          onClick={() => {
            handleClose();
            dispatch(
              setTermModal({
                isTermModalOpen: true,
                termModalData: props?.data,
                termModalType: "EDIT",
              })
            );
          }}
        />
      </CustomPopover>
    </>
  );
};

export default ActionCell;
