import _ from "lodash";
import {
  addDataScope,
  updateDataScope,
  getDataScopeCategories,
} from "../../../services";
import { Stack } from "@mui/material";
import {
  getDataScopesAsync,
  resetDataScopeAddEditModal,
} from "../../../store/slices/dataScopes";
import { Field, Form, Formik } from "formik";
import BaseModal from "../../../common/BaseModal";
import { toaster } from "../../../common/Toaster";
import { REQUIRED_OPTIONS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { InputField } from "../../../common/InputField";
import React, { useEffect, useRef, useState } from "react";
import CustomSelectField from "../../../common/CustomSelectField";
import { dataScopeValidationSchema } from "../../../utils/validationSchemas";

const DataScopeAddEditModal = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const { isDataScopeAddEditOpen, dataScopeAddEditData, dataScopeAddEditType } =
    useSelector((state) => state?.dataScopes);

  const [isLoading, setIsLoading] = useState(false);
  const [categoryOptionsLoading, setCategoryOptionsLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState(null);

  const handleClose = (resetForm, validateForm) => {
    dispatch(resetDataScopeAddEditModal());
    resetForm();
    validateForm();
  };

  const initialValues = {
    name: dataScopeAddEditData?.name ? dataScopeAddEditData?.name : "",
    required: dataScopeAddEditData
      ? dataScopeAddEditData?.required
        ? REQUIRED_OPTIONS[0]
        : REQUIRED_OPTIONS[1]
      : REQUIRED_OPTIONS[0],
    category: _.isEmpty(dataScopeAddEditData?.category)
      ? null
      : {
          value: dataScopeAddEditData?.category,
          label: dataScopeAddEditData?.category,
        },
    optionValues: _.isEmpty(dataScopeAddEditData?.values)
      ? ""
      : dataScopeAddEditData?.values,
    description: _.isEmpty(dataScopeAddEditData?.details)
      ? ""
      : dataScopeAddEditData?.details,
  };

  const handleChange = (field, value) => {
    field.onChange({
      target: {
        name: field.name,
        value: value,
      },
    });
  };

  const onSubmit = (values, { resetForm, validateForm }) => {
    const payload = {
      name: values?.name || "",
      category: values?.category?.value || "",
      values: values?.optionValues || "",
      details: values?.description || "",
      required: values?.required?.value === "yes",
    };

    if (dataScopeAddEditType === "ADD") {
      setIsLoading(true);
      addDataScope({
        payload: payload,
      })
        .then((response) => {
          setIsLoading(false);
          handleClose(resetForm, validateForm);

          toaster({
            type: "success",
            message:
              response?.data?.toaster?.data?.message ||
              "Data Scope was successfully created.",
          });

          dispatch(getDataScopesAsync());
        })
        .catch((e) => {
          setIsLoading(false);
          toaster({ type: "error", message: e });
        });
    } else {
      setIsLoading(true);
      updateDataScope({
        dataScopeId: dataScopeAddEditData?.id,
        payload: payload,
      })
        .then((response) => {
          dispatch(getDataScopesAsync());
          setIsLoading(false);
          handleClose(resetForm, validateForm);

          toaster({
            type: "success",
            message:
              response?.data?.toaster?.data?.message ||
              "Data scope was successfully updated.",
          });
        })
        .catch((e) => {
          setIsLoading(false);
          toaster({ type: "error", message: e });
        });
    }
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    if (isDataScopeAddEditOpen) {
      setCategoryOptionsLoading(true);
      getDataScopeCategories()
        .then((response) => {
          setCategoryOptionsLoading(false);
          setCategoryOptions(response);
        })
        .catch((error) => {
          setCategoryOptionsLoading(false);
          toaster({ type: "error", message: error });
        });
    }
  }, [isDataScopeAddEditOpen]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={dataScopeValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      initialTouched={{ title: true }}
      validateOnMount={true}
      enableReinitialize={true}
      onSubmit={onSubmit}>
      {({ touched, errors, values, isValid, resetForm, validateForm }) => (
        <Form>
          <BaseModal
            dialogWidth={"508px"}
            title={
              dataScopeAddEditType === "ADD"
                ? "Add Data Scope"
                : "Data Scope Edit"
            }
            isOpen={isDataScopeAddEditOpen}
            handleClose={() => {
              handleClose(resetForm, validateForm);
            }}
            confirmTitle={dataScopeAddEditType === "ADD" ? "Submit" : "Update"}
            handleSubmit={handleSubmitClick}
            isLoading={isLoading}
            submitDisable={errors && Object.keys(errors)?.length !== 0}>
            <Stack rowGap={2}>
              <Field name='name'>
                {({ field, form }) => (
                  <InputField
                    name='name'
                    isLabel={true}
                    label='Data Scope Name'
                    placeholder='Enter data scope name'
                    value={field.value}
                    onChange={({ currentTarget: input }) =>
                      handleChange(field, input.value)
                    }
                    onBlur={() => {
                      form.setTouched({
                        ...form.touched,
                        name: true,
                      });
                    }}
                    error={form.touched.name && Boolean(form.errors.name)}
                    helperText={form.touched.name && form.errors.name}
                  />
                )}
              </Field>
              <Stack direction={"row"} columnGap={1}>
                <Field name='category'>
                  {({ field, form }) => (
                    <CustomSelectField
                      name='category'
                      isLabel={true}
                      label='Category'
                      isMulti={false}
                      placeholder='Select or type to create category'
                      options={categoryOptions}
                      isCreatable={true}
                      optionsLoading={categoryOptionsLoading}
                      value={field.value}
                      onChange={(selectedValue) =>
                        handleChange(field, selectedValue)
                      }
                      handleOnCreateOption={(val) => {
                        const email = val?.trim();
                        const newOption = {
                          value: email,
                          label: email,
                          user: {
                            email: email,
                          },
                          __isNew__: true,
                        };

                        handleChange(field, newOption);
                      }}
                      onBlur={() => {
                        form.setTouched({
                          ...form.touched,
                          category: true,
                        });
                      }}
                      error={
                        form.touched.category && Boolean(form.errors.category)
                      }
                      helperText={form.touched.category && form.errors.category}
                    />
                  )}
                </Field>
                <Field name='required'>
                  {({ field, form }) => (
                    <CustomSelectField
                      name='required'
                      isLabel={true}
                      label='Required'
                      isMulti={false}
                      placeholder='Select required or not'
                      options={REQUIRED_OPTIONS}
                      value={field.value}
                      onChange={(selectedValue) =>
                        handleChange(field, selectedValue)
                      }
                      onBlur={() => {
                        form.setTouched({
                          ...form.touched,
                          required: true,
                        });
                      }}
                      error={
                        form.touched.required && Boolean(form.errors.required)
                      }
                      helperText={form.touched.required && form.errors.required}
                    />
                  )}
                </Field>
              </Stack>
              <Stack rowGap={2}>
                <Field name='optionValues'>
                  {({ field, form }) => (
                    <InputField
                      className={"inputHeightAuto"}
                      multiline={true}
                      rows={3}
                      name='optionValues'
                      isLabel={true}
                      label='Values'
                      placeholder='Enter values separated by comma in order to create a dropdown selector for users. Leaving it blank will allow users to enter text.'
                      value={field.value}
                      onChange={({ currentTarget: input }) =>
                        handleChange(field, input.value)
                      }
                      onBlur={() => {
                        form.setTouched({
                          ...form.touched,
                          optionValues: true,
                        });
                      }}
                      error={
                        form.touched.optionValues &&
                        Boolean(form.errors.optionValues)
                      }
                      helperText={
                        form.touched.optionValues && form.errors.optionValues
                      }
                    />
                  )}
                </Field>
                <Field name='description'>
                  {({ field, form }) => (
                    <InputField
                      className={"inputHeightAuto"}
                      multiline={true}
                      rows={3}
                      name='description'
                      isLabel={true}
                      label='Description'
                      placeholder='Enter description for the data scope'
                      value={field.value}
                      onChange={({ currentTarget: input }) =>
                        handleChange(field, input.value)
                      }
                      onBlur={() => {
                        form.setTouched({
                          ...form.touched,
                          description: true,
                        });
                      }}
                      error={
                        form.touched.description &&
                        Boolean(form.errors.description)
                      }
                      helperText={
                        form.touched.description && form.errors.description
                      }
                    />
                  )}
                </Field>
              </Stack>
            </Stack>
          </BaseModal>
        </Form>
      )}
    </Formik>
  );
};

export default DataScopeAddEditModal;
