import {
  NorthRounded,
  SouthRounded,
  UnfoldMoreRounded,
} from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import HeaderSort from "../HeaderSort";
import HeaderFilter from "../HeaderFilter";

const CustomHeaderCell = (props) => {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      width={1}>
      <Stack
        sx={{
          overflowX: "auto",
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}>
        <Stack direction={"row"} alignItems={"center"} columnGap={1}>
          <Typography
            variant='spec_body'
            sx={{ fontWeight: 600, textTransform: "capitalize" }}>
            {" "}
            {props?.column?.colDef?.headerName}
          </Typography>
          {props?.enableSorting && <HeaderSort props={props} />}
        </Stack>
      </Stack>

      <Stack alignItems={"center"} sx={{ cursor: "pointer" }}>
        {props?.column?.colDef?.filterParams?.isCustomFilter && (
          <HeaderFilter props={props} />
        )}
      </Stack>
    </Stack>
  );
};

export default CustomHeaderCell;
