import React, { useEffect, useRef, useState } from "react";
import { chartColors } from "../../SelectVisuals/utils";
import { useSelector } from "react-redux";
import { Switch } from "@mui/material";
import _ from "lodash";

const StackedColumnChart = ({
  chartData,
  xTitle,
  yTitle,
  isMultipleSeries,
  isInverted,
}) => {
  const chartRef = useRef(null);
  const containerRef = useRef(null);
  const r = useRef((Math.random() + 1).toString(36).substring(7));
  const reduxState = useSelector((state) => state?.spec);

  useEffect(() => {
    const renderChart = () => {
      setTimeout(() => {
        drawMultiSeriesChart();
      }, 150);
    };

    renderChart();
  }, [
    containerRef.current?.clientHeight,
    containerRef.current?.clientWidth,
    reduxState?.isSpecVisualDatasetLoading,
    chartData,
    isInverted,
  ]);

  const drawMultiSeriesChart = () => {
    const transformedData = convertDataToArrayForMultiSeries(
      chartData[0].dataForChart
    );
    // console.log(isInverted);
    let pushData = [];
    if (isInverted) {
      pushData = [...convertData(chartData[0])];
    } else {
      let labels = [chartData[0].xaxis];
      chartData[0].yaxis.map((axis) => {
        labels.push(axis);
        labels.push({ type: "string", role: "tooltip", p: { html: true } });
      });
      pushData = [[...labels], ...transformedData];
    }
    // window.google.charts.load("current", { packages: ["corechart", "bar"] });
    if (!reduxState?.isSpecVisualDatasetLoading)
      window.google.charts.setOnLoadCallback(drawColColors);

    function drawColColors() {
      const data = window.google.visualization.arrayToDataTable([
        // [chartData[0].xaxis, ...chartData[0].yaxis],
        ...pushData,
      ]);

      const options = {
        hAxis: {
          title: chartData[0].xaxis,
          textStyle: { fontSize: 12, fontName: "Poppins" },

          titleTextStyle: {
            italic: false,
            fontSize: 12,
            fontName: "Poppins-Bold",
          },
        },
        tooltip: { isHtml: true },
        vAxis: {
          format: "#%",
          textStyle: { fontSize: 12, fontName: "Poppins" },
          titleTextStyle: {
            italic: false,
            fontSize: 12,
            fontName: "Poppins-Bold",
          },
        },
        colors: [...chartColors],
        height: containerRef.current?.clientHeight,
        chartArea: {
          left: "8%",
          height: containerRef.current?.clientHeight - 80,
          top: "2%",
        },
        isStacked: "percent",
      };

      const chart = new window.google.visualization.ColumnChart(
        chartRef.current
      );
      chart.draw(data, options);
    }
  };

  function convertDataToArrayForMultiSeries(data) {
    let result = [];

    data?.forEach((item) => {
      let currentItem = [];
      currentItem.push(item?.xaxis);

      item?.yaxis?.forEach((yAxisItem) => {
        if (yAxisItem)
          Object.entries(yAxisItem.data).forEach(([key, value]) => {
            currentItem.push(value);

            currentItem.push(
              createCustomHTMLTooltip(item?.xaxis, value, key, yAxisItem.symbol)
            );
          });
      });

      result.push(currentItem);
    });

    return result;
  }

  function createCustomHTMLTooltip(key, value, yName, symbol) {
    const fixedValue = Number(value?.toFixed(2));
    const newValue = fixedValue?.toLocaleString("en-US");

    return (
      '<div style="padding:5px;">' +
      `<strong>${xTitle}: </strong>` +
      key +
      "<br/>" +
      `<strong>${_.capitalize(yName)}: </strong>` +
      `${
        symbol === "$"
          ? "$" + newValue
          : symbol === "%"
          ? newValue + "%"
          : newValue
      }` +
      "</div>"
    );
  }

  function convertData(data, labels) {
    let result = [];
    // Extract unique product names and months
    let xaxisData = Array.from(data?.dataForChart?.map((item) => item.xaxis));
    let months = data?.yaxis;

    // months.sort();
    let dumpArray = [];
    // Push the header row
    xaxisData.map((item) => {
      dumpArray.push(item);
      dumpArray.push({ type: "string", role: "tooltip", p: { html: true } });
    });
    result.push(["months", ...dumpArray]);
    // Create a map to hold the values for each product and month
    let productData = new Map();
    xaxisData.forEach((product) => {
      productData.set(product, new Map());
    });

    // Populate productData map with values
    data?.dataForChart.forEach((item) => {
      let product = item.xaxis;
      item?.yaxis?.forEach((yItem) => {
        let month = Object.keys(yItem.data)[0];
        let value = yItem.data[month];

        if (!productData.get(product).has(month)) {
          productData.get(product).set(month.toLowerCase(), value);
        }
      });
    });

    // Populate result array from the productData map
    months.forEach((month) => {
      let row = [month];
      xaxisData.forEach((product) => {
        row.push(productData.get(product).get(month.toLowerCase()) || "");
        row.push(
          createCustomHTMLTooltip(
            product,
            productData.get(product).get(month.toLowerCase()) || "",
            month,
            "$"
          )
        );
      });
      result.push(row);
    });

    return result;
  }

  return (
    <div
      ref={containerRef}
      id={r.current}
      className='cancelDraggableElement'
      style={{ height: "100%" }}>
      <div ref={chartRef} id={`${r.current}_div`}></div>
    </div>
  );
};

export default StackedColumnChart;
