import { Box, styled as MuiStyled } from "@mui/material";

export const AcceptInvitationContainer = MuiStyled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  overflowY: "auto",
}));

export const AcceptInvitationCard = MuiStyled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(4),
  borderRadius: theme.spacing(2),
  boxShadow: theme.palette.shadows.shadow1,
  marginTop: theme.spacing(7),
  marginBottom: theme.spacing(4),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  width: "auto",
}));
