import { useSelector } from "react-redux";
import BaseModal from "../../common/BaseModal";
import { Stack, Typography } from "@mui/material";

export const SpecPublishModal = ({
  isOpen,
  onClose,
  onSubmit,
  isPublished,
  isLoading,
}) => {
  const { specData, specIndustry, specFunction } = useSelector(
    (state) => state.spec
  );

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(specData?.id);
    handleClose();
  };

  const isMissingFields = !specIndustry?.id || !specFunction?.id;

  return (
    <BaseModal
      title={isPublished ? "Unpublish from Templates" : "Publish to Templates"}
      isOpen={isOpen}
      dialogWidth={400}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      submitDisable={isMissingFields}
      confirmTitle={isPublished ? "Unpublish" : "Publish"}
      isLoading={isLoading}>
      <Stack rowGap={0.5}>
        {isPublished ? (
          <Typography variant='spec_body' sx={{ wordBreak: "break-word" }}>
            Are you sure you want to unpublish this specification?
          </Typography>
        ) : (
          <Typography variant='spec_body' sx={{ wordBreak: "break-word" }}>
            {isMissingFields ? (
              <span>
                Please make sure you have selected <strong>Function</strong> and{" "}
                <strong>Industry</strong> for your specification to be
                published.
              </span>
            ) : (
              <span>
                Please confirm your Function:{" "}
                <strong>{specFunction?.tag}</strong> and Industry:{" "}
                <strong>{specIndustry?.title}</strong> for the specification
                before you publish it.
              </span>
            )}
          </Typography>
        )}
      </Stack>
    </BaseModal>
  );
};
