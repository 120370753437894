export const StackedAreaIcon = () => {
  return (
    <svg
      width='100%'
      height='100%'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 60 61'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g id='chart icon / stacked area chart'>
        <g id='Group 11'>
          <path
            id='Vector 11'
            d='M46.8002 32.9629V19.332C46.8002 18.9956 46.4747 18.7552 46.1531 18.8541L43.9546 19.5306C43.8849 19.552 43.8114 19.558 43.7392 19.548L37.4232 18.6768C37.3203 18.6627 37.2156 18.6808 37.1236 18.7289L21.754 26.7478C21.6544 26.7997 21.5402 26.8166 21.4298 26.7958L13.7931 25.351C13.4852 25.2927 13.2002 25.5289 13.2002 25.8423V36.4872C13.2002 36.7847 13.4582 37.0163 13.7539 36.9844L46.3539 33.46C46.6078 33.4326 46.8002 33.2183 46.8002 32.9629Z'
            fill='#7e8687'
          />
          <path
            id='Vector 10'
            d='M46.8002 43.9227V22.4602C46.8002 22.0781 46.3889 21.8372 46.0556 22.0242L37.6912 26.7164C37.5648 26.7873 37.4137 26.7998 37.2772 26.7507L32.0835 24.881C31.9179 24.8213 31.7331 24.8532 31.597 24.9649L23.1296 31.9124C23.0229 32 22.8847 32.0396 22.7478 32.0217L18.0067 31.4033C17.8406 31.3816 17.6747 31.4446 17.5647 31.571L13.3229 36.4491C13.2438 36.5401 13.2002 36.6566 13.2002 36.7772V43.9227C13.2002 44.1988 13.4241 44.4227 13.7002 44.4227H46.3002C46.5763 44.4227 46.8002 44.1988 46.8002 43.9227Z'
            fill='#919b9c'
          />
          <path
            id='Vector 9'
            d='M46.8002 46.3063V33.0132C46.8002 32.6934 46.5041 32.4558 46.1919 32.525L41.7982 33.4996C41.722 33.5165 41.6508 33.551 41.5903 33.6003L37.37 37.0451C37.2807 37.1179 37.1691 37.1577 37.0538 37.1577H27.8738C27.7395 37.1577 27.6108 37.2118 27.5167 37.3077L21.8029 43.1374C21.7088 43.2334 21.5801 43.2874 21.4458 43.2874H13.7002C13.4241 43.2874 13.2002 43.5113 13.2002 43.7874V46.3064C13.2002 46.5825 13.4241 46.8064 13.7002 46.8064H46.3002C46.5763 46.8064 46.8002 46.5825 46.8002 46.3063Z'
            fill='#7e8687'
          />
        </g>
      </g>
    </svg>
  );
};
