export const SingleLineIcon = () => {
  return (
    <svg
      width='100%'
      height='100%'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 60 61'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g id='chart icon / single line chart '>
        <g id='Group 1'>
          <path
            id='Vector 2'
            d='M14.25 39.4524L20.1 32.0274H30.225L39.9757 23.5026L48 21.0024'
            stroke='##808080'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <ellipse
            id='Ellipse 1'
            cx='19.8858'
            cy='31.7381'
            rx='1.28571'
            ry='1.28571'
            fill='#808080'
            stroke='#808080'
            strokeWidth='1.5'
          />
          <ellipse
            id='Ellipse 2'
            cx='30.2359'
            cy='31.7381'
            rx='1.28571'
            ry='1.28571'
            fill='#808080'
            stroke='#808080'
            strokeWidth='1.5'
          />
          <ellipse
            id='Ellipse 4'
            cx='40.2857'
            cy='23.4881'
            rx='1.28572'
            ry='1.28571'
            fill='#808080'
            stroke='#808080'
            strokeWidth='1.5'
          />
          <path id='Vector 1' d='M12 12.0024V48.0024H48' stroke='#808080' strokeDasharray='2 2' />
        </g>
      </g>
    </svg>
  );
};
