export const ROLES = {
  ADMIN: "admin",
  ADMIN_LABEL: "Admin",
  EDITOR: "editor",
  EDITOR_LABEL: "Editor",
  COLLABORATOR: "collaborator",
  COLLABORATOR_LABEL: "Commenter",
  // VIEWER: "viewer",
  // VIEWER_LABEL: "Viewer",
};

export const ROLES_LABEL = {
  [ROLES.ADMIN]: "Admin",
  [ROLES.EDITOR]: "Editor",
  [ROLES.COLLABORATOR]: "Commenter",
  // [ROLES.VIEWER]: "Viewer",
};

export const PERMISSIONS = {
  ADMIN: "owner",
  EDITOR: "editor",
  COMMENTER: "collaborator",
  // VIEWER: "viewer",
};

export const PERMISSIONS_LABEL = {
  [PERMISSIONS.ADMIN]: "Author",
  [PERMISSIONS.EDITOR]: "Editor",
  [PERMISSIONS.COMMENTER]: "Commenter",
  // [PERMISSIONS.VIEWER]: "Viewer",
};
export const PERMISSIONS_ARR = [
  PERMISSIONS.ADMIN,
  PERMISSIONS.EDITOR,
  PERMISSIONS.COMMENTER,
  // PERMISSIONS.VIEWER,
];

export const STATUS_DOC = {
  DRAFT: "draft",
  PENDING: "pending_approval",
  APPROVED: "approved",
};

export const STATUS_DOC_LABEL = {
  [STATUS_DOC.DRAFT]: "Draft",
  [STATUS_DOC.PENDING]: "Pending approval",
  [STATUS_DOC.APPROVED]: "Approved",
};

export const STATUS_DOC_COLORS = {
  [STATUS_DOC.DRAFT]: "warning",
  [STATUS_DOC.PENDING]: "info",
  [STATUS_DOC.APPROVED]: "success",
};

export const DEPARTMENTS = [{ label: "Analyst" }, { label: "Marketing" }];

export const COMPANY_STATUS = {
  ACTIVE: "active",
  INVITED: "invited",
};

export const USER_STATUS = {
  ACCEPTED: "accepted",
  DEACTIVATED: "deactivated",
  INVITED: "invited",
  EXPIRED: "expired",
};

export const COMMENT_STATUS = {
  OPEN: "active",
  RESOLVED: "resolved",
};

export const COMMENT_STATUS_LABEL = {
  [COMMENT_STATUS.OPEN]: "Open",
  [COMMENT_STATUS.RESOLVED]: "Resolved",
};

export const LEVELS = {
  LOW_LABEL: "Low",
  MEDIUM_LABEL: "Medium",
  HIGH_LABEL: "High",
  LOW: "low",
  MEDIUM: "medium",
  HIGH: "high",
};

export const SPECS_FUNCTIONS = [
  "Executive",
  "Finance",
  "Marketing",
  "Sales",
  "IT",
  "Engineering",
  "Operations",
  "HR",
  "Customer Service",
];

export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*():;+=|'"/\\])[A-Za-z\d!@#$%^&*():;+=|'"/\\]{8,}$/;

export const DOMAIN = ".spectio.ai";

export const TITLE = "Title";
export const DESCRIPTION = "Description";
export const SUBTITLE = "Subtitle";
export const ANNOTATION = "Internal Annotation";
export const SEARCH = "Search";
export const FILTER = "Filter";
export const STATISTIC = "Statistic";

export const STATE_BUSINESS_TERMS = {
  PROPOSED: "proposed",
  EXISTS: "exists",
  DECLINED: "declined",
  DEFAULT: "default",
};

export const TERM_STATES = [
  { value: "proposed", label: "Proposed" },
  { value: "exists", label: "Approved" },
  { value: "declined", label: "Declined" },
  { value: "default", label: "Default" },
];

export const STATE_BUSINESS_TERMS_LABEL = {
  [STATE_BUSINESS_TERMS.PROPOSED]: "Proposed",
  [STATE_BUSINESS_TERMS.EXISTS]: "Approved",
  [STATE_BUSINESS_TERMS.DECLINED]: "Declined",
  [STATE_BUSINESS_TERMS.DEFAULT]: "Default",
};

export const NUMBER = {
  type: "number",
  label: "Number",
};

export const TEXT = {
  type: "text",
  label: "Text",
};

export const PERCENT = {
  type: "percent",
  label: "Percent",
};
export const CURRENCY = {
  type: "currency",
  label: "Currency",
};

export const TIME = {
  type: "time",
  label: "Time",
};

export const DATE = {
  type: "date",
  label: "Date",
};
export const DAY = {
  type: "date_day",
  label: "Date: Day",
};
export const WEEK = {
  type: "date_week",
  label: "Date: Week",
};
export const MONTH = {
  type: "date_month",
  label: "Date: Month",
};
export const YEAR = {
  type: "date_year",
  label: "Date: Year",
};

export const BOOLEAN = {
  type: "boolean",
  label: "Boolean",
};
export const GEOGRAPHY = {
  type: "geography",
  label: "Geography",
};

export const SYSTEM_DEFAULT = {
  type: "systemDefault",
  label: "System Default",
};

export const TYPES = [
  TEXT,
  NUMBER,
  PERCENT,
  CURRENCY,
  TIME,
  DATE,
  // DAY,
  // WEEK,
  // MONTH,
  // YEAR, //These commented are now one type that is `DATE`
  BOOLEAN,
  GEOGRAPHY,
  SYSTEM_DEFAULT,
].sort((a, b) => a.label.localeCompare(b.label));

export const TYPE_LABEL = TYPES.reduce(
  (acc, type) => ({ ...acc, [type.type]: type.label }),
  {}
);

export const QUALITY = {
  MEDIUM: "medium",
  HIGH: "high",
  LOW: "low",
};

export const QUALITY_LABEL = {
  [QUALITY.MEDIUM]: "Medium",
  [QUALITY.HIGH]: "High",
  [QUALITY.LOW]: "Low",
};

export const REQUIRED = {
  YES: "yes",
  OPTIONAL: "optional",
};

export const REQUIRED_LABEL = {
  [REQUIRED.YES]: "Yes",
  [REQUIRED.OPTIONAL]: "Optional",
};

export const EMBED_TYPE = {
  TABLEAU: "tableau",
  OTHER: "other",
};

export const MOCK_DATA_LENGTH = 50;

export const NAVBAR_HEIGHT = 45;
export const SUBNAVBAR_HEIGHT = 60;
export const SIDEBAR_HEIGHT_MOBILE = 88;
export const SIDEBAR_HEIGHT = 43;

export const MappingColumnTypeValue = "Not Configurable: Original, Derived";

//rollbar Config
export const rollbarConfig = {
  accessToken: process.env.ROLLBAR_ACCESS_TOKEN,
  environment: process.env.ROLLBAR_NODE_ENV,
};

// Main Bar
export const MAIN_APPBAR_HEIGHT = 45;

//Spec Bars
export const SPEC_SUB_APPBAR_HEIGHT = 44;
export const SPEC_SUB_APPBAR_HEIGHT_RESPONSIVE = 44;
export const VISUAL_MOCKUP_ACTIONBAR = 56;
export const MOCKDATASET_TAB_HEIGHT = 33;
export const DATA_SETTINGS_SUB_APPBAR_HEIGHT = 42;
export const TEMPLATE_SUB_APPBAR_HEIGHT = 49;
export const SIDEBAR_WIDTH = 235;

// MOCK DATASET DATA TYPES
export const DATA_TYPES = [
  {
    value: "number",
    label: "Number",
  },
  {
    value: "text",
    label: "Text",
  },
  {
    value: "percent",
    label: "Percent",
  },
  {
    value: "currency",
    label: "Currency",
  },
  {
    value: "time",
    label: "Time",
  },
  {
    value: "date",
    label: "Date",
  },
  {
    value: "boolean",
    label: "Boolean",
  },
  {
    value: "geography",
    label: "Geography",
  },
  {
    value: "systemDefault",
    label: "System Default",
  },
];

export const URGENCY = [
  { value: "low", label: "Low" },
  {
    value: "medium",
    label: "Medium",
  },
  {
    value: "high",
    label: "High",
  },
];

export const REQUIRED_OPTIONS = [
  { value: "yes", label: "Yes" },
  { value: "optional", label: "Optional" },
];

export const ROLE_OPTIONS = [
  { value: "admin", label: "Admin" },
  { value: "editor", label: "Editor" },
  { value: "collaborator", label: "Commenter" },
];
