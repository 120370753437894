import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const AppLayoutWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.white,
  minHeight: "100%",
  overflow: "hidden",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const MainContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.white,
  height: "calc(100vh - 45px)",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: "45px",
  width: "100%",
}));
