import _ from "lodash";
import { TYPE_LABEL } from "../../constants";
import { format } from "date-fns";

// Function to convert businessTermFields array to object
const mapFieldsToObj = (fields) => {
  return fields.reduce(
    (acc, { mappingColumnId, value, mappingColumnValue }) => {
      // Use mappingColumnValue if value is empty
      acc[mappingColumnId] =
        value === ""
          ? mappingColumnValue === "" || !mappingColumnValue
            ? "-"
            : mappingColumnValue
          : value;
      return acc;
    },
    {}
  );
};

export const getRowData = (businessTerms) => {
  return businessTerms?.map((term) => {
    return {
      id: term?.id,
      specs: term?.specificationsCount,
      description: !_.isEmpty(term?.description) ? term?.description : null,
      businessAttribute: !_.isEmpty(term?.businessAttribute)
        ? term?.businessAttribute
        : null,
      businessTermCategory: term?.businessTermCategory?.id
        ? term?.businessTermCategory
        : null,
      name: term?.name,
      sample: !_.isEmpty(term?.sample) ? term?.sample : "-",
      proposedBy:
        term?.proposedBy && !_.isEmpty(term?.proposedBy)
          ? term?.proposedBy
          : null,
      proposedFor: term?.proposedFor?.title ? term?.proposedFor?.title : "-",
      proposedForId: term?.proposedFor?.id ? term?.proposedFor?.id : null,
      dataType: term?.dataType ? term?.dataType : null,
      ...(term?.businessTermFields &&
        term?.businessTermFields.length > 0 &&
        mapFieldsToObj(term?.businessTermFields)),
      businessTermFields: term?.businessTermFields,
      prompt: term?.prompt,
      plainPrompt: term?.plainPrompt,
      mentions: term?.mentions ? term?.mentions : [],
      state: term?.state,
      dataset: term?.dataset,
      datasetColumnId: term?.datasetColumnId,
      lastUpdatedBy: `Updated by ${term?.lastUpdatedBy?.name} on ${format(
        term?.updatedAt,
        "MM/dd/yyyy"
      )} at ${format(term?.updatedAt, "hh:mm a")}`,
    };
  });
};
