import ActionCell from "./ActionCell";

const commonCellStyle = { wordBreak: "normal", lineHeight: "normal" };
export const getSpecDataScopeColumns = (isPreview) => {
  const cols = [
    {
      field: "action",
      editable: false,
      headerName: "Actions",
      resizable: false,
      cellDataType: "text",
      width: 90,
      minWidth: 90,
      maxWidth: 90,
      sortable: false,
      cellRenderer: ActionCell,
      pinned: "left",
      suppressMovable: true,
    },
    {
      field: "name",
      editable: false,
      headerName: "Data scope",
      resizable: false,
      unSortIcon: true,
      cellDataType: "text",
      pinned: "left",
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
      suppressMovable: true,
    },

    {
      field: "value",
      editable: false,
      headerName: "Value",
      resizable: false,
      unSortIcon: true,
      cellDataType: "text",
      flex: 1,
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
      suppressMovable: true,
    },
    {
      field: "category",
      editable: false,
      headerName: "Category",
      resizable: false,
      unSortIcon: true,
      cellDataType: "text",
      minWidth: 200,
      flex: 1,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
      suppressMovable: true,
    },
    {
      field: "description",
      editable: false,
      headerName: "Description",
      resizable: false,
      unSortIcon: true,
      cellDataType: "text",
      minWidth: 200,
      flex: 1,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
      suppressMovable: true,
    },
    {
      field: "required",
      editable: false,
      headerName: "Required",
      resizable: false,
      unSortIcon: true,
      cellDataType: "text",
      flex: 1,
      cellStyle: {
        textTransform: "capitalize",
      },
      minWidth: 150,
      suppressMovable: true,
    },
  ];

  const filteredCols = cols.filter(
    (col) => !(col.field === "action" && isPreview)
  );

  return filteredCols;
};
