import { Fragment } from "react";

import { Box, Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CloseOutlined } from "@mui/icons-material";

export const FiltersChips = ({ filters, clearAllFilters }) => {
  const theme = useTheme();

  const show = filters.some(([, filter]) => Boolean(filter.active.size));

  if (show) {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "flex-start",
          maxWidth: "100%",
          gap: 1,
        }}
      >
        <Chip
          size="small"
          variant="outlined"
          title="Clear All"
          onClick={clearAllFilters}
          label={
            <Box display="flex" alignItems="center">
              <Box mr={1}>Clear All</Box>
              <CloseOutlined sx={{ fontSize: "12px", color: "#424242" }} />
            </Box>
          }
          color={"error"}
        />
      </Box>
    );
  }
};
