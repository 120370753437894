import React, { memo } from "react";
import { selectStyle } from "./style";
import { components } from "react-select";
import { FormHelperText } from "@mui/material";
import { AsyncPaginate, withAsyncPaginate } from "react-select-async-paginate";
import { ExpandMoreRoundedIcon } from "../../assets";
import { Box, Stack, useTheme, InputLabel, Typography } from "@mui/material";
import Creatable from "react-select/creatable";

const PaginatedSelectField = ({
  name = "",
  label = "",
  value = null,
  isLabel = false,
  isRequired = false,
  error,
  onBlur,
  disabled,
  onChange,
  helperText,
  loadOptions,
  menuPlacement,
  debounceTimeout,
  formatOptionLabel,
  labelTypographySx,
  handleOnCreateOption,
  maxMenuHeight = "170px",
  placeholder = "Add Role",
  isCreatable = false,
  Components,
  defaultOptions = true,
  isClearable = true,
  isMulti = false,
  allowCreateWhileLoading,
  formatCreateLabel,
}) => {
  const theme = useTheme();

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ExpandMoreRoundedIcon />
      </components.DropdownIndicator>
    );
  };

  const CreatableAsyncPaginate = withAsyncPaginate(Creatable);
  const SelectAsyncPaginate = isCreatable
    ? CreatableAsyncPaginate
    : AsyncPaginate;

  return (
    <Stack sx={{ width: 1 }}>
      {isLabel && (
        <InputLabel
          htmlFor={name}
          required={isRequired}
          error={error}
          sx={{
            mb: 0.65,
            ".MuiFormLabel-asterisk": {
              color: "secondary.error",
            },
            textAlign: "left",
          }}>
          <Typography
            variant='spec_inputLabel'
            sx={{
              color: "secondary.black",
              ...labelTypographySx,
            }}>
            {label}
          </Typography>
        </InputLabel>
      )}

      <SelectAsyncPaginate
        styles={selectStyle(error, theme)}
        {...(onBlur && {
          onBlur: (e) => {
            if (name) {
              e.target.name = name;
            }
            onBlur(e);
          },
        })}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator,
          ...Components,
        }}
        cacheOptions
        value={value}
        closeMenuOnSelect
        onChange={onChange}
        isMulti={isMulti}
        isSearchable={true}
        defaultOptions={defaultOptions}
        isDisabled={disabled}
        formatOptionLabel={formatOptionLabel}
        loadOptions={loadOptions}
        openMenuOnClick={true}
        isClearable={isClearable}
        placeholder={placeholder}
        onCreateOption={handleOnCreateOption}
        maxMenuHeight={maxMenuHeight}
        menuPortalTarget={document.body}
        menuPlacement={menuPlacement ? menuPlacement : "auto"}
        debounceTimeout={debounceTimeout ? debounceTimeout : 500}
        additional={{
          page: 1,
        }}
        allowCreateWhileLoading={allowCreateWhileLoading}
        formatCreateLabel={
          formatCreateLabel
            ? formatCreateLabel
            : (inputValue) => (
                <Typography
                  variant='spec_body'
                  color={
                    "secondary.main"
                  }>{`Create "${inputValue}"`}</Typography>
              )
        }
      />
      <Box>
        {error || helperText ? (
          <FormHelperText error={error} sx={{ m: 0 }}>
            <Typography
              sx={(theme) => ({
                margin: 0,
                fontFamily: "Poppins",
                fontWeight: 400,
                color: error
                  ? theme.palette.error.main
                  : theme.palette.gray.main,
              })}
              variant='spec_caption'>
              {helperText}
            </Typography>
          </FormHelperText>
        ) : null}
      </Box>
    </Stack>
  );
};

export default memo(PaginatedSelectField);
