import { MoreHorizRounded } from "@mui/icons-material";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import { SquareIconButton } from "../../common/commonStyle";
import CustomPopover from "../../common/CustomPopover";
import MenuItem from "../../common/MenuItem";
import {
  getBusinessTerms,
  setTermModal,
} from "../../store/slices/businessTerms";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmationModal from "../../common/DeleteConfirmationModal";
import { deleteBusinessTerm } from "../../services";
import { HTTP_OK } from "../../constants/statusCodes";
import { defaultMessage } from "../../constants/messages";
import { toaster } from "../../common/Toaster";

const ActionCell = (props) => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.tableFilter);
  const { currentTermTab } = useSelector((state) => state?.businessTerms);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUpdatedTerms = () => {
    dispatch(
      getBusinessTerms({
        status: currentTermTab,
        dataType: filters?.["dataType"]
          ? filters?.["dataType"]?.map((selected) => selected?.value)
          : [],
        proposedBy: filters?.["proposedBy"]
          ? filters?.["proposedBy"]?.map((selected) => selected?.value)
          : [],
        businessTermCategory: filters?.["businessTermCategory"]
          ? filters?.["businessTermCategory"]?.map(
              (selected) => selected?.value
            )
          : [],
      })
    );
  };

  const handleDelete = async () => {
    try {
      setIsDeleteLoading(true);
      const response = await deleteBusinessTerm({ id: props?.data?.id });

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }
      setIsDeleteLoading(false);

      getUpdatedTerms();
      setDeleteOpen(false);

      toaster({
        type: "success",
        message: "Business term deleted successfully.",
      });
    } catch (error) {
      setIsDeleteLoading(false);
      toaster({
        type: "error",
        message: "Error while deleting business term!",
      });
    }
  };

  return (
    <>
      <Stack alignItems='center' justifyContent={"center"} width={1}>
        <SquareIconButton onClick={handleClick}>
          <MoreHorizRounded
            sx={(theme) => ({ color: theme.palette.gray.iconColor })}
          />
        </SquareIconButton>
      </Stack>
      <CustomPopover anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          itemText={"Edit"}
          onClick={() => {
            handleClose();
            dispatch(
              setTermModal({
                isTermModalOpen: true,
                termModalData: props?.data,
                termModalType: "EDIT",
              })
            );
          }}
        />
        {Number(props?.data?.specs) <= 0 && (
          <MenuItem
            itemText={"Delete"}
            onClick={() => {
              handleClose();
              setDeleteOpen(true);
            }}
          />
        )}
      </CustomPopover>
      <DeleteConfirmationModal
        loading={isDeleteLoading}
        isOpen={isDeleteOpen}
        handleClose={() => setDeleteOpen(false)}
        message='Are you sure you want to delete this business term?'
        description='This action cannot be undone!'
        handleDelete={handleDelete}
      />
    </>
  );
};

export default ActionCell;
