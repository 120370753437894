import { DATA_TYPES, STATE_BUSINESS_TERMS, TYPE_LABEL } from "../../constants";
import {
  getBusinessTermCategoriesOptions,
  getCompanyUsersOptions,
} from "../../services";
import AvatarWithLabelCell from "../SpecsListing/AvatarWithLabelCell";
import ActionCell from "./ActionCell";
import LastUpdatedByCell from "./LastUpdatedByCell";
import MockupCountCell from "./MockupCountCell";
import ProposedForCell from "./ProposedForCell";

const commonCellStyle = { wordBreak: "normal", lineHeight: "normal" };

export const getMappingsColumns = (mappingColumns, currentTermTab, canEdit) => {
  const newColDef = mappingColumns?.map((column) => {
    if (column?.originalKey === "specs") {
      return {
        field: "specs",
        editable: false,
        headerName: "Specs",
        width: 110,
        minWidth: 110,
        resizable: true,
        unSortIcon: true,
        cellDataType: "text",
        cellRenderer: MockupCountCell,
      };
    }

    if (column?.originalKey === "description") {
      return {
        field: "description",
        editable: false,
        headerName: "Description",
        resizable: true,
        unSortIcon: true,
        cellDataType: "text",
        cellStyle: { wordBreak: "normal", lineHeight: "normal" },
        wrapText: true,
        autoHeight: true,
        valueFormatter: (params) => (params?.value ? params?.value : "-"),
        minWidth: 150,
      };
    }

    if (column?.originalKey === "businessAttribute") {
      return {
        field: "businessAttribute",
        editable: false,
        headerName: "Attribute",
        resizable: true,
        unSortIcon: true,
        cellDataType: "text",
        valueFormatter: (params) => (params?.value ? params?.value : "-"),
        minWidth: 150,
      };
    }

    if (column?.originalKey === "prompt") {
      return {
        field: "plainPrompt",
        editable: false,
        headerName: "Prompt",
        resizable: true,
        unSortIcon: true,
        cellDataType: "text",
        cellStyle: { wordBreak: "normal", lineHeight: "normal" },
        wrapText: true,
        autoHeight: true,
        valueFormatter: (props) => (props?.value ? props?.value : "-"),
        minWidth: 150,
      };
    }

    if (column?.originalKey === "businessTermCategory") {
      return {
        field: "businessTermCategory",
        editable: false,
        headerName: "Category",
        resizable: true,
        unSortIcon: true,
        cellDataType: "text",
        filterParams: {
          isCustomFilter: true,
          loadOptions: getBusinessTermCategoriesOptions,
        },
        valueFormatter: (props) =>
          props?.value?.id ? props?.value?.name : "Uncategorized",
        minWidth: 150,
      };
    }

    if (column?.originalKey === "name") {
      return {
        field: "name",
        editable: false,
        headerName: "Term",
        resizable: true,
        unSortIcon: true,
        cellDataType: "text",
        wrapText: true,
        autoHeight: true,
        cellStyle: commonCellStyle,
        minWidth: 150,
      };
    }

    if (column?.originalKey === "sample") {
      return {
        field: "sample",
        editable: false,
        headerName: "Sample",
        resizable: true,
        unSortIcon: true,
        cellDataType: "text",

        cellStyle: commonCellStyle,
        minWidth: 150,
      };
    }

    if (column?.kind === "custom") {
      return {
        field: column?.id,
        editable: false,
        headerName: column?.name,
        resizable: true,
        unSortIcon: true,
        cellDataType: "text",
        wrapText: true,
        autoHeight: true,
        cellStyle: commonCellStyle,
        minWidth: 150,
      };
    }
  });

  newColDef?.push({
    field: "dataType",
    editable: false,
    headerName: "Data Type",
    resizable: true,
    unSortIcon: true,
    cellDataType: "text",
    filterParams: {
      isCustomFilter: true,
      staticOptions: DATA_TYPES,
    },
    valueFormatter: (props) => (props?.value ? TYPE_LABEL[props?.value] : "-"),
    minWidth: 150,
  });

  if (
    currentTermTab === STATE_BUSINESS_TERMS.PROPOSED ||
    currentTermTab === STATE_BUSINESS_TERMS.DECLINED
  ) {
    newColDef?.push({
      field: "proposedBy",
      editable: false,
      headerName: "Proposed By",
      resizable: true,
      unSortIcon: true,
      cellDataType: "text",
      cellRenderer: AvatarWithLabelCell,
      filterParams: {
        isCustomFilter: true,
        loadOptions: getCompanyUsersOptions,
      },
      minWidth: 150,
    });
    newColDef?.push({
      field: "proposedFor",
      editable: false,
      headerName: "proposed For",
      resizable: true,
      unSortIcon: true,
      cellDataType: "text",
      autoHeight: true,
      cellRenderer: ProposedForCell,
      minWidth: 150,
    });
  }

  newColDef?.push({
    field: "lastUpdatedBy",
    editable: false,
    headerName: "Last Updated By",
    resizable: true,
    unSortIcon: true,
    cellDataType: "text",
    autoHeight: true,
    minWidth: 250,
    flex: 1,
    cellRenderer: LastUpdatedByCell,
    cellStyle: { ...commonCellStyle, whiteSpace: "wrap" },
  });

  if (canEdit) {
    newColDef?.unshift({
      field: "action",
      editable: false,
      headerName: "Action",
      width: 80,
      pinned: "left",
      resizable: false,
      sortable: false,
      //   : false,
      cellDataType: "text",
      cellRenderer: ActionCell,
      minWidth: 80,
    });
  }

  const colDef = newColDef?.filter(
    (col, i) =>
      !(
        col?.field === "specs" &&
        (currentTermTab === STATE_BUSINESS_TERMS.PROPOSED ||
          currentTermTab === STATE_BUSINESS_TERMS.DECLINED)
      )
  );

  return colDef.map((col, i) => {
    if (i === (canEdit ? 1 : 0)) {
      // as now the first column is action which is already pinned to left now we need to pin the second column to left
      return {
        ...col,
        pinned: "left",
      };
    }
    return col;
  });
};
