import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import CustomIconButton from "../CustomIconButton";
import LoadingButton from "../LoadingButton";

const BaseModal = ({
  isOpen = false,
  handleClose = () => {},
  actions,
  dialogWidth,
  title = "Title",
  children,
  dialogAction,
  contentPadding,
  contentSx,
  confirmTitle = "Submit",
  submitDisable,
  isLoading = false,
  handleSubmit,
  InfoIcon,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Dialog
      keepMounted
      open={isOpen}
      scroll='paper'
      onClose={() => {}}
      className='cancelDraggableElement'
      PaperProps={{
        sx: {
          width: dialogWidth ? dialogWidth : "425px",
          borderRadius: theme.spacing(1),
        },
      }}
      {...props}>
      <DialogTitle
        sx={(theme) => ({
          padding: `9px 16px`,
          display: "flex",
          justifyContent: "space-between",
        })}>
        <Stack direction={"row"} alignItems={"center"} columnGap={1}>
          <Typography
            variant='spec_h2'
            sx={{
              wordBreak: "break-all",
            }}>
            {title}
          </Typography>
          {InfoIcon && InfoIcon}
        </Stack>
        <CustomIconButton
          Icon={CloseOutlined}
          onClick={handleClose}
          sx={{ padding: 0 }}
        />
      </DialogTitle>
      <Divider sx={{ backgroundColor: theme.palette.gray.borderGray }} />
      <Box
        sx={(theme) => ({
          overflowY: "auto",
          p: contentPadding ? contentPadding : "20px 24px",
          ...contentSx,
        })}>
        {children}
      </Box>
      <DialogActions
        sx={(theme) => ({
          p: theme.spacing(2),
        })}>
        {dialogAction ? (
          dialogAction
        ) : (
          <>
            <Stack flexDirection={"row"} columnGap={2}>
              <Button variant={"outlined"} color={"gray"} onClick={handleClose}>
                Cancel
              </Button>
              <LoadingButton
                onClick={handleSubmit}
                variant='contained'
                disabled={submitDisable}
                loading={isLoading}>
                {confirmTitle}
              </LoadingButton>
            </Stack>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BaseModal;
