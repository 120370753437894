import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import _ from "lodash";
import {
  Badge,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  getSpecsList,
  getSpecIndexPreferenceAsync,
} from "../../store/slices/specListing";
import { specListingColDef } from "./columns";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getRowData } from "./helperFunctions";
import { toaster } from "../../common/Toaster";
import { InputField } from "../../common/InputField";
import { bulkDestroySpecs, createSpecification } from "../../services";
import { HTTP_OK } from "../../constants/statusCodes";
import LoadingButton from "../../common/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import { defaultMessage } from "../../constants/messages";
import AgGridComponent from "../../common/AgGridComponent";
import { SecondaryAppBar, StyledBadge } from "../../common/commonStyle";
import CustomHeaderCell from "../../common/CustomHeaderCell";
import {
  AddRounded,
  CheckBox,
  CheckBoxOutlineBlank,
  ClearRounded,
  DeleteOutline,
  IndeterminateCheckBox,
  SearchOutlined,
} from "@mui/icons-material";
import SpecColumnFilter from "../../components/SpecColumnFilter";
import CustomOverlayLoadingComponent from "../../common/CustomOverlayLoadingComponent";
import NoRowsOverlayCustomComponent from "../../common/NoRowsOverlayCustomComponent";
import { ROLES } from "../../constants";
import CustomIconButton from "../../common/CustomIconButton";
import DeleteConfirmationModal from "../../common/DeleteConfirmationModal";
import { format } from "date-fns";
import {
  resetTableFilters,
  tableFilterInitialState,
} from "../../store/slices/tableFilters";
import NoRowsOverlayCustomComponentSpecListing from "./NoRowsOverlayComponentSpecListing";

const SpecsListing = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const businessTermId = searchParams.get("business_term_id");
  const similarTo = searchParams.get("similar_to");
  const dataScope = searchParams.get("data_scope");

  const {
    specs,
    isSpecsLoading,
    specIndexPrefrences,
    isSpecIndexPrefrencesLoading,
  } = useSelector((state) => state.specListing);
  const { filters } = useSelector((state) => state.tableFilter);
  const { user, currentCompany } = useSelector((state) => state.userAuth);
  const [searchText, setSearchText] = useState("");
  const [isGridReady, setIsGridReady] = useState(false);
  const [specsRowsForAgGrid, setSpecsRowsForAgGrid] = useState([]);
  const [creatSpecLoading, setCreateSpecLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [canAddSpec, setCanAddSpec] = useState(
    currentCompany?.permissions?.specificationPolicy?.create
  );
  useEffect(() => {
    setCanAddSpec(currentCompany?.permissions?.specificationPolicy?.create);
  }, [currentCompany?.permissions]);

  const isFilterApplied = !_.isEqual(tableFilterInitialState?.filters, filters);

  const canBulkAction = user?.superAdmin || [ROLES.ADMIN].includes(user?.role);

  const onSearchFilterChanged = useCallback(() => {
    const displayedRows = ref.current.api.getDisplayedRowAtIndex(0);
    if (!displayedRows) {
      showNoRowsOverlay();
    } else {
      hideOverlay(); // Hide any existing overlay
    }
  }, []);

  const onFilterTextBoxChanged = useCallback(
    (e) => {
      setSearchText(e.target.value);
      ref.current.api.setGridOption("quickFilterText", e.target.value);

      // Call onSearchFilterChanged to trigger check for empty rows
      onSearchFilterChanged();
    },
    [onSearchFilterChanged]
  );

  const handleCreateSpec = async () => {
    try {
      setCreateSpecLoading(true);
      const response = await createSpecification({
        payload: {
          specification: {
            title: "New Specification",
            shared: false,
          },
        },
      });

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }
      setCreateSpecLoading(false);
      navigate(`/specs/spec/${response.data.specification.id}`);

      toaster({
        type: "success",
        message: "Specification created successfully",
      });
    } catch (error) {
      setCreateSpecLoading(false);
      toaster({
        type: "error",
        message: "Error while creating a new specification!",
      });
    }
  };

  const handleDeleteSelectedSpecs = async () => {
    setIsDeleteLoading(true);
    try {
      const response = await bulkDestroySpecs({
        payload: {
          specification_ids: selectedRows?.map((spec) => spec?.id),
        },
      });

      setIsDeleteLoading(false);
      dispatch(getSpecsList());
      goToFirstPage();

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }

      setDeleteOpen(false);

      toaster({
        type: "success",
        message: "Selected specifications deleted successfully.",
      });
    } catch (error) {
      setIsDeleteLoading(false);
      toaster({
        type: "error",
        message:
          "Error while performing bulk delete operation of selected specifications!",
      });
    }
  };

  // GET SPEC LISTING AND SPEC INDEX PREFRENCES
  useEffect(() => {
    dispatch(getSpecIndexPreferenceAsync())
      .unwrap()
      .then(() => {
        // setIsLoading(false);
      });

    const payload = {
      similarTo: similarTo,
      businessTermIds: businessTermId,
      dataScope: dataScope,
    };

    dispatch(getSpecsList(payload))
      .unwrap()
      .then(() => {
        setIsLoading(false);
      });

    return () => dispatch(resetTableFilters());
  }, []);

  useEffect(() => {
    const payload = {
      specStatusIds: filters?.["status"]
        ? filters?.["status"]?.map((selected) => selected?.value)
        : [],
      priorityIds: filters?.["priority"]
        ? filters?.["priority"]?.map((selected) => selected?.value)
        : [],
      functionIds: filters?.["function"]
        ? filters?.["function"]?.map((selected) => selected?.value)
        : [],
      requesterIds: filters?.["requester"]
        ? filters?.["requester"]?.map((selected) => selected?.value)
        : [],
      authorIds: filters?.["author"]
        ? filters?.["author"]?.map((selected) => selected?.value)
        : [],
      collaboratorIds: filters?.["collaborators"]
        ? filters?.["collaborators"]?.map((selected) => selected?.value)
        : [],
      urgency: filters?.["urgency"]
        ? filters?.["urgency"]?.map((selected) => selected?.value)
        : [],
      dueDateFrom: filters?.["dueDate"]?.dateRange?.startDate
        ? format(filters?.["dueDate"]?.dateRange?.startDate, "dd-MM-yyyy")
        : "",
      dueDateTo: filters?.["dueDate"]?.dateRange?.endDate
        ? format(filters?.["dueDate"]?.dateRange?.endDate, "dd-MM-yyyy")
        : "",
      similarTo: similarTo,
      businessTermIds: businessTermId,
      dataScope: dataScope,
    };
    dispatch(getSpecsList(payload));
  }, [filters]);

  const onGridReady = useCallback(() => {
    setIsGridReady(true);
  }, []);

  const onRowClick = useCallback((selectRows) => {
    setSelectedRows(selectRows);
  }, []);

  // GO TO FIRST PAGE
  const goToFirstPage = useCallback(() => {
    ref.current.api.paginationGoToFirstPage();
  }, []);

  // SHOW OVERLAY
  const showLoading = useCallback(() => {
    return ref?.current?.api?.showLoadingOverlay();
  }, []);

  // SHOW NO ROWS OVERLAY
  const showNoRowsOverlay = useCallback(() => {
    return ref.current.api.showNoRowsOverlay();
  }, []);

  // HIDE OVERLAY
  const hideOverlay = useCallback(() => {
    return ref?.current?.api?.hideOverlay();
  }, []);

  // SHOW OR HIDE LOADING OVERLAY
  useEffect(() => {
    if (ref && ref?.current && isGridReady) {
      if (isSpecsLoading) {
        showLoading();
      } else {
        hideOverlay();
      }
    }
  }, [ref, isSpecsLoading, isGridReady]);

  // SET ROWS
  useEffect(() => {
    const customRows = specs?.map((spec) => {
      return getRowData(spec);
    });
    setSpecsRowsForAgGrid(customRows);
  }, [specs]);

  // CUSTOM HEADER
  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeaderCell,
    };
  }, []);

  const allSpecIndexPrefrences = {
    specification: true,
    ...specIndexPrefrences,
  };
  const prefrenceColDef = specListingColDef()
    ?.filter((col) => allSpecIndexPrefrences[col?.field])
    .map((col) => {
      if (col?.field === "specification") {
        return {
          ...col,
          ...(canBulkAction && {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
          }),
        };
      }

      return col;
    });

  return (
    <>
      <Stack sx={{ width: 1, height: 1 }}>
        <SecondaryAppBar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Stack direction={"row"} alignItems={"center"} columnGap={1}>
            <Stack sx={{ width: { xs: "auto", sm: "350px" } }}>
              <InputField
                className={"searchInput"}
                variant={"outlined"}
                value={searchText}
                onChange={onFilterTextBoxChanged}
                placeholder='Search for specs by title'
                startAdornment={
                  <SearchOutlined
                    fontSize='small'
                    sx={(theme) => ({
                      color: theme.palette.gray.iconColor,
                    })}
                  />
                }
              />
            </Stack>
            {selectedRows?.length > 0 && (
              <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                <Typography
                  variant='spec_body'
                  sx={(theme) => ({
                    fontWeight: 600,
                    color: theme.palette.gray.iconColor,
                  })}>
                  Actions:
                </Typography>
                <StyledBadge
                  size='small'
                  color='primary'
                  badgeContent={
                    selectedRows?.length ? selectedRows?.length : 0
                  }>
                  <CustomIconButton
                    onClick={() => setDeleteOpen(true)}
                    tooltipTitle='Delete Selected Specifications'
                    Icon={DeleteOutline}
                    IconProps={{ fontSize: "small" }}
                    sx={{ p: 0 }}
                  />
                </StyledBadge>
              </Stack>
            )}
          </Stack>
          <Stack direction='row' columnGap={1} alignItems={"center"}>
            {isFilterApplied && (
              <Button
                variant='outlined'
                color='error'
                sx={{ height: "30px" }}
                onClick={() => dispatch(resetTableFilters())}
                startIcon={<ClearRounded />}>
                Clear All Filter
              </Button>
            )}
            {specIndexPrefrences && (
              <SpecColumnFilter columns={specListingColDef()} />
            )}
            {canAddSpec && (
              <LoadingButton
                startIcon={<AddRounded />}
                sx={{ height: "30px" }}
                loading={creatSpecLoading}
                onClick={() => handleCreateSpec()}>
                Add New Spec
              </LoadingButton>
            )}
          </Stack>
        </SecondaryAppBar>
        {isLoading ? (
          <Stack
            justifyContent='center'
            alignItems='center'
            sx={{
              height: 1,
              width: 1,
            }}>
            <CircularProgress size={60} />
          </Stack>
        ) : (
          <AgGridComponent
            ref={ref}
            columnDefs={prefrenceColDef}
            data={specsRowsForAgGrid}
            components={components}
            loadingOverlayComponent={CustomOverlayLoadingComponent}
            noRowsOverlayComponent={(props) => (
              <NoRowsOverlayCustomComponentSpecListing {...props} />
            )}
            rowModelType='clientSide'
            onGridReady={onGridReady}
            paginationPageSize={25}
            noRowsMessage={
              isFilterApplied || searchText?.length
                ? "No specifications found related to search term or filter applied."
                : `There are no specifications at this time. Click on "Add New Spec" to get started.`
            }
            rowSelection='multiple'
            noRowsOverlayComponentParamsObj={{
              isSpecListingAndEmpty: !(isFilterApplied || searchText?.length),
              canAddSpec: canAddSpec,
              handleCreateSpec: handleCreateSpec,
              creatSpecLoading: creatSpecLoading,
            }}
            onRowClick={onRowClick}
          />
        )}
      </Stack>
      <DeleteConfirmationModal
        isOpen={deleteOpen}
        handleClose={() => setDeleteOpen(false)}
        message='Are you sure you want to delete all the selected specifications?'
        description='This action cannot be undone!'
        handleDelete={handleDeleteSelectedSpecs}
        loading={isDeleteLoading}
      />
    </>
  );
};

export default SpecsListing;
