import MenuItem from "../../common/MenuItem";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ExpandMoreRoundedIcon } from "../../assets";
import { updateSpecification } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import CustomPopover from "../../common/CustomPopover";
import TooltipTypography from "../../common/TooltipTypography";
import { getFunctionsAsync } from "../../store/slices/functions";
import { Stack, Typography, alpha, useTheme } from "@mui/material";
import FunctionsMenuSkeleton from "../../common/Skeletons/FunctionsMenuSkeleton";
import { updateSpecFunction } from "../../store/slices/userSpec";

const FunctionDropdown = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { specId } = useParams();
  const { specData } = useSelector((state) => state.spec);
  const { specFunctions, isFunctionsLoading } = useSelector(
    (state) => state.functions
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentSpecFunction, setCurrentSpecFunction] = useState({
    id: specData?.specFunctionId,
    tag: specData?.specFunction,
  });

  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateFunction = (func) => {
    setCurrentSpecFunction({
      id: func?.id,
      tag: func?.tag,
    });
    handleClose();

    const payload = {
      specification: {
        spec_function_id: func?.id,
      },
    };

    updateSpecification({
      payload: payload,
      id: specId,
    }).then((response) => {
      dispatch(
        updateSpecFunction({
          id: func?.id,
          tag: func?.tag,
        })
      );
      // DO SOMETHING ON FUNCTION UPDATE SUCCESS - MOST PROBABLY UPDATE SAVED STATUS
    });
  };

  useEffect(() => {
    if (open) {
      dispatch(getFunctionsAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (currentSpecFunction) {
      dispatch(updateSpecFunction(currentSpecFunction));
    }
  }, [currentSpecFunction]);

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} columnGap={1}>
        <Typography variant='spec_caption' color={"gray.main"}>
          Function:
        </Typography>

        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
          onClick={handleClick}>
          <TooltipTypography
            label={currentSpecFunction?.tag || "Select function"}
            size='25'
            variant='spec_caption'
            displaySx={{ whiteSpace: "nowrap" }}
          />
          <ExpandMoreRoundedIcon />
        </Stack>
      </Stack>
      <CustomPopover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          maxHeight: "330px",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {isFunctionsLoading ? (
          <FunctionsMenuSkeleton />
        ) : (
          <Stack rowGap={1}>
            {specFunctions?.length > 0 ? (
              specFunctions?.map((func, index) => (
                <MenuItem
                  key={index}
                  itemText={<TooltipTypography label={func?.tag} />}
                  onClick={() => handleUpdateFunction(func)}
                  itemProps={{
                    sx: {
                      height: "12px",
                      backgroundColor:
                        func?.id === currentSpecFunction?.id
                          ? alpha(theme.palette.primary.light, 0.3)
                          : "",
                    },
                  }}
                />
              ))
            ) : (
              <Typography
                variant='spec_h3'
                color={"error.main"}
                textAlign={"center"}>
                No Functions Available!
              </Typography>
            )}
          </Stack>
        )}
      </CustomPopover>
    </>
  );
};

export default FunctionDropdown;
