export function convertDateTimeToCustomFormat(dateTimeString) {
  // Parse the given date and time string
  const dateObject = new Date(dateTimeString);

  // Months array to map the month number to its name
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get the month, day, hour, and minute
  const month = months[dateObject.getMonth()];
  const day = dateObject.getDate();
  const hour = dateObject.getHours();
  const minute = dateObject.getMinutes();

  // Determine AM/PM
  const ampm = hour >= 12 ? "PM" : "AM";

  // Adjust hour to 12-hour format
  const formattedHour = hour % 12 || 12;

  // Construct the custom format string
  return `${month} ${day}, ${formattedHour}:${
    (minute < 10 ? "0" : "") + minute
  } ${ampm}`;
}

export function convertDateToCustomFormat(dateTimeString) {
  // Parse the given date and time string
  const dateObject = new Date(dateTimeString);

  // Months array to map the month number to its name
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get the month, day, hour, and minute
  const month = months[dateObject.getMonth()];
  const day = dateObject.getDate();
  // Construct the custom format string
  return `${month} ${day}`;
}

export function convertDateTimeForComments(dateTimeString) {
  // Parse the given date and time string
  const dateObject = new Date(dateTimeString);

  // Get the month, day, hour, and minute
  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();
  const hour = dateObject.getHours();
  const minute = dateObject.getMinutes();

  // Determine AM/PM
  const ampm = hour >= 12 ? "PM" : "AM";

  // Adjust hour to 12-hour format
  const formattedHour = hour % 12 || 12;

  // Construct the custom format string 8:15 AM on 3/5/23
  return `${formattedHour}:${
    (minute < 10 ? "0" : "") + minute
  } ${ampm} on ${day}/${
    (month < 10 ? "0" : "") + month
  }/${dateObject.getFullYear()}`;
}
