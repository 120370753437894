import React, { useState } from "react";
import CustomIconButton from "../../common/CustomIconButton";
import { MoreVertIcon } from "../../assets";
import CustomPopover from "../../common/CustomPopover";
import {
  ContentCopyOutlined,
  DeleteOutlined,
  RestorePageOutlined,
  UploadFileOutlined,
} from "@mui/icons-material";
import MenuItem from "../../common/MenuItem";
import {
  createSpecCopy,
  deleteSpec,
  publishTemplate,
  unPublishTemplate,
} from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { HTTP_OK } from "../../constants/statusCodes";
import { toaster } from "../../common/Toaster";
import { defaultMessage } from "../../constants/messages";
import { CircularProgress } from "@mui/material";
import DeleteConfirmationModal from "../../common/DeleteConfirmationModal";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_PATH } from "../../router/routes";
import { SpecPublishModal } from "../SpecPublishModal";
import { set } from "lodash";
import { updatePublishedAsTemplate } from "../../store/slices/userSpec";

const SpecMoreMenu = ({ isPreview = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { specId } = useParams();
  const { user } = useSelector((state) => state.userAuth);
  const { specData, publishedAsTemplate } = useSelector((state) => state.spec);

  const superAdmin = user?.superAdmin;

  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isMakeCopyLoading, setIsMakeCopyLoading] = useState(false);
  const [isPublishOpen, setIsPublishOpen] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMakeSpecCopy = async () => {
    setIsMakeCopyLoading(true);
    try {
      const response = await createSpecCopy({
        payload: { template_id: specData?.id },
      });

      setIsMakeCopyLoading(false);
      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }
      const id = response?.data?.specification?.id;
      const newUrl = `${window.location.origin}/specs/spec/${id}/`;
      toaster({
        type: "success",
        message: response?.data?.toaster?.data?.message,
      });
      if (id) {
        window.open(newUrl, "_blank");
      }
    } catch (error) {
      setIsMakeCopyLoading(false);
      toaster({ type: "error", message: defaultMessage });
    }
  };

  const handleDeleteSpec = async () => {
    setIsDeleteLoading(true);
    try {
      const response = await deleteSpec(specId);
      setIsDeleteLoading(false);

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }

      toaster({
        type: "success",
        message: response?.data?.toaster?.data?.message,
      });
      navigate(ROUTE_PATH.SPECS);
    } catch (error) {
      setIsDeleteLoading(false);
      toaster({ type: "error", message: defaultMessage });
    }
  };

  const handleDeleteClick = () => {
    setIsDeleteOpen(true);
    handleClose();
  };

  const handlePublishUnpublish = () => {
    if (publishedAsTemplate) {
      unPublishTemplate({ templateId: specId })
        .then((response) => {
          if (response?.status !== HTTP_OK) {
            throw new Error(defaultMessage);
          }
          dispatch(updatePublishedAsTemplate(false));
          toaster({
            type: "success",
            message: response?.data?.toaster?.data?.message,
          });
        })
        .catch((error) => {
          toaster({ type: "error", message: error });
        });
    } else {
      publishTemplate({ specId: specId })
        .then((response) => {
          if (response?.status !== HTTP_OK) {
            throw new Error(defaultMessage);
          }
          dispatch(updatePublishedAsTemplate(true));
          navigate(ROUTE_PATH.TEMPLATES);
          toaster({
            type: "success",
            message: response?.data?.toaster?.data?.message,
          });
        })
        .catch((error) => {
          toaster({ type: "error", message: error });
        });
    }
  };

  const menuActions = [
    {
      id: 1,
      label: publishedAsTemplate ? "Unpublish" : "Publish",
      icon: publishedAsTemplate ? (
        <RestorePageOutlined fontSize='small' />
      ) : (
        <UploadFileOutlined fontSize='small' />
      ),
      operation: (e) => {
        handleClose();
        setIsPublishOpen(true);
      },
    },
    {
      id: 2,
      label: "Make a Copy",
      icon: isMakeCopyLoading ? (
        <CircularProgress size={24} />
      ) : (
        <ContentCopyOutlined fontSize='small' />
      ),
      operation: (e) => handleMakeSpecCopy(e),
    },
    {
      id: 3,
      label: "Delete",
      icon: <DeleteOutlined fontSize='small' />,
      operation: (e) => handleDeleteClick(e),
    },
  ];

  const filteredMenuActions = menuActions.filter((action) => {
    if (!superAdmin && action.id === 1) {
      return false; // Exclude menu item with id: 1 when superAdmin is true
    }
    if (isPreview && action.id === 2) {
      return false; // Exclude menu item with id: 2 when isPreview is 2
    }
    return true; // Include all other menu items
  });

  return (
    <>
      <CustomIconButton
        tooltipTitle='More'
        Icon={MoreVertIcon}
        onClick={handleClick}
      />
      <CustomPopover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {filteredMenuActions?.map((action, i) => (
          <MenuItem
            key={i}
            Icon={action?.icon}
            itemText={action?.label}
            onClick={action?.operation}
          />
        ))}
      </CustomPopover>
      <DeleteConfirmationModal
        message='Are you sure you want to delete this specification?'
        description="This action can't be undone."
        isOpen={isDeleteOpen}
        handleClose={() => {
          setIsDeleteOpen(false);
        }}
        handleDelete={handleDeleteSpec}
        loading={isDeleteLoading}
      />
      <SpecPublishModal
        isOpen={isPublishOpen}
        onClose={() => setIsPublishOpen(false)}
        onSubmit={handlePublishUnpublish}
        isPublished={publishedAsTemplate}
      />
    </>
  );
};

export default SpecMoreMenu;
