import React from "react";
import {
  Close,
  CheckOutlined,
  DeleteOutline,
  BorderColorOutlined,
} from "@mui/icons-material";
import { CircularProgress, Stack, Tooltip } from "@mui/material";

import { CommentActionButton } from "../style";

const CommentActionButtons = ({
  isEditable,
  toggleEdit,
  onSubmit,
  onCancel,
  onDelete,
  loading,
}) => {
  const iconStyle = {
    height: "17px",
    width: "17px",
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        columnGap: "8px",
      }}>
      {!isEditable && (
        <>
          <Tooltip title='Edit' placement='bottom' arrow>
            <CommentActionButton onClick={toggleEdit}>
              <BorderColorOutlined sx={iconStyle} />
            </CommentActionButton>
          </Tooltip>
          <Tooltip title='Delete' placement='bottom' arrow>
            <CommentActionButton onClick={onDelete}>
              <DeleteOutline sx={iconStyle} />
            </CommentActionButton>
          </Tooltip>
        </>
      )}
      {isEditable && (
        <>
          <Tooltip title='Save' placement='bottom' arrow>
            <CommentActionButton onClick={onSubmit} disabled={loading}>
              {!loading ? (
                <CheckOutlined sx={iconStyle} />
              ) : (
                <CircularProgress
                  color='primary'
                  size={18}
                  sx={{
                    display: "flex",
                  }}
                />
              )}
            </CommentActionButton>
          </Tooltip>
          <Tooltip title='Cancel' placement='bottom' arrow>
            <CommentActionButton onClick={onCancel} disabled={loading}>
              <Close sx={iconStyle} />
            </CommentActionButton>
          </Tooltip>
        </>
      )}
    </Stack>
  );
};

export default CommentActionButtons;
