import {
  updComment,
  deleteComment,
  getAutocompleteUsersComments,
} from "../../../services";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toaster } from "../../../common/Toaster";
import { HTTP_OK } from "../../../constants/statusCodes";
import CommentActionButtons from "../CommentActionButtons";
import { stringAvatar } from "../../../utils/commonUtility";
import PromptTagField from "../../../common/PromptTagField";
import { defaultMessage } from "../../../constants/messages";
import { specSecondary } from "../../../constants/theme/colors";
import { Grid, Typography, Avatar, Stack } from "@mui/material";
import { convertDateTimeForComments } from "../../../utils/convertData";
import DeleteConfirmationModal from "../../../common/DeleteConfirmationModal";

const CommentReplyCard = ({
  child,
  parentId,
  onUpdateComment,
  handleUpdateCommentsOnDelete,
}) => {
  const { user } = useSelector((state) => state.userAuth);
  const { specId } = useParams();

  const [isEditable, setIsEditable] = useState(false);
  const [commentBody, setCommentBody] = useState(child.body);
  const [commentPlainBody, setCommentPlainBody] = useState(
    child.plainBody || ""
  );
  const [mentionedUsers, setMentionedUsers] = useState(child.mentions || []);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isChildDeleteLoading, setIsChildDeleteLoading] = useState(false);
  const [updatCommentLoading, setUpdateCommentLoading] = useState(false);

  const handleCommentChange = (event, newValue, plainText, mentions) => {
    const uniqueMentions = mentions.filter(
      (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
    );
    setCommentBody(newValue);
    setCommentPlainBody(plainText);
    setMentionedUsers(uniqueMentions);
  };

  const toggleEdit = () => {
    setIsEditable((prev) => !prev);
  };

  const fetchUsers = (query, callback) => {
    getAutocompleteUsersComments({ query: query, specId: specId })
      .then((res) => {
        // Transform the users to what react-mentions expects
        return res?.data?.users?.map((user) => ({
          display: `${user?.firstName} ${user?.lastName}`,
          id: user?.id,
        }));
      })
      .then(callback);
  };

  const onCommentUpdate = () => {
    const payload = {
      comment: {
        body: commentBody,
        plain_body: commentPlainBody,
        mentions: mentionedUsers,
      },
    };
    setUpdateCommentLoading(true);
    updComment({ commentId: child.id, payload: payload, specId: specId })
      .then((r) => {
        setUpdateCommentLoading(false);
        if (r?.status !== HTTP_OK) {
          throw new Error(defaultMessage);
        }
        // set updated comment to local state
        onUpdateComment({
          comment: r?.data?.comment,
          isParent: false,
          parentId: parentId,
        });
        setIsEditable(false);
      })
      .catch((e) => {
        setUpdateCommentLoading(false);
        toaster({ type: "error", message: e });
      });
  };

  const onCancel = () => {
    setIsEditable(false);
    setCommentBody(child.body);
  };

  const onCommentDeleteDelete = () => {
    setIsChildDeleteLoading(true);
    deleteComment({ specId: specId, commentId: child.id })
      .then((r) => {
        setIsChildDeleteLoading(false);
        if (r?.status !== HTTP_OK) {
          throw new Error(defaultMessage);
        }
        setOpenDeleteModal(false);
        handleUpdateCommentsOnDelete({
          id: child.id,
          isParent: false,
          parentId: parentId,
        });
      })
      .catch((e) => {
        setIsChildDeleteLoading(false);
        toaster({ type: "error", message: e });
      });
  };

  const onDelete = () => {
    setOpenDeleteModal(true);
  };

  return (
    <Stack key={child.id} pb={1}>
      <Stack sx={{ width: 1, py: 1 }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}>
          <Stack direction={"row"} alignItems={"center"} columnGap={1}>
            <Avatar
              sx={(theme) => ({
                bgcolor: specSecondary[50],
                height: "32px",
                width: "32px",
              })}>
              <Typography variant='spec_body' color={"secondary.main"}>
                {stringAvatar(child?.user || "")?.children}
              </Typography>
            </Avatar>

            <Stack>
              <Typography variant='spec_body'>{child?.user}</Typography>

              <Typography
                variant='spec_caption'
                sx={{ fontWeight: 400 }}
                color='gray.main'>
                {convertDateTimeForComments(child.createdAt)}
              </Typography>
            </Stack>
          </Stack>

          {user?.id === child?.userId && (
            <CommentActionButtons
              isEditable={isEditable}
              toggleEdit={toggleEdit}
              onSubmit={onCommentUpdate}
              onCancel={onCancel}
              onDelete={onDelete}
              loading={updatCommentLoading}
            />
          )}
        </Stack>
      </Stack>
      <Grid item xs={12}>
        <PromptTagField
          value={commentBody}
          isView={!isEditable}
          data={fetchUsers}
          handleChange={handleCommentChange}
          singleLine={false}
          trigger='@'
        />
      </Grid>
      <DeleteConfirmationModal
        message='Are you sure you want to delete this comment?'
        description='This Action can not be undone!'
        isOpen={openDeleteModal}
        loading={isChildDeleteLoading}
        handleDelete={onCommentDeleteDelete}
        handleClose={() => setOpenDeleteModal(false)}
      />
    </Stack>
  );
};

export default CommentReplyCard;
