import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultExceptionHandler } from "../../utils/defaultExceptionHandler";
import { HTTP_OK } from "../../constants/statusCodes";
import {
  getSpecAutoCompleteUser,
  getSpecData,
  getSpecDataset,
  getSpecHistory,
  getSpecVisualization,
} from "../../services";

const initialState = {
  // WHOLE SPEC DETAILS STATES
  specData: null,
  isSpecLoading: false,
  isSpecLoadingForBusinessRequirements: false,

  //SPEC HISTORY
  specHistory: {},
  isSpecHistoryLoading: false,
  // SPEC VISUAL DATASET
  specVisualDataset: {},
  isSpecVisualDatasetLoading: false,

  //SPEC VISUALISATION STATES
  specVisualizationError: false,
  isVisualisationloading: false,
  specVisualization: {},

  //SPEC AUTO COMPLETE USER
  specAutoCompleteUser: [],
  isSpecAutoCompleteUserLoading: false,

  // VISUAL DRAWER STATE
  isVisualConfigOpen: false,
  selectedChart: null,

  // SHARE MODAL STATE
  isShareModalOpen: false,
  shareModalData: null,

  specFunction: null,
  specIndustry: null,
  publishedAsTemplate: false,

  // SAVED STATUS
  autoSaved: false,

  // CURRENTLY COPIED VISUALIZATION
  copiedVisualization: null,

  // IS SIDE DRAWER OPEN
  isSideDrawerOpen: false,

  // IS KPI BI MODAL OPEN
  isKpiBiModalOpen: false,
  kpiBiModalObj: null,
};

export const getSpecVisualizationAsync = createAsyncThunk(
  "spec/getSpecVisualizationAsync",
  async (payload) => {
    try {
      const response = await getSpecVisualization(payload?.specId);

      if (response?.status !== HTTP_OK) {
        throw new Error("Something went wrong!");
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

export const getSpecDataAsync = createAsyncThunk(
  "spec/getSpecDataAsync",
  async (payload) => {
    try {
      const response = await getSpecData(payload?.specId);

      if (response?.status !== HTTP_OK) {
        throw new Error("Something went wrong!");
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

export const getSpecDataForBusinessRequirementsAsync = createAsyncThunk(
  "spec/getSpecDataForBusinessRequirementsAsync",
  async (payload) => {
    try {
      const response = await getSpecData(payload?.specId);

      if (response?.status !== HTTP_OK) {
        throw new Error("Something went wrong!");
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

export const getSpecDataSetAsync = createAsyncThunk(
  "spec/getSpecDataSetAsync",
  async (payload) => {
    try {
      const response = await getSpecDataset(payload?.specId);

      if (response?.status !== HTTP_OK) {
        throw new Error("Something went wrong!");
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

export const getSpecHistoryAsync = createAsyncThunk(
  "spec/getSpecHistoryAsync",
  async (payload) => {
    try {
      const response = await getSpecHistory(payload);

      if (response?.status !== HTTP_OK) {
        throw new Error("Something went wrong!");
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

export const getSpecAutoCompleteUserAsync = createAsyncThunk(
  "spec/getSpecAutoCompleteUserAsync",
  async (payload) => {
    try {
      const response = await getSpecAutoCompleteUser(payload);

      if (response?.status !== HTTP_OK) {
        throw new Error("Something went wrong!");
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

const specSlice = createSlice({
  name: "spec",
  initialState: initialState,

  reducers: {
    setIsVisualConfigOpen: (state, action) => {
      state.isVisualConfigOpen = action.payload.open;
      state.selectedChart = action?.payload?.chart ?? null;
    },

    setShareModalOpen: (state, action) => {
      state.isShareModalOpen = true;
      state.shareModalData = action?.payload?.shareModalData || null;
    },
    setShareModalClose: (state) => {
      state.isShareModalOpen = false;
      state.shareModalData = null;
    },
    resetSpecVisualization: (state) => {
      state.specVisualization = {};
    },
    updateSpecVisualization: (state, action) => {
      state.specVisualization = action.payload;
    },
    updateSelectedChart: (state, action) => {
      state.selectedChart = action.payload;
    },

    updateSpecIndustry: (state, action) => {
      state.specIndustry = action.payload;
    },
    updateSpecFunction: (state, action) => {
      state.specFunction = action.payload;
    },
    updatePublishedAsTemplate: (state, action) => {
      state.publishedAsTemplate = action.payload;
    },
    updateAutoSaved: (state, action) => {
      state.autoSaved = action.payload;
    },
    setCopiedVisualization: (state, action) => {
      state.copiedVisualization = action.payload;
    },
    setIsSideDrawerOpen: (state, action) => {
      state.isSideDrawerOpen = action.payload;
    },

    setIsKpiBiModalOpen: (state, action) => {
      state.isKpiBiModalOpen = true;
      state.kpiBiModalObj = action.payload.kpiBiModalObj || null;
    },

    resetKpiBiModal: (state) => {
      state.isKpiBiModalOpen = false;
      state.kpiBiModalObj = null;
    },

    updateKpiBi: (state, action) => {
      if (state?.specData?.specKpis) {
        state.specData.specKpis = action.payload;
      }
    },
  },

  extraReducers: (builder) => {
    // SPEC VISUALISATION BUILDER FUNCTIONS
    builder.addCase(getSpecVisualizationAsync.pending, (state) => {
      state.isVisualisationloading = true;
    });
    builder.addCase(getSpecVisualizationAsync.fulfilled, (state, action) => {
      state.isVisualisationloading = false;
      state.specVisualization = action.payload?.specVisualization;
    });
    builder.addCase(getSpecVisualizationAsync.rejected, (state) => {
      state.isVisualisationloading = false;
      state.specVisualizationError = true;
    });

    // GET SPEC DATA BUILDER FUNCTIONS
    builder.addCase(getSpecDataAsync.pending, (state) => {
      state.isSpecLoading = true;
    });
    builder.addCase(getSpecDataAsync.fulfilled, (state, action) => {
      state.isSpecLoading = false;
      state.specData = action.payload?.specification;
      state.publishedAsTemplate =
        action.payload?.specification?.publishedAsTemplate;
    });
    builder.addCase(getSpecDataAsync.rejected, (state) => {
      state.isSpecLoading = false;
    });
    // GET SPEC DATASET FUNCTIONS
    builder.addCase(getSpecDataSetAsync.pending, (state) => {
      state.isSpecVisualDatasetLoading = true;
    });
    builder.addCase(getSpecDataSetAsync.fulfilled, (state, action) => {
      state.isSpecVisualDatasetLoading = false;
      state.specVisualDataset = action.payload;
    });
    builder.addCase(getSpecDataSetAsync.rejected, (state) => {
      state.isSpecVisualDatasetLoading = false;
    });
    // GET SPEC HISTORY FUNCTIONS
    builder.addCase(getSpecHistoryAsync.pending, (state) => {
      state.isSpecHistoryLoading = true;
    });
    builder.addCase(getSpecHistoryAsync.fulfilled, (state, action) => {
      state.isSpecHistoryLoading = false;
      state.specHistory = action.payload;
    });
    builder.addCase(getSpecHistoryAsync.rejected, (state) => {
      state.isSpecHistoryLoading = false;
    });
    // GET SPEC AUTOCOMPLETE FUNCTIONS
    builder.addCase(getSpecAutoCompleteUserAsync.pending, (state) => {
      state.isSpecAutoCompleteUserLoading = true;
    });
    builder.addCase(getSpecAutoCompleteUserAsync.fulfilled, (state, action) => {
      state.isSpecAutoCompleteUserLoading = false;
      state.specAutoCompleteUser = action.payload;
    });
    builder.addCase(getSpecAutoCompleteUserAsync.rejected, (state) => {
      state.isSpecAutoCompleteUserLoading = false;
    });

    // GET SPEC DATA BUILDER FUNCTIONS
    builder.addCase(
      getSpecDataForBusinessRequirementsAsync.pending,
      (state) => {
        state.isSpecLoadingForBusinessRequirements = true;
      }
    );
    builder.addCase(
      getSpecDataForBusinessRequirementsAsync.fulfilled,
      (state, action) => {
        state.isSpecLoadingForBusinessRequirements = false;
        state.specData = action.payload?.specification;
      }
    );
    builder.addCase(
      getSpecDataForBusinessRequirementsAsync.rejected,
      (state) => {
        state.isSpecLoadingForBusinessRequirements = false;
      }
    );
  },
});

export const {
  setIsVisualConfigOpen,
  setShareModalOpen,
  setShareModalClose,
  resetSpecVisualization,
  updateSpecVisualization,
  updateSelectedChart,
  updateSpecIndustry,
  updateSpecFunction,
  updatePublishedAsTemplate,
  updateAutoSaved,
  setCopiedVisualization,
  setIsSideDrawerOpen,
  setIsKpiBiModalOpen,
  resetKpiBiModal,
  updateKpiBi,
} = specSlice.actions;
export default specSlice.reducer;
