import { PERMISSIONS, ROLES } from "../../constants";

export const checkAvailable = (currentUser, specData) =>
  currentUser?.superAdmin ||
  currentUser.role === ROLES.ADMIN ||
  Boolean(
    specData.specAccesses?.find(
      (access) =>
        access.user?.id === currentUser.id &&
        [PERMISSIONS.ADMIN, PERMISSIONS.EDITOR, PERMISSIONS.COMMENTER].includes(
          access.permission
        )
    )
  );
