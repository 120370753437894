import React, { useEffect, useState } from "react";

import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useParams } from "react-router-dom";

// import { useRequestBuilder } from '../../../../hooks/use_request_builder';

import { StyledUserMenu } from "./style";
import {
  ArrowDownwardOutlined,
  KeyboardArrowDown,
  SearchOutlined,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getSpecAutoCompleteUserAsync } from "../../../store/slices/userSpec";

const UserFilter = ({ title = "User", selected, setSelected }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state?.spec);
  const { specId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [userList, setUserList] = useState([]);
  const [filteredList, setFilteredList] = useState(userList || []);

  // const [fetchUsers] = useRequestBuilder('GET', 'users/autocomplete_user');

  const isOpen = Boolean(anchorEl);

  const getUsers = (query = "") => {
    dispatch(getSpecAutoCompleteUserAsync({ specId }));
  };

  const onSearchChange = (e) => {
    const search = e.target.value;
    setSearchInput(search);
  };

  useEffect(() => {
    if (searchInput) {
      const filteredData = filteredList.filter((option) =>
        option.name?.toLowerCase().includes(searchInput.toLowerCase())
      );
      setFilteredList(filteredData);
    } else {
      setFilteredList(userList);
    }
  }, [searchInput, userList]);

  const handleOnClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchInput("");
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelected((prev) => [...prev, value]);
    } else {
      setSelected((prev) => [...prev.filter((id) => id !== value)]);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    setUserList(
      reduxState?.specAutoCompleteUser?.users?.map((user) => ({
        id: user?.id,
        name: `${user?.firstName} ${user?.lastName}`,
      }))
    );
  }, [reduxState?.specAutoCompleteUser]);

  return (
    <>
      <Button
        // size="small"
        onClick={handleOnClick}
        variant='outlined'
        sx={{
          border: `1px solid ${theme.palette.grey[300]}`,
          color:
            selected.length > 0
              ? theme.palette.primary.dark
              : theme.palette.secondary.textColor,
        }}
        endIcon={
          <KeyboardArrowDown
            style={{ fontSize: "15px", color: theme.palette.grey[500] }}
          />
        }>
        <Box
          component='span'
          sx={{
            fontSize: "14px",
            lineHeight: "22px",
          }}>
          {title}
        </Box>
        {Boolean(selected?.length) && (
          <Badge
            badgeContent={selected?.length}
            color='primary'
            sx={{ ml: 2, mr: 1 }}
          />
        )}
      </Button>
      <StyledUserMenu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
        <TextField
          value={searchInput}
          onChange={onSearchChange}
          sx={{ pb: 1 }}
          InputProps={{
            startAdornment: <SearchOutlined />,
            placeholder: "Search Users",
            type: "search",
          }}
        />
        <Stack>
          {filteredList?.length > 0 ? (
            filteredList.map((user, i) => (
              <FormControlLabel
                key={i}
                value={user?.id}
                checked={selected.some((id) => id === user?.id)}
                onChange={handleFilterChange}
                control={<Checkbox />}
                label={
                  <Typography
                    variant='spec_body'
                    key={i}
                    sx={{ wordBreak: "break-word" }}>
                    {user?.name}
                  </Typography>
                }
              />
            ))
          ) : (
            <Typography variant='spec_body' sx={{ textAlign: "center" }}>
              No users found.
            </Typography>
          )}
        </Stack>
      </StyledUserMenu>
    </>
  );
};

export default UserFilter;
