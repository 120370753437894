import {
  NUMBER,
  PERCENT,
  CURRENCY,
  TYPE_LABEL,
  STATE_BUSINESS_TERMS_LABEL,
} from "../../constants";
import { MuiSelectField } from "../../common/style";
import { InputField } from "../../common/InputField";
import { STAT_METRIC_ARR } from "../SelectVisuals/utils";
import { Box, InputLabel, MenuItem, Stack, Typography } from "@mui/material";

export const StatisticModal = ({
  setParams,
  mockDataset,
  params,
  isPreview = false,
}) => {
  const getBusinessTerm = (term) => {
    if (term.toLowerCase() === "default") {
      return "Default";
    } else {
      return term;
    }
  };

  return (
    <Box py={2} px={4}>
      <Stack direction='column' spacing={2}>
        <InputField
          isLabel={true}
          type='text'
          value={params?.title}
          name='Title'
          label='Statistic Title'
          placeholder='Enter a title for the statistic'
          fullWidth
          noTextError
          id={`label`}
          onChange={(e) => {
            setParams({ ...params, title: e?.target?.value });
          }}
          disabled={isPreview}
        />

        <Stack
          direction='column'
          spacing={2}
          alignItems='left'
          sx={{ width: "100%" }}>
          <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
            <InputLabel>
              <Typography variant='spec_inputLabel'>Select Metric</Typography>
            </InputLabel>
            <MuiSelectField
              disabled={isPreview}
              id='x'
              value={params?.x || 0}
              onChange={(e) => {
                setParams({
                  ...params,
                  x: e.target.value,
                });
              }}
              sx={{
                width: "100%",
                "#table": { display: "flex" },
                ":has(div)": { color: "#8c8c8c", alignItems: "end" },
              }}>
              <MenuItem value={0} disabled>
                <Typography variant='spec_body'>Select Metric</Typography>
              </MenuItem>
              {mockDataset?.table.columns.map((item, index) => {
                if (
                  [NUMBER.type, PERCENT.type, CURRENCY.type].includes(item.text)
                ) {
                  return (
                    <MenuItem
                      key={index}
                      value={item.columnId}
                      sx={{ display: "flex", alignItems: "end" }}>
                      <Typography
                        mr={1}
                        color='text.primary'
                        variant='spec_body'>
                        {item?.name}
                      </Typography>
                      <Typography color='#8c8c8c' variant='spec_body'>
                        [{TYPE_LABEL[item?.text]} -{" "}
                        {getBusinessTerm(
                          STATE_BUSINESS_TERMS_LABEL[item?.state]
                        )}
                        ]
                      </Typography>
                    </MenuItem>
                  );
                }
              })}
            </MuiSelectField>
          </Stack>
        </Stack>

        <Stack
          direction='column'
          spacing={2}
          alignItems='left'
          sx={{ width: "100%" }}>
          <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
            <InputLabel>
              <Typography variant='spec_inputLabel'>
                Select Aggregation
              </Typography>
            </InputLabel>
            <MuiSelectField
              disabled={isPreview}
              id='func'
              placeholder='Select Aggregation'
              value={params?.metric || 0}
              onChange={(e) => {
                setParams({
                  ...params,
                  metric: e.target.value,
                });
              }}
              sx={{
                width: "100%",
                "#table": { display: "flex" },
                ":has(div)": { color: "#8c8c8c", alignItems: "end" },
              }}>
              <MenuItem value={0} disabled>
                <Typography variant='spec_body'>Select Aggregation</Typography>
              </MenuItem>
              {STAT_METRIC_ARR.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item.id}
                  sx={{ display: "flex", alignItems: "end" }}>
                  <Typography mr={1} color='text.primary' variant='spec_body'>
                    {item.label}
                  </Typography>
                </MenuItem>
              ))}
            </MuiSelectField>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
