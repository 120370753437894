import { Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import QuillRTEComponent from "../../common/QuillRTEComponent";

const UserStoryPreview = () => {
  const { specData } = useSelector((state) => state.spec);

  return (
    <Stack rowGap={4}>
      <Stack direction={"row"} columnGap={1} sx={{ alignItems: "center" }}>
        <Typography variant='spec_h3'>User Story </Typography>
      </Stack>
      <Stack
        pl={3}
        columnGap={2}
        sx={{
          flexDirection: { xs: "column" },
          rowGap: 2,
          height: "max-content",
        }}>
        <Typography variant='spec_body'>
          {`As a ${specData?.businessNeed?.persona || "-"}, I want to ${
            specData?.businessNeed?.action || "-"
          } so that ${specData?.businessNeed?.outcome || "-"}`}
        </Typography>
      </Stack>

      {specData.otherBusinessConsiderations && (
        <Stack
          className='business-requirements'
          sx={{
            mt: { xs: 1 },
            width: { xs: 1 },
            // pb: { xs: "66px", sm: "42px" },
            ".quill-editor": {
              maxHeight: "max-content",
            },
            ".quill-editor .ql-container": {
              border: "none",
            },
          }}
          rowGap={0.7}>
          <Typography variant='spec_inputLabel'>
            Additional Information:
          </Typography>
          <QuillRTEComponent
            value={specData?.otherBusinessConsiderations}
            setValue={() => {}}
            isPreview={true}
            placeholder='No Additional Information Found.'
          />
        </Stack>
      )}
    </Stack>
  );
};

export default UserStoryPreview;
