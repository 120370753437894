import { useEffect, useState } from "react";

import {
  BorderColorOutlined,
  ControlCameraOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MainCard from "../MainCard";

export const TitleBlock = ({
  element,
  index,
  handleDelete,
  moveItem,
  onChange,
  isDisable = false,
  isPreview = false,
}) => {
  // title is not dragable (by customer request)
  const [title, setTitle] = useState("");

  useEffect(() => {
    setTitle(element?.text || "");
  }, [element]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      title !== element?.text && onChange({ text: title });
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [title]);

  return (
    <MainCard
      title='Title'
      sx={{ height: "100%" }}
      secondary={
        <Stack direction='row' spacing={1}>
          {!isPreview && (
            <>
              <Tooltip title='Drag' arrow>
                <IconButton>
                  <ControlCameraOutlined
                    fontSize='small'
                    style={{ color: "#424242" }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title='Delete' arrow>
                <IconButton
                  onClick={() => handleDelete(index)}
                  className='cancelDraggableElement'>
                  <DeleteOutline
                    fontSize='small'
                    style={{ color: "#424242" }}
                  />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Stack>
      }>
      {!isPreview ? (
        <TextField
          className='cancelDraggableElement'
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder='Enter title here'
          InputProps={{
            readOnly: isDisable,
            endAdornment: !isDisable && (
              <InputAdornment position='end'>
                <BorderColorOutlined
                  fontSize='small'
                  style={{ color: "#424242" }}
                />
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <Typography variant='spec_h1'>{title}</Typography>
      )}
    </MainCard>
  );
};
