import { Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";

const LastUpdatedByCell = (props) => {
  return (
    <Stack>
      <Typography variant='spec_body'>
        {props.data.lastUpdatedByName}
      </Typography>
      <Typography variant='spec_body'>
        on {format(props?.data?.updatedAt, "MM/dd/yyyy")} at{" "}
        {format(props?.data?.updatedAt, "hh:mm a")}
      </Typography>
    </Stack>
  );
};

export default LastUpdatedByCell;
