import { getFunctions } from "../../services";
import { HTTP_OK } from "../../constants/statusCodes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultExceptionHandler } from "../../utils/defaultExceptionHandler";

const initialState = {
  // FUNCTIONS STATES
  specFunctions: [],
  isFunctionsLoading: false,
  isFunctionAddEditOpen: false,
  functionAddEditData: null,
  functionAddEditType: "ADD",
};

export const getFunctionsAsync = createAsyncThunk(
  "functions/getFunctionsAsync",
  async (payload) => {
    try {
      const response = await getFunctions({ admin: payload?.admin });

      if (response?.status !== HTTP_OK) {
        throw new Error("Something went wrong!");
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

const functionsSlice = createSlice({
  name: "functions",
  initialState: initialState,

  reducers: {
    setFunctionAddEditModal: (state, action) => {
      state.functionAddEditData = action?.payload?.functionAddEditData
        ? action?.payload?.functionAddEditData
        : null;
      state.isFunctionAddEditOpen = true;
      state.functionAddEditType = action?.payload?.functionAddEditType
        ? action.payload.functionAddEditType
        : "ADD";
    },
    resetFunctionAddEditModal: (state, action) => {
      state.functionAddEditData = null;
      state.isFunctionAddEditOpen = false;
      state.functionAddEditType = "ADD";
    },
  },

  extraReducers: (builder) => {
    // GET FUNCTIONS BUILDER FUNCTIONS
    builder.addCase(getFunctionsAsync.pending, (state, action) => {
      state.isFunctionsLoading = true;
    });
    builder.addCase(getFunctionsAsync.fulfilled, (state, action) => {
      state.isFunctionsLoading = false;
      state.specFunctions = action?.payload?.specFunctions;
    });
    builder.addCase(getFunctionsAsync.rejected, (state) => {
      state.isFunctionsLoading = false;
    });
  },
});

export const { setFunctionAddEditModal, resetFunctionAddEditModal } =
  functionsSlice.actions;
export default functionsSlice.reducer;
