import StatusCell from "./StatusCell";
import SpecificationCell from "./SpecificationCell";
import AvatarWithLabelCell from "./AvatarWithLabelCell";
import MultipleAvatarGroup from "./MultipleAvatarGroup";
import {
  getCompanyUsersOptions,
  getSpecFunctions,
  getSpecPriorities,
  getSpecStatuses,
} from "../../services";
import { LEVELS } from "../../constants";
import PriorityCell from "./PriorityCell";

export const customValueFormatter = (params) => {
  if (!params?.value || params.value === 0) {
    return "-";
  }
  return params?.value;
};

export const specListingColDef = () => {
  return [
    {
      field: "specification",
      editable: false,
      headerName: "Specs",
      cellRenderer: SpecificationCell,
      resizable: true,
      lockPosition: "left",
      unSortIcon: true,
      pinned: true,
      width: 300,
      wrapText: true,
      autoHeight: true,
      cellDataType: "text",
      minWidth: 150,
    },
    {
      field: "status",
      editable: false,
      headerName: "Status",
      resizable: true,
      unSortIcon: true,
      cellRenderer: (props) => <StatusCell isListing={true} {...props} />,
      filterParams: {
        isCustomFilter: true,
        loadOptions: getSpecStatuses,
      },
      cellDataType: "text",
      suppressCellSelection: true,
      minWidth: 150,
    },
    {
      field: "priority",
      editable: false,
      headerName: "Priority",
      resizable: true,
      unSortIcon: true,
      cellRenderer: (props) => <PriorityCell isListing={true} {...props} />,
      filterParams: {
        isCustomFilter: true,
        loadOptions: getSpecPriorities,
      },
      cellDataType: "text",
      suppressCellSelection: true,
      minWidth: 150,
    },
    {
      field: "function",
      editable: false,
      headerName: "Function",
      resizable: true,
      unSortIcon: true,
      filterParams: {
        isCustomFilter: true,
        loadOptions: getSpecFunctions,
      },
      cellDataType: "text",
      minWidth: 150,
    },
    {
      field: "requester",
      editable: false,
      headerName: "Requester",
      resizable: true,
      unSortIcon: true,
      cellRenderer: AvatarWithLabelCell,
      filterParams: {
        isCustomFilter: true,
        loadOptions: getCompanyUsersOptions,
      },
      cellDataType: "text",
      minWidth: 150,
    },
    {
      field: "author",
      editable: false,
      headerName: "Author",
      resizable: true,
      unSortIcon: true,
      width: "250px",
      cellRenderer: AvatarWithLabelCell,
      filterParams: {
        isCustomFilter: true,
        loadOptions: getCompanyUsersOptions,
      },
      cellDataType: "text",
      minWidth: 150,
    },
    {
      field: "collaborators",
      editable: false,
      headerName: "Collaborators",
      resizable: true,
      unSortIcon: true,
      cellRenderer: MultipleAvatarGroup,
      filterParams: {
        isCustomFilter: true,
        loadOptions: getCompanyUsersOptions,
      },
      cellDataType: "text",
      minWidth: 150,
    },
    {
      field: "urgency",
      editable: false,
      headerName: "Urgency",
      resizable: true,
      unSortIcon: true,
      cellStyle: {
        textTransform: "capitalize",
      },
      filterParams: {
        isCustomFilter: true,
        staticOptions: [
          { value: LEVELS.LOW, label: LEVELS.LOW_LABEL },
          {
            value: LEVELS.MEDIUM,
            label: LEVELS.MEDIUM_LABEL,
          },
          {
            value: LEVELS.HIGH,
            label: LEVELS.HIGH_LABEL,
          },
        ],
      },
      cellDataType: "text",
      minWidth: 150,
    },
    {
      field: "comments",
      editable: false,
      headerName: "Comments",
      resizable: true,
      valueFormatter: customValueFormatter,
      unSortIcon: true,
      cellDataType: "text",
      minWidth: 150,
    },
    {
      minWidth: 150,
      field: "visuals",
      editable: false,
      headerName: "Visuals",
      resizable: true,
      valueFormatter: customValueFormatter,
      unSortIcon: true,
      cellDataType: "text",
    },
    {
      minWidth: 150,
      field: "labels",
      editable: false,
      headerName: "Labels",
      resizable: true,
      valueFormatter: customValueFormatter,
      unSortIcon: true,
      cellDataType: "text",
    },
    {
      minWidth: 150,
      field: "dueDate",
      editable: false,
      headerName: "Due Date",
      resizable: true,
      unSortIcon: true,
      cellDataType: "text",
      filterParams: {
        isCustomFilter: true,
        isDateFilter: true,
      },
    },
    {
      field: "updatedAt",
      editable: false,
      headerName: "Last Updated",
      width: 250,
      minWidth: 150,
      resizable: true,
      unSortIcon: true,
      cellDataType: "text",
    },
  ];
};
