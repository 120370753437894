import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { InputField } from "../../common/InputField";
import { updateSpecification } from "../../services";
import { Box, Button, Stack, Typography } from "@mui/material";
import { AddOutlined, DeleteOutline } from "@mui/icons-material";
import InfoTooltip from "../../common/InfoTooltip";
import { updateAutoSaved } from "../../store/slices/userSpec";
import CriteriaStatus from "./CriteriaStatus";
import _ from "lodash";
import { StyledStatusChip } from "./style";

function AcceptanceCriteriaForm({ isPreview = false }) {
  const dispatch = useDispatch();
  const { specId } = useParams();
  const { specData } = useSelector((state) => state.spec);

  const [fields, setFields] = useState(
    specData?.acceptanceCriteria ? [...specData.acceptanceCriteria] : []
  );

  const [initialFields, setInitialFields] = useState(
    specData?.acceptanceCriteria ? [...specData.acceptanceCriteria] : []
  );

  const addField = () => {
    setFields([
      ...fields,
      { id: Date.now(), description: "", _destroy: false, __isNew__: true },
    ]);
  };

  const removeField = (id, field) => {
    if (field?.__isNew__) {
      setFields(fields.filter((field) => field.id !== id));
    } else {
      setFields(
        fields.map((field) =>
          field?.id === id ? { ...field, _destroy: true } : field
        )
      );
    }
  };

  const handleChange = (id, value) => {
    setFields(
      fields.map((field) =>
        field.id === id ? { ...field, description: value } : field
      )
    );
  };

  const handleApprovalStatusChange = (id, status) => {
    setFields(
      fields.map((field) =>
        field.id === id ? { ...field, approval: status } : field
      )
    );
  };

  const handleSave = () => {
    if (_.isEqual(fields, initialFields)) return;

    const payload = fields
      .filter((criterion) => criterion.description.length)
      ?.map((criterion) => {
        if (criterion?.__isNew__) {
          return {
            // ...(criterion?.approval_id && {
            //   approval_id: criterion?.approval_id,
            // }),
            description: criterion?.description,
            _destroy: criterion?._destroy,
          };
        }

        return criterion;
      });

    if (payload?.length) {
      updateSpecification({
        payload: {
          specification: {
            acceptance_criteria_attributes: payload,
          },
        },
        id: specId,
      }).then((response) => {
        setFields(response?.data?.specification?.acceptanceCriteria);
        setInitialFields(response?.data?.specification?.acceptanceCriteria);
        dispatch(updateAutoSaved(true));
      });
    }
  };

  return !isPreview ? (
    <Stack rowGap={4} onMouseLeave={handleSave}>
      <Stack direction={"row"} columnGap={1} sx={{ alignItems: "center" }}>
        <Typography variant='spec_h3'>Acceptance Criteria</Typography>
        <InfoTooltip
          infoText='The conditions that must be met for a data feature to be accepted by the end user'
          placement='right'
          isInfoIcon={true}
        />
      </Stack>
      <Stack rowGap={2} pl={3}>
        {fields
          .filter((field) => !field?._destroy)

          .map((field, i) => (
            <DynamicField
              index={i}
              value={field?.description}
              key={field.id}
              id={field.id}
              removeField={removeField}
              handleChange={handleChange}
              field={field}
              handleApprovalStatusChange={handleApprovalStatusChange}
              approvalStatus={field.approval}
            />
          ))}
        <Button
          startIcon={<AddOutlined />}
          variant='outlined'
          color='secondary'
          onClick={addField}
          sx={{ width: { xs: "auto", sm: "300px" } }}>
          Add a Criteria
        </Button>
      </Stack>
    </Stack>
  ) : (
    fields?.length > 0 && (
      <Stack rowGap={4} onMouseLeave={handleSave}>
        <Stack direction={"row"} columnGap={1} sx={{ alignItems: "center" }}>
          <Typography variant='spec_h3'>Acceptance Criteria</Typography>
        </Stack>
        <Stack rowGap={2} pl={3}>
          {fields?.map((item, i) => (
            <Stack
              sx={{
                flexDirection: { xs: "column", md: "row" },
              }}
              columnGap={2}
              rowGap={1}
              alignItems={"flex-start"}>
              <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                <Typography variant='spec_body' sx={{ width: "16px" }}>
                  {i + 1}.
                </Typography>
                <StyledStatusChip
                  sx={{
                    minWidth: "120px !important",
                    dispaly: "flex",
                    justifyContent: "space-between",
                  }}
                  // CHIP LABEL
                  label={
                    <Typography
                      variant='spec_body'
                      sx={(theme) => ({
                        color: theme.palette.info.main,
                      })}>
                      {item.approval ? "Approved" : "Not Approved"}
                    </Typography>
                  }
                />
              </Stack>
              <Stack direction={"row"} columnGap={2} alignItems={"center"}>
                <Typography variant='spec_body'>
                  {item?.description || "-"}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    )
  );
}

function DynamicField({
  index,
  value,
  handleChange,
  id,
  removeField,
  field,
  handleSave,
  handleApprovalStatusChange,
  approvalStatus,
}) {
  const isEmpty = value?.trim()?.length <= 0;

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      columnGap={2}
      sx={{
        width: { xs: 1 },
      }}>
      <Typography variant='spec_body' sx={{ width: "10px" }}>
        {index + 1}.
      </Typography>
      <InputField
        value={value}
        onChange={(e) => {
          handleChange(id, e?.target?.value);
        }}
        placeholder='Input an acceptance criteria'
        // error={!field?.__isNew__ && isEmpty}
        onBlur={handleSave}
        // helperText={
        //   isEmpty
        //     ? field?.__isNew__
        //       ? ""
        //       : "You cleared existing acceptance criteria, it'll revert to previous version if not changed."
        //     : ""
        // }
        endAdornment={
          <DeleteOutline
            fontSize='small'
            onClick={() => removeField(id, field)}
            sx={(theme) => ({
              cursor: "pointer",
              color: theme.palette.gray.iconColor,
            })}
          />
        }
      />
      <CriteriaStatus
        handleApprovalStatusChange={(status) =>
          handleApprovalStatusChange(id, status)
        }
        approvalStatus={approvalStatus}
      />
    </Stack>
  );
}

export default AcceptanceCriteriaForm;
