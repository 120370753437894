import { getSubdomain } from "../../utils/commonUtility";

export const getCurrentCompany = (user, currentURL) => {
  // Check if the user is a super admin
  if (user?.superAdmin) {
    // Return the only company from the activeCompanies array
    return user?.activeCompanies[0];
  }

  // Check if the current URL contains a subdomain
  const subdomain = getSubdomain(currentURL);

  // Find the company in the activeCompanies array where the user has an admin role for the subdomain
  const companyWithAdminRole = user.activeCompanies.find((company) => {
    return company.subdomain === subdomain && company.role === "admin";
  });

  if (subdomain && companyWithAdminRole) {
    // Return the company where the user has an admin role for the subdomain
    return companyWithAdminRole;
  }

  // Check if the user is in a particular subdomain and not a company owner
  if (subdomain) {
    // Find the company matching the current subdomain
    const companyInSubdomain = user?.activeCompanies.find(
      (company) => company.subdomain === subdomain
    );

    if (companyInSubdomain) {
      return companyInSubdomain;
    }
  }

  // Find the first company in the activeCompanies array where the user is the owner
  const companyOwner = user?.activeCompanies?.find(
    (company) => company?.companyOwner
  );

  if (companyOwner) {
    // Return the company where the user is the owner
    return companyOwner;
  }

  // Return the first company from the activeCompanies array
  return user?.activeCompanies[0];
};
