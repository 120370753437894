const _ = require("lodash");

// we have historyData and convert to array of objects grouped by month and day
export function convertHistoryObjectToArray(inputObj) {
  const outputArr = [];
  const groupedChangesByMonth = {};
  for (const date in inputObj) {
    const changes = inputObj[date];
    const [year, month, day] = date.split("-");
    const monthTitle = new Date(year, parseInt(month) - 1).toLocaleString(
      "en-US",
      {
        month: "long",
        year: "numeric",
      }
    );
    const dayTitle = new Date(year, parseInt(month) - 1, day).toLocaleString(
      "en-US",
      {
        day: "numeric",
        month: "long",
      }
    );
    if (!groupedChangesByMonth[monthTitle]) {
      groupedChangesByMonth[monthTitle] = {};
    }
    if (!groupedChangesByMonth[monthTitle][dayTitle]) {
      groupedChangesByMonth[monthTitle][dayTitle] = [];
    }
    groupedChangesByMonth[monthTitle][dayTitle] =
      groupedChangesByMonth[monthTitle][dayTitle].concat(changes);
  }
  for (const monthTitle in groupedChangesByMonth) {
    const monthObj = groupedChangesByMonth[monthTitle];
    const daysArray = [];
    for (const dayTitle in monthObj) {
      daysArray.push({ title: dayTitle, changes: monthObj[dayTitle] });
    }
    outputArr.push({ title: monthTitle, days: daysArray });
  }
  return outputArr;
}

export function formatTimestampToTime(timestamp) {
  const options = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  return new Date(timestamp).toLocaleString("en-US", options);
}

// it is used to generate the readable title for the record type in the history
export const targetName = (recordType, original) => {
  switch (recordType) {
    case "SpecVisualization":
      return original?.displayName && original?.displayName?.length > 0
        ? original?.displayName
        : "Visualization";
    case "SpecMockdataTab":
      return original?.displayName && original?.displayName?.length > 0
        ? original?.displayName
        : "Mock Dataset";
    case "AcceptanceCriterion":
      return "Acceptance Criteria";
    case "SpecRequest":
      return "Information about the Request";
    case "BusinessTermSpecification":
      return "Business Term";
    case "DataScopeSpecification":
      return "Data Scope";
    case "BusinessNeed":
      return "User Story";
    default:
      return recordType;
  }
};

export function camelCaseToSentence(input) {
  // Remove leading and trailing spaces, if any
  input = input.trim();
  // Add a space before each capital letter and convert the first letter to uppercase
  const sentence = input.replace(/([A-Z])/g, " $1");

  // Capitalize the first letter
  return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
}

/**
 * Deep diff between two object-likes
 * @param  {Object} fromObject the original object
 * @param  {Object} toObject   the updated object
 * @return {Object}            a new object which represents the diff
 */
export function deepDiff(fromObject, toObject) {
  const changes = [];
  const label = fromObject[0]?.label ? fromObject[0].label : "";
  const buildPath = (path, obj, key) =>
    _.isUndefined(path) ? key : `${path}.${key}`;

  const walk = (fromObject, toObject, path) => {
    for (const key of _.keys(fromObject)) {
      if (!_.has(toObject, key)) {
        changes.push({ title: "deleted", from: _.get(fromObject, key) });
      }
    }

    for (const [key, to] of _.entries(toObject)) {
      const currentPath = buildPath(path, toObject, key);
      if (!_.has(fromObject, key)) {
        changes.push({ title: "added", to });
      } else {
        const from = _.get(fromObject, key);
        if (!_.isEqual(from, to)) {
          if (_.isObjectLike(to) && _.isObjectLike(from)) {
            walk(from, to, currentPath);
          } else {
            changes.push({
              title: key,
              label,
              from,
              to,
            });
          }
        }
      }
    }
  };

  walk(fromObject, toObject);

  return changes;
}

export const ACTION_OPTIONS = [
  { value: "create", label: "Added" },
  { value: "update", label: "Updated" },
  { value: "destroy", label: "Deleted" },
];

export const COMPONENTS_OPTIONS = [
  { value: "SpecVisualization", label: "Visualization" },
  { value: "AcceptanceCriterion", label: "Acceptance Criteria" },
  { value: "SpecRequest", label: "Information about the Request" },
  { value: "BusinessTermSpecification", label: "Business Term" },
  { value: "DataScopeSpecification", label: "Data Scope" },
  { value: "BusinessNeed", label: "User Story" },
  { value: "SpecMockdataTab", label: "Mock Dataset" },
];
