import React from "react";
import { ROUTE_PATH } from "../../../router/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { StyledTab, StyledTabs } from "./styled";
import { useMediaQuery, useTheme } from "@mui/material";

const tabs = [
  {
    label: "Specs",
    path: ROUTE_PATH?.SPECS,
  },
  {
    label: "Business Terms",
    path: ROUTE_PATH?.MAPPINGS_LIBRARY,
  },
  {
    label: "Templates",
    path: ROUTE_PATH?.TEMPLATES,
  },
];

const Navigables = ({ setOpen }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const isLgDown = useMediaQuery(theme.breakpoints.down("lg"));

  const handleChange = (_, newValue) => {
    if (isLgDown) {
      setOpen(false);
    }
    navigate(newValue);
  };

  const selectedTab = tabs.find((tab) => currentPath.includes(tab.path))?.path;

  return (
    <StyledTabs
      orientation={isLgDown ? "vertical" : "horizontal"}
      value={selectedTab}
      indicatorColor='primary'
      aria-label='secondary tabs example'>
      {tabs?.map((tab) => (
        <StyledTab
          key={tab?.path}
          value={tab?.path}
          label={tab?.label}
          selected={currentPath?.includes(tab?.path)}
          onClick={(e) => handleChange(e, tab?.path)}
        />
      ))}
    </StyledTabs>
  );
};

export default Navigables;
