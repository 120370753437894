import {
  getIndustriesAsync,
  resetIndustryAddEditModal,
} from "../../../store/slices/industries";
import { Field, Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { toaster } from "../../../common/Toaster";
import BaseModal from "../../../common/BaseModal";
import { useDispatch, useSelector } from "react-redux";
import { InputField } from "../../../common/InputField";
import { addIndustry, updateIndustry } from "../../../services";
import { industryNameValidationSchema } from "../../../utils/validationSchemas";

const IndustryAddEditModal = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const { isIndustryAddEditOpen, industryAddEditData, industryAddEditType } =
    useSelector((state) => state.industries);

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = (resetForm, validateForm) => {
    dispatch(resetIndustryAddEditModal());
    resetForm();
    validateForm();
  };

  const initialValues = {
    industry: industryAddEditData?.title ? industryAddEditData.title : "",
  };

  const handleChange = (field, value) => {
    field.onChange({
      target: {
        name: field.name,
        value: value,
      },
    });
  };

  const onSubmit = (values, { resetForm, validateForm }) => {
    if (industryAddEditType === "ADD") {
      setIsLoading(true);
      addIndustry({
        payload: {
          title: values.industry,
        },
      })
        .then((response) => {
          setIsLoading(false);
          dispatch(getIndustriesAsync());
          toaster({
            type: "success",
            message:
              response?.data?.toaster?.data?.message ||
              "Industry was successfully created.",
          });
          handleClose(resetForm, validateForm);
        })
        .catch((e) => {
          setIsLoading(false);
          toaster({
            type: "error",
            message: e,
          });
        });
    } else {
      setIsLoading(true);
      updateIndustry({
        industryId: industryAddEditData?.id,
        payload: {
          title: values.industry,
        },
      })
        .then((response) => {
          dispatch(getIndustriesAsync());
          setIsLoading(false);
          handleClose(resetForm, validateForm);

          toaster({
            type: "success",
            message:
              response?.data?.toaster?.data?.message ||
              "Industry Name was successfully updated.",
          });
        })
        .catch((e) => {
          setIsLoading(false);
          toaster({ type: "error", message: e });
        });
    }
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={industryNameValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      initialTouched={{ title: true }}
      validateOnMount={true}
      enableReinitialize={true}
      onSubmit={onSubmit}>
      {({ touched, errors, values, isValid, resetForm, validateForm }) => (
        <BaseModal
          title={
            industryAddEditType === "ADD" ? "Add Industry" : "Industry Edit"
          }
          isOpen={isIndustryAddEditOpen}
          handleClose={() => {
            handleClose(resetForm, validateForm);
          }}
          confirmTitle={industryAddEditType === "ADD" ? "Submit" : "Update"}
          handleSubmit={handleSubmitClick}
          isLoading={isLoading}
          submitDisable={errors && Object.keys(errors)?.length !== 0}>
          <Form>
            <Field name='industry'>
              {({ field, form }) => (
                <InputField
                  name='industry'
                  placeholder='Enter industry name here'
                  isLabel={true}
                  label='Industry Name'
                  variant={"outlined"}
                  value={field.value}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmitClick();
                    }
                  }}
                  onChange={({ currentTarget: input }) =>
                    handleChange(field, input.value)
                  }
                  onBlur={() => {
                    form.setTouched({
                      ...form.touched,
                      industry: true,
                    });
                  }}
                  error={form.touched.industry && Boolean(form.errors.industry)}
                  helperText={form.touched.industry && form.errors.industry}
                />
              )}
            </Field>
          </Form>
        </BaseModal>
      )}
    </Formik>
  );
};

export default IndustryAddEditModal;
