import { List, styled as MuiStyled } from "@mui/material";
import { specSecondary } from "../../constants/theme/colors";
import { SIDEBAR_WIDTH } from "../../constants";

export const DrawerList = MuiStyled(List)(({ theme }) => ({
  paddingTop: 0,
  position: "relative",

  "& .sidebar-link": {
    ".MuiListItemIcon-root": {
      color: theme.palette.gray.iconColor,
    },
    color: theme.palette.gray.textGray1,
    justifyContent: "center",
    ".MuiListItemText-root": {
      whiteSpace: "nowrap",
      span: {
        fontSize: "14px",
        fontFamily: "Poppins",
      },
    },
    ".MuiButtonBase-root": {
      borderLeft: `3px solid transparent`,
    },
    "&:hover .MuiButtonBase-root": {
      fontFamily: "Poppins",
      backgroundColor: specSecondary[50],
      borderLeft: `3px solid ${theme.palette.info.main}`,
      ".MuiListItemIcon-root": {
        color: theme.palette.info.main,
      },
    },
    "&.active .MuiButtonBase-root": {
      //   paddingLeft: "14px",
      backgroundColor: specSecondary[50],
      borderLeft: `3px solid ${theme.palette.info.main}`,
      ".MuiListItemIcon-root": {
        color: theme.palette.info.main,
      },
      ".MuiListItemText-root": {
        span: {
          fontWeight: 600,
        },
      },
    },
  },
}));

export const openedMixin = (theme) => ({
  width: SIDEBAR_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

export const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(6.7)})`,
});
