import _ from "lodash";

export const getSpecDataScopeRows = (scopes) => {
  return scopes?.map((scope) => {
    return {
      id: scope?.id,
      name: scope?.name,
      values: scope?.values ? scope?.values : "-",
      category: scope?.category,
      description: _.isEmpty(scope?.details) ? "-" : scope?.details,
      required: scope?.required,
      specificationsCount: scope.specificationsCount,
      originalObject: scope,
    };
  });
};
