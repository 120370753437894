import ActionCell from "./ActionCell";
import SpecCountCell from "./SpecCountCell";

const commonCellStyle = { wordBreak: "normal", lineHeight: "normal" };
export const getSpecDataScopeColumns = () => {
  return [
    // {
    //   field: "specificationsCount",
    //   editable: false,
    //   headerName: "Spec Count",
    //   resizable: false,
    //   unSortIcon: true,
    //   cellDataType: "text",
    //   pinned: true,
    //   flex: 1,
    //   width: 140,
    //   cellRenderer: SpecCountCell,
    //   cellStyle: commonCellStyle,
    // },
    {
      field: "action",
      editable: false,
      headerName: "Action",
      resizable: false,
      cellDataType: "text",
      width: 80,
      sortable: false,
      pinned: "left",
      cellRenderer: ActionCell,
    },
    {
      field: "name",
      editable: false,
      headerName: "Data scope",
      resizable: false,
      unSortIcon: true,
      cellDataType: "text",
      flex: 1,
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
      pinned: "left",
    },

    {
      field: "values",
      editable: false,
      headerName: "Values",
      resizable: false,
      unSortIcon: true,
      cellDataType: "text",
      flex: 1,
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
    },
    {
      field: "category",
      editable: false,
      headerName: "Category",
      resizable: false,
      unSortIcon: true,
      cellDataType: "text",
      flex: 1,
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
    },
    {
      field: "description",
      editable: false,
      headerName: "Description",
      resizable: false,
      unSortIcon: true,
      cellDataType: "text",
      flex: 1,
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
    },
    {
      field: "required",
      editable: false,
      headerName: "Required",
      resizable: false,
      unSortIcon: true,
      cellDataType: "text",
      flex: 1,
      minWidth: 150,
      cellStyle: {
        textTransform: "capitalize",
      },
    },
  ];
};
