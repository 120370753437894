import {
  Box,
  Grid,
  List,
  Stack,
  Button,
  Avatar,
  ListItem,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { ROLES_LABEL } from "../../constants";
import MainCard from "../../components/MainCard";
import { stringAvatar } from "../../utils/commonUtility";
import { ChipRole } from "../../common/ChipRole/ChipRole";
import { specSecondary } from "../../constants/theme/colors";
import { ROUTE_PATH } from "../../router/routes";

const Profile = () => {
  const { user, currentCompany } = useSelector((state) => state.userAuth);

  return (
    <Grid
      container
      sx={{
        backgroundColor: "white",
      }}>
      <Box m={3} sx={{ width: "100%" }}>
        <Grid container spacing={2.5}>
          <Grid item sx={{ flex: 1 }} xs={12} sm={5} md={4} lg={4} xl={3}>
            <MainCard
              headerSX={{
                display: "none",
              }}
              dividerSX={{
                display: "none",
              }}
              sx={{
                height: "100%",
                "& .MuiCardContent-root": {
                  display: "flex",
                  position: "relative",
                  // height: "100%",
                  alignItems: "center",
                },
              }}>
              <ChipRole
                role={user?.companyOwner ? "owner" : currentCompany?.role}
                // isSuperAdmin={user?.superAdmin}
                size='small'
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 5,
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={2.5} alignItems='center'>
                    <Avatar
                      sx={(theme) => ({
                        bgcolor: specSecondary[50],
                        height: "150px",
                        width: "150px",
                      })}>
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "56px",
                          fontWeight: 400,
                        }}
                        color={"secondary.main"}>
                        {
                          stringAvatar(`${user?.firstName} ${user?.lastName}`)
                            ?.children
                        }
                      </Typography>
                    </Avatar>
                    <Stack spacing={0.5} alignItems='center'>
                      <Typography variant='spec_h2'>{`${user?.firstName} ${user?.lastName}`}</Typography>
                      <Typography color='gray.main' variant='spec_body'>
                        {user?.companyOwner
                          ? "Owner"
                          : currentCompany.role
                          ? ROLES_LABEL[currentCompany?.role]
                          : ""}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </MainCard>
          </Grid>
          <Grid item sx={{ flex: 1 }} xs={12} sm={7} md={8} lg={8} xl={9}>
            <MainCard title='Personal Details' sx={{ height: "100%" }}>
              <List sx={{ py: 0 }}>
                <ListItem>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography variant='spec_body' color='gray.main'>
                          First Name
                        </Typography>
                        <Typography variant='spec_body'>
                          {user?.firstName}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography variant='spec_body' color='gray.main'>
                          Last Name
                        </Typography>
                        <Typography variant='spec_body'>
                          {user?.lastName}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography variant='spec_body' color='gray.main'>
                          Department
                        </Typography>
                        <Typography variant='spec_body'>
                          {user?.department}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography variant='spec_body' color='gray.main'>
                          Position
                        </Typography>
                        <Typography variant='spec_body'>
                          {user?.jobTitle}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography variant='spec_body' color='gray.main'>
                          Company name
                        </Typography>
                        <Typography variant='spec_body'>
                          {user?.company?.name}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography variant='spec_body' color='gray.main'>
                          Email
                        </Typography>
                        <Typography variant='spec_body'>
                          {user?.email}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </MainCard>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction='row'
              justifyContent='flex-end'
              alignItems='center'>
              <Button
                component={NavLink}
                to={ROUTE_PATH.EDIT_PROFILE}
                variant='contained'>
                <Box
                  component='span'
                  sx={{
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}>
                  Edit My Profile
                </Box>
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Profile;
