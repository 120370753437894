import { HelpOutlineRounded, InfoOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React from "react";

const InfoTooltip = ({
  infoText = "",
  grayTooltip,
  placement = "top",
  isInfoIcon = false,
}) => {
  const IconComponent = isInfoIcon ? InfoOutlined : HelpOutlineRounded;

  return (
    <Tooltip
      componentsProps={{
        popper: {
          sx: {
            [`& .${tooltipClasses?.arrow}`]: {
              // color: (theme) => theme.palette.primary?.main,
            },
            [`& .${tooltipClasses?.tooltip}`]: {
              // backgroundColor: (theme) => theme.palette.primary?.main,
              // boxShadow: (theme) => theme?.palette?.shadows?.shadow1,
              // textAlign: "center",
            },
          },
        },
      }}
      title={
        <Typography
          variant='spec_tooltip'
          sx={(theme) => ({
            backgroundColor: "transparent",
            color: theme.palette.secondary.white,
          })}>
          {infoText}
        </Typography>
      }
      // title='Enter an AI prompt that will be used to generate the mock data you expect to see.'
      placement={placement}
      arrow>
      <IconComponent
        fontSize={"14px"}
        sx={(theme) => ({
          color: theme.palette.info.main,
          cursor: "pointer",
        })}
      />
    </Tooltip>
  );
};

export default InfoTooltip;
