import {
  Stack,
  Avatar,
  Button,
  Dialog,
  useTheme,
  Typography,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import React from "react";
import LoadingButton from "../LoadingButton";
import CustomIconButton from "../CustomIconButton";
import { CloseOutlined, Delete } from "@mui/icons-material";

const DeleteConfirmationModal = ({
  dialogWidth,
  message = "",
  isOpen = false,
  loading = false,
  description = "",
  confirmationLabel = "Delete",
  handleClose = () => {},
  handleDelete = () => {},
  ...props
}) => {
  const theme = useTheme();
  return (
    <Dialog
      keepMounted
      open={isOpen}
      scroll='paper'
      onClose={() => {}}
      PaperProps={{
        sx: {
          width: dialogWidth ? dialogWidth : "425px",
          borderRadius: theme.spacing(1),
        },
      }}
      {...props}>
      <DialogTitle
        sx={(theme) => ({
          padding: `9px 16px`,
          display: "flex",
          justifyContent: "flex-end",
        })}>
        <CustomIconButton
          Icon={CloseOutlined}
          onClick={loading ? () => {} : handleClose}
          sx={{ padding: 0 }}
        />
      </DialogTitle>
      <DialogContent>
        <Stack alignItems={"center"} spacing={3.5}>
          <Avatar
            sx={{
              bgcolor: "error.main",
              width: 72,
              height: 72,
              fontSize: "1.75rem",
            }}>
            <Delete
              sx={(theme) => ({ color: theme?.palette?.secondary.white })}
            />
          </Avatar>
          <Stack alignItems={"center"} textAlign={"center"}>
            <Typography
              variant='spec_h3'
              sx={{ textTransform: "none", wordBreak: "break-word" }}>
              {message}
            </Typography>
            <Typography variant='spec_body' sx={{ wordBreak: "break-word" }}>
              {description}
            </Typography>
          </Stack>
          <Stack
            direction='row'
            spacing={2}
            justifyContent={"center"}
            sx={{ width: 1 }}>
            <Button
              variant='outlined'
              color='gray'
              onClick={handleClose}
              disabled={loading}>
              Cancel
            </Button>
            <LoadingButton
              variant='contained'
              color='error'
              onClick={handleDelete}
              loading={loading}>
              {confirmationLabel}
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
