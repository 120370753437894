import React from "react";
import { StyledAppBar, StyledToolbar } from "./style";
import { Stack, Typography } from "@mui/material";

const DataSettingsAppBar = () => {
  return (
    <StyledAppBar>
      <StyledToolbar>
        <Stack px={2}>
          <Typography variant='spec_h2'>Data Settings</Typography>
        </Stack>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default DataSettingsAppBar;
