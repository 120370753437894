import { Box } from '@mui/material';

export const BoxIcon = () => {
  return (
    <Box
      style={{
        transform: 'rotate(90deg)',
        // marginTop: '-20px',
        // marginBottom: '-30px',
      }}>
      <svg
        width='85%'
        height='85%'
        viewBox='0 0 60 61'
        preserveAspectRatio='xMidYMid meet'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M43.4038 38.8066H42.8759L42.8771 13.3535H43.4039C43.6952 13.3535 43.9312 13.1174 43.9312 12.8262C43.9312 12.5349 43.6952 12.2988 43.4039 12.2988H41.2945C41.0032 12.2988 40.7672 12.5349 40.7672 12.8262C40.7672 13.1174 41.0032 13.3535 41.2945 13.3535H41.8224L41.8212 38.8066H41.2944C41.0031 38.8066 40.7671 39.0427 40.7671 39.334C40.7671 39.6252 41.0031 39.8613 41.2944 39.8613H43.4038C43.6951 39.8613 43.9312 39.6252 43.9312 39.334C43.9312 39.0427 43.695 38.8066 43.4038 38.8066Z'
          fill='#808080'
        />
        <path
          d='M31.0535 17.5723C31.3448 17.5723 31.5808 17.3362 31.5808 17.0449C31.5808 16.7537 31.3447 16.5176 31.0535 16.5176H28.9441C28.6529 16.5176 28.4167 16.7537 28.4167 17.0449C28.4167 17.3362 28.6529 17.5723 28.9441 17.5723H29.472L29.4708 43.0254H28.9441C28.6529 43.0254 28.4167 43.2615 28.4167 43.5527C28.4167 43.844 28.6529 44.0801 28.9441 44.0801H31.0535C31.3448 44.0801 31.5808 43.844 31.5808 43.5527C31.5808 43.2615 31.3448 43.0254 31.0535 43.0254H30.5256L30.5268 17.5723H31.0535Z'
          fill='#808080'
        />
        <path
          d='M18.7032 21.791C18.9944 21.791 19.2305 21.5549 19.2305 21.2637C19.2305 20.9724 18.9944 20.7363 18.7032 20.7363H16.5938C16.3026 20.7363 16.0665 20.9724 16.0665 21.2637C16.0665 21.5549 16.3026 21.791 16.5938 21.791H17.1217L17.1205 47.2441H16.5938C16.3025 47.2441 16.0664 47.4802 16.0664 47.7715C16.0664 48.0627 16.3025 48.2988 16.5938 48.2988H18.7031C18.9944 48.2988 19.2305 48.0627 19.2305 47.7715C19.2305 47.4802 18.9944 47.2441 18.7031 47.2441H18.1752L18.1764 21.791H18.7032Z'
          fill='#808080'
        />
        <path
          d='M17.6472 14.9343C18.2297 14.9343 18.7019 14.4621 18.7019 13.8796C18.7019 13.2972 18.2297 12.825 17.6472 12.825C17.0647 12.825 16.5925 13.2972 16.5925 13.8796C16.5925 14.4621 17.0647 14.9343 17.6472 14.9343Z'
          fill='#808080'
        />
        <path
          d='M17.6472 19.1531C18.2297 19.1531 18.7019 18.6809 18.7019 18.0984C18.7019 17.5159 18.2297 17.0437 17.6472 17.0437C17.0647 17.0437 16.5925 17.5159 16.5925 18.0984C16.5925 18.6809 17.0647 19.1531 17.6472 19.1531Z'
          fill='#808080'
        />
        <path
          d='M29.9976 14.9343C30.58 14.9343 31.0522 14.4621 31.0522 13.8796C31.0522 13.2972 30.58 12.825 29.9976 12.825C29.4151 12.825 28.9429 13.2972 28.9429 13.8796C28.9429 14.4621 29.4151 14.9343 29.9976 14.9343Z'
          fill='#808080'
        />
        <path
          d='M29.9963 47.7703C30.5788 47.7703 31.051 47.2981 31.051 46.7156C31.051 46.1331 30.5788 45.6609 29.9963 45.6609C29.4139 45.6609 28.9417 46.1331 28.9417 46.7156C28.9417 47.2981 29.4139 47.7703 29.9963 47.7703Z'
          fill='#808080'
        />
        <path
          d='M42.3503 47.7703C42.9328 47.7703 43.405 47.2981 43.405 46.7156C43.405 46.1331 42.9328 45.6609 42.3503 45.6609C41.7679 45.6609 41.2957 46.1331 41.2957 46.7156C41.2957 47.2981 41.7679 47.7703 42.3503 47.7703Z'
          fill='#808080'
        />
        <path
          d='M42.3503 43.5515C42.9328 43.5515 43.405 43.0793 43.405 42.4968C43.405 41.9143 42.9328 41.4421 42.3503 41.4421C41.7679 41.4421 41.2957 41.9143 41.2957 42.4968C41.2957 43.0793 41.7679 43.5515 42.3503 43.5515Z'
          fill='#808080'
        />
        <rect x='25.2' y='21.2988' width='9.6' height='18' rx='0.5' fill='#808080' />
        <rect x='13.2' y='30.2988' width='9' height='9' rx='0.5' fill='#808080' />
        <rect x='37.8' y='21.2988' width='9' height='9' rx='0.5' fill='#808080' />
      </svg>
    </Box>
  );
};
