import { Typography } from "@mui/material";
import React from "react";
import { StyledMenuItem } from "./style";

const MenuItem = ({
  Icon,
  itemProps,
  iconProps,
  itemText = "",
  customItemText,
  typographyProps,
  onClick = () => {},
}) => {
  return (
    <StyledMenuItem onClick={onClick} {...itemProps}>
      {Icon}
      {customItemText ? (
        customItemText
      ) : (
        <Typography variant='spec_body' {...typographyProps}>
          {itemText}
        </Typography>
      )}
    </StyledMenuItem>
  );
};

export default MenuItem;
