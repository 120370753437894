import { Box, Card } from "@mui/material";
import { styled as MuiStyled } from "@mui/material/styles";

export const LoginContainer = MuiStyled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const LoginCard = MuiStyled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: theme.spacing(2),
  boxShadow:
    theme?.palette?.shadows?.shadow1 ?? "0px 4px 8px rgba(38, 105, 93, 0.1)",
  marginTop: theme.spacing(-4),

  [theme.breakpoints.up("xs")]: {
    width: "auto",
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: 0,
  },

  [theme.breakpoints.up("sm")]: {
    width: "485px",
  },
}));

export const LoginContent = MuiStyled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    padding: theme.spacing(3),
  },

  // [theme.breakpoints.up("sm")]: {
  //   padding: theme.spacing(3),
  // },

  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },

  [theme.breakpoints.up("xl")]: {
    padding: theme.spacing(5),
  },
}));
