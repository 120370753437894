import { useEffect, useState } from "react";

import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { KeyboardArrowDown, SearchOutlined } from "@mui/icons-material";
import { specPrimary } from "../../constants/theme/colors";

// import { OptionLabel } from "../specs/navbar/style";

export const Filter = ({
  title,
  options = [],
  activeOptions,
  onOptionClick,
  selectAll = false,
  onSelectAll,
  disabled = false,
  buttonSx,
}) => {
  const theme = useTheme();

  const [searchString, setSearchString] = useState("");
  const [data, setData] = useState(options);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAll, setSelectedAll] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (searchString) {
      const filteredData = data.filter((option) =>
        option.label?.toLowerCase().includes(searchString.toLowerCase())
      );
      setData(filteredData);
    } else {
      setData(options);
    }
  }, [searchString, options]);

  useEffect(() => {
    const hasAllOptions = (options, activeOptions) => {
      for (const option of options) {
        if (!activeOptions?.has(option.id)) {
          return false;
        }
      }
      return true;
    };

    if (options && Array.isArray(options) && options?.length) {
      if (hasAllOptions(options, activeOptions)) {
        setSelectedAll(true);
      } else {
        setSelectedAll(false);
      }
    }
  }, [options, activeOptions]);

  return (
    <>
      <Button
        disabled={disabled}
        size='small'
        onClick={handleClick}
        variant='outlined'
        sx={{
          border: `1px solid ${theme.palette.grey[300]} !important`,
          ...buttonSx,
        }}
        endIcon={
          <KeyboardArrowDown
            style={{ fontSize: "10px", color: theme.palette.grey[500] }}
          />
        }>
        <Typography variant='spec_button' color={"black"}>
          {title}
        </Typography>
        {Boolean(activeOptions.size) && (
          <Badge
            badgeContent={activeOptions.size}
            color='primary'
            sx={{ ml: 2, mr: 1 }}
          />
        )}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className='cancelDraggableElement'
        sx={{
          "& .MuiMenu-list": {
            maxHeight: "260px",
            p: 1,
          },
        }}>
        <TextField
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          sx={{ pb: 1 }}
          InputProps={{
            startAdornment: <SearchOutlined style={{ color: "#424242" }} />,
            placeholder: "Search for filter",
            type: "search",
          }}
        />
        <Stack sx={{ maxHeight: "214px", overflowY: "auto" }}>
          <FormGroup>
            {selectAll && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedAll}
                    onChange={() => {
                      const updatedSelectedAll = !selectedAll;
                      setSelectedAll(updatedSelectedAll);
                      onSelectAll(updatedSelectedAll);
                    }}
                  />
                }
                label={<Typography variant='spec_body'>All</Typography>}
              />
            )}
            {data
              ?.filter((op) => op?.id !== undefined)
              ?.map((option) => (
                <FormControlLabel
                  key={option.label + title}
                  control={
                    <Checkbox
                      checked={activeOptions?.has(option.id)}
                      onChange={() => onOptionClick(option)}
                    />
                  }
                  label={
                    <Typography variant='spec_body'>{option.label}</Typography>
                  }
                />
              ))}
          </FormGroup>
        </Stack>
      </Menu>
    </>
  );
};

// options: {id: string | number, label: string}
Filter.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  activeOptions: PropTypes.instanceOf(Map).isRequired,
  onOptionClick: PropTypes.func,
  selectAll: PropTypes.bool,
};
