import { alpha, createTheme } from "@mui/material";
import { specGray } from "./colors";
import { themePalette } from "./colors";

export const themeInputs = createTheme(themePalette, {
  components: {
    MuiTextField: {
      input: {
        "&:-webkit-autofill": {
          "-webkit-box-shadow": "0 0 0 100px #000 inset",
          "-webkit-text-fill-color": themePalette.palette.secondary.white,
        },
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            "& .MuiOutlinedInput-root": {
              height: "38px",
              "& fieldset": {
                borderRadius: "7px",
              },
              input: {
                "&:-webkit-autofill": {
                  transitionDelay: "9999s",
                  transitionProperty: "background-color, color",
                },
                "&::placeholder": {
                  fontWeight: 400,
                  color: themePalette.palette.gray.dark,
                },
              },
              "&:hover fieldset": {
                borderColor: themePalette.palette.primary.main,
              },
              "&.Mui-focused fieldset": {
                // border: `1px solid ${specPrimary[700]}`,
              },
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "23px",
              "&::placeholder": {
                opacity: 0.5,
              },
            },
            "& .MuiFormHelperText-root": {
              margin: 0,
              fontFamily: "Poppins",
            },
            "& .MuiOutlinedInput-root.Mui-error": {
              "&:hover fieldset": {
                borderColor: themePalette.palette.error.main,
              },
              "&.Mui-focused fieldset": {
                border: `2px solid ${themePalette.palette.error.main}`,
                borderRadius: "8px !important",
              },
            },
            "& .MuiOutlinedInput-root.Mui-disabled": {
              backgroundColor: specGray[50],
              "& fieldset": {
                borderColor: specGray[300],
              },
              "&:hover fieldset": {
                borderColor: specGray[300],
              },
              "&.Mui-focused fieldset": {
                border: `2px solid ${specGray[300]}`,
                borderRadius: "8px !important",
              },
            },
            "&.inputHeightAuto": {
              "& .MuiOutlinedInput-root": {
                height: "auto",
              },
            },
            // SEARCH INPUT FIELD
            "&.searchInput": {
              "& .MuiOutlinedInput-root": {
                height: "38px",
                "& fieldset": {
                  borderColor: "transparent",
                  borderRadius: "7px",
                },

                input: {
                  "&:-webkit-autofill": {
                    transitionDelay: "9999s",
                    transitionProperty: "background-color, color",
                  },
                  "&::placeholder": {
                    fontWeight: 400,
                    color: themePalette.palette.gray.dark,
                  },
                },
                "&:hover fieldset": {
                  // backgroundColor: alpha(
                  //   themePalette.palette.primary.main,
                  //   0.3
                  // ),
                  borderColor: themePalette.palette.primary.main,
                },
                "&.Mui-focused fieldset": {
                  border: `2px solid ${themePalette.palette.primary.main}`,
                  borderRadius: "8px !important",
                },
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "23px",
                "&::placeholder": {
                  opacity: 0.5,
                },
              },
              "& .MuiFormHelperText-root": {
                margin: 0,
                fontFamily: "Poppins",
              },
              "& .MuiOutlinedInput-root.Mui-error": {
                "& fieldset": {
                  backgroundColor: alpha(themePalette.palette.error.main, 0.2),
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  backgroundColor: alpha(themePalette.palette.error.main, 0.2),
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  backgroundColor: alpha(themePalette.palette.error.main, 0.2),
                  borderColor: "transparent",
                  borderRadius: "8px !important",
                },
              },
              "& .MuiOutlinedInput-root.Mui-disabled": {
                borderColor: "transparent",
                backgroundColor: specGray[100],
                "& fieldset": {
                  backgroundColor: specGray[100],
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                  backgroundColor: specGray[100],
                },
                "&.Mui-focused fieldset": {
                  backgroundColor: specGray[100],
                  borderColor: "transparent",
                  borderRadius: "8px !important",
                },
              },
            },
          },
        },
      ],
    },
  },
});
