// ====================================================================================================
import _ from "lodash";

import {
  groupDataByDayTime,
  groupDataByDayTimeAndX,
  groupDataByMonth,
  groupDataByMonthAndX,
  groupDataByWeek,
  groupDataByWeekAndX,
  groupDataByXAndDayTime,
  groupDataByXAndMonth,
  groupDataByXAndWeek,
  simpleGroupByTime,
  simpleGroupByTimeAndX,
  simpleGroupByXandTime,
} from "./utils-time";

// FUNCTIONS for charts data transformation and generation
// ====================================================================================================
import {
  DATE,
  DAY,
  MOCK_DATA_LENGTH,
  MONTH,
  TIME,
  WEEK,
  YEAR,
} from "../constants";
import { findMedian, isObjectEmpty } from "./common-utils";

export const STAT_FUNCTIONS = {
  total: "total",
  count: "count",
  average: "average",
  median: "median",
};

// select function by time type
export const getDataGropedByTime = (data, timeType) => {
  if (
    timeType === DAY.type ||
    timeType === MONTH.type ||
    timeType === YEAR.type
  ) {
    return simpleGroupByTime(data, timeType);
  } else if (timeType === WEEK.type) {
    return groupDataByWeek(data);
  } else if (timeType === TIME.type) {
    return groupDataByDayTime(data);
  } else if (timeType === DATE.type) {
    return groupDataByMonth(data);
  } else {
    return [];
  }
};

export const getDataGropedByTimeAndX = (data, timeType) => {
  if (
    timeType === DAY.type ||
    timeType === MONTH.type ||
    timeType === YEAR.type
  ) {
    return simpleGroupByTimeAndX(data, timeType);
  } else if (timeType === WEEK.type) {
    return groupDataByWeekAndX(data);
  } else if (timeType === TIME.type) {
    return groupDataByDayTimeAndX(data);
  } else if (timeType === DATE.type) {
    return groupDataByMonthAndX(data);
  } else {
    return [];
  }
};

// ====================================================================================================
// https://nivo.rocks/line/ - line chart
export const transformDataToLineChartFormat = (data, time, y, metric) => {
  const { columns, rows } = data;

  const timeType = columns.find((item) => item.columnId === time)?.type;
  const timeName = columns.find((item) => item.columnId === time)?.name;
  const columnName = columns.find((item) => item.columnId === y)?.name;
  // prepare data, get only time and value
  // slice for mock data length
  // in future states, when mock dataset will be editable, we will remove slice
  const preparedData = rows.slice(0, MOCK_DATA_LENGTH).map((item) => {
    return { time: item[time], value: convertToNumber(item[y], metric) };
  });

  // reduce data
  const reduceDataByTime = getDataGropedByTime(preparedData, timeType);

  // scale factor char
  const scaleFactorChar = getScaleFactor(reduceDataByTime);

  // scale factor number
  const scaleFactor = getScaleNumber(scaleFactorChar);

  // symbol for currency and percent
  const symbol = getSymbol(rows[0][y]);

  return [
    {
      id: columnName,
      // for x axis label
      xName: timeName,
      // for y axis label
      yName: columnName,
      // actually data
      data: Object.entries(reduceDataByTime).map(([x, y]) => ({
        x,
        // scale data
        y: y / scaleFactor,
        timeName,
        // real data
        yReal: y,
        // scale factor char
        postfix: scaleFactorChar,
        // symbol for currency and percent
        symbol,
      })),
    },
  ];
};

// https://nivo.rocks/line/ - multiline chart
export const transformDataToMultiLineChartFormat = (
  data,
  time,
  x,
  y,
  metric
) => {
  const { columns, rows } = data;
  const timeType = columns.find((item) => item.columnId === time)?.type;
  const timeName = columns.find((item) => item.columnId === time)?.name;
  const preparedData = rows.slice(0, MOCK_DATA_LENGTH).map((item) => {
    return {
      time: item[time],
      x: item[x],
      value: convertToNumber(item[y] || ""),
    };
  });
  let reducedByXAndTime;

  if (timeType === DAY.type) {
    reducedByXAndTime = simpleGroupByXandTime(preparedData, timeType);
  } else if (timeType === MONTH.type) {
    reducedByXAndTime = simpleGroupByXandTime(preparedData, timeType);
  } else if (timeType === YEAR.type) {
    reducedByXAndTime = simpleGroupByXandTime(preparedData, timeType);
  } else if (timeType === WEEK.type) {
    reducedByXAndTime = groupDataByXAndWeek(preparedData);
  } else if (timeType === TIME.type) {
    reducedByXAndTime = groupDataByXAndDayTime(preparedData);
  } else if (timeType === DATE.type) {
    reducedByXAndTime = groupDataByXAndMonth(preparedData);
  }

  if (!reducedByXAndTime || isObjectEmpty(reducedByXAndTime)) {
    return [];
  }
  const scaleFactorChar = getScaleFactor(
    Object.entries(reducedByXAndTime).map(([x, y]) => y)[0]
  );

  const scaleFactor = getScaleNumber(scaleFactorChar);

  const symbol = getSymbol(rows[0][y]);

  return Object.entries(reducedByXAndTime).map(([x, y]) => {
    return {
      id: x,
      data: Object.entries(y).map(([x, y]) => ({
        x,
        // scaled data
        y: y / scaleFactor,
        timeName,
        // real data
        yReal: y,
        // scale factor char
        postfix: scaleFactorChar,
        // symbol for currency and percent
        symbol,
      })),
    };
  });
};

//https://nivo.rocks/radar/ - radar chart
export const transformDataToCircularAreaChartFormat = (
  data,
  time,
  y,
  metric
) => {
  const { columns, rows } = data;

  const columnName = columns.find((item) => item.columnId === y)?.name;
  const timeType = columns.find((item) => item.columnId === time)?.type;
  const preparedData = rows.slice(0, MOCK_DATA_LENGTH).map((item) => {
    return { time: item[time], value: convertToNumber(item[y]) };
  });

  const reduceDataByTime = getDataGropedByTime(preparedData, timeType);

  return Object.entries(reduceDataByTime).map(([x, y]) => ({
    id: x,
    [columnName]: y,
  }));
};

// https://nivo.rocks/bar/ - bar chart
export const transformDataToMultiColumnChartFormat = (
  data,
  time,
  x,
  y,
  metric
) => {
  const { columns, rows } = data;
  const timeType = columns?.find((item) => item?.columnId === time)?.type;
  const timeName = columns?.find((item) => item?.columnId === time)?.name;
  const preparedData = rows?.slice(0, MOCK_DATA_LENGTH).map((item) => {
    return {
      time: item[time],
      x: item[x],
      value: convertToNumber(item[y] || ""),
    };
  });

  const reducedByTimeAndX = getDataGropedByTimeAndX(preparedData, timeType);

  if (!reducedByTimeAndX || isObjectEmpty(reducedByTimeAndX)) {
    return [];
  }
  const scaleFactorChar = getScaleFactor(
    Object.entries(reducedByTimeAndX).map(([x, y]) => y)[0]
  );

  const scaleFactor = getScaleNumber(scaleFactorChar);

  const symbol = getSymbol(rows[0][y]);

  const chartData = Object.entries(reducedByTimeAndX)
    .map(([x, y]) => {
      if (!isObjectEmpty(y)) {
        const scaleData = Object.entries(y)
          .map(([x, y]) => ({ [x]: y / scaleFactor }))
          .reduce((accumulator, obj) => {
            const [x, y] = Object.entries(obj)[0];
            accumulator[x] = (accumulator[x] || 0) + y;
            return accumulator;
          }, {});
        return {
          id: x,
          ...scaleData,
        };
      }
    })
    .filter((item) => item !== undefined);

  return [
    {
      dataForChart: chartData || {},
      scaleFactorChar,
      timeName,
      symbol,
    },
  ];
};

// https://nivo.rocks/scatterplot/ - scatter chart
export const transformDataToScatterChartFormat = (data, x, y, metric) => {
  const { columns, rows } = data;
  const yName = columns.find((item) => item.columnId === y)?.name;
  const xName = columns.find((item) => item.columnId === x)?.name;

  const scaleFactorCharX = getScaleFactor(
    rows.slice(0, MOCK_DATA_LENGTH).map((item) => item[x])
  );
  const scaleFactorX = getScaleNumber(scaleFactorCharX);
  const scaleFactorCharY = getScaleFactor(
    rows.slice(0, MOCK_DATA_LENGTH).map((item) => item[y])
  );
  const scaleFactorY = getScaleNumber(scaleFactorCharY);
  const symbolX = getSymbol(rows[0][x]);
  const symbolY = getSymbol(rows[0][y]);

  return x && y
    ? [
        {
          id: "",
          data: rows.slice(0, MOCK_DATA_LENGTH).map((row) => {
            return {
              x: convertToNumber(row[x]) / scaleFactorX || 0,
              y: convertToNumber(row[y]) / scaleFactorY || 0,
              xReal: convertToNumber(row[x]) || 0,
              yReal: convertToNumber(row[y]) || 0,
              yName,
              xName,
              postfixX: scaleFactorCharX,
              postfixY: scaleFactorCharY,
              symbolX,
              symbolY,
            };
          }),
        },
      ]
    : [];
};

// https://nivo.rocks/bar/ - bar chart
export const transformDataToBarChart = (data, x, y, metric) => {
  const { columns, rows } = data;

  const scaleFactorChar = getScaleFactor(
    rows.slice(0, MOCK_DATA_LENGTH).map((item) => item[y])
  );
  const scaleFactor = getScaleNumber(scaleFactorChar);
  const timeName = columns.find((item) => item.columnId === x)?.name;
  const symbol = getSymbol(rows[0][y]);

  const chartData = columns
    .filter((column) => column.columnId === y)
    .map((column) => {
      const reducedData = roundNumbers(
        reduceData(rows.slice(0, MOCK_DATA_LENGTH), x, column.columnId, metric)
      );

      const scaleData = Object.entries(reducedData)
        .map(([x, y]) => ({ [x]: y / scaleFactor }))
        .reduce((accumulator, obj) => {
          const [x, y] = Object.entries(obj)[0];
          accumulator[x] = (accumulator[x] || 0) + y;
          return accumulator;
        }, {});

      return {
        id: column.name,
        ...scaleData,
      };
    })
    .filter((item) => item !== null);

  return [
    {
      dataForChart: chartData,
      scaleFactorChar,
      timeName,
      symbol,
    },
  ];
};

// https://nivo.rocks/bar/ - bar chart
export const transformDataToColumnChart = (data, x, y, metric) => {
  const { columns, rows } = data;
  const scaleFactorChar = getScaleFactor(
    rows.slice(0, MOCK_DATA_LENGTH).map((item) => item[y])
  );
  const scaleFactor = getScaleNumber(scaleFactorChar);
  const timeName = columns.find((item) => item.columnId === x)?.name;
  const symbol = getSymbol(rows[0][y]);

  const chartData = columns
    .filter((column) => column.columnId === y)
    .map((column) => {
      const reducedData = roundNumbers(
        reduceData(rows.slice(0, MOCK_DATA_LENGTH), x, column.columnId, metric)
      );

      const scaleData = Object.entries(reducedData)
        .map(([x, y]) => ({ [x]: y / scaleFactor }))
        .reduce((accumulator, obj) => {
          const [x, y] = Object.entries(obj)[0];
          accumulator[x] = (accumulator[x] || 0) + y;
          return accumulator;
        }, {});

      return {
        id: column.name,
        ...scaleData,
      };
    })
    .filter((item) => item !== null);

  return [
    {
      dataForChart: chartData,
      scaleFactorChar,
      timeName,
      symbol,
    },
  ];
};

// https://nivo.rocks/pie/ - pie chart
export const transformDataToPieChart = (data, x, y, metric) => {
  const { columns, rows } = data;
  const scaleFactorChar = getScaleFactor(
    rows.slice(0, MOCK_DATA_LENGTH).map((item) => item[y])
  );
  const scaleFactor = getScaleNumber(scaleFactorChar);
  const symbol = getSymbol(rows[0][y]);

  const reduceDataByXValue = (data, xColumnName, yColumnName) => {
    const reducedData = reduceData(
      rows.slice(0, MOCK_DATA_LENGTH),
      xColumnName,
      yColumnName,
      metric
    );

    const total = Object.values(reducedData).reduce(
      (sum, value) => sum + value,
      0
    );

    return Object.entries(reducedData).map(([x, y]) => ({
      id: x,
      label: x,
      value: +y.toFixed(2),
      percentage:
        total !== 0 ? ((y / total) * 100).toFixed(2)?.replace(/\.00$/, "") : 0,
      postfix: scaleFactorChar,
      symbol: symbol,
      color: "hsl(277, 70%, 50%)",
    }));
  };

  return columns
    .filter((column) => column.columnId === y)
    .map((column) => reduceDataByXValue(data, x, column.columnId))
    .filter((item) => item !== null)[0];
};

// https://nivo.rocks/boxplot/ - box plot chart
export const transformDataToBoxPlotFormat = (prevData, x, y, metric) => {
  const { rows } = prevData;
  const groupByX = new Map();

  rows.slice(0, MOCK_DATA_LENGTH).forEach((row) => {
    const xValue = row[x];
    const yValue = convertToNumber(row[y]) || 0;

    if (!groupByX.has(xValue)) {
      groupByX.set(xValue, []);
    }

    groupByX.get(xValue).push(yValue);
  });

  const commonData = Array.from(groupByX.entries()).map(([x, y]) => {
    const calculateMean = y.reduce((a, b) => a + b) / y.length;

    const calculateSD = Math.sqrt(
      y.reduce((a, b) => a + Math.pow(b - calculateMean, 2), 0) / (y.length - 1)
    );

    return {
      x,
      mu: calculateMean,
      n: y.length,
      sd: calculateSD,
    };
  });

  return rows.slice(0, MOCK_DATA_LENGTH).map((row) => {
    const xValue = row[x];
    const yValue = convertToNumber(row[y]) || 0;
    const commonDatum = commonData.find((data) => data.x === xValue);

    return {
      group: xValue,
      // subgroup: xValue,
      // mu: commonDatum.mu,
      // sd: commonDatum.sd,
      // n: commonDatum.n,
      value: yValue,
    };
  });
};

// ====================================================================================================
// utils for charts data transformation and generation
export const convertToNumber = (str) => {
  const cleanedStr = str?.replace(/[^0-9.-]/g, "");
  return +parseFloat(cleanedStr).toFixed(2);
};

export const currencySymbols = ["$", "€", "£", "¥", "₹"];

// get symbol for currency and percent
export const getSymbol = (str) => {
  const currencySymbols = ["$", "€", "£", "¥", "₹", "%"];
  const cleanedStr = str?.trim();

  for (const symbol of currencySymbols) {
    if (cleanedStr?.startsWith(symbol) || cleanedStr?.endsWith(symbol)) {
      return symbol;
    }
  }

  return "";
};

export const roundNumbers = (obj) => {
  const roundedObj = {};

  for (const key in obj) {
    if (typeof obj?.[key] === "number") {
      roundedObj[key] = obj?.[key]?.toFixed(2);
    } else {
      roundedObj[key] = obj?.[key];
    }
  }

  return roundedObj;
};

export const addCommas = (value) => {
  const threshold = 100;

  if (value > threshold) {
    const formattedValue = Number(value)?.toLocaleString("en-IN", {
      maximumFractionDigits: 3,
    });
    return formattedValue?.replace(/\.0+$/, "");
  }
  return `${value}`;
};

const findAverage = (rows, xColumnName, yColumnName) => {
  const tempCountData = {};
  const tempData = rows
    ?.slice(0, MOCK_DATA_LENGTH)
    ?.reduce((accumulator, row, i) => {
      const xValue = row?.[xColumnName];
      const yValue = convertToNumber(row?.[yColumnName]) || 0;

      if (!accumulator[xValue]) {
        accumulator[xValue] = 0;
        tempCountData[`count-${xValue}`] = 0;
      }

      accumulator[xValue] += +yValue?.toFixed(2);
      tempCountData[`count-${xValue}`] += 1;

      return accumulator;
    }, {});

  for (let xValue in tempData) {
    tempData[xValue] = tempData?.[xValue] / tempCountData[`count-${xValue}`];
  }
  return tempData;
};

const findMedianFromObj = (rows, xColumnName, yColumnName) => {
  const accumulator = {};
  const tempRow = rows?.slice(0, MOCK_DATA_LENGTH);
  for (const row of tempRow) {
    const xValue = row[xColumnName];
    const yValue = convertToNumber(row?.[yColumnName]) || 0;

    if (!accumulator[xValue]) {
      accumulator[xValue] = [];
    }

    accumulator[xValue]?.push(yValue);
  }

  for (let xValue in accumulator) {
    accumulator[xValue] = findMedian(accumulator[xValue]);
  }
  return accumulator;
};

export const reduceData = (rows, xColumnName, yColumnName, metric) => {
  switch (metric) {
    // case STAT_FUNCTIONS.total:
    //   // code block
    //   break;
    case STAT_FUNCTIONS.count:
      return rows?.slice(0, MOCK_DATA_LENGTH)?.reduce((accumulator, row) => {
        const xValue = row[xColumnName];

        if (!accumulator[xValue]) {
          accumulator[xValue] = 0;
        }

        accumulator[xValue] += 1;

        return accumulator;
      }, {});
    case STAT_FUNCTIONS.average:
      return findAverage(rows, xColumnName, yColumnName);
    case STAT_FUNCTIONS.median:
      return findMedianFromObj(rows, xColumnName, yColumnName);
    default:
      return rows?.slice(0, MOCK_DATA_LENGTH).reduce((accumulator, row) => {
        const xValue = row[xColumnName];
        const yValue = convertToNumber(row[yColumnName]) || 0;

        if (!accumulator[xValue]) {
          accumulator[xValue] = 0;
        }

        accumulator[xValue] += +yValue?.toFixed(2);

        return accumulator;
      }, {});
  }
};

function calculateSum(data, keys) {
  return keys.reduce((sum, key) => sum + +(parseFloat(data?.[key]) || 0), 0);
}

function maxSumObject(data, keys) {
  return data?.reduce(
    (maxObj, obj) => {
      const sum = calculateSum(obj, keys);

      if (sum > maxObj?.sum) {
        return { obj, sum };
      }
      return maxObj;
    },
    { obj: null, sum: 0 }
  );
}

export const addOtherKey = (data, keys, otherKey) => {
  const maxSumObj = maxSumObject(data, keys);

  return data.map((obj) => {
    const sum = +calculateSum(obj, keys)?.toFixed(2);
    const others = maxSumObj?.sum - sum;

    return { ...obj, [otherKey]: +others?.toFixed(2) };
  });
};

export const getScaleFactor = (data) => {
  const max_value = Math?.max(...Object.values(data));
  if (max_value >= 1e9) {
    return "B";
  } else if (max_value >= 1e6) {
    return "M";
  } else if (max_value >= 1e3) {
    return "K";
  } else {
    return null;
  }
};

export const getScaleNumber = (char) => {
  if (char === "B") {
    return 1e9;
  } else if (char === "M") {
    return 1e6;
  } else if (char === "K") {
    return 1e3;
  } else {
    return 1;
  }
};

export const formatLargeNumber = (number) => {
  const abbreviations = [
    { value: 1e42, symbol: "Td" },
    { value: 1e39, symbol: "Dd" },
    { value: 1e36, symbol: "Ud" },
    { value: 1e33, symbol: "Dc" },
    { value: 1e30, symbol: "No" },
    { value: 1e27, symbol: "Oc" },
    { value: 1e24, symbol: "Sp" },
    { value: 1e21, symbol: "Sx" },
    { value: 1e18, symbol: "Qi" },
    { value: 1e15, symbol: "Qa" },
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "B" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "K" },
  ];

  for (const { value, symbol } of abbreviations) {
    if (Math?.abs(number) >= value) {
      const formattedNumber = number / value;
      const roundedNumber = Number?.isInteger(formattedNumber)
        ? formattedNumber?.toFixed(0)
        : formattedNumber?.toFixed(2);
      return `${roundedNumber}${symbol}`;
    }
  }

  return `${number}`;
};
