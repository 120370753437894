import { PersonOutline } from "@mui/icons-material";
import {
  Box,
  Container,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  specError,
  specGray,
  specPrimary,
  specPrimaryGray,
  specSecondary,
  specSuccess,
} from "./colors";
import { PaletteCube } from "./style";
import LoadingButton from "../../common/LoadingButton";
import CustomSelectField from "../../common/CustomSelectField";

const ThemeDemo = () => {
  const [selectValue, setSelectValue] = useState(null);
  const [options, setoptions] = useState([
    {
      value: "1",
      label: "One",
    },
    {
      value: "2",
      label: "Two",
    },
    {
      value: "3",
      label: "Three",
    },
    {
      value: "4",
      label: "Four",
    },
    {
      value: "5",
      label: "Five",
    },
    {
      value: "6",
      label: "One",
    },
    {
      value: "7",
      label: "Two",
    },
    {
      value: "8",
      label: "Three",
    },
    {
      value: "9",
      label: "Four",
    },
    {
      value: "10",
      label: "Five",
    },
  ]);

  const onSelect = (value, action) => {
    if (action.action === "pop-value") return;
    setSelectValue(value);
  };

  return (
    <Container
      sx={{
        height: `calc(100vh - 45px)`,
        overflowY: "scroll",
      }}>
      <Stack my={2} mb={7}>
        {/* TYPOGRAPHY */}

        <Typography variant='spec_h1'>Headline 1 - H1</Typography>
        <Typography variant='spec_h2'>Headline 2 - H2</Typography>
        <Typography variant='spec_h3'>Headline 3 - H3</Typography>
        <Typography variant='spec_h3'>SubHeadline 1 - H3</Typography>
        <Typography variant='spec_button'>Button</Typography>
        <Typography variant='spec_caption'>Caption</Typography>

        {/* COLOR PALLETE */}

        <Stack rowGap={1} mt={2}>
          <Typography variant='spec_h1'>Primary Palette</Typography>
          <Stack columnGap={1} flexDirection={"row"} alignItems={"center"}>
            {Object?.keys(specPrimary)
              ?.map((key, i) => (
                <Tooltip key={i} title={specPrimary[key]} placement='top' arrow>
                  <PaletteCube
                    backcolor={specPrimary[key]}
                    ismain={key === "700" ? 1 : 0}
                  />
                </Tooltip>
              ))
              .reverse()}
          </Stack>
          <Typography variant='spec_h1'>Secondary & Info Palette</Typography>
          <Stack columnGap={1} flexDirection={"row"} alignItems={"center"}>
            {Object?.keys(specSecondary)
              ?.map((key, i) => (
                <Tooltip
                  key={i}
                  title={specSecondary[key]}
                  placement='top'
                  arrow>
                  <PaletteCube
                    backcolor={specSecondary[key]}
                    ismain={key === "500" ? 1 : 0}
                  />
                </Tooltip>
              ))
              .reverse()}
          </Stack>
          <Typography variant='spec_h1'>Gray Palette</Typography>
          <Stack columnGap={1} flexDirection={"row"} alignItems={"center"}>
            {Object?.keys(specGray)
              ?.map((key, i) => (
                <Tooltip key={i} title={specGray[key]} placement='top' arrow>
                  <PaletteCube
                    backcolor={specGray[key]}
                    ismain={key === "500" ? 1 : 0}
                  />
                </Tooltip>
              ))
              .reverse()}
          </Stack>
          <Stack columnGap={1} flexDirection={"row"} alignItems={"center"}>
            {Object?.keys(specPrimaryGray)?.map((key, i) => (
              <Tooltip
                key={i}
                title={
                  <Box>
                    {specPrimaryGray[key]} : {key}
                  </Box>
                }
                placement='top'
                arrow>
                <PaletteCube
                  backcolor={specPrimaryGray[key]}
                  ismain={key === "500" ? 1 : 0}
                />
              </Tooltip>
            ))}
          </Stack>
          <Typography variant='spec_h1'>Error Palette</Typography>
          <Stack columnGap={1} flexDirection={"row"} alignItems={"center"}>
            {Object?.keys(specError)
              ?.map((key, i) => (
                <Tooltip key={i} title={specError[key]} placement='top' arrow>
                  <PaletteCube
                    backcolor={specError[key]}
                    ismain={key === "400" ? 1 : 0}
                  />
                </Tooltip>
              ))
              .reverse()}
          </Stack>
          <Typography variant='spec_h1'>Success Palette</Typography>
          <Stack columnGap={1} flexDirection={"row"} alignItems={"center"}>
            {Object?.keys(specSuccess)
              ?.map((key, i) => (
                <Tooltip key={i} title={specSuccess[key]} placement='top' arrow>
                  <PaletteCube
                    backcolor={specSuccess[key]}
                    ismain={key === "400" ? 1 : 0}
                  />
                </Tooltip>
              ))
              .reverse()}
          </Stack>
        </Stack>

        {/* BUTTONS */}

        <Stack
          rowGap={1}
          mt={2}
          width={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
          }}>
          <Typography variant='spec_h1'>Buttons</Typography>
          <Stack
            flexDirection={"row"}
            columnGap={2}
            rowGap={2}
            justifyContent={"flex-start"}
            flexWrap={"wrap"}>
            <LoadingButton variant='contained'>Add New Spec</LoadingButton>
            <LoadingButton variant='contained' startIcon={<PersonOutline />}>
              Add New Spec
            </LoadingButton>
            <LoadingButton variant='contained' endIcon={<PersonOutline />}>
              Add New Spec
            </LoadingButton>
            <LoadingButton
              variant='contained'
              startIcon={<PersonOutline />}
              disabled>
              Add New Spec
            </LoadingButton>
            <LoadingButton
              variant='contained'
              endIcon={<PersonOutline />}
              disabled
              loading>
              Add New Spec
            </LoadingButton>
          </Stack>
          <Stack
            flexDirection={"row"}
            columnGap={2}
            rowGap={2}
            justifyContent={"flex-start"}
            flexWrap={"wrap"}>
            <LoadingButton variant='outlined' color='secondary'>
              Add Visuals
            </LoadingButton>
            <LoadingButton
              variant='outlined'
              color='secondary'
              startIcon={<PersonOutline />}>
              Add Visuals
            </LoadingButton>
            <LoadingButton
              variant='outlined'
              color='secondary'
              endIcon={<PersonOutline />}>
              Add Visuals
            </LoadingButton>
            <LoadingButton
              variant='outlined'
              color='secondary'
              disabled
              startIcon={<PersonOutline />}>
              Add Visuals
            </LoadingButton>
            <LoadingButton
              variant='outlined'
              color='secondary'
              disabled
              loading>
              Add Visuals
            </LoadingButton>
          </Stack>
          <Stack
            flexDirection={"row"}
            columnGap={2}
            rowGap={2}
            justifyContent={"flex-start"}
            flexWrap={"wrap"}>
            <LoadingButton variant={"outlined"} color={"gray"}>
              Cancel
            </LoadingButton>
            <LoadingButton
              variant={"outlined"}
              color={"gray"}
              startIcon={<PersonOutline />}>
              Cancel
            </LoadingButton>
            <LoadingButton
              variant={"outlined"}
              color={"gray"}
              endIcon={<PersonOutline />}>
              Cancel
            </LoadingButton>
            <LoadingButton
              variant={"outlined"}
              color={"gray"}
              endIcon={<PersonOutline />}
              disabled>
              Cancel
            </LoadingButton>
            <LoadingButton
              variant={"outlined"}
              color={"gray"}
              endIcon={<PersonOutline />}
              disabled
              loading>
              Cancel
            </LoadingButton>
          </Stack>
        </Stack>
        <Stack
          rowGap={1}
          mt={2}
          width={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
          }}>
          <Typography variant='spec_h1'>TextFields</Typography>
          <TextField
            placeholder='Please type in here'
            sx={{
              width: "300px",
            }}
          />
          <TextField
            value={"Disabled text field"}
            placeholder='Please type in here'
            sx={{
              width: "300px",
            }}
            disabled
          />
          <TextField
            helperText='This is a helper text'
            placeholder='Please type in here'
            sx={{
              width: "300px",
            }}
          />
          <TextField
            helperText={"Please enter a valid input"}
            placeholder='Please type in here'
            error={true}
            sx={{
              width: "300px",
            }}
          />
        </Stack>
        <Stack
          rowGap={1}
          mt={2}
          width={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
          }}>
          <Typography variant='spec_h1'>Select Fields</Typography>
          <CustomSelectField
            isLabel
            label='Select User'
            error={true}
            value={selectValue}
            options={options}
            onChange={onSelect}
          />
        </Stack>
      </Stack>
    </Container>
  );
};

export default ThemeDemo;
