import React from "react";
import SideDrawer from "../SideDrawer";
import { Box, IconButton, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Comments } from ".";

function CommentsDrawer({ open, onClose }) {
  return (
    <div>
      <SideDrawer open={open} onClose={() => onClose()} width={"400px"}>
        <Box sx={{ px: 3, py: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Typography variant='spec_h2'>Comments</Typography>
            <IconButton onClick={() => onClose()}>
              <CloseOutlined color='black' />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ px: 3, pt: 0, pb: 5 }}>
          <Comments />
        </Box>
      </SideDrawer>
    </div>
  );
}

export default CommentsDrawer;
