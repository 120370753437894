export const PieIcon = () => {
  return (
    <svg
      width='100%'
      height='100%'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 60 61'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M41.526 18.4785C41.7213 18.2832 42.0385 18.2828 42.2279 18.4838C45.1604 21.596 46.8002 25.7157 46.8002 30.0043C46.8002 34.46 45.0302 38.7331 41.8796 41.8837C38.729 45.0343 34.4558 46.8043 30.0002 46.8043C25.7115 46.8043 21.5919 45.1645 18.4796 42.232C18.2786 42.0427 18.2791 41.7255 18.4744 41.5302L30.0002 30.0043L41.526 18.4785Z'
        fill='#808080'
      />
      <path
        d='M18.4744 41.5302C18.2791 41.7254 17.9619 41.7259 17.7725 41.5249C15.613 39.2329 14.1387 36.3771 13.523 33.2819C12.8748 30.023 13.2075 26.6451 14.479 23.5753C15.7506 20.5055 17.9039 17.8817 20.6666 16.0357C23.2906 14.2824 26.3525 13.3055 29.5002 13.2118C29.7762 13.2036 30.0002 13.4282 30.0002 13.7043V29.7972C30.0002 29.9298 29.9475 30.057 29.8537 30.1508L18.4744 41.5302Z'
        fill='#808080'
      />
      <path
        d='M31.8003 9.50439C31.8003 9.22825 32.0245 9.00374 32.3005 9.0092C34.2776 9.04828 35.9184 9.33286 37.8003 10.1124C39.6873 10.894 41.3716 11.7986 42.849 13.1664C43.048 13.3506 43.0514 13.6611 42.8646 13.8577L32.6628 24.5965C32.3517 24.924 31.8003 24.7038 31.8003 24.2521V9.50439Z'
        fill='#808080'
      />
    </svg>
  );
};
