import {
  Drawer,
  IconButton,
  Link,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  AddHomeWorkOutlined,
  AddTaskOutlined,
  AutoGraphOutlined,
  DomainAddOutlined,
  FormatIndentDecreaseOutlined,
  FormatIndentIncreaseOutlined,
  GridOnRounded,
  InventoryOutlined,
  LabelOutlined,
  NoteAltOutlined,
  StyleOutlined,
  UnfoldMoreDoubleRounded,
} from "@mui/icons-material";
import { ROUTE_PATH } from "../../router/routes";
import { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { DrawerList, closedMixin, openedMixin } from "./style";
import { useSelector } from "react-redux";

export const DataSettingsSidebar = () => {
  const theme = useTheme();

  const { user } = useSelector((state) => state.userAuth);

  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const list = [
    {
      label: "Data Scope",
      icon: <NoteAltOutlined fontSize='small' />,
      link: ROUTE_PATH.DATA_SCOPE,
    },
    {
      label: "Business Terms",
      icon: <StyleOutlined fontSize='small' />,
      link: ROUTE_PATH.MAPPINGS,
    },
    {
      label: "Functions",
      icon: <AddHomeWorkOutlined fontSize='small' />,
      link: ROUTE_PATH.FUNCTIONS,
    },
    {
      label: "Statuses",
      icon: <LabelOutlined fontSize='small' />,
      link: ROUTE_PATH.STATUSES,
    },
    {
      label: "Priorities",
      icon: <UnfoldMoreDoubleRounded fontSize='small' />,
      link: ROUTE_PATH.PRIORITY,
    },
    {
      label: "Industries",
      icon: <DomainAddOutlined fontSize='small' />,
      link: ROUTE_PATH.INDUSTIRES,
    },
  ];

  const isMDUp = useMediaQuery(theme.breakpoints.up("md"));

  const isOpen = isMDUp ? open : false;

  const filteredList = user?.superAdmin
    ? list
    : list.filter((item) => item.label !== "Industries");

  return (
    <Drawer
      open={isOpen}
      variant='permanent'
      sx={{
        borderTop: "1px solid transparent",
        backgroundColor: "white",
        color: "balck",
      }}
      PaperProps={{
        sx: {
          position: "relative",
          zIndex: 0,
          ...(isOpen ? openedMixin(theme) : closedMixin(theme)),
        },
      }}>
      <DrawerList theme={theme}>
        {filteredList.map((item, index) => (
          <Tooltip
            title={isOpen ? "" : item.label}
            key={index}
            arrow
            placement='right'>
            <Link
              key={index}
              className='sidebar-link'
              component={RouterLink}
              to={item.link}
              target='_self'
              underline='none'
              role={undefined}>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: "14px",
                      justifyContent: "center",
                    }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    sx={{
                      opacity: isOpen ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          </Tooltip>
        ))}
      </DrawerList>
      <Stack
        sx={(theme) => ({
          position: "fixed",
          bottom: 0,
          width: theme.spacing(6.7),
          display: isMDUp ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
        })}>
        <IconButton onClick={isOpen ? handleDrawerClose : handleDrawerOpen}>
          {isOpen ? (
            <FormatIndentDecreaseOutlined
              fontSize='small'
              sx={(theme) => ({ color: theme.palette.gray.iconColor })}
            />
          ) : (
            <FormatIndentIncreaseOutlined
              fontSize='small'
              sx={(theme) => ({ color: theme.palette.gray.iconColor })}
            />
          )}
        </IconButton>
      </Stack>
    </Drawer>
  );
};
