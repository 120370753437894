import React, { useEffect, useRef } from "react";
import { chartColors } from "../../SelectVisuals/utils";
import { useSelector } from "react-redux";
import _ from "lodash";

const MultiLineChart = ({
  chartData,
  xTitle,
  yTitle,
  isMultipleSeries,
  metric,
}) => {
  const chartRef = useRef(null);
  const containerRef = useRef(null);
  const r = useRef((Math.random() + 1).toString(36).substring(7));
  const reduxState = useSelector((state) => state?.spec);

  useEffect(() => {
    const renderChart = () => {
      setTimeout(() => {
        drawMultipleSeriesChart();
      }, 150);
    };

    if (!window.google) return; // Make sure google is loaded
    renderChart();
  }, [
    containerRef.current?.clientHeight,
    containerRef.current?.clientWidth,
    reduxState?.isSpecVisualDatasetLoading,
    chartData,
  ]);
  const drawMultipleSeriesChart = () => {
    const drawChart = () => {
      const data = new window.google.visualization.DataTable();
      data.addColumn("string", chartData[0].xaxis);
      chartData[0].yaxis.forEach((axis) => {
        data.addColumn("number", axis);
        data.addColumn({ type: "string", role: "tooltip", p: { html: true } });
      });

      const formattedData = convertDataToArrayForMultiSeries(
        chartData[0].dataForChart
      );
      data.addRows([...formattedData]);

      const options = {
        // legend: { position: "top" },
        pointSize: 6,
        hAxis: {
          title: chartData[0].xaxis,
          textStyle: { fontSize: 12, fontName: "Poppins" },
          titleTextStyle: {
            italic: false,
            fontSize: 12,
            fontName: "Poppins-Bold",
          },
        },
        vAxis: {
          format: "short",
          textStyle: { fontSize: 12, fontName: "Poppins" },
          titleTextStyle: {
            italic: false,
            fontSize: 12,
            fontName: "Poppins-Bold",
          },
        },
        colors: [...chartColors],
        height: containerRef.current?.clientHeight,
        chartArea: {
          height: containerRef.current?.clientHeight - 80,
          top: "2%",
          left: "8%",
        },
        tooltip: { isHtml: true },
        series: {
          1: { curveType: "function" },
        },
      };

      const chart = new window.google.visualization.LineChart(chartRef.current);
      chart.draw(data, options);
    };
    if (!reduxState?.isSpecVisualDatasetLoading)
      window.google.charts.setOnLoadCallback(drawChart);
  };

  const convertDataToArrayForMultiSeries = (data) => {
    let result = [];
    data.forEach((item) => {
      let currentItem = [];
      currentItem.push(item.xaxis);
      item?.yaxis?.forEach((yAxisItem) => {
        if (yAxisItem)
          Object.entries(yAxisItem.data).forEach(([key, value]) => {
            currentItem.push(value);
            currentItem.push(
              createCustomHTMLTooltip(item?.xaxis, value, key, yAxisItem.symbol)
            );
          });
      });
      result.push(currentItem);
    });
    return result;
  };

  function createCustomHTMLTooltip(key, value, yName, symbol) {
    const fixedValue = Number(value?.toFixed(2));
    const newValue = fixedValue?.toLocaleString("en-US");

    return (
      '<div style="padding:5px;">' +
      `<strong>${xTitle}: </strong>` +
      key +
      "<br/>" +
      `<strong>${_.capitalize(yName)}: </strong>` +
      `${
        symbol === "$"
          ? "$" + newValue
          : symbol === "%"
          ? newValue + "%"
          : newValue
      }` +
      "</div>"
    );
  }

  return (
    <div
      ref={containerRef}
      id={r.current}
      className='cancelDraggableElement'
      style={{ height: "100%" }}>
      <div ref={chartRef}></div>
    </div>
  );
};

export default MultiLineChart;
