import { BorderColorOutlined, MoreHorizRounded } from "@mui/icons-material";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import { SquareIconButton } from "../../common/commonStyle";
import CustomPopover from "../../common/CustomPopover";
import MenuItem from "../../common/MenuItem";
import { useDispatch } from "react-redux";
import { setSpecDataScopeModal } from "../../store/slices/dataScopes";

const ActionCell = (props) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack
        width={1}
        height={1}
        justifyContent={"center"}
        alignItems={"center"}>
        <SquareIconButton onClick={handleClick}>
          <MoreHorizRounded />
        </SquareIconButton>
      </Stack>
      <CustomPopover open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem
          Icon={<BorderColorOutlined fontSize='small' />}
          itemText={"Edit"}
          itemProps={{ height: "15px" }}
          onClick={() => {
            handleClose();
            dispatch(
              setSpecDataScopeModal({
                isSetSpecDSModalOpen: true,
                specDSData: props?.data,
              })
            );
          }}
        />
      </CustomPopover>
    </>
  );
};

export default ActionCell;
