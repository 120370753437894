import { STATE_BUSINESS_TERMS_LABEL, TYPE_LABEL } from "../../constants";
import MockupCountCell from "../MappingsLibrary/MockupCountCell";
import ActionCell from "./ActionCell";
// import ActionCell from "./ActionCell";

export const getMappingsColumns = (mappingColumns, isPreview) => {
  const newColDef = mappingColumns?.map((column) => {
    if (column?.originalKey === "specs") {
      return {
        field: "specs",
        editable: false,
        headerName: "Specs",
        width: 110,
        resizable: true,
        unSortIcon: true,
        cellDataType: "text",
        cellRenderer: MockupCountCell,
        minWidth: 110,
      };
    }

    if (column?.originalKey === "description") {
      return {
        field: "description",
        editable: false,
        headerName: "Description",
        resizable: true,
        unSortIcon: true,
        cellDataType: "text",
        cellStyle: { wordBreak: "normal", lineHeight: "normal" },
        wrapText: true,
        autoHeight: true,
        valueFormatter: (params) => (params?.value ? params?.value : "-"),
        minWidth: 150,
      };
    }

    if (column?.originalKey === "businessAttribute") {
      return {
        field: "businessAttribute",
        editable: false,
        headerName: "Attribute",
        resizable: true,
        unSortIcon: true,
        cellDataType: "text",
        valueFormatter: (params) => (params?.value ? params?.value : "-"),
        minWidth: 150,
      };
    }

    if (column?.originalKey === "prompt") {
      return {
        field: "plainPrompt",
        editable: false,
        headerName: "Prompt",
        resizable: true,
        unSortIcon: true,
        cellDataType: "text",
        cellStyle: { wordBreak: "normal", lineHeight: "normal" },
        wrapText: true,
        autoHeight: true,
        valueFormatter: (props) => (props?.value ? props?.value : "-"),
        minWidth: 150,
      };
    }

    if (column?.originalKey === "businessTermCategory") {
      return {
        field: "businessTermCategory",
        editable: false,
        headerName: "Category",
        resizable: true,
        unSortIcon: true,
        cellDataType: "text",
        // filterParams: {
        //   isCustomFilter: true,
        //   loadOptions: getBusinessTermCategoriesOptions,
        // },
        valueFormatter: (props) =>
          props?.value?.id ? props?.value?.name : "Uncategorized",
        minWidth: 150,
      };
    }

    if (column?.originalKey === "name") {
      return {
        field: "name",
        editable: false,
        headerName: "Term",
        resizable: true,
        unSortIcon: true,
        cellDataType: "text",
        minWidth: 150,
        wrapText: true,
        autoHeight: true,
        lineHeight: "normal",
      };
    }

    if (column?.originalKey === "sample") {
      return {
        field: "sample",
        editable: false,
        headerName: "Sample",
        resizable: true,
        unSortIcon: true,
        cellDataType: "text",
        minWidth: 150,
      };
    }

    if (column?.kind === "custom") {
      return {
        field: column?.id,
        editable: false,
        headerName: column?.name,
        resizable: true,
        unSortIcon: true,
        cellDataType: "text",
        minWidth: 150,
        wrapText: true,
        autoHeight: true,
        lineHeight: "normal",
      };
    }
  });

  newColDef?.push({
    field: "dataType",
    editable: false,
    headerName: "Data Type",
    resizable: true,
    unSortIcon: true,
    cellDataType: "text",
    // filterParams: {
    //   isCustomFilter: true,
    //   staticOptions: DATA_TYPES,
    // },
    valueFormatter: (props) => (props?.value ? TYPE_LABEL[props?.value] : "-"),
    minWidth: 150,
  });

  newColDef?.push({
    field: "state",
    editable: false,
    headerName: "Status",
    resizable: true,
    unSortIcon: true,
    cellDataType: "text",
    // filterParams: {
    //   isCustomFilter: true,
    //   staticOptions: DATA_TYPES,
    // },
    valueFormatter: (props) =>
      props?.value ? STATE_BUSINESS_TERMS_LABEL[props?.value] : "-",
    minWidth: 150,
  });

  newColDef?.unshift({
    field: "action",
    editable: false,
    headerName: "Action",
    width: 80,
    resizable: false,
    sortable: false,
    pinned: "left",
    cellDataType: "text",
    cellRenderer: ActionCell,
  });

  newColDef?.push({
    field: "lastUpdatedBy",
    editable: false,
    headerName: "Last Updated By",
    resizable: true,
    unSortIcon: true,
    cellDataType: "text",
    autoHeight: true,
    minWidth: 250,
    flex: 1,
    cellStyle: { lineHeight: "normal", whiteSpace: "wrap" },
  });

  const filteredColDef = newColDef.filter((object) => {
    if (object?.field === "specs") {
      return false;
    }

    if (object?.field === "action" && isPreview) {
      return false;
    }

    return true; // Include all other menu items
  });

  return filteredColDef.map((col, i) => {
    if (i === (isPreview ? 0 : 1)) {
      return {
        ...col,
        pinned: "left",
      };
    }
    return col;
  });
};
