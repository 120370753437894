import { getCompanyUsers } from "../../services";
import { HTTP_OK } from "../../constants/statusCodes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultExceptionHandler } from "../../utils/defaultExceptionHandler";

const initialState = {
  users: [],
  isUsersLoading: false,
};

export const getUsersAsync = createAsyncThunk(
  "userManagement/getUsersAsync",
  async (payload) => {
    try {
      const response = await getCompanyUsers();

      if (response?.status !== HTTP_OK) {
        throw new Error("Something went wrong!");
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

const userManagementSlice = createSlice({
  name: "userManagement",
  initialState: initialState,

  reducers: {},

  extraReducers: (builder) => {
    // GET STATUSES BUILDER FUNCTIONS
    builder.addCase(getUsersAsync.pending, (state, action) => {
      state.isUsersLoading = true;
    });
    builder.addCase(getUsersAsync.fulfilled, (state, action) => {
      state.isUsersLoading = false;
      state.users = action?.payload?.users;
    });
    builder.addCase(getUsersAsync.rejected, (state) => {
      state.isUsersLoading = false;
    });
  },
});

userManagementSlice.actions;
export default userManagementSlice.reducer;
