import { defaultMessage } from "../constants/messages";
export const defaultExceptionHandler = (error) => {
  let message = defaultMessage;
  if (error?.response?.data?.toaster.data?.message) {
    message = error?.response?.data?.toaster.data?.message;
  } else {
    message = error.message;
  }
  return message;
};
