import React from "react";
import { Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
const InvoiceNumberCell = (props) => {
  const theme = useTheme();
  return !props?.data?.isTrialPeriod ? (
    <Link
      variant='spec_body'
      style={{
        ...theme.typography.spec_body,
        cursor: "pointer",
        color: theme.palette.secondary.main,
      }}
      to={props?.data?.stripePaymentUrl} //the pay now url is same as invoice if not paid will redirect to paywall and if paid will redirect to invoice
      target='_blank'
      rel='noopener noreferrer'>
      {props?.value}
    </Link>
  ) : (
    <Typography variant='spec_body'>-</Typography>
  );
};

export default InvoiceNumberCell;
