import roundLogo from "./logos/roundLogo.png";
import { ReactComponent as fullLogo } from "./logos/fullLogo.svg";
import barChart from "./icons/charts/barChart.svg";
import columnChart from "./icons/charts/columnChart.svg";
import singleLineChart from "./icons/charts/singleLineChart.svg";
import multiLineChart from "./icons/charts/multipleLineChart.svg";
import scatterChart from "./icons/charts/scatterChart.svg";
import boxplot from "./icons/charts/boxplot.svg";
import stacked100PerColumnChart from "./icons/charts/stacked100PerColumnChart.svg";
import stackedColumnChart from "./icons/charts/stackedColumnChart.svg";
import pieChart from "./icons/charts/pieChart.svg";
import drag from "./icons/drag.svg";
import Error404 from "./images/Error404.png";
import { ReactComponent as noDataFound } from "./images/noDataFound.svg";

import {
  Info,
  Visibility,
  VisibilityOff,
  PersonAddAlt1,
  ExpandCircleDownOutlined,
  DescriptionOutlined,
  LogoutOutlined,
  PeopleAltOutlined,
  PersonOutline,
  SettingsOutlined,
  BorderColorOutlined,
  ExpandMoreRounded,
  MoreVert,
  ShareOutlined,
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox,
} from "@mui/icons-material";

export const RoundLogo = roundLogo;
export const FullLogo = fullLogo;
export const BarChartIcon = barChart;
export const ColumnChartIcon = columnChart;
export const SingleLineChartIcon = singleLineChart;
export const MultiLineChartIcon = multiLineChart;
export const ScatterChartIcon = scatterChart;
export const BoxplotIcon = boxplot;
export const Stacked100PerColumnChartIcon = stacked100PerColumnChart;
export const StackedColumnChart = stackedColumnChart;
export const PieChart = pieChart;
export const VisibilityIcon = Visibility;
export const VisibilityOffIcon = VisibilityOff;
export const Drag = drag;
export const PersonAddAlt1Icon = PersonAddAlt1;
export const ExpandCircleDownOutlinedIcon = ExpandCircleDownOutlined;
export const DescriptionOutlinedIcon = DescriptionOutlined;
export const LogoutOutlinedIcon = LogoutOutlined;
export const PeopleAltOutlinedIcon = PeopleAltOutlined;
export const PersonOutlineIcon = PersonOutline;
export const SettingsOutlinedIcon = SettingsOutlined;
export const Error404Image = Error404;
export const BorderColorOutlinedIcon = BorderColorOutlined;
export const InfoIcon = Info;
export const ExpandMoreRoundedIcon = ExpandMoreRounded;
export const MoreVertIcon = MoreVert;
export const ShareOutlinedIcon = ShareOutlined;
export const NoDataFound = noDataFound;
export const CheckBoxIcon = CheckBox;
export const CheckBoxOutlineBlankIcon = CheckBoxOutlineBlank;
export const IndeterminateCheckBoxIcon = IndeterminateCheckBox;
