import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistStore,
} from "redux-persist";
import userAuthSlice from "./slices/userAuth";
import specListingSlice from "./slices/specListing";
import LocalStorage from "redux-persist/lib/storage";
import specSlice from "./slices/userSpec";
import statusesSlice from "./slices/statuses";
import functionsSlice from "./slices/functions";
import industriesSlice from "./slices/industries";
import mockDatasetSlice from "./slices/mockDataset";
import copyLabelSlice from "./slices/copyLabel";
import tableFilterSlice from "./slices/tableFilters";
import businessTermsSlice from "./slices/businessTerms";
import dataScopesSlice from "./slices/dataScopes";
import billingSlice from "./slices/billing";
import mappingSettingsSlice from "./slices/mappingSettings";
import userManagementSlice from "./slices/userManagement";
import announcementsSlice from "./slices/announcements";
import prioritiesSlices from "./slices/priorities";

const rootReducer = combineReducers({
  userAuth: userAuthSlice,
  specListing: specListingSlice,
  spec: specSlice,
  statuses: statusesSlice,
  functions: functionsSlice,
  industries: industriesSlice,
  mockDataset: mockDatasetSlice,
  copyLabel: copyLabelSlice,
  tableFilter: tableFilterSlice,
  businessTerms: businessTermsSlice,
  dataScopes: dataScopesSlice,
  billing: billingSlice,
  mappingSettings: mappingSettingsSlice,
  userManagement: userManagementSlice,
  announcements: announcementsSlice,
  priorities: prioritiesSlices,
});

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

const storage =
  typeof window !== "undefined" ? LocalStorage : createNoopStorage();

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["userAuth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

let persistor = persistStore(store);

export { store, persistor };
