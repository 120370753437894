import React from "react";
import { Typography, Link } from "@mui/material";

const InvoiceLinkCell = (props) => {
  return props?.data?.isTrialPeriod ? (
    <Typography variant='spec_body'> Trial Period </Typography>
  ) : !props?.data?.paid ? (
    <Link
      variant='spec_body'
      color='secondary'
      sx={{ textDecoration: "none" }}
      target={"_blank"}
      href={props?.data?.stripePaymentUrl}>
      Pay Now
    </Link>
  ) : (
    <Typography variant='spec_body'> Paid </Typography>
  );
};

export default InvoiceLinkCell;
