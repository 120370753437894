import React from "react";
import { Typography } from "@mui/material";
import { StyledStatusChip } from "../../components/StatusChip/style";

const StatusCell = (props) => {
  return (
    <StyledStatusChip
      label={
        <Typography
          variant='spec_body'
          sx={(theme) => ({
            color: theme.palette.info.main,
          })}>
          {props?.value ? props?.value : "Status not defined"}
        </Typography>
      }
    />
  );
};

export default StatusCell;
