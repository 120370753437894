import React, { Fragment } from "react";

import {
  alpha,
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useTable } from "react-table";

const HistoryTable = ({
  columns,
  data,
  maxHeight = "500px",
  stickyHeader = true,
}) => {
  const theme = useTheme();

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      data,
      columns,
    });

  return (
    <TableContainer
      sx={{
        maxHeight,
        width: "100%",
        display: data.length ? "block" : "none",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
      }}>
      <Table {...getTableProps()} stickyHeader={stickyHeader}>
        <TableHead
          sx={{
            background: theme.palette.common.white,
            backgroundColor: theme.palette.grey[50],
            textTransform: "capitalize",
            // borderBottom: `1px solid ${theme.palette.gray.borderGray}`,
          }}>
          {headerGroups.map((headerGroup, i) => (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
              key={`header_${i}`}>
              {headerGroup.headers.splice(0, 5).map((column, i) => {
                return (
                  <TableCell
                    sx={{
                      py: 1,
                      minWidth: column.sx?.width,
                      maxWidth: column.sx?.width,
                      backgroundColor: theme.palette.gray.headerBackground,
                      borderLeft: `1px solid ${theme.palette.gray.borderGray}`,
                      ...column.sx,
                    }}
                    {...column.getHeaderProps()}
                    key={`tableCell_${i}`}>
                    <Stack
                      direction='row'
                      spacing={1}
                      alignItems='center'
                      sx={{
                        ...column?.sxHeader,
                      }}>
                      <Box sx={{ width: "max-content" }}>
                        <Typography variant='spec_h3'>
                          {column.render("Header")}
                        </Typography>
                      </Box>
                    </Stack>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <Fragment key={`fragment_${i}`}>
                <TableRow
                  {...row.getRowProps()}
                  key={`tableRow_${i}`}
                  sx={{
                    cursor: "pointer",
                    bgcolor: row.isSelected
                      ? alpha(theme.palette.primary.lighter, 0.35)
                      : "inherit",
                    ".MuiTableCell-root": {
                      p: 0.5,
                      fontSize: "0.8rem",
                    },
                  }}>
                  {row.cells.map((cell, i) => {
                    return (
                      cell.column.Header !== "" && (
                        <Fragment key={`parent_${cell.column.Header}_${i}`}>
                          {cell.render("Cell")}
                        </Fragment>
                      )
                    );
                  })}
                </TableRow>
                {/* <TableRow
                  {...row.getRowProps()}
                  key={`subRow_${i}`}
                  sx={{
                    cursor: "pointer",
                    bgcolor: row.isSelected
                      ? alpha(theme.palette.primary.lighter, 0.35)
                      : "inherit",
                    ".MuiTableCell-root": {
                      p: 0.5,
                      fontSize: "0.8rem",
                    },
                  }}
                >
                  {row.cells.map((cell, i) => {
                    return (
                      cell.column.Header === "" && (
                        <Fragment key={`child_${cell.column.Header}_${i}`}>
                          {cell.render("Cell")}
                        </Fragment>
                      )
                    );
                  })}
                </TableRow> */}
                {/* <TableRow
                  sx={
                    {
                      // height: "6px",
                    }
                  }></TableRow> */}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HistoryTable;
