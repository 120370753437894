import React from "react";
import SideDrawer from "../SideDrawer";
import { Box, IconButton, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { History } from "./History";
import { SIDEBAR_WIDTH } from "../../constants";
import { useSelector } from "react-redux";

function HistoryDrawer({ open, onClose }) {
  const { isSideDrawerOpen } = useSelector((state) => state.spec);
  return (
    <div>
      <SideDrawer
        open={open}
        onClose={() => onClose()}
        width={`calc(100vw - ${isSideDrawerOpen ? SIDEBAR_WIDTH : "53.6"}px)`}>
        <Box sx={{ p: 3, pb: 0 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Typography variant='spec_h2'>History</Typography>
            <IconButton onClick={() => onClose()}>
              <CloseOutlined color='black' />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ px: 3, pt: 0, pb: 5 }}>
          <History />
        </Box>
      </SideDrawer>
    </div>
  );
}

export default HistoryDrawer;
