import {
  Box,
  Stack,
  Drawer,
  Divider,
  useTheme,
  IconButton,
  useMediaQuery,
  Typography,
} from "@mui/material";
import {
  StyledTab,
  a11yProps,
  StyledTabs,
  CustomTabPanel,
} from "../../pages/MockDataset/style";
import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import ConfigureChart from "../ConfigureChart";
import MockDatasetTab from "../MockDatasetTab";
import { closedMixin, openedMixin } from "./style";
import { useDispatch, useSelector } from "react-redux";
import VisualRequirements from "../VisualRequirements";
import { setIsVisualConfigOpen } from "../../store/slices/userSpec";
import { StatisticModal } from "../StatsCard/StatisticModal";
import ConfigureFilters from "../ConfigureFilters";
import MockDatasetPreview from "../VisualConfigPreview/MockDatasetPreview";

const getObjectById = (arr, id) => {
  return arr.find((obj) => obj.id === id);
};

const VisualConfigDrawer = ({
  chart,
  label,
  params,
  onClose,
  setLabel,
  isPreview = false,
  setParams,
  mockDataset,
  onClickSwitchVisual,
}) => {
  const mockdata = getObjectById(mockDataset, chart?.mockDataId);

  const tabs = [
    {
      label: "Mock Dataset",
      path: "mockdataset",
      component: isPreview ? (
        <MockDatasetPreview mockDataset={mockdata} />
      ) : (
        <MockDatasetTab />
      ),
    },
    {
      label: "Configuration",
      path: "configuration",
      component:
        chart?.type === "Statistic" ? (
          <StatisticModal
            chart={chart}
            label={label}
            params={params}
            setLabel={setLabel}
            setParams={setParams}
            mockDataset={mockdata}
            isPreview={isPreview}
          />
        ) : chart?.type === "Filter" ? (
          <ConfigureFilters
            chart={chart}
            label={label}
            params={params}
            setLabel={setLabel}
            setParams={setParams}
            mockDataset={mockdata}
            isPreview={isPreview}
          />
        ) : (
          <ConfigureChart
            isPreview={isPreview}
            chart={chart}
            label={label}
            params={params}
            setLabel={setLabel}
            setParams={setParams}
            mockDataset={mockdata}
            onClickSwitchVisual={onClickSwitchVisual}
          />
        ),
    },
    {
      label: "Requirements",
      path: "requirements",
      component: <VisualRequirements isPreview={isPreview} />,
    },
  ];

  const dispatch = useDispatch();
  const theme = useTheme();
  const { isVisualConfigOpen } = useSelector((state) => state?.spec);

  const [currentTab, setCurrentTab] = useState("mockdataset");

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Drawer
      open={isVisualConfigOpen}
      variant='permanent'
      anchor='right'
      sx={{
        borderTop: "1px solid transparent",
        backgroundColor: "white",
        color: "balck",
      }}
      PaperProps={{
        sx: {
          mt: isMdUp ? 0 : "145px",
          height: isMdUp ? 1 : `calc(100% - 145px)`,
          ...(isMdUp && { position: "relative", zIndex: 0 }),
          width: isMdUp ? "450px" : "auto",
          ...(isVisualConfigOpen ? openedMixin(theme) : closedMixin(theme)),
        },
      }}>
      <Box
        sx={{
          height: 1,
          width: 1,
        }}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <StyledTabs
            orientation={"horizontal"}
            value={currentTab}
            onChange={handleChange}
            indicatorColor='primary'
            aria-label='secondary tabs example'>
            {tabs?.map((tab) => (
              <StyledTab
                key={tab?.path}
                value={tab?.path}
                label={tab?.label}
                {...a11yProps(tab?.path)}
              />
            ))}
          </StyledTabs>
          <IconButton
            disableRipple
            sx={{ p: 0, mr: 1 }}
            onClick={() => {
              dispatch(setIsVisualConfigOpen({ open: false }));
              setCurrentTab("mockdataset");
              onClose();
            }}>
            <Close
              fontSize='small'
              sx={(theme) => ({ color: theme.palette.gray.iconColor })}
            />
          </IconButton>
        </Stack>

        <Divider
          sx={(theme) => ({
            bgcolor: `1px solid ${theme.palette.gray.borderGray}`,
          })}
        />
        {tabs?.map((tab) => (
          <CustomTabPanel key={tab?.path} value={currentTab} index={tab?.path}>
            {tab?.component}
          </CustomTabPanel>
        ))}
      </Box>
    </Drawer>
  );
};

export default VisualConfigDrawer;
