import { getInvoices } from "../../services";
import { HTTP_OK } from "../../constants/statusCodes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultExceptionHandler } from "../../utils/defaultExceptionHandler";

const initialState = {
  invoices: [],
  isInvoicesLoading: false,
};

export const getInvoicesAsync = createAsyncThunk(
  "billing/getInvoicesAsync",
  async (payload) => {
    try {
      const response = await getInvoices();

      if (response?.status !== HTTP_OK) {
        throw new Error("Something went wrong!");
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

const billingSlice = createSlice({
  name: "billing",
  initialState: initialState,

  reducers: {},

  extraReducers: (builder) => {
    // GET STATUSES BUILDER FUNCTIONS
    builder.addCase(getInvoicesAsync.pending, (state, action) => {
      state.isInvoicesLoading = true;
    });
    builder.addCase(getInvoicesAsync.fulfilled, (state, action) => {
      state.isInvoicesLoading = false;
      state.invoices = action?.payload?.invoices;
    });
    builder.addCase(getInvoicesAsync.rejected, (state) => {
      state.isInvoicesLoading = false;
    });
  },
});

billingSlice.actions;
export default billingSlice.reducer;
