import React, { useState, useEffect } from "react";
import BaseModal from "../../common/BaseModal";
import {
  BAR_CHART,
  BOX_PLOT,
  COLUMN_CHART,
  COLUMN_CHART_TIME,
  MULTIPLE_LINE_CHART,
  PIE_CHART,
  SCATTER_CHART,
  SCATTER_CHART_SECOND,
  SINGLE_LINE_CHART,
  STACKED_100_AREA_CHART,
  STACKED_100_COLUMN_CHART,
  STACKED_AREA_CHART,
  STACKED_COLUMN_CHART,
  STAT_FUNCTIONS_ARR,
  TOP_N_ARR,
  charTypes,
} from "../SelectVisuals/utils";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tooltip,
  Typography,
  Switch,
} from "@mui/material";
import { InputField } from "../../common/InputField";
import {
  BOOLEAN,
  DATE,
  DAY,
  GEOGRAPHY,
  MONTH,
  STATE_BUSINESS_TERMS_LABEL,
  TEXT,
  TIME,
  TYPE_LABEL,
  WEEK,
  YEAR,
} from "../../constants";
import { DeleteOutline, InfoOutlined } from "@mui/icons-material";
import { isEmpty } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import { MuiSelectField } from "../../common/style";
import { useSelector } from "react-redux";
// import SwitchVisual from "../SwitchVisual";

const ConfigureChart = ({
  chart,
  setParams,
  mockDataset,
  params,
  label,
  setLabel,
  isPreview = false,
  // onClickSwitchVisual,
}) => {
  const chartType = charTypes.find((item) => item.id === chart?.type);
  const [isInverted, setIsInverted] = useState(params?.isInverted);
  const xAxisTypes = chartType?.xAxisType || [];
  const yAxisTypes = chartType?.yAxisType || [];
  const [isMultipleSeries, setIsMultipleSeries] = useState(false);
  const [multipleSeries, setMultipleSeries] = useState([0]);
  const [boxPlotXAxisLabel, setBoxPlotXAxisLabel] = useState("");
  const [boxPlotYAxisLabel, setBoxPlotYAxisLabel] = useState("");
  const { isVisualConfigOpen, selectedChart } = useSelector(
    (state) => state?.spec
  );

  const getBusinessTerm = (term) => {
    if (term.toLowerCase() === "default") {
      return "Default";
    } else {
      return term;
    }
  };

  useEffect(() => {
    if (params?.multipleSeries && params?.multipleSeries.length > 0) {
      setMultipleSeries(params?.multipleSeries);
      setIsMultipleSeries(true);
    } else {
      setIsMultipleSeries(false);
    }
  }, [params?.multipleSeries, isVisualConfigOpen]);

  useEffect(() => {
    if (params?.xAxisLabel) {
      setBoxPlotXAxisLabel(params?.xAxisLabel);
    }

    if (params?.yAxisLabel) {
      setBoxPlotYAxisLabel(params?.yAxisLabel);
    }
  }, [params?.xAxisLabel, params?.yAxisLabel, isVisualConfigOpen]);

  const handleSeriesDelete = (index) => {
    let dumpArray = [...multipleSeries];
    dumpArray.splice(index, 1);
    setParams({ ...params, multipleSeries: dumpArray });
    setMultipleSeries(dumpArray);
  };

  const handleSeriesChange = (funcparams) => {
    let dumpArray = [...multipleSeries];
    let object = { ...funcparams };
    delete object.index;
    dumpArray[funcparams.index] = { ...object };
    setParams({ ...params, multipleSeries: dumpArray });
    setMultipleSeries(dumpArray);
  };

  const handleChartToggle = () => {
    setIsInverted(!isInverted);
    setParams({ ...params, isInverted: !isInverted });
  };

  return (
    <Box py={2} px={4}>
      <Stack direction='column' spacing={2} alignItems='center'>
        {/* <Stack
          sx={{
            width: 1,
            flexDirection: "row",
            justifyContent: "flex-end",
            mb: "-30px !important",
            position: "relative",
            zIndex: 1,
          }}>
          <SwitchVisual onClick={(e) => onClickSwitchVisual(chart)} />
        </Stack> */}

        <Stack direction='column' spacing={0.5} sx={{ width: "100%" }}>
          <InputLabel>
            <Typography variant='spec_inputLabel'>Title:</Typography>
          </InputLabel>
          <InputField
            type='text'
            value={label}
            name='Title'
            placeholder='Enter title'
            fullWidth
            noTextError
            id={`label`}
            onChange={(e) => {
              setLabel(e?.target?.value);
            }}
            disabled={isPreview}
          />
        </Stack>

        {[
          MULTIPLE_LINE_CHART,
          COLUMN_CHART_TIME,
          STACKED_AREA_CHART,
          STACKED_100_AREA_CHART,
        ].includes(chart?.params?.id) && (
          <>
            <Stack
              direction='column'
              spacing={2}
              alignItems='left'
              sx={{ width: "100%" }}>
              <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
                <InputLabel>
                  <Typography variant='spec_inputLabel'>
                    {chartType.timeLabel}
                  </Typography>
                </InputLabel>
                <MuiSelectField
                  disabled={isPreview}
                  id='table'
                  value={params?.x || 0}
                  onChange={(e) => {
                    setParams({
                      ...params,
                      x: e.target.value,
                      xTitle: mockDataset?.table.columns.find(
                        (item) => item.columnId === e.target.value
                      )?.name,
                    });
                  }}
                  sx={{
                    width: "100%",
                    "#table": { display: "flex" },
                    ":has(div)": { color: "#8c8c8c", alignItems: "end" },
                  }}>
                  <MenuItem value={0} disabled>
                    <Typography variant='spec_body'>
                      {chartType.placeholderTime}
                    </Typography>
                  </MenuItem>
                  {mockDataset?.table.columns.map(
                    (item, index) =>
                      [
                        TIME.type,
                        DATE.type,
                        DAY.type,
                        WEEK.type,
                        MONTH.type,
                        YEAR.type,
                      ].includes(item.type) && (
                        <MenuItem
                          key={index}
                          value={item.columnId}
                          sx={{ alignItems: "end" }}>
                          <Typography
                            mr={1}
                            color='text.primary'
                            variant='spec_body'>
                            {item.name}
                          </Typography>
                          <Typography color='#8c8c8c' variant='spec_body'>
                            [{TYPE_LABEL[item?.text]} -{" "}
                            {getBusinessTerm(
                              STATE_BUSINESS_TERMS_LABEL[item?.state]
                            )}
                            ]
                          </Typography>
                        </MenuItem>
                      )
                  )}
                </MuiSelectField>
              </Stack>
            </Stack>

            <Stack
              direction='column'
              spacing={2}
              alignItems='left'
              sx={{ width: "100%" }}>
              <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
                <InputLabel>
                  <Typography variant='spec_inputLabel'>Series</Typography>
                  <Typography
                    variant='spec_inputLabel'
                    sx={{ fontSize: "10px", p: 0 }}>
                    (each series must have the same unit)
                  </Typography>
                </InputLabel>
                {multipleSeries?.map((series, index) => {
                  const selectedValues = multipleSeries
                    .filter((s, i) => i !== index) // Exclude current series
                    .map((s) => s.y);

                  return (
                    <Box
                      key={index} // Added key prop for list items
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <MuiSelectField
                        disabled={isPreview}
                        id={`table_${index}`} // Use a unique ID for each select field
                        value={series?.y || ""} // Ensure a default value or empty string
                        onChange={(e) => {
                          handleSeriesChange({
                            index,
                            y: e.target.value,
                            yTitle: mockDataset.table.columns.find(
                              (item) => item.columnId === e.target.value
                            )?.name,
                          });
                        }}
                        sx={{
                          width: "100%",
                          [`#table_${index}`]: { display: "flex" },
                          ":has(div)": {
                            color: "#8c8c8c",
                            alignItems: "end",
                          },
                        }}>
                        {/* Uncomment placeholder MenuItem if needed */}
                        {/* <MenuItem value={0} disabled>
          {chartType.placeholderY}
        </MenuItem> */}
                        {mockDataset?.table.columns.map((item, colIndex) => {
                          if (
                            yAxisTypes.includes(item.text) &&
                            !selectedValues.includes(item.columnId)
                          ) {
                            return (
                              <MenuItem
                                key={colIndex} // Use a unique key for each MenuItem
                                value={item.columnId}
                                sx={{ display: "flex", alignItems: "end" }}>
                                <Typography
                                  mr={1}
                                  color='text.primary'
                                  variant='spec_body'>
                                  {item.name}
                                </Typography>
                                <Typography color='#8c8c8c' variant='spec_body'>
                                  [{TYPE_LABEL[item?.text]} -{" "}
                                  {getBusinessTerm(
                                    STATE_BUSINESS_TERMS_LABEL[item?.state]
                                  )}
                                  ]
                                </Typography>
                              </MenuItem>
                            );
                          }
                          return null; // Skip rendering if condition is not met
                        })}
                      </MuiSelectField>
                      {index > 0 && (
                        <IconButton
                          onClick={() => handleSeriesDelete(index)}
                          disabled={isPreview}>
                          <DeleteOutline />
                        </IconButton>
                      )}
                    </Box>
                  );
                })}
              </Stack>
              <Button
                disabled={isPreview}
                variant='outlined'
                sx={{ width: "fit-content" }}
                onClick={() => setMultipleSeries([...multipleSeries, 0])}
                startIcon={<AddIcon />}>
                Add More
              </Button>
            </Stack>
          </>
        )}

        {[BOX_PLOT].includes(chart?.params?.id) && (
          <>
            <Stack width={1} rowGap={2}>
              <InputField
                value={boxPlotXAxisLabel || ""}
                onChange={(e) => {
                  setBoxPlotXAxisLabel(e.target.value);
                  setParams({ ...params, xAxisLabel: e.target.value });
                }}
                isLabel={true}
                label='X-axis Label'
                placeholder='Enter x-axis label'
                disabled={isPreview}
              />
              <InputField
                value={boxPlotYAxisLabel || ""}
                onChange={(e) => {
                  setBoxPlotYAxisLabel(e.target.value);
                  setParams({ ...params, yAxisLabel: e.target.value });
                }}
                isLabel={true}
                label='Y-axis Label'
                placeholder='Enter y-axis label'
                disabled={isPreview}
              />
            </Stack>
            <Stack
              direction='column'
              spacing={2}
              alignItems='left'
              sx={{ width: "100%" }}>
              <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
                <InputLabel>
                  <Typography variant='spec_inputLabel'>Series</Typography>
                  <Typography
                    variant='spec_inputLabel'
                    sx={{ fontSize: "10px", p: 0 }}>
                    (each series must have the same unit)
                  </Typography>
                </InputLabel>
                {multipleSeries?.map((series, index) => {
                  const selectedValues = multipleSeries
                    .filter((s, i) => i !== index) // Exclude current series
                    .map((s) => s.y);

                  return (
                    <Box
                      key={index} // Added key prop for list items
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <MuiSelectField
                        disabled={isPreview}
                        id={`table_${index}`} // Use a unique ID for each select field
                        value={series?.y || ""} // Ensure a default value or empty string
                        onChange={(e) => {
                          handleSeriesChange({
                            index,
                            y: e.target.value,
                            yTitle: mockDataset.table.columns.find(
                              (item) => item.columnId === e.target.value
                            )?.name,
                          });
                        }}
                        sx={{
                          width: "100%",
                          [`#table_${index}`]: { display: "flex" },
                          ":has(div)": {
                            color: "#8c8c8c",
                            alignItems: "end",
                          },
                        }}>
                        {/* Uncomment placeholder MenuItem if needed */}
                        <MenuItem value={0} disabled>
                          <Typography variant='spec_body'>
                            Select Series
                          </Typography>
                        </MenuItem>
                        {mockDataset?.table.columns.map((item, colIndex) => {
                          if (
                            yAxisTypes.includes(item.text) &&
                            !selectedValues.includes(item.columnId)
                          ) {
                            return (
                              <MenuItem
                                key={colIndex} // Use a unique key for each MenuItem
                                value={item.columnId}
                                sx={{ display: "flex", alignItems: "end" }}>
                                <Typography
                                  mr={1}
                                  color='text.primary'
                                  variant='spec_body'>
                                  {item.name}
                                </Typography>
                                <Typography color='#8c8c8c' variant='spec_body'>
                                  [{TYPE_LABEL[item?.text]} -{" "}
                                  {getBusinessTerm(
                                    STATE_BUSINESS_TERMS_LABEL[item?.state]
                                  )}
                                  ]
                                </Typography>
                              </MenuItem>
                            );
                          }
                          return null; // Skip rendering if condition is not met
                        })}
                      </MuiSelectField>
                      {index > 0 && (
                        <IconButton
                          onClick={() => handleSeriesDelete(index)}
                          disabled={isPreview}>
                          <DeleteOutline />
                        </IconButton>
                      )}
                    </Box>
                  );
                })}
              </Stack>
              <Button
                disabled={isPreview}
                variant='outlined'
                sx={{ width: "fit-content" }}
                onClick={() => setMultipleSeries([...multipleSeries, 0])}
                startIcon={<AddIcon />}>
                Add More
              </Button>
            </Stack>
          </>
        )}

        {[STACKED_100_COLUMN_CHART, STACKED_COLUMN_CHART].includes(
          chart?.params?.id
        ) && (
          <>
            <Stack
              direction='column'
              spacing={2}
              alignItems='left'
              sx={{ width: "100%" }}>
              <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
                <InputLabel>
                  <Typography variant='spec_inputLabel'>
                    {!params?.isInverted ? chartType.timeLabel : "Y - Axis"}
                  </Typography>
                </InputLabel>
                <MuiSelectField
                  disabled={isPreview}
                  id='table'
                  value={params?.x || 0}
                  onChange={(e) => {
                    setParams({
                      ...params,
                      x: e.target.value,
                      xTitle: mockDataset?.table.columns.find(
                        (item) => item.columnId === e.target.value
                      )?.name,
                    });
                  }}
                  sx={{
                    width: "100%",
                    "#table": { display: "flex" },
                    ":has(div)": { color: "#8c8c8c", alignItems: "end" },
                  }}>
                  <MenuItem value={0} disabled>
                    <Typography variant='spec_body'>
                      {chartType.placeholderTime}
                    </Typography>
                  </MenuItem>
                  {mockDataset?.table.columns.map(
                    (item, index) =>
                      [
                        TIME.type,
                        DATE.type,
                        DAY.type,
                        WEEK.type,
                        MONTH.type,
                        YEAR.type,
                        TEXT.type,
                        GEOGRAPHY.type,
                        BOOLEAN.type,
                      ].includes(item.type) && (
                        <MenuItem
                          key={index}
                          value={item.columnId}
                          sx={{ alignItems: "end" }}>
                          <Typography
                            mr={1}
                            color='text.primary'
                            variant='spec_body'>
                            {item.name}
                          </Typography>
                          <Typography color='#8c8c8c' variant='spec_body'>
                            [{TYPE_LABEL[item?.text]} -{" "}
                            {getBusinessTerm(
                              STATE_BUSINESS_TERMS_LABEL[item?.state]
                            )}
                            ]
                          </Typography>
                        </MenuItem>
                      )
                  )}
                </MuiSelectField>
              </Stack>
              <Stack direction={"row"} spacing={"4"} alignItems={"center"}>
                <InputLabel>
                  <Typography variant='spec_inputLabel'>
                    Show Inverted Chart
                  </Typography>
                </InputLabel>
                <Switch
                  disabled={isPreview}
                  onChange={handleChartToggle}
                  value={params?.isInverted}
                  checked={params?.isInverted}
                />
              </Stack>
            </Stack>

            <Stack
              direction='column'
              spacing={2}
              alignItems='left'
              sx={{ width: "100%" }}>
              <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
                <InputLabel>
                  <Typography variant='spec_inputLabel'>Series</Typography>
                  <Typography
                    variant='spec_inputLabel'
                    sx={{ fontSize: "10px", p: 0 }}>
                    (each series must have the same unit)
                  </Typography>
                </InputLabel>
                {multipleSeries?.map((series, index) => {
                  const selectedValues = multipleSeries
                    .filter((s, i) => i !== index) // Exclude current series
                    .map((s) => s.y);

                  return (
                    <Box
                      key={index} // Added key prop for list items
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <MuiSelectField
                        disabled={isPreview}
                        id={`table_${index}`} // Use a unique ID for each select field
                        value={series?.y || ""} // Ensure a default value or empty string
                        onChange={(e) => {
                          handleSeriesChange({
                            index,
                            y: e.target.value,
                            yTitle: mockDataset.table.columns.find(
                              (item) => item.columnId === e.target.value
                            )?.name,
                          });
                        }}
                        sx={{
                          width: "100%",
                          [`#table_${index}`]: { display: "flex" },
                          ":has(div)": {
                            color: "#8c8c8c",
                            alignItems: "end",
                          },
                        }}>
                        {/* Uncomment placeholder MenuItem if needed */}
                        {/* <MenuItem value={0} disabled>
          {chartType.placeholderY}
        </MenuItem> */}
                        {mockDataset?.table.columns.map((item, colIndex) => {
                          if (
                            yAxisTypes.includes(item.text) &&
                            !selectedValues.includes(item.columnId)
                          ) {
                            return (
                              <MenuItem
                                key={colIndex} // Use a unique key for each MenuItem
                                value={item.columnId}
                                sx={{ display: "flex", alignItems: "end" }}>
                                <Typography
                                  mr={1}
                                  color='text.primary'
                                  variant='spec_body'>
                                  {item.name}
                                </Typography>
                                <Typography color='#8c8c8c' variant='spec_body'>
                                  [{TYPE_LABEL[item?.text]} -{" "}
                                  {getBusinessTerm(
                                    STATE_BUSINESS_TERMS_LABEL[item?.state]
                                  )}
                                  ]
                                </Typography>
                              </MenuItem>
                            );
                          }
                          return null; // Skip rendering if condition is not met
                        })}
                      </MuiSelectField>
                      {index > 0 && (
                        <IconButton
                          onClick={() => handleSeriesDelete(index)}
                          disabled={isPreview}>
                          <DeleteOutline />
                        </IconButton>
                      )}
                    </Box>
                  );
                })}
              </Stack>
              <Button
                disabled={isPreview}
                variant='outlined'
                sx={{ width: "fit-content" }}
                onClick={() => setMultipleSeries([...multipleSeries, 0])}
                startIcon={<AddIcon />}>
                Add More
              </Button>
            </Stack>
          </>
        )}
        {[SINGLE_LINE_CHART].includes(chart?.params?.id) && (
          <>
            <Stack
              direction='column'
              spacing={2}
              alignItems='left'
              sx={{ width: "100%" }}>
              <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
                <InputLabel>
                  <Typography variant='spec_inputLabel'>
                    {chartType.timeLabel}
                  </Typography>
                </InputLabel>
                <MuiSelectField
                  disabled={isPreview}
                  id='table'
                  value={params?.x || 0}
                  onChange={(e) => {
                    setParams({
                      ...params,
                      x: e.target.value,
                      xTitle: mockDataset?.table.columns.find(
                        (item) => item.columnId === e.target.value
                      )?.name,
                    });
                  }}
                  sx={{
                    width: "100%",
                    "#table": { display: "flex" },
                    ":has(div)": { color: "#8c8c8c", alignItems: "end" },
                  }}>
                  <MenuItem value={0} disabled>
                    <Typography variant='spec_body'>
                      {chartType.placeholderTime}
                    </Typography>
                  </MenuItem>
                  {mockDataset?.table.columns.map(
                    (item, index) =>
                      [
                        TIME.type,
                        DATE.type,
                        DAY.type,
                        WEEK.type,
                        MONTH.type,
                        YEAR.type,
                      ].includes(item.type) && (
                        <MenuItem
                          key={index}
                          value={item.columnId}
                          sx={{ display: "flex", alignItems: "end" }}>
                          <Typography
                            mr={1}
                            color='text.primary'
                            variant='spec_body'>
                            {item.name}
                          </Typography>
                          <Typography color='#8c8c8c' variant='spec_body'>
                            [{TYPE_LABEL[item?.text]} -{" "}
                            {getBusinessTerm(
                              STATE_BUSINESS_TERMS_LABEL[item?.state]
                            )}
                            ]
                          </Typography>
                        </MenuItem>
                      )
                  )}
                </MuiSelectField>
              </Stack>
            </Stack>
            <Stack
              direction='column'
              spacing={2}
              alignItems='left'
              sx={{ width: "100%" }}>
              <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
                <InputLabel>
                  <Typography variant='spec_inputLabel'>
                    {chartType.yLabel}
                  </Typography>
                </InputLabel>
                <MuiSelectField
                  disabled={isPreview}
                  id='table'
                  value={params?.y || 0}
                  onChange={(e) => {
                    setParams({
                      ...params,
                      y: e.target.value,
                      yTitle: mockDataset?.table.columns.find(
                        (item) => item.columnId === e.target.value
                      )?.name,
                    });
                  }}
                  sx={{
                    width: "100%",
                    "#table": { display: "flex" },
                    ":has(div)": { color: "#8c8c8c", alignItems: "end" },
                  }}>
                  <MenuItem value={0} disabled>
                    <Typography variant='spec_body'>
                      {chartType.placeholderY}
                    </Typography>
                  </MenuItem>
                  {mockDataset?.table.columns.map(
                    (item, index) =>
                      yAxisTypes.includes(item.text) && (
                        <MenuItem
                          key={index}
                          value={item.columnId}
                          sx={{ display: "flex", alignItems: "end" }}>
                          <Typography
                            mr={1}
                            color='text.primary'
                            variant='spec_body'>
                            {item.name}
                          </Typography>
                          <Typography color='#8c8c8c' variant='spec_body'>
                            [{TYPE_LABEL[item?.text]} -{" "}
                            {getBusinessTerm(
                              STATE_BUSINESS_TERMS_LABEL[item?.state]
                            )}
                            ]
                          </Typography>
                        </MenuItem>
                      )
                  )}
                </MuiSelectField>
              </Stack>
            </Stack>
          </>
        )}
        {[SCATTER_CHART, SCATTER_CHART_SECOND, COLUMN_CHART].includes(
          chart?.params?.id
        ) && (
          <>
            <Stack
              direction='column'
              spacing={2}
              alignItems='left'
              sx={{ width: "100%" }}>
              <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
                <InputLabel>
                  <Typography variant='spec_inputLabel'>
                    {chartType.xLabel}
                  </Typography>
                </InputLabel>
                <MuiSelectField
                  disabled={isPreview}
                  id='table'
                  value={params?.x || 0}
                  onChange={(e) => {
                    setParams({
                      ...params,
                      x: e.target.value,
                      xTitle: mockDataset?.table.columns.find(
                        (item) => item.columnId === e.target.value
                      )?.name,
                    });
                  }}
                  sx={{
                    width: "100%",
                    "#table": { display: "flex" },
                    ":has(div)": { color: "#8c8c8c", alignItems: "end" },
                  }}>
                  <MenuItem value={0} disabled>
                    <Typography variant='spec_body'>
                      {chartType.placeholderX}
                    </Typography>
                  </MenuItem>
                  {mockDataset?.table.columns.map(
                    (item, index) =>
                      xAxisTypes.includes(item.text) && (
                        <MenuItem
                          key={index}
                          value={item.columnId}
                          sx={{ display: "flex", alignItems: "end" }}>
                          <Typography
                            mr={1}
                            color='text.primary'
                            variant='spec_body'>
                            {item.name}
                          </Typography>
                          <Typography color='#8c8c8c' variant='spec_body'>
                            [{TYPE_LABEL[item?.text]} -{" "}
                            {getBusinessTerm(
                              STATE_BUSINESS_TERMS_LABEL[item?.state]
                            )}
                            ]
                          </Typography>
                        </MenuItem>
                      )
                  )}
                </MuiSelectField>
              </Stack>
            </Stack>
            <Stack
              direction='column'
              spacing={2}
              alignItems='left'
              sx={{ width: "100%" }}>
              <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
                <InputLabel>
                  <Typography variant='spec_inputLabel'>
                    {chartType.yLabel}
                  </Typography>
                </InputLabel>
                <MuiSelectField
                  disabled={isPreview}
                  id='table'
                  value={params?.y || 0}
                  onChange={(e) => {
                    setParams({
                      ...params,
                      y: e.target.value,
                      yTitle: mockDataset?.table.columns.find(
                        (item) => item.columnId === e.target.value
                      )?.name,
                    });
                  }}
                  sx={{
                    width: "100%",
                    "#table": { display: "flex" },
                    ":has(div)": { color: "#8c8c8c", alignItems: "end" },
                  }}>
                  <MenuItem value={0} disabled>
                    <Typography variant='spec_body'>
                      {chartType.placeholderY}
                    </Typography>
                  </MenuItem>
                  {mockDataset?.table.columns.map(
                    (item, index) =>
                      yAxisTypes.includes(item.text) && (
                        <MenuItem
                          key={index}
                          value={item.columnId}
                          sx={{ display: "flex", alignItems: "end" }}>
                          <Typography
                            mr={1}
                            color='text.primary'
                            variant='spec_body'>
                            {item.name}
                          </Typography>
                          <Typography color='#8c8c8c' variant='spec_body'>
                            [{TYPE_LABEL[item?.text]} -{" "}
                            {getBusinessTerm(
                              STATE_BUSINESS_TERMS_LABEL[item?.state]
                            )}
                            ]
                          </Typography>
                        </MenuItem>
                      )
                  )}
                </MuiSelectField>
              </Stack>
            </Stack>
          </>
        )}
        {/*we have swapped axies in BOX_PLOT and BAR-Chart*/}
        {[BAR_CHART].includes(chart?.params?.id) && (
          <>
            <Stack
              direction='column'
              spacing={2}
              alignItems='left'
              sx={{ width: "100%" }}>
              <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
                <InputLabel>
                  <Typography variant='spec_inputLabel'>
                    {chartType.xLabel}
                  </Typography>
                </InputLabel>
                <MuiSelectField
                  disabled={isPreview}
                  id='table'
                  value={params?.y || 0}
                  onChange={(e) => {
                    setParams({
                      ...params,
                      y: e.target.value,
                      yTitle: mockDataset?.table.columns.find(
                        (item) => item.columnId === e.target.value
                      )?.name,
                    });
                  }}
                  sx={{
                    width: "100%",
                    "#table": { display: "flex" },
                    ":has(div)": { color: "#8c8c8c", alignItems: "end" },
                  }}>
                  <MenuItem value={0} disabled>
                    <Typography variant='spec_body'>
                      {chartType.placeholderX}
                    </Typography>
                  </MenuItem>
                  {mockDataset?.table.columns.map(
                    (item, index) =>
                      yAxisTypes.includes(item.text) && (
                        <MenuItem
                          key={index}
                          value={item.columnId}
                          sx={{ display: "flex", alignItems: "end" }}>
                          <Typography
                            mr={1}
                            color='text.primary'
                            variant='spec_body'>
                            {item.name}
                          </Typography>
                          <Typography color='#8c8c8c' variant='spec_body'>
                            [{TYPE_LABEL[item?.text]} -{" "}
                            {getBusinessTerm(
                              STATE_BUSINESS_TERMS_LABEL[item?.state]
                            )}
                            ]
                          </Typography>
                        </MenuItem>
                      )
                  )}
                </MuiSelectField>
              </Stack>
            </Stack>
            <Stack
              direction='column'
              spacing={2}
              alignItems='left'
              sx={{ width: "100%" }}>
              <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
                <InputLabel>
                  <Typography variant='spec_inputLabel'>
                    {chartType.yLabel}
                  </Typography>
                </InputLabel>
                <MuiSelectField
                  disabled={isPreview}
                  id='table'
                  value={params?.x || 0}
                  onChange={(e) => {
                    setParams({
                      ...params,
                      x: e.target.value,
                      xTitle: mockDataset?.table.columns.find(
                        (item) => item.columnId === e.target.value
                      )?.name,
                    });
                  }}
                  sx={{
                    width: "100%",
                    "#table": { display: "flex" },
                    ":has(div)": { color: "#8c8c8c", alignItems: "end" },
                  }}>
                  <MenuItem value={0} disabled>
                    <Typography variant='spec_body'>
                      {chartType.placeholderY}
                    </Typography>
                  </MenuItem>
                  {mockDataset?.table.columns.map(
                    (item, index) =>
                      xAxisTypes.includes(item.text) && (
                        <MenuItem
                          key={index}
                          value={item.columnId}
                          sx={{ display: "flex", alignItems: "end" }}>
                          <Typography
                            mr={1}
                            color='text.primary'
                            variant='spec_body'>
                            {item.name}
                          </Typography>
                          <Typography color='#8c8c8c' variant='spec_body'>
                            [{TYPE_LABEL[item?.text]} -{" "}
                            {getBusinessTerm(
                              STATE_BUSINESS_TERMS_LABEL[item?.state]
                            )}
                            ]
                          </Typography>
                        </MenuItem>
                      )
                  )}
                </MuiSelectField>
              </Stack>
            </Stack>
          </>
        )}
        {PIE_CHART === chart?.params?.id && (
          <>
            <Stack
              direction='column'
              spacing={2}
              alignItems='left'
              sx={{ width: "100%" }}>
              <Stack
                direction='column'
                spacing={2}
                alignItems='left'
                sx={{ width: "100%" }}>
                <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
                  <InputLabel>
                    <Typography variant='spec_inputLabel'>
                      {chartType.yLabel}
                    </Typography>
                  </InputLabel>
                  <MuiSelectField
                    disabled={isPreview}
                    id='table'
                    value={params?.y || 0}
                    onChange={(e) => {
                      setParams({
                        ...params,
                        y: e.target.value,
                        yTitle: mockDataset?.table.columns.find(
                          (item) => item.columnId === e.target.value
                        )?.name,
                      });
                    }}
                    sx={{
                      width: "100%",
                      "#table": { display: "flex" },
                      ":has(div)": { color: "#8c8c8c", alignItems: "end" },
                    }}>
                    <MenuItem value={0} disabled>
                      <Typography variant='spec_body'>
                        {chartType.placeholderY}
                      </Typography>
                    </MenuItem>
                    {mockDataset?.table.columns.map(
                      (item, index) =>
                        yAxisTypes.includes(item.text) && (
                          <MenuItem
                            key={index}
                            value={item.columnId}
                            sx={{ display: "flex", alignItems: "end" }}>
                            <Typography
                              mr={1}
                              color='text.primary'
                              variant='spec_body'>
                              {item.name}
                            </Typography>
                            <Typography color='#8c8c8c' variant='spec_body'>
                              [{TYPE_LABEL[item?.text]} -{" "}
                              {getBusinessTerm(
                                STATE_BUSINESS_TERMS_LABEL[item?.state]
                              )}
                              ]
                            </Typography>
                          </MenuItem>
                        )
                    )}
                  </MuiSelectField>
                </Stack>
              </Stack>
              <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
                <InputLabel>
                  <Typography variant='spec_inputLabel'>
                    {chartType.xLabel}
                  </Typography>
                </InputLabel>
                <MuiSelectField
                  disabled={isPreview}
                  id='table'
                  value={params?.x || 0}
                  onChange={(e) => {
                    setParams({
                      ...params,
                      x: e.target.value,
                      xTitle: mockDataset?.table.columns.find(
                        (item) => item.columnId === e.target.value
                      )?.name,
                    });
                  }}
                  sx={{
                    width: "100%",
                    "#table": { display: "flex" },
                    ":has(div)": { color: "#8c8c8c" },
                  }}>
                  <MenuItem value={0} disabled>
                    <Typography variant='spec_body'>
                      {chartType.placeholderX}
                    </Typography>
                  </MenuItem>
                  {mockDataset?.table.columns.map(
                    (item, index) =>
                      xAxisTypes.includes(item.text) && (
                        <MenuItem
                          key={index}
                          value={item.columnId}
                          sx={{ display: "flex", alignItems: "end" }}>
                          <Typography
                            mr={1}
                            color='text.primary'
                            variant='spec_body'>
                            {item.name}
                          </Typography>
                          <Typography color='#8c8c8c' variant='spec_body'>
                            [{TYPE_LABEL[item?.text]} -{" "}
                            {getBusinessTerm(
                              STATE_BUSINESS_TERMS_LABEL[item?.state]
                            )}
                            ]
                          </Typography>
                        </MenuItem>
                      )
                  )}
                </MuiSelectField>
              </Stack>
            </Stack>
          </>
        )}

        {![
          BOX_PLOT,
          SCATTER_CHART,
          STACKED_100_COLUMN_CHART,
          MULTIPLE_LINE_CHART,
          STACKED_COLUMN_CHART,
          PIE_CHART,
        ].includes(chart?.params?.id) &&
          !isMultipleSeries && (
            <Stack
              direction='column'
              spacing={2}
              alignItems='left'
              sx={{ width: "100%" }}>
              <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
                <InputLabel>
                  <Typography variant='spec_inputLabel'>
                    Select Aggregation{" "}
                    {!isEmpty(chartType?.metricAxis) &&
                      `for ${chartType?.metricAxis
                        .map((axis) => axis)
                        .join(" & ")}`}
                  </Typography>
                </InputLabel>
                <MuiSelectField
                  disabled={isPreview}
                  id='func'
                  placeholder='Select Aggregation'
                  value={params?.metric || 0}
                  onChange={(e) => {
                    setParams({
                      ...params,
                      metric: e.target.value,
                    });
                  }}
                  sx={{
                    width: "100%",
                    "#table": { display: "flex" },
                    ":has(div)": { color: "#8c8c8c", alignItems: "end" },
                  }}>
                  <MenuItem value={0} disabled>
                    <Typography variant='spec_body'>
                      Select Aggregation
                    </Typography>
                  </MenuItem>
                  {STAT_FUNCTIONS_ARR.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.id}
                      sx={{ display: "flex", alignItems: "end" }}>
                      <Typography
                        mr={1}
                        color='text.primary'
                        variant='spec_body'>
                        {item.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </MuiSelectField>
              </Stack>
            </Stack>
          )}
        {[BAR_CHART, COLUMN_CHART].includes(chart?.params?.id) && (
          <Stack
            direction='column'
            spacing={2}
            alignItems='left'
            sx={{ width: "100%" }}>
            <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
              <Stack direction={"row"} columnGap={1}>
                <InputLabel>
                  <Typography variant='spec_inputLabel'>
                    Select Number of{" "}
                    {BAR_CHART === chart?.params?.id ? "Bars" : "Columns"}
                  </Typography>
                </InputLabel>
                <Tooltip
                  arrow
                  title={`The top N items may include tied values. All tied items are displayed.`}>
                  <InfoOutlined fontSize='small' color='info' />
                </Tooltip>
              </Stack>
              <MuiSelectField
                disabled={isPreview}
                id='func'
                placeholder={`Select ${
                  BAR_CHART === chart?.params?.id ? "Bars" : "Columns"
                }`}
                value={params?.topN || 0}
                onChange={(e) => {
                  setParams({
                    ...params,
                    topN: e.target.value,
                  });
                }}
                sx={{
                  width: "100%",
                  "#table": { display: "flex" },
                  ":has(div)": { color: "#8c8c8c", alignItems: "end" },
                }}>
                <MenuItem value={0} disabled>
                  <Typography variant='spec_body'>{`Select ${
                    BAR_CHART === chart?.params?.id ? "Bars" : "Columns"
                  }`}</Typography>
                </MenuItem>
                {TOP_N_ARR.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.id}
                    sx={{ display: "flex", alignItems: "end" }}>
                    <Typography mr={1} color='text.primary' variant='spec_body'>
                      {item.label}
                    </Typography>
                  </MenuItem>
                ))}
              </MuiSelectField>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default ConfigureChart;
