import React, { useEffect, useState } from "react";

import {
  ControlCameraOutlined,
  DeleteOutline,
  BorderColorOutlined,
  EditOffOutlined,
  ContentCopy,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  InputLabel,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MainCard from "../MainCard";
import { FILTER } from "../../constants";
import { Filter } from "../Filters";
import { MuiSelectField } from "../../common/style";
import CustomPopover from "../../common/CustomPopover";
import * as CustomMenuItem from "../../common/MenuItem";

export const FilterBlock = ({
  index,
  element,
  handleDelete,
  onChange,
  isPreview = false,
  filterSettings,
  handleEdit,
  filterEditIndex,
  setOpenSideBar,
  handlePaste = () => {},
}) => {
  const [searchTermID, setSearchTermID] = useState(
    element?.params?.searchTermID
  );
  // const [searchTermName, setSearchTermName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  // const [options, setOptions] = useState([]);
  const [activeOptions, setActiveOptions] = useState(new Map());
  const [getConfigure, setGetConfigure] = useState({});

  const isSelected = index === filterEditIndex;

  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  useEffect(() => {
    setGetConfigure({
      filterName: element?.params?.filterByTerm ?? "",
      filterValue: element?.params?.options ?? [],
    });
  }, [filterSettings]);

  // useEffect(() => {
  //   const data = new Set(
  //     mockDataset
  //       ?.map((tab) => tab.table.rows.map((row) => row[searchTermID]))
  //       .flat()
  //   );
  //   setOptions([...data].map((item) => ({ id: item, label: item })));
  //   // searchTermID !== element?.params?.searchTermID &&
  //   // onChange(
  //   //   {
  //   //     ...element,
  //   //     params: {
  //   //       searchTermID,
  //   //       activeOptions,
  //   //       options: getConfigure.filterValue,
  //   //       filterByTerm: getConfigure.filterName,
  //   //     },
  //   //   },
  //   //   element?.params?.dimension?.i
  //   // );
  // }, [mockDataset, getConfigure]);

  useEffect(() => {
    // element?.params?.activeOptions?.length &&
    setActiveOptions(
      element?.params?.activeOptions?.length
        ? new Map(element?.params?.activeOptions?.map((item) => [item, item]))
        : new Map()
    );
  }, [element?.params?.activeOptions]);

  return (
    <>
      <MainCard
        border={!isSelected}
        cardSelected={isSelected}
        divider={false}
        content={false}
        sx={{ height: "100%", width: "100%" }}
        type={"filters"}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          p={0.5}>
          <Stack
            direction='row'
            justifyContent='flex-start'
            alignItems='center'>
            <Typography
              variant='spec_h3'
              sx={{ paddingLeft: "10px", paddingRight: "8px" }}>
              Filter by {getConfigure.filterName}
            </Typography>
          </Stack>

          <Box>
            {!isPreview && (
              <>
                <Tooltip
                  title={<Typography variant='spec_tooltip'>Drag</Typography>}
                  arrow>
                  <IconButton>
                    <ControlCameraOutlined
                      fontSize='small'
                      style={{ color: "#424242" }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    <Typography variant='spec_tooltip'>Duplicate</Typography>
                  }
                  arrow>
                  <IconButton
                    onClick={(e) => {
                      handlePaste(element?.mockDataId, "filter");
                    }}>
                    <ContentCopy
                      fontSize='small'
                      style={{ color: "#424242" }}
                      className='handleDraggableElement'
                    />
                  </IconButton>
                </Tooltip>
              </>
            )}

            <Tooltip
              title={
                <Typography variant='spec_tooltip'>
                  {isSelected
                    ? "Close Configuration"
                    : isPreview
                    ? "View Configuration"
                    : "Configure"}
                </Typography>
              }
              arrow>
              <IconButton
                // onClick={() => setOpenModal(true)}
                onClick={(e) => {
                  e.preventDefault();
                  handleEdit(index);
                  // openModal(index);
                  if (!isSelected) {
                    setOpenSideBar();
                  }
                }}>
                {isSelected ? (
                  !isPreview ? (
                    <EditOffOutlined
                      fontSize='small'
                      style={{ color: "#424242" }}
                    />
                  ) : (
                    <VisibilityOff
                      fontSize='small'
                      style={{ color: "#424242" }}
                    />
                  )
                ) : !isPreview ? (
                  <BorderColorOutlined
                    fontSize='small'
                    style={{ color: "#424242" }}
                  />
                ) : (
                  <Visibility fontSize='small' style={{ color: "#424242" }} />
                )}
              </IconButton>
            </Tooltip>
            {!isPreview && (
              <Tooltip
                title={<Typography variant='spec_tooltip'>Delete</Typography>}
                arrow>
                <IconButton
                  onClick={() => handleDelete(element?.mockDataId, index)}
                  className='cancelDraggableElement'>
                  <DeleteOutline
                    fontSize='small'
                    style={{ color: "#424242" }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Stack>
        <Divider />
        <Box m={2.5} className={"cancelDraggableElement"}>
          {!element?.params?.isRange &&
            (!isPreview ? (
              <Filter
                // disabled={searchTermID !== "0" ? false : true}
                title={`${getConfigure.filterName || "Filter"}`}
                activeOptions={activeOptions ?? []}
                options={
                  getConfigure?.filterValue?.map((value) => {
                    return {
                      id: value,
                      label: value,
                    };
                  }) || []
                }
                onOptionClick={(option) => {
                  const active = activeOptions;
                  if (active.has(option.id)) active.delete(option.id);
                  else active.set(option.id, option.label);
                  setActiveOptions(new Map(active));
                  onChange(
                    {
                      type: FILTER,
                      params: {
                        ...element?.params,
                        options: getConfigure.filterValue,
                        // searchTermID,
                        activeOptions: [
                          ...new Set([...new Map(active)].flat()),
                        ],
                        filterByTerm: getConfigure.filterName,
                      },
                    },
                    element?.params?.dimension?.i
                  );
                }}
                selectAll={true}
                onSelectAll={(selectAll) => {
                  if (selectAll) {
                    setActiveOptions(
                      new Map(
                        getConfigure?.filterValue.map((item) => [item, item])
                      )
                    );
                    onChange(
                      {
                        type: FILTER,
                        params: {
                          // searchTermID,
                          ...element?.params,
                          options: getConfigure.filterValue,
                          filterByTerm: getConfigure.filterName,
                          activeOptions: [
                            ...new Set(
                              [
                                ...new Map(
                                  new Map(
                                    getConfigure?.filterValue.map((item) => [
                                      item,
                                      item,
                                    ])
                                  )
                                ),
                              ].flat()
                            ),
                          ],
                        },
                      },
                      element?.params?.dimension?.i
                    );
                  } else {
                    setActiveOptions(new Map());
                    onChange(
                      {
                        type: FILTER,
                        params: {
                          ...element?.params,
                          searchTermID,
                          activeOptions: [],
                        },
                      },
                      element?.params?.dimension?.i
                    );
                  }
                }}
              />
            ) : (
              <Typography variant='spec_body' gutterBottom>
                {element?.params?.activeOptions?.length > 0
                  ? element?.params?.activeOptions
                      ?.toString()
                      ?.replace(/,/g, ", ")
                  : "No filter applied!"}
              </Typography>
            ))}

          {element?.params?.isRange && (
            <Stack direction={"row"} columnGap={2}>
              <Stack
                direction='column'
                spacing={2}
                alignItems='left'
                sx={{ width: "100%" }}>
                <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
                  <InputLabel>
                    <Typography variant='spec_inputLabel'>From</Typography>
                  </InputLabel>
                  <MuiSelectField
                    className='cancelDraggableElement'
                    disabled={isPreview}
                    id='table'
                    value={element?.params?.from || ""}
                    onChange={(e) => {
                      onChange(
                        {
                          ...element,
                          params: {
                            ...element.params,
                            from: e.target.value,
                          },
                        },
                        element?.params?.dimension?.i
                      );
                    }}
                    sx={{
                      width: "100%",
                      "#table": { display: "flex" },
                      ":has(div)": { color: "#8c8c8c", alignItems: "end" },
                    }}>
                    <MenuItem
                      value={""}
                      disabled
                      className='cancelDraggableElement'>
                      <Typography variant='spec_body'>Select From</Typography>
                    </MenuItem>
                    {element?.params?.rangeOptions?.map((item) => (
                      <MenuItem
                        key={item}
                        value={item}
                        className='cancelDraggableElement'>
                        <Typography variant='spec_body'>{item}</Typography>
                      </MenuItem>
                    ))}
                  </MuiSelectField>
                </Stack>
              </Stack>
              <Stack
                direction='column'
                spacing={2}
                alignItems='left'
                sx={{ width: "100%" }}>
                <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
                  <InputLabel>
                    <Typography variant='spec_inputLabel'>To</Typography>
                  </InputLabel>
                  <MuiSelectField
                    disabled={isPreview}
                    id='table'
                    className='cancelDraggableElement'
                    value={element?.params?.to || ""}
                    onChange={(e) => {
                      onChange(
                        {
                          ...element,
                          params: {
                            ...element.params,
                            to: e.target.value,
                          },
                        },
                        element?.params?.dimension?.i
                      );
                    }}
                    sx={{
                      width: "100%",
                      "#table": { display: "flex" },
                      ":has(div)": { color: "#8c8c8c", alignItems: "end" },
                    }}>
                    <MenuItem
                      value={""}
                      disabled
                      className='cancelDraggableElement'>
                      <Typography variant='spec_body'>Select To</Typography>
                    </MenuItem>
                    {element?.params?.rangeOptions?.map((item) => (
                      <MenuItem
                        key={item}
                        value={item}
                        className='cancelDraggableElement'>
                        <Typography variant='spec_body'>{item}</Typography>
                      </MenuItem>
                    ))}
                  </MuiSelectField>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Box>
      </MainCard>
      <CustomPopover
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }>
        <CustomMenuItem
          Icon={<ContentCopy fontSize='small' />}
          itemText={"Copy"}
          itemProps={{ height: "15px" }}
          onClick={() => {
            handleClose();
            handlePaste(element?.mockDataId, "filter");
          }}
        />
      </CustomPopover>
    </>
  );
};
