import { Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const MockupCountCell = (props) => {
  const navigate = useNavigate();

  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      width={1}
      onClick={() => navigate(`/specs?business_term_id=${props?.data?.id}`)}
      sx={{ cursor: "pointer" }}>
      <Typography color='secondary.main'>{props?.value}</Typography>
    </Stack>
  );
};

export default MockupCountCell;
