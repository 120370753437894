import {
  CircularProgress,
  Divider,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { ExpandMoreRoundedIcon } from "../../assets";
import CustomPopover from "../../common/CustomPopover";
import MenuItem from "../../common/MenuItem";
import {
  PERMISSIONS,
  PERMISSIONS_ARR,
  PERMISSIONS_LABEL,
} from "../../constants";
import { useParams } from "react-router-dom";
import { HTTP_OK } from "../../constants/statusCodes";
import { defaultMessage } from "../../constants/messages";
import { toaster } from "../../common/Toaster";
import { removeSpecAccess, transferAuthorship } from "../../services";
import { getSpecDataAsync } from "../../store/slices/userSpec";
import { useDispatch } from "react-redux";
import BaseModal from "../../common/BaseModal";

const RoleDropdown = ({
  role,
  onRoleChange = () => {},
  roleSx,
  setSpecAccesses,
  specAccesses,
  setOldSpecAccesses,
  oldSpecAccesses,
  access = {},
  allowDelete = false,
  disableRoleChange,
  isOwner = false,
}) => {
  const theme = useTheme();
  const { specId } = useParams();
  const dispatch = useDispatch();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [transferLoading, setTransferLoading] = useState(false);
  const [currentRole, setCurrentRole] = useState(role || PERMISSIONS.EDITOR);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElConfirmation, setAnchorElConfirmation] = useState(null);

  const open = Boolean(anchorEl);
  const openConfirmation = Boolean(anchorElConfirmation);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClickConfirmation = (e) => {
    handleClose();
    setAnchorElConfirmation(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseConfirmation = () => {
    setAnchorElConfirmation(null);
  };

  const handleRoleChange = (role) => (e) => {
    setCurrentRole(role);
    onRoleChange(role);
    handleClose();
  };

  const handleRemoveSpecAccess = async () => {
    const accessId = access?.id;
    const removeAccessFromState = () => {
      setSpecAccesses(
        specAccesses?.filter((access) => access?.id !== accessId)
      );
      setOldSpecAccesses(
        oldSpecAccesses?.filter((access) => access?.id !== accessId)
      );
    };

    if (access?.__isNew__ || access?.__isExisting__) {
      removeAccessFromState();
      handleClose();
    } else {
      try {
        setDeleteLoading(true);
        const response = await removeSpecAccess(specId, accessId);
        setDeleteLoading(false);

        if (response?.status !== HTTP_OK) {
          throw new Error(defaultMessage);
        }

        removeAccessFromState();
        handleClose();

        toaster({
          type: "success",
          message:
            response?.data?.toaster?.data?.message ??
            "Access was successfully deleted.",
        });
      } catch (error) {
        setDeleteLoading(false);
        toaster({
          type: "error",
          message: "Error while removing specification access for given user!",
        });
      }
    }
  };

  const handleTransferAuthorship = async () => {
    const specAccessId = access?.id;
    try {
      setTransferLoading(true);
      const response = await transferAuthorship({ specId, specAccessId });
      setTransferLoading(false);

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }

      dispatch(getSpecDataAsync({ specId }));

      toaster({
        type: "success",
        message:
          response?.data?.toaster?.data?.message ??
          "Authorship was successfully transferred.",
      });
    } catch (error) {
      setTransferLoading(false);
      toaster({
        type: "error",
        message: "Error while transferring authorship!",
      });
    }
  };

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        sx={{
          cursor: disableRoleChange ? "default" : "pointer",
          opacity: disableRoleChange ? 0.5 : 1,
          ...roleSx,
        }}
        onClick={disableRoleChange ? () => {} : handleClick}>
        <Typography variant='spec_caption' sx={{ textTransform: "capitalize" }}>
          {PERMISSIONS_LABEL[currentRole]}
        </Typography>
        <ExpandMoreRoundedIcon />
      </Stack>
      <CustomPopover
        anchorEl={anchorEl}
        open={open}
        onClose={deleteLoading ? () => {} : handleClose}>
        <Stack rowGap={1}>
          {PERMISSIONS_ARR.map(
            (perm) =>
              perm !== PERMISSIONS.ADMIN && (
                <MenuItem
                  key={perm}
                  itemText={PERMISSIONS_LABEL[perm]}
                  itemProps={{
                    sx: {
                      height: "12px",
                      backgroundColor:
                        perm === currentRole
                          ? alpha(theme.palette.primary.main, 0.3)
                          : "",
                    },
                  }}
                  onClick={deleteLoading ? () => {} : handleRoleChange(perm)}
                />
              )
          )}
        </Stack>
        {allowDelete && (
          <Stack>
            <Divider
              sx={{
                my: 1,
              }}
            />
            <MenuItem
              Icon={deleteLoading && <CircularProgress size={20} />}
              onClick={deleteLoading ? () => {} : handleRemoveSpecAccess}
              itemText='Delete Permissions'
              itemProps={{
                sx: {
                  height: "12px",
                },
              }}
            />
            {!isOwner && !(access?.__isNew__ || access?.__isExisting__) && (
              <MenuItem
                onClick={handleClickConfirmation}
                itemText='Transfer Authorship'
                itemProps={{
                  sx: {
                    height: "12px",
                  },
                }}
              />
            )}
          </Stack>
        )}
      </CustomPopover>
      <BaseModal
        dialogWidth={450}
        title='Confirm Authorship Transfer'
        isOpen={openConfirmation}
        confirmTitle='Transfer'
        handleClose={handleCloseConfirmation}
        isLoading={transferLoading}
        handleSubmit={handleTransferAuthorship}>
        <Typography variant='spec_body'>
          Are you sure you want to transfer authorship to this user?
        </Typography>
      </BaseModal>
    </>
  );
};

export default RoleDropdown;
