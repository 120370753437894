import axiosInstance from "../axios";
import { defaultExceptionHandler } from "../utils/defaultExceptionHandler";
import {
  acceptInvitationTokenUrl,
  announcementsUrl,
  askToInviteUrl,
  autocompleteSuggestionsUrl,
  businessTermCategoriesUrl,
  businessTermUrl,
  createSpecCopyUrl,
  dataScopesUrls,
  deactivateUrl,
  generateDataUrl,
  getAutocompleteUsersUrl,
  getCompanyUsersUrl,
  getDefaultDataChartsUrl,
  getFunctionsUrl,
  getIndustriesUrl,
  getLabelUrl,
  getPrioritiesUrl,
  getSpecAutoCompleteUserUrl,
  getSpecDataUrl,
  getSpecHistoryUrl,
  getStatusesUrl,
  getTemplatesUrl,
  inviteUserUrl,
  invoicesUrl,
  mappingColumnsUrl,
  passwordsUrl,
  resetPasswordUrl,
  specDatasetUrl,
  specFunctionsUrl,
  specIndexPreferenceUrl,
  specStatusesUrl,
  specVisualisationUrl,
  specificationsUrl,
  templatesUrl,
  updateSpecificationUrl,
  usersUrl,
  zendeskJwtUrl,
} from "../utils/urls";

export const getSpecDataset = async (specId) => {
  const response = await axiosInstance
    .get(
      `${specDatasetUrl({
        id: specId,
      })}`
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getSpecHistory = async (params) => {
  const response = await axiosInstance
    .get(
      `${getSpecHistoryUrl({
        id: params?.specId,
      })}?date_from=${params?.date_from}&date_to=${params?.date_to}&event=${
        params?.event ?? ""
      }&user_ids=${params?.user_ids ?? ""}&record_type=${
        params?.record_type ?? ""
      }`
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getSpecAutoCompleteUser = async (params) => {
  const response = await axiosInstance
    .get(
      `${getSpecAutoCompleteUserUrl({
        id: params?.specId,
      })}`
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getTemplates = async (params) => {
  const response = await axiosInstance
    .get(
      `${getTemplatesUrl}?&function_ids=${
        params?.functionIds ?? ""
      }&industry_ids=${params?.industryIds ?? ""}`
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const updateSpecification = async (params) => {
  const response = await axiosInstance
    .patch(`${updateSpecificationUrl}/${params.id}`, params.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getSpecVisualization = async (specId) => {
  const response = await axiosInstance
    .get(
      `${specVisualisationUrl({
        id: specId,
      })}`
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getSpecData = async (specId) => {
  const response = await axiosInstance
    .get(
      `${getSpecDataUrl({
        id: specId,
      })}`
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getStatuses = async () => {
  const response = await axiosInstance.get(getStatusesUrl).catch((error) => {
    throw defaultExceptionHandler(error);
  });

  return response;
};

export const getPriorities = async () => {
  const response = await axiosInstance.get(getPrioritiesUrl).catch((error) => {
    throw defaultExceptionHandler(error);
  });

  return response;
};

export const getFunctions = async (params) => {
  const response = await axiosInstance
    .get(
      `${getFunctionsUrl}${
        params?.admin ? `?admin=${params?.admin ?? false}:` : ""
      }`
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getIndustries = async () => {
  const response = await axiosInstance.get(getIndustriesUrl).catch((error) => {
    throw defaultExceptionHandler(error);
  });

  return response;
};

export const createSpecCopy = async (params) => {
  const response = await axiosInstance
    .post(createSpecCopyUrl, params.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const deleteSpec = async (specId) => {
  const response = await axiosInstance
    .delete(`${specificationsUrl}/${specId}`)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getCompanyUsers = async () => {
  const response = await axiosInstance
    .get(getCompanyUsersUrl)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getAutocompleteUsers = async ({ query, allUsers }) => {
  const response = await axiosInstance
    .get(`${getAutocompleteUsersUrl}?query=${query}&all_user=${allUsers}`)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getSpecAccesses = async (specId) => {
  const response = await axiosInstance
    .get(`${specificationsUrl}/${specId}/spec_accesses`)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const askToInvite = async (payload) => {
  const response = await axiosInstance
    .post(askToInviteUrl, payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const updateShareList = async (specId, payload) => {
  const response = await axiosInstance
    .patch(
      `${updateSpecificationUrl}/${specId}/spec_accesses/update_share_list`,
      payload
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const removeSpecAccess = async (specId, accessId) => {
  const response = await axiosInstance
    .delete(`${updateSpecificationUrl}/${specId}/spec_accesses/${accessId}`)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getSpecMockDatasetTabs = async (specId) => {
  const response = await axiosInstance
    .get(`${specificationsUrl}/${specId}/spec_mockdata_tabs`)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getSelectedMockDatasetTab = async (params) => {
  const response = await axiosInstance
    .get(
      `${specificationsUrl}/${params?.specId}/spec_mockdata_tabs/${params?.tabId}`
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getDefaultDataCharts = async (specId, payload) => {
  const response = await axiosInstance
    .post(`${getDefaultDataChartsUrl({ id: specId })}`, payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const createNewTab = async (params) => {
  const response = await axiosInstance
    .post(
      `${specificationsUrl}/${params?.specId}/spec_mockdata_tabs`,
      params?.payload
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const updateTab = async (params) => {
  const response = await axiosInstance
    .put(
      `${specificationsUrl}/${params?.specId}/spec_mockdata_tabs/${params?.tabId}`,
      params?.payload
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const deleteTab = async (params) => {
  const response = await axiosInstance
    .delete(
      `${specificationsUrl}/${params?.specId}/spec_mockdata_tabs/${params?.tabId}`
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const autoCompleteSuggestions = async (params) => {
  const response = await axiosInstance
    .get(
      `${autocompleteSuggestionsUrl}?query=${
        params?.query
      }&spec_mockdata_tab_id=${
        params?.tabId ? params?.tabId : ""
      }&specification_id=${params?.specId ? params?.specId : ""}`,
      {
        params: {
          state: params?.state ? params?.state : "",
        },
      }
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const updateCell = async (params) => {
  const response = await axiosInstance
    .patch(
      `${specificationsUrl}/${params?.specId}/spec_mockdata_tabs/${params?.tabId}/update_cell`,
      params.payload
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const pasteLabel = async (params) => {
  const response = await axiosInstance
    .post(
      `${specificationsUrl}/${params?.specId}/spec_mockdata_tabs/${params?.tabId}/copy_term`,
      params.payload
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getLabel = async (params) => {
  const response = await axiosInstance
    .get(`${getLabelUrl}/${params?.labelId}`)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const generateData = async (params) => {
  const response = await axiosInstance
    .post(generateDataUrl, params.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const addLabel = async (params) => {
  const response = await axiosInstance
    .post(
      `${specificationsUrl}/${params?.specId}/spec_mockdata_tabs/${params?.tabId}/add_term`,
      params.payload
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const updateLabel = async (params) => {
  const response = await axiosInstance
    .patch(
      `${specificationsUrl}/${params?.specId}/spec_mockdata_tabs/${params?.tabId}/update_term`,
      params.payload
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const createSpecification = async (params) => {
  const response = await axiosInstance
    .post(specificationsUrl, params.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const bulkDestroySpecs = async (params) => {
  const response = await axiosInstance
    .post(`${specificationsUrl}/bulk_destroy`, params?.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getSpecIndexPreference = async () => {
  const response = await axiosInstance
    .get(specIndexPreferenceUrl)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const updateSpecIndexPreference = async (params) => {
  const response = await axiosInstance
    .patch(specIndexPreferenceUrl, params?.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getSpecStatuses = async () => {
  const response = await axiosInstance.get(specStatusesUrl).catch((error) => {
    throw defaultExceptionHandler(error);
  });

  const options = response?.data?.specStatuses?.map((status) => {
    return { value: status?.id, label: status?.name };
  });
  return options;
};

export const getSpecPriorities = async () => {
  const response = await axiosInstance.get(getPrioritiesUrl).catch((error) => {
    throw defaultExceptionHandler(error);
  });

  const options = response?.data?.priorities?.map((priority) => {
    return { value: priority?.id, label: priority?.name };
  });
  return options;
};

export const getSpecFunctions = async () => {
  const response = await axiosInstance.get(specFunctionsUrl).catch((error) => {
    throw defaultExceptionHandler(error);
  });

  const options = response?.data?.specFunctions?.map((func) => {
    return { value: func?.id, label: func?.tag };
  });
  return options;
};

export const getBusinessTermCategoriesOptions = async () => {
  const response = await axiosInstance
    .get(businessTermCategoriesUrl)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  const options = response?.data?.categories?.map((category) => {
    return { value: category?.id, label: category?.name };
  });
  return options;
};

export const getCompanyUsersOptions = async () => {
  const response = await axiosInstance
    .get(getCompanyUsersUrl)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  const options = response?.data?.users?.reduce((filtered, user) => {
    if (user?.firstName && user?.lastName) {
      filtered?.push({
        value: user?.id,
        label: `${user?.firstName} ${user?.lastName}`,
      });
    }

    return filtered;
  }, []);

  return options;
};

export const getBusinessTerm = async (params) => {
  const response = await axiosInstance
    .get(`${getLabelUrl}?state=${params?.payload?.status ?? "exists"}`, {
      params: {
        datatype: params?.payload?.dataType,
        proposed_by_ids: params?.payload?.proposedBy,
        categories_ids: params?.payload?.businessTermCategory,
      },
    })
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getSpecBusinessTerm = async (params) => {
  const response = await axiosInstance
    .get(`${specificationsUrl}/${params?.specId}/business_terms`)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getMappingColumns = async () => {
  const response = await axiosInstance.get(mappingColumnsUrl).catch((error) => {
    throw defaultExceptionHandler(error);
  });

  return response;
};

export const addBusinessTerm = async (params) => {
  const response = await axiosInstance
    .post(businessTermUrl, params?.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const editBusinessTerm = async (params) => {
  const response = await axiosInstance
    .patch(`${businessTermUrl}/${params?.id}`, params?.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const deleteBusinessTerm = async (params) => {
  const response = await axiosInstance
    .delete(`${businessTermUrl}/${params?.id}`)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getspecDataScopes = async (params) => {
  const response = await axiosInstance
    .get(`${specificationsUrl}/${params?.specId}/data_scopes`)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getDataScopes = async () => {
  const response = await axiosInstance.get(dataScopesUrls).catch((error) => {
    throw defaultExceptionHandler(error);
  });

  return response;
};

export const getCompanyUsersEmailOrName = async () => {
  const response = await axiosInstance
    .get(getCompanyUsersUrl)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  const options = response?.data?.users?.map((user) => {
    return {
      value: user?.id,
      label:
        user?.firstName && user?.lastName
          ? `${user.firstName} ${user.lastName}`
          : user?.email,
    };
  });

  return options;
};

// STATUS SERVICES
export const addStatuses = async (params) => {
  const response = await axiosInstance
    .post(getStatusesUrl, params.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};
// STATUS SERVICES
export const addPriority = async (params) => {
  const response = await axiosInstance
    .post(getPrioritiesUrl, params.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const deleteStatus = async (params) => {
  const response = await axiosInstance
    .delete(`${getStatusesUrl}/${params?.statusId}`)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const deletePriority = async (params) => {
  const response = await axiosInstance
    .delete(`${getPrioritiesUrl}/${params?.priorityId}`)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const updateStatus = async (params) => {
  const response = await axiosInstance
    .patch(`${getStatusesUrl}/${params?.statusId}`, params?.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const updatePriority = async (params) => {
  const response = await axiosInstance
    .patch(`${getPrioritiesUrl}/${params?.priorityId}`, params?.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

// FUNCTION SERVICES

export const addFunction = async (params) => {
  const response = await axiosInstance
    .post(getFunctionsUrl, params.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const deleteFunction = async (params) => {
  const response = await axiosInstance
    .delete(`${getFunctionsUrl}/${params?.functionId}`)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const updateFunction = async (params) => {
  const response = await axiosInstance
    .patch(`${getFunctionsUrl}/${params?.functionId}`, params?.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

// INDUSTRIES SERVICES

export const addIndustry = async (params) => {
  const response = await axiosInstance
    .post(getIndustriesUrl, params.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const deleteIndustry = async (params) => {
  const response = await axiosInstance
    .delete(`${getIndustriesUrl}/${params?.industryId}`)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const updateIndustry = async (params) => {
  const response = await axiosInstance
    .patch(`${getIndustriesUrl}/${params?.industryId}`, params?.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getDataScopeCategories = async () => {
  const response = await axiosInstance
    .get(`${dataScopesUrls}/autocomplete_categories?query=`)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  const categoriesOptions = response?.data?.categories?.map((category) => {
    return { value: category, label: category };
  });

  return categoriesOptions;
};

export const addDataScope = async (params) => {
  const response = await axiosInstance
    .post(dataScopesUrls, params?.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const deleteDataScope = async (params) => {
  const response = await axiosInstance
    .delete(`${dataScopesUrls}/${params?.dataScopeId}`)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const updateDataScope = async (params) => {
  const response = await axiosInstance
    .patch(`${dataScopesUrls}/${params?.dataScopeId}`, params?.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const deactivateCompany = async () => {
  const response = await axiosInstance.delete(deactivateUrl).catch((error) => {
    throw defaultExceptionHandler(error);
  });

  return response;
};

export const getInvoices = async () => {
  const response = await axiosInstance.get(invoicesUrl).catch((error) => {
    throw defaultExceptionHandler(error);
  });

  return response;
};

export const updatePosition = async (params) => {
  const response = await axiosInstance
    .patch(
      `${mappingColumnsUrl}/${params.rowId}/update_position`,
      params?.payload
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const deleteMappingsColumn = async (params) => {
  const response = await axiosInstance
    .delete(`${mappingColumnsUrl}/${params?.rowId}`)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const createMappingsColumn = async (params) => {
  const response = await axiosInstance
    .post(mappingColumnsUrl, params?.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const editMappingsColumn = async (params) => {
  const response = await axiosInstance
    .patch(`${mappingColumnsUrl}/${params?.id}`, params?.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const editMappingsDefaultColumn = async (params) => {
  const response = await axiosInstance
    .patch(`${businessTermCategoriesUrl}/bulk_update`, params?.payload)
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const getBottomComments = async (params) => {
  const response = await axiosInstance
    .get(
      `${specificationsUrl}/${params?.specId}/comments?comment_type=${params?.comment_type}`
    )
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const addComment = async (params) => {
  const response = await axiosInstance
    .post(`${specificationsUrl}/${params?.specId}/comments`, params?.payload)
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const updComment = async (params) => {
  const response = await axiosInstance
    .patch(
      `${specificationsUrl}/${params?.specId}/comments/${params?.commentId}`,
      params?.payload
    )
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const deleteComment = async (params) => {
  const response = await axiosInstance
    .delete(
      `${specificationsUrl}/${params?.specId}/comments/${params?.commentId}`
    )
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const getAutocompleteUsersComments = async (params) => {
  const response = await axiosInstance
    .get(
      `${getAutocompleteUsersUrl}?query=${params?.query}&specification_id=${params?.specId}`
    )
    .catch((error) => {
      throw defaultExceptionHandler(error);
    });

  return response;
};

export const createNewPassword = async (params) => {
  const response = await axiosInstance
    .patch(
      `${getCompanyUsersUrl}/${params?.userId}/change_password`,
      params?.payload
    )
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const resetPasswordRequest = async (params) => {
  const response = await axiosInstance
    .post(resetPasswordUrl, params.payload)
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const deleteUser = async (params) => {
  const response = await axiosInstance
    .delete(`${usersUrl}/${params?.userId}`)
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const updateUser = async (params) => {
  const response = await axiosInstance
    .patch(`${usersUrl}/${params?.userId}`, params?.payload)
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const getUser = async (params) => {
  const response = await axiosInstance
    .get(`${usersUrl}/${params?.userId}`)
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const acceptInvitation = async (params) => {
  const response = await axiosInstance
    .patch(
      `${acceptInvitationTokenUrl}?invitation_token=${params?.token}`,
      params?.payload
    )
    .catch((e) => {
      // console.log("ew", e);
      throw e;
    });

  return response;
};

export const getInvitedUserData = async (params) => {
  const response = await axiosInstance
    .get(`${inviteUserUrl}/${params?.token}`)
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const createNewPasswordRequest = async (params) => {
  const response = await axiosInstance
    .post(passwordsUrl, params.payload)
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const getZendeskJwt = async () => {
  const response = await axiosInstance.get(zendeskJwtUrl, {}).catch((e) => {
    throw defaultExceptionHandler(e);
  });

  return response;
};

export const publishTemplate = async (params) => {
  const response = await axiosInstance
    .post(`${specificationsUrl}/${params?.specId}/publish_as_template`)
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const unPublishTemplate = async (params) => {
  const response = await axiosInstance
    .delete(`${templatesUrl}/${params?.templateId}`)
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const getAnnouncements = async () => {
  const response = await axiosInstance.get(announcementsUrl).catch((e) => {
    throw defaultExceptionHandler(e);
  });

  return response;
};

export const transferOwnership = async (params) => {
  const response = await axiosInstance
    .patch(`${usersUrl}/${params?.userId}/transfer_ownership`, params?.payload)
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const copyPasteVisual = async (params) => {
  const response = await axiosInstance
    .post(
      `${specificationsUrl}/${params?.specId}/spec_mockdata_tabs/${params?.tabId}/copy_visual_table`,
      params?.payload
    )
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const transferAuthorship = async (params) => {
  const response = await axiosInstance
    .patch(
      `${specificationsUrl}/${params?.specId}/spec_accesses/transfer_authorship`,
      {
        spec_access_id: params?.specAccessId,
      }
    )
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const resetTerm = async (params) => {
  const response = await axiosInstance
    .patch(
      `${specificationsUrl}/${params?.specId}/spec_mockdata_tabs/${params?.tabId}/reset_term`,
      params?.payload
    )
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const addSpecKpis = async (params) => {
  const response = await axiosInstance
    .post(`${specificationsUrl}/${params?.specId}/spec_kpis`, params?.payload)
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const updateSpeckpi = async (params) => {
  const response = await axiosInstance
    .patch(
      `${specificationsUrl}/${params?.specId}/spec_kpis/${params?.id}`,
      params?.payload
    )
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};

export const deleteSpecKpi = async (params) => {
  const response = await axiosInstance
    .delete(`${specificationsUrl}/${params?.specId}/spec_kpis/${params?.id}`)
    .catch((e) => {
      throw defaultExceptionHandler(e);
    });

  return response;
};
