import { Popover, styled as MuiStyled } from "@mui/material";

export const StyledPopover = MuiStyled((props) => (
  <Popover
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme, paperprops }) => ({
  "& .MuiPopover-root": {
    // popover root styling goes here
  },
  "& .MuiPopover-paper": {
    minWidth: "200px",
    maxWidth: "290px",
    borderRadius: "8px",
    marginTop: theme.spacing(1),
    boxShadow: theme.palette.shadows.shadow1,
    padding: "10px",
    ...paperprops,
  },
}));
