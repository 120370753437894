import { useParams } from "react-router-dom";
import { Button, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AgGridComponent from "../../common/AgGridComponent";
import React, { useEffect, useMemo, useState } from "react";
import CustomHeaderCell from "../../common/CustomHeaderCell";
import KPIAndBIAddEditModal from "./KPIAndBIAddEditModal";
import { AddOutlined } from "@mui/icons-material";
import { setIsKpiBiModalOpen } from "../../store/slices/userSpec";
import { getSpecKpiRows } from "./helperFunctions";
import { getSpecKpiColumns } from "./columns";

const KPIAndBI = ({ isPreview = false }) => {
  const { specId } = useParams();
  const dispatch = useDispatch();
  const { specData, isKpiBiModalOpen } = useSelector((state) => state.spec);

  const [rowData, setRowData] = useState([]);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([]);
  // CUSTOM HEADER
  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeaderCell,
    };
  }, []);

  const handleAddKPI = () => {
    dispatch(
      setIsKpiBiModalOpen({
        kpiBiModalObj: {
          type: "ADD",
          data: null,
        },
      })
    );
  };

  useEffect(() => {
    setColDefs(getSpecKpiColumns(isPreview));
    setRowData(getSpecKpiRows(specData?.specKpis, isPreview));
  }, [specData]);

  return (
    <>
      <Stack
        rowGap={4}
        sx={{
          display: !rowData?.length > 0 && isPreview ? "none" : "flex",
        }}>
        <Stack direction={"row"} alignItems={"center"}>
          <Typography variant='spec_h3'>KPIs & Business Impact</Typography>
        </Stack>

        {rowData.length > 0 && (
          <Stack
            ml={3}
            sx={(theme) => ({
              border: `1px solid ${theme.palette.gray.borderGray}`,
              borderRadius: "8px",
              overflow: "hidden",
            })}>
            <AgGridComponent
              data={rowData}
              columnDefs={colDefs}
              components={components}
              autoSizeStrategy={"fitGridWidth"}
              pagination={false}
              domLayout={"autoHeight"}
            />
          </Stack>
        )}
      </Stack>
      {!isPreview && (
        <Button
          startIcon={<AddOutlined />}
          variant='outlined'
          color='secondary'
          onClick={handleAddKPI}
          sx={{ width: { xs: "auto", sm: "300px" }, ml: 3 }}>
          Add KPI
        </Button>
      )}

      {isKpiBiModalOpen && <KPIAndBIAddEditModal />}
    </>
  );
};

export default KPIAndBI;
