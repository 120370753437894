import { MOCK_DATA_LENGTH } from "../constants";

export const reorderArray = (arr, idxs, to) => {
  const movedElements = arr.filter((_, idx) => idxs.includes(idx));
  const targetIdx =
    Math.min(...idxs) < to
      ? (to += 1)
      : (to -= idxs.filter((idx) => idx < to).length);
  const leftSide = arr.filter(
    (_, idx) => idx < targetIdx && !idxs.includes(idx)
  );
  const rightSide = arr.filter(
    (_, idx) => idx >= targetIdx && !idxs.includes(idx)
  );
  return [...leftSide, ...movedElements, ...rightSide];
};

export const createEmptyCopy = (originalObject) => {
  const emptyCopy = {};

  for (let prop in originalObject) {
    // eslint-disable-next-line no-prototype-builtins
    if (originalObject.hasOwnProperty(prop)) {
      emptyCopy[prop] =
        typeof originalObject[prop] === "object"
          ? createEmptyCopy(originalObject[prop])
          : "";
    }
  }

  return emptyCopy;
};

export const createArrayWithFixedLength = (arr, length = MOCK_DATA_LENGTH) => {
  const fixedLength = length;
  const newArray = [];

  for (let i = 0; i < fixedLength; i++) {
    newArray.push(arr[i % arr.length]);
  }
  return newArray;
};

export const convertValuesToString = (arrayOfObjects) => {
  for (const obj of arrayOfObjects) {
    for (const key in obj) {
      if (obj[key] === null) {
        obj[key] = "";
      } else if (["number", "boolean"].includes(typeof obj[key])) {
        obj[key] = obj[key].toString();
      }
    }
  }
  return arrayOfObjects;
};

export const capitalizeFirstLetter = (word) =>
  word?.length ? word.charAt(0).toUpperCase() + word.slice(1) : word;
export const cleanSpaces = (inputString) =>
  (typeof inputString === "string" ? inputString : "").replace(
    /^\s+|\s+$|\s+(?=\s)/g,
    ""
  );

export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

export const ensureSchemeAndTrailingSlash = (url) => {
  // If there's no scheme (http:// or https://), add http:// by default
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    url = "http://" + url;
  }

  // If there's no trailing slash, add it
  if (!url.endsWith("/")) {
    url = url + "/";
  }

  return url;
};

export const findMedian = (arr) => {
  arr.sort((a, b) => a - b);
  let median = 0;
  if (arr.length % 2 === 1) {
    median = arr[Math.floor(arr.length / 2)];
  } else {
    const middle1 = arr[arr.length / 2 - 1];
    const middle2 = arr[arr.length / 2];
    median = (middle1 + middle2) / 2;
  }
  return median;
};

export const calculateStandardDeviation = (digitArray) => {
  const n = digitArray?.length;
  if (n <= 1) {
    return 0;
  }
  const mean = digitArray?.reduce((acc, val) => acc + val, 0) / n;
  const squaredDifferences = digitArray?.map((val) => Math.pow(val - mean, 2));
  const variance =
    squaredDifferences?.reduce((acc, val) => acc + val, 0) / (n - 1);
  const stdDeviation = Math.sqrt(variance);
  return stdDeviation;
};
