import { Stack, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { ROUTE_PATH } from "../../router/routes";
import { Link, useNavigate } from "react-router-dom";
import { InputField } from "../../common/InputField";
import LoadingButton from "../../common/LoadingButton";
import { resetPasswordRequest } from "../../services";
import { toaster } from "../../common/Toaster";
import { HTTP_OK } from "../../constants/statusCodes";
import { defaultMessage } from "../../constants/messages";
import { Field, Form, Formik } from "formik";
import { ForgetPasswordValidationSchema } from "../../utils/validationSchemas";
import { LoginContainer } from "../Login/style";

const ForgotPassword = () => {
  const formRef = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    email: "",
  };
  const onSubmit = (values, { resetForm, validateForm }) => {
    const payload = {
      email: values.email.toLowerCase(),
    };
    setLoading(true);
    resetPasswordRequest({ payload: payload })
      .then((response) => {
        setLoading(false);
        if (response?.status !== HTTP_OK) {
          throw new Error(defaultMessage);
        }

        resetForm();
        validateForm();

        toaster({
          type: "success",
          message: response?.data?.toaster?.data?.message,
        });
      })
      .catch((e) => {
        setLoading(false);
        toaster({ type: "error", message: e });
      });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleChange = (field, value) => {
    field.onChange({
      target: {
        name: field.name,
        value: value,
      },
    });
  };

  return (
    <LoginContainer>
      <Stack
        alignItems={"center"}
        sx={(theme) => ({
          width: { xs: "auto", sm: "411px" },

          pt: 3,
          pb: 4,
          px: { xs: 2, sm: 4 },

          borderRadius: theme.spacing(2),
          boxShadow: theme.palette.shadows.shadow1,
        })}>
        <Stack rowGap={3} width={1}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}>
            <Typography variant='spec_h3'>Forgot Password?</Typography>
            <Typography
              variant='spec_body'
              color={"secondary"}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(ROUTE_PATH.LOGIN);
              }}>
              Already have an account?
            </Typography>
          </Stack>

          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={ForgetPasswordValidationSchema}
            validateOnChange={true}
            validateOnBlur={true}
            validateOnMount={true}
            onSubmit={onSubmit}>
            {({ touched, errors, values, isValid }) => (
              <Form>
                <Stack rowGap={1}>
                  <Field name='email'>
                    {({ field, form }) => (
                      <InputField
                        name='email'
                        placeholder='Enter email address'
                        isLabel={true}
                        label='Email'
                        variant={"outlined"}
                        value={field.value}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSubmitClick();
                          }
                        }}
                        onChange={({ currentTarget: input }) =>
                          handleChange(field, input.value)
                        }
                        onBlur={() => {
                          form.setTouched({
                            ...form.touched,
                            email: true,
                          });
                        }}
                        error={form.touched.email && Boolean(form.errors.email)}
                        helperText={form.touched.email && form.errors.email}
                      />
                    )}
                  </Field>
                  <LoadingButton
                    variant={"contained"}
                    color='primary'
                    type='submit'
                    loading={loading}>
                    Reset Password
                  </LoadingButton>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Stack>
    </LoginContainer>
  );
};

export default ForgotPassword;
