import {
  NorthRounded,
  SouthRounded,
  UnfoldMoreRounded,
} from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";

const HeaderSort = ({ props }) => {
  const [ascSort, setAscSort] = useState("inactive");
  const [descSort, setDescSort] = useState("inactive");
  const [noSort, setNoSort] = useState("inactive");

  const onSortChanged = () => {
    setAscSort(props?.column?.isSortAscending() ? "active" : "inactive");
    setDescSort(props?.column?.isSortDescending() ? "active" : "inactive");
    setNoSort(
      !props?.column?.isSortAscending() && !props?.column?.isSortDescending()
        ? "active"
        : "inactive"
    );
  };

  const onSortRequested = (order, event) => {
    props?.setSort(order, event.shiftKey);
  };

  useEffect(() => {
    props?.column?.addEventListener("sortChanged", onSortChanged);
    onSortChanged();
  }, []);

  return (
    <Stack direction={"row"} alignItems={"center"}>
      {ascSort === "active" && (
        <IconButton
          sx={(theme) => ({ p: 0, color: theme.palette.gray.iconColor })}
          disableRipple
          onClick={(event) => onSortRequested("desc", event)}
          onTouchEnd={(event) => onSortRequested("desc", event)}>
          <NorthRounded fontSize='small' />
        </IconButton>
      )}
      {descSort === "active" && (
        <IconButton
          sx={(theme) => ({ p: 0, color: theme.palette.gray.iconColor })}
          disableRipple
          onClick={(event) => onSortRequested("", event)}
          onTouchEnd={(event) => onSortRequested("", event)}>
          <SouthRounded fontSize='small' />
        </IconButton>
      )}
      {noSort === "active" && (
        <IconButton
          sx={(theme) => ({ p: 0, color: theme.palette.gray.iconColor })}
          disableRipple
          onClick={(event) => onSortRequested("asc", event)}
          onTouchEnd={(event) => onSortRequested("asc", event)}>
          <UnfoldMoreRounded fontSize='small' />
        </IconButton>
      )}
    </Stack>
  );
};

export default HeaderSort;
