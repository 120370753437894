import _ from "lodash";
import { format } from "date-fns";
import { PERMISSIONS } from "../../constants";

export const getRowData = (spec) => {
  return {
    id: spec?.id,
    specification: spec?.title,
    status: spec?.specStatus?.name,
    priority: spec?.priority?.name,
    function: spec?.specFunction ?? "-",
    urgency: spec?.specRequest?.urgency ?? "-",
    comments: spec.comments.total ?? "-",
    visuals: spec?.visualCount ?? "-",
    labels: spec?.labelCount,
    dueDate: spec?.specRequest?.neededBy
      ? format(new Date(spec?.specRequest?.neededBy), `MMMM dd, yyyy`)
      : "-",
    updatedAt: spec?.updatedAt
      ? format(new Date(spec?.updatedAt), `MMMM dd, yyyy 'at' HH:mm a`)
      : null,
    requester:
      spec.specRequest?.requester &&
      !_.isEmpty(spec.specRequest?.requester) &&
      spec?.specRequest?.requester?.firstName
        ? `${spec?.specRequest?.requester?.firstName} ${spec?.specRequest?.requester?.lastName}`
        : null,
    author:
      spec?.specAccesses?.length > 0
        ? `${spec?.specAccesses?.[0]?.user?.firstName} ${spec?.specAccesses?.[0]?.user?.lastName}`
        : null,
    collaborators: spec?.specAccesses
      ? spec?.specAccesses
          .filter((item) => item?.permission !== PERMISSIONS.ADMIN)
          .map((item) => {
            return {
              username: `${item?.user?.firstName} ${item?.user?.lastName}`,
            };
          })
      : [],
    isTemplate: spec?.publishedAsTemplate,
    specAccesses: spec?.specAccesses,
  };
};
