import MenuItem from "../../common/MenuItem";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ExpandMoreRoundedIcon } from "../../assets";
import { updateSpecification } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import CustomPopover from "../../common/CustomPopover";
import TooltipTypography from "../../common/TooltipTypography";
import { Stack, Typography, alpha, useTheme } from "@mui/material";
import { getIndustriesAsync } from "../../store/slices/industries";
import IndustriesMenuSkeleton from "../../common/Skeletons/IndustriesMenuSkeleton";
import { updateSpecIndustry } from "../../store/slices/userSpec";

const IndustryDropdown = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { specId } = useParams();
  const { specData } = useSelector((state) => state.spec);
  const { specIndustries, isIndustriesLoading } = useSelector(
    (state) => state.industries
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentSpecIndustry, setCurrentSpecIndustry] = useState(
    specData?.specIndustry
  );

  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateIndustry = (industry) => {
    setCurrentSpecIndustry(industry);
    handleClose();

    const payload = {
      specification: {
        spec_industry_id: industry?.id,
      },
    };

    updateSpecification({
      payload: payload,
      id: specId,
    }).then((response) => {
      dispatch(updateSpecIndustry(industry));
      // DO SOMETHING ON INDUSTRY UPDATE SUCCESS - MOST PROBABLY UPDATE SAVED STATUS
    });
  };

  useEffect(() => {
    if (open) {
      dispatch(getIndustriesAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (currentSpecIndustry) {
      dispatch(updateSpecIndustry(currentSpecIndustry));
    }
  }, [currentSpecIndustry]);

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} columnGap={1}>
        <Typography variant='spec_caption' color={"gray.main"}>
          Industry:
        </Typography>

        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
          onClick={handleClick}>
          <TooltipTypography
            label={currentSpecIndustry?.title || "Select Industry"}
            size='25'
            variant='spec_caption'
            displaySx={{ whiteSpace: "nowrap" }}
          />
          <ExpandMoreRoundedIcon />
        </Stack>
      </Stack>
      <CustomPopover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          maxHeight: "332px",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {isIndustriesLoading ? (
          <IndustriesMenuSkeleton />
        ) : (
          <Stack rowGap={1}>
            {specIndustries?.length > 0 ? (
              specIndustries?.map((industry, index) => (
                <MenuItem
                  key={index}
                  itemText={<TooltipTypography label={industry?.title} />}
                  onClick={() => handleUpdateIndustry(industry)}
                  itemProps={{
                    sx: {
                      height: "12px",
                      backgroundColor:
                        industry?.id === currentSpecIndustry?.id
                          ? alpha(theme.palette.primary.light, 0.3)
                          : "",
                    },
                  }}
                />
              ))
            ) : (
              <Typography
                variant='spec_h3'
                color={"error.main"}
                textAlign={"center"}>
                No Industries Available!
              </Typography>
            )}
          </Stack>
        )}
      </CustomPopover>
    </>
  );
};

export default IndustryDropdown;
