import { useSelector } from "react-redux";
import UserEditForm from "./UserEditForm";

export const ProfileEdit = () => {
  const { user, currentCompany } = useSelector((state) => state.userAuth);

  const userData = {
    id: user?.id,
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    department: user?.department,
    companyName: user?.company?.name || "",
    position: user?.jobTitle,
    role: currentCompany?.role || "",
    superAdmin: user?.superAdmin,
  };

  return <UserEditForm userData={userData} canEditRole={false} />;
};
