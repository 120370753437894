import { Skeleton, Stack } from "@mui/material";
import React from "react";

const StatusMenuSkeleton = () => {
  return (
    <Stack>
      <Skeleton height={40} />
      <Skeleton height={40} />
      <Skeleton height={40} />
    </Stack>
  );
};

export default StatusMenuSkeleton;
