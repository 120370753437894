import { useEffect, useState } from "react";

import {
  ArrowForward,
  CancelOutlined,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  CircularProgress,
  Stack,
  TableCell,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import _ from "lodash";
import moment from "moment";
// import { useSpecContext } from "@contexts/spec_provider";

import HistoryFilters from "./Filters/HistoryFilters";
import UserFilter from "./Filters/UserFilter";
import HistoryTable from "./HistoryTable";
import {
  ACTION_OPTIONS,
  COMPONENTS_OPTIONS,
  camelCaseToSentence,
  convertHistoryObjectToArray,
  deepDiff,
  targetName,
} from "./utils";
import { stringAvatar } from "../../utils/commonUtility";
import DateRangeCalendar from "../../common/DateRangeCalendar";
import { useDispatch, useSelector } from "react-redux";
import { getSpecHistoryAsync } from "../../store/slices/userSpec";
import { useParams } from "react-router-dom";

export const History = ({ needReload }) => {
  // const { specData } = useSpecContext();
  let { specId } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state?.spec);
  const [historyData, setHistoryData] = useState([]);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [component, setComponent] = useState(null);
  const [action, setAction] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: moment.utc().subtract(6, "days").format(),
    endDate: moment().format(),
    key: "selection",
  });

  useEffect(() => {
    // needReload &&
    dispatch(
      getSpecHistoryAsync({
        specId: specId,
        date_from: moment.utc(dateRange?.startDate).format("dd/MM/yyyy"),
        date_to: moment.utc(dateRange?.endDate).format("dd/MM/yyyy"),
      })
    );
    setSelectedUsers([]);
    setComponent(null);
    setAction(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needReload]);

  useEffect(() => {
    setHistoryData(
      reduxState?.specHistory?.specHistory
        ? convertHistoryObjectToArray(reduxState?.specHistory?.specHistory)
        : []
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxState?.specHistory]);

  // the changes are in the format of {from: ..., to: ...}
  // this function will return the changes in a readable format
  // this function for one change in historyData
  const getChanges = (changes) => {
    // it is arr of changes, thats why we get the first element for understanding the type of change
    const { from, to } = changes[Object.keys(changes)[0]];

    // it will save only differences between objects
    let diff = [];
    // if from and to is object, then it's a complex change and we need to get the diff between objects
    if (_.isObject(from)) {
      diff = deepDiff(from, to).filter(
        (change) =>
          // filter out changes that are not informative to the user
          change.title !== "time" &&
          change.title !== "x" &&
          change.title !== "y" &&
          change.title !== "type" &&
          change.title !== "searchTermID"
      );
    }
    // if from and to is string, then it's a simple change
    return typeof from === "string" || typeof to === "string" ? (
      <Typography
        variant='spec_caption'
        sx={{ display: "flex", alignItems: "center" }}>
        <span style={{ fontWeight: 600, marginRight: "2px" }}>
          {camelCaseToSentence(Object.keys(changes)[0])}:
        </span>
        <span
          dangerouslySetInnerHTML={{
            __html: from,
          }}></span>
        <ArrowForward style={{ margin: "0 8px", color: "#424242" }} />
        <span
          dangerouslySetInnerHTML={{
            __html: to,
          }}></span>
      </Typography>
    ) : (
      // if from and to is object, then it's a complex change
      diff.map((change, index) => {
        return (
          <Typography
            variant='spec_caption'
            key={index}
            sx={{ display: "flex", alignItems: "center" }}>
            <span style={{ fontWeight: 600, marginRight: "2px" }}>
              {change.label
                ? // show label of the change if it exists and title of the change
                  `${change.label} (${camelCaseToSentence(change.title)})`
                : camelCaseToSentence(
                    // if type of change is title, then show 'Title', else show the title of the change
                    from[0]?.type?.toLowerCase() === "title"
                      ? "title"
                      : change.title
                  )}
              :
            </span>
            {/*// from value*/}
            <span
              dangerouslySetInnerHTML={{
                __html:
                  typeof change.from === "string"
                    ? change.from
                    : change.from?.label || change.from?.type,
              }}></span>
            {/*// arrow icon*/}
            {change.from && change.to && (
              <ArrowForward style={{ margin: "0 8px", color: "#424242" }} />
            )}
            {/*to value*/}
            <span
              dangerouslySetInnerHTML={{
                __html:
                  typeof change.to === "string"
                    ? change.to
                    : change.to?.label || change.to?.type,
              }}></span>
          </Typography>
        );
      })
    );
  };

  const columns = [
    {
      Header: "User",
      accessor: "name",
      sxHeader: { justifyContent: "center" },
      Cell: ({ row }) => {
        const original = row.original;
        return (
          <TableCell
            // rowSpan="2"
            sx={{
              border: `1px solid ${theme.palette.gray.borderGray}`,
              borderTop: "none",
              borderRight: 0,
            }}>
            <Box
              sx={{
                height: "max-content",
                display: "flex",
                justifyContent: "center",
              }}>
              <Tooltip
                title={
                  <Typography
                    variant={"spec_tooltip"}
                    sx={{
                      backgroundColor: "transparent",
                      wordBreak: "break-word",
                    }}>
                    {`${original?.userFirstName} ${original?.userLastName}`}
                  </Typography>
                }
                arrow>
                <Avatar
                  sx={{
                    height: "30px",
                    width: "30px",
                    color: "#1890FF",
                    backgroundColor: "#E6F7FF",
                    "&.MuiAvatar-root:last-child ": {
                      marginLeft: "0px!important",
                    },
                  }}>
                  <Typography variant='spec_body' color={"secondary.main"}>
                    {
                      stringAvatar(
                        `${original?.userFirstName} ${original?.userLastName}`
                      )?.children
                    }
                  </Typography>
                </Avatar>
              </Tooltip>
            </Box>
          </TableCell>
        );
      },
    },
    {
      Header: "Date",
      accessor: "date",
      sxHeader: { justifyContent: "center" },
      Cell: ({ value }) => {
        return (
          <TableCell
            sx={{
              border: `1px solid ${theme.palette.gray.borderGray}`,
              borderTop: "none",
              borderRight: 0,
              whiteSpace: "nowrap",
              textAlign: "center",
            }}>
            <Typography variant='spec_history_body'>{value}</Typography>
          </TableCell>
        );
      },
    },
    {
      Header: "Action",
      sxHeader: { justifyContent: "center" },
      accessor: "event",
      Cell: ({ value }) => {
        return (
          <TableCell
            sx={{
              border: `1px solid ${theme.palette.gray.borderGray}`,
              textAlign: "center",
              // borderLeft: 0,
              borderRight: 0,
              borderTop: "none",
            }}>
            <Typography variant='spec_history_body'>
              {value === "create"
                ? "Added"
                : value === "destroy"
                ? "Deleted"
                : value === "update"
                ? "Updated"
                : camelCaseToSentence(value)}
            </Typography>
          </TableCell>
        );
      },
    },
    {
      Header: "Component",
      accessor: "recordType",
      sxHeader: { justifyContent: "center" },
      Cell: ({ value, row }) => {
        const original = row.original;
        return (
          <TableCell
            sx={{
              border: `1px solid ${theme.palette.gray.borderGray}`,
              // borderLeft: 0,
              borderRight: 0,
              borderTop: "none",
              textAlign: "center",
            }}
            // sx={{
            //   border: `1px solid ${theme.palette.gray.borderGray}`,
            //   borderTop: "none",
            //   wordBreak: "break-word",
            // }}
          >
            <Typography variant='spec_history_body'>
              {targetName(value, original)}
            </Typography>
          </TableCell>
        );
      },
    },
    {
      Header: "Change",
      accessor: "span",
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <TableCell
            // colSpan="3"
            // sx={{
            //   border: `1px solid ${theme.palette.grey[100]}`,
            //   borderTop: 0,
            // }}
            sx={{
              border: `1px solid ${theme.palette.gray.borderGray}`,
              borderTop: "none",
              wordBreak: "break-word",
              paddingLeft: "10px !important",
              paddingRight: "10px !important",
            }}>
            {/* {value} */}
            {getChanges(original.changes)}
          </TableCell>
        );
      },
    },
  ];

  const handleChangeAction = (value) => {
    setAction(value);
  };

  const handleChangeComponent = (value) => {
    setComponent(value);
  };

  const handleClearFilter = () => {
    setAction(null);
    setComponent(null);
    setSelectedUsers([]);
  };

  useEffect(() => {
    dispatch(
      getSpecHistoryAsync({
        specId: specId,
        date_from: moment.utc(dateRange?.startDate).format("DD/MM/yyyy"),
        date_to: moment(dateRange?.endDate).format("DD/MM/yyyy"),
        user_ids: selectedUsers,
        event: action?.value,
        record_type: component?.value,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, component, selectedUsers, dateRange]);

  return (
    <Box
      sx={{
        height: "calc(100vh - 170px)",
        top: 0,
      }}>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          py: 1,
          columnGap: 1,
          rowGap: 1,
        }}>
        <DateRangeCalendar
          dateRange={dateRange}
          setDateRange={setDateRange}
          allowClearButton={false}
        />
        <HistoryFilters
          title='Action'
          options={ACTION_OPTIONS}
          width={150}
          selected={action}
          onSelect={handleChangeAction}
        />
        <HistoryFilters
          title='Components'
          options={COMPONENTS_OPTIONS}
          selected={component}
          onSelect={handleChangeComponent}
        />
        <UserFilter selected={selectedUsers} setSelected={setSelectedUsers} />
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          onClick={handleClearFilter}
          sx={{
            cursor: "pointer",
            columnGap: 0.5,
          }}>
          <CancelOutlined fontSize='small' style={{ color: "#424242" }} />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 600,
            }}>
            CLEAR ALL
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      {reduxState?.isSpecHistoryLoading ? (
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          sx={{
            columnGap: 1,
            py: 1,
          }}>
          <CircularProgress
            color='primary'
            size={20}
            sx={{
              display: "flex",
            }}
          />
          <Typography variant='h6'>Loading history</Typography>
        </Stack>
      ) : (
        <Stack direction='column'>
          {historyData.length ? (
            historyData.map((month) => (
              <Box key={month.title}>
                <Box mt={2.25} mb={2.25}>
                  <Typography variant='spec_historytitle' color='#595959'>
                    {month.title}
                  </Typography>
                </Box>
                <Divider />
                {month.days.map((day, index) => (
                  <Box key={day.title} mt={2.25}>
                    <TreeView
                      aria-label='history'
                      defaultCollapseIcon={
                        <KeyboardArrowDown style={{ color: "#424242" }} />
                      }
                      defaultExpandIcon={
                        <KeyboardArrowRight style={{ color: "#424242" }} />
                      }
                      defaultExpanded={["1"]}
                      sx={{
                        flexGrow: 1,
                        overflowY: "auto",
                        ul: {
                          margin: 0,
                        },
                      }}>
                      <TreeItem
                        className='custom-tree-item'
                        nodeId={`first${index + 1}`}
                        sx={{
                          "& > .MuiTreeItem-content": {
                            backgroundColor: "White",

                            "&.Mui-selected, &.Mui-selected.Mui-focused, &.Mui-focused, &:hover.Mui-selected":
                              {
                                backgroundColor: "White",
                              },
                          },
                        }}
                        label={
                          <Typography variant='spec_h3'>{day.title}</Typography>
                        }>
                        <Box sx={{ py: 1 }}>
                          <HistoryTable
                            key={index}
                            data={day.changes}
                            columns={columns}
                          />
                        </Box>
                      </TreeItem>
                      <Box mt={1.5}>
                        <Divider />
                      </Box>
                    </TreeView>
                  </Box>
                ))}
              </Box>
            ))
          ) : (
            <Typography
              variant='spec_body'
              sx={{
                py: 1,
              }}>
              No history found.
            </Typography>
          )}
        </Stack>
      )}
    </Box>
  );
};
