import { CircularProgress, Stack, useTheme } from "@mui/material";
import CustomOverlayLoadingComponent from "../../common/CustomOverlayLoadingComponent";
import NoRowsOverlayCustomComponent from "../../common/NoRowsOverlayCustomComponent";
import AgGridComponent from "../../common/AgGridComponent";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getMappingsColumns } from "./columns";
import {
  getMappingColumnsAsync,
  getSpecBusinessTermsAsync,
  resetBusinessTerms,
} from "../../store/slices/businessTerms";
import { useDispatch, useSelector } from "react-redux";
import { getSpecBusinessTerm } from "../../services";
import { useLocation, useParams } from "react-router-dom";
import CustomHeaderCell from "../../common/CustomHeaderCell";
import { getRowData } from "./helperFunctions";
import AddEditBusinessTerm from "../../components/AddEditBusinessTerm";
import { ROUTE_PATH } from "../../router/routes";

const SpecMappings = () => {
  const { specId } = useParams();
  const location = useLocation();

  const ref = useRef(null);
  const dispatch = useDispatch();
  const theme = useTheme();

  const {
    mappingColumns,
    businessTerms,
    currentTermTab,
    isBuisnessTermLoading,
  } = useSelector((state) => state.businessTerms);

  // Example condition based on location pathname
  const isPreview = location.pathname.includes("/spec-preview");

  const [colDef, setColDef] = useState([]);
  const [isGridReady, setIsGridReady] = useState(false);
  const [businessTermsRowsForAgGrid, setBusinessTermsRowsForAgGrid] = useState(
    []
  );

  const [isInitialLoading, setIsInitialLoading] = useState(true);

  // SHOW OVERLAY
  const showLoading = useCallback(() => {
    return ref?.current?.api?.showLoadingOverlay();
  }, []);

  // SHOW NO ROWS OVERLAY
  const showNoRowsOverlay = useCallback(() => {
    return ref.current.api.showNoRowsOverlay();
  }, []);

  // HIDE OVERLAY
  const hideOverlay = useCallback(() => {
    return ref?.current?.api?.hideOverlay();
  }, []);

  const onGridReady = useCallback(() => {
    setIsGridReady(true);
  }, []);

  // CUSTOM HEADER
  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeaderCell,
    };
  }, []);

  // SHOW OR HIDE LOADING OVERLAY
  useEffect(() => {
    if (ref && ref?.current && isGridReady) {
      if (isBuisnessTermLoading) {
        showLoading();
      } else {
        // hideOverlay();
      }
    }
  }, [ref, isBuisnessTermLoading, isGridReady]);

  useEffect(() => {
    dispatch(getMappingColumnsAsync());
    dispatch(
      getSpecBusinessTermsAsync({
        specId: specId,
      })
    )
      .unwrap()
      .then(() => {
        setIsInitialLoading(false);
      });

    return () => dispatch(resetBusinessTerms());
  }, []);

  useEffect(() => {
    if (mappingColumns) {
      const columns = getMappingsColumns(mappingColumns, isPreview);
      setColDef(columns);
    }
  }, [mappingColumns]);

  useEffect(() => {
    const newRows = getRowData(businessTerms);

    setBusinessTermsRowsForAgGrid(newRows);
  }, [businessTerms]);

  return (
    <>
      <Stack
        sx={{
          width: 1,
          height: 1,
        }}>
        {isInitialLoading ? (
          <Stack
            justifyContent='center'
            alignItems='center'
            sx={{
              height: 1,
              width: 1,
            }}>
            <CircularProgress size={60} />
          </Stack>
        ) : (
          <Stack
            sx={{
              height: 1,
              width: 1,
              borderTop: `1px solid ${theme.palette.gray.borderGray}`,
              // isPreview
              //   ? "none"
              //   : `1px solid ${theme.palette.gray.borderGray}`,
            }}>
            <AgGridComponent
              ref={ref}
              columnDefs={colDef}
              data={
                businessTermsRowsForAgGrid?.length > 0
                  ? businessTermsRowsForAgGrid
                  : []
              }
              components={components}
              loadingOverlayComponent={CustomOverlayLoadingComponent}
              noRowsOverlayComponent={NoRowsOverlayCustomComponent}
              rowModelType='clientSide'
              onGridReady={onGridReady}
              paginationPageSize={25}
              noRowsMessage={
                `Business terms will get displayed here as you create a visual mockup.`
                // isPreview
                //   ? "Business terms will be automatically displayed here based on what is inputted in the visual mockup."
                //   : "Business terms will automatically be displayed here if you propose any new term or make changes to the default terms."
              }
              // {...(isPreview && { domLayout: "autoHeight" })}
            />
          </Stack>
        )}
      </Stack>
      <AddEditBusinessTerm isSpecMappings={true} />
    </>
  );
};

export default SpecMappings;
