import { Box, styled as muiStyled } from "@mui/material";

export const PromptTextBox = muiStyled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.gray.light}`,
  paddingRight: "10px",
  paddingLeft: "10px",
  borderRadius: "7px",
  "&:hover": {
    borderColor: theme.palette.primary.main,
  },
  "&:focus-within": {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));
