import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGrid, AgGridContainer } from "./style";

export const AgGridComponent = (
  {
    components,
    data = [],
    datasource = null,
    columnDefs = [],
    defaultColDef = {},
    hiddenColumns = [],
    rowSelection = "single",
    onRowClick = () => null,
    rowDragEntireRow = false,
    height = null,
    rowModelType = "clientSide",
    getRowId = null,
    maxBlocksInCache = null,
    rowBuffer = 10,
    paginationPageSize = 25,
    cacheOverflowSize = 2,
    maxConcurrentDatasourceRequests = 2,
    infiniteInitialRowCount = 1,
    suppressRowClickSelection = true,
    blockLoadDebounceMillis = 500,
    handleOnCellEditingStopped,
    noRowsOverlayComponent,
    loadingOverlayComponent,
    onGridReady,
    noRowsMessage = "No data found.",
    autoSizeStrategy,
    pagination = true,
    domLayout,
    onRowDragEnd,
    noRowsOverlayComponentParamsObj = {},
  },
  ref
) => {
  // const [rowData, setRowData] = useState([]);

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      ...noRowsOverlayComponentParamsObj,
      noRowsMessageFunc: () => noRowsMessage,
    };
  }, [noRowsMessage, noRowsOverlayComponentParamsObj]);

  // useEffect(() => {
  //   setRowData(data?.length > 0 ? data : []);
  // }, [data]);

  const onSelectionChanged = useCallback(() => {
    const selectedRows = ref.current?.api.getSelectedRows();
    onRowClick(selectedRows);
  }, [onRowClick]);

  return (
    <AgGridContainer className='ag-theme-quartz'>
      <AgGrid
        ref={ref}
        rowData={data}
        datasource={datasource}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        components={components}
        suppressMenuHide={true}
        rowSelection={rowSelection}
        onSelectionChanged={onSelectionChanged}
        rowHeight={55}
        rowDragEntireRow={rowDragEntireRow}
        rowDragManaged={true}
        rowModelType={rowModelType}
        getRowId={getRowId ?? undefined}
        maxBlocksInCache={maxBlocksInCache ?? undefined}
        rowBuffer={rowBuffer}
        cacheOverflowSize={cacheOverflowSize}
        maxConcurrentDatasourceRequests={maxConcurrentDatasourceRequests}
        infiniteInitialRowCount={infiniteInitialRowCount}
        suppressRowClickSelection={suppressRowClickSelection}
        blockLoadDebounceMillis={blockLoadDebounceMillis}
        onCellEditingStopped={handleOnCellEditingStopped}
        noRowsOverlayComponent={noRowsOverlayComponent}
        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
        loadingOverlayComponent={loadingOverlayComponent}
        reactiveCustomComponents
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        // paginationAutoPageSize //CAN USE TO AUTOMATICALLY ADD PAGE SIZE
        paginationPageSizeSelector={false}
        onGridReady={onGridReady}
        suppressRowHoverHighlight={true}
        headerHeight={42}
        {...(autoSizeStrategy && {
          autoSizeStrategy: { type: autoSizeStrategy },
        })}
        // autoSizeStrategy={{ type: "fitGridWidth" }} //CAN ADJUST FOR COLUMN SIZE IN RELATION TO THE AVAILABLE WIDTH
        domLayout={domLayout}
        onRowDragEnd={onRowDragEnd}
      />
    </AgGridContainer>
  );
};

export default forwardRef(AgGridComponent);
