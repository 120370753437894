import { Box, styled as MuiStyled, Tab, Tabs, Typography } from "@mui/material";

export const StyledTabs = MuiStyled((props) => (
  <Tabs
    sx={{
      p: 0,
      m: 0,
    }}
    TabIndicatorProps={{
      sx: {
        height: "3px",
        borderRadius: "8px",
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  // here goes styling for mui tabs component
  minHeight: "32px !important",
  "& .MuiTabScrollButton-root.Mui-disabled": {
    display: "none",
  },
  ".MuiButtonBase-root.Mui-selected": {
    color: theme.palette.secondary.black,
  },
}));

export const StyledTab = MuiStyled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: theme.palette.gray.main,
    minHeight: "32px !important",
    textTransform: "none",
    fontFamily: "Poppins",
    letterSpacing: 0,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: 0,

    "&.Mui-selected": {
      fontWeight: 600,
      color: theme.palette.gray.textGray1,
    },

    [theme.breakpoints.down("lg")]: {
      minWidth: "auto",
      maxWidth: "100%",
      alignItems: "end",
    },
  })
);

export const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{
        height: `calc(100% - 33px)`, //33px is height of tabs mockdataset page tabs
      }}
      {...other}>
      {value === index && children}
    </Box>
  );
};

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
