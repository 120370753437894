import { toaster } from "../Toaster";
import PopupSelect from "../PopupSelect";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterAltOutlined } from "@mui/icons-material";
import { Badge, IconButton, Typography } from "@mui/material";
import {
  setDueDate,
  setSelectedOptions,
} from "../../store/slices/tableFilters";
import DateRangeCalendar from "../DateRangeCalendar";

const HeaderFilter = ({ props }) => {
  const dispatch = useDispatch(false);
  const colDef = props?.column?.colDef;
  const { filters } = useSelector((state) => state.tableFilter);
  const field = colDef?.field;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectValue, setSelectValue] = useState(
    filters?.[field] ? filters?.[field] : null
  );
  const [options, setOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(
      setSelectedOptions({ colId: colDef?.field, options: selectValue })
    );
  };

  useEffect(() => {
    const getFilterOptions = async (loadOptions) => {
      setOptionsLoading(true);
      try {
        const response = await loadOptions();
        setOptionsLoading(false);

        setOptions(response);
      } catch (error) {
        setOptionsLoading(false);
        toaster({
          type: "error",
          message: "Error while fetching filter options!",
        });
      }
    };

    if (open) {
      const loadOptions = colDef?.filterParams?.loadOptions;
      const staticOptions = colDef?.filterParams?.staticOptions;
      if (loadOptions) {
        getFilterOptions(loadOptions);
      }
      if (staticOptions) {
        setOptions(staticOptions);
      }
    }
  }, [open]);

  return (
    <>
      {!colDef?.filterParams?.isDateFilter ? (
        <>
          <IconButton sx={{ p: 0 }} onClick={handleClick}>
            <Badge
              size={"small"}
              badgeContent={filters?.[field] ? filters?.[field]?.length : 0}
              color='primary'
              max={10}
              sx={(theme) => ({
                "& .MuiBadge-badge": {
                  right: -3,
                  top: -2,
                  border: `2px solid ${theme.palette.background.paper}`,
                  padding: "0 4px",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                },
              })}>
              <FilterAltOutlined
                fontSize='small'
                open={open}
                sx={(theme) => ({
                  color: open
                    ? theme.palette.primary.main
                    : theme.palette.gray.iconColor,
                })}
              />
            </Badge>
          </IconButton>

          <PopupSelect
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            value={selectValue}
            handleSelectChange={(newValue) => setSelectValue(newValue)}
            isMulti={true}
            options={options}
            loading={optionsLoading}
          />
        </>
      ) : (
        <DateRangeCalendar
          dateRange={filters?.[field]?.dateRange}
          setDateRange={(obj) => {
            dispatch(setDueDate(obj));
          }}
          isFilterIcon={true}
        />
      )}
    </>
  );
};

export default HeaderFilter;
