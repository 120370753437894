import { InputLabel, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { InputField } from "../../common/InputField";
import CustomSelectField from "../../common/CustomSelectField";
import { URGENCY } from "../../constants";
import {
  AddLinkOutlined,
  BorderColorOutlined,
  CalendarMonthOutlined,
} from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { toaster } from "../../common/Toaster";
import {
  getCompanyUsersEmailOrName,
  updateSpecification,
} from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";
import { format, parseISO } from "date-fns";
import {
  setShareModalOpen,
  updateAutoSaved,
} from "../../store/slices/userSpec";
const RequesterInformationForm = () => {
  const dispatch = useDispatch();

  const { specId } = useParams();
  const { specData } = useSelector((state) => state.spec);

  const [requester, setRequester] = useState(null);
  const [urgency, setUrgency] = useState(null);
  const [effort, setEffort] = useState(null);
  const [purpose, setPurpose] = useState(null);
  const [link, setLink] = useState(null);
  const [date, setDate] = useState(null);

  const [userOptions, setUserOptions] = useState([]);
  const [userOptionsLoading, setUserOptionsLoading] = useState(false);

  const formatCreateLabel = (newValue) => {
    return (
      <Typography variant='spec_body' sx={{ fontSize: "16px" }}>
        Add{" "}
        <Typography
          component={"span"}
          color={"secondary"}
          variant='spec_body'
          sx={{ fontSize: "16px" }}>
          {newValue}
        </Typography>{" "}
        as User
      </Typography>
    );
  };

  const handleOnCreateOption = (value) => {
    dispatch(
      setShareModalOpen({
        shareModalData: value?.trim(),
      })
    );
  };

  useEffect(() => {
    const getUsers = () => {
      setUserOptionsLoading(true);
      getCompanyUsersEmailOrName()
        .then((res) => {
          setUserOptionsLoading(false);
          setUserOptions(res);
        })
        .catch(() => {
          setUserOptionsLoading(false);
          toaster({
            type: "error",
            message: "Error while fetching users details!",
          });
        });
    };

    getUsers();
  }, []);

  useEffect(() => {
    setEffort(specData.specRequest?.effort || null);
    setLink(specData.specRequest?.linkToTicket || "");
    setRequester(specData.specRequest?.requester?.id || null);
    setUrgency(specData.specRequest?.urgency || null);
    setDate(
      specData.specRequest?.neededBy
        ? new Date(parseISO(specData.specRequest.neededBy))
        : null
    );
    setPurpose(specData.specRequest?.neededFor || "");
  }, [specData]);

  const updateLink = useCallback(
    debounce(({ link }) => {
      updateSpecification({
        payload: {
          specification: {
            spec_request_attributes: {
              link_to_ticket: link,
            },
          },
        },
        id: specId,
      }).then((response) => {
        dispatch(updateAutoSaved(true));
        // DO NOTHING AS OF NOW AS ITS A AUTO SAVE FUNCTIONALITY
      });
    }, 1000),
    []
  );
  const updatePurpose = useCallback(
    debounce(({ purpose }) => {
      updateSpecification({
        payload: {
          specification: {
            spec_request_attributes: {
              needed_for: purpose,
            },
          },
        },
        id: specId,
      }).then((response) => {
        dispatch(updateAutoSaved(true));
        // DO NOTHING AS OF NOW AS ITS A AUTO SAVE FUNCTIONALITY
      });
    }, 1000),
    []
  );

  const updateUrgency = ({ urgency }) => {
    updateSpecification({
      payload: {
        specification: {
          spec_request_attributes: {
            urgency: urgency,
          },
        },
      },
      id: specId,
    }).then((response) => {
      dispatch(updateAutoSaved(true));
      // DO NOTHING AS OF NOW AS ITS A AUTO SAVE FUNCTIONALITY
    });
  };

  const updateEffort = ({ effort }) => {
    updateSpecification({
      payload: {
        specification: {
          spec_request_attributes: {
            effort: effort,
          },
        },
      },
      id: specId,
    }).then((response) => {
      dispatch(updateAutoSaved(true));
      // DO NOTHING AS OF NOW AS ITS A AUTO SAVE FUNCTIONALITY
    });
  };

  const updateDueDate = ({ dueDate }) => {
    updateSpecification({
      payload: {
        specification: {
          spec_request_attributes: {
            needed_by: dueDate,
          },
        },
      },
      id: specId,
    }).then((response) => {
      dispatch(updateAutoSaved(true));
      // DO NOTHING AS OF NOW AS ITS A AUTO SAVE FUNCTIONALITY
    });
  };

  const updateRequester = ({ requester }) => {
    updateSpecification({
      payload: {
        specification: {
          spec_request_attributes: {
            requester_id: requester,
          },
        },
      },
      id: specId,
    }).then((response) => {
      dispatch(updateAutoSaved(true));
      // DO NOTHING AS OF NOW AS ITS A AUTO SAVE FUNCTIONALITY
    });
  };

  return (
    <Stack rowGap={4}>
      <Typography variant='spec_h3'>Requester Information</Typography>
      <Stack rowGap={2} pl={3}>
        <Stack
          columnGap={1}
          rowGap={1}
          sx={{
            flexDirection: { xs: "column", sm: "row" },
          }}>
          <Stack sx={{ width: { xs: 1, sm: "50%" } }}>
            <CustomSelectField
              value={
                userOptions?.find((option) => option.value === requester) ||
                null
              }
              isLabel={true}
              label='Requester'
              placeholder='Search by name or email'
              onChange={(newValue) => {
                setRequester(newValue?.value);
                updateRequester({ requester: newValue?.value });
              }}
              optionsLoading={userOptionsLoading}
              disabled={userOptionsLoading}
              options={userOptions}
              isMulti={false}
              isCreatable={true}
              menuPlacement={"top"}
              handleOnCreateOption={handleOnCreateOption}
              formatCreateLabel={formatCreateLabel}
            />
          </Stack>
          <Stack sx={{ width: { xs: 1, sm: "50%" } }}>
            <CustomSelectField
              isLabel={true}
              label='Urgency'
              placeholder='Please Select'
              value={
                URGENCY?.find((option) => option.value === urgency) || null
              }
              options={URGENCY}
              onChange={(newValue) => {
                setUrgency(newValue.value);
                updateUrgency({ urgency: newValue.value });
              }}
              isMulti={false}
            />
          </Stack>
        </Stack>
        <Stack
          columnGap={1}
          rowGap={1}
          sx={{
            flexDirection: { xs: "column", sm: "row" },
          }}>
          <Stack sx={{ width: { xs: 1, sm: "50%" } }}>
            <Stack>
              <InputLabel
                sx={{
                  mb: 0.65,
                }}>
                <Typography variant='spec_inputLabel'>Due Date</Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  format={"MM/dd/yyyy"}
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue);
                    const date = new Date(newValue);
                    // Convert the date to ISO format
                    let isoDate = date.toISOString();
                    updateDueDate({ dueDate: isoDate });
                  }}
                  slotProps={{
                    textField: {
                      InputProps: {
                        endAdornment: (
                          <CalendarMonthOutlined
                            fontSize='small'
                            sx={(theme) => ({
                              color: theme.palette.gray.iconColor,
                            })}
                          />
                        ),
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </Stack>
          </Stack>
          <Stack sx={{ width: { xs: 1, sm: "50%" } }}>
            <CustomSelectField
              isLabel={true}
              label='Effort'
              placeholder='Please Select'
              value={URGENCY?.find((option) => option.value === effort) || null}
              options={URGENCY}
              onChange={(newValue) => {
                setEffort(newValue.value);
                updateEffort({ effort: newValue.value });
              }}
              isMulti={false}
            />
          </Stack>
        </Stack>
        <Stack
          columnGap={1}
          rowGap={1}
          sx={{
            flexDirection: { xs: "column", sm: "row" },
          }}>
          <Stack sx={{ width: { xs: 1, sm: "50%" } }}>
            <InputField
              value={purpose}
              onChange={(e) => {
                setPurpose(e.target.value);
                updatePurpose({ purpose: e.target.value });
              }}
              isLabel={true}
              label='Purpose'
              placeholder='Briefly describe the purpose of your request'
              endAdornment={
                <BorderColorOutlined
                  fontSize='small'
                  sx={(theme) => ({ color: theme.palette.gray.iconColor })}
                />
              }
            />
          </Stack>
          <Stack sx={{ width: { xs: 1, sm: "50%" } }}>
            <InputField
              value={link}
              onChange={(e) => {
                setLink(e.target.value);
                updateLink({ link: e.target.value });
              }}
              isLabel={true}
              label='Link to ticket (optional)'
              placeholder='https://example.com'
              endAdornment={
                <AddLinkOutlined
                  fontSize='small'
                  sx={(theme) => ({ color: theme.palette.gray.iconColor })}
                />
              }
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RequesterInformationForm;
