import { createTheme } from "@mui/material";
import { themePalette } from "./colors";
export const themeTypography = createTheme(themePalette, {
  typography: {
    spec_bold_title: {
      fontFamily: "Poppins",
      fontSize: "18px",
      fontWeight: 600,
      textTransform: "capitalize",
      color: themePalette.palette.secondary.textColor,
    },
    spec_bold_subtitle: {
      fontFamily: "Poppins",
      fontSize: "15px",
      fontWeight: 400,
      textTransform: "capitalize",
      color: themePalette.palette.secondary.textColor,
    },
    spec_h1: {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: 700,
      textTransform: "capitalize",
      color: themePalette.palette.secondary.textColor,
    },
    spec_h2: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 600,
      textTransform: "capitalize",
      color: themePalette.palette.secondary.textColor,
    },
    spec_h3: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 600,
      textTransform: "capitalize",
      color: themePalette.palette.secondary.textColor,
    },
    spec_h4: {
      fontFamily: "Poppins",
      fontSize: "13px",
      fontWeight: 500,
      textTransform: "capitalize",
      color: themePalette.palette.secondary.textColor,
    },
    spec_body: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
      color: themePalette.palette.secondary.textColor,
    },
    spec_button: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 500,
      textTransform: "capitalize",
    },
    spec_caption: {
      fontFamily: "Poppins",
      fontSize: "12px",
      fontWeight: 500,
      color: themePalette.palette.secondary.textColor,
    },
    spec_tooltip: {
      fontFamily: "Poppins",
      fontSize: "12px",
      fontWeight: 500,
      color: themePalette.palette.secondary.white,
    },
    spec_inputLabel: {
      fontFamily: "Poppins",
      fontWeight: 500,
      color: "#262626",
      // lineHeight: "0.8em",
      fontSize: "14px",
      padding: "0 8px",
      marginLeft: -6,
      lineHeight: "1.4375em",
    },
    spec_companytitle: {
      fontFamily: "Poppins",
      fontSize: "19px",
      fontWeight: 600,
      textTransform: "capitalize",
      color: themePalette.palette.secondary.textColor,
    },
    spec_historytitle: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
      textTransform: "capitalize",
      color: themePalette.palette.secondary.textColor,
    },
    spec_large_27: {
      fontFamily: "Poppins",
      fontSize: "27px",
      fontWeight: 400,
      textTransform: "capitalize",
      color: themePalette.palette.secondary.textColor,
    },
    spec_history_body: {
      fontFamily: "Poppins",
      fontSize: "12px",
      fontWeight: 400,
      color: themePalette.palette.secondary.textColor,
    },
  },
});
