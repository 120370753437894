import { ListItem, styled as MuiStyled } from "@mui/material";

export const InfoItem = MuiStyled(ListItem)(() => ({
  height: "100%",
  border: "1px solid #F0F0F0",
  borderRadius: "10px",
  padding: 16,
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  img: {
    marginRight: "16px",
  },
}));
