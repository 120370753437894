import React, { useEffect, useState } from "react";
import VisualMockup from "../VisualMockup";
import MainCard from "../../components/MainCard";
import { useParams } from "react-router-dom";
import { UseCase } from "../../components/UseCase";
import { Stack, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSpecVisualizationAsync } from "../../store/slices/userSpec";
import DataScopeTable from "../../components/DataScopeTable";
import VisualConfigPreview from "../../components/VisualConfigPreview";
import { FILTER, SEARCH, STATISTIC, TITLE } from "../../constants";
import SpecMappings from "../SpecMappings";
import { getSpecBusinessTermsAsync } from "../../store/slices/businessTerms";

function SpecPreview() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { specId } = useParams();
  const { specVisualization } = useSelector((state) => state.spec);
  const { businessTerms } = useSelector((state) => state.businessTerms);

  const [charts, setCharts] = useState([]);

  const cardBorderSX = {
    border: `1px solid #ffffff`,
    borderColor: "#dddddd",
    boxShadow: theme.palette.shadows.shadow1,
    ":hover": {
      boxShadow: theme.palette.shadows.shadow1,
    },
  };
  const dividerSX = { borderColor: "#dddddd" };

  useEffect(() => {
    dispatch(getSpecVisualizationAsync({ specId }));
    dispatch(
      getSpecBusinessTermsAsync({
        specId: specId,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (specVisualization && specVisualization.length > 0) {
      setCharts(
        specVisualization?.filter(
          (item) => ![SEARCH, TITLE, TITLE.toLowerCase()].includes(item.type)
        ) || []
      );
    }
  }, [specVisualization]);

  return (
    <Stack
      sx={(theme) => ({
        height: 1,
        width: 1,
        backgroundColor: theme.palette.gray.backgroundGray,
        overflowY: "auto",
      })}>
      <Stack sx={{ p: 2.5, pt: "20px" }} rowGap={3}>
        <UseCase />

        <DataScopeTable isPreview={true} />

        {specVisualization?.length > 0 && (
          <MainCard
            content={false}
            title='Visualization'
            sx={{
              ...cardBorderSX,
            }}
            dividerSX={dividerSX}
            titleTypographyProps={{ variant: "spec_h2" }}
            isHeaderPaddingRequired={false}>
            <VisualMockup editable={false} />
          </MainCard>
        )}
        {specVisualization?.length > 0 && (
          <Stack
            flexDirection={"row"}
            flexWrap={"wrap"}
            justifyContent={"space-between"}
            columnGap={1}
            rowGap={2}
            sx={{
              width: 1,
              flexDirection: { xs: "column", md: "row" },
              flexWrap: { xs: "nowrap", md: "wrap" },
            }}>
            {charts?.map((chart) => {
              const chartLabel = chart?.label
                ? `${chart.label} settings`
                : "Chart Settings";
              const filterLabel = chart?.params?.filterByTerm
                ? `${chart.params.filterByTerm} Settings`
                : "Filter Settings"; // or some default
              const statisticsLabel = chart?.params?.title
                ? `${chart.params.title} Settings`
                : "Statistics Setting"; // or some default

              return (
                <Stack sx={{ width: { xs: 1, md: "49%" } }}>
                  <MainCard
                    content={false}
                    title={
                      chart?.type === FILTER
                        ? filterLabel
                        : chart?.type === STATISTIC
                        ? statisticsLabel
                        : chartLabel
                    }
                    sx={{
                      ...cardBorderSX,
                    }}
                    dividerSX={dividerSX}
                    titleTypographyProps={{ variant: "spec_h2" }}
                    isHeaderPaddingRequired={false}>
                    <Stack
                      sx={{
                        height: "290px",
                      }}>
                      <VisualConfigPreview chart={chart} />
                    </Stack>
                  </MainCard>
                </Stack>
              );
            })}
          </Stack>
        )}
        {businessTerms.length > 0 && (
          <MainCard
            content={false}
            title='Mappings'
            sx={{
              ...cardBorderSX,
            }}
            dividerSX={dividerSX}
            titleTypographyProps={{ variant: "spec_h2" }}
            isHeaderPaddingRequired={false}>
            <SpecMappings isPreview={true} />
          </MainCard>
        )}
      </Stack>
    </Stack>
  );
}

export default SpecPreview;
