import {
  Drawer,
  IconButton,
  Link,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  AutoGraphOutlined,
  FormatIndentDecreaseOutlined,
  FormatIndentIncreaseOutlined,
  InventoryOutlined,
  TocRounded,
} from "@mui/icons-material";
import { ROUTE_PATH } from "../../router/routes";
import { useEffect, useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { DrawerList, closedMixin, openedMixin } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { setIsSideDrawerOpen } from "../../store/slices/userSpec";

export const SideBar = ({ isPreview = false }) => {
  const { isVisualConfigOpen } = useSelector((state) => state?.spec);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const list = [
    {
      label: "Business Requirements",
      icon: <InventoryOutlined fontSize='small' />,
      link: ROUTE_PATH.BUSINESS_REQUIREMETS,
    },
    // {
    //   label: "Mock Dataset",
    //   icon: <GridOnRounded fontSize='small' />,
    //   link: ROUTE_PATH.MOCKDATASET,
    // },
    {
      label: "Visual Mockup",
      icon: <AutoGraphOutlined fontSize='small' />,
      link: ROUTE_PATH.VISUAL_MOCKUP,
    },
    {
      label: "Mappings",
      icon: <TocRounded fontSize='small' />,
      link: ROUTE_PATH.SPEC_MAPPINGS,
    },
  ];

  const isMDUp = useMediaQuery(theme.breakpoints.up("md"));

  const isOpen = isMDUp && !isVisualConfigOpen ? open : false;

  useEffect(() => {
    dispatch(setIsSideDrawerOpen(isMDUp && !isVisualConfigOpen ? open : false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMDUp, isVisualConfigOpen, open]);

  return (
    <Drawer
      open={isOpen}
      variant='permanent'
      sx={{
        borderTop: "1px solid transparent",
        backgroundColor: "white",
        color: "balck",
      }}
      PaperProps={{
        sx: {
          position: "relative",
          zIndex: 0,
          ...(isOpen ? openedMixin(theme) : closedMixin(theme)),
        },
      }}>
      <DrawerList theme={theme}>
        {list.map((item, index) => (
          <Tooltip
            title={isOpen ? "" : item.label}
            key={index}
            arrow
            placement='right'>
            <Link
              key={index}
              className='sidebar-link'
              component={RouterLink}
              to={item.link}
              target='_self'
              underline='none'
              role={undefined}>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: "14px",
                      justifyContent: "center",
                    }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    sx={{
                      opacity: isOpen ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          </Tooltip>
        ))}
      </DrawerList>
      <Stack
        sx={(theme) => ({
          // marginTop: "auto",
          position: "fixed",
          bottom: 0,
          width: theme.spacing(6.7),
          display: isMDUp ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: "pink",
        })}>
        <IconButton onClick={isOpen ? handleDrawerClose : handleDrawerOpen}>
          {isOpen ? (
            <FormatIndentDecreaseOutlined
              fontSize='small'
              sx={(theme) => ({ color: theme.palette.gray.iconColor })}
            />
          ) : (
            <FormatIndentIncreaseOutlined
              fontSize='small'
              sx={(theme) => ({ color: theme.palette.gray.iconColor })}
            />
          )}
        </IconButton>
      </Stack>
    </Drawer>
  );
};
