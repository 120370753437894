import { useEffect, useRef } from "react";
import { getZendeskJwt } from "../../services";
import { Box, CircularProgress } from "@mui/material";

const ZendeskJwt = ({ isFeedback = false }) => {
  const formRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    getZendeskJwt().then((res) => {
      try {
        inputRef.current.value = res.data.jwt;
        formRef.current.submit();
      } catch (error) {
        console.error("Error:", error);
      }
    });
  }, []);

  return (
    <>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={{ height: "100%" }}>
        <CircularProgress />
      </Box>
      <div style={{ display: "none" }}>
        <form
          ref={formRef}
          action={`https://spectio.zendesk.com/access/jwt`}
          method='post'>
          <input ref={inputRef} type='hidden' name='jwt'></input>
          <input
            type='hidden'
            name='return_to'
            value={
              isFeedback
                ? "https://spectio.zendesk.com/hc/en-us/requests/new"
                : "https://spectio.zendesk.com/hc/en-us"
            }
          />
        </form>
      </div>
    </>
  );
};

export default ZendeskJwt;
