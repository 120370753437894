export const StackedColumnIcon = () => {
  return (
    <svg
      width='100%'
      height='100%'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 60 61'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g id='chart icon / stacked column chart'>
        <g id='Group 10'>
          <g id='Group 7'>
            <rect
              id='Rectangle 14'
              x='22.0503'
              y='15.8933'
              width='5.85'
              height='14.784'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 17'
              x='22.0503'
              y='13.2053'
              width='5.85'
              height='4.8'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 15'
              x='22.0503'
              y='30.6772'
              width='5.85'
              height='9.856'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 16'
              x='22.0503'
              y='40.5332'
              width='5.85'
              height='6.272'
              rx='0.5'
              fill='#808080'
            />
          </g>
          <g id='Group 6'>
            <rect
              id='Rectangle 14_2'
              x='40.9502'
              y='20.3733'
              width='5.85'
              height='7.616'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 18'
              x='40.9502'
              y='16.7893'
              width='5.85'
              height='4.8'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 15_2'
              x='40.9502'
              y='27.9893'
              width='5.85'
              height='8.4'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 17_2'
              x='40.9502'
              y='35.6052'
              width='5.85'
              height='5.824'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 16_2'
              x='40.9502'
              y='41.4292'
              width='5.85'
              height='5.376'
              rx='0.5'
              fill='#808080'
            />
          </g>
          <g id='Group 8'>
            <rect
              id='Rectangle 14_3'
              x='31.5'
              y='26.6453'
              width='5.85'
              height='7.616'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 18_2'
              x='31.5'
              y='23.9573'
              width='5.85'
              height='4.8'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 17_3'
              x='31.5'
              y='38.2798'
              width='5.85'
              height='4.43319'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 19'
              x='31.5'
              y='31.5732'
              width='5.85'
              height='8.4'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 16_3'
              x='31.5'
              y='42.7131'
              width='5.85'
              height='4.09218'
              rx='0.5'
              fill='#808080'
            />
          </g>
          <g id='Group 9'>
            <rect
              id='Rectangle 14_4'
              x='12.6001'
              y='30.5981'
              width='5.85'
              height='6.12267'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 18_3'
              x='12.6001'
              y='28.4373'
              width='5.85'
              height='4.8'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 17_4'
              x='12.6001'
              y='39.9517'
              width='5.85'
              height='3.56394'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 19_2'
              x='12.6001'
              y='34.5598'
              width='5.85'
              height='8.4'
              rx='0.5'
              fill='#808080'
            />
            <rect
              id='Rectangle 16_4'
              x='12.6001'
              y='43.5156'
              width='5.85'
              height='3.28979'
              rx='0.5'
              fill='#808080'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
