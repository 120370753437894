import { forwardRef } from "react";

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { specPrimary } from "../../constants/theme/colors";

// header style
const defaultHeaderSX = {
  p: 1,
  pl: 2,
  width: 1,
  fontSize: "97px",
  "& .MuiCardHeader-action": { m: "0px auto", alignSelf: "center" },
};

// ==============================|| CUSTOM - MAIN CARD ||============================== //

// eslint-disable-next-line react/display-name
const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentSX = {},
      headerSX = defaultHeaderSX,
      darkTitle,
      id,
      divider = true,
      titleTypographyProps,
      elevation,
      secondary,
      shadow,
      titleRightProps,
      sx = {},
      title,
      isHeaderPaddingRequired = true,
      dividerSX,
      type = "others",
      cardSelected = false,
      ...others
    },
    ref
  ) => {
    const theme = useTheme();
    boxShadow = theme.palette.mode === "dark" ? boxShadow || true : boxShadow;

    return (
      <Card
        data-testid={"main-card"}
        elevation={elevation || 0}
        ref={ref}
        {...others}
        sx={{
          border: border ? "1px solid" : cardSelected ? "2px solid" : "none",
          borderRadius: 2,
          borderColor: !cardSelected
            ? theme.palette.grey[200]
            : specPrimary[700],
          boxShadow:
            boxShadow && (!border || theme.palette.mode === "dark")
              ? shadow || theme?.customShadows?.z1
              : "inherit",
          ":hover": {
            boxShadow: boxShadow
              ? shadow || theme?.customShadows?.z1
              : "inherit",
          },
          "& pre": {
            m: 0,
            p: "16px !important",
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.75rem",
          },
          ...sx,
        }}
        id={id}>
        {/* card header and action */}
        {!darkTitle && type === "others" && (
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            mb={isHeaderPaddingRequired ? 0.5 : 0}>
            <CardHeader
              sx={headerSX}
              // className="cancelDraggableElement"
              titleTypographyProps={{
                variant: "spec_h3",
                ...titleTypographyProps,
              }}
              title={title?.trim().length > 0 ? title : "No Title"}
              action={secondary}
            />
            {titleRightProps?.customComponent && (
              <Box sx={{ marginRight: "10px" }}>
                {titleRightProps?.customComponent}
              </Box>
            )}
          </Box>
        )}

        {darkTitle && (
          <CardHeader
            className='handleDraggableElement'
            sx={headerSX}
            title={<Typography variant='spec_h3'>{title}</Typography>}
            action={secondary}
          />
        )}
        {/* content & header divider */}
        {divider && <Divider sx={dividerSX} />}
        {/* card content */}
        {content && <CardContent sx={contentSX}>{children}</CardContent>}
        {!content && children}
      </Card>
    );
  }
);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  divider: PropTypes.bool,
  elevation: PropTypes.number,
  secondary: PropTypes.node,
  shadow: PropTypes.func,
  sx: PropTypes.object,
  title: PropTypes.string,
  content: PropTypes.bool,
  children: PropTypes.node,
};

export default MainCard;
