import React from "react";
import Drawer from "@mui/material/Drawer";
import { useSelector } from "react-redux";

const SideDrawer = ({ open, onClose, width, children }) => {
  const { announcements } = useSelector((state) => state.announcements);

  return (
    <div>
      <Drawer
        variant='temporary'
        anchor={"right"}
        open={open}
        onClose={onClose}
        // hideBackdrop
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
          "& .MuiPaper-root": {
            width: width,
            zIndex: -1,
            top: announcements?.length > 0 ? "130px" : "90px",
            height:
              announcements?.length > 0
                ? "calc(100vh - 130px)"
                : "calc(100vh - 90px)",
            boxShadow: "none",
            border: "1px solid #efefef",
          },
          "&.MuiDrawer-root": {
            zIndex: 1,
          },
        }}>
        {children}
      </Drawer>
    </div>
  );
};
export default SideDrawer;
