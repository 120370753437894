import { StyledPriorityChip, StyledStatusChip } from "./style";
import MenuItem from "../../common/MenuItem";
import { useParams } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ExpandMoreRoundedIcon } from "../../assets";
import { updateSpecification } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import CustomPopover from "../../common/CustomPopover";
import { specSecondary } from "../../constants/theme/colors";
import { getStatusesAsync } from "../../store/slices/statuses";
import StatusMenuSkeleton from "../../common/Skeletons/StatusMenuSkeleton";
import { getPrioritiesAsync } from "../../store/slices/priorities";

const PriorityChip = () => {
  const { specId } = useParams();
  const { specData } = useSelector((state) => state.spec);
  const { priorities, isPrioritiesLoading } = useSelector(
    (state) => state.priorities
  );
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [currentSpecPriority, setCurrentSpecPriority] = useState(
    specData?.priority ? specData?.priority : "Select Priority"
  );

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdatePriority = (priority) => {
    setCurrentSpecPriority(priority);
    handleClose();

    const payload = {
      specification: {
        priority_id: priority?.id,
      },
    };

    updateSpecification({
      payload: payload,
      id: specId,
    }).then((response) => {
      // DO SOMETHING ON STATUS UPDATE SUCCESS - MOST PROBABLY UPDATE SAVED STATUS
    });
  };

  useEffect(() => {
    if (open) {
      dispatch(getPrioritiesAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <StyledPriorityChip
        // CHIP LABEL
        label={
          <Typography
            variant='spec_body'
            sx={(theme) => ({
              color: theme.palette.info.main,
            })}>
            {currentSpecPriority?.name
              ? currentSpecPriority?.name
              : "Select Priority"}
          </Typography>
        }
        // CHIP ARROW ICON
        deleteIcon={
          <ExpandMoreRoundedIcon
            sx={(theme) => ({
              color: `${theme.palette.info.main} !important`,
            })}
          />
        }
        // NEEDED TO DISPLAY ARROW ICON
        onDelete={(e) => handleClick(e)}
        onClick={(e) => handleClick(e)}
      />
      <CustomPopover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {isPrioritiesLoading ? (
          <StatusMenuSkeleton />
        ) : (
          <Stack rowGap={1}>
            {priorities?.length > 0 ? (
              priorities?.map((priority, index) => (
                <MenuItem
                  key={index}
                  itemText={priority?.name}
                  onClick={() => handleUpdatePriority(priority)}
                  itemProps={{
                    sx: {
                      height: "12px",
                      backgroundColor:
                        priority?.id === currentSpecPriority?.id
                          ? specSecondary[50]
                          : "",
                      ":hover": {
                        backgroundColor: specSecondary[50],
                      },
                    },
                  }}
                />
              ))
            ) : (
              <Typography
                variant='spec_h3'
                color={"error.main"}
                textAlign={"center"}>
                No Priorities Available!
              </Typography>
            )}
          </Stack>
        )}
      </CustomPopover>
    </>
  );
};

export default PriorityChip;
