import React, { useState } from "react";
import DeleteConfirmationModal from "../../common/DeleteConfirmationModal";
import { CircularProgress, Stack } from "@mui/material";
import { SquareIconButton } from "../../common/commonStyle";
import {
  BorderColorOutlined,
  DeleteOutline,
  MoreHorizRounded,
  Send,
} from "@mui/icons-material";
import CustomPopover from "../../common/CustomPopover";
import MenuItem from "../../common/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser } from "../../services";
import { HTTP_CREATED, HTTP_OK } from "../../constants/statusCodes";
import { defaultMessage } from "../../constants/messages";
import { toaster } from "../../common/Toaster";
import { getUsersAsync } from "../../store/slices/userManagement";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../router/routes";
import { USER_STATUS } from "../../constants";
import { inviteUserUrl } from "../../utils/urls";
import axiosInstance from "../../axios";

const ActionCell = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.userAuth);

  const [anchorEl, setAnchorEl] = useState(null);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleDelete = () => {
    setDeleteLoading(true);
    deleteUser({
      userId: props?.data?.id,
    })
      .then((response) => {
        setDeleteLoading(false);
        if (response?.status !== HTTP_OK) {
          throw new Error(defaultMessage);
        }

        dispatch(getUsersAsync());

        toaster({
          type: "success",
          message:
            response?.data?.toaster?.data?.message ||
            "User was successfully deleted.",
        });
      })
      .catch((error) => {
        setDeleteLoading(false);
        toaster({ type: "error", error });
      });
  };

  const resendInvite = () => {
    const payload = {
      email: props?.data?.email,
      role_name: props?.data?.role,
      company_id: props?.data?.companyId, //current admin id
      owner: false, //always false
    };
    setResendLoading(true);

    axiosInstance
      .post(inviteUserUrl, payload)
      .then((response) => {
        if (response?.status === HTTP_OK || response?.status === HTTP_CREATED) {
          toaster({
            type: "success",
            message: response?.data?.toaster?.data?.message,
          });
          setResendLoading(false);

          handleClose();
        }
      })
      .catch((error) => {
        setResendLoading(false);
        toaster({
          type: "success",
          message: error?.response?.data?.toaster?.data?.message,
        });
      });
  };

  return (
    <>
      <Stack
        width={1}
        height={1}
        justifyContent={"center"}
        alignItems={"center"}>
        <SquareIconButton onClick={handleClick}>
          <MoreHorizRounded />
        </SquareIconButton>
      </Stack>
      <CustomPopover open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem
          Icon={<BorderColorOutlined fontSize='small' />}
          itemText={"Edit"}
          itemProps={{ height: "15px" }}
          onClick={() => {
            handleClose();
            navigate(`${ROUTE_PATH.USER_MGT}/${props?.data?.id}/edit`);
          }}
        />
        {props?.data?.id !== user?.id && (
          <MenuItem
            Icon={<DeleteOutline fontSize='small' />}
            itemText={"Delete"}
            itemProps={{ height: "15px" }}
            onClick={() => {
              handleClose();
              setIsDeleteOpen(true);
            }}
          />
        )}

        {(props?.data?.status === USER_STATUS.INVITED ||
          props?.data?.status === USER_STATUS.EXPIRED) && (
          <MenuItem
            Icon={
              resendLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Send fontSize='small' />
              )
            }
            itemText={"Resend Invite"}
            itemProps={{ height: "15px" }}
            onClick={() => {
              if (resendLoading) return;
              resendInvite();
            }}
          />
        )}
      </CustomPopover>
      <DeleteConfirmationModal
        loading={deleteLoading}
        isOpen={isDeleteOpen}
        message={`Are you sure you want delete this user?`}
        description='This action can not be undone!'
        handleDelete={handleDelete}
        handleClose={() => setIsDeleteOpen(false)}
      />
    </>
  );
};

export default ActionCell;
