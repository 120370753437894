import { Avatar, Stack, Typography } from "@mui/material";
import { stringAvatar } from "../../utils/commonUtility";
import { specSecondary } from "../../constants/theme/colors";

const AvatarWithLabelCell = ({ allowEmptyAvatar = false, ...props }) => {
  return (
    <Stack direction='row' spacing={1.5} alignItems='center' width={1}>
      {props?.value && (
        <Avatar
          sx={(theme) => ({
            bgcolor: specSecondary[50],
            height: "35px",
            width: "35px",
          })}>
          <Typography variant='spec_body' color={"info.main"}>
            {stringAvatar(props?.value)?.children}
          </Typography>
        </Avatar>
      )}

      {allowEmptyAvatar && !props?.value && (
        <Avatar
          sx={(theme) => ({
            bgcolor: specSecondary[50],
            height: "35px",
            width: "35px",
          })}></Avatar>
      )}

      <Typography
        variant={"spec_body"}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}>
        {props?.value ? props?.value : "Not Specified"}
      </Typography>
    </Stack>
  );
};
export default AvatarWithLabelCell;
