import { styled as MuiStyled } from "@mui/material/styles";

export const LoaderWrapper = MuiStyled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 2001,
  width: "100%",
  "& > * + *": {
    marginTop: theme.spacing(2),
  },
}));
