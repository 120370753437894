import {
  PersonOutlineIcon,
  LogoutOutlinedIcon,
  SettingsOutlinedIcon,
  PeopleAltOutlinedIcon,
  DescriptionOutlinedIcon,
  ExpandCircleDownOutlinedIcon,
} from "../../assets";
import Cookies from "js-cookie";
import {
  ErrorLogoutMessage,
  LogoutConfirmationMessage,
} from "../../constants/messages";
import React, { useState } from "react";
import axiosInstance from "../../axios";
import { persistor } from "../../store";
import { useSelector } from "react-redux";
import MenuItem from "../../common/MenuItem";
import BaseModal from "../../common/BaseModal";
import { toaster } from "../../common/Toaster";
import { useNavigate } from "react-router-dom";
import storage from "redux-persist/lib/storage";
import { ROUTE_PATH } from "../../router/routes";
import { StyledProfileMenuButton } from "./style";
import { authenticationUrl } from "../../utils/urls";
import CustomPopover from "../../common/CustomPopover";
import { Avatar, Typography, alpha } from "@mui/material";
import { stringAvatar } from "../../utils/commonUtility";
import { specSecondary } from "../../constants/theme/colors";
import { ROLES } from "../../constants";

const ProfileMenu = () => {
  const navigate = useNavigate();
  const { user, currentCompany } = useSelector((state) => state.userAuth);

  const [anchorEl, setAnchorEl] = useState(null);
  const [logoutAnchorEl, setLogoutAnchorEl] = useState(null);
  const [logoutLoading, setLogoutLoading] = useState(false);

  const open = Boolean(anchorEl);
  const isLogoutOpen = Boolean(logoutAnchorEl);
  const firstName = user?.firstName ? user?.firstName : "";
  const lastName = user?.lastName ? user?.lastName : "";

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClickLogout = (e) => {
    handleClose(null);
    setLogoutAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseLogout = (e) => {
    setLogoutAnchorEl(null);
  };

  const handleLogout = async () => {
    setLogoutLoading(true);
    axiosInstance
      .delete(authenticationUrl)
      .then((response) => {
        setLogoutLoading(false);
        Cookies.remove("token", { domain: `${process.env.REACT_APP_DOMAIN}` });
        Cookies.remove("subdomain", {
          domain: `${process.env.REACT_APP_DOMAIN}`,
        });
        persistor.purge();
        storage.removeItem("persist:root");
        localStorage.clear();
        window.location.replace(process.env.REACT_APP_MAIN_DOMAIN);
      })
      .catch((error) => {
        setLogoutLoading(false);
        toaster("error", ErrorLogoutMessage);
      });
  };

  // Profile menu options array
  const menus = [
    {
      id: 1,
      label: "Manage Account",
      icon: <PersonOutlineIcon fontSize='small' />,
      operation: () => {
        navigate(ROUTE_PATH.MY_PROFILE);
        handleClose();
      },
    },
    {
      id: 2,
      label: "User Management",
      icon: <PeopleAltOutlinedIcon fontSize='small' />,
      operation: () => {
        navigate(ROUTE_PATH.USER_MGT);
        handleClose();
      },
    },
    {
      id: 3,
      label: "Billing",
      icon: <DescriptionOutlinedIcon fontSize='small' />,
      operation: () => {
        navigate(ROUTE_PATH.BILLING);
        handleClose();
      },
    },
    {
      id: 4,
      label: "Data Settings",
      icon: <SettingsOutlinedIcon fontSize='small' />,
      operation: () => {
        navigate(ROUTE_PATH.DATA_SCOPE);
        handleClose();
      },
    },
    {
      id: 5,
      label: "Logout",
      icon: <LogoutOutlinedIcon fontSize='small' />,
      operation: (e) => handleClickLogout(e),
    },
  ];

  const filteredMenus = menus.filter((action) => {
    if (!currentCompany?.companyOwner && action.id === 3) {
      return false;
    }

    if (currentCompany?.role !== ROLES.ADMIN && action.id === 2) {
      return false;
    }

    if (currentCompany?.role !== ROLES.ADMIN && action.id === 4) {
      return false;
    }

    return true; // Include all other menu items
  });

  return (
    <>
      <StyledProfileMenuButton
        onClick={handleClick}
        endIcon={<ExpandCircleDownOutlinedIcon />}
        sx={{ display: { xs: "none", sm: "flex" } }}>
        <Avatar
          sx={(theme) => ({
            bgcolor: specSecondary[50],
            height: "30px",
            width: "30px",
          })}>
          <Typography variant='spec_body' color={"secondary.main"}>
            {stringAvatar(`${firstName} ${lastName}`)?.children}
          </Typography>
        </Avatar>
        <Typography
          variant='spec_body'
          sx={{
            display: { xs: "none", sm: "flex" },
            textTransform: "capitalize",
          }}>
          {firstName} {lastName}
        </Typography>
      </StyledProfileMenuButton>

      <Avatar
        sx={(theme) => ({
          bgcolor: specSecondary[50],
          height: "28px",
          width: "28px",
          display: { xs: "flex", sm: "none" },
        })}
        onClick={handleClick}>
        <Typography variant='spec_h3' color={"secondary.main"}>
          {stringAvatar(`${firstName} ${lastName}`)?.children}
        </Typography>
      </Avatar>

      {/* Profile menu popover */}
      <CustomPopover open={open} anchorEl={anchorEl} onClose={handleClose}>
        {filteredMenus?.map((item, i) => (
          <MenuItem
            key={i}
            Icon={item?.icon}
            itemText={item?.label}
            itemProps={{ height: "15px" }}
            onClick={item?.operation}
          />
        ))}
      </CustomPopover>

      {/* Logout Modal */}
      <BaseModal
        isOpen={isLogoutOpen}
        handleClose={handleCloseLogout}
        confirmTitle={"Yes, Confirm"}
        title={"Logout"}
        handleSubmit={handleLogout}
        isLoading={logoutLoading}>
        <Typography variant='spec_body'>{LogoutConfirmationMessage}</Typography>
      </BaseModal>
    </>
  );
};

export default ProfileMenu;
