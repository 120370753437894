import ActionCell from "./ActionCell";

const commonCellStyle = { wordBreak: "normal", lineHeight: "normal" };
export const getFunctionsSettingsColumns = () => {
  return [
    {
      field: "title",
      editable: false,
      headerName: "Title",
      resizable: false,
      cellDataType: "text",
      flex: 1,
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      cellStyle: commonCellStyle,
      rowDrag: true,
    },
    {
      field: "values",
      editable: false,
      headerName: "Values",
      resizable: false,
      cellDataType: "text",
      flex: 1,
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      sortable: true,
    },
    {
      field: "options",
      editable: false,
      headerName: "Options",
      resizable: false,
      cellDataType: "text",
      flex: 1,
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      cellRenderer: ActionCell,
    },
  ];
};
