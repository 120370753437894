import _ from "lodash";
import { Field, Form, Formik } from "formik";
import { CheckRounded } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import { cellEditValidationSchema } from "../../../utils/validationSchemas";

export default memo(
  ({ value, stopEditing, onValueChange, eventKey, ...params }) => {
    // refs FOR INPUT & FORM
    const refInput = useRef(null);
    const formRef = useRef(null);

    // const [isChanged, setIsChanged] = useState(false);

    // MAKE A LOCAL COPY OF VALUE AND MAKE CHANGES ON IT
    const initialValues = {
      cellValue: !value || value === null ? "" : value,
    };

    useEffect(() => {
      let startValue = value || "";

      // if (eventKey === "Backspace") {
      //   startValue = "";
      // } else if (eventKey && eventKey?.length === 1) {
      //   startValue = eventKey;
      // } else {
      //   startValue = value;
      // }
      // if (startValue == null) {
      //   startValue = "";
      // }

      // if (eventKey) {
      //   setIsChanged(true);
      // }

      formRef?.current?.setFieldValue("cellValue", startValue);

      // USED TO ADD AUTO FOCUS TO INPUT FIELD (NOW OPTIONAL AS TEXTFIELD NOW HAVE autoFocus PROP)
      window.setTimeout(() => {
        const eInput = refInput?.current;
        eInput?.focus();
        eInput?.select();
      });
    }, []);

    const onSubmit = (values, { resetForm, validateForm }) => {
      // UPDATES THE CELL VALUE ON AG-GRID
      onValueChange(values?.cellValue);

      resetForm({ values: initialValues });
      // THIS TRIGGERS onCellEditingStopped ON AG-GRID
      stopEditing(true);
    };

    // TRIGGER FORMIK ONSUBMIT (trigger this same function if on enter save functionalty is requested by client)
    const handleSubmitClick = () => {
      if (formRef.current) {
        formRef.current.handleSubmit();
      }
    };

    // FORMIK HANDLE CHANGE
    const handleChange = (field, value) => {
      // if (isChanged) {
      field.onChange({
        target: {
          name: field.name,
          value: value,
        },
      });
      // } else {
      //   setIsChanged(true);
      //   field.onChange({
      //     target: {
      //       name: field.name,
      //       value: value?.slice(-1)?.trim()?.length > 0 ? value?.slice(-1) : "",
      //     },
      //   });
      // }
    };

    return (
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={true}
        validateOnMount={true}
        validateOnChange={true}
        enableReinitialize={true}
        initialTouched={{ cellValue: true }} //INITIALLY TOUCHED TRUE FOR cellValue
        validationSchema={cellEditValidationSchema} //VALIDATIONS FOR cellValue
      >
        {({ touched, errors, values, isValid, resetForm, validateForm }) => (
          <Form>
            <Field name='cellValue'>
              {({ field, form }) => (
                <TextField
                  name='cellValue'
                  autoFocus
                  placeholder='Enter cell value'
                  variant='outlined'
                  sx={{ backgroundColor: "white", borderRadius: "7px" }}
                  inputRef={refInput}
                  onKeyDownCapture={(e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                      handleSubmitClick();
                    }
                  }}
                  value={field.value}
                  onChange={({ currentTarget: input }) => {
                    handleChange(field, input.value);
                  }}
                  inputProps={{
                    form: {
                      autocomplete: "off",
                    },
                    autocomplete: "off",
                  }}
                  InputProps={{
                    endAdornment:
                      (errors && Object.keys(errors)?.length !== 0) ||
                      _.isEqual(initialValues, values) ? (
                        <IconButton
                          disableRipple
                          sx={{ cursor: "not-allowed", p: 0 }}
                          onClick={() => {}}>
                          <CheckRounded color='gray.main' />
                        </IconButton>
                      ) : (
                        <IconButton
                          sx={{ cursor: "pointer", p: 0 }}
                          onClick={handleSubmitClick}>
                          <CheckRounded color='success' />
                        </IconButton>
                      ),
                  }}
                  error={
                    form.touched.cellValue && Boolean(form.errors.cellValue)
                  }
                  helperText={form.touched.cellValue && form.errors.cellValue}
                />
              )}
            </Field>
          </Form>
        )}
      </Formik>
    );
  }
);
