import { useEffect, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import BaseModal from "../BaseModal";

const InfoPopup = ({ tab, settingPage = false, isMockDataSet = false }) => {
  const theme = useTheme();
  const [buttonText, setButtonText] = useState("Column Definitions");
  const [headingText, setHeadingText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isMappingDefinitions, setIsMappingDefinitions] = useState(true);

  const setMappingDefinitions = () => {
    setButtonText("Column Definitions");
    setIsMappingDefinitions(true);
    setHeadingText(
      settingPage
        ? "Use this page to add up to five columns which may be used to characterize and define labels for mock datasets. Users may also choose to customize “Category” options. See default column definitions below:"
        : "Use this tab to map mock dataset labels to real attributes in external datasets, and edit mock dataset labels and metadata. See default column definitions below:"
    );
  };

  const setDataScopeDefinitions = () => {
    setButtonText("Data Scope Definitions");
    setIsMappingDefinitions(false);
    setHeadingText(
      settingPage
        ? "Use this section to add Data Scopes which will allow editors to define the scope of data in each spec. Examples may include data refresh cadence, historical date ranges, or PII permissions. Admins may specify permitted values for each Data Scope. See column definitions below:"
        : ""
    );
  };

  useEffect(() => {
    // switch (tab) {
    //   case 0:
    //     setMappingDefinitions();
    //     break;
    //   case 1:
    //     setMappingDefinitions();
    //     break;
    //   case 2:
    //     setDataScopeDefinitions();
    //     break;
    //   default:
    //     setMappingDefinitions();
    // }
    if (tab === 2) {
      setDataScopeDefinitions();
    } else {
      setMappingDefinitions();
    }
  }, [tab]);

  return (
    <>
      <Button
        size='small'
        variant='contained'
        onClick={() => setIsOpen(true)}
        {...(InfoOutlinedIcon && {
          startIcon: (
            <InfoOutlinedIcon
              style={{ fontSize: "15px", color: theme.palette.info.main }}
            />
          ),
        })}
        sx={{
          minWidth: "fit-content",
          backgroundColor: "#FFFFFF",
          "&:hover": {
            backgroundColor: "#FFFFFF",
          },
        }}>
        <Box
          component='span'
          sx={{
            fontSize: "14px",
            lineHeight: "14px",
            color: theme.palette.info.main,
          }}>
          {buttonText}
        </Box>
      </Button>
      <BaseModal
        title={buttonText}
        dialogAction={<></>}
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        dialogWidth={theme.breakpoints.values.sm ? "90%" : "425px"}>
        <Stack rowGap={1}>
          <Typography
            variant='spec_button'
            sx={{
              fontSize: "0.9rem",
              wordBreak: "break-word",
              textTransform: "none",
            }}>
            {headingText}
          </Typography>
          {isMappingDefinitions ? (
            <TableContainer>
              <Table
                sx={{
                  minWidth: 350,
                  border: "1px solid #ddd",
                  borderRadius: "10px",
                }}
                aria-label='simple table'>
                <TableBody sx={{ background: theme.palette.common.white }}>
                  {/* <TableRow>
                    <TableCell sx={{ py: 1 }}>Derived</TableCell>
                    <TableCell sx={{ py: 1, fontWeight: 400 }}>
                      Indicates a label that was created using other labels
                    </TableCell>
                  </TableRow> */}
                  <TableRow>
                    <TableCell sx={{ py: 1 }}>
                      <Typography variant='spec_button'>Term</Typography>
                    </TableCell>
                    <TableCell sx={{ py: 1, fontWeight: 400 }}>
                      <Typography variant='spec_body'>
                        User input column name
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ py: 1 }}>
                      <Typography variant='spec_button'>Specs</Typography>
                    </TableCell>
                    <TableCell sx={{ py: 1, fontWeight: 400 }}>
                      <Typography variant='spec_body'>
                        Number of specs that include the label
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ py: 1 }}>
                      <Typography variant='spec_button'>Description</Typography>
                    </TableCell>
                    <TableCell sx={{ py: 1, fontWeight: 400 }}>
                      <Typography variant='spec_body'>
                        User input column description
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ py: 1 }}>
                      <Typography variant='spec_button'>Attribute</Typography>
                    </TableCell>
                    <TableCell sx={{ py: 1, fontWeight: 400 }}>
                      <Typography variant='spec_body'>
                        Corresponding field in real dataset
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ py: 1 }}>
                      <Typography variant='spec_button'>Prompt</Typography>
                    </TableCell>
                    <TableCell sx={{ py: 1, fontWeight: 400 }}>
                      <Typography variant='spec_body'>
                        AI prompt used to generate mock data
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ py: 1 }}>
                      <Typography variant='spec_button'>Sample</Typography>
                    </TableCell>
                    <TableCell sx={{ py: 1, fontWeight: 400 }}>
                      <Typography variant='spec_body'>
                        Sample from AI generated mock data
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ py: 1 }}>
                      <Typography variant='spec_button'>Category</Typography>
                    </TableCell>
                    <TableCell sx={{ py: 1, fontWeight: 400 }}>
                      <Typography variant='spec_body'>
                        User defined label category
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {isMockDataSet && (
                    <TableRow>
                      <TableCell sx={{ py: 1 }}>
                        <Typography variant='spec_button'>Status</Typography>
                      </TableCell>
                      <TableCell sx={{ py: 1, fontWeight: 400 }}>
                        <Typography variant='spec_body'>
                          Proposed, approved, or declined
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer>
              <Table
                sx={{ minWidth: 350, border: "1px solid #ddd" }}
                aria-label='simple table'>
                <TableBody sx={{ background: theme.palette.common.white }}>
                  {/* {settingPage && (
                    <TableRow>
                      <TableCell sx={{ py: 1 }}>
                        <Typography variant='spec_button'>
                          Specs Count
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ py: 1, fontWeight: 400 }}>
                        <Typography variant='spec_body'>
                          Number of specs that include the data scope
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )} */}
                  <TableRow>
                    <TableCell sx={{ py: 1 }}>
                      <Typography variant='spec_button'>Data Scope</Typography>
                    </TableCell>
                    <TableCell sx={{ py: 1, fontWeight: 400 }}>
                      <Typography variant='spec_body'>
                        Name of data scope
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ py: 1 }}>
                      <Typography variant='spec_button'>Values</Typography>
                    </TableCell>
                    <TableCell sx={{ py: 1, fontWeight: 400 }}>
                      <Typography variant='spec_body'>
                        Permitted values available to spec editors when
                        specifying data scope
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ py: 1 }}>
                      <Typography variant='spec_button'>Category</Typography>
                    </TableCell>
                    <TableCell sx={{ py: 1, fontWeight: 400 }}>
                      <Typography variant='spec_body'>
                        Category of data scope
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ py: 1 }}>
                      <Typography variant='spec_button'>Description</Typography>
                    </TableCell>
                    <TableCell sx={{ py: 1, fontWeight: 400 }}>
                      <Typography variant='spec_body'>
                        Additional information to clarify data scope definition
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ py: 1 }}>
                      <Typography variant='spec_button'>Required</Typography>
                    </TableCell>
                    <TableCell sx={{ py: 1, fontWeight: 400 }}>
                      <Typography variant='spec_body'>
                        Indicates if the data scope is required or optional
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Stack>
      </BaseModal>
    </>
  );
};

export default InfoPopup;
