import { Chip } from "@mui/material";
import PropTypes from "prop-types";
import { COMPANY_STATUS, USER_STATUS } from "../../constants";

export const ChipStatus = ({ status, variant, size }) => {
  let color;
  switch (status) {
    case USER_STATUS.ACCEPTED:
    case COMPANY_STATUS.ACTIVE: {
      color = "success";
      break;
    }
    case USER_STATUS.EXPIRED: {
      color = "error";
      break;
    }
    case USER_STATUS.DEACTIVATED: {
      color = "secondary";
      break;
    }
    case COMPANY_STATUS.INVITED: {
      color = "warning";
      break;
    }
    default: {
      color = "info";
    }
  }

  return (
    <Chip
      size={size ?? "small"}
      variant={variant ?? "outlined"}
      color={color}
      label={status}
      sx={{
        fontFamily: "Poppins",
        cursor: "pointer",
        borderRadius: "100px",
        textTransform: "capitalize",
      }}
    />
  );
};

ChipStatus.propTypes = {
  status: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
};
