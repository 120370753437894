import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

const ColumnChart = ({ chartData, xTitle, yTitle, metric }) => {
  const chartRef = useRef(null);
  const containerRef = useRef(null);
  const reduxState = useSelector((state) => state?.spec);
  const { isVisualConfigOpen, selectedChart } = useSelector(
    (state) => state?.spec
  );

  const drawColumnChart = () => {
    if (!window.google) return; // Make sure google is loaded
    const r = (Math.random() + 1).toString(36).substring(7);

    const createData = chartData[0].dataForChart.map((d) => [
      d.id,
      d.value,
      createCustomHTMLTooltip(d.id, d.value, chartData[0]?.symbol),
    ]);

    function drawColColors() {
      const data = new window.google.visualization.DataTable();
      data.addColumn("string", xTitle);
      data.addColumn("number", yTitle);
      data.addColumn({ type: "string", role: "tooltip", p: { html: true } });

      data.addRows(createData);

      const options = {
        title: "",
        colors: ["#F1E15B"],
        tooltip: { isHtml: true },
        legend: { position: "none" },
        height: containerRef.current?.clientHeight,
        chartArea: {
          height: containerRef.current?.clientHeight - 80,
          top: "2%",
        },
        theme: "material",
        hAxis: {
          title: chartData[0]?.xasix,
          textStyle: { fontSize: 12, fontName: "Poppins" },
          titleTextStyle: {
            italic: false,
            fontSize: 12,
            fontName: "Poppins-Bold",
          },
        },
        vAxis: {
          format: "short",
          title:
            chartData[0]?.symbol &&
            (chartData[0]?.symbol === "$" || chartData[0]?.symbol === "%")
              ? `${chartData[0].yaxis} (${metric} in ${chartData[0].symbol})`
              : `${chartData[0].yaxis} (${chartData[0]?.metric})`,
          textStyle: { fontSize: 12, fontName: "Poppins" },
          titleTextStyle: {
            italic: false,
            fontSize: 12,
            fontName: "Poppins-Bold",
          },
        },
      };

      const chart = new window.google.visualization.ColumnChart(
        chartRef.current
      );

      chart.draw(data, options);
    }
    window.google.charts.setOnLoadCallback(drawColColors);
  };

  useEffect(() => {
    const renderChart = () => {
      setTimeout(() => {
        drawColumnChart();
      }, 150);
    };

    renderChart();
  }, [
    containerRef.current?.clientHeight,
    containerRef.current?.clientWidth,
    chartData,
  ]);

  function createCustomHTMLTooltip(key, value, symbol) {
    const fixedValue = Number(value?.toFixed(2));
    const newValue = fixedValue?.toLocaleString("en-US");

    return (
      '<div style="padding:5px;">' +
      `<strong>${xTitle}: </strong>` +
      key +
      "<br/>" +
      `<strong>${yTitle}: </strong>` +
      `${
        symbol === "$"
          ? "$" + newValue
          : symbol === "%"
          ? newValue + "%"
          : newValue
      }` +
      "</div>"
    );
  }
  return (
    <div
      ref={containerRef}
      style={{ height: "100%" }}
      className='cancelDraggableElement'>
      <div ref={chartRef} style={{ marginBottom: "20px" }}></div>
    </div>
  );
};

export default ColumnChart;
