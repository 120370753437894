import InvoiceLinkCell from "./InvoiceLinkCell";
import InvoiceNumberCell from "./InvoiceNumberCell";

const commonCellStyle = { wordBreak: "break-word", lineHeight: "normal" };
export const getBillingColumns = () => {
  return [
    {
      field: "invoiceNumber",
      editable: false,
      headerName: "Invoice Number",
      resizable: false,
      unSortIcon: true,
      cellDataType: "text",
      flex: 1,
      width: 200,
      pinned: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
      cellRenderer: InvoiceNumberCell,
    },
    {
      field: "stripePaymentUrl",
      editable: false,
      headerName: "Invoice Link",
      resizable: false,
      cellDataType: "text",
      minWidth: 200,
      sortable: true,
      flex: 1,
      cellRenderer: InvoiceLinkCell,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
    },
    {
      field: "seats",
      editable: false,
      headerName: "Seats",
      resizable: false,
      cellDataType: "text",
      minWidth: 200,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: { ...commonCellStyle, justifyContent: "flex-end" },
    },
    {
      field: "totalCost",
      editable: false,
      headerName: "Total Cost",
      resizable: false,
      cellDataType: "text",

      minWidth: 200,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      type: "text",

      cellStyle: { ...commonCellStyle, justifyContent: "flex-end" },
    },
    {
      field: "startDate",
      editable: false,
      headerName: "Start Date",
      resizable: false,
      cellDataType: "text",
      minWidth: 250,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
    },
    {
      field: "endDate",
      editable: false,
      headerName: "End Date",
      resizable: false,
      cellDataType: "text",
      minWidth: 250,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
    },
    {
      field: "firstName",
      editable: false,
      headerName: "First Name",
      resizable: false,
      cellDataType: "text",
      minWidth: 200,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
    },
    {
      field: "lastName",
      editable: false,
      headerName: "Last Name",
      resizable: false,
      cellDataType: "text",
      minWidth: 200,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
    },
    {
      field: "email",
      editable: false,
      headerName: "Email",
      resizable: false,
      cellDataType: "text",
      minWidth: 260,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellStyle: commonCellStyle,
    },
  ];
};
