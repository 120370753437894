import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { ContentWrapper } from "./style";
import { Outlet, useParams } from "react-router-dom";
import { SideBar } from "../../components/Sidebar";
import SpecAppBar from "../../components/SpecAppBar";
import { useDispatch, useSelector } from "react-redux";
import { getSpecDataAsync } from "../../store/slices/userSpec";
import LayoutLoader from "../../components/LayoutLoader";
import SpecPreviewAppBar from "../../components/SpecPreviewAppBar";

const SpecLayout = ({ children, isPreview = false }) => {
  const dispatch = useDispatch();
  const { specId } = useParams();
  const { specData, isSpecLoading } = useSelector((state) => state.spec);

  useEffect(() => {
    dispatch(getSpecDataAsync({ specId }));
  }, [specId, dispatch]);

  return !isSpecLoading && specData?.id ? (
    <Box
      sx={{
        width: 1,
        height: 1,
        // backgroundColor: "red",
        dispaly: "flex !important",
        flexDirection: "row !important",
      }}>
      {isPreview ? <SpecPreviewAppBar /> : <SpecAppBar />}

      <Box
        sx={{
          display: "flex",
          height: 1,
          pt: "45px",
          overflowY: "auto",
        }}>
        {!isPreview && <SideBar />}
        <ContentWrapper>
          {children}
          <Outlet />
        </ContentWrapper>
      </Box>
    </Box>
  ) : (
    <LayoutLoader />
  );
};

export default SpecLayout;
