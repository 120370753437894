import { useEffect, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTheme } from "@mui/material";
import {
  Box,
  Grid,
  Link,
  ListItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { isEmpty } from "lodash";

import Effort from "../../assets/icons/use-case/effort.svg";
import Function from "../../assets/icons/use-case/function.svg";
import Job from "../../assets/icons/use-case/job.svg";
import NeededBy from "../../assets/icons/use-case/neededBy.svg";
import NeededFor from "../../assets/icons/use-case/neededFor.svg";
import Requester from "../../assets/icons/use-case/requester.svg";
import Status from "../../assets/icons/use-case/status.svg";
import Urgency from "../../assets/icons/use-case/urgency.svg";
import { LEVELS as LEVEL, STATUS_DOC_LABEL } from "../../constants";
import MainCard from "../MainCard";
import { convertDateToCustomFormat } from "../../utils/convertData";
import { useSelector } from "react-redux";

const InfoItem = styled(ListItem)(() => ({
  border: "1px solid #F0F0F0",
  borderRadius: "10px",
  padding: 16,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  svg: {
    marginRight: 10,
  },
}));

const showLevel = (level) => {
  switch (level) {
    case LEVEL.LOW:
      return LEVEL.LOW_LABEL;
    case LEVEL.MEDIUM:
      return LEVEL.MEDIUM_LABEL;
    case LEVEL.HIGH:
      return LEVEL.HIGH_LABEL;
    default:
      return "-";
  }
};

export const UseCase = () => {
  const theme = useTheme();
  const [title, setTitle] = useState("");

  const { specData } = useSelector((state) => state?.spec);

  return (
    <>
      <MainCard
        sx={{
          border: `1px solid #ffffff`,
          borderColor: "#dddddd",
          boxShadow: theme.palette.shadows.shadow1,
          ":hover": {
            boxShadow: theme.palette.shadows.shadow1,
          },
        }}
        dividerSX={{ borderColor: "#dddddd" }}
        titleTypographyProps={{ variant: "spec_h2" }}
        title={`Title of Spec: ${title || specData?.title || "-"}`}
        isHeaderPaddingRequired={false}
        secondary={
          specData.specRequest?.linkToTicket && (
            <Link
              variant='spec_body'
              href={specData.specRequest.linkToTicket}
              target='_blank'
              rel='noopener noreferrer'
              color='secondary'>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Box>Open Link to Ticket</Box>
                <ContentCopyIcon
                  style={{ color: "#424242" }}
                  fontSize='small'
                />
              </Stack>
            </Link>
          )
        }>
        <Box pb={5}>
          <Stack spacing={1}>
            <Typography variant='spec_h2' style={{ fontWeight: 600 }}>
              User Story:
            </Typography>
            <Typography variant='spec_body'>
              {`As a ${specData?.businessNeed?.persona || "-"}, I want to ${
                specData?.businessNeed?.action || "-"
              } so that ${specData?.businessNeed?.outcome || "-"}`}
            </Typography>
          </Stack>
          {specData.otherBusinessConsiderations && (
            <Box mt={2.5}>
              <Stack spacing={1}>
                <Typography variant='spec_h2' style={{ fontWeight: 600 }}>
                  Description:
                </Typography>
                <Typography
                  variant='spec_body'
                  dangerouslySetInnerHTML={{
                    __html: `${specData.otherBusinessConsiderations || "-"}`,
                  }}></Typography>
              </Stack>
            </Box>
          )}
          {!isEmpty(specData?.acceptanceCriteria) && (
            <Box mt={2.5}>
              <Stack spacing={1}>
                <Typography variant='spec_h2' style={{ fontWeight: 600 }}>
                  Acceptance Criteria:
                </Typography>
                {specData?.acceptanceCriteria?.map((item) => (
                  <li key={item?.id || "0"}>
                    <Typography variant='spec_body'>
                      {item?.description || "-"}
                    </Typography>
                  </li>
                ))}
              </Stack>
            </Box>
          )}
        </Box>
        <Grid container spacing={2.5}>
          <Grid item xs={3}>
            <InfoItem>
              <img src={Requester} alt='icon' />
              <Stack direction='column'>
                <Typography variant='spec_body' style={{ color: "#8C8C8C" }}>
                  Requester:
                </Typography>
                <Typography variant='spec_h3'>{`${
                  specData.specRequest?.requester?.firstName || ""
                } ${
                  specData.specRequest?.requester?.lastName || "-"
                }`}</Typography>
              </Stack>
            </InfoItem>
          </Grid>
          <Grid item xs={3}>
            <InfoItem>
              <img src={Job} alt='icon' />
              <Stack direction='column'>
                <Typography variant='spec_body' style={{ color: "#8C8C8C" }}>
                  Job Title:
                </Typography>
                <Typography variant='spec_h3'>
                  {specData.specRequest?.requester?.jobTitle || "-"}
                </Typography>
              </Stack>
            </InfoItem>
          </Grid>
          <Grid item xs={3}>
            <InfoItem>
              <img src={Status} alt='icon' />
              <Stack direction='column'>
                <Typography variant='spec_body' style={{ color: "#8C8C8C" }}>
                  Status:
                </Typography>
                <Typography variant='spec_h3'>
                  {STATUS_DOC_LABEL[specData.status]}
                </Typography>
              </Stack>
            </InfoItem>
          </Grid>
          <Grid item xs={3}>
            <InfoItem>
              <img src={Function} alt='icon' />
              <Stack direction='column'>
                <Typography variant='spec_body' style={{ color: "#8C8C8C" }}>
                  Function:
                </Typography>
                <Typography variant='spec_h3'>
                  {specData.specFunction || "-"}{" "}
                </Typography>
              </Stack>
            </InfoItem>
          </Grid>
          <Grid item xs={3}>
            <InfoItem>
              <img src={NeededFor} alt='icon' />
              <Stack direction='column'>
                <Typography variant='spec_body' style={{ color: "#8C8C8C" }}>
                  Purpose:
                </Typography>
                <Typography variant='spec_h3'>
                  {specData.specRequest?.neededFor || "-"}
                </Typography>
              </Stack>
            </InfoItem>
          </Grid>
          <Grid item xs={3}>
            <InfoItem>
              <img src={NeededBy} alt='icon' />
              <Stack direction='column'>
                <Typography variant='spec_body' style={{ color: "#8C8C8C" }}>
                  Due Date:
                </Typography>
                <Typography variant='spec_h3'>
                  {specData.specRequest?.neededBy
                    ? convertDateToCustomFormat(specData.specRequest?.neededBy)
                    : "-"}
                </Typography>
              </Stack>
            </InfoItem>
          </Grid>
          <Grid item xs={3}>
            <InfoItem>
              <img src={Urgency} alt='icon' />
              <Stack direction='column'>
                <Typography variant='spec_body' style={{ color: "#8C8C8C" }}>
                  Urgency:
                </Typography>
                <Typography variant='spec_h3'>
                  {showLevel(specData.specRequest?.urgency)}
                </Typography>
              </Stack>
            </InfoItem>
          </Grid>
          <Grid item xs={3}>
            <InfoItem>
              <img src={Effort} alt='icon' />

              <Stack direction='column'>
                <Typography variant='spec_body' style={{ color: "#8C8C8C" }}>
                  Effort:
                </Typography>
                <Typography variant='spec_h3'>
                  {showLevel(specData.specRequest?.effort)}
                </Typography>
              </Stack>
            </InfoItem>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};
