import React, { useState } from "react";

import {
  BorderColorOutlined,
  ContentCopy,
  ContentPasteRounded,
  ControlCameraOutlined,
  DeleteOutline,
  EditOffOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { StatisticModal } from "./StatisticModal";
import { specPrimary } from "../../constants/theme/colors";
import CustomPopover from "../../common/CustomPopover";
import MenuItem from "../../common/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { setCopiedVisualization } from "../../store/slices/userSpec";

const StatBlock = ({
  item,
  statistic,
  setStatistic,
  index,
  charts,
  mockDataset,
  setNeedUpdate,
  editable = true,
  isPreview = false,
  data,
  setOpenSideBar,
  handleEdit,
  statEditIndex,
  handleDeleteStatistic,
  handlePaste = () => {},
}) => {
  const [selectedStatistic, setSelectedStatistic] = useState(null);
  const [isOpenStatistic, setIsOpenStatistic] = useState(false);

  const dispatch = useDispatch();
  const { copiedVisualization } = useSelector((state) => state.spec);
  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  // const handleAddStatistic = (el) => {
  //   if (selectedStatistic?.params?.dimension?.i) {
  //     let newState = statistic.map((stat) => {
  //       if (
  //         selectedStatistic?.params?.dimension?.i === stat?.params?.dimension?.i
  //       ) {
  //         // const fixedValue = Number(total.toFixed(2));
  //         // const value = fixedValue.toLocaleString("en-US");
  //         return {
  //           ...el,
  //           params: {
  //             ...selectedStatistic?.params,
  //             title: el?.params?.title,

  //             value: el?.params?.value,
  //           },
  //         };
  //       }
  //       return stat;
  //     });
  //     setStatistic(newState);
  //     setNeedUpdate([...newState]);
  //     setSelectedStatistic(null);
  //   } else {
  //     setStatistic([...statistic, { ...el, params: { ...el.params } }]);
  //     setNeedUpdate([...statistic, { ...el, params: { ...el.params } }]);
  //   }
  // };

  const isSelected = index === statEditIndex;

  return (
    <>
      <Paper
        key={index}
        variant='outlined'
        sx={(theme) => ({
          height: "100%",
          width: "100%",
          overflow: "auto",
          border: isSelected
            ? `2px solid ${specPrimary[700]}`
            : `1px solid ${theme.palette.grey[200]}`,
        })}>
        <Stack direction='row' justifyContent='space-between'>
          <Box py={1.5} pl={2.5} pr={1.5}>
            <Typography variant='spec_h3'>{item.params.title}</Typography>
          </Box>
          <Box p={0.5}>
            <Stack direction='row' spacing={0}>
              {!isPreview && (
                <>
                  <Tooltip
                    title={<Typography variant='spec_tooltip'>Drag</Typography>}
                    arrow>
                    <IconButton>
                      <ControlCameraOutlined
                        fontSize='small'
                        style={{ color: "#424242" }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      <Typography variant='spec_tooltip'>Duplicate</Typography>
                    }
                    arrow>
                    <IconButton
                      onClick={(e) => {
                        handlePaste(item?.mockDataId, "statistic");
                      }}>
                      <ContentCopy
                        fontSize='small'
                        style={{ color: "#424242" }}
                        className='handleDraggableElement'
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )}

              <Tooltip
                title={
                  <Typography variant='spec_tooltip'>
                    {isSelected
                      ? "Close Configuration"
                      : isPreview
                      ? "View Configuration"
                      : "Configure"}
                  </Typography>
                }
                arrow>
                <IconButton
                  className='cancelDraggableElement'
                  // onClick={() => {
                  //   setIsOpenStatistic(true);
                  //   setSelectedStatistic(item);
                  // }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleEdit(index);
                    // openModal(index);
                    if (!isSelected) {
                      setOpenSideBar();
                    }
                  }}>
                  {isSelected ? (
                    !isPreview ? (
                      <EditOffOutlined
                        fontSize='small'
                        style={{ color: "#424242" }}
                      />
                    ) : (
                      <VisibilityOff
                        fontSize='small'
                        style={{ color: "#424242" }}
                      />
                    )
                  ) : !isPreview ? (
                    <BorderColorOutlined
                      fontSize='small'
                      style={{ color: "#424242" }}
                    />
                  ) : (
                    <Visibility fontSize='small' style={{ color: "#424242" }} />
                  )}
                </IconButton>
              </Tooltip>
              {!isPreview && (
                <Tooltip
                  title={<Typography variant='spec_tooltip'>Delete</Typography>}
                  arrow>
                  <IconButton
                    onClick={() =>
                      handleDeleteStatistic(item?.mockDataId, index)
                    }
                    className='cancelDraggableElement'>
                    <DeleteOutline
                      fontSize='small'
                      style={{ color: "#424242" }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Box>
        </Stack>
        <Box px={2.5} py={1.5} className={"cancelDraggableElement"}>
          <Typography variant='spec_large_27'>{item.params.value}</Typography>
        </Box>
      </Paper>
      {/* <StatisticModal
        isOpen={isOpenStatistic}
        statisticData={selectedStatistic}
        mockDataset={mockDataset}
        onClose={() => {
          setIsOpenStatistic(false);
          setSelectedStatistic(null);
        }}
        onSubmit={handleAddStatistic}
        charts={[...charts]}
      /> */}
      <CustomPopover
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }>
        <MenuItem
          Icon={<ContentCopy fontSize='small' />}
          itemText={"Copy"}
          itemProps={{ height: "15px" }}
          onClick={() => {
            handleClose();
            handlePaste(item?.mockDataId, "statistic");
          }}
        />
      </CustomPopover>
    </>
  );
};

export default StatBlock;
