import { styled as MuiStyled, Tab, Tabs } from "@mui/material";
import { specGray } from "../../../constants/theme/colors";

export const StyledTabs = MuiStyled((props) => (
  <Tabs
    TabIndicatorProps={{
      sx: {
        height: "3px",
        borderRadius: "8px",
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  // here goes styling for mui tabs component
  minHeight: "45px !important",
  ".MuiButtonBase-root.Mui-selected": {
    color: theme.palette.secondary.black,
  },
}));

export const StyledTab = MuiStyled((props) => <Tab disableRipple {...props} />)(
  ({ theme, selected }) => ({
    color: theme.palette.gray.textGray1,
    minHeight: "45px !important",
    textTransform: "none",
    fontFamily: "Poppins",
    letterSpacing: 0,
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: 0,

    ":hover": {
      backgroundColor: specGray[100],
    },

    [theme.breakpoints.down("lg")]: {
      minWidth: "auto",
      maxWidth: "100%",
      alignItems: "end",
      textDecoration: selected ? "underline" : "none",
      textDecorationThickness: "3px",
      textDecorationColor: theme.palette.primary.main,
    },
  })
);
