import { AppBar, styled as MuiStyled, Toolbar } from "@mui/material";

export const StyledAppBar = MuiStyled((props) => (
  <AppBar position='static' {...props} />
))(({ theme }) => ({
  position: "absolute",
  width: "100%",
  backgroundColor: theme.palette.secondary.white,
  boxShadow: "none",
  minHeight: `45px !important`,
  borderBottom: `1px solid ${theme.palette.gray.borderGray}`,
  paddingTop: 0,
  paddingBottom: 0,
}));

export const StyledToolbar = MuiStyled((props) => <Toolbar {...props} />)(
  ({ theme }) => ({
    minHeight: `45px !important`,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: `0 !important`,

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  })
);
