import {
  Badge,
  Box,
  IconButton,
  styled as MuiStyled,
  Stack,
  alpha,
} from "@mui/material";

export const ContentCenteredBox = MuiStyled(Stack)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

export const SecondaryAppBar = MuiStyled(Box)(({ theme }) => ({
  padding: "3px 24px 3px 4px",
  backgroundColor: theme.palette.secondary.white,
  border: "1px solid transparent",
  borderBottomColor: theme.palette.gray.borderGray,
}));

export const StyledBadge = MuiStyled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: -2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: 500,
  },
}));

export const SquareIconButton = MuiStyled(IconButton)(({ theme }) => ({
  padding: 0.25,
  backgroundColor: alpha(theme.palette.gray.lighter, 0.5),
  ":hover": {
    backgroundColor: theme.palette.primary.main,
  },
  borderRadius: theme.spacing(0.4),
}));
