import Tabs from "./Tabs";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import MockDatasetTable from "../MockDatasetTable";
import { useDispatch, useSelector } from "react-redux";
import { FileDownloadOutlined } from "@mui/icons-material";
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { getSpecMockdatasetTabsAsync } from "../../store/slices/mockDataset";
import AddEditLabel from "../MockDatasetTable/MockDatasetContextMenu/AddEditLabel";

const MockDatasetTab = () => {
  const { specId } = useParams();
  const dispatch = useDispatch();

  const { isSpecMockdataTabsLoading } = useSelector(
    (state) => state.mockDataset
  );

  useEffect(() => {
    dispatch(getSpecMockdatasetTabsAsync(specId));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box
        sx={{
          height: 1,
          width: 1,
          overflowY: "auto",
          position: "relative",
        }}>
        {isSpecMockdataTabsLoading ? (
          <Stack
            sx={{ height: 1 }}
            justifyContent={"center"}
            alignItems={"center"}>
            <CircularProgress size={45} />
          </Stack>
        ) : (
          <>
            <Box
              sx={{
                // height: `calc(100% - 46px)`, //45px height of bottom tabs
                height: "100%",
              }}>
              <MockDatasetTable />
            </Box>
            {/* <Box
              sx={(theme) => ({
                width: 1,
                height: "45px",
                display: "flex",
                alignItems: "center",
                borderTop: `1px solid ${theme.palette.gray.lighter}`,
              })}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                columnGap={1}
                width={1}>
                <Tabs />
                <Box pr={2}>
                  <Button
                    variant='contained'
                    startIcon={<FileDownloadOutlined />}
                    size='small'>
                    Export to CSV
                  </Button>
                </Box>
              </Stack>
            </Box> */}
          </>
        )}
      </Box>
      <AddEditLabel />
    </>
  );
};

export default MockDatasetTab;
