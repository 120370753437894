import { getBillingColumns } from "./columns";
import { getBillingRows } from "./helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { Stack, CircularProgress } from "@mui/material";
import AgGridComponent from "../../common/AgGridComponent";
import CustomHeaderCell from "../../common/CustomHeaderCell";
import { getInvoicesAsync } from "../../store/slices/billing";
import React, { useEffect, useState, useMemo, useRef } from "react";
import NoRowsOverlayCustomComponent from "../../common/NoRowsOverlayCustomComponent";
import CustomOverlayLoadingComponent from "../../common/CustomOverlayLoadingComponent";

const Billing = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();

  const { invoices } = useSelector((state) => state.billing);

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeaderCell,
    };
  }, []);

  useEffect(() => {
    dispatch(getInvoicesAsync())
      .unwrap()
      .then(() => {
        setIsInitialLoading(false);
      });
  }, []);

  useEffect(() => {
    setColDefs(getBillingColumns());
    if (invoices?.length) {
      setRowData(getBillingRows(invoices));
    }
  }, [invoices]);

  return (
    <>
      <Stack
        sx={{
          height: 1,
          width: 1,
        }}>
        <Stack
          sx={(theme) => ({
            height: 1,
            borderTop: `1px solid ${theme.palette.gray.borderGray}`,
          })}>
          {isInitialLoading ? (
            <Stack
              height={1}
              width={1}
              justifyContent={"center"}
              alignItems={"center"}>
              <CircularProgress size={40} />
            </Stack>
          ) : (
            <AgGridComponent
              ref={ref}
              data={rowData}
              columnDefs={colDefs}
              components={components}
              loadingOverlayComponent={CustomOverlayLoadingComponent}
              noRowsOverlayComponent={NoRowsOverlayCustomComponent}
              noRowsMessage='No Invoices found.'
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default Billing;
