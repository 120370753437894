import { useTheme } from "@emotion/react";
import { Typography, alpha } from "@mui/material";
import React from "react";
import { MicrosoftLoginButton } from "react-social-login-buttons";
import { baseApi } from "../../utils/urls";

const MicrosoftButton = () => {
  const theme = useTheme();
  const handleMicrosoftLogin = () => {
    window.location.href = `${baseApi}/auth/azure_activedirectory_v2`;
  };

  return (
    <MicrosoftLoginButton
      onClick={handleMicrosoftLogin}
      style={{
        background: theme?.palette?.secondary?.white,
        borderRadius: theme?.spacing(1),
        boxShadow: theme?.palette?.shadows?.shadow1,
      }}
      activeStyle={{ background: alpha(theme?.palette?.gray?.lighter, 0.2) }}>
      <Typography variant='spec_body'>Login with Microsoft</Typography>
    </MicrosoftLoginButton>
  );
};

export default MicrosoftButton;
