import { alpha } from "@mui/material";
export const defaultStyle = (theme, isView = false, minHeight, maxHeight) => ({
  control: {
    backgroundColor: isView ? "transparent" : "#fff",
    fontFamily: `'Poppins', sans-serif`,
    fontWeight: 400,
    lineHeight: "normal",
    ...(!minHeight && { height: isView ? "auto" : "auto" }),
    ...(minHeight && { minHeight: minHeight }),
    ...(maxHeight && { maxHeight: maxHeight }),
    ...(isView && { fontSize: "14px" }),
  },

  "&multiLine": {
    control: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "14px",
      ...(!minHeight && { height: isView ? "auto" : "100px" }),
      ...(minHeight && { minHeight: minHeight }),
      ...(maxHeight && { maxHeight: maxHeight }),
      borderRadius: "7px",
      lineHeight: "normal",
    },
    highlighter: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "14px",
      overflow: "hidden",
      padding: 9,
      border: "1px solid transparent",
      ...(!minHeight && { height: isView ? "auto" : "100px" }),
      ...(minHeight && { minHeight: minHeight }),
      ...(maxHeight && { maxHeight: maxHeight }),
      borderRadius: "7px",
      lineHeight: "normal",
    },
    input: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "14px",
      padding: 9,
      overflow: "auto",
      border: "1px solid transparent",
      borderRadius: "7px",
      ...(!minHeight && { height: isView ? "auto" : "100px" }),
      ...(minHeight && { minHeight: minHeight }),
      ...(maxHeight && { maxHeight: maxHeight }),
      outline: "none",
      lineHeight: "normal",
    },
  },

  "&singleLine": {
    display: "inline-block",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "14px",
    width: "100%",
    control: {
      fontFamily: `'Poppins', sans-serif`,
      borderRadius: "7px",
    },
    highlighter: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "14px",
      padding: 1,
      border: "1px solid transparent",
      borderRadius: "7px",
    },
    input: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "14px",
      padding: 1,
      borderRadius: "7px",
      border: "1px solid transparent",
      outline: "none",
    },
  },

  suggestions: {
    zIndex: `9999 !important`,
    list: {
      width: "200px",
      backgroundColor: "white",
      boxShadow: theme.palette.shadows.shadow1,
      borderRadius: "7px",
      fontSize: 14,
      maxHeight: "300px",
      overflow: "auto",
      paddingLeft: "8px",
      paddingRight: "8px",
      paddingTop: "8px",
      paddingBottom: "8px",
    },
    item: {
      padding: "5px 15px",
      borderRadius: "7px",
      "&focused": {
        backgroundColor: alpha(theme.palette.primary.main, 0.3),
      },
    },
  },
});
