import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { ChooseRelevantChart } from "./chooseRelevantChart";
import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  ToggleButton,
  useMediaQuery,
} from "@mui/material";
import { GROUP, charTypes } from "./utils";
import { specGray, themePalette } from "../../constants/theme/colors";
import { ChartItem, SideBarItem, TabGroup } from "../../common/style";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const SelectVisuals = ({
  open,
  handleClose,
  selectedChart,
  setSelectedChart,
}) => {
  const isMobileView = useMediaQuery("(max-width: 600px)");

  const [tab, setTab] = useState("0");
  // const [selectedChart, setSelectedChart] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState("All");

  const handleSelectChart = (type) => {
    const foundChart = charTypes.find((chart) => chart?.type === type);

    if (foundChart) {
      setSelectedChart(foundChart);
      handleClose();
    }
  };

  const handleAlignment = (event, tab) => {
    setTab(tab ? tab : "0");
  };

  useEffect(() => {
    if (isMobileView) {
      setTab("1");
    }
  }, [isMobileView, open]);

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <DialogTitle
          sx={{
            position: "relative",
            borderBottom: `1px solid ${specGray["300"]}`,
            p: 0,
          }}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClose}
              aria-label='close'>
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, whiteSpace: "nowrap", fontWeight: 500 }}
              variant='spec_h1'>
              Select Chart
            </Typography>
            {!isMobileView && (
              <TabGroup
                sx={{ ml: 4, flex: 10 }}
                size='small'
                value={tab}
                exclusive
                onChange={handleAlignment}
                aria-label='text alignment'>
                <ToggleButton value='0' aria-label='Taxonomy'>
                  <Typography variant='spec_body'>Taxonomy</Typography>
                </ToggleButton>
                <ToggleButton value='1' aria-label='List'>
                  <Typography variant='spec_body'>List</Typography>
                </ToggleButton>
              </TabGroup>
            )}

            {/* <Button variant="contained">
              Apply
            </Button> */}
          </Toolbar>
        </DialogTitle>
        <DialogContent
          sx={{
            px: 0,
          }}>
          {tab === "0" ? (
            <ChooseRelevantChart
              chart={selectedChart?.type}
              handleSelectChart={handleSelectChart}
            />
          ) : (
            <Grid container sx={{ width: "100%", height: "100%" }}>
              {!isMobileView && (
                <Grid
                  item
                  xs={3}
                  sm={2}
                  sx={{ borderRight: "1px solid #F0F0F0" }}>
                  <List sx={{ py: 0 }}>
                    <SideBarItem
                      className={selectedGroup === "All" && "active"}
                      onClick={() => setSelectedGroup("All")}>
                      All
                    </SideBarItem>
                    <SideBarItem
                      className={selectedGroup === GROUP.comparison && "active"}
                      onClick={() => setSelectedGroup(GROUP.comparison)}>
                      {GROUP.comparison}
                    </SideBarItem>
                    <SideBarItem
                      className={
                        selectedGroup === GROUP.relationship && "active"
                      }
                      onClick={() => setSelectedGroup(GROUP.relationship)}>
                      {GROUP.relationship}
                    </SideBarItem>
                    <SideBarItem
                      className={
                        selectedGroup === GROUP.composition && "active"
                      }
                      onClick={() => setSelectedGroup(GROUP.composition)}>
                      {GROUP.composition}
                    </SideBarItem>
                    <SideBarItem
                      className={
                        selectedGroup === GROUP.distribution && "active"
                      }
                      onClick={() => setSelectedGroup(GROUP.distribution)}>
                      {GROUP.distribution}
                    </SideBarItem>
                  </List>
                </Grid>
              )}

              <Grid
                item
                xs={isMobileView ? 12 : 9}
                sm={isMobileView ? 12 : 10}
                sx={{ height: 1, overflow: "auto" }}>
                <List
                  pl={1}
                  sx={{
                    py: 0,
                    height: "100%",
                    overflowY: "auto",
                    "& svg": {
                      overflow: "visible",
                    },
                  }}>
                  {selectedGroup !== "All" ? (
                    <Box display='flex' flexWrap='wrap'>
                      {charTypes
                        .filter((item) => item.group.includes(selectedGroup))
                        .map((item, index) => {
                          return (
                            <ChartItem
                              key={index}
                              className={
                                selectedChart?.type === item?.type && "active"
                              }
                              onClick={() => {
                                handleSelectChart(item.type);
                              }}
                              sx={{
                                width: isMobileView ? "100%" : "50%",
                              }}>
                              {item.icon}
                              <Stack ml={2} rowGap={0.3}>
                                <Typography
                                  variant='spec_caption'
                                  sx={{
                                    color: themePalette.palette.gray.main,
                                  }}>
                                  {item.period}
                                </Typography>
                                <Typography variant='spec_h3'>
                                  {item.label}
                                </Typography>
                                <Typography
                                  variant='spec_caption'
                                  sx={{
                                    fontWeight: 400,
                                  }}>
                                  {item.categories}
                                </Typography>
                              </Stack>
                            </ChartItem>
                          );
                        })}
                    </Box>
                  ) : (
                    <>
                      {[
                        GROUP.comparison,
                        GROUP.relationship,
                        GROUP.composition,
                        GROUP.distribution,
                      ].map((type) => {
                        return (
                          <Box key={type}>
                            <Typography mt={1} ml={1} variant='spec_h3'>
                              {type}
                            </Typography>
                            <Box display='flex' flexWrap='wrap'>
                              {charTypes
                                .filter((item) => item.group.includes(type))
                                .map((item, index) => {
                                  return (
                                    <ChartItem
                                      key={index}
                                      className={
                                        selectedChart?.id === item.id &&
                                        "active"
                                      }
                                      onClick={() => {
                                        handleSelectChart(item.type);
                                      }}
                                      sx={{
                                        width: isMobileView ? "100%" : "50%",
                                      }}>
                                      {item.icon}
                                      <Stack ml={2} rowGap={0.3}>
                                        <Typography
                                          variant='spec_caption'
                                          sx={{
                                            color:
                                              themePalette.palette.gray.main,
                                          }}>
                                          {item.period}
                                        </Typography>
                                        <Typography variant='spec_h3'>
                                          {item.label}
                                        </Typography>
                                        <Typography
                                          variant='spec_caption'
                                          sx={{
                                            fontWeight: 400,
                                          }}>
                                          {item.categories}
                                        </Typography>
                                      </Stack>
                                    </ChartItem>
                                  );
                                })}
                            </Box>
                            {type !== GROUP.distribution && (
                              <Box>
                                <Divider />
                              </Box>
                            )}
                          </Box>
                        );
                      })}
                    </>
                  )}
                </List>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default SelectVisuals;
