import {
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { maintainCaretPosition } from "../../utils/commonUtility";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const InputField = ({
  id,
  isLabel = false,
  label = "",
  name = "",
  isRequired = false,
  type = "text",
  rows,
  multiline = false,
  className,
  classes,
  style,
  sx,
  variant,
  autofocus,
  color,
  disabled = false,
  endAdornment,
  startAdornment,
  inputProps,
  placeholder = "",
  onChange = () => {},
  onKeyDown = () => {},
  onBlur,
  value = "",
  fullWidth,
  helperText = "",
  error = false,
  readOnly = false,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const handleClickShowPassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <Stack sx={{ width: 1 }}>
      {isLabel && (
        <InputLabel
          htmlFor={name}
          required={isRequired}
          error={error}
          sx={{
            mb: 0.65,
            ".MuiFormLabel-asterisk": {
              ml: -1,
              color: "error.main",
            },
            textAlign: "left",
          }}>
          <Typography
            variant='spec_inputLabel'
            sx={{
              color: "secondary.black",
            }}>
            {label}
          </Typography>
        </InputLabel>
      )}
      <TextField
        id={id ? id : name}
        readOnly={readOnly}
        type={
          type === "password" ? (isPasswordVisible ? "text" : "password") : type
        }
        className={className}
        onWheel={(e) => e.target.blur()}
        classes={classes}
        error={error}
        style={style}
        sx={sx}
        variant={variant ? variant : "outlined"}
        autoComplete='off'
        autoFocus={autofocus}
        color={color}
        disabled={disabled}
        multiline={multiline}
        {...(rows && { rows: rows })}
        InputProps={{
          endAdornment: endAdornment ? (
            <InputAdornment position='end'>{endAdornment}</InputAdornment>
          ) : type === "password" && value?.length ? (
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              sx={(theme) => ({
                height: "32px",
                width: "32px",
              })}>
              {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          ) : null,
          startAdornment: startAdornment && (
            <InputAdornment position='start'>{startAdornment}</InputAdornment>
          ),
        }}
        inputProps={{
          ...inputProps,
          form: {
            autocomplete: "off",
          },
        }}
        placeholder={placeholder}
        onChange={(e) => {
          maintainCaretPosition(e);
          onChange(e);
        }}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        value={value ? value : ""}
        fullWidth={fullWidth}
        name={name}
        helperText={
          error && helperText ? (
            <Typography variant='helperText'>{helperText}</Typography>
          ) : null
        }
      />
    </Stack>
  );
};
