import {
  addComment,
  updComment,
  deleteComment,
  getAutocompleteUsersComments,
} from "../../../services";
import { useState } from "react";
import PropTypes from "prop-types";
import { checkAvailable } from "../utils";
import { useSelector } from "react-redux";
import { CommentsTextBox } from "../style";
import { useParams } from "react-router-dom";
import { toaster } from "../../../common/Toaster";
import CommentReplyCard from "../CommentReplyCard";
import { HTTP_OK } from "../../../constants/statusCodes";
import LoadingButton from "../../../common/LoadingButton";
import CommentActionButtons from "../CommentActionButtons";
import PromptTagField from "../../../common/PromptTagField";
import { stringAvatar } from "../../../utils/commonUtility";
import { defaultMessage } from "../../../constants/messages";
import { CheckCircleOutlineRounded } from "@mui/icons-material";
import { specSecondary } from "../../../constants/theme/colors";
import { convertDateTimeForComments } from "../../../utils/convertData";
import { COMMENT_STATUS, COMMENT_STATUS_LABEL } from "../../../constants";
import DeleteConfirmationModal from "../../../common/DeleteConfirmationModal";
import { Box, Button, Stack, Typography, Avatar, alpha } from "@mui/material";

export const CommentCard = ({
  onReply,
  comment,
  activeId,
  onActive,
  onUpdate,
  onUpdateComment,
  handleUpdateCommentsOnDelete,
}) => {
  const { user } = useSelector((state) => state.userAuth);
  const { specData } = useSelector((state) => state.spec);
  const { specId } = useParams();

  const [replyBody, setReplyBody] = useState("");
  const [replyPlainBody, setReplyPlainBody] = useState("");
  const [replyMentions, setReplyMentions] = useState([]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [commentBody, setCommentBody] = useState(comment?.body || "");
  const [commentPlainBody, setCommentPlainBody] = useState(
    comment?.plainBody || ""
  );
  const [mentionedUsers, setMentionedUsers] = useState(comment?.mentions || []);
  const [loading, setLoading] = useState(false);
  const [onReplyLoading, setOnReplyLoading] = useState(false);
  const [onDeleteLoading, setOnDeleteLoading] = useState(false);

  const isActive = comment.id === activeId;

  // Check if user can interact with comments
  const canInteract = checkAvailable(user, specData);

  const handleReply = () => {
    const payload = {
      comment: {
        body: replyBody,
        plain_body: replyPlainBody,
        mentions: replyMentions,
        parent_id: comment.id,
      },
    };
    setOnReplyLoading(true);

    addComment({ specId: specId, payload: payload })
      .then((r) => {
        setOnReplyLoading(false);
        if (r.status !== HTTP_OK) {
          throw new Error(defaultMessage);
        }
        onReply({ comment: r?.data?.comment, commentId: comment.id });
        setReplyBody("");
        setReplyPlainBody("");
        setReplyMentions([]);
      })
      .catch((e) => {
        setOnReplyLoading(false);
        toaster({ type: "error", message: e });
      });
  };

  const handleCancel = () => {
    setReplyBody("");
    setReplyPlainBody("");
    setReplyMentions([]);
  };
  const handleCommentChange = (event, newValue, plainText, mentions) => {
    const uniqueMentions = mentions.filter(
      (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
    );
    setCommentBody(newValue);
    setCommentPlainBody(plainText);
    setMentionedUsers(uniqueMentions);
  };

  const handleReplyChange = (event, newValue, plainText, mentions) => {
    const uniqueMentions = mentions.filter(
      (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
    );
    setReplyBody(newValue);
    setReplyPlainBody(plainText);
    setReplyMentions(uniqueMentions);
  };

  const toggleEdit = () => {
    setIsEditable((prev) => !prev);
  };

  const onCommentUpdate = () => {
    setLoading(true);
    const payload = {
      comment: {
        body: commentBody,
        plain_body: commentPlainBody,
        mentions: mentionedUsers,
      },
    };
    updComment({
      specId: specId,
      payload: payload,
      commentId: comment.id,
    })
      .then((r) => {
        // set updated comment to local state
        onUpdateComment({ comment: r?.data?.comment, isParent: true });
        setIsEditable(false);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onCancel = () => {
    setIsEditable(false);
    setCommentBody(comment.body);
  };

  const onCommentDeleteDelete = () => {
    setOnDeleteLoading(true);
    deleteComment({ commentId: comment.id, specId: specId })
      .then((r) => {
        if (r?.status !== HTTP_OK) {
          throw new Error(defaultMessage);
        }
        setOnDeleteLoading(false);
        setOpenDeleteModal(false);
        handleUpdateCommentsOnDelete({ id: comment.id, isParent: true });
      })
      .catch((e) => {
        setOnDeleteLoading(false);
        setOpenDeleteModal(false);
        toaster({ type: "error", message: e });
      });
  };

  const onDelete = () => {
    setOpenDeleteModal(true);
  };

  const fetchUsers = (query, callback) => {
    getAutocompleteUsersComments({ query: query, specId: specId })
      .then((res) => {
        // Transform the users to what react-mentions expects
        return res?.data?.users?.map((user) => ({
          display: `${user?.firstName} ${user?.lastName}`,
          id: user?.id,
        }));
      })
      .then(callback);
  };

  return (
    <Box
      id={comment.id}
      sx={(theme) => ({
        width: "99%",
        border: `1px solid ${theme.palette.gray.borderGray}`,
        borderRadius: "10px",
        backgroundColor: isActive
          ? alpha(theme.palette.primary.light, 0.2)
          : "white",
      })}
      onClick={() => onActive(comment.id)}>
      <Stack
        sx={(theme) => ({
          width: 1,
        })}>
        <Stack
          sx={(theme) => ({
            px: 2,
            py: 1,
            borderRadius: "10px 10px 0px 0px",
            backgroundColor: theme.palette.gray.backgroundGray,
            borderBottom: `1px solid ${theme.palette.gray.borderGray}`,
          })}>
          <Stack
            spacing={2}
            direction='row'
            justifyContent='space-between'
            alignItems='baseline'>
            {comment.status && (
              <Stack spacing={1} direction='row' alignItems='center'>
                <Typography
                  variant='spec_body'
                  color='grey'
                  textTransform='capitalize'>
                  {COMMENT_STATUS_LABEL[comment.status]}
                </Typography>
                <CheckCircleOutlineRounded fontSize='15px' color='gray' />
              </Stack>
            )}
            {canInteract && (
              <Typography
                variant='spec_body'
                color='info.main'
                style={{ cursor: "pointer" }}
                onClick={() =>
                  onUpdate(
                    comment.id,
                    comment.status === COMMENT_STATUS.RESOLVED
                      ? COMMENT_STATUS.OPEN
                      : COMMENT_STATUS.RESOLVED
                  )
                }>
                {comment.status === COMMENT_STATUS.RESOLVED
                  ? "Reopen"
                  : "Resolve"}
              </Typography>
            )}
          </Stack>
        </Stack>

        <Stack sx={{ px: 2 }}>
          <Stack spacing={1} py={1}>
            <Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={1}>
                <Stack direction={"row"} alignItems={"center"} columnGap={1}>
                  <Avatar
                    sx={(theme) => ({
                      bgcolor: specSecondary[50],
                      height: "32px",
                      width: "32px",
                    })}>
                    <Typography variant='spec_body' color={"secondary.main"}>
                      {stringAvatar(comment?.user || "")?.children}
                    </Typography>
                  </Avatar>

                  <Stack>
                    <Typography variant='spec_body'>{comment.user}</Typography>

                    <Typography
                      variant='spec_caption'
                      sx={{ fontWeight: 400 }}
                      color='gray.main'>
                      {convertDateTimeForComments(comment.createdAt)}
                    </Typography>
                  </Stack>
                </Stack>
                {user?.id === comment?.userId && (
                  <CommentActionButtons
                    isEditable={isEditable}
                    toggleEdit={toggleEdit}
                    onSubmit={onCommentUpdate}
                    onCancel={onCancel}
                    onDelete={onDelete}
                    loading={loading}
                  />
                )}
              </Stack>
            </Stack>
            <Stack>
              <PromptTagField
                value={commentBody}
                isView={!isEditable}
                data={fetchUsers}
                handleChange={handleCommentChange}
                singleLine={false}
                trigger='@'
              />
            </Stack>
          </Stack>
          <Stack spacing={1} pb={2}>
            {comment.children.length > 0 &&
              comment.children.map((child, i) => (
                <CommentReplyCard
                  child={child}
                  key={i}
                  onUpdateComment={onUpdateComment}
                  parentId={comment?.id}
                  handleUpdateCommentsOnDelete={handleUpdateCommentsOnDelete}
                />
              ))}
            {canInteract && (
              <Stack>
                <CommentsTextBox
                  sx={{
                    mt: 0,
                    display: "flex",
                    alignItems: "center",
                    px: 1,
                    backgroundColor: "white",
                  }}>
                  <PromptTagField
                    value={replyBody}
                    minHeight={"32px"}
                    maxHeight={"100px"}
                    placeholder='Reply'
                    data={fetchUsers}
                    handleChange={handleReplyChange}
                    trigger='@'
                  />
                </CommentsTextBox>
              </Stack>
            )}
            {(replyBody || replyPlainBody) && canInteract && (
              <Stack sx={{ width: 1 }}>
                <Stack
                  columnGap={1}
                  direction='row'
                  justifyContent='flex-end'
                  alignItems='baseline'>
                  <Button
                    disabled={onReplyLoading}
                    variant='outlined'
                    color='gray'
                    sx={{ height: "32px" }}
                    onClick={handleCancel}>
                    Cancel
                  </Button>
                  <LoadingButton
                    variant='contained'
                    color='primary'
                    sx={{ height: "32px" }}
                    onClick={handleReply}
                    loading={onReplyLoading}>
                    Reply
                  </LoadingButton>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
      <DeleteConfirmationModal
        message='Are you sure you want to delete this comment?'
        description='This action can not be undone!'
        isOpen={openDeleteModal}
        handleDelete={onCommentDeleteDelete}
        handleClose={() => setOpenDeleteModal(false)}
        loading={onDeleteLoading}
      />
    </Box>
  );
};

CommentCard.propTypes = {
  comment: PropTypes.object,
};
