import { LinearProgress } from "@mui/material";
import React from "react";
import { LoaderWrapper } from "./style";

const LayoutLoader = () => {
  return (
    <LoaderWrapper>
      <LinearProgress color='primary' />
    </LoaderWrapper>
  );
};

export default LayoutLoader;
