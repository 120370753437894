import { Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ProposedForCell = (props) => {
  const navigate = useNavigate();

  return (
    <Stack
      width={1}
      onClick={() =>
        props?.data?.proposedForId
          ? navigate(`/specs/spec/${props?.data?.proposedForId}`)
          : {}
      }
      sx={{ cursor: "pointer" }}>
      <Typography
        variant='spec_body'
        sx={(theme) => ({
          textWrap: "wrap",
          lineHeight: "normal",
          color: props?.data?.proposedForId ? theme.palette.secondary.main : "",
        })}>
        {props?.value}
      </Typography>
    </Stack>
  );
};

export default ProposedForCell;
