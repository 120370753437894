import {
  MoreVert,
  AddRounded,
  ContentPaste,
  DeleteOutline,
  ContentCopyRounded,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import {
  setCopyLabelData,
  resetCopyLabelData,
} from "../../../store/slices/copyLabel";
import { useParams } from "react-router";
import ConfigureRows from "./ConfigureRows";
import MenuItem from "../../../common/MenuItem";
import { toaster } from "../../../common/Toaster";
import BaseModal from "../../../common/BaseModal";
import { useDispatch, useSelector } from "react-redux";
import { HTTP_OK } from "../../../constants/statusCodes";
import CustomPopover from "../../../common/CustomPopover";
import { BorderColorOutlinedIcon } from "../../../assets";
import LoadingButton from "../../../common/LoadingButton";
import { pasteLabel, updateSpecification, updateTab } from "../../../services";
import { defaultMessage } from "../../../constants/messages";
import {
  replaceTable,
  setAddEditLabel,
} from "../../../store/slices/mockDataset";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import DeleteConfirmationModal from "../../../common/DeleteConfirmationModal";
import {
  updateSelectedChart,
  updateSpecVisualization,
} from "../../../store/slices/userSpec";
import {
  BAR_CHART,
  BOX_PLOT,
  COLUMN_CHART,
  MULTIPLE_LINE_CHART,
  PIE_CHART,
  SCATTER_CHART,
  SINGLE_LINE_CHART,
  STACKED_100_COLUMN_CHART,
  STACKED_COLUMN_CHART,
} from "../../SelectVisuals/utils";

const MockDatasetContextMenu = ({ props }) => {
  const dispatch = useDispatch();

  const { specId } = useParams();
  const { specMockdataTabs, currentSelectedTab } = useSelector(
    (state) => state.mockDataset
  );
  const { specVisualization } = useSelector((state) => state.spec);
  const { labelId, labelDetails } = useSelector((state) => state?.copyLabel);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openRemoveColumn, setOpenRemoveColumn] = useState(false);
  const [removeColumnLoading, setRemoveColumnLoading] = useState(false);
  const [openPaste, setOpenPaste] = useState(false);
  const [pasteLoading, setPasteLoading] = useState(false);
  const [openConfigureRow, setOpenConfigureRow] = useState(false);
  const [currentProps, setCurrentProps] = useState(false);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const menus = [
    {
      id: "configure_rows",
      label: "Configure Rows",
      icon: <BorderColorOutlinedIcon fontSize='small' />,
      operation: () => {
        handleClose();
        setOpenConfigureRow(true);
      },
    },
    {
      id: "edit_label",
      label: "Edit Business Term",
      icon: <BorderColorOutlinedIcon fontSize='small' />,
      operation: () => {
        dispatch(
          setAddEditLabel({
            addEditLabelOpen: true,
            addEditLabelType: "EDIT",
            labelId: currentProps?.column?.colDef?.labelId,
          })
        );

        handleClose();
      },
    },
    {
      id: "add_column",
      label: "Add Column",
      icon: <AddRounded fontSize='small' />,
      operation: () => {
        handleClose();
        dispatch(
          setAddEditLabel({
            addEditLabelOpen: true,
            addEditLabelType: "ADD",
            labelId: currentProps?.column?.colDef?.labelId,
          })
        );
      },
    },
    {
      id: "remove_column",
      label: "Remove Column",
      icon: <DeleteOutline fontSize='small' />,
      operation: () => {
        handleClose();
        setOpenRemoveColumn(true);
      },
    },
    {
      id: "copy_column",
      label: "Copy Column",
      icon: <ContentCopyRounded fontSize='small' />,
      operation: () => {
        dispatch(
          setCopyLabelData({
            id: props?.column?.colDef?.field,
            labelDetails: props?.column?.colDef,
          })
        );
        toaster({ type: "info", message: "Column Copied!" });
      },
    },
  ];

  const filteredMenu =
    props?.column?.colId === "index"
      ? menus.filter(
          (item) => item?.id === "add_column" || item.id === "configure_rows"
        )
      : menus.filter((item) => item.id !== "configure_rows");

  const removeColumnFromMultipleSeries = (mockDataId, columnId) => {
    const localSpecVisualization = JSON.parse(
      JSON.stringify(specVisualization)
    );

    const updatedSpecVisualization = localSpecVisualization?.map(
      (visualization) => {
        if (
          visualization?.mockDataId === mockDataId &&
          visualization?.params?.multipleSeries?.length > 0 &&
          [
            MULTIPLE_LINE_CHART,
            STACKED_100_COLUMN_CHART,
            STACKED_COLUMN_CHART,
            BOX_PLOT,
          ].includes(visualization?.params?.id)
        ) {
          visualization.params.multipleSeries =
            visualization?.params?.multipleSeries?.filter(
              (series) => series?.y !== columnId
            );

          if (!visualization.params.multipleSeries.length) {
            visualization.params.multipleSeries = [];
            visualization.params.chartData = null;
          }

          if (visualization.params.x === columnId) {
            visualization.params.x = "";
            visualization.params.xTitle = "";
            visualization.params.chartData = null;
          }

          dispatch(updateSelectedChart(visualization));
        }

        // Single series
        if (
          visualization?.mockDataId === mockDataId &&
          !visualization?.params?.multipleSeries?.length &&
          [
            BAR_CHART,
            COLUMN_CHART,
            SINGLE_LINE_CHART,
            SCATTER_CHART,
            BOX_PLOT,
            PIE_CHART,
          ].includes(visualization?.params?.id)
        ) {
          if (visualization.params.x === columnId) {
            visualization.params.x = "";
            visualization.params.xTitle = "";
            visualization.params.chartData = null;
          }

          if (visualization.params.y === columnId) {
            visualization.params.y = "";
            visualization.params.yTitle = "";
            visualization.params.chartData = null;
          }

          dispatch(updateSelectedChart(visualization));
        }

        if (
          visualization?.mockDataId === mockDataId &&
          !visualization?.params?.multipleSeries?.length &&
          ["Statistics"].includes(visualization?.type)
        ) {
          if (visualization.params.x === columnId) {
            visualization.params.x = 0;
            visualization.params.value = "";
          }

          dispatch(updateSelectedChart(visualization));
        }

        if (
          visualization?.mockDataId === mockDataId &&
          !visualization?.params?.multipleSeries?.length &&
          ["Filter"].includes(visualization?.type)
        ) {
          if (visualization.params.x === columnId) {
            visualization.params.x = 0;
            visualization.params.options = [];
            visualization.params.activeOptions = [];
          }

          if (visualization.params.y === columnId) {
            visualization.params.y = 0;
            visualization.params.rangeOptions = [];
            visualization.params.from = "";
            visualization.params.to = "";
          }

          dispatch(updateSelectedChart(visualization));
        }

        return visualization;
      }
    );

    return updatedSpecVisualization;
  };

  const handleRemoveColumn = async () => {
    const columntoDelete = props?.column?.colId;

    const table = specMockdataTabs?.find(
      (tab) => tab?.id === currentSelectedTab
    )?.table;

    const updatedColumns = table?.columns?.filter(
      (column) => column?.columnId !== columntoDelete
    );

    const payload = {
      spec_mockdata_tab: {
        table: { columns: updatedColumns },
      },
    };

    setRemoveColumnLoading(true);

    try {
      const response = await updateTab({
        specId: specId,
        tabId: currentSelectedTab,
        payload: payload,
      });
      setRemoveColumnLoading(false);
      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }
      setOpenRemoveColumn(false);
      const newTable = response?.data?.specMockdataTab?.table;

      dispatch(
        replaceTable({
          tabId: currentSelectedTab,
          newTable: newTable,
        })
      );
      toaster({ type: "success", message: "Column removed successfully!" });

      const newVisualizationData = removeColumnFromMultipleSeries(
        currentSelectedTab,
        columntoDelete
      );
      dispatch(updateSpecVisualization(newVisualizationData));
      updateSpecification({
        payload: {
          specification: {
            spec_visualization_attributes: {
              visualization_data: newVisualizationData,
            },
          },
        },
        id: specId,
      }).then((response) => {
        // dispatch(resetSpecVisualization());
        // dispatch(getSpecVisualizationAsync({ specId }));
      });
    } catch (error) {
      setRemoveColumnLoading(false);
      toaster({ type: "error", message: "Error while removing column!" });
    }
  };

  const handlePaste = async () => {
    const payload = {
      dataset_column_id: labelId,
    };
    try {
      setPasteLoading(true);
      const response = await pasteLabel({
        specId: specId,
        tabId: currentSelectedTab,
        payload: payload,
      });

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }

      setOpenPaste(false);
      dispatch(resetCopyLabelData());

      const newTable = response?.data?.specMockdataTab?.table;

      dispatch(
        replaceTable({
          tabId: currentSelectedTab,
          newTable: newTable,
        })
      );
      setPasteLoading(false);
    } catch (error) {
      setPasteLoading(false);
    }
  };

  useEffect(() => {
    setCurrentProps(props);
  }, []);

  return (
    <>
      <IconButton
        sx={(theme) => ({
          p: 0,
          color: theme.palette.gray.iconColor,
        })}
        onClick={handleClick}
        onTouchStart={handleClick}>
        <MoreVert fontSize='small' color='inherit' />
      </IconButton>
      <CustomPopover open={open} anchorEl={anchorEl} onClose={handleClose}>
        {filteredMenu?.map((item, i) => {
          return (
            <MenuItem
              key={i}
              Icon={item?.icon}
              itemText={item?.label}
              itemProps={{ height: "15px" }}
              onClick={item?.operation}
            />
          );
        })}
        {labelId && (
          <MenuItem
            key={"Paste"}
            Icon={<ContentPaste fontSize='small' />}
            itemText={"Paste Column"}
            itemProps={{ height: "15px" }}
            onClick={() => {
              handleClose();
              setOpenPaste(true);
            }}
          />
        )}
      </CustomPopover>
      <DeleteConfirmationModal
        message={"Are you sure you want to remove this column?"}
        isOpen={openRemoveColumn}
        loading={removeColumnLoading}
        description={"This action can not be undone."}
        handleClose={() => {
          setOpenRemoveColumn(false);
        }}
        handleDelete={handleRemoveColumn}
        confirmationLabel='Remove'
      />
      <BaseModal
        isOpen={openPaste}
        handleClose={() => setOpenPaste(false)}
        title='Paste Column'
        isLoading={pasteLoading}
        dialogAction={
          <>
            <Stack flexDirection={"row"} columnGap={2}>
              <Button
                variant={"outlined"}
                color={"gray"}
                onClick={() => setOpenPaste(false)}>
                Cancel
              </Button>
              <LoadingButton
                onClick={handlePaste}
                variant='contained'
                loading={pasteLoading}>
                Paste
              </LoadingButton>
            </Stack>
          </>
        }>
        <Typography variant='spec_body'>
          Are You sure you want to paste{" "}
          <b>
            <em>{labelDetails?.label}</em>
          </b>{" "}
          column here?
        </Typography>
      </BaseModal>
      <ConfigureRows
        open={openConfigureRow}
        onClose={setOpenConfigureRow}
        props={props}
      />
    </>
  );
};

export default MockDatasetContextMenu;
