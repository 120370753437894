import { IconButton, Box, styled as muiStyled } from "@mui/material";

export const CommentsTextBox = muiStyled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.gray.light}`,
  borderRadius: "7px",
  "&:hover": {
    borderColor: theme.palette.primary.main,
  },
  "&:focus-within": {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));
export const CommentActionButton = muiStyled(IconButton)(({ theme }) => ({
  height: "15px",
  width: "15px",
}));
