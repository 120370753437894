import {
  Avatar,
  Box,
  Button,
  Grid,
  InputLabel,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import MainCard from "../../components/MainCard";
import { ChipRole } from "../../common/ChipRole/ChipRole";
import { stringAvatar } from "../../utils/commonUtility";
import { ROLES, ROLES_LABEL, ROLE_OPTIONS } from "../../constants";
import { specSecondary } from "../../constants/theme/colors";
import { InputField } from "../../common/InputField";
import { Field, Form, Formik } from "formik";
import { editProfileValidationSchema } from "../../utils/validationSchemas";
import CustomSelectField from "../../common/CustomSelectField";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { transferOwnership, updateUser } from "../../services";
import { HTTP_OK } from "../../constants/statusCodes";
import { defaultMessage } from "../../constants/messages";
import { toaster } from "../../common/Toaster";
import { updateAuthUser } from "../../store/slices/userAuth";
import LoadingButton from "../../common/LoadingButton";
import { matchPath, useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../router/routes";

const UserEditForm = ({ userData = {} }) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.userAuth);

  const isCompanyOwner = user?.companyOwner;
  const [loading, setLoading] = useState(false);
  const [transferLoading, setTransferLoading] = useState(false);

  const isEditProfile = matchPath(
    { path: ROUTE_PATH.EDIT_PROFILE, exact: true },
    location.pathname
  );

  const canTransferOwnership =
    user.companyOwner && user.id != userData.id && userData.role === "admin";

  const initialValues = {
    firstName: userData?.firstName ? userData?.firstName : "",
    lastName: userData?.lastName ? userData?.lastName : "",
    department: userData?.department ? userData?.department : "",
    position: userData?.position ? userData?.position : "",
    companyName: userData?.companyName ? userData?.companyName : "",
    email: userData?.email ? userData?.email : "",
    role: userData?.role
      ? ROLE_OPTIONS?.find((role) => role?.value === userData?.role)
      : null,
  };

  const onSubmit = (values, { resetForm, validateForm }) => {
    const myProfilePayload = {
      department: values?.department ? values?.department : "",
      first_name: values?.firstName ? values?.firstName : "",
      last_name: values?.lastName ? values?.lastName : "",
      job_title: values?.position ? values?.position : "",
    };

    const editUserDetailsPayload = {
      department: values?.department ? values?.department : "",
      first_name: values?.firstName ? values?.firstName : "",
      last_name: values?.lastName ? values?.lastName : "",
      job_title: values?.position ? values?.position : "",
      role: values?.role ? values?.role?.value : "",
    };

    if (userData?.id === user?.id) {
      setLoading(true);
      updateUser({ userId: userData?.id, payload: myProfilePayload })
        .then((response) => {
          setLoading(false);
          if (response?.status !== HTTP_OK) {
            throw new Error(defaultMessage);
          }

          dispatch(updateAuthUser({ user: response?.data?.user }));
          if (isEditProfile) {
            navigate(ROUTE_PATH.MY_PROFILE);
          } else {
            navigate(ROUTE_PATH.USER_MGT);
          }
          toaster({
            type: "success",
            message:
              response?.data?.toaster?.data?.message ||
              "User details successfully updated.",
          });
        })
        .catch((e) => {
          setLoading(false);
          toaster({ type: "error", message: e });
        });
    } else {
      setLoading(true);
      updateUser({ userId: userData?.id, payload: editUserDetailsPayload })
        .then((response) => {
          setLoading(false);
          if (response?.status !== HTTP_OK) {
            throw new Error(defaultMessage);
          }

          if (isEditProfile) {
            navigate(ROUTE_PATH.MY_PROFILE);
          } else {
            navigate(ROUTE_PATH.USER_MGT);
          }
          toaster({
            type: "success",
            message:
              response?.data?.toaster?.data?.message ||
              "User details successfully updated.",
          });
        })
        .catch((e) => {
          setLoading(false);
          toaster({ type: "error", message: e });
        });
    }
  };

  const handleTransferOwnership = () => {
    setTransferLoading(true);
    transferOwnership({ userId: userData?.id })
      .then((response) => {
        setTransferLoading(false);
        if (response?.status !== HTTP_OK) {
          throw new Error(defaultMessage);
        }
        window.location.replace(
          `${process.env.REACT_APP_MAIN_DOMAIN}${ROUTE_PATH.MY_PROFILE}`
        );

        toaster({
          type: "success",
          message:
            response?.data?.toaster?.data?.message ||
            "Ownership transferred successfully.",
        });
      })
      .catch((e) => {
        setTransferLoading(false);
        toaster({ type: "error", message: e });
      });
  };

  const handleChange = (field, value) => {
    field.onChange({
      target: {
        name: field.name,
        value: value,
      },
    });
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={editProfileValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
      onSubmit={onSubmit}
      enableReinitialize={true}>
      {({ touched, errors, values, isValid }) => (
        <Form
          style={{
            height: "100%",
          }}>
          <Grid
            container
            sx={{
              height: 1,
              overflowY: "auto",
              backgroundColor: "white",
            }}>
            <Box m={3} sx={{ width: "100%" }}>
              <Grid container spacing={2.5}>
                <Grid item sx={{ flex: 1 }} xs={12} sm={5} md={4} lg={4} xl={3}>
                  <MainCard
                    headerSX={{
                      display: "none",
                    }}
                    dividerSX={{
                      display: "none",
                    }}
                    sx={{
                      // height: "100%",
                      "& .MuiCardContent-root": {
                        display: "flex",
                        position: "relative",
                        // height: "100%",
                        alignItems: "center",
                      },
                    }}>
                    <ChipRole
                      role={
                        userData?.id === user?.id && user?.companyOwner
                          ? "owner"
                          : userData.role
                      }
                      // isSuperAdmin={user?.superAdmin}
                      size='small'
                      sx={{
                        position: "absolute",
                        right: 10,
                        top: 5,
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    />
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Stack spacing={2.5} alignItems='center'>
                          <Avatar
                            sx={(theme) => ({
                              bgcolor: specSecondary[50],
                              height: "150px",
                              width: "150px",
                            })}>
                            {values?.firstName && values?.lastName && (
                              <Typography
                                sx={{
                                  fontFamily: "Poppins",
                                  fontSize: "56px",
                                  fontWeight: 400,
                                }}
                                color={"secondary.main"}>
                                {
                                  stringAvatar(
                                    `${values?.firstName} ${values?.lastName}`
                                  )?.children
                                }
                              </Typography>
                            )}
                          </Avatar>
                          <Stack spacing={0.5} alignItems='center'>
                            <Typography variant='spec_h2'>{`${values.firstName} ${values.lastName}`}</Typography>
                            <Typography color='gray.main' variant='spec_body'>
                              {userData?.id === user?.id && user?.companyOwner
                                ? "owner"
                                : ROLES_LABEL[userData.role]}
                            </Typography>
                          </Stack>
                          <Stack spacing={0.5} alignItems='center'>
                            {canTransferOwnership && (
                              <LoadingButton
                                loading={transferLoading}
                                onClick={handleTransferOwnership}
                                variant={"contained"}
                                sx={{
                                  borderRadius: "20px",
                                }}>
                                Transfer ownership
                              </LoadingButton>
                            )}
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </MainCard>
                </Grid>
                <Grid item sx={{ flex: 1 }} xs={12} sm={7} md={8} lg={8} xl={9}>
                  <MainCard title='Personal Details' sx={{ height: "100%" }}>
                    <List sx={{ py: 0 }}>
                      <ListItem>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                              <Field name='firstName'>
                                {({ field, form }) => (
                                  <InputField
                                    isLabel={true}
                                    label='First Name'
                                    name='firstName'
                                    placeholder='Enter first name'
                                    value={field.value}
                                    onChange={({ currentTarget: input }) =>
                                      handleChange(field, input.value)
                                    }
                                    onBlur={() => {
                                      form.setTouched({
                                        ...form.touched,
                                        firstName: true,
                                      });
                                    }}
                                    error={
                                      form.touched.firstName &&
                                      Boolean(form.errors.firstName)
                                    }
                                    helperText={
                                      form.touched.firstName &&
                                      form.errors.firstName
                                    }
                                  />
                                )}
                              </Field>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                              <Field name='lastName'>
                                {({ field, form }) => (
                                  <InputField
                                    isLabel={true}
                                    label='Last Name'
                                    name='lastName'
                                    placeholder='Enter last name'
                                    value={field.value}
                                    onChange={({ currentTarget: input }) =>
                                      handleChange(field, input.value)
                                    }
                                    onBlur={() => {
                                      form.setTouched({
                                        ...form.touched,
                                        lastName: true,
                                      });
                                    }}
                                    error={
                                      form.touched.lastName &&
                                      Boolean(form.errors.lastName)
                                    }
                                    helperText={
                                      form.touched.lastName &&
                                      form.errors.lastName
                                    }
                                  />
                                )}
                              </Field>
                            </Stack>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                              <Field name='department'>
                                {({ field, form }) => (
                                  <InputField
                                    isLabel={true}
                                    label='Department'
                                    name='department'
                                    placeholder='Enter your department'
                                    value={field.value}
                                    onChange={({ currentTarget: input }) =>
                                      handleChange(field, input.value)
                                    }
                                    onBlur={() => {
                                      form.setTouched({
                                        ...form.touched,
                                        department: true,
                                      });
                                    }}
                                    error={
                                      form.touched.department &&
                                      Boolean(form.errors.department)
                                    }
                                    helperText={
                                      form.touched.department &&
                                      form.errors.department
                                    }
                                  />
                                )}
                              </Field>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                              <Field name='position'>
                                {({ field, form }) => (
                                  <InputField
                                    isLabel={true}
                                    label='Position'
                                    name='position'
                                    placeholder='Enter your position/job title'
                                    value={field.value}
                                    onChange={({ currentTarget: input }) =>
                                      handleChange(field, input.value)
                                    }
                                    onBlur={() => {
                                      form.setTouched({
                                        ...form.touched,
                                        position: true,
                                      });
                                    }}
                                    error={
                                      form.touched.position &&
                                      Boolean(form.errors.position)
                                    }
                                    helperText={
                                      form.touched.position &&
                                      form.errors.position
                                    }
                                  />
                                )}
                              </Field>
                            </Stack>
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                              <Field name='companyName'>
                                {({ field, form }) => (
                                  <InputField
                                    isLabel={true}
                                    label='Company Name'
                                    name='companyName'
                                    placeholder='Enter your company name'
                                    value={field.value}
                                    disabled
                                    onChange={({ currentTarget: input }) =>
                                      handleChange(field, input.value)
                                    }
                                    onBlur={() => {
                                      form.setTouched({
                                        ...form.touched,
                                        companyName: true,
                                      });
                                    }}
                                    error={
                                      form.touched.companyName &&
                                      Boolean(form.errors.companyName)
                                    }
                                    helperText={
                                      form.touched.companyName &&
                                      form.errors.companyName
                                    }
                                  />
                                )}
                              </Field>
                            </Stack>
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                              <Field name='email'>
                                {({ field, form }) => (
                                  <InputField
                                    name='email'
                                    placeholder='Enter email address'
                                    isLabel={true}
                                    label='Email'
                                    variant={"outlined"}
                                    value={field.value}
                                    disabled
                                    onChange={({ currentTarget: input }) =>
                                      handleChange(field, input.value)
                                    }
                                    onBlur={() => {
                                      form.setTouched({
                                        ...form.touched,
                                        email: true,
                                      });
                                    }}
                                    error={
                                      form.touched.email &&
                                      Boolean(form.errors.email)
                                    }
                                    helperText={
                                      form.touched.email && form.errors.email
                                    }
                                  />
                                )}
                              </Field>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={0.5}>
                              <InputLabel>
                                <Typography variant='spec_inputLabel'>
                                  {`Role`}
                                </Typography>
                                <Typography
                                  variant='spec_caption'
                                  color={"gray.main"}>
                                  {values?.role?.value === ROLES.ADMIN &&
                                    "Admin counts as one editor seat*"}
                                </Typography>
                              </InputLabel>
                              {isCompanyOwner && userData?.id !== user?.id ? (
                                <Field name='role'>
                                  {({ field, form }) => (
                                    <CustomSelectField
                                      name='role'
                                      isLabel={false}
                                      options={ROLE_OPTIONS}
                                      isMulti={false}
                                      value={field?.value}
                                      onChange={(selectedOption) =>
                                        handleChange(field, selectedOption)
                                      }
                                      onBlur={() => {
                                        form.setTouched({
                                          ...form.touched,
                                          role: true,
                                        });
                                      }}
                                      error={
                                        form.touched.role &&
                                        Boolean(form.errors.role)
                                      }
                                      helperText={
                                        form.touched.role && form.errors.role
                                      }
                                    />
                                  )}
                                </Field>
                              ) : (
                                <InputField
                                  isLabel={false}
                                  disabled
                                  value={
                                    isCompanyOwner
                                      ? "Owner"
                                      : ROLES_LABEL[values?.role?.value]
                                  }
                                />
                              )}
                            </Stack>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </List>
                  </MainCard>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction='row'
                    justifyContent='flex-end'
                    alignItems='center'
                    columnGap={1}>
                    <Button
                      variant='outlined'
                      color='gray'
                      disabled={loading}
                      onClick={() => {
                        if (isEditProfile) {
                          navigate(ROUTE_PATH?.MY_PROFILE);
                        } else {
                          navigate(ROUTE_PATH?.USER_MGT);
                        }
                      }}>
                      Cancel
                    </Button>
                    <LoadingButton
                      variant='contained'
                      color='primary'
                      type='submit'
                      loading={loading}
                      disabled={
                        _.isEqual(initialValues, values) ||
                        (errors && Object.keys(errors)?.length !== 0)
                      }>
                      Update
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default UserEditForm;
