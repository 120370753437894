import {
  ListItem,
  ToggleButtonGroup,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { specPrimary, themePalette } from "../constants/theme/colors";
import { ExpandMoreRounded } from "@mui/icons-material";

export const TabGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: "#FFF",
  "& .MuiToggleButton-root": {
    paddingTop: "2px",
    paddingBottom: "2px",
    lineHeight: "22px",
    textTransform: "capitalize",
    "&:not(:first-of-type)": {
      marginLeft: "-2px",
    },

    border: `2px solid ${theme.palette.gray.borderGray}`,
    "&:hover": {
      "&:not(:first-of-type)": {
        position: "relative",
        zIndex: 1,
      },
      "&:not(:last-of-type)": {
        zIndex: 1,
      },
      border: "2px solid",
      backgroundColor: `${themePalette.palette.secondary.white}!important`,
      borderColor: `${alpha(themePalette.palette.primary.main, 0.5)}!important`,
    },
    "&.Mui-selected": {
      ".MuiTypography-root": {
        fontWeight: 600,
      },
      border: "2px solid",
      zIndex: 2,
      backgroundColor: `${themePalette.palette.secondary.white}!important`,
      borderColor: `${themePalette.palette.primary.main}!important`,
    },
  },
}));

export const SideBarItem = styled(ListItem)(({ theme }) => ({
  cursor: "pointer",
  padding: "8px",
  paddingLeft: "16px",
  wordBreak: "break-word",
  "&:hover": {
    backgroundColor: specPrimary["50"],
  },
  "&.active": {
    backgroundColor: specPrimary["50"],
  },
}));

export const ChartItem = styled(ListItem)(({ theme }) => ({
  width: "50%",
  padding: "16px",
  marginBottom: "16px",
  cursor: "pointer",

  img: {
    width: "50px",
    height: "50px",
    marginRight: 16,
  },
  "&:hover": {
    backgroundColor: specPrimary["50"],
  },
  "&.active": {
    backgroundColor: specPrimary["50"],
  },
}));

export const MuiSelectField = styled((props) => (
  <Select
    {...props}
    IconComponent={(iconProps) => (
      <ExpandMoreRounded {...iconProps} sx={{ mr: 1 }} />
    )}
  />
))(({ theme }) => ({
  "&.MuiInputBase-root": {
    borderRadius: "7px",
    height: "38px !important",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "24px",
  },
  "& .MuiInputBase-root:hover": {
    borderColor: `${specPrimary[600]} !important`,
  },

  "&:hover": {
    "&& fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },

  "& .MuiSelect-select": {
    padding: "6px 16px",
  },
}));

export const OptionLabel = ({
  label = "",
  size = "25",
  variant = "spec_body",
  tooltipVariant = "spec_body",
  displaySx,
}) => {
  // eslint-disable-next-line no-lone-blocks
  {
    return label?.length >= size ? (
      <Tooltip
        title={
          <Typography
            variant={tooltipVariant}
            color='common.white'
            sx={{
              wordBreak: "break-word",
            }}>
            {label}
          </Typography>
        }
        arrow>
        <Typography
          variant={variant}
          color='text.primary'
          sx={{
            wordBreak: "break-word",
            ...displaySx,
          }}>
          {label?.slice(0, size)}...
        </Typography>
      </Tooltip>
    ) : (
      <Typography
        variant={variant}
        color='text.primary'
        sx={{
          wordBreak: "break-word",
          ...displaySx,
        }}>
        {label}
      </Typography>
    );
  }
};
