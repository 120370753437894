import React, { useState } from "react";
import { StyledAppBar, StyledToolbar } from "./style";
import { Link, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmationModal from "../../common/DeleteConfirmationModal";
import { toaster } from "../../common/Toaster";
import { ROUTE_PATH } from "../../router/routes";
import Cookies from "js-cookie";
import { persistor } from "../../store";
import storage from "redux-persist/lib/storage";
import { deactivateCompany } from "../../services";
import { matchPath } from "react-router-dom";

const AccountsAppBar = () => {
  const { user } = useSelector((state) => state.userAuth);

  const { company } = user;

  const [seats, setSeats] = useState(company?.currentPlan?.seats || 0);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDelete = () => {
    setDeleteLoading(true);
    deactivateCompany()
      .then((response) => {
        setDeleteLoading(false);
        Cookies.remove("token", { domain: `${process.env.REACT_APP_DOMAIN}` });
        Cookies.remove("subdomain", {
          domain: `${process.env.REACT_APP_DOMAIN}`,
        });
        persistor.purge();
        storage.removeItem("persist:root");
        localStorage.clear();
        window.location.pathname = ROUTE_PATH.LOGIN;
      })
      .catch((error) => {
        setDeleteLoading(false);
        toaster({ type: "error", error });
      });
  };

  const isBilling = matchPath(
    { path: ROUTE_PATH.BILLING, exact: true },
    location.pathname
  );

  return (
    <>
      <StyledAppBar>
        <StyledToolbar>
          <Stack
            sx={{
              px: { xs: 1, md: 2 },
            }}
            width={1}
            columnGap={2}
            pl={"42px"}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}>
            <Typography variant='spec_h2' sx={{ whiteSpace: "nowrap" }}>
              Manage Account
            </Typography>
            {isBilling && (
              <Stack
                direction={"row"}
                columnGap={1}
                alignItems={"center"}
                sx={{
                  alignItems: "flex-end",
                  overflowX: "scroll",
                  scrollbarWidth: "none",
                  "::-webkit-scrollbar": {
                    display: "none",
                  },
                }}>
                <Link
                  variant='spec_caption'
                  color='secondary'
                  sx={{ textDecoration: "none", whiteSpace: "nowrap" }}
                  target={"_blank"}
                  href={
                    "https://billing.spectio.com/p/login/aEUaG0bGy6eS6PK288"
                  }>
                  Manage Your Billing
                </Link>
                <Typography variant='spec_caption'>|</Typography>
                <Stack>
                  {company?.currentPlan?.planName?.toLowerCase() === "trial" ? (
                    <Typography
                      variant='spec_caption'
                      sx={{ whiteSpace: "nowrap" }}>
                      Unlimited editor seats available in your free trial
                    </Typography>
                  ) : (
                    <Typography
                      variant='spec_caption'
                      sx={{ whiteSpace: "nowrap" }}>
                      {company?.currentPlan?.availableSeats} / {seats} editor{" "}
                      {seats === 1 ? "seat" : "seats"} available in your plan
                    </Typography>
                  )}
                </Stack>
                <Typography variant='spec_caption'>|</Typography>
                <Stack direction={"row"} columnGap={1}>
                  <Typography
                    variant='spec_caption'
                    sx={{ whiteSpace: "nowrap" }}>
                    Want to close your account?
                  </Typography>
                  <Typography
                    variant='spec_caption'
                    color='error'
                    sx={{
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                      setIsDeleteOpen(true);
                    }}>
                    Deactivate your plan
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        </StyledToolbar>
      </StyledAppBar>
      <DeleteConfirmationModal
        loading={deleteLoading}
        isOpen={isDeleteOpen}
        message={`Are you sure you want delete ${user?.company?.name}?`}
        description='This action can not be undone!'
        handleDelete={handleDelete}
        handleClose={() => setIsDeleteOpen(false)}
      />
    </>
  );
};

export default AccountsAppBar;
