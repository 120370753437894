import { getStatuses } from "../../services";
import { HTTP_OK } from "../../constants/statusCodes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultExceptionHandler } from "../../utils/defaultExceptionHandler";

const initialState = {
  // STATUS STATES
  specStatuses: [],
  isStatusesLoading: false,
  isStatusAddEditOpen: false,
  statusAddEditData: null,
  statusAddEditType: "ADD",
};

export const getStatusesAsync = createAsyncThunk(
  "statuses/getStatusesAsync",
  async (payload) => {
    try {
      const response = await getStatuses();

      if (response?.status !== HTTP_OK) {
        throw new Error("Something went wrong!");
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

const statusesSlice = createSlice({
  name: "statuses",
  initialState: initialState,

  reducers: {
    setStatusAddEditModal: (state, action) => {
      state.statusAddEditData = action?.payload?.statusAddEditData
        ? action?.payload?.statusAddEditData
        : null;
      state.isStatusAddEditOpen = true;
      state.statusAddEditType = action?.payload?.statusAddEditType
        ? action.payload.statusAddEditType
        : "ADD";
    },
    resetStatusAddEditModal: (state, action) => {
      state.statusAddEditData = null;
      state.isStatusAddEditOpen = false;
      state.statusAddEditType = "ADD";
    },
  },

  extraReducers: (builder) => {
    // GET STATUSES BUILDER FUNCTIONS
    builder.addCase(getStatusesAsync.pending, (state, action) => {
      state.isStatusesLoading = true;
    });
    builder.addCase(getStatusesAsync.fulfilled, (state, action) => {
      state.isStatusesLoading = false;
      state.specStatuses = action?.payload?.specStatuses;
    });
    builder.addCase(getStatusesAsync.rejected, (state) => {
      state.isStatusesLoading = false;
    });
  },
});

export const { setStatusAddEditModal, resetStatusAddEditModal } =
  statusesSlice.actions;
export default statusesSlice.reducer;
