import React from "react";
import { Box } from "@mui/material";
import { ContentWrapper } from "./style";
import { Outlet } from "react-router-dom";
import AccountsAppBar from "../../components/AccountsAppBar";
import { AccountsSidebar } from "../../components/AccountsSidebar";

const AccountLayout = ({ children }) => {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        backgroundColor: "pink",
        dispaly: "flex !important",
        flexDirection: "row !important",
      }}>
      <AccountsAppBar />

      <Box
        sx={{
          display: "flex",
          height: 1,
          pt: "45px",
          overflowY: "auto",
        }}>
        <AccountsSidebar />
        <ContentWrapper>
          {children}
          <Outlet />
        </ContentWrapper>
      </Box>
    </Box>
  );
};

export default AccountLayout;
