import React, { useState } from "react";

import { Box, Button, Stack, Typography, useTheme } from "@mui/material";

import { StyledUserMenu } from "./style";
import { ArrowDownwardOutlined, KeyboardArrowDown } from "@mui/icons-material";

const HistoryFilters = ({
  title = "",
  options = [],
  width,
  selected,
  onSelect,
}) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        // size="small"
        onClick={handleClick}
        variant='outlined'
        sx={{
          border: `1px solid ${theme.palette.grey[300]}`,
          color: selected
            ? theme.palette.primary.dark
            : theme.palette.secondary.textColor,
        }}
        endIcon={
          <KeyboardArrowDown
            style={{ fontSize: "15px", color: theme.palette.grey[500] }}
          />
        }>
        <Box
          component='span'
          sx={{
            fontSize: "14px",
            lineHeight: "22px",
          }}>
          {selected ? selected?.label : title}
        </Box>
      </Button>
      <StyledUserMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPopover-paper": {
            width: width,
          },
        }}>
        <Stack rowGap={1}>
          {options?.map((option, i) => (
            <Box
              onClick={() => {
                onSelect(option);
                handleClose();
              }}
              key={i}
              sx={{
                cursor: "pointer",
                p: 0.5,
                px: 1,
                borderRadius: "3px",
                backgroundColor:
                  option?.value === selected?.value
                    ? theme.palette.primary.light
                    : "",
                "&:hover": {
                  backgroundColor:
                    option?.value === selected?.value
                      ? theme.palette.primary.light
                      : theme.palette.grey[100],
                },
              }}>
              <Typography variant='spec_body'>{option?.label} </Typography>
            </Box>
          ))}
        </Stack>
      </StyledUserMenu>
    </>
  );
};

export default HistoryFilters;
