import { Field, Form, Formik } from "formik";
import { useParams } from "react-router-dom";
import React, { useRef, useState } from "react";
import { updateTab } from "../../../../services";
import { toaster } from "../../../../common/Toaster";
import BaseModal from "../../../../common/BaseModal";
import { useDispatch, useSelector } from "react-redux";
import { InputField } from "../../../../common/InputField";
import { HTTP_OK } from "../../../../constants/statusCodes";
import { defaultMessage } from "../../../../constants/messages";
import { updateSpecTab } from "../../../../store/slices/mockDataset";
import { configureRowValidationSchema } from "../../../../utils/validationSchemas";

const ConfigureRows = ({ open, onClose }) => {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const { specId } = useParams();
  const { specMockdataTabs, currentSelectedTab } = useSelector(
    (state) => state.mockDataset
  );

  const [configrowLoading, setConfigRowLoading] = useState(false);

  const handleClose = (resetForm, validateForm) => {
    resetForm({ values: initialValues });
    setTimeout(() => {
      validateForm();
      onClose(false);
    }, 0);
  };

  const currentRowLimit = specMockdataTabs?.find(
    (tab) => tab?.id === currentSelectedTab
  )?.rowLimit;

  const initialValues = {
    noOfRows: currentRowLimit,
  };

  const onSubmit = async (values, { resetForm, validateForm }) => {
    const newRowLimit = values?.noOfRows;
    const payload = {
      spec_mockdata_tab: {
        row_limit: newRowLimit,
      },
    };

    setConfigRowLoading(true);
    try {
      const response = await updateTab({
        specId: specId,
        tabId: currentSelectedTab,
        payload: payload,
      });

      setConfigRowLoading(false);
      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }

      toaster({ type: "success", message: "Row limit updated successfully." });
      dispatch(
        updateSpecTab({
          tabId: currentSelectedTab,
          newTab: response?.data?.specMockdataTab,
        })
      );
      handleClose(resetForm, validateForm);
    } catch (error) {
      setConfigRowLoading(false);
      toaster({ type: "error", message: "Error while updating row limit!" });
    }
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleChange = (field, value) => {
    field.onChange({
      target: {
        name: field.name,
        value: value,
      },
    });
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={true}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
      initialTouched={true}
      validationSchema={configureRowValidationSchema}>
      {({ errors, resetForm, validateForm }) => (
        <Form>
          <BaseModal
            title='Configure rows'
            confirmTitle='Save'
            isOpen={open}
            isLoading={configrowLoading}
            submitDisable={errors && Object.keys(errors)?.length !== 0}
            handleClose={() => handleClose(resetForm, validateForm)}
            handleSubmit={handleSubmitClick}>
            <Field name='noOfRows'>
              {({ field, form }) => (
                <InputField
                  name='noOfRows'
                  placeholder='Enter tab name'
                  isLabel={true}
                  label='Number of rows (Max. 30)'
                  variant={"outlined"}
                  value={field.value}
                  onChange={({ currentTarget: input }) =>
                    handleChange(field, input.value)
                  }
                  onBlur={() => {
                    form.setTouched({
                      ...form.touched,
                      noOfRows: true,
                    });
                  }}
                  error={form.touched.noOfRows && Boolean(form.errors.noOfRows)}
                  helperText={form.touched.noOfRows && form.errors.noOfRows}
                />
              )}
            </Field>
          </BaseModal>
        </Form>
      )}
    </Formik>
  );
};

export default ConfigureRows;
