import { Box, Stack, useTheme, InputLabel, Typography } from "@mui/material";
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";
import Select, { components } from "react-select";
import React from "react";
import { FormHelperText } from "@mui/material";
import { selectStyle } from "./style";
import { ExpandMoreRoundedIcon } from "../../assets";
// import { DropdownIndicatorIcon } from "../../constants/images";

const CustomSelectField = ({
  name = "",
  label = "",
  value = null,
  async = false,
  isLabel = false,
  isCreatable = false,
  isRequired = false,
  error,
  onBlur,
  options,
  disabled,
  onChange,
  helperText,
  loadOptions,
  menuPlacement,
  isClearable,
  inputValue,
  onInputChange,
  labelTypographySx,
  formatOptionLabel,
  maxMenuHeight = 170,
  placeholder = "Select",
  optionsLoading = false,
  noOptionsMessage,
  handleOnCreateOption,
  isMulti = true,
  formatCreateLabel,
}) => {
  const theme = useTheme();
  const SelectComponent = async
    ? AsyncSelect
    : isCreatable
    ? CreatableSelect
    : Select;

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ExpandMoreRoundedIcon />
      </components.DropdownIndicator>
    );
  };

  return (
    <Stack sx={{ width: 1 }}>
      {isLabel && (
        <InputLabel
          htmlFor={name}
          required={isRequired}
          error={error}
          sx={{
            mb: 0.65,
            ".MuiFormLabel-asterisk": {
              color: "secondary.error",
            },
            textAlign: "left",
          }}>
          <Typography
            variant='spec_inputLabel'
            sx={{
              color: "secondary.black",
              ...labelTypographySx,
            }}>
            {label}
          </Typography>
        </InputLabel>
      )}
      <SelectComponent
        styles={selectStyle(error, theme)}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator,
        }}
        {...(!async && {
          options: options,
        })}
        {...(async && {
          loadOptions: loadOptions,
        })}
        {...(onBlur && {
          onBlur: (e) => {
            if (name) {
              e.target.name = name;
            }
            onBlur(e);
          },
        })}
        isMulti={isMulti}
        inputValue={inputValue}
        onInputChange={onInputChange}
        cacheOptions
        value={value}
        closeMenuOnSelect
        onChange={onChange}
        isSearchable={true}
        defaultOptions={true}
        isDisabled={disabled}
        loadOptions={loadOptions}
        placeholder={placeholder}
        maxMenuHeight={maxMenuHeight}
        menuPortalTarget={document.body}
        formatOptionLabel={formatOptionLabel}
        menuPlacement={menuPlacement ? menuPlacement : "auto"}
        isClearable={isClearable}
        isLoading={optionsLoading}
        noOptionsMessage={noOptionsMessage}
        onCreateOption={handleOnCreateOption}
        formatCreateLabel={formatCreateLabel}
      />
      <Box>
        {error && helperText ? (
          <FormHelperText error={error} sx={{ m: 0 }}>
            <Typography
              variant='helperText'
              sx={{ margin: 0, fontFamily: "Poppins" }}>
              {helperText}
            </Typography>
          </FormHelperText>
        ) : null}
      </Box>
    </Stack>
  );
};

export default CustomSelectField;
