import { useEffect, useState } from "react";

import {
  BorderColorOutlined,
  ContentCopy,
  ContentPasteRounded,
  ControlCameraOutlined,
  DeleteOutline,
  EditOffOutlined,
  Preview,
  Visibility,
  VisibilityOff,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import MainCard from "../MainCard";
import { getCharts, getDefaultCharts } from "../../utils/chartUtilities";
import { specPrimary } from "../../constants/theme/colors";
import { useDispatch, useSelector } from "react-redux";
import { setCopiedVisualization } from "../../store/slices/userSpec";
import CustomPopover from "../../common/CustomPopover";
import MenuItem from "../../common/MenuItem";

//TODO refactor this component. It is too big and has too many dependencies
export const VisualCard = ({
  chart,
  index,
  editIndex,
  handleDelete,
  handleEdit,
  onChangeVisual,
  moveItem,
  mockDataset,
  isPreview = false,
  filterSettings = null,
  setOpenSideBar,
  label,
  handlePaste = () => {},
}) => {
  const isSelected = index === editIndex;
  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <>
      <MainCard
        key={index}
        border={!isSelected}
        id={`main_card`}
        title={chart?.label}
        sx={{ height: "100%" }}
        onContextMenu={handleContextMenu}
        contentSX={{ height: "80%", width: "100%" }}
        cardSelected={isSelected}
        secondary={
          <Stack direction='row' spacing={0.5}>
            {!isPreview && (
              <>
                <Tooltip
                  title={<Typography variant='spec_tooltip'>Drag</Typography>}
                  arrow>
                  <IconButton>
                    <ControlCameraOutlined
                      fontSize='small'
                      style={{ color: "#424242" }}
                      className='handleDraggableElement'
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    <Typography variant='spec_tooltip'>Duplicate</Typography>
                  }
                  arrow>
                  <IconButton
                    onClick={(e) => {
                      handlePaste(chart?.mockDataId, "chart");
                    }}>
                    <ContentCopy
                      fontSize='small'
                      style={{ color: "#424242" }}
                      className='handleDraggableElement'
                    />
                  </IconButton>
                </Tooltip>
              </>
            )}
            <Tooltip
              title={
                <Typography variant='spec_tooltip'>
                  {isSelected
                    ? "Close Configuration"
                    : isPreview
                    ? "View Configuration"
                    : "Configure"}
                </Typography>
              }
              arrow>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  handleEdit(index);
                  // openModal(index);
                  if (!isSelected) {
                    setOpenSideBar();
                  }
                }}
                className='cancelDraggableElement'>
                {isSelected ? (
                  !isPreview ? (
                    <EditOffOutlined
                      fontSize='small'
                      style={{ color: "#424242" }}
                    />
                  ) : (
                    <VisibilityOff
                      fontSize='small'
                      style={{ color: "#424242" }}
                    />
                  )
                ) : !isPreview ? (
                  <BorderColorOutlined
                    fontSize='small'
                    style={{ color: "#424242" }}
                  />
                ) : (
                  <Visibility fontSize='small' style={{ color: "#424242" }} />
                )}
              </IconButton>
            </Tooltip>
            {!isPreview && (
              <Tooltip
                title={<Typography variant='spec_tooltip'>Delete</Typography>}
                arrow>
                <IconButton
                  onClick={() => handleDelete(index, chart?.mockDataId)}
                  className='cancelDraggableElement'>
                  <DeleteOutline
                    fontSize='small'
                    style={{ color: "#424242" }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        }>
        {chart?.params?.chartData ? (
          <>
            {getCharts(
              chart.params?.id,
              chart?.params?.chartData,
              chart.params?.xTitle,
              chart.params?.metric
                ? `${chart.params?.yTitle} (${chart.params?.metric})`
                : chart.params?.yTitle,
              chart.params?.timeTitle,
              chart?.params.metric,
              chart?.params?.multipleSeries,
              filterSettings,
              chart?.params?.isInverted
            )}
          </>
        ) : (
          <>
            {getDefaultCharts(chart.params?.id)}
            <Typography sx={{ textAlign: "center", marginTop: "-40px" }}>
              Configure Chart with Mock Data
            </Typography>
          </>
        )}
      </MainCard>
      <CustomPopover
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }>
        <MenuItem
          Icon={<ContentCopy fontSize='small' />}
          itemText={"Duplicate"}
          itemProps={{ height: "15px" }}
          onClick={() => {
            handlePaste(chart?.mockDataId, "chart");
            handleClose();
          }}
        />
      </CustomPopover>
    </>
  );
};
