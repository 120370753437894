import { getAnnouncements } from "../../services";
import { HTTP_OK } from "../../constants/statusCodes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultExceptionHandler } from "../../utils/defaultExceptionHandler";

const initialState = {
  announcements: [],
  isAnnouncementsLoading: false,
};

export const getAnnouncementsAsync = createAsyncThunk(
  "announcements/getAnnouncementsAsync",
  async (payload) => {
    try {
      const response = await getAnnouncements();

      if (response?.status !== HTTP_OK) {
        throw new Error("Something went wrong!");
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

const announcementsSlice = createSlice({
  name: "announcements",
  initialState: initialState,

  reducers: {
    // REDUCERS
    closeAnnouncement: (state, action) => {
      state.announcements = [];
    },
  },

  extraReducers: (builder) => {
    // GET STATUSES BUILDER FUNCTIONS
    builder.addCase(getAnnouncementsAsync.pending, (state, action) => {
      state.isAnnouncementsLoading = true;
    });
    builder.addCase(getAnnouncementsAsync.fulfilled, (state, action) => {
      state.isAnnouncementsLoading = false;
      state.announcements = action?.payload?.announcements;
    });
    builder.addCase(getAnnouncementsAsync.rejected, (state) => {
      state.isAnnouncementsLoading = false;
    });
  },
});

export const { closeAnnouncement } = announcementsSlice.actions;
export default announcementsSlice.reducer;
