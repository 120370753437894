import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { SquareIconButton } from "../../../common/commonStyle";
import {
  BorderColorOutlined,
  DeleteOutline,
  MoreVert,
} from "@mui/icons-material";
import CustomPopover from "../../../common/CustomPopover";
import MenuItem from "../../../common/MenuItem";
import { useDispatch } from "react-redux";
import {
  getMappingColumnsAsync,
  setMappingColumnsAddEditModal,
} from "../../../store/slices/mappingSettings";
import DeleteConfirmationModal from "../../../common/DeleteConfirmationModal";
import { deleteMappingsColumn } from "../../../services";
import { toaster } from "../../../common/Toaster";
import { HTTP_OK } from "../../../constants/statusCodes";
import { defaultMessage } from "../../../constants/messages";

const ActionCell = (props) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setIsDeleteLoading(true);
    deleteMappingsColumn({ rowId: props?.data?.id })
      .then((response) => {
        if (response?.status !== HTTP_OK) {
          throw new Error(defaultMessage);
        }

        dispatch(getMappingColumnsAsync());
        setIsDeleteLoading(false);
        setIsDeleteOpen(false);
        toaster({
          type: "success",
          message:
            response?.data?.toaster?.data?.message ||
            "Column was successfully deleted.",
        });
      })
      .catch((e) => {
        setIsDeleteLoading(false);
        toaster({
          type: "error",
          message: e,
        });
      });
  };

  return (
    <>
      <Stack
        direction={"row"}
        sx={{ width: 1, height: 1, alignItems: "center", columnGap: 2 }}>
        <Typography variant='spec_body'>{props?.value}</Typography>
        {(props?.data?.kind === "custom" ||
          props?.data?.originalKey === "businessTermCategory") && (
          <SquareIconButton
            sx={{ height: "max-content" }}
            onClick={handleClick}>
            <MoreVert />
          </SquareIconButton>
        )}
      </Stack>
      <CustomPopover open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem
          Icon={<BorderColorOutlined fontSize='small' />}
          itemText={"Edit"}
          itemProps={{ height: "15px" }}
          onClick={() => {
            handleClose();
            dispatch(
              setMappingColumnsAddEditModal({
                mappingColumnsAddEditData: props?.data,
                mappingColumnsAddEditType: "EDIT",
              })
            );
          }}
        />
        {props?.data?.originalKey !== "businessTermCategory" && (
          <MenuItem
            Icon={<DeleteOutline fontSize='small' />}
            itemText={"Delete"}
            itemProps={{ height: "15px" }}
            onClick={() => {
              handleClose();
              setIsDeleteOpen(true);
            }}
          />
        )}
      </CustomPopover>
      <DeleteConfirmationModal
        isOpen={isDeleteOpen}
        handleClose={() => setIsDeleteOpen(false)}
        message={`Are you sure you want to delete ${props?.data?.title}?`}
        description='This operation cannot be undone.'
        handleDelete={handleDelete}
        loading={isDeleteLoading}
      />
    </>
  );
};

export default ActionCell;
