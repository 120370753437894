import {
  DeleteOutline,
  MoreHorizRounded,
  BorderColorOutlined,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import MenuItem from "../../../common/MenuItem";
import { deletePriority } from "../../../services";
import { toaster } from "../../../common/Toaster";
import CustomPopover from "../../../common/CustomPopover";
import { SquareIconButton } from "../../../common/commonStyle";
import DeleteConfirmationModal from "../../../common/DeleteConfirmationModal";
import {
  getPrioritiesAsync,
  setPriorityAddEditModal,
} from "../../../store/slices/priorities";

const ActionCell = (props) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setIsDeleteLoading(true);
    deletePriority({ priorityId: props?.data?.id })
      .then((response) => {
        dispatch(getPrioritiesAsync());
        setIsDeleteLoading(false);
        setIsDeleteOpen(false);
        toaster({
          type: "success",
          message:
            response?.data?.toaster?.data?.message ||
            "Priority was successfully deleted.",
        });
      })
      .catch((e) => {
        setIsDeleteLoading(false);
        toaster({
          type: "error",
          message: e,
        });
      });
  };

  return (
    <>
      <Stack
        width={1}
        height={1}
        justifyContent={"center"}
        alignItems={"center"}>
        <SquareIconButton onClick={handleClick}>
          <MoreHorizRounded />
        </SquareIconButton>
      </Stack>
      <CustomPopover open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem
          Icon={<BorderColorOutlined fontSize='small' />}
          itemText={"Edit"}
          itemProps={{ height: "15px" }}
          onClick={() => {
            handleClose();
            dispatch(
              setPriorityAddEditModal({
                priorityAddEditData: props?.data,
                priorityAddEditType: "EDIT",
              })
            );
          }}
        />
        <MenuItem
          Icon={<DeleteOutline fontSize='small' />}
          itemText={"Delete"}
          itemProps={{ height: "15px" }}
          onClick={() => {
            handleClose();
            setIsDeleteOpen(true);
          }}
        />
      </CustomPopover>
      <DeleteConfirmationModal
        isOpen={isDeleteOpen}
        handleClose={() => setIsDeleteOpen(false)}
        message={`Are you sure you want to delete ${props?.data?.name} priority?`}
        description='This operation cannot be undone.'
        handleDelete={handleDelete}
        loading={isDeleteLoading}
      />
    </>
  );
};

export default ActionCell;
