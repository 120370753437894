import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Link, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import userAuth from "../../store/slices/userAuth";
import { PERMISSIONS, ROLES } from "../../constants";
import { getSpecPreviewRoute } from "../../router/routes";

const SpecificationCell = (props) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.userAuth);
  const activeCompany = user?.company?.status === "active";
  const canEditSpec =
    activeCompany &&
    (user.superAdmin ||
      (user.role === ROLES.ADMIN && !props?.data?.isTemplate) ||
      Boolean(
        props?.data?.specAccesses?.find(
          (access) =>
            access?.user?.id === user.id &&
            [PERMISSIONS.EDITOR, PERMISSIONS.ADMIN].includes(access?.permission)
        )
      ));

  return (
    <Box
      sx={{
        heigth: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        color: "black",
        ":hover": {
          textDecoration: "underline",
        },
        textDecorationColor: "black",
        cursor: "pointer",
        pr: 2,
      }}
      onClick={() => {
        if (canEditSpec) {
          navigate(`/specs/spec/${props?.data?.id}`);
        } else {
          navigate(getSpecPreviewRoute(props?.data?.id));
        }
      }}>
      <Typography
        variant='spec_body'
        sx={{
          lineHeight: "normal",
        }}>
        {props?.value}
      </Typography>
    </Box>
  );
};

export default SpecificationCell;
