import React, { useEffect, useState } from "react";
import BaseModal from "../../common/BaseModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getBusinessTerms,
  getSpecBusinessTermsAsync,
  unsetTermModal,
} from "../../store/slices/businessTerms";
import { Box, Stack, Typography } from "@mui/material";
import { InputField } from "../../common/InputField";
import DataSampleComponent from "../../common/DataSampleComponent";
import CustomSelectField from "../../common/CustomSelectField";
import {
  DATA_TYPES,
  ROLES,
  STATE_BUSINESS_TERMS,
  TERM_STATES,
} from "../../constants";
import { v4 as uuidv4 } from "uuid";
import {
  addBusinessTerm,
  autoCompleteSuggestions,
  editBusinessTerm,
  generateData,
  getBusinessTermCategoriesOptions,
} from "../../services";
import { HTTP_OK } from "../../constants/statusCodes";
import { defaultMessage } from "../../constants/messages";
import { toaster } from "../../common/Toaster";
import { PromptTextBox } from "../MockDatasetTable/MockDatasetContextMenu/AddEditLabel/style";
import PromptTagField from "../../common/PromptTagField";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LoadingButton from "../../common/LoadingButton";
import { HelpOutlineRounded } from "@mui/icons-material";
import TermDynamicFields from "./TermDynamicFields";
import _ from "lodash";
import { useParams } from "react-router-dom";

const AddEditBusinessTerm = ({ isSpecMappings = false }) => {
  const dispatch = useDispatch();
  const { specId } = useParams();
  const { filters } = useSelector((state) => state.tableFilter);
  const { user } = useSelector((state) => state.userAuth);
  const { termModal, mappingColumns, currentTermTab } = useSelector(
    (state) => state?.businessTerms
  );

  const [state, setState] = useState(TERM_STATES[1]);

  const [labelName, setLabelName] = useState("");
  const [description, setDescription] = useState("");
  const [attribute, setAttribute] = useState("");
  const [dataType, setDataType] = useState({ value: "text", label: "Text" });

  const [genDataLoading, setGenDataLoading] = useState(false);
  const [dataset, setDataset] = useState([]);
  const [promptBody, setPromptBody] = useState("");
  const [plainPrompt, setPlainPrompt] = useState("");
  const [mentionedLabels, setMentionedLabels] = useState([]);
  const [category, setCategory] = useState({
    value: "-1",
    label: "Uncategorized",
  });
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([
    { value: "-1", label: "Uncategorized" },
  ]);
  //   loading
  const [isLoading, setIsloading] = useState(false);

  // State to store the selected values for each custom mapping column
  const [formData, setFormData] = useState({});

  const dataTypeOptions = DATA_TYPES?.filter(
    (type) => type?.value !== "systemDefault"
  );

  const getUpdatedTerms = () => {
    dispatch(
      getBusinessTerms({
        status: currentTermTab,
        dataType: filters?.["dataType"]
          ? filters?.["dataType"]?.map((selected) => selected?.value)
          : [],
        proposedBy: filters?.["proposedBy"]
          ? filters?.["proposedBy"]?.map((selected) => selected?.value)
          : [],
        businessTermCategory: filters?.["businessTermCategory"]
          ? filters?.["businessTermCategory"]?.map(
              (selected) => selected?.value
            )
          : [],
      })
    );
  };

  const handleClose = () => {
    setState(TERM_STATES[1]);
    setLabelName("");
    setDescription("");
    setAttribute("");
    setDataType({ value: "text", label: "Text" });
    setDataset([]);
    setPromptBody("");
    setPlainPrompt("");
    setCategory({
      value: "-1",
      label: "Uncategorized",
    });
    setMentionedLabels([]);
    setFormData({});
    dispatch(unsetTermModal());
  };

  const handlePromptChange = (event, newValue, plainText, mentions) => {
    const uniqueMentions = mentions?.filter(
      (obj, index, self) => index === self?.findIndex((o) => o?.id === obj?.id)
    );
    setPromptBody(newValue);
    setPlainPrompt(plainText);
    setMentionedLabels(uniqueMentions);
  };

  const handleGenarateData = async () => {
    const payload = {
      data_type: dataType?.value,
      primary_input: labelName,
      secondary_input: plainPrompt,
      mentions: mentionedLabels,
      prompt: promptBody,
    };

    setGenDataLoading(true);

    try {
      const response = await generateData({ payload });

      setGenDataLoading(false);
      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }

      setDataset(response?.data?.generatedData);
    } catch (error) {
      setGenDataLoading(false);
      toaster({
        type: "error",
        message: "Error while generating mock dataset!",
      });
    }
  };

  const fetchLabels = (query, callback) => {
    autoCompleteSuggestions({ query: query })
      .then((res) => {
        // Transform the users to what react-mentions expects
        return res?.data?.businessTerms?.map((term) => ({
          display: `${term.name}`,
          id: term?.id,
        }));
      })
      .then(callback);
  };

  const handleSubmit = async () => {
    const payload = {
      dataset_column_id:
        termModal?.termModalType === "ADD"
          ? uuidv4()
          : termModal?.termModalData?.datasetColumnId,
      name: labelName,
      data_type: dataType?.value,
      state: state?.value,
      description: description,
      business_attribute: attribute,
      formula: "",
      quality: "low",
      database: "",
      table: "",
      dataset: dataset,
      sample: dataset.length ? dataset[0] : "",
      prompt: promptBody,
      plain_prompt: plainPrompt ?? "",
      mentions: mentionedLabels,
      proposedBy: user?.id,
      business_term_category_id: category?.value,
      business_term_fields_attributes: Object.values(formData),
    };

    if (termModal?.termModalType === "ADD") {
      try {
        setIsloading(true);
        const response = await addBusinessTerm({ payload: payload });

        if (response?.status !== HTTP_OK) {
          throw new Error(defaultMessage);
        }

        setIsloading(false);
        handleClose();
        getUpdatedTerms();
        toaster({
          type: "success",
          message:
            response?.data?.toaster?.data?.message ||
            "Business term has been successfully created.",
        });
      } catch (error) {
        setIsloading(false);
        toaster({
          type: "error",
          message: error,
        });
      }
    } else {
      try {
        setIsloading(true);
        const response = await editBusinessTerm({
          id: termModal?.termModalData?.id,
          payload: payload,
        });

        if (response?.status !== HTTP_OK) {
          throw new Error(defaultMessage);
        }

        setIsloading(false);
        handleClose();
        if (isSpecMappings) {
          dispatch(
            getSpecBusinessTermsAsync({
              specId: specId,
            })
          );
        } else {
          getUpdatedTerms();
        }

        toaster({
          type: "success",
          message:
            response?.data?.toaster?.data?.message ||
            "Business term updated successfully.",
        });
      } catch (error) {
        setIsloading(false);
        toaster({
          type: "error",
          message: error || "Error while updating a business term!",
        });
      }
    }
  };

  useEffect(() => {
    const getCategory = async () => {
      setCategoryLoading(true);
      try {
        const response = await getBusinessTermCategoriesOptions();
        setCategoryLoading(false);
        setCategoryOptions([
          { value: "-1", label: "Uncategorized" },
          ...response,
        ]);
      } catch (error) {
        setCategoryLoading(false);
        toaster({ type: "error", message: "Error while fetching categories!" });
      }
    };

    if (termModal?.isTermModalOpen) {
      getCategory();
    }
  }, [termModal?.isTermModalOpen]);

  useEffect(() => {
    const termModalData = termModal?.termModalData;

    if (termModalData) {
      setLabelName(termModalData.name);
      setDescription(termModalData.description);
      setAttribute(termModalData.businessAttribute);
      setDataType(
        dataTypeOptions?.find(
          (obj) => obj?.value === termModalData?.dataType
        ) || null
      );
      setPromptBody(termModalData?.prompt ? termModalData?.prompt : "");
      setPlainPrompt(
        termModalData?.plainPrompt ? termModalData?.plainPrompt : ""
      );
      setMentionedLabels(
        _.isEmpty(termModalData?.mentions) ? [] : termModalData?.mentions
      );
      setDataset(
        _.isEmpty(termModalData?.dataset) ? [] : termModalData?.dataset
      );
      setState(
        TERM_STATES?.find((obj) => obj?.value === termModalData?.state) || null
      );
      setCategory(
        termModalData?.businessTermCategory?.id
          ? {
              value: termModalData?.businessTermCategory?.id,
              label: termModalData?.businessTermCategory?.name,
            }
          : {
              value: "-1",
              label: "Uncategorized",
            }
      );
    }
  }, [termModal?.termModalData]);

  return (
    <BaseModal
      title={
        termModal.termModalType === "ADD"
          ? "Add Business Term"
          : "Edit Business Term"
      }
      dialogWidth={"550px"}
      isOpen={termModal?.isTermModalOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      submitDisable={!labelName.trim()}>
      <Stack rowGap={4}>
        <Stack rowGap={2}>
          <Typography variant='spec_h3'>Business Term Management</Typography>
          <Stack direction={"row"} columnGap={1} pl={2}>
            <Box sx={{ width: "50%" }}>
              <CustomSelectField
                value={category}
                isLabel={true}
                label='Category'
                options={categoryOptions}
                loading={categoryLoading}
                placeholder='Select Category'
                onChange={(selectedOption) => setCategory(selectedOption)}
                isMulti={false}
              />
            </Box>
            {termModal.termModalType !== "ADD" && (
              <Box sx={{ width: "50%" }}>
                <CustomSelectField
                  value={state}
                  isLabel={true}
                  label='State'
                  options={TERM_STATES.filter((obj) => obj.value !== "default")}
                  placeholder='Select State'
                  disabled={
                    user?.role !== ROLES.ADMIN ||
                    (isSpecMappings
                      ? false
                      : currentTermTab === STATE_BUSINESS_TERMS.EXISTS)
                  }
                  onChange={(selectedOption) => setState(selectedOption)}
                  isMulti={false}
                />
              </Box>
            )}
          </Stack>
        </Stack>
        <Stack rowGap={2}>
          <Typography variant='spec_h3'>Default Columns</Typography>
          <Stack rowGap={1} pl={2}>
            <InputField
              value={labelName}
              isLabel={true}
              label='Business Term Name'
              placeholder='Enter business term name'
              onChange={(e) => {
                setLabelName(e.target.value);
              }}
            />
            <InputField
              className={"inputHeightAuto"}
              isLabel={true}
              label='Description'
              placeholder='Enter business term description'
              multiline={true}
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Stack>
          <Stack direction={"row"} columnGap={1} pl={2}>
            <InputField
              isLabel={true}
              label='Attribute'
              placeholder='Name of attribute'
              value={attribute}
              onChange={(e) => setAttribute(e.target.value)}
            />
            <CustomSelectField
              value={dataType}
              isLabel={true}
              label='Data Type'
              options={dataTypeOptions}
              menuPlacement={"top"}
              placeholder='Select data type'
              onChange={(selectedOption) => setDataType(selectedOption)}
              isMulti={false}
            />
          </Stack>
          <Stack rowGap={1} pl={2}>
            <Stack rowGap={0.4}>
              <Stack direction={"column"}>
                <Typography
                  variant='spec_inputLabel'
                  sx={{
                    color: "secondary.black",
                  }}>
                  Mock Dataset (Optional)
                </Typography>
                <Stack py={0.5} pl={0.5}>
                  <Typography variant='spec_caption' color={"gray.main"}>
                    1. Enter an AI prompt that will be used to generate the mock
                    data you expect to see.
                  </Typography>
                  <Typography variant='spec_caption' color={"gray.main"}>
                    2. To create a derived label, press #. Click = to enter a
                    calculation.
                  </Typography>
                  <Typography variant='spec_caption' color={"gray.main"}>
                    3. If the prompt for the Mock Dataset is left empty, a
                    column will be generated with empty cells.
                  </Typography>
                </Stack>
                {/* <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses?.arrow}`]: {
                          color: (theme) => theme.palette.primary?.main,
                        },
                        [`& .${tooltipClasses?.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.primary?.main,
                          boxShadow: (theme) =>
                            theme?.palette?.shadows?.shadow1,
                          textAlign: "center",
                        },
                      },
                    },
                  }}
                  title={
                    <Typography variant='spec_caption'>
                      Enter an AI prompt that will be used to generate the mock
                      data you expect to see. To create a derived label, press
                      #. Click = to enter a calculation.
                    </Typography>
                  }
                  // title='Enter an AI prompt that will be used to generate the mock data you expect to see.'
                  placement='top'
                  arrow>
                  <HelpOutlineRounded
                    fontSize='small'
                    sx={(theme) => ({
                      color: theme.palette.info.main,
                      cursor: "pointer",
                    })}
                  />
                </Tooltip> */}
              </Stack>
              <Stack
                direction={"row"}
                // columnGap={1}
                width={1}
                justifyContent={"space-between"}>
                <PromptTextBox
                  sx={{
                    width: "75%",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <PromptTagField
                    value={promptBody}
                    handleChange={handlePromptChange}
                    placeholder='Enter a prompt to generate mock data.'
                    data={fetchLabels}
                  />
                </PromptTextBox>
                <Box sx={{ width: "20%" }}>
                  <LoadingButton
                    loading={genDataLoading}
                    onClick={handleGenarateData}
                    onMouseDown={(event) => event.preventDefault()}
                    variant='contained'
                    sx={{ width: "100px" }}
                    edge='end'
                    color='primary'>
                    Generate
                  </LoadingButton>
                </Box>
              </Stack>
            </Stack>
            <DataSampleComponent data={dataset} />
          </Stack>
        </Stack>
        <Stack rowGap={2}>
          <Typography variant='spec_h3'>Custom Columns</Typography>
          <Stack
            rowGap={1}
            columnGap={1}
            direction={"row"}
            flexWrap={"wrap"}
            pl={2}>
            <TermDynamicFields
              term={termModal?.termModalData}
              mappingColumns={mappingColumns}
              formData={formData}
              setFormData={setFormData}
            />
          </Stack>
        </Stack>
      </Stack>
    </BaseModal>
  );
};

export default AddEditBusinessTerm;
