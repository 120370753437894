import AppRouter from "../router/AppRouter";
import { useDispatch, useStore } from "react-redux";
import { createInstanceAndInjectStore } from "../axios";
import { persistor } from "../store";
import NoInternetConnection from "../components/NoInternetConnection/NoInternetConnection";

const App = () => {
  const st = useStore();
  const dispatch = useDispatch();
  createInstanceAndInjectStore(st, dispatch, persistor);
  return (
    <NoInternetConnection>
      <AppRouter />
    </NoInternetConnection>
  );
};

export default App;
