import { alpha, createTheme } from "@mui/material";
import { themePalette } from "./colors";

export const themeButtons = createTheme(themePalette, {
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        variant: "contained",
        color: "primary",
        fontSize: "14px",
        fontWeight: 500,
      },
      styleOverrides: {
        root: {
          borderRadius: "6px",
          textTransform: "capitalize",
          fontFamily: "Poppins",
          minWidth: "fit-content",
          // width: "fit-content",
          whiteSpace: "nowrap",
        },
      },
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: {
            "&:hover": {
              backgroundColor: themePalette.palette.primary.dark,
            },
            // "&:focus": {
            //   backgroundColor: themePalette.palette.primary.dark,
            // },
            "&:disabled": {
              backgroundColor: alpha(themePalette.palette.primary.dark, 0.5),
              color: themePalette.palette.gray.main,
            },
            // "&:active": {
            //   backgroundColor: themePalette.palette.primary.dark,
            // },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            color: themePalette.palette.secondary.main,
            borderColor: alpha(themePalette.palette.secondary.main, 0.5),
            "&:hover": {
              borderColor: themePalette.palette.secondary.main,
            },
            // "&:focus": {
            //   borderColor: themePalette.palette.secondary.main,
            // },
            "&:disabled": {
              borderColor: themePalette.palette.gray.lighter,
              color: themePalette.palette.gray.light,
            },
            // "&:active": {
            //   borderColor: themePalette.palette.secondary.main,
            // },
          },
        },
        {
          props: { variant: "outlined", color: "gray" },
          style: {
            color: themePalette.palette.gray.buttonGray,
            borderColor: alpha(themePalette.palette.gray.buttonGray, 0.5),
            "&:hover": {
              borderColor: alpha(themePalette.palette.gray.buttonGray, 0.7),
            },
            // "&:focus": {
            //   borderColor: alpha(themePalette.palette.gray.buttonGray, 0.7),
            // },
            "&:disabled": {
              borderColor: themePalette.palette.gray.lighter,
              color: themePalette.palette.gray,
            },
            // "&:active": {
            //   borderColor: alpha(themePalette.palette.gray.buttonGray, 0.7),
            // },
          },
        },
        {
          props: { variant: "outlined", color: "error" },
          style: {
            color: themePalette.palette.error.main,
            borderColor: alpha(themePalette.palette.error.main, 0.5),
            "&:hover": {
              borderColor: alpha(themePalette.palette.error.main, 0.7),
            },
            "&:focus": {
              borderColor: alpha(themePalette.palette.error.main, 0.7),
            },
            "&:disabled": {
              borderColor: themePalette.palette.error.light,
              color: themePalette.palette.error.main,
            },
            "&:active": {
              borderColor: alpha(themePalette.palette.error.main, 0.7),
            },
          },
        },
      ],
    },
  },
});
