import { useDispatch, useSelector } from "react-redux";
import { editBusinessTerm, updateCell } from "../../services";
import { useParams } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css";
import { toaster } from "../../common/Toaster";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { HTTP_OK } from "../../constants/statusCodes";
import CustomCellTextEditor from "./CustomCellTextEditor";
import { defaultMessage } from "../../constants/messages";
import { AgGridComponent, AgGridContainer } from "./style";

import OverlayNoRowsComponent from "./OverlayNoRowsComponent";
import LoadingOverlayComponent from "./LoadingOverlayComponent";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CustomMockdatasetHeader from "./CustomMockdatasetHeader/CustomMockdatasetHeader";
import { CURRENCY, NUMBER, PERCENT } from "../../constants";
import {
  getSelectedMockDatasetTabAsync,
  updateSpecTab,
} from "../../store/slices/mockDataset";

const MockDatasetTable = () => {
  const dispatch = useDispatch();
  const gridRef = useRef(null);
  const { specId } = useParams();
  const { specMockdataTabs, currentSelectedTab } = useSelector(
    (state) => state.mockDataset
  );

  const [rowData, setRowData] = useState([]);

  // Column Definitions: Defines & controls grid columns.
  const [colDefs, setColDefs] = useState([]);

  // SHOW OVERLAY
  const showLoading = useCallback(() => {
    return gridRef?.current?.api?.showLoadingOverlay();
  }, []);

  const hideOverlay = useCallback(() => {
    return gridRef?.current?.api?.hideOverlay();
  }, []);

  // Custom Header
  const components = useMemo(() => {
    return {
      agColumnHeader: (params) => (
        <CustomMockdatasetHeader {...params} isView={false} />
      ),
    };
  }, []);

  const handleOnCellEditingStopped = async (params) => {
    if (!params.newValue) {
      return;
    }

    if (params?.oldValue?.trim() === params.newValue?.trim()) {
      return;
    }

    const payload = {
      dataset_column_id: params?.colDef?.field,
      row_index: params?.rowIndex,
      value: params?.newValue,
    };

    try {
      showLoading();
      const response = await updateCell({
        specId: specId,
        tabId: currentSelectedTab,
        payload: payload,
      });

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }

      dispatch(
        updateSpecTab({
          tabId: response?.data?.specMockdataTab?.id,
          newTab: response?.data?.specMockdataTab,
        })
      );
      hideOverlay();
    } catch (error) {
      hideOverlay();
      toaster({ type: "error", message: "Error while updating cell data!" });
    }
  };

  const onSortChanged = async (e) => {
    showLoading();

    let sort = e.columns[e.columns?.length - 1]?.sort;
    let sortId = e.columns[e.columns?.length - 1]?.colDef?.labelId;

    try {
      const response = await editBusinessTerm({
        id: sortId,
        payload: { ordered_by: sort ? sort : "unsorted" },
      });

      if (response?.status !== HTTP_OK) {
        throw new Error(defaultMessage);
      }

      dispatch(
        getSelectedMockDatasetTabAsync({
          specId: specId,
          tabId: currentSelectedTab,
        })
      );

      // toaster({
      //   type: "success",
      //   message:
      //     response?.data?.toaster?.data?.message ||
      //     "Business term updated successfully.",
      // });
    } catch (error) {
      // toaster({
      //   type: "error",
      //   message: error || "Error while updating a business term!",
      // });
    }
  };

  useEffect(() => {
    if (specMockdataTabs?.length) {
      if (currentSelectedTab) {
        const table = specMockdataTabs?.find(
          (tab) => tab?.id === currentSelectedTab
        )?.table;

        const modifiedColumn = !table
          ? []
          : table?.columns?.map((column) => {
              return {
                field: column?.columnId,
                editable: true,
                headerName: column?.name,
                headerComponentParams: {
                  outdated: column?.outdated,
                },
                labelId: column?.businessTermId,
                type: "text",
                labelType: column?.type,
                status: column?.state,
                cellEditor: CustomCellTextEditor,
                singleClickEdit: true,
                cellEditorPopup: true,
                width: 200,
                minWidth: 200,
                sortable: true,
                sort: column?.orderedBy === "unsorted" ? "" : column?.orderedBy,
                comparator: () => 0,
                cellClass: [NUMBER.type, PERCENT.type, CURRENCY.type].includes(
                  column?.type
                )
                  ? "ag-right-aligned-cell"
                  : "",
              };
            });

        setColDefs([
          {
            field: "index",
            editable: false,
            headerName: "Index",
            type: "text",
            labelType: "",
            valueGetter: "node.rowIndex + 1",
            width: 100,
            resizable: false,
            lockPosition: "left",
            sortable: false,
            comparator: () => 0,
          },
          ...modifiedColumn,
        ]);

        const mutableRows = table?.rows?.map((item) => {
          return { ...item };
        });

        setRowData(mutableRows);
      }
    }
  }, [currentSelectedTab, specMockdataTabs]);

  return (
    <AgGridContainer className={"ag-theme-quartz"}>
      <AgGridComponent
        ref={gridRef}
        rowData={rowData}
        columnDefs={colDefs}
        components={components}
        onCellEditingStopped={handleOnCellEditingStopped}
        noRowsOverlayComponent={OverlayNoRowsComponent}
        loadingOverlayComponent={LoadingOverlayComponent}
        reactiveCustomComponents
        onSortChanged={onSortChanged}
      />
    </AgGridContainer>
  );
};

export default MockDatasetTable;
