export const ColumnIcon = () => {
  return (
    <svg
      width='100%'
      height='100%'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 60 61'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g id='chart icon / column chart (over time)'>
        <g id='Group 14'>
          <rect
            id='Rectangle 1'
            x='13.2002'
            y='31.7393'
            width='5.7931'
            height='15.0621'
            rx='0.5'
            fill='#808080'
          />
          <rect
            id='Rectangle 2'
            x='22.4692'
            y='13.2014'
            width='5.7931'
            height='33.6'
            rx='0.5'
            fill='#808080'
          />
          <rect
            id='Rectangle 3'
            x='31.7383'
            y='20.7324'
            width='5.7931'
            height='26.069'
            rx='0.5'
            fill='#808080'
          />
          <rect
            id='Rectangle 4'
            x='41.0073'
            y='28.2634'
            width='5.7931'
            height='18.5379'
            rx='0.5'
            fill='#808080'
          />
        </g>
      </g>
    </svg>
  );
};
