import {
  CloseOutlined,
  FeedbackOutlined,
  HelpOutline,
  MenuRounded,
} from "@mui/icons-material";
import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import Navigables from "./Navigables";
import { StyledAppBar, StyledToolbar } from "./style";
import InviteButton from "../InviteButton";
import { ROLES } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import CompanyToggleButton from "../CompanyToggleButton";
import CustomIconButton from "../../common/CustomIconButton";
import ProfileMenu from "../ProfileMenu";
import backgroundImage from "../../assets/images/announcementBgImage.png";
import { closeAnnouncement } from "../../store/slices/announcements";

const MainAppBar = () => {
  const dispatch = useDispatch();
  const { user, currentCompany } = useSelector((state) => state.userAuth);
  const { announcements } = useSelector((state) => state.announcements);
  const canInvite =
    [ROLES.ADMIN].includes(currentCompany?.role) || user?.superAdmin;

  const [open, setOpen] = useState(false);

  const toggleDrawer = (event) => {
    if (
      event?.type === "keydown" &&
      (event?.key === "Tab" || event?.key === "Shift")
    ) {
      return;
    }
    setOpen(!open);
  };

  return (
    <>
      <StyledAppBar>
        <Stack direction={"row"} width={1}>
          {announcements?.length > 0 && (
            <Stack // Announcement bar
              direction={"row"}
              width={1}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={(theme) => ({
                pl: 2,
                height: "40px",
                backgroundImage: `url(${backgroundImage})`,
                backgroundPosition: "0 0",
                backgroundRepeat: "repeat",
                backgroundSize: "auto",
              })}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "100%",
                  textAlign: "center", // Center the text
                  mx: "auto",
                  overflowX: "scroll",
                  "::-webkit-scrollbar": {
                    display: "none",
                  },
                }}>
                <Typography
                  variant='spec_body'
                  color='inherit'
                  component='div'
                  sx={(theme) => ({
                    color: theme?.palette?.secondary.white, // Your text color
                    whiteSpace: "nowrap",
                    a: {
                      whiteSpace: "nowrap",
                      color: theme?.palette?.primary?.main, // Your link color
                      textDecoration: "underline", // Your link underline style
                    },
                  })}
                  dangerouslySetInnerHTML={{
                    __html: announcements[0]?.body,
                  }}
                />
              </Box>
              <IconButton
                onClick={() => {
                  dispatch(closeAnnouncement());
                }}>
                <CloseOutlined sx={{ color: "white" }} fontSize='small' />
              </IconButton>
            </Stack>
          )}
        </Stack>
        <StyledToolbar>
          <Stack width={1}>
            <Stack direction={"row"} width={1} alignItems={"center"}>
              <CompanyToggleButton />
              <Box sx={{ display: { xs: "none", lg: "flex" } }} pl={2}>
                <Navigables />
              </Box>

              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                sx={{ ml: "auto" }}>
                {canInvite && <InviteButton />}
                <Stack flexDirection={"row"} alignItems={"center"} ml={0.5}>
                  <CustomIconButton
                    tooltipTitle='Feedback'
                    Icon={FeedbackOutlined}
                    onClick={() => window.open("/access/feedback", "_blank")}
                  />
                  <CustomIconButton
                    tooltipTitle='Help Center'
                    Icon={HelpOutline}
                    onClick={() => window.open("/access/zendesk", "_blank")}
                  />
                </Stack>

                <ProfileMenu />
                <CustomIconButton
                  sx={{ display: { xs: "flex", lg: "none" } }}
                  onClick={toggleDrawer}
                  Icon={MenuRounded}
                />
              </Stack>
            </Stack>
          </Stack>
        </StyledToolbar>
      </StyledAppBar>
      <Drawer
        anchor={"top"}
        open={open}
        onClose={toggleDrawer}
        sx={{ display: { xs: "flex", lg: "none" } }}>
        <Navigables setOpen={setOpen} />
      </Drawer>
    </>
  );
};

export default MainAppBar;
