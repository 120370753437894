/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Stack,
  Typography,
  Button,
  Box,
  InputLabel,
  MenuItem,
  Switch,
} from "@mui/material";
import { SquareIconButton } from "../../common/commonStyle";
import { InputField } from "../../common/InputField";
import { StyledStatusChip } from "../StatusChip/style";
import { CloseOutlined } from "@mui/icons-material";
import BaseModal from "../../common/BaseModal";
import AddIcon from "@mui/icons-material/Add";
import InfoTooltip from "../../common/InfoTooltip";
import { MuiSelectField } from "../../common/style";
import {
  BOOLEAN,
  CURRENCY,
  DATE,
  GEOGRAPHY,
  NUMBER,
  PERCENT,
  STATE_BUSINESS_TERMS_LABEL,
  TEXT,
  TIME,
  TYPE_LABEL,
} from "../../constants";

const ConfigureFilters = ({
  chart,
  label,
  params,
  setLabel,
  setParams,
  mockDataset,
  isPreview,
}) => {
  const xAxisType = [TEXT.type, GEOGRAPHY.type, BOOLEAN.type];
  const yAxisType = [
    NUMBER.type,
    PERCENT.type,
    CURRENCY.type,
    DATE.type,
    TIME.type,
  ];

  const [toggleType, setToggleType] = useState(params?.isRange || false);

  const handleFilterToggle = () => {
    setToggleType(!toggleType);
    setParams({ ...params, isRange: !toggleType });
  };

  const getBusinessTerm = (term) => {
    if (term.toLowerCase() === "default") {
      return "Default";
    } else {
      return term;
    }
  };

  // const [filterName, setFilterName] = useState("");
  // const [filterValueText, setFilterValueText] = useState("");
  // const [filterValue, setFilterValue] = useState([]);
  // const [isDuplicate, setIsDuplicate] = useState(false);

  // useEffect(() => {
  //   if (isOpen) {
  //     setFilterName(values.filterName);
  //     setFilterValue(values.filterValue);
  //   }

  //   setIsDuplicate(false);
  // }, [isOpen]);

  // const handleAddFilterValue = (e) => {
  //   if (e.key === "Enter" && e.target.value.trim() !== "") {
  //     if (!filterValue?.includes(e.target.value.trim())) {
  //       let values = [...filterValue];
  //       values.push(e.target.value.trim());
  //       setFilterValue(values);
  //       setFilterValueText("");
  //     } else {
  //       setIsDuplicate(true);
  //     }
  //   }
  // };

  // const onAddClick = () => {
  //   if (filterValueText.trim() !== "") {
  //     if (!filterValue?.includes(filterValueText.trim())) {
  //       let values = [...filterValue];
  //       values.push(filterValueText.trim());
  //       setFilterValue(values);
  //       setFilterValueText("");
  //     } else {
  //       setIsDuplicate(true);
  //     }
  //   }
  // };

  // const handleRemoveFilterValue = (index) => {
  //   let values = [...filterValue];
  //   values.splice(index, 1);
  //   setFilterValue(values);
  // };

  return (
    <Box py={2} px={4}>
      <Stack direction='column' spacing={2}>
        <InputField
          disabled={isPreview}
          isLabel={true}
          type='text'
          value={params?.filterByTerm}
          name='Title'
          label='Filter Title'
          placeholder='Enter a title for the filter'
          fullWidth
          noTextError
          id={`label`}
          onChange={(e) => {
            setParams({ ...params, filterByTerm: e?.target?.value });
          }}
        />

        <Stack direction={"row"} spacing={"4"} alignItems={"center"}>
          <InputLabel>
            <Typography variant='spec_inputLabel'>Is a Range</Typography>
          </InputLabel>
          <Switch
            disabled={isPreview}
            onChange={handleFilterToggle}
            value={params?.isRange}
            checked={params?.isRange}
          />
        </Stack>

        {!params?.isRange && (
          <Stack
            direction='column'
            spacing={2}
            alignItems='left'
            sx={{ width: "100%" }}>
            <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
              <InputLabel>
                <Typography variant='spec_inputLabel'>
                  Select Business Term
                </Typography>
              </InputLabel>
              <MuiSelectField
                disabled={isPreview}
                id='x'
                value={params?.x || 0}
                onChange={(e) => {
                  setParams({
                    ...params,
                    x: e.target.value,
                  });
                }}
                sx={{
                  width: "100%",
                  "#table": { display: "flex" },
                  ":has(div)": { color: "#8c8c8c", alignItems: "end" },
                }}>
                <MenuItem value={0} disabled>
                  <Typography variant='spec_body'>
                    Select Business Term
                  </Typography>
                </MenuItem>
                {mockDataset?.table.columns.map(
                  (item, index) =>
                    xAxisType.includes(item.text) && (
                      <MenuItem
                        key={index}
                        value={item.columnId}
                        sx={{ display: "flex", alignItems: "end" }}>
                        <Typography
                          mr={1}
                          color='text.primary'
                          variant='spec_body'>
                          {item?.name}
                        </Typography>
                        <Typography color='#8c8c8c' variant='spec_body'>
                          [{TYPE_LABEL[item?.text]} -{" "}
                          {getBusinessTerm(
                            STATE_BUSINESS_TERMS_LABEL[item?.state]
                          )}
                          ]
                        </Typography>
                      </MenuItem>
                    )
                )}
              </MuiSelectField>
            </Stack>
          </Stack>
        )}

        {params?.isRange && (
          <Stack
            direction='column'
            spacing={2}
            alignItems='left'
            sx={{ width: "100%" }}>
            <Stack direction='column' spacing={0.5} sx={{ width: 1 }}>
              <InputLabel>
                <Typography variant='spec_inputLabel'>
                  Select Business Term
                </Typography>
              </InputLabel>
              <MuiSelectField
                disabled={isPreview}
                id='y'
                value={params?.y || 0}
                onChange={(e) => {
                  setParams({
                    ...params,
                    y: e.target.value,
                  });
                }}
                sx={{
                  width: "100%",
                  "#table": { display: "flex" },
                  ":has(div)": { color: "#8c8c8c", alignItems: "end" },
                }}>
                <MenuItem value={0} disabled>
                  <Typography variant='spec_body'>
                    Select Business Term
                  </Typography>
                </MenuItem>
                {mockDataset?.table.columns.map(
                  (item, index) =>
                    yAxisType.includes(item.text) && (
                      <MenuItem
                        key={index}
                        value={item.columnId}
                        sx={{ display: "flex", alignItems: "end" }}>
                        <Typography
                          mr={1}
                          color='text.primary'
                          variant='spec_body'>
                          {item?.name}
                        </Typography>
                        <Typography color='#8c8c8c' variant='spec_body'>
                          [{TYPE_LABEL[item?.text]} -{" "}
                          {getBusinessTerm(
                            STATE_BUSINESS_TERMS_LABEL[item?.state]
                          )}
                          ]
                        </Typography>
                      </MenuItem>
                    )
                )}
              </MuiSelectField>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default ConfigureFilters;
