import { CloudOff } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

const NoInternetConnection = (props) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return props.children;
  } else {
    return (
      <Stack
        sx={{
          height: "100vh",
          width: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(31, 31, 31)",
        }}>
        <CloudOff
          sx={{ height: "128px", width: "128px", color: "rgb(227,227,227)" }}
        />
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "90%",
          }}>
          <Typography
            variant='spec_h1'
            sx={{
              color: "rgb(227,227,227)",
            }}>
            No internet connection.
          </Typography>
          <Typography
            variant='spec_body'
            sx={{
              color: "rgb(227,227,227)",
            }}>
            Please check your connection and try again.
          </Typography>
        </Stack>
      </Stack>
    );
  }
};

export default NoInternetConnection;
