import React, { useEffect, useRef, useState } from "react";

import {
  ArrowDownwardOutlined,
  FilterAltOutlined,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Menu,
  Stack,
  useTheme,
} from "@mui/material";
import { format, sub } from "date-fns";
import { DateRangePicker } from "react-date-range";

import "./default.css";
import "./styles.css";
import "./extendedStyle.css";
import { StyledBadge } from "../commonStyle";

const DateRangeCalendar = (props) => {
  const {
    dateRange,
    setDateRange,
    isFilterIcon,
    allowClearButton = true,
  } = props;
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectValue, setSelectvalue] = useState({
    startDate: sub(new Date(), { days: 6 }),
    endDate: new Date(),
    key: "selection",
  });
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleApply = () => {
    setDateRange(selectValue);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectvalue(dateRange);
  };

  const handleClear = () => {
    setAnchorEl(null);
    setDateRange({
      startDate: null,
      endDate: null,
      key: "selection",
    });
  };

  const handleSelect = (obj) => {
    setSelectvalue(obj?.selection);
  };

  useEffect(() => {
    setSelectvalue(dateRange);
  }, [dateRange]);

  return (
    <Box className='calendarWrap'>
      {isFilterIcon ? (
        <StyledBadge
          size='small'
          badgeContent={dateRange?.startDate && dateRange?.endDate ? 1 : 0}
          color='primary'
          showZero={false}>
          <IconButton
            disableRipple
            onClick={handleClick}
            sx={{
              height: "20px",
              width: "20px",
            }}>
            <FilterAltOutlined fontSize='small' style={{ color: "#424242" }} />
          </IconButton>
        </StyledBadge>
      ) : (
        <Button
          // size="small"
          onClick={handleClick}
          variant='outlined'
          sx={{
            border: `1px solid ${theme.palette.grey[300]}`,
            color: theme.palette.primary.dark,
          }}
          endIcon={
            <KeyboardArrowDown
              style={{ fontSize: "15px", color: theme.palette.grey[500] }}
            />
          }>
          <Box
            component='span'
            sx={{
              fontSize: "14px",
              lineHeight: "22px",
            }}>
            {!dateRange?.startDate && !dateRange?.endDate
              ? "mm/dd/yyyy - mm/dd/yyyy"
              : `${format(dateRange?.startDate, "MM/dd/yyyy")} - ${format(
                  dateRange?.endDate,
                  "MM/dd/yyyy"
                )}`}
          </Box>
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          mt: 1,
          p: 0,
          "& .MuiMenu-list": {
            p: 0,
          },
        }}>
        <DateRangePicker
          ranges={[selectValue]}
          onChange={handleSelect}
          className='calendarElement'
          color={"#FFC53D"} //this won't accept values from theme needs hash strings
          rangeColors={["#FFC53D", "#FFC53D"]} //this won't accept values from theme needs hash strings
        />
        <Stack
          flexDirection={"row"}
          justifyContent={"flex-end"}
          columnGap={1}
          sx={{ p: 1 }}>
          {allowClearButton && (
            <Button
              onClick={handleClear}
              color='secondary'
              variant='outlined'
              disabled={!dateRange?.startDate && !dateRange?.endDate}
              sx={{ minWidth: "100px" }}>
              <Box
                component='span'
                sx={{
                  fontSize: "14px",
                  lineHeight: "22px",
                }}>
                Clear
              </Box>
            </Button>
          )}
          <Button
            onClick={handleClose}
            color='gray'
            variant='outlined'
            sx={{ minWidth: "100px" }}>
            <Box
              component='span'
              sx={{
                fontSize: "14px",
                lineHeight: "22px",
              }}>
              Cancel
            </Box>
          </Button>
          <Button
            size='small'
            variant='contained'
            onClick={handleApply}
            sx={{ minWidth: "100px" }}>
            <Box
              component='span'
              sx={{
                fontSize: "14px",
                lineHeight: "22px",
              }}>
              Apply
            </Box>
          </Button>
        </Stack>
      </Menu>
    </Box>
  );
};

export default DateRangeCalendar;
