import { Button, CircularProgress, alpha, useTheme } from "@mui/material";
import React from "react";
import { specGray } from "../../constants/theme/colors";

const LoadingButton = ({
  loading = false,
  startIcon = null,
  endIcon = null,
  variant,
  children,
  disabled,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <Button
      startIcon={!loading ? startIcon : null}
      endIcon={!loading ? endIcon : null}
      variant={variant}
      disabled={loading ? true : disabled}
      {...rest}>
      <span style={{ opacity: loading ? 0 : 1 }}>{children}</span>
      {loading && (
        <CircularProgress
          size={24}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
            color:
              variant === "outlined"
                ? alpha(specGray[400], 0.7)
                : theme.palette.secondary.white,
          }}
        />
      )}
    </Button>
  );
};

export default LoadingButton;
