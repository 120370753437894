import { Avatar, Box, Stack, Tooltip, Typography, alpha } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { stringAvatar } from "../../utils/commonUtility";
import { specSecondary } from "../../constants/theme/colors";

const Author = () => {
  const { specData } = useSelector((state) => state.spec);

  const specAccesses = specData?.specAccesses ? specData?.specAccesses : [];

  const owner =
    specAccesses?.find((user) => user?.permission === "owner")?.user || null;

  return (
    <Stack direction={"row"} spacing={1}>
      <Box>
        <Typography variant='spec_caption' color={"gray.main"}>
          Author:
        </Typography>
      </Box>
      <Box>
        <Tooltip
          title={
            <Typography
              variant={"spec_tooltip"}
              sx={{
                backgroundColor: "transparent",
                wordBreak: "break-word",
              }}>
              {`${owner?.firstName} ${owner?.lastName}`}
            </Typography>
          }
          arrow>
          <Avatar
            sx={(theme) => ({
              bgcolor: specSecondary[50],
              height: "30px",
              width: "30px",
            })}>
            <Typography variant='spec_body' color={"secondary.main"}>
              {stringAvatar(`${owner?.firstName} ${owner?.lastName}`)?.children}
            </Typography>
          </Avatar>
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default Author;
