import { Stack, Typography } from "@mui/material";
import React from "react";
import { convertArrayToString } from "../../utils/commonUtility";

const DataSampleComponent = ({ data }) => {
  return (
    data?.length > 0 && (
      <Stack rowGap={0.4}>
        <Typography
          variant='spec_inputLabel'
          sx={{
            color: "secondary.black",
          }}>
          Data Sample:
        </Typography>
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          sx={{ whiteSpace: "pre-line" }}>
          <Typography variant='spec_body' color={"gray.main"}>
            {convertArrayToString(data)}
          </Typography>
        </Stack>
      </Stack>
    )
  );
};

export default DataSampleComponent;
