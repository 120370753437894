import { Box, styled as MuiStyled, alpha } from "@mui/material";
import { AgGridReact } from "ag-grid-react";

const borderColor = `color-mix(in srgb, transparent, var(
    --ag-foreground-color) 15%)`;

export const AgGridContainer = MuiStyled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  "&.ag-theme-quartz": {
    "--ag-foreground-color": theme.palette.secondary.black,
    "--ag-background-color": theme.palette.secondary.white,
    "--ag-header-foreground-color": theme.palette.secondary.black,
    "--ag-header-background-color": theme.palette.gray.headerBackground,
    "--ag-font-size": "14px",
    "--ag-font-family": "Poppins !important",
  },
  "--ag-row-border-color": theme.palette.gray.borderGray,
  ".ag-cell, .ag-header-cell": {
    borderRight: `1px solid ${theme.palette.gray.borderGray}`,
  },
  ".ag-row .ag-cell:last-child, .ag-header-row .ag-header-cell:last-child": {
    borderRight: `1px solid ${borderColor}`,
  },

  ".ag-header-cell, .ag-header-group-cell": {
    paddingLeft: "8px",
    paddingRight: "8px",
  },
}));

export const AgGridComponent = MuiStyled(AgGridReact)(({ theme }) => ({
  "--ag-header-column-resize-handle-display": "none",
  ".ag-header-cell-resize:hover": {
    "--ag-header-column-resize-handle-display": "block",
    "--ag-header-column-resize-handle-height": "100%",
    "--ag-header-column-resize-handle-width": "5px",
    "--ag-header-column-resize-handle-color": alpha(
      theme.palette.primary.main,
      0.3
    ),
  },
  ".ag-root-wrapper": {
    borderRadius: "0px",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "none",
    borderTop: "none",
  },
  ".ag-popup-child": {
    borderRadius: "7px !important",
    boxShadow: theme.palette?.shadows?.shadow1,
  },
}));
