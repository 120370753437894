import _ from "lodash";
import { format } from "date-fns";

export const getUserRows = (users) => {
  return users?.map((user) => {
    return {
      member:
        user?.firstName && user?.lastName
          ? `${user?.firstName} ${user?.lastName}`
          : null,
      id: user?.id,
      email: user?.email ? user?.email : "-",
      status: user?.invitationStatus ? user?.invitationStatus : "-",
      role: user?.companyOwner ? "owner" : user?.role,
      position: user?.jobTitle ? user?.jobTitle : "-",
      department: user?.department ? user?.department : "-",
      company: user?.company ? user?.company?.name : "-",
      specificationCount: user?.specificationsCount
        ? user?.specificationsCount
        : "-",
      dateJoined: user?.dateJoin
        ? format(new Date(user?.dateJoin), `MMMM dd, yyyy 'at' h:m a`)
        : "-",
      companyId: user?.company?.id ? user?.company?.id : null,
    };
  });
};
