import { Box, CircularProgress } from "@mui/material";
import React from "react";

const LoadingOverlayComponent = (props) => {
  return (
    <Box>
      <CircularProgress size={45} />
    </Box>
  );
};

export default LoadingOverlayComponent;
