import _ from "lodash";

export const getSpecDataScopeRows = (scopes, isPreview) => {
  const filteredScopes = isPreview
    ? scopes.filter((scope) => {
        return !_.isEmpty(scope?.value);
      })
    : scopes;

  const rowData = filteredScopes?.map((scope) => {
    return {
      id: scope?.id,
      name: scope?.dataScope?.name,
      value: scope?.value ? scope?.value : "-",
      category: scope?.dataScope?.category,
      description: _.isEmpty(scope?.dataScope?.details)
        ? "-"
        : scope?.dataScope?.details,
      required: scope?.dataScope?.required,
      values: scope?.dataScope?.values,
      originalObject: scope,
    };
  });

  return rowData;
};
