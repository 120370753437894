import { alpha } from "@mui/material";

export const selectStyle = (error, theme) => ({
  control: (base, { isDisabled, isFocused, menuIsOpen }) => ({
    ...base,
    height: "36px",
    minHeight: "36px",
    borderRadius: "7px",
    border: `${menuIsOpen ? "2px" : "1px"} solid ${
      isFocused ? theme.palette.primary.main : `rgba(0, 0, 0, 0.23)`
    }`,

    ...(error && {
      border: `${menuIsOpen ? "2px" : "1px"} solid ${theme.palette.error.main}`,
    }),
    backgroundColor: theme.palette.secondary.white,
    boxShadow: "0 !important",
    cursor: "pointer",
    "&:hover": {
      borderColor: theme.palette.primary.main,
      border: `${menuIsOpen ? "2px" : "1px"} solid ${
        isFocused ? theme.palette.primary.main : theme.palette.primary.main
      } !important`,
      ...(error && {
        border: `${menuIsOpen ? "2px" : "1px"} solid ${
          theme.palette.error.main
        }`,
      }),
    },
    fontFamily: "poppins",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "20px",
    paddingLeft: "4px",
  }),
  placeholder: (provider, state) => ({
    ...provider,
    opacity: 1,
    fontFamily: "poppins",
    fontWeight: 400,
    color: alpha(theme.palette.gray.dark, 0.5),
    fontSize: "15px",
    lineHeight: "24px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: "poppins",
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "24px",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? alpha(theme.palette.primary.main, 0.3)
      : theme.palette.secondary.white,
    color: theme.palette.secondary.black,
    marginTop: "5px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: state.isSelected
        ? alpha(theme.palette.primary.main, 0.3)
        : alpha(theme.palette.primary.main, 0.2),
    },
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    paddingRight: "10px",
  }),
  clearIndicator: (base, state) => ({
    ...base,
    padding: 0,
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: 0,
    color: theme.palette.gray.iconColor,
  }),
  menu: (provided, state) => ({
    ...provided,
    position: "relative",
    boxShadow: "0 !important",
    marginBottom: 0,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
});
