import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { MoreVertIcon } from "../../../assets";

const OverlayNoRowsComponent = (props) => {
  return (
    <Box
      sx={(theme) => ({
        // boxShadow: theme?.palette?.shadows?.shadow1,
        px: 3,
        py: 2,
        maxWidth: "350px",
        borderRadius: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "justify",
      })}>
      <Stack rowGap={2}>
        <Typography variant='spec_body'>
          Enhance your dashboard mockup effortlessly by following these steps:
        </Typography>
        <Stack rowGap={1}>
          <Typography variant='spec_body'>
            <b>1.</b> Click on the three-dot (
            <MoreVertIcon sx={{ mb: -0.5 }} fontSize='small' />) menu icon
            located in the table header index column.
          </Typography>
          <Typography variant='spec_body'>
            <b>2.</b> Define columns with labels by selecting 'Add Column'.
          </Typography>
          <Typography variant='spec_body'>
            <b>3.</b> Configure the number of rows for your dataset by clicking
            on 'Configure Rows' from the menu.
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default OverlayNoRowsComponent;
