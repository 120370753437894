import axios from "axios";
import { useDispatch } from "react-redux";
import { toaster } from "../../common/Toaster";
import { ROUTE_PATH } from "../../router/routes";
import React, { useEffect, useState } from "react";
import { checkOAuthTokenUrl } from "../../utils/urls";
import { setCookie } from "../../utils/commonUtility";
import { getCurrentCompany } from "../Login/loginUtil";
import { CircularProgress, Stack } from "@mui/material";
import { defaultMessage } from "../../constants/messages";
import { HTTP_CREATED } from "../../constants/statusCodes";
import { useLocation, useNavigate } from "react-router-dom";
import { setUserAndCompanies } from "../../store/slices/userAuth";

const SocialAuth = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const checkOAuthTokenFunc = async () => {
    try {
      const urlParams = new URLSearchParams(location.search);
      const token = urlParams.get("token");
      const requestConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(checkOAuthTokenUrl, {}, requestConfig);

      toaster({
        type: "success",
        message: response?.data?.toaster?.data?.message,
      });

      const currentURL = window.location.href;
      const currentCompany = getCurrentCompany(response.data?.user, currentURL);
      response.data.currentCompany = currentCompany;

      setCookie("token", token);
      setCookie("subdomain", currentCompany?.subdomain);
      dispatch(setUserAndCompanies(response?.data));

      navigate(ROUTE_PATH.SPECS);
    } catch (error) {
      toaster({
        type: "error",
        message: error?.response?.data?.toaster.data?.message,
      });
      navigate(ROUTE_PATH.LOGIN);
    }
  };

  useEffect(() => {
    checkOAuthTokenFunc();
    // eslint-disable-next-line
  }, []);

  return (
    <Stack
      sx={{
        height: 1,
        width: 1,
        justifyContent: "center",
        alignItems: "center",
      }}>
      <CircularProgress size={60} />
    </Stack>
  );
};

export default SocialAuth;
