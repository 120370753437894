import { getMappingColumns } from "../../services";
import { HTTP_OK } from "../../constants/statusCodes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultExceptionHandler } from "../../utils/defaultExceptionHandler";

const initialState = {
  // FUNCTIONS STATES
  mappingColumns: [],
  isMappingColumnsLoading: false,
  isMappingColumnsAddEditOpen: false,
  mappingColumnsAddEditData: null,
  mappingColumnsAddEditType: "ADD",
};

export const getMappingColumnsAsync = createAsyncThunk(
  "mappingSettings/getMappingColumnsAsync",
  async (payload) => {
    try {
      const response = await getMappingColumns();

      if (response?.status !== HTTP_OK) {
        throw new Error("Something went wrong!");
      }
      return response?.data;
    } catch (error) {
      throw defaultExceptionHandler(error);
    }
  }
);

const mappingSettingsSlice = createSlice({
  name: "mappingSettings",
  initialState: initialState,

  reducers: {
    setMappingColumnsAddEditModal: (state, action) => {
      state.mappingColumnsAddEditData = action?.payload
        ?.mappingColumnsAddEditData
        ? action?.payload?.mappingColumnsAddEditData
        : null;
      state.isMappingColumnsAddEditOpen = true;
      state.mappingColumnsAddEditType = action?.payload
        ?.mappingColumnsAddEditType
        ? action.payload.mappingColumnsAddEditType
        : "ADD";
    },
    resetMappingColumnsAddEditModal: (state, action) => {
      state.mappingColumnsAddEditData = null;
      state.isMappingColumnsAddEditOpen = false;
      state.mappingColumnsAddEditType = "ADD";
    },
  },

  extraReducers: (builder) => {
    // GET FUNCTIONS BUILDER FUNCTIONS
    builder.addCase(getMappingColumnsAsync.pending, (state, action) => {
      state.isMappingColumnsLoading = true;
    });
    builder.addCase(getMappingColumnsAsync.fulfilled, (state, action) => {
      state.isMappingColumnsLoading = false;
      state.mappingColumns = action?.payload?.mappingColumns;
    });
    builder.addCase(getMappingColumnsAsync.rejected, (state) => {
      state.isMappingColumnsLoading = false;
    });
  },
});

export const {
  setMappingColumnsAddEditModal,
  resetMappingColumnsAddEditModal,
} = mappingSettingsSlice.actions;
export default mappingSettingsSlice.reducer;
