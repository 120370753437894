import { Field, Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { toaster } from "../../../common/Toaster";
import BaseModal from "../../../common/BaseModal";
import { useDispatch, useSelector } from "react-redux";
import { InputField } from "../../../common/InputField";
import { addPriority, updatePriority } from "../../../services";
import { priorityNameValidationSchema } from "../../../utils/validationSchemas";
import {
  getPrioritiesAsync,
  resetPriorityAddEditModal,
} from "../../../store/slices/priorities";

const PriorityAddEditModal = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const { isPriorityAddEditOpen, priorityAddEditData, priorityAddEditType } =
    useSelector((state) => state.priorities);

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = (resetForm, validateForm) => {
    dispatch(resetPriorityAddEditModal());
    resetForm();
    validateForm();
  };

  const initialValues = {
    priority: priorityAddEditData?.name ? priorityAddEditData.name : "",
  };

  const handleChange = (field, value) => {
    field.onChange({
      target: {
        name: field.name,
        value: value,
      },
    });
  };

  const onSubmit = (values, { resetForm, validateForm }) => {
    if (priorityAddEditType === "ADD") {
      setIsLoading(true);
      addPriority({
        payload: {
          name: values.priority,
        },
      })
        .then((response) => {
          setIsLoading(false);
          dispatch(getPrioritiesAsync());
          toaster({
            type: "success",
            message:
              response?.data?.toaster?.data?.message ||
              "Priority was successfully created.",
          });
          handleClose(resetForm, validateForm);
        })
        .catch((e) => {
          setIsLoading(false);
          toaster({
            type: "error",
            message: e,
          });
        });
    } else {
      setIsLoading(true);
      updatePriority({
        priorityId: priorityAddEditData?.id,
        payload: {
          name: values.priority,
        },
      })
        .then((response) => {
          dispatch(getPrioritiesAsync());
          setIsLoading(false);
          handleClose(resetForm, validateForm);

          toaster({
            type: "success",
            message:
              response?.data?.toaster?.data?.message ||
              "Priority was successfully updated.",
          });
        })
        .catch((e) => {
          setIsLoading(false);
          toaster({ type: "error", message: e });
        });
    }
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={priorityNameValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      initialTouched={{ title: true }}
      validateOnMount={true}
      enableReinitialize={true}
      onSubmit={onSubmit}>
      {({ touched, errors, values, isValid, resetForm, validateForm }) => (
        <BaseModal
          title={
            priorityAddEditType === "ADD" ? "Add Priority" : "Priority Edit"
          }
          isOpen={isPriorityAddEditOpen}
          handleClose={() => {
            handleClose(resetForm, validateForm);
          }}
          confirmTitle={priorityAddEditType === "ADD" ? "Submit" : "Update"}
          handleSubmit={handleSubmitClick}
          isLoading={isLoading}
          submitDisable={errors && Object.keys(errors)?.length !== 0}>
          <Form>
            <Field name='priority'>
              {({ field, form }) => (
                <InputField
                  name='priority'
                  placeholder='Enter priority name here'
                  isLabel={true}
                  label='Priority Name'
                  variant={"outlined"}
                  value={field.value}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmitClick();
                    }
                  }}
                  onChange={({ currentTarget: input }) =>
                    handleChange(field, input.value)
                  }
                  onBlur={() => {
                    form.setTouched({
                      ...form.touched,
                      priority: true,
                    });
                  }}
                  error={form.touched.priority && Boolean(form.errors.priority)}
                  helperText={form.touched.priority && form.errors.priority}
                />
              )}
            </Field>
          </Form>
        </BaseModal>
      )}
    </Formik>
  );
};

export default PriorityAddEditModal;
