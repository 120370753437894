import React from "react";
import CustomPopover from "../CustomPopover";
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";
import Select, { components } from "react-select";
import { selectStyle } from "./style";
import { Checkbox, Stack, Typography, useTheme } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";

const PopupSelect = ({
  open,
  anchorEl,
  onClose,
  isAsync,
  isCreatable,
  value,
  handleSelectChange,
  error,
  isMulti,
  options,
  loading,
}) => {
  const theme = useTheme();
  const SelectComponent = isAsync
    ? AsyncSelect
    : isCreatable
    ? CreatableSelect
    : Select;

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <SearchOutlined fontSize='small' />
      </components.DropdownIndicator>
    );
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <Stack direction={"row"} alignItems={"center"} columnGap={1}>
            <Checkbox
              color='primary'
              sx={(theme) => ({
                p: 0,
                color: theme.palette.primary.main,
              })}
              checked={props.isSelected}
            />
            <Typography
              variant='spec_body'
              sx={{
                wordBreak: "break-word",
              }}>
              {props.label}
            </Typography>
          </Stack>
        </components.Option>
      </div>
    );
  };

  return (
    <>
      <CustomPopover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        paperprops={{
          minWidth: "250px",
          maxWidth: "250px",
        }}>
        <SelectComponent
          autoFocus
          backspaceRemovesValue={false}
          components={{ DropdownIndicator, IndicatorSeparator: null, Option }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          onChange={handleSelectChange}
          options={options}
          placeholder='Search...'
          styles={selectStyle(error, theme)}
          tabSelectsValue={false}
          value={value}
          isMulti={isMulti}
          isLoading={loading}
        />
      </CustomPopover>
    </>
  );
};

export default PopupSelect;
