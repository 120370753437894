import { Chip, styled as MuiStyled } from "@mui/material";
import { specSecondary } from "../../constants/theme/colors";

export const StyledStatusChip = MuiStyled((props) => (
  <Chip clickable {...props} />
))(({ theme }) => ({
  height: "25px",
  backgroundColor: specSecondary[50],
  ":hover": {
    backgroundColor: specSecondary[50],
  },
}));
