import React, { useEffect, useRef } from "react";
import { convertToCandlestickFormat } from "./utils";
import { useSelector } from "react-redux";
import { chartColors } from "../../SelectVisuals/utils";

function BoxPlot({ chartData, xTitle, yTitle, metric }) {
  const chartRef = useRef(null);
  const containerRef = useRef(null);
  const reduxState = useSelector((state) => state?.spec);

  useEffect(() => {
    const renderChart = () => {
      setTimeout(() => {
        drawBoxPlot();
      }, 150);
    };
    if (!window.google) return;
    renderChart();
  }, [
    containerRef?.current?.clientHeight,
    containerRef?.current?.clientWidth,
    reduxState?.isSpecVisualDatasetLoading,
    chartData,
  ]);

  const drawBoxPlot = () => {
    if (!window.google) return;

    const transformedData =
      JSON.parse(JSON.stringify(chartData[0].dataForChart))?.map(
        (ele) => ele?.values
      ) || [];

    const data = new window.google.visualization.DataTable();
    data.addColumn("string", "x");
    for (let i = 0; i < transformedData[0].length - 1; i++) {
      data.addColumn({ id: i, type: "number", p: { html: true } });
    }

    data.addColumn({
      id: "max",
      type: "number",
      role: "interval",
    });
    data.addColumn({
      id: "min",
      type: "number",
      role: "interval",
    });
    data.addColumn({
      id: "firstQuartile",
      type: "number",
      role: "interval",
    });
    data.addColumn({
      id: "median",
      type: "number",
      role: "interval",
    });
    data.addColumn({
      id: "thirdQuartile",
      type: "number",
      role: "interval",
    });
    data.addColumn({ type: "string", role: "tooltip", p: { html: true } });

    data.addRows(getBoxPlotValues(transformedData));

    /**
     * Takes an array of input data and returns an
     * array of the input data with the box plot
     * interval data appended to each row.
     */
    function getBoxPlotValues(array) {
      const arrayLength = array?.[0]?.length - 1;

      for (let i = 0; i < array.length; i++) {
        const arr = array[i].slice(1).sort(function (a, b) {
          return a - b;
        });

        const max = arr[arr.length - 1];
        const min = arr[0];
        const median = getMedian(arr);

        // First Quartile is the median from lowest to overall median.
        const firstQuartile = getMedian(arr.slice(0, 4));

        // Third Quartile is the median from the overall median to the highest.
        const thirdQuartile = getMedian(arr.slice(3));

        const tooltip = `<div style="padding: 10px; font-family: 'Poppins';">
          <strong>Max: </strong>${max}<br/>
          <strong>Min: </strong>${min}<br/>
          <strong>First Quartile: </strong>${firstQuartile}<br/>
          <strong>Median: </strong>${median}<br/>
          <strong>Third Quartile: </strong>${thirdQuartile}
        </div>`;

        array[i][arrayLength + 1] = max;
        array[i][arrayLength + 2] = min;
        array[i][arrayLength + 3] = firstQuartile;
        array[i][arrayLength + 4] = median;
        array[i][arrayLength + 5] = thirdQuartile;
        array[i][arrayLength + 6] = tooltip;
      }
      return array;
    }

    /*
     * Takes an array and returns
     * the median value.
     */
    function getMedian(array) {
      const length = array.length;

      /* If the array is an even length the
       * median is the average of the two
       * middle-most values. Otherwise the
       * median is the middle-most value.
       */
      if (length % 2 === 0) {
        const midUpper = length / 2;
        const midLower = midUpper - 1;

        return (array[midUpper] + array[midLower]) / 2;
      } else {
        return array[Math.floor(length / 2)];
      }
    }

    const options = {
      title: "Box Plot",
      tooltip: { isHtml: true, trigger: "none" },
      height: containerRef?.current?.clientHeight,
      chartArea: {
        height: containerRef?.current?.clientHeight - 80,
        top: "2%",
      },
      colors: [...chartColors],
      legend: {
        position: "none",
      },
      vAxis: {
        title: chartData?.[0]?.yAxisLabel || "",
        textStyle: { fontSize: 12, fontName: "Poppins" },
        titleTextStyle: {
          italic: false,
          fontSize: 12,
          fontName: "Poppins-Bold",
        },
      },
      hAxis: {
        title: chartData?.[0]?.xAxisLabel || "",
        textStyle: { fontSize: 12, fontName: "Poppins" },
        titleTextStyle: {
          italic: false,
          fontSize: 12,
          fontName: "Poppins-Bold",
        },
        gridlines: {
          color: "#fff",
        },
      },
      lineWidth: 0,
      series: [
        {
          color: "#D3362D",
        },
      ],
      intervals: {
        barWidth: 1,
        boxWidth: 1,
        lineWidth: 2,
        style: "boxes",
        color: chartColors[0],
      },
      interval: {
        max: {
          style: "bars",
          fillOpacity: 1,
          color: "#777",
        },
        min: {
          style: "bars",
          fillOpacity: 1,
          color: "#777",
        },
      },
    };

    const chart = new window.google.visualization.LineChart(chartRef?.current);

    chart.draw(data, options);
  };

  // const drawBoxPlot = () => {
  //   if (!window.google) return; // Make sure google is loaded

  //   const createData = convertToCandlestickFormat(Object.values(chartData));
  //   function drawChart() {
  //     const data = window.google.visualization.arrayToDataTable(
  //       [...createData],
  //       true
  //     );

  //     const options = {
  //       legend: "none",
  //       tooltip: { isHtml: true },
  //       hAxis: {
  //         title: xTitle,
  //         textStyle: { fontSize: 12, fontName: "Poppins" },
  //         titleTextStyle: {
  //           italic: false,
  //           fontSize: 12,
  //           fontName: "Poppins-Bold",
  //         },
  //       },
  //       vAxis: {
  //         title:
  //           chartData[0]?.symbol &&
  //           (chartData[0]?.symbol === "$" || chartData[0]?.symbol === "%")
  //             ? `${chartData[0].yaxis} (${metric} in ${chartData[0].symbol})`
  //             : yTitle,
  //         format: "short",
  //         textStyle: { fontSize: 12, fontName: "Poppins" },
  //         titleTextStyle: {
  //           italic: false,
  //           fontSize: 12,
  //           fontName: "Poppins-Bold",
  //         },
  //       },
  //       colors: ["#F1E15B"],
  //       height: containerRef.current?.clientHeight,
  //       chartArea: {
  //         height: containerRef.current?.clientHeight - 80,
  //         top: "2%",
  //       },
  //     };

  //     const chart = new window.google.visualization.CandlestickChart(
  //       chartRef.current
  //     );
  //     chart.draw(data, options);
  //   }
  //   if (!reduxState?.isSpecVisualDatasetLoading)
  //     window.google.charts.setOnLoadCallback(drawChart);
  // };

  return (
    <div
      ref={containerRef}
      style={{ height: "100%" }}
      className='cancelDraggableElement'>
      <div ref={chartRef}></div>
    </div>
  );
}

export default BoxPlot;
