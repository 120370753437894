export const baseApi = process.env.REACT_APP_API_URL;
let apiVersion = `${process.env.REACT_APP_API_URL}/api/v1/`;

export const authenticationUrl = `${apiVersion}authentications`;
export const checkOAuthTokenUrl = `${apiVersion}authentications/check_oauth_token`;
export const getUserProfile = `${apiVersion}users/profile`;
export const checkTokenUrl = `${apiVersion}authentications/check_token`;
export const specificationsUrl = `${apiVersion}specifications`;
export const inviteUserUrl = `${apiVersion}users/invitation`;

export const specDatasetUrl = (params) => {
  const { id = "" } = params;
  return `${apiVersion}specifications/${id}/dataset`;
};

export const specVisualisationUrl = (params) => {
  const { id = "" } = params;
  return `${apiVersion}specifications/${id}/visualization`;
};

export const updateSpecificationUrl = `${apiVersion}specifications`;

export const getSpecDataUrl = (params) => {
  const { id = "" } = params;
  return `${apiVersion}specifications/${id}`;
};

export const getSpecHistoryUrl = (params) => {
  const { id = "" } = params;
  return `${apiVersion}specifications/${id}/history`;
};
export const getSpecAutoCompleteUserUrl = (params) => {
  const { id = "" } = params;
  return `${apiVersion}users/autocomplete_user?query=&specification_id=${id}`;
};

export const getTemplatesUrl = `${apiVersion}templates`;
export const getStatusesUrl = `${apiVersion}spec_statuses`;
export const getPrioritiesUrl = `${apiVersion}priorities`;
export const getFunctionsUrl = `${apiVersion}spec_functions`;
export const getIndustriesUrl = `${apiVersion}spec_industries`;
export const createSpecCopyUrl = `${apiVersion}templates`;
export const getCompanyUsersUrl = `${apiVersion}users`;
export const getAutocompleteUsersUrl = `${apiVersion}users/autocomplete_user`;
export const askToInviteUrl = `${apiVersion}users/invitation/ask_to_invite`;

export const autocompleteSuggestionsUrl = `${apiVersion}business_terms/autocomplete_suggestions`;
export const getLabelUrl = `${apiVersion}business_terms`;
export const generateDataUrl = `${apiVersion}open_ai/generate_data`;
export const getDefaultDataChartsUrl = (params) => {
  const { id = "" } = params;
  return `${apiVersion}specifications/${id}/spec_mockdata_tabs`;
};
export const specIndexPreferenceUrl = `${apiVersion}spec_index_preference`;
export const specStatusesUrl = `${apiVersion}spec_statuses`;
export const specFunctionsUrl = `${apiVersion}spec_functions`;
export const mappingColumnsUrl = `${apiVersion}mapping_columns`;
export const businessTermCategoriesUrl = `${apiVersion}business_term_categories`;
export const businessTermUrl = `${apiVersion}business_terms`;
export const dataScopesUrls = `${apiVersion}data_scopes`;
export const deactivateUrl = `${apiVersion}companies/deactivate`;
export const invoicesUrl = `${apiVersion}invoices`;
export const resetPasswordUrl = `${apiVersion}passwords/reset_password`;
export const usersUrl = `${apiVersion}users`;
export const acceptInvitationTokenUrl = `${apiVersion}users/invitation/accept`;
export const passwordsUrl = `${apiVersion}passwords`;
export const zendeskJwtUrl = `${apiVersion}zendesk_jwt`;
export const templatesUrl = `${apiVersion}templates`;
export const announcementsUrl = `${apiVersion}announcements`;
