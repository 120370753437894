import {
  Box,
  Grid,
  List,
  Stack,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useState } from "react";
import {
  isNumber,
  minLength,
  isSpecialChar,
  isLowercaseChar,
  isUppercaseChar,
} from "../../utils/validations";
import MainCard from "../MainCard";
import { useSelector } from "react-redux";
import { toaster } from "../../common/Toaster";
import { createNewPassword } from "../../services";
import { InputField } from "../../common/InputField";
import { specGray } from "../../constants/theme/colors";
import { Check, HorizontalRule } from "@mui/icons-material";
import LoadingButton from "../../common/LoadingButton";

// ==============================|| TAB - PASSWORD CHANGE ||============================== //

const initialState = {
  confirm: "",
  password: "",
  oldPassword: "",
};

export const ChangePassword = () => {
  const { user } = useSelector((state) => state.userAuth);

  const [value, setValue] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const handleChange = (key) => (event) => {
    setValue((prev) => ({
      ...prev,
      [key]: event.target.value,
    }));
  };

  const isPasswordValid = () => {
    const { password, confirm, oldPassword } = value;
    return (
      oldPassword !== "" &&
      isLowercaseChar(password) &&
      isUppercaseChar(password) &&
      isSpecialChar(password) &&
      isNumber(password) &&
      minLength(password) &&
      password === confirm &&
      password !== oldPassword
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { oldPassword, password, confirm } = value;
    setLoading(true);
    createNewPassword({
      payload: {
        current_password: oldPassword,
        password: password,
        password_confirmation: confirm,
      },
      userId: user?.id,
    })
      .then((response) => {
        toaster({
          type: "success",
          message:
            response?.data?.toaster?.data?.message ||
            "You have successfully updated your password.",
        });
        setLoading(false);
        handleReset();
      })
      .catch((e) => {
        setLoading(false);
        toaster({ type: "error", message: e });
      });
  };

  const handleReset = () => {
    setValue(initialState);
  };

  return (
    <Grid container sx={{ backgroundColor: "white", height: "100%" }}>
      <Box component='form' noValidate m={3} sx={{ width: "100%" }}>
        <Grid container spacing={3} justifyContent='flex-start'>
          <Grid item xs={12} sm={6}>
            <MainCard title='Change Password'>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={1.25}>
                    <InputField
                      isLabel={true}
                      label='Current Password'
                      placeholder='Enter Old Password'
                      type={"password"}
                      value={value.oldPassword}
                      name='old'
                      onChange={handleChange("oldPassword")}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1.25}>
                    <InputField
                      isLabel={true}
                      label='New Password'
                      placeholder='Enter New Password'
                      type={"password"}
                      value={value.password}
                      name='password'
                      onChange={handleChange("password")}
                      error={
                        !!value.password.length &&
                        !(
                          isLowercaseChar(value.password) &&
                          isUppercaseChar(value.password) &&
                          isSpecialChar(value.password) &&
                          isNumber(value.password) &&
                          minLength(value.password)
                        )
                      }
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1.25}>
                    <InputField
                      isLabel={true}
                      label='Confirm Password'
                      placeholder='Enter Confirm Password'
                      type={"password"}
                      value={value.confirm}
                      name='confirm'
                      onChange={handleChange("confirm")}
                      error={
                        !!value.confirm.length &&
                        value.password !== value.confirm
                      }
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction='row'
                    justifyContent='flex-end'
                    alignItems='center'
                    spacing={2}>
                    <Button
                      disabled={loading}
                      variant='outlined'
                      color='gray'
                      onClick={handleReset}>
                      Clear
                    </Button>
                    <LoadingButton
                      disabled={!isPasswordValid() || loading}
                      loading={loading}
                      type='submit'
                      variant='contained'
                      color='primary'
                      onClick={handleSubmit}>
                      Save
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </MainCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <List>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color: minLength(value.password)
                      ? "success.main"
                      : "inherit",
                  }}>
                  {minLength(value.password) ? (
                    <Check sx={{ color: specGray[800] }} />
                  ) : (
                    <HorizontalRule sx={{ color: specGray[800] }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: "spec_body" }}
                  primary='At least 8 characters'
                />
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color: isLowercaseChar(value.password)
                      ? "success.main"
                      : "inherit",
                  }}>
                  {isLowercaseChar(value.password) ? (
                    <Check sx={{ color: specGray[800] }} />
                  ) : (
                    <HorizontalRule sx={{ color: specGray[800] }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: "spec_body" }}
                  primary='At least 1 lower letter (a-z)'
                />
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color: isUppercaseChar(value.password)
                      ? "success.main"
                      : "inherit",
                  }}>
                  {isUppercaseChar(value.password) ? (
                    <Check sx={{ color: specGray[800] }} />
                  ) : (
                    <HorizontalRule sx={{ color: specGray[800] }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: "spec_body" }}
                  primary='At least 1 uppercase letter (A-Z)'
                />
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color: isNumber(value.password)
                      ? "success.main"
                      : "inherit",
                  }}>
                  {isNumber(value.password) ? (
                    <Check sx={{ color: specGray[800] }} />
                  ) : (
                    <HorizontalRule sx={{ color: specGray[800] }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: "spec_body" }}
                  primary='At least 1 number (0-9)'
                />
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color: isSpecialChar(value.password)
                      ? "success.main"
                      : "inherit",
                  }}>
                  {isSpecialChar(value.password) ? (
                    <Check sx={{ color: specGray[800] }} />
                  ) : (
                    <HorizontalRule sx={{ color: specGray[800] }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: "spec_body" }}
                  primary='At least 1 special characters'
                />
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{
                    color:
                      value.password !== value.oldPassword
                        ? "success.main"
                        : "inherit",
                  }}>
                  {value.password !== value.oldPassword && value.password ? (
                    <Check sx={{ color: specGray[800] }} />
                  ) : (
                    <HorizontalRule sx={{ color: specGray[800] }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: "spec_body" }}
                  primary='Previous password cannot be reused'
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
