import { IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";

const CustomIconButton = ({
  Icon,
  IconSx,
  IconProps,

  tooltipTitle = "",
  ...props
}) => {
  return (
    <Tooltip
      title={
        tooltipTitle ? (
          <Typography
            variant='spec_tooltip'
            sx={(theme) => ({
              backgroundColor: "transparent",
              color: theme.palette.secondary.white,
            })}>
            {tooltipTitle}
          </Typography>
        ) : (
          ""
        )
      }
      arrow>
      <IconButton {...props}>
        <Icon
          {...IconProps}
          sx={(theme) => ({ color: theme.palette.gray.iconColor, ...IconSx })}
        />
      </IconButton>
    </Tooltip>
  );
};

export default CustomIconButton;
