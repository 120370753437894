import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { acceptInvitation, getInvitedUserData } from "../../services";
import { HTTP_OK } from "../../constants/statusCodes";
import { defaultMessage } from "../../constants/messages";
import {
  registrationValidationSchema1,
  registrationValidationSchema2,
} from "../../utils/validationSchemas";
import { toaster } from "../../common/Toaster";
import { Divider, Link, Stack, Typography } from "@mui/material";
import { AcceptInvitationCard, AcceptInvitationContainer } from "./style";
import { InputField } from "../../common/InputField";
import { ROUTE_PATH } from "../../router/routes";
import LoadingButton from "../../common/LoadingButton";
import { setCookie } from "../../utils/commonUtility";

const AcceptInvitation = () => {
  const formRef = useRef();
  const { token } = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const specId = queryParams.get("specId");
  const navigate = useNavigate();

  const [loadedData, setLoadedData] = useState(null);
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const redirectOnSuccess = (subdomain) => {
    const url = new URL(window.location.href);
    const parts = url?.hostname?.split(".");

    // If there's already a subdomain, replace it
    if (parts?.length > 2) {
      parts[0] = subdomain;
    } else {
      // If not, insert the subdomain
      parts?.unshift(subdomain);
    }

    url.hostname = parts?.join(".");

    const path = specId ? `/specs/spec/${specId}` : "/auth/login";

    setCookie("subdomain", subdomain);

    window.location.replace(`${url.protocol + "//" + url.hostname}${path}`);
  };

  useEffect(() => {
    setIsExistingUser(loadedData?.existingUser);
    if (!loadedData?.email?.length) {
      getInvitedUserData({ token: token })
        .then((response) => {
          if (response?.status !== HTTP_OK) {
            throw new Error(defaultMessage);
          }
          setLoadedData(response.data.user);
        })
        .catch((error) => {
          toaster({ type: "error", message: error });
          navigate("/auth/login");
        });
    } else {
      if (loadedData?.existingUser && loadedData?.company?.subdomain) {
        acceptInvitation({ token: token, payload: {} })
          .then((response) => {
            setSubmitLoading(false);
            if (response?.status !== HTTP_OK) {
              throw new Error(defaultMessage);
            }

            redirectOnSuccess(loadedData?.company?.subdomain);
          })
          .catch((e) => {
            setSubmitLoading(false);

            if (e.response.data.errors) {
              if (e?.response?.data?.errors?.context?.[0]) {
                toaster({
                  type: "error",
                  message: e?.response?.data?.errors?.context?.[0],
                });
              }
            }
          });
      }
    }
  }, [loadedData, token]);

  const initialValues = {
    firstName: loadedData?.firstName ? loadedData?.firstName : "",
    lastName: loadedData?.lastName ? loadedData?.lastName : "",
    department: loadedData?.department ? loadedData?.department : "",
    jobTitle: loadedData?.jobTitle ? loadedData?.jobTitle : "",
    companyName: loadedData?.company?.name ? loadedData?.company?.name : "",
    subdomain: loadedData?.company?.subdomain
      ? loadedData?.company?.subdomain
      : "",
    email: loadedData?.email ? loadedData?.email : "",
    password: "",
    confirmPassword: "",
  };

  const handleChange = (field, value) => {
    field.onChange({
      target: {
        name: field.name,
        value: value,
      },
    });
  };

  const generateSubdomain = (name) =>
    name
      .replace(/[^\w\s-]/g, "")
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/(-)+/g, "$1");

  const handleDomainChange = (value, setFieldValue) => {
    setFieldValue("companyName", value);
    setFieldValue("subdomain", generateSubdomain(value));
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const onSubmit = (values, { setFieldError }) => {
    const payload = {
      user: {
        first_name: values.firstName,
        last_name: values.lastName,
        department: values.department,
        job_title: values.jobTitle,
        password: values.password,
        confirm_password: values.confirmPassword,
      },
      company: {
        name: values.companyName,
        subdomain: values.subdomain,
      },
    };
    setSubmitLoading(true);
    acceptInvitation({ token: token, payload: payload })
      .then((response) => {
        setSubmitLoading(false);
        if (response?.status !== HTTP_OK) {
          throw new Error(defaultMessage);
        }

        redirectOnSuccess(values.subdomain);
      })
      .catch((e) => {
        setSubmitLoading(false);

        if (e.response.data.errors) {
          if (e.response.data.errors.name) {
            setFieldError(
              "companyName",
              `Company name ${e.response.data.errors.name}`
            );
          }
          if (e.response.data.errors.subdomain) {
            setFieldError(
              "subdomain",
              `Subdomain ${e.response.data.errors.subdomain}`
            );
          }

          if (e?.response?.data?.errors?.context?.[0]) {
            toaster({
              type: "error",
              message: e?.response?.data?.errors?.context?.[0],
            });
          }
        }
      });
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={
        isExistingUser
          ? registrationValidationSchema2
          : registrationValidationSchema1
      }
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
      onSubmit={onSubmit}
      enableReinitialize={true}>
      {({ touched, errors, values, isValid, setFieldValue }) => (
        <Form>
          <AcceptInvitationContainer>
            <AcceptInvitationCard>
              <Stack rowGap={2} mb={3}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  pb={2}>
                  <Typography
                    variant='spec_h2'
                    sx={{
                      fontSize: "20.8px",
                    }}>
                    Sign Up
                  </Typography>
                  <Typography
                    variant='spec_body'
                    color={"secondary"}
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(ROUTE_PATH.LOGIN);
                    }}>
                    Already have an account?
                  </Typography>
                </Stack>
                <Stack direction={"row"} columnGap={2}>
                  <Stack sx={{ width: "49%" }}>
                    <Field name='firstName'>
                      {({ field, form }) => (
                        <InputField
                          isRequired={true}
                          isLabel={true}
                          label='First Name'
                          name='firstName'
                          placeholder='Enter first name'
                          value={field.value}
                          onChange={({ currentTarget: input }) =>
                            handleChange(field, input.value)
                          }
                          onBlur={() => {
                            form.setTouched({
                              ...form.touched,
                              firstName: true,
                            });
                          }}
                          error={
                            form.touched.firstName &&
                            Boolean(form.errors.firstName)
                          }
                          helperText={
                            form.touched.firstName && form.errors.firstName
                          }
                          disabled={isExistingUser}
                        />
                      )}
                    </Field>
                  </Stack>
                  <Stack sx={{ width: "49%" }}>
                    <Field name='lastName'>
                      {({ field, form }) => (
                        <InputField
                          isRequired={true}
                          isLabel={true}
                          label='Last Name'
                          name='lastName'
                          placeholder='Enter last name'
                          disabled={isExistingUser}
                          value={field.value}
                          onChange={({ currentTarget: input }) =>
                            handleChange(field, input.value)
                          }
                          onBlur={() => {
                            form.setTouched({
                              ...form.touched,
                              lastName: true,
                            });
                          }}
                          error={
                            form.touched.lastName &&
                            Boolean(form.errors.lastName)
                          }
                          helperText={
                            form.touched.lastName && form.errors.lastName
                          }
                        />
                      )}
                    </Field>
                  </Stack>
                </Stack>

                <Stack direction={"row"} columnGap={2}>
                  <Stack sx={{ width: "49%" }}>
                    <Field name='department'>
                      {({ field, form }) => (
                        <InputField
                          isRequired={true}
                          isLabel={true}
                          label='Department'
                          name='department'
                          placeholder='Enter your department'
                          disabled={isExistingUser}
                          value={field.value}
                          onChange={({ currentTarget: input }) =>
                            handleChange(field, input.value)
                          }
                          onBlur={() => {
                            form.setTouched({
                              ...form.touched,
                              department: true,
                            });
                          }}
                          error={
                            form.touched.department &&
                            Boolean(form.errors.department)
                          }
                          helperText={
                            form.touched.department && form.errors.department
                          }
                        />
                      )}
                    </Field>
                  </Stack>
                  <Stack sx={{ width: "49%" }}>
                    <Field name='jobTitle'>
                      {({ field, form }) => (
                        <InputField
                          isRequired={true}
                          isLabel={true}
                          label='Job Title'
                          name='jobTitle'
                          placeholder='Enter your job title'
                          value={field.value}
                          disabled={isExistingUser}
                          onChange={({ currentTarget: input }) =>
                            handleChange(field, input.value)
                          }
                          onBlur={() => {
                            form.setTouched({
                              ...form.touched,
                              jobTitle: true,
                            });
                          }}
                          error={
                            form.touched.jobTitle &&
                            Boolean(form.errors.jobTitle)
                          }
                          helperText={
                            form.touched.jobTitle && form.errors.jobTitle
                          }
                        />
                      )}
                    </Field>
                  </Stack>
                </Stack>

                <Stack direction={"row"} columnGap={2}>
                  <Stack sx={{ width: "49%" }}>
                    <Field name='companyName'>
                      {({ field, form }) => (
                        <InputField
                          isRequired={true}
                          isLabel={true}
                          label='Company Name'
                          name='companyName'
                          placeholder='Enter your company name'
                          value={field.value}
                          disabled={loadedData?.company?.name}
                          onChange={({ currentTarget: input }) =>
                            handleDomainChange(input.value, setFieldValue)
                          }
                          onBlur={() => {
                            form.setTouched({
                              ...form.touched,
                              companyName: true,
                            });
                          }}
                          error={
                            form.touched.companyName &&
                            Boolean(form.errors.companyName)
                          }
                          helperText={
                            form.touched.companyName && form.errors.companyName
                          }
                        />
                      )}
                    </Field>
                  </Stack>
                  <Stack sx={{ width: "49%" }}>
                    <Field name='subdomain'>
                      {({ field, form }) => (
                        <InputField
                          isRequired={true}
                          isLabel={true}
                          label='Subdomain'
                          name='subdomain'
                          placeholder='Enter subdomain'
                          disabled={loadedData?.company?.subdomain}
                          value={field.value}
                          onChange={({ currentTarget: input }) =>
                            handleChange(field, input.value)
                          }
                          onBlur={() => {
                            form.setTouched({
                              ...form.touched,
                              subdomain: true,
                            });
                          }}
                          error={
                            form.touched.subdomain &&
                            Boolean(form.errors.subdomain)
                          }
                          helperText={
                            form.touched.subdomain && form.errors.subdomain
                          }
                          endAdornment={
                            <Typography
                              variant='spec_body'
                              color={"secondary.black"}>
                              .spectio.ai
                            </Typography>
                          }
                        />
                      )}
                    </Field>
                  </Stack>
                </Stack>
                <Stack direction={"row"} columnGap={2}>
                  <Stack sx={{ width: "49%" }}>
                    <Field name='email'>
                      {({ field, form }) => (
                        <InputField
                          isRequired={true}
                          name='email'
                          placeholder='Enter email address'
                          isLabel={true}
                          label='Email'
                          variant={"outlined"}
                          disabled
                          value={field.value}
                          onChange={({ currentTarget: input }) =>
                            handleChange(field, input.value)
                          }
                          onBlur={() => {
                            form.setTouched({
                              ...form.touched,
                              email: true,
                            });
                          }}
                          error={
                            form.touched.email && Boolean(form.errors.email)
                          }
                          helperText={form.touched.email && form.errors.email}
                        />
                      )}
                    </Field>
                  </Stack>
                  {!isExistingUser && (
                    <Stack sx={{ width: "49%" }}>
                      <Field name='password'>
                        {({ field, form }) => (
                          <InputField
                            isRequired={true}
                            name='password'
                            type={"password"}
                            placeholder='Enter Password'
                            isLabel={true}
                            label='Password'
                            variant={"outlined"}
                            value={field.value}
                            onChange={({ currentTarget: input }) =>
                              handleChange(field, input.value)
                            }
                            onBlur={() => {
                              form.setTouched({
                                ...form.touched,
                                password: true,
                              });
                            }}
                            error={
                              form.touched.password &&
                              Boolean(form.errors.password)
                            }
                            helperText={
                              form.touched.password && form.errors.password
                            }
                          />
                        )}
                      </Field>
                    </Stack>
                  )}
                </Stack>
                {!isExistingUser && (
                  <Stack direction={"row"} columnGap={2}>
                    <Stack sx={{ width: "49%" }}>
                      <Field name='confirmPassword'>
                        {({ field, form }) => (
                          <InputField
                            isRequired={true}
                            name='confirmPassword'
                            type={"password"}
                            placeholder='Confirm your password'
                            isLabel={true}
                            label='Confirm Password'
                            variant={"outlined"}
                            value={field.value}
                            onChange={({ currentTarget: input }) =>
                              handleChange(field, input.value)
                            }
                            onBlur={() => {
                              form.setTouched({
                                ...form.touched,
                                confirmPassword: true,
                              });
                            }}
                            error={
                              form.touched.confirmPassword &&
                              Boolean(form.errors.confirmPassword)
                            }
                            helperText={
                              form.touched.confirmPassword &&
                              form.errors.confirmPassword
                            }
                          />
                        )}
                      </Field>
                    </Stack>
                  </Stack>
                )}
              </Stack>
              <Divider />
              <Stack
                spacing={1}
                sx={{
                  mt: 2,
                  width: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Typography color='gray.main' variant='spec_caption'>
                  By signing up, I agree to the Spectio{" "}
                  {loadedData?.companyOwner && (
                    <>
                      <Link
                        href={"https://www.spectio.com/saas-agreement"}
                        target='_blank'
                        rel='noopener'
                        variant='spec_caption'
                        color='gray.main'
                        sx={{ textDecoration: "underline", cursor: "pointer" }}>
                        SaaS Agreement
                      </Link>{" "}
                      and{" "}
                    </>
                  )}
                  <Link
                    href={"https://www.spectio.com/acceptable-use-policy"}
                    target='_blank'
                    rel='noopener'
                    variant='spec_caption'
                    color='gray.main'
                    sx={{ textDecoration: "underline", cursor: "pointer" }}>
                    Acceptable Use Policy.
                  </Link>
                </Typography>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  color='primary'
                  sx={{ width: "50%" }}
                  disabled={errors && Object.keys(errors)?.length !== 0}
                  loading={submitLoading}>
                  Sign up
                </LoadingButton>
              </Stack>
            </AcceptInvitationCard>
          </AcceptInvitationContainer>
        </Form>
      )}
    </Formik>
  );
};

export default AcceptInvitation;
