import { createSlice } from "@reduxjs/toolkit";

export const tableFilterInitialState = {
  filters: {
    status: [],
    priority: [],
    function: [],
    requester: [],
    author: [],
    collaborators: [],
    urgency: [],
    dataType: [],
    proposedBy: [],
    businessTermCategory: [],
    dueDate: {
      dateRange: {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    },
  },
};

const tableFilterSlice = createSlice({
  name: "tableFilter",
  initialState: tableFilterInitialState,

  reducers: {
    setSelectedOptions: (state, action) => {
      const { colId, options } = action.payload;
      state.filters[colId] = options;
    },
    setDueDate: (state, action) => {
      state.filters.dueDate.dateRange = action?.payload;
    },
    resetTableFilters: (state, action) => tableFilterInitialState,
  },
});

export const { setSelectedOptions, resetTableFilters, setDueDate } =
  tableFilterSlice.actions;
export default tableFilterSlice.reducer;
