import React, { useEffect, useState } from "react";
import {
  CommentOutlined,
  HistoryOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Chip, Stack, Typography, alpha } from "@mui/material";
import SpecTitle from "../SpecTitle";
import { StyledAppBar, StyledToolbar } from "./style";
import CustomIconButton from "../../common/CustomIconButton";
import StatusChip from "../StatusChip";
import Author from "../Author";
import FunctionDropdown from "../FunctionDropdown";
import IndustryDropdown from "../IndustryDropdown";
import { useDispatch, useSelector } from "react-redux";
import SpecMoreMenu from "../SpecMoreMenu";
import ShareModal from "../ShareModal";
import HistoryDrawer from "../History/HistoryDrawer";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTE_PATH, getSpecPreviewRoute } from "../../router/routes";
import CommentsDrawer from "../Comments/CommentsDrawer";
import { updateAutoSaved } from "../../store/slices/userSpec";
import PriorityChip from "../PriorityChip";

const SpecAppBar = ({ isPreview }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { specId } = useParams();
  const [searchParams] = useSearchParams();
  const commentId = searchParams.get("commentId");
  const { user } = useSelector((state) => state.userAuth);
  const { autoSaved } = useSelector((state) => state.spec);
  const [historyDrawerAnchor, setHistoryDrawerAnchor] = useState(null);
  const [commentsDrawerAnchor, setCommentsDrawerAnchor] = useState(null);

  useEffect(() => {
    if (commentId) {
      setCommentsDrawerAnchor(true);
    }
  }, [commentId]);

  useEffect(() => {
    if (autoSaved) {
      setTimeout(() => {
        dispatch(updateAutoSaved(false));
      }, 1000);
    }
  }, [autoSaved]);

  return (
    <>
      <StyledAppBar>
        <StyledToolbar>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={1}
            columnGap={2}
            sx={{
              position: "relative",
              overflowX: { xs: "scroll", sm: "scroll" },
              overflowY: { xs: "hidden", sm: "hidden" },
              scrollbarWidth: "none",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}>
            <SpecTitle />

            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                columnGap: 2,
                minWidth: "100px",
                width: 1,
                overflowX: "scroll",
                scrollbarWidth: "none",
                "::-webkit-scrollbar": {
                  display: "none",
                },
              }}>
              <StatusChip />
              <PriorityChip />
              <FunctionDropdown />
              {user?.superAdmin && <IndustryDropdown />}
              <Author />
            </Stack>

            <Stack flexDirection={"row"} columnGap={1}>
              {autoSaved && (
                <Stack alignItems={"center"} justifyContent={"center"}>
                  <Chip
                    size='small'
                    variant='filled'
                    label={
                      <Typography variant='spec_body' color={"success.main"}>
                        Saved
                      </Typography>
                    }
                    sx={(theme) => ({
                      borderRadius: "12px",
                      backgroundColor: alpha(theme.palette.success.main, 0.1),
                      color: theme.palette.success.main,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                    })}
                  />
                </Stack>
              )}

              <CustomIconButton
                tooltipTitle='Comments'
                Icon={CommentOutlined}
                onClick={() => setCommentsDrawerAnchor(true)}
              />
              <CustomIconButton
                tooltipTitle='History'
                Icon={HistoryOutlined}
                onClick={() => setHistoryDrawerAnchor(true)}
              />
              <CustomIconButton
                onClick={() => navigate(getSpecPreviewRoute(specId))}
                tooltipTitle='Preview'
                Icon={VisibilityOutlined}
              />
              <ShareModal />
              <SpecMoreMenu />
            </Stack>
          </Stack>
        </StyledToolbar>
      </StyledAppBar>
      <HistoryDrawer
        open={historyDrawerAnchor}
        onClose={() => setHistoryDrawerAnchor(false)}
      />
      <CommentsDrawer
        open={commentsDrawerAnchor}
        onClose={() => setCommentsDrawerAnchor(false)}
      />
    </>
  );
};

export default SpecAppBar;
